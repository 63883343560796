import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Button, TextField } from '@mui/material';
import axios from "../../../config/axiosConfig";
import Application from "../../../config/ApplicationWrapper";
import { toast } from "react-toastify";

export default function TemplateQrPopup(props) {
  const { show, closepopup, qrDetails } = props;
  const [limit, setLimit] = useState(qrDetails.external_form_template_datum.max_forms_limit_by_qr_code);

  const onhandleStatusClose = () => {
    closepopup();
  };

  const update = async () => {
    const result = await axios.patch(`${Application.api_url}/api/template_qr_codes/${qrDetails.id}`,
      { max_forms_limit_by_qr_code: limit },
    );

    if (result.data && result.data.success === true) {
      toast.success("Updated Successfully")
      closepopup();
    }
  }

  return (
    <Modal
      show={show}
      onHide={(e) => {
        onhandleStatusClose();
      }}
      className="qr-modal-wrap add-task-modal pf-model"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="pf-model-header">
            <p className="pf-model-title"> Edit Qr Code Details: </p>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="row">
            <div className="col-md-12">
              <label>
                Max Forms Limit
              </label>
              <TextField
                fullWidth
                className="text-field"
                id="outlined-required"
                value={limit}
                onChange={(e) => {
                  setLimit(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="submit-btns">
          <Button className="btn-cancel" onClick={(e) => { onhandleStatusClose() }}>Cancel</Button>
          <Button variant="contained" onClick={(e) => { update() }}>
            Update
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
