import React from "react";
import FooterLogo from "../../../javascript/assets/images/footer-logo.svg";
import Facebook from "../../../javascript/assets/fonts/Facebook_white.svg";
import LinkedIn from "../../../javascript/assets/fonts/LinkedIn.svg";
import ScrollArrow from "../../../javascript/assets/fonts/Arrow.svg";
import AppleStore from "../../../javascript/assets/images/applestore.svg";
import PlayStore from "../../../javascript/assets/fonts/play.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  const goTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="Footer">
      <div className="home-container">
        <div className="footer-contents d-flex">
          <div className="col-xs-12 col-md-4 col-lg-4 footer-logo-content">
          <Link to="/" role="button"><img src={FooterLogo} alt="..." className="footer-logo" /></Link>
          </div>
          <div className="col-xs-12 col-md-2  col-lg-2">
            {/* <h3>Products</h3>
            <ul>
              <li>Product 1</li>
              <li>Product 2</li>
              <li>Product 3</li>
              <li>Product 4</li>
            </ul> */}
          </div>
          <div className="col-xs-12 col-md-2 col-lg-2">
            {/* <h3>Solutions</h3>
            <ul>
              <li>For Project Controls</li>
              <li>For Risk Assessment</li>
              <li>Site Operations</li>
              <li>Contract Management</li>
            </ul> */}
          </div>
          <div className="col-xs-12">
            {/* <ul style={{ marginTop: "40px" }}>
              <li>About US</li>
              <li>Our Team</li>
              <li>Careers</li>
              <li>Contact Us</li>
            </ul> */}
          </div>
          <div className="col-xs-12 col-md-2 col-lg-2 social-media">
            <p>Follow us</p>
            <div className="d-inline-flex d-flex follow-us">
              <div className="social">
                <a
                  href="https://www.facebook.com/profile.php?id=100076178023002"
                  target="_blank"
                >
                  <img src={Facebook} alt="..." />
                </a>
              </div>
              {/* <Col className="social">
                <img src={Youtube} alt="..." />
              </Col> */}
              <div className="social">
                <a
                  href="https://www.linkedin.com/company/safetyloopinc/"
                  target="_blank"
                >
                  <img src={LinkedIn} alt="..." />
                </a>
              </div>
              {/* <Col className="social">
                <img src={Twitter} alt="..." />
              </Col> */}
            </div>
            <div>
              <a
                title=""
                href="https://apps.apple.com/us/app/safetyloop-app/id1589722340"
                target="_blank"
              >
                <img src={AppleStore} className="store-img" alt="..." />
              </a>
              <a
                title=""
                href="https://play.google.com/store/apps/details?id=com.safetyloop"
                target="_blank"
              >
                <img src={PlayStore} alt="..." className="store-img" />
              </a>
            </div>
          </div>
        </div>
        <img src={ScrollArrow} className="scroll" alt="..." onClick={goTop} />
        <div>
          <div className="d-flex justify-content-between copyrights">
            <div>
              <p>Copyright 2024 © SafetyLoop, Inc. All rights reserved.</p>
            </div>
            
            <div class="trustpilot-widget" data-locale="en-US" data-template-id="56278e9abfbbba0bdcd568bc" data-businessunit-id="667c06f1341e07c644226adf" data-style-height="52px" data-style-width="100%">
              <a href="https://www.trustpilot.com/review/safetyloop.com" target="_blank" rel="noopener">Trustpilot</a>
            </div>
            
            <div>
              <ul className="d-flex terms-cond">
                
                <li><a href="/terms">Terms & Conditions</a></li>
                <li><a href="/privacy">Privacy Policy</a></li>
                <li><a href="/disclaimer">Disclaimer</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};



export default Footer;
