import React from 'react';
import UserInfo from './UserInfo';
import ExpiredTrial from './ExpiredTrial';
import RemainingTrialDays from './RemainingTrialDays';
import AccountStatus from './AccountStatus';
import { User } from "pages/HomePage/Login";

class RightNavBar extends React.Component {
    render() {
        return (
            <div className="nav navbar-top-links navbar-right" style={{ marginLeft: "auto", display: 'flex', justifyContent: 'space-between', padding: "12px 0" }}>
                {User.isActiveSubscription && <AccountStatus />}
                {User.remainingTrialDays > 0 && <RemainingTrialDays days={User.remainingTrialDays} />}
                {!User.isActiveSubscription && <ExpiredTrial />}
                <UserInfo />
            </div>
        );
    }
}

export default RightNavBar


//<li role="separator" className="divider hidden-lg hidden-md hidden-sm"></li>
// <li className="hidden-xs user-notification" onClick={this.handleCheck}>
//     <a className="action-right-sidebar-toggle" title="Right sidebar on/off">
//         <img alt="Mashey"  src={timeline_icon}/>
//     </a>
// </li>
