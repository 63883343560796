import React, { useEffect, useRef } from "react";
import { Controller, useWatch } from "react-hook-form";
import {
  Grid,
  Typography,
  FormControlLabel,
  Switch,
  FormControl,
} from "@mui/material";
import DualListbox from "../../../shared/components/dualListBox";

export const GlobalProjectForm = ({
  register,
  errors,
  setValue,
  control,
  companies = [],
  groups = [],
  managers = [],
}) => {
  const elementRef = useRef(null);
  console.log(companies, "companies");
  const isGlobalProject = useWatch({
    control: control,
    name: "invite_company",
    defaultValue: false,
  });
  useEffect(() => {
    if (elementRef && elementRef.current) {
      elementRef.current.scrollIntoView();
    }
  }, []);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Controller
            name="group_managers"
            control={control}
            render={({ field: { value = [] } }) => (
              <DualListbox
                title={"Manager(s)"}
                control={control}
                setValue={setValue}
                name="group_managers"
                leftData={managers.filter((tr) =>
                  value.every((val) => val.value !== tr.value)
                )}
                rightData={value}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Controller
            name="group_members"
            control={control}
            render={({ field: { value = [] } }) => (
              <DualListbox
                title={"Member(s)"}
                control={control}
                setValue={setValue}
                name="group_members"
                leftData={groups.filter((tr) =>
                  value.every((val) => val.value !== tr.value)
                )}
                rightData={value}
              />
            )}
          />
        </Grid>
        {/* <Grid item xs={1}>
          <Controller
            style={{ color: "#407797" }}
            name="shared"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                style={{ marginLeft: "15px" }}
                control={<Switch {...field} checked={field.value} />}
                label=""
              />
            )}
          />
        </Grid> */}
        <Grid item xs={12} sm={12}>
          <div className="grey-box">
            <FormControl>
              <Controller
                name="invite_company"
                control={control}
                defaultValue={false} // Set default to false
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        inputRef={ref}
                      />
                    }
                    label="Invite Sub contractors to work on your behalf"
                  />
                )}
              />
            </FormControl>
          </div>
        </Grid>
        {/* <Grid item xs={6}>
          <Typography variant="body1">
            Invite Sub contractors to work on your behalf
          </Typography>
        </Grid> */}
        <div ref={elementRef} />
        {!!isGlobalProject && (
          <Grid item xs={12} sm={12}>
            <Controller
              name="group_companies"
              control={control}
              render={({ field: { value = [] } }) => (
                <DualListbox
                  title={"Companies"}
                  control={control}
                  setValue={setValue}
                  name="group_companies"
                  leftData={companies.filter((tr) =>
                    value.every((val) => val.value !== tr.value)
                  )}
                  rightData={value}
                />
              )}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};
