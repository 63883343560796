import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { Pagination, MenuItem, FormControl, Select, Divider, Link, Breadcrumbs } from '@mui/material';
import axios from "../../../config/axiosConfig";
import Application from "../../../config/ApplicationWrapper";
import Folder from "../../../assets/fonts/Folder.svg";
import List from "../../../assets/fonts/List.png";
import Card from "../../../assets/fonts/Card.png";
import ListView from "../CompanyDocs/view/list";
import CardView from "../CompanyDocs/view/card";
import CreateFolder from "../CompanyDocs/popups/create";
import loader from "../../../assets/images/newloader.gif";

// import TemplateQrPopup from "../Popups/TemplateQrCode";

const StaredDoc = () => {

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [pageCount, setPageCount] = useState(0);
    const [pageDetails, setPageDetails] = useState([]);
    const [paginationStatus, setPaginationStatus] = useState(false);
    const [name, setSearchByName] = useState();
    const [searchByType, setSearchByType] = useState();
    const [searchByCreatedBy, setSearchByCreatedBy] = useState();
    const [searchByUpdatedAt, setSearchByUpdatedAt] = useState();
    const [viewStyle, setViewStyle] = useState("list");
    const [openFolder, setOpenFolder] = useState(false);
    const [selectedData, setSelectedData] = useState();
    const [sorting, setSorting] = useState(false);
    const [sortedBy, setSortedBy] = useState("file_name");
    const firstUpdate = useRef(false);
    const [breadcrumb, setBreadcrumb] = useState([{
        name: "Home",
        id: ""
    }]);

    useEffect(() => {
        getPageDetails(breadcrumb[breadcrumb.length - 1].id);
    }, [page, limit, sorting, sortedBy]);

    useEffect(() => {
        if (firstUpdate.current) {
            const delayDebounceFn = setTimeout(() => {
                setPaginationStatus(false);
                getPageDetails(breadcrumb[breadcrumb.length - 1].id, 1);
            }, 2000);

            return () => clearTimeout(delayDebounceFn);
        } else {
            firstUpdate.current = true;
        }
    }, [name, searchByType, searchByUpdatedAt, searchByCreatedBy]);

    const getPageDetails = async (id, pageNo) => {
        try {
            window.scrollTo(0, 0);
            setPaginationStatus(false); // Set loader to true when fetching starts
            let url = `${Application.api_url}/api/v2/company_documents/starred_docs`;
            if (id) {
                url = `${Application.api_url}/api/v2/company_documents/${id}`;
            }
            let resultObj = await axios.get(url, {
                params: {
                    page: pageNo ? pageNo : page,
                    limit: limit,
                    search_text: name,
                    doc_type_cont: searchByType,
                    user_full_name_cont: searchByCreatedBy,
                    updated_at_eq: searchByUpdatedAt,
                    sort: sortedBy + " " + `${sorting ? "asc" : "desc"}`,
                    sort_by_starred: `${sorting ? "asc" : "desc"}`,
                },
            });
            if (resultObj.status === 200) {
                let details = resultObj.data;
                if (id) {
                    setPageDetails(details ? details.data.children : []);
                } else {
                    setPageDetails(details ? details.data : []);
                }
                setPageCount(details && details.metadata ? details.metadata.pages : 0);
                setPaginationStatus(true); // Set loader to false when fetching completes
            }
        } catch (err) {
            console.log("error==>", err);
        }
    };

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const deleteFolder = async (id) => {
        try {
            let resultObj = await axios.delete(`${Application.api_url}/api/v2/company_documents/${id}`);
            if (resultObj.data.success) {
                toast.success("Deleted Successfully");
                getPageDetails(breadcrumb[breadcrumb.length - 1].id);
            }
        } catch (err) {
            console.log("error==>", err);
        }
    };

    const changeRoot = async (data) => {
        setPage(1);
        setPaginationStatus(false); // Set loader to true when fetching starts
        setBreadcrumb([...breadcrumb, ...[{ name: data.name, id: data.slug }]]);
        getPageDetails(data.slug);
    };

    const hanldeClick = (id) => {
        setPaginationStatus(false); // Set loader to true when fetching starts
        if (id) {
            const index = breadcrumb.findIndex((item) => item.slug === id);
            const filteredValues = breadcrumb.slice(0, index + 1);
            setBreadcrumb(filteredValues);
            getPageDetails(id);
        } else {
            setBreadcrumb([{ name: "Home", id: "" }]);
            getPageDetails();
        }
    };

    const editRecord = (data) => {
        setOpenFolder(true);
        setSelectedData(data);
    };

    const hanldeShorting = (sort, field) => {
        setSorting(sort);
        setSortedBy(field);
    };

    const hanldeStared = async (stared, id) => {
        try {
            let resultObj = await axios.patch(`${Application.api_url}/api/v2/company_documents/${id}/${stared ? "unstar" : "star"}`);
            if (resultObj.data.success) {
                toast.success(`${stared ? "Removed from favorites" : "Successfully added to favorites"}`);
                getPageDetails(breadcrumb[breadcrumb.length - 1].id);
            }
        } catch (err) {
            console.log("error==>", err);
        }
    };

    const hanldeSearch = (name, value) => {
        switch (name) {
            case "name":
                setSearchByName(value);
                break;
            case "type":
                setSearchByType(value);
                break;
            case "created_by":
                setSearchByCreatedBy(value);
                break;
            case "updated_at":
                setSearchByUpdatedAt(value);
                break;
            default:
                break;
        }
    };

    const clearFilter = () => {
        setSearchByName("");
        setSearchByType("");
        setSearchByCreatedBy("");
        setSearchByUpdatedAt("");
    };

    const handleClosePopup = (data) => {
        setOpenFolder(false);
        data && getPageDetails(breadcrumb[breadcrumb.length - 1].id);
    };

    const findAsset = (isAsset) => {
        console.log("Asset found: ", isAsset);
    };

    return (
        <>
            <div className="card asset-tab-landing">
                <div className="card-body">
                    <div className="tab-content">
                        <div id="active-risk-assessment" className="tab-pane fade in active active-risk-assessment company-docs">
                            <div className="nestedTable pf-table">
                                <div className="fields-setting">
                                    <div className="asset-active-filters">
                                        <h3>Starred Documents List</h3>
                                    </div>
                                </div>
                                <Divider />
                                <div className="fields-setting">
                                    <div className="asset-active-filters">
                                        <Breadcrumbs separator={">"} aria-label="breadcrumb">
                                            {breadcrumb.map((val, index) => (
                                                <Link underline="hover" key={index} color="inherit" onClick={() => hanldeClick(val.id)} className="breadcrumbs-link">
                                                    <img src={Folder} alt="" className="folder-icon" />{val.name}
                                                </Link>
                                            ))}
                                        </Breadcrumbs>
                                    </div>
                                    <div className="bulk-action">
                                        <div className="view-change-switch">
                                            <div>
                                                <img src={List} alt="" className={viewStyle === "list" ? "active" : "cursor-pointer"} onClick={() => setViewStyle("list")} />
                                            </div>
                                            <div>
                                                <img src={Card} alt="" className={viewStyle === "card" ? "active" : "cursor-pointer"} onClick={() => setViewStyle("card")} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {paginationStatus ? (
                                    <>
                                        {viewStyle === "list" ? (
                                            <>
                                                <div className="fields-setting">
                                                    <div className="asset-active-filters">
                                                        Active Filters :
                                                        <span className="active-filters">
                                                            <span>Name :</span>
                                                            <span className="selected">{name ? name : "All"}</span>
                                                            <span className={name ? "clr-selected" : "clr-selected hidden"} onClick={() => setSearchByName("")}>X</span>
                                                        </span>
                                                        <span className="active-filters">
                                                            <span>Type :</span>
                                                            <span className="selected">{searchByType ? searchByType : "All"}</span>
                                                            <span className={searchByType ? "clr-selected" : "clr-selected hidden"} onClick={() => setSearchByType("")}>X</span>
                                                        </span>
                                                        <span className="active-filters">
                                                            <span>Created By :</span>
                                                            <span className="selected">{searchByCreatedBy ? searchByCreatedBy : "All"}</span>
                                                            <span className={searchByCreatedBy ? "clr-selected" : "clr-selected hidden"} onClick={() => setSearchByCreatedBy("")}>X</span>
                                                        </span>
                                                        <span className="active-filters">
                                                            <span>Updated At :</span>
                                                            <span className="selected">{searchByUpdatedAt ? searchByUpdatedAt : "All"}</span>
                                                            <span className={searchByUpdatedAt ? "clr-selected" : "clr-selected hidden"} onClick={() => setSearchByUpdatedAt("")}>X</span>
                                                        </span>
                                                        <span className="active-filters-clear" onClick={clearFilter}>
                                                            Clear All
                                                        </span>
                                                    </div>
                                                </div>
                                                <ListView
                                                    isStarred={true}
                                                    pageDetails={pageDetails}
                                                    deleteFolder={deleteFolder}
                                                    changeDirectory={changeRoot}
                                                    edit={editRecord}
                                                    changeSorting={hanldeShorting}
                                                    stared={hanldeStared}
                                                    searching={hanldeSearch}
                                                    searchByName={name}
                                                    searchByType={searchByType}
                                                    searchByCreatedBy={searchByCreatedBy}
                                                    searchByUpdatedAt={searchByUpdatedAt}
                                                    findAsset={findAsset} // Pass the function here
                                                />
                                            </>
                                        ) : (
                                            <CardView
                                                pageDetails={pageDetails}
                                                deleteFolder={deleteFolder}
                                                changeDirectory={changeRoot}
                                                edit={editRecord}
                                                stared={hanldeStared}
                                                findAsset={findAsset} // Pass the function here
                                            />
                                        )}
                                    </>
                                ) : (
                                    <div className="new-loader">
                                        <img src={loader} alt="Loading..." />
                                    </div>
                                )}
                                <div className="page-limits">
                                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                        <Select
                                            labelId="demo-select-small"
                                            id="demo-select-small"
                                            value={limit}
                                            onChange={(e) => {
                                                setPage(1);
                                                setPaginationStatus(false);
                                                setLimit(e.target.value);
                                            }}
                                        >
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={20}>20</MenuItem>
                                            <MenuItem value={25}>25</MenuItem>
                                            <MenuItem value={30}>30</MenuItem>
                                            <MenuItem value={35}>35</MenuItem>
                                            <MenuItem value={50}>50</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {paginationStatus && (
                                        <Pagination
                                            count={pageCount}
                                            variant="outlined"
                                            shape="rounded"
                                            onChange={handleChangePage}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {openFolder &&
                <CreateFolder show={openFolder} details={selectedData} closepopup={handleClosePopup} />
            }
        </>
    );
}

export default StaredDoc;