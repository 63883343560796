import React, { useState } from 'react';
import UsersList from './usersList';
import SubTabs from '../../../shared/components/subTabs';
import useFilterChange from '../../../shared/components/useFilterChange';
import { ButtonCreate, ButtonWithLinkIcon } from '../../../shared/components/table';
import ArchiveUsersList from './usersArchiveList';
import ExportCSV from '../../../shared/components/exportCsv';
import NewUserCreate from './newUserCreate';
import NewUserEdit from './newUserEdit';
import useFetch from '../../../shared/components/userFetch';
import { showToast } from '../../../shared/components/showNotification';
import { connect } from 'react-redux';


const subTabs = {
    ulist: [
        {
            label: 'User List',
            value: 'user_list'
        },
        {
            label: 'Archived',
            value: 'arch'
        }
    ]
}


const UsersListWrapper = ({title, active, pageCount}) => {

    const initialState = {
        page: 1,
        limit: 20
    }
    const { data: baseData, isLoading: baseDataLoading, refetch: refetchBaseData } = useFetch(
        "/api/v2/entity_management/users/base_data?activated_eq=true"
      );
    const {
        permissions: {
            allow_create,
            allow_invite_link,
            allow_to_export_users
        } = {},
        invite_link_company_id,
    } = baseData || {};

    const handleCopyClick = () => {
        navigator.clipboard.writeText(window.location.origin+`/invite_user/${invite_link_company_id}`).then(() => {
            showToast({ message: "Copied!" });
          }).catch(err => console.error('Failed to copy text: ', err));
    }
    const [create, setCreate] = useState(false);
    const [edit, setEdit] = useState('');
    const { handleOnFilterChange, filterState, filterData, setFilterState, handleSort } = useFilterChange(initialState);
    const { handleOnFilterChange: handleOnFilterChangeArchive, filterState: filterStateArchive, filterData: filterDataArchive, setFilterState: setFilterStateArchive, handleSort: handleSortArchive } = useFilterChange(initialState);
    const refetch = () => {
        setFilterState({...filterState});
    }

    return (
        <>
            <SubTabs
                tabList={subTabs[active] || []}
                withTitle
                title={title}
                pageCount={pageCount.ulist}
                extras={[
                    <div style={{display: 'flex', gap: '10px'}}>
                        {allow_invite_link && <ButtonWithLinkIcon label="Invite Link" onClick={handleCopyClick}/>}
                        {allow_to_export_users && <ExportCSV url={'/api/v2/entity_management/users?activated_eq=true'} params={{
                            ...filterData
                        }} />}
                        {allow_create && <ButtonCreate label="New User" onClick={() => setCreate(true)} />}
                    </div>
                ]}>
                {(activeKey) => (
                    <>
                        {activeKey === "user_list" && (
                            <>
                                <UsersList
                                    handleOnFilterChange={handleOnFilterChange}
                                    filterData={filterData}
                                    filterState={filterState}
                                    setFilterState={setFilterState}
                                    handleSort={handleSort}
                                    initialState={initialState}
                                    setEdit={setEdit}
                                    baseData={baseData}
                                />
                            </>
                        )}
                        {activeKey === 'arch' && (
                            <>
                                <ArchiveUsersList
                                    handleOnFilterChange={handleOnFilterChangeArchive}
                                    filterData={filterDataArchive}
                                    filterState={filterStateArchive}
                                    setFilterState={setFilterStateArchive}
                                    handleSort={handleSortArchive}
                                    initialState={initialState}
                                />
                            </>
                        )}
                    </>
                )}
            </SubTabs>
            {create && <NewUserCreate open={create} setOpen={setCreate} setFilterState={setFilterState} refetch={refetchBaseData} />}
            {!!edit && <NewUserEdit edit={edit} setEdit={setEdit} refetch={refetch} />}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        pageCount: state.CommonReducer.pageCount
    };
  };
  
  export default connect(mapStateToProps)(UsersListWrapper);
