import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "../../../../config/axiosConfig";
import Application from "../../../../config/ApplicationWrapper";

export default function ViewDocs(props) {
    const { show, closepopup, details } = props;
    const [pageDetails, setPageDetails] = useState();
    const imageFormat = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png)$/i;
    const pdfFormat = /(\.pdf)$/i;

    useEffect(() => {
        async function fetchGroup() {
            const data = await axios.get(`${Application.api_url}/api/v2/company_documents/${details}`);
            if (data.status == 200 && data.data.success === true) {
                let values = data.data;
                setPageDetails(values.data);
            }
        }
        fetchGroup()
    }, [])

    const onhandleStatusClose = () => {
        setPageDetails()
        closepopup();
    };


    return (
        <Modal
            bsSize="large"
            show={show}
            onHide={(e) => {
                onhandleStatusClose();
            }}
            className="qr-modal-wrap add-task-modal pf-model docs-model"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="pf-model-header">
                        <p className="pf-model-title"> View Documents</p>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {pageDetails && pageDetails.file && pdfFormat.exec(pageDetails.name) &&
                        <iframe src={pageDetails.file.url} style={{width:"100%", height:"500px"}} frameborder="0" />
                    }
                    {pageDetails && pageDetails.file && imageFormat.exec(pageDetails.name) &&
                        <img src={pageDetails.file.url} alt="" style={{width:"100%"}} />
                    }
                </div>
            </Modal.Body>
        </Modal>
    );
}
