import React, { useEffect, useState } from "react";
import { TextField, FormHelperText } from "@mui/material";
import {
    Modal,
    FormControl as Input,
} from "react-bootstrap";
import { toast } from "react-toastify";
import Application from "../../../config/ApplicationWrapper";
import axios from "../../../config/axiosConfig";


export default function CreateTopics(props) {
    const { show, closepopup, details } = props;
    const [onSubmit, setSubmit] = useState(false);
    const [disabled, setDisable] = useState(false);
    const [values, setValues] = useState({
        title: "",
        description: "",
        expiry_value: ""
    });

    useEffect(() => {
        details && setValues(details)
    }, [show])

    const handleChange = (name, event) => {
        setValues({ ...values, [name]: event.target.value });
        setDisable(false)
        setSubmit(false);
    };


    const createOrUpdate = async () => {
        try {
            setSubmit(true);
            setDisable(true);
            if (!values.title || !values.expiry_value) {
                return false
            }

            if (details && details.identifier) {
                await axios.patch(`${Application.api_url}/api/v2/internal_training_topics/${details.identifier}`, values).then((result) => {
                    if (result.data && result.data.success === true) {
                        toast.success(`Update Successfully`)
                        closepopup(result.data)
                    }
                }).catch((error) => {
                    error.response.data.message.map((msg) => {
                        toast.error(msg)
                    });
                })
            } else {
                await axios.post(`${Application.api_url}/api/v2/internal_training_topics`, values).then((result) => {
                    if (result.data && result.data.success === true) {
                        toast.success(`Created Successfully`)
                        closepopup(result.data)
                    }
                }).catch((error) => {
                    error.response.data.message.map((msg) => {
                        toast.error(msg)
                    });
                })
            }

        } catch (err) {
            console.log("err===>", err);
        }
    };

    return (
        <Modal
            bsSize="large"
            show={show}
            onHide={(e) => {
                closepopup();
            }}
            className="create-maintanance-popup internal-sub-employee-popup"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <i className="fa fa-plus" aria-hidden="true"></i>
                    <b> {details && details.identifier ? "Update Topics" : "Add Topics"} </b>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="create-form">
                    <div className="row">
                        <div className="col-md-12">
                            <label>
                                Topic Name<text style={{ color: "red" }}>*</text>
                            </label>
                            <TextField
                                required
                                fullWidth
                                className="text-field"
                                id="outlined-required"
                                error={!values.title && onSubmit}
                                helperText={
                                    !values.title && onSubmit ? "First Name is required" : ""
                                }
                                value={values.title}
                                onChange={(e) => {
                                    handleChange("title", e);
                                }}
                            />
                        </div>
                        <div className="col-md-12">
                            <label>
                                Description
                            </label>
                            <textarea
                                fullWidth
                                className="textarea"
                                id="outlined-required"
                                value={values.description}
                                onChange={(e) => {
                                    handleChange("description", e);
                                }}
                            />
                        </div>
                        <div className="col-md-12">
                            <label>
                                Expiration Date<text style={{ color: "red" }}>*</text>
                            </label>
                            <div className="topics-expiration-date">
                                <div className="form-check form-check-inline no_expiration">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="inlineRadioOptions"
                                        id="no_expiration"
                                        value="no_expiration"
                                        checked={values.expiry_value === "no_expiration"}
                                        onChange={(e) => {
                                            handleChange("expiry_value", e);
                                        }}
                                    />
                                    <label className="form-check-label no_expiration" htmlFor="no_expiration">No Expiration</label>
                                </div>
                                <div className="form-check form-check-inline five_year">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="inlineRadioOptions"
                                        id="five_year"
                                        value="five_year"
                                        checked={values.expiry_value === "five_year"}
                                        onChange={(e) => {
                                            handleChange("expiry_value", e);
                                        }}
                                    />
                                    <label className="form-check-label five_year" htmlFor="five_year">5 Year</label>
                                </div>
                                <div className="form-check form-check-inline four_year">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="inlineRadioOptions"
                                        id="four_year"
                                        value="four_year"
                                        checked={values.expiry_value === "four_year"}
                                        onChange={(e) => {
                                            handleChange("expiry_value", e);
                                        }}
                                    />
                                    <label className="form-check-label four_year" htmlFor="four_year">4 Year</label>
                                </div>
                                <div className="form-check form-check-inline three_year">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="inlineRadioOptions"
                                        id="three_year"
                                        value="three_year"
                                        checked={values.expiry_value === "three_year"}
                                        onChange={(e) => {
                                            handleChange("expiry_value", e);
                                        }}
                                    />
                                    <label className="form-check-label three_year" htmlFor="three_year">3 Year</label>
                                </div>
                                <div className="form-check form-check-inline two_year">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="inlineRadioOptions"
                                        id="two_year"
                                        value="two_year"
                                        checked={values.expiry_value === "two_year"}
                                        onChange={(e) => {
                                            handleChange("expiry_value", e);
                                        }}
                                    />
                                    <label className="form-check-label two_year" htmlFor="two_year">2 Year</label>
                                </div>
                                <div className="form-check form-check-inline one_year">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="inlineRadioOptions"
                                        id="one_year"
                                        value="one_year"
                                        checked={values.expiry_value === "one_year"}
                                        onChange={(e) => {
                                            handleChange("expiry_value", e);
                                        }}
                                    />
                                    <label className="form-check-label one_year" htmlFor="one_year">1 Year</label>
                                </div>
                            </div>
                            <FormHelperText
                                className="inputErrorMsg"
                                error={!values.expiry_value && onSubmit}
                            >
                                {!values.expiry_value && onSubmit
                                    ? "Expiration Date is required"
                                    : ""}
                            </FormHelperText>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn button cancel-btn"
                    onClick={() => {
                        closepopup()
                    }}
                >
                    Cancel
                </button>
                <button
                    className="btn btn-primary"
                    disabled={disabled}
                    onClick={(event) => {
                        createOrUpdate();
                    }}
                >
                    {details && details.identifier ? "Update" : "Create"}
                </button>
            </Modal.Footer>
        </Modal>
    );
}
