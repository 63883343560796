import React from 'react';
import { Route, Router, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import cx from 'classnames';
import { setMobileNavVisibility } from '../../reducers/Layout';
import { withRouter } from 'react-router-dom';
import '../../assets/styles/themes.scss';
import PropTypes from 'prop-types';
import UserManagement from '../shared/UserManagement';
import Select from 'react-select';
import axios, { post } from 'axios';

class NewUser extends React.Component {
  constructor(props) {
    super(props);
    const initialState = {
        fields: {password:'', email:'',first_name:'',last_name:'',username:'', employee_id: ''},
        errors: {},
        roles_list: [],
        company_list: [],
        selectedOption: '',
        role_selected: '',
        company_selected: '',
        group_selected: [],
        is_admin: '',
        company_name: '',
        user_role: '',
        approver: '',
        groups: []
    };
    this.state = initialState
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleUserSelect = this.handleUserSelect.bind(this);
    this.handleCompanySelect = this.handleCompanySelect.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.resetfilter = this.resetfilter.bind(this);
  }

  handleSelect = (selectedOption) => {
    this.setState({ selectedOption });
    console.log(`Selected: ${selectedOption.label}`);
  }

  handleUserSelect = (role_selected) => {
      this.setState({ role_selected });
  }

  handleGroupSelect = (group_selected) => {
    let selected_values = group_selected.map(a => a.value);
    this.setState({ group_selected: selected_values });
  }

  handleCompanySelect = (company_selected) => {
     this.setState({ company_selected });
     this.loadGroupsData(company_selected.value)
  }

  handleApproverSelect = (approver) => {
    this.setState({ approver: approver.value });
  }

  componentDidMount(){
    let that = this
    axios.get('/users/get_user_roles',{
    })
    .then(function(response){
      if(response.data){
          var data = response.data
          that.setState({
              roles_list: data.user_roles,
              company_list: data.user_companies,
              is_admin: data.is_admin
          });
      }
    })
    .catch(function(error){
        console.log(error);
    })
  }

  handleValidation(){
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if(!fields["first_name"]){
        formIsValid = false;
        errors["first_name"] = "Required";
    }

    if(!fields["last_name"]){
        formIsValid = false;
        errors["last_name"] = "Required";
    }

    if(!fields["username"]){
        formIsValid = false;
        errors["username"] = "Required";
    }

    if(!this.state.role_selected){
        formIsValid = false;
        errors["role_id"] = "Required";
    }

    if(!this.state.company_selected){
        formIsValid = false;
        errors["user_company_id"] = "Required";
    }

    // if(!fields["email"]){
    //     formIsValid = false;
    //     errors["email"] = "Email cannot be empty";
    // }

    if(!fields["employee_id"]){
        formIsValid = false;
        errors["employee_id"] = "Required";
    }

    if(fields["email"] !== ''){
      let lastAtPos = fields["email"].lastIndexOf('@');
      let lastDotPos = fields["email"].lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
          formIsValid = false;
          errors["email"] = "Email is not valid";
      }
    }
    this.setState({errors: errors});
    return formIsValid;
  }

  handleChange(field, e){
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({fields});
  }

  loadGroupsData = async (company) => {
    const response = await axios.get('/users/groups_data', { params: { company_id: company } });
    let data = response.data
    this.setState({ groups: data });
  }

  handleSubmit(e) {
    e.preventDefault();
    let errors = {};
    var that = this;
    if(this.handleValidation()){
      $(".loading").show()
      const url = '/users/new_user';
      var user = {
                    email: this.state.fields['email'],
                    first_name: this.state.fields['first_name'],
                    last_name: this.state.fields['last_name'],
                    username: this.state.fields['username'],
                    employee_id: this.state.fields['employee_id'],
                    role_id: this.state.role_selected.value,
                    company_id: this.state.company_selected.value,
                    groups: this.state.group_selected,
                    approver: this.state.approver,
                    password: this.state.fields['password']
                 }
      post(url, user)
        .then(function(response) {
            $(".loading").hide()
            $('#new_user').append('<span class="new_user_message">User has been successfully created.</span>')
            $('.new_user_message').fadeOut(15000);
        })
        .catch(function(error) {
            $(".loading").hide()
            errors["invalid_user"] = error.response.data.error;
            that.setState({errors: errors});
        });
    }else{
        console.log("Form has errors.")
    }
  }

  resetfilter(){
    let selectedOption, role_selected, company_selected  = this.state;
   var fields = {email:'',first_name:'',last_name:'',username:'',employee_id:''}
    selectedOption, role_selected, company_selected= ''
    this.setState({fields,selectedOption,company_selected,role_selected});
  }

  renderRolesData() {
  return this.state.roles.map((roles,key) => {
    return (
        <option key={key} value={roles.id}>{roles.name}</option>
        );
    })
  }

  removeReadOnly(obj) {
  }

  renderCompanyData(companies, value="") {
    console.log(value)
    let optionItems = companies.map((company,key) => {
      return (
        <option key={key} value={company.id}>{company.name}</option>
      );
      })
      if (value){
        optionItems.unshift(<option key="" value="">Please Select</option>);
      }
      return optionItems;
  }

  render() {
    const { selectedOption, role_selected, company_selected,group_selected, roles_list, approver, is_admin, company_list, groups } = this.state;

    return (
      <div className="content">
        <div className="sub-navbar sub-navbar__header-breadcrumbs">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 sub-navbar-column">
                        <div className="sub-navbar-header"></div>
                        <ol className="breadcrumb navbar-text navbar-right no-bg"></ol>
                    </div>
                </div>
            </div>
        </div>

        <div className="sub-navbar sub-navbar__header-breadcrumbs">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 sub-navbar-column">
                        <ol className="breadcrumb navbar-text navbar-left no-bg">
                            <li className="current-parent">
                                <NavLink id="home-link-icon" to="/" className="current-parent" role="button"><i className="fa fa-fw fa-home"></i></NavLink>
                            </li>
                            <li className="active">
                                <NavLink id="dashboard-link" to="/" className="current-parent" role="button">Dashboard</NavLink>
                            </li>
                            <li className="active">New User</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div className="container">
            <div className="row">
                <UserManagement source= "add-users" />
                <div className="col-lg-10 admin-content">
                    <div className="panel panel-default   b-a-2 no-bg b-gray-dark">
                        <div className="panel-heading">
                            <h4 className="panel-title">New User Profile</h4>
                        </div>

                        <div className="panel-body">
                            <div id="new_user"/>
                            <span style={{color: "red"}}>{this.state.errors["invalid_user"]}</span>
                            <form className="form-horizontal" autoComplete="new-password">
                                <div className="form-group">
                                    <label className="col-sm-3 control-label">First Name<span className="text-danger">*</span></label>
                                    <div className="col-sm-6">
                                        <input className="form-control" ref="first_name" id="first_name" maxLength="50" placeholder="" onChange={this.handleChange.bind(this, "first_name")} value={this.state.fields["first_name"]}/>
                                        <span style={{color: "red"}}>{this.state.errors["first_name"]}</span>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="col-sm-3 control-label"> Last Name<span className="text-danger">*</span></label>
                                    <div className="col-sm-6">
                                        <input className="form-control" ref="last_name" id="last_name" maxLength="50" placeholder="" onChange={this.handleChange.bind(this, "last_name")} value={this.state.fields["last_name"]}/>
                                        <span style={{color: "red"}}>{this.state.errors["last_name"]}</span>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="col-sm-3 control-label">User Name<span className="text-danger">*</span></label>
                                    <div className="col-sm-6">
                                        <input className="form-control" style={{backgroundColor: "#fff"}}  ref="username" id="username" maxLength="50" readOnly="readonly" onFocus={function(e){ e.target.removeAttribute("readOnly") }} placeholder="" onChange={this.handleChange.bind(this, "username")} value={this.state.fields["username"]}/>
                                        <span style={{color: "red"}}>{this.state.errors["username"]}</span>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="col-sm-3 control-label">Email<span className="text-danger"></span></label>
                                    <div className="col-sm-6">
                                        <input className="form-control" ref="email" id="email" maxLength="50" placeholder="" onChange={this.handleChange.bind(this, "email")} value={this.state.fields["email"]}/>
                                        <span style={{color: "red"}}>{this.state.errors["email"]}</span>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="col-sm-3 control-label">Password<span className="text-danger"></span></label>
                                    <div className="col-sm-6">
                                        <input type="password" className="form-control" autocomplete="none" ref="password" id="password" maxLength="15" placeholder="" onChange={this.handleChange.bind(this, "password")} value={this.state.fields["password"]}/>
                                        <span style={{color: "red"}}>{this.state.errors["password"]}</span>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="col-sm-3 control-label">Employee Id<span className="text-danger">*</span></label>
                                    <div className="col-sm-6">
                                        <input className="form-control" ref="employee" id="employee_id" maxLength="50" placeholder="" onChange={this.handleChange.bind(this, "employee_id")} value={this.state.fields["employee_id"]}/>
                                        <span style={{color: "red"}}>{this.state.errors["employee_id"]}</span>
                                    </div>
                                </div>

                               <div className="form-group">
                                    <label className="col-sm-3 control-label">Company Name<span className="text-danger">*</span></label>
                                    <div className="col-sm-6">
                                        
                                        <Select
                                        name="form-field-name"
                                        value={company_selected}
                                        onChange={this.handleCompanySelect}
                                        options={company_list}
                                        id="user_company_id"
                                        />
                                        <span style={{color: "red"}}>{this.state.errors["user_company_id"]}</span>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="col-sm-3 control-label">User Role<span className="text-danger">*</span></label>
                                    <div className="col-sm-6">
                                        
                                        <Select
                                        name="form-field-name"
                                        value={role_selected}
                                        onChange={this.handleUserSelect}
                                        options={roles_list}
                                        id="role_id"
                                        />
                                        <span style={{color: "red"}}>{this.state.errors["role_id"]}</span>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="col-sm-3 control-label">Approver</label>
                                    <div className="col-sm-6">
                                        <Select
                                            name="form-field-name"
                                            value={approver}
                                            onChange={this.handleApproverSelect}
                                            options={[{value:"Yes", label:"Yes"}, {value:"No", label:"No"}]}
                                            id="user_approver"
                                          />
                                    </div>
                                </div>
                              <div className="form-group">
                                <label className="col-sm-3 control-label">Assign Group</label>
                                  <div className="col-sm-6">
                                      <Select
                                          name="form-field-name"
                                          value={group_selected}
                                          onChange={this.handleGroupSelect}
                                          options={groups}
                                          multi={true}
                                          clearable={false}
                                          id="user_groups" />
                                  </div>
                              </div>
                              <div className="form">
                                <div className="row" >
                                  <div className="col-sm-3">

                                  </div>
                                  <div className="col-sm-6 text-right">
                                    <button type="button" id="new-user-reset" className="btn btn-primary reset-btn text-right" onClick={this.resetfilter.bind()}>Reset</button>
                                    <button type="button" id="new-user-save" className="btn btn-primary text-right" onClick={this.handleSubmit}>Save</button>
                                  </div>
                                 </div>
                              </div>

                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    )
  }
}

export default NewUser;
