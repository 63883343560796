import React  from 'react'
import {Table} from "react-bootstrap";
import EmployeeTrainings from './EmployeeTrainings';

const EmployeesList = ({employees, onEmployeeSelect, isOpen, user_trainings, onModalClose}) => {
    const renderUsers = employees.map((data,index) =>
    {
        return (
            <tr key={index} onClick={() => onEmployeeSelect({user_id: data.id })} className="cursor-pointer">
                <td>{data.name}</td>
                <td>{data.role}</td>
            </tr>
        )
    });

    const renderContent = (employees) => {
        if (employees.length == 0){
            return <h2 className='align-center'> No Employees were assigned to the shared projects</h2>
        }
        return <Table responsive striped bordered hover>
                    <thead>
                    <tr>
                        <th>name</th>
                        <th>role</th>
                    </tr>

                    </thead>
                    <tbody>
                        {renderUsers}
                    </tbody>
              </Table>
    }

    return (
        <div className="shared-forms">
            <h3>
                <i className="fa fa-user-o" aria-hidden="true"></i>
                employees list
            </h3>

            {renderContent(employees)}
            <EmployeeTrainings user_trainings={user_trainings} show={isOpen} onModalClose={onModalClose} />
        </div>
    )
}

export default EmployeesList;
