import { Alert, Snackbar } from "@mui/material";
import React from "react";
import { toast } from "react-toastify";

export const ShowNotification = ({
  open,
  onClose,
  severity = "success",
  message = "",
}) => {
  return (
    <Snackbar
    style={{ top: '75px'}}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        variant="filled"
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export const showToast = ({type = 'success', message = 'Successfully Updated'}) => {
  return toast(message, {
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    theme: "light",
    type
  });
}
