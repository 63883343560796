import React from "react";


export const ComponentToPrint = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} style={{ textAlign: "center" }} className="print-qr-body">
      <p
        style={{ fontWeight: "500", fontSize: "37px", color: "#080808" }}
      >
        {props.pageData.name}
      </p>
      <img alt="" src={props.pageData ? props.pageData.qr_url : ""} style={{ width: "100%" }} />
      <div style={{ textAlign: "start", marginLeft:"50px", marginBottom:"50px" }}>
        <div>
          <span style={{ fontWeight: "500", fontSize: "20px", color: "#080808" }}>Company Name: </span> <span style={{ fontWeight: "500", fontSize: "16px", color: "#909090" }}>{props.pageData ? props.pageData.name : ""}</span>
        </div>
        <div style={{ textAlign: "start" }}>
          <span style={{ fontWeight: "500", fontSize: "20px", color: "#080808" }}>Admin Name: </span> <span style={{ fontWeight: "500", fontSize: "16px", color: "#909090" }}>{props.pageData.admin ? props.pageData.admin.full_name : ""}</span>
        </div>
        <div style={{ textAlign: "start" }}>
          <span style={{ fontWeight: "500", fontSize: "20px", color: "#080808" }}>Admin Email: </span> <span style={{ fontWeight: "500", fontSize: "16px", color: "#909090" }}>{props.pageData.admin ? props.pageData.admin.email : ""}</span>
        </div>
      </div>
      <span style={{ fontWeight: "500", fontSize: "20px", color: "#909090",textAlign: "start" }}>QR Code Link: - {props.pageData.invite_link}</span>
      <p style={{ fontWeight: "500", fontSize: "20px", color: "#080808" }}>Scanning this will add you to this .</p>
    </div>
  );
});