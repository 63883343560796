import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Typography from "@mui/material/Typography";
import { useReactToPrint } from 'react-to-print';
import { ComponentToPrint } from './ComponentToPrint'; 

export default function QrPopup(props) {
  const { show, closeup, details } = props;
  const [qrDetails, setQrDetails] = useState("")

  const handleStatusClose = () => {
    closeup();
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Modal
      show={show}
      onHide={(e) => {
        handleStatusClose();
      }}
      className="qr-modal-wrap add-task-modal signature-popup"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <i
            class="fa fa-qrcode"
            style={{
              fontSize: "19px",
              marginRight: "5px",
            }}
          ></i>
          <b>QR Code</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="qr-info-item">
          <div className="qr-asset-info">
            <Typography variant="body2" gutterBottom className="qr-info-title">
              QR Information
            </Typography>
            <div className="qr-details">
              <Typography gutterBottom variant="subtitle1" component="div" className="qr-created-by">
                Inventory Name:

                <label className="status-label">
                  {details && details.inventory_name}
                </label>
              </Typography> 
            </div>
          </div>
        </div>
        <div id="qr-scan-img">
          <div style={{ textAlign: "center" }}>
            <img src={details &&  details.url} alt="qr" />
          </div> 
        </div>
      </Modal.Body>
      <Modal.Footer>  
        <button
          className="btn btn-secondary"
          onClick={() => { handlePrint() }}
        >
          <img style={{ width: "auto", padding: 0 }} />
          <span className="btn-text">Print</span>
        </button>
      </Modal.Footer>
      {details && details.url &&
        <ComponentToPrint ref={componentRef} pageData={details} />
      }
    </Modal>
  );
}
