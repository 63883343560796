import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";

import axios from "@config/axiosConfig";
import Application from "@config/ApplicationWrapper";

import Loader from "@components/MobileViewLoader";
import loader from "@assets/images/newloader.gif";

import ArrowUp from "@assets/fonts/ArrowRight.svg";
import Searchbar from "@assets/images/searchbar.svg";
import InfiniteScroll from 'react-infinite-scroll-component';

import Folder from "@assets/fonts/Folder.svg";
import FolderExpired from "@assets/fonts/FolderExpired.svg";

import PNGIcon from "@assets/fonts/png.png";
import JPGIcon from "@assets/fonts/jpg.png";
import MP4Icon from "@assets/fonts/mp4.png";
import MP3Icon from "@assets/fonts/mp3.png";
import ZIPIcon from "@assets/fonts/zip.png";
import PPTIcon from "@assets/fonts/ppt.png";
import XLSIcon from "@assets/fonts/xls.png";
import PDFIcon from "@assets/fonts/pdf.png";
import DOCSIcon from "@assets/fonts/docs.png";
import XLSXIcon from "@assets/fonts/xlsx1.png";

import CreateFolder from "./create";
import UploadFiles from "./upload";

const Docs = ({ history, match }) => {
    const template_id = 'dbcb37bc-715e-4b7b-9610-a53d16c85e7b';
    let userDetails = localStorage.getItem("user");
    userDetails = JSON.parse(userDetails);

    const mounted = useRef(false);
    const [pageDetails, setPageDetails] = useState([]);
    const [search, setSearch] = useState();
    const [pageNumber, setPageNumber] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [openFolder, setOpenFolder] = useState(false);
    const [selectedData, setSelectedData] = useState();
    const [parentId, setParentId] = useState();
    const [openUploadFile, setOpenUploadFile] = useState(false);
    const [breadcrumb, setBreadcrumb] = useState([{
        name: "Home",
        id: ""
    }])

    useEffect(() => {
        getPageDetails()
    }, [])


    useEffect(() => {
        const redirectTimer = setTimeout(() => {
            mounted.current && getPageDetails("", 1)
            mounted.current = true;
        }, 2000);

        return () => clearTimeout(redirectTimer);
    }, [search]);

    const getPageDetails = async (id, page) => {
        try {
            window.scrollTo(0, 0);
            let url = `${Application.api_url}/api/v2/company_documents`;
            if (id) {
                url = `${Application.api_url}/api/v2/company_documents/${id}`;
            }
            let resultObj = await axios.get(url, {
                params: {
                    page: page ? page : pageNumber,
                    limit: 20,
                    search_text: search,
                },
            });
            if (resultObj.data && resultObj.data.success == true) {
                let details = resultObj.data;
                let finalObj;
                if (id) {
                    finalObj = details ? details.data.children : []
                } else {
                    finalObj = details ? details.data : []
                }

                if (!search && !id && !page) {
                    setPageDetails(prevItems => [...prevItems, ...finalObj])
                    setPageNumber(pageNumber + 1);
                    setPageCount(
                        details && details.metadata ? details.metadata.pages : 0
                    );
                } else {
                    setPageDetails(finalObj)
                }
                setLoading(false)
            }
        } catch (err) {
            console.log("error==>", err);
        }
    };

    const goBack = () => {
        history.push('/internalsub_dashboard');
    }

    const handleClosePopup = (data) => {
        setOpenFolder(false);
        setOpenUploadFile(false);
        setSelectedData("") 
        data && getPageDetails(breadcrumb[breadcrumb.length - 1].id, 1);
    }

    const fileIcons = {
        Folder: Folder,
        FolderExpired: FolderExpired,
        png: PNGIcon,
        jpg: JPGIcon,
        jpeg: JPGIcon,
        pdf: PDFIcon,
        doc: DOCSIcon,
        docx: DOCSIcon,
        xlsx: XLSXIcon,
        xls: XLSIcon,
        csv: XLSIcon,
        mp4: MP4Icon,
        mp3: MP3Icon,
        zip: ZIPIcon,
        ppt: PPTIcon,
        pptx: PPTIcon,
    };

    const FileType = ({ item }) => {
        let fileExtension = item.is_directory ? 'Folder' : item.name.split('.').pop().toLowerCase();
        let fileIcon = fileIcons[fileExtension] || DOCSIcon;
        if(item.is_directory && item.expired) fileIcon = fileIcons['FolderExpired']

        return (
            <img src={fileIcon} alt="image" className="file-icon" style={{ width: "40px", marginRight: "10px" }}/>
        );
    }

    const deleteFolder = async (id) => {
        try {
            let resultObj = await axios.delete(`${Application.api_url}/api/v2/company_documents/${id}`);
            if (resultObj.data.success) {
                console.log("check")
                toast.success("Deleted Successfully");
                getPageDetails(breadcrumb[breadcrumb.length - 1].id, 1);
            }
        } catch (err) {
            console.log("error==>", err);
        }
    }

    const changeiDirectory = async (data) => {
        setLoading(true)
        setParentId(data.int_id)
        setBreadcrumb([...breadcrumb, ...[{ name: data.name, id: data.slug }]])
        getPageDetails(data.slug, 1)
    }

    const hanldeClick = async (val) => {
        if (val.is_directory) {
            changeiDirectory(val)
        } else {
            const data = await axios.get(`${Application.api_url}/api/v2/company_documents/${val.slug}`);
            if (data.status == 200 && data.data.success === true) {
                let values = data.data.data;
                if (values.file && values.file.url) {
                    location.href = values.file.url;
                }
            }
        }
    }

    const changeRoot = (id) => {
        if (id) {
            const index = breadcrumb.findIndex((item) => item.slug == id);
            const filteredValues = breadcrumb.slice(0, index);
            setBreadcrumb(filteredValues)
            getPageDetails(id, 1);
        } else {
            setParentId("")
            setBreadcrumb([{
                name: "Home",
                id: ""
            }])
            getPageDetails("", 1);
        }
    }

    const editRecord = (data) => {
        setOpenFolder(true);
        setSelectedData(data);
    }


    return (
        <>
            <Loader loading={loading} />
            {!loading &&
                <div className="internal-sub-docs">
                    <div className="title">
                        <div><img src={ArrowUp} alt="back-arrow" className="back-arrow" onClick={(e) => { goBack() }} /></div>
                        <div>
                            <span className="role">Documents</span>
                        </div>
                    </div>
                    <div className="select-groups">
                        <div className="search-bar">
                            <input className="search-input" placeholder="Search" onChange={(e) => { setSearch(e.target.value) }} />
                            <img src={Searchbar} alt="Searchbar" className="search-icon" />
                        </div>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                {breadcrumb.map((val, index) => (
                                    Number(breadcrumb.length - 1) > index ? (
                                        <li class="breadcrumb-item" onClick={(e) => {
                                            changeRoot(val.id)
                                        }}><a>{val.name}</a></li>
                                    ) : (
                                        <li>{val.name}</li>
                                    )
                                )
                                )}
                            </ol>
                        </nav>
                        <div className="groups-list">
                            <InfiniteScroll
                                dataLength={pageDetails.length}
                                next={(e) => { getPageDetails(userDetails) }}
                                hasMore={pageCount >= pageNumber}
                                loader={
                                    <div className="new-loader mobile-view" >
                                        <img src={loader} alt="" />
                                    </div >
                                }
                            >
                                {
                                    pageDetails.length > 0 &&
                                    pageDetails.map((val, index) => (
                                        <div className="groups-details">
                                            
                                            <div className={`file-view ${val.expired ? 'folder-expired' : ''}`} onClick={(e) => { hanldeClick(val) }}>
                                                <div>
                                                    <FileType item={val} />
                                                </div>
                                                <div>
                                                    <label className="filename" htmlFor="one_year">{val.name}</label>
                                                    <label className="createdAt" htmlFor="one_year">{val.created_at}</label>
                                                </div>
                                            </div>
                                            <p className="action-list">
                                                <a
                                                    aria-expanded="false"
                                                    aria-haspopup="true"
                                                    class="btn dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    role="button"
                                                >
                                                    <i class="fa fa-ellipsis-v"></i>
                                                </a>
                                                <ul aria-labelledby="dropdownMenuLink" class="dropdown-menu dropdown-menu-right" style={{ width: "125px", left: "25px" }}>
                                                    <li>
                                                        <a
                                                            class="archive_group_invites"
                                                            data-toggle="tooltip"
                                                            title="Edit"
                                                            onClick={(e) => {
                                                                editRecord(val)
                                                            }}
                                                        >
                                                            <div class="mb-menu">
                                                                <div>
                                                                    <i
                                                                        class="fa fa-pencil-square-o"
                                                                        style={{
                                                                            fontSize: "16px",
                                                                            cursor: "pointer"
                                                                        }}
                                                                    ></i>
                                                                </div>
                                                                <div
                                                                    class=""
                                                                >
                                                                    Edit
                                                                </div>
                                                            </div>
                                                        </a>
                                                        <a
                                                            class="archive_group_invites"
                                                            data-toggle="tooltip"
                                                            title="Copy"
                                                            onClick={(e) => {
                                                                navigator.clipboard.writeText(`${window.location.origin}/company_documents/${val.slug}`)
                                                            }}
                                                        >
                                                            <div class="mb-menu">
                                                                <div >
                                                                    <i
                                                                        class="fa fa-copy"
                                                                        style={{
                                                                            fontSize: "16px",
                                                                            cursor: "pointer"
                                                                        }}
                                                                    ></i>
                                                                </div>
                                                                <div>
                                                                    Copy
                                                                </div>
                                                            </div>
                                                        </a>
                                                        <a
                                                            class="archive_group_invites"
                                                            data-toggle="tooltip"
                                                            title="Archive"
                                                            onClick={(e) => {
                                                                deleteFolder(val.slug);
                                                            }}
                                                        >
                                                            <div class="mb-menu">
                                                                <div class="">
                                                                    <i
                                                                        class="fa fa-trash"
                                                                        style={{
                                                                            fontSize: "16px",
                                                                            color: "#FF3030",
                                                                            cursor: "pointer"
                                                                        }}
                                                                    ></i>
                                                                </div>
                                                                <div
                                                                    class=""
                                                                    style={{
                                                                        color: "#FF3030",
                                                                    }}
                                                                >
                                                                    Delete
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </p>
                                        </div>

                                    ))}
                            </InfiniteScroll>
                        </div>
                        <div className="add-folder">
                            <button onClick={(e) => { setOpenFolder(true) }}>Add Folder </button>
                            <button onClick={(e) => { setOpenUploadFile(true) }}>Upload </button>
                        </div>
                    </div>
                    {openFolder &&
                        <CreateFolder
                            show={openFolder}
                            details={selectedData}
                            parentId={parentId}
                            closepopup={handleClosePopup}
                        />
                    }
                    {openUploadFile &&
                        <UploadFiles
                            show={openUploadFile}
                            parentId={parentId}
                            closepopup={handleClosePopup} />
                    }
                </div>
            }
        </>
    )
}

export default withRouter(Docs);