import React from "react";
import {
    faTruckMoving,
    faFileLines,
    faCalendarDays,
    faAddressCard,
    faChartArea,
    faTriangleExclamation,
    faGraduationCap,
    faFolderTree,
    faPersonRunning,
    faUsers
} from "@fortawesome/free-solid-svg-icons";

import FeatureListItem from "./CommonComponents/FeatureListItem";

import "../../assets/styles/Home/styles.scss";
import HomepageDemoSection from "../../pages/HomePage/Components/HomepageDemoSection";
import ParallaxSection from "../../pages/HomePage/Components/ParallaxSection";

const FeaturesHomePage = () => {
    return (
        <div className="features-homepage-wrapper">
            <div className="fh-container">
                <h1 className="hero-text">
                    <span className="blue-highlight">Empower</span> your Health & Safety with SafetyLoop.
                </h1>
                <p className="sub-text">
                    SafetyLoop transforms extensive hours of Health and Safety tasks into mere seconds. Here's a compilation of the features that contribute to SafetyLoop's exceptional performance.
                </p>
            </div>
            <div className="feature-list-container">
                <FeatureListItem
                    featureURL="/features/asset_management"
                    featureIcon={faTruckMoving}
                    headerText="Asset Management"
                    subText="Seize command of your assets through SafetyLoop's all-encompassing management tools, streamlining asset tracking, maintenance, and compliance within your safety eco-system. Effortlessly manage your assets to optimize your safety protocols."
                />
                <FeatureListItem
                    featureURL="/features/form_creation"
                    featureIcon={faFileLines}
                    headerText="Form Creation"
                    subText="Streamline your safety management system effortlessly with SafetyLoop's user-friendly tools for creating and customizing forms. Craft forms to suit your specific requirements, simplifying your safety processes and enhancing compliance."
                />
                <FeatureListItem
                    featureURL="/features/form_scheduling"
                    featureIcon={faCalendarDays}
                    headerText="Form Scheduling"
                    subText="SafetyLoop simplifies the scheduling of forms for data collection and compliance monitoring, offering tailored scheduling options that seamlessly integrate with your workflow, providing ease and flexibility in your safety management processes."
                />
                <FeatureListItem
                    featureURL="/features/subcontractor_management"
                    featureIcon={faAddressCard}
                    headerText="Subcontractor Management"
                    subText="SafetyLoop streamlines subcontractor management, providing you with an efficient solution to maintain an extensive directory of your partners. This simplification enhances your ability to oversee and collaborate with subcontractors effortlessly."
                />
                <FeatureListItem
                    featureURL="/features/risk_assessment_management"
                    featureIcon={faChartArea}
                    headerText="Risk Assessment Management"
                    subText="With SafetyLoop's user-friendly risk assessment tools, identifying and managing risks becomes a seamless process. Empower your team to take proactive measures in addressing safety concerns, ensuring a safer work environment."
                />
                <FeatureListItem
                    featureURL="/features/emergency_plan_management"
                    featureIcon={faTriangleExclamation}
                    headerText="Emergency Plan Management"
                    subText="SafetyLoop grants you the capability to rapidly generate and tailor emergency plans, guaranteeing your organization's readiness for unforeseen circumstances. With our tools, you're equipped for any unexpected situation that may arise."
                />
                <FeatureListItem
                    featureURL="/features/training_certificate"
                    featureIcon={faGraduationCap}
                    headerText="Training Certificate Management"
                    subText="Simplify training certificate tracking and compliance management through SafetyLoop. This ensures your workforce remains certified and up-to-date, effectively boosting the performance of your health and safety management system."
                />
                <FeatureListItem
                    featureURL="/features/company_document_management"
                    featureIcon={faFolderTree}
                    headerText="Company Document Management"
                    subText="With SafetyLoop, effortlessly streamline the organization, storage, and management of all your company documents. Enhance efficiency in your safety management system by simplifying document access, version control, and compliance."
                />
                <FeatureListItem
                    featureURL="/features/actions"
                    featureIcon={faPersonRunning}
                    headerText="Actions"
                    subText="SafetyLoop offers user-friendly tools to streamline safety action tracking and execution, enabling swift control of your health and safety management system, making it a seamless and efficient process."
                />
                <FeatureListItem
                    featureURL="/features/user_management"
                    featureIcon={faUsers}
                    headerText="User Management"
                    subText="Simplify user management for both individual users and companies within a unified platform. SafetyLoop's robust user management tools empower you to take control of your health and safety management system, streamlining access and control across your entire safety ecosystem."
                />
            </div>
            <div className="Home-Page sl-home-wrapper" style={{ paddingTop: 0 }}>
                <ParallaxSection />
            </div>
            <div className="features-homepage-wrapper">
                <HomepageDemoSection mergeTop={false} />
            </div>
        </div>
    )
}

export default FeaturesHomePage;
