import Cookies from "js-cookie";

// userReducer.js
const initialState = {
    loading: false,
    pageData: {
        isToggleEnable: Cookies.get("collapse") === "true" ? true : false,
        ...Cookies.get("pageData")
    },
    pageCount: {
      ulist: {
        user_list: null,
        arch: null
      },
      jp: {
        job_profile: null,
        arch: null
      },
      cg: {
        comp_group: null,
        group_arch: null
      },
      agp: {
        assigned_global_project: null,
        invite_pending_accept: null,
        invite_request: null
      }
    },
    error: '',
  };
  

  // Action Types
export const SET_VALUE = 'SET_VALUE';
export const SET_PAGE_COUNT = 'SET_PAGE_COUNT';

// Action Creators
export const setValue = (value) => ({
  type: SET_VALUE,
  payload: value
});

export const setPageCount = (value) => ({
  type: SET_PAGE_COUNT,
  payload: value
});


  
  const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_VALUE:
            return {
              ...state,
              pageData: action.payload // Update the value in the store
            };
            case SET_PAGE_COUNT:
              return {
                ...state,
                pageCount: { ...state.pageCount,  ...action.payload} // Update the value in the store
              };
      default:
        return state;
    }
  };
  
  export default userReducer;
  