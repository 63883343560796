import React, { useState } from "react";
import Topics from "./Topics";  

const List = () => {
    const [active, setActive] = useState(0); 
    const [updateActive] = useState(); 

    const updateActiveCount = (count) => {
        setActive(count)
    }

    return (
        <>
            <div class="card asset-tab-landing">
                <div class="card-body">
                    <div className="d-flex align-item-center justify-content-between header-badge">
                        <div className="d-flex align-item-center">
                            <h3>Configuration</h3>
                        </div>
                    </div>
                    <ul class="nav nav-tabs">
                        <li class="active">
                            <a data-toggle="tab" href="#internal-subs-topics">
                                Training Topics
                                <span class="badge badge-light">
                                    {active}
                                </span>
                            </a>
                        </li> 
                    </ul>
                    <div class="tab-content">
                        <div
                            id="internal-subs-topics"
                            class="tab-pane fade in active active-risk-assessment"
                        >
                            <Topics
                                updateActiveCount={updateActiveCount}
                                updateActiveRecord={updateActive} 
                            />
                        </div> 
                    </div>
                </div>
            </div>
        </>
    );
}

export default List;
