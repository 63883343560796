import React, { useState, useEffect } from "react";
import { FormControl, Select, MenuItem, Pagination } from "@mui/material";
import Loader from "../../../../components/NewLoader";
import Application from "../../../../config/ApplicationWrapper";
import axios from "../../../../config/axiosConfig";

const StockHistory = ({ id }) => {
  const [pageDetails, setPageDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(30);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    try {
      axios
        .get(
          `${Application.api_url}/api/v2/inventories/${id}/inventory_stock_histories`
        )
        .then((res) => {
          setPageDetails(res.data.data);
          setPageCount(
            res.data && res.data.metadata ? res.data.metadata.pages : 0
          );
          setLoading(false);
        })
        .catch((err) => {
          console.log("err===>", err);
        });
    } catch (err) {
      console.lof("err==>", err);
    }
  }, [page, limit]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <div className="inventory-details-pg">
      <div className="title">
        <h2>Stock History</h2>
      </div>
      <div className="stock-history-table">
        <div className="page-content">
          <table>
            <thead>
              <tr>
                <th className="divider">
                  <span className="col-name">Update Date</span>
                </th>
                <th className="divider">
                  <span className="col-name">Quantity</span>
                </th>
                <th className="divider">
                  <span className="col-name">Unit Price</span>
                </th>
                <th className="divider">
                  <span className="col-name">Total Value</span>
                </th>
                <th className="divider">
                  <span className="col-name">Update By</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {!loading &&
                pageDetails.length > 0 &&
                pageDetails.map((val, index) => {
                  return (
                    <tr>
                      <td>{val.updated_at}</td>
                      <td>{val.quantity}</td>
                      <td>{val.unit_price}</td>
                      <td>{val.total_value}</td>
                      <td>{val.updated_by && val.updated_by.full_name}</td>
                    </tr>
                  );
                })}
              <Loader pageDetails={pageDetails} loading={loading} />
            </tbody>
          </table>
        </div>
        <div className="page-limits">
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={limit}
              onChange={(e) => {
                setPage(1);
                setLoading(true);
                setLimit(e.target.value);
              }}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
            </Select>
          </FormControl>
          <Pagination
            count={pageCount || 1}
            variant="outlined"
            shape="rounded"
            onChange={handleChangePage}
          />
        </div>
      </div>
    </div>
  );
};

export default StockHistory;
