import React from "react"
import '../../assets/styles/themes.scss'

const CheckoutEButton = ({ disabled, style, children = "", type, variant = "solid" }) => {
  return (
    <button
      type={type}
      className={`customButton ${variant}`}
      style={style}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default CheckoutEButton
