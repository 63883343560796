import React from "react"

const CheckoutArrowUpLineIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_5974_63466)">
        <path
          d="M21 17.5L14 10.5L7 17.5"
          stroke="#080808"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5974_63466">
          <rect
            width="28"
            height="28"
            fill="white"
            transform="matrix(-1 0 0 -1 28 28)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CheckoutArrowUpLineIcon
