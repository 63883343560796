import React from 'react'
import HeroSection from './CommonComponents/HeroSection'
import BookDemoSection from './CommonComponents/BookDemoSection'

export default function CompanyWorkflowManagement() {
  return (
    <>
      <HeroSection heroText={"Optimize Company Workflow"} subText={"Efficiently manage and streamline your company's workflows with SafetyLoop. Enhance coordination, control, and productivity within your health and safety management system."} />
      <BookDemoSection/>
    </>
  )
}
