import React, { useEffect, useReducer, useState } from "react";
import SideNav from "../../components/SideNav";
import Paywall from "./InventoryList/Paywall";
import { User } from "../../pages/HomePage/Login";
import Configuration from "../Inventory/Configuration";
import Inventory from "./InventoryList";
import { withRouter } from "react-router-dom";
import OrderManagement from "./Order/OrderManagement";

const initializeTabStatus = (location) => {
  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get("tab") || "inventory";
  return {
    inventory: tab === "inventory",
    config: tab === "config",
    "order-management": tab === "order-management"
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE":
      state[action.tab] = true;
      return { ...state };
    default:
      return state;
  }
};

const AssetTrackingTool = ({ history, location }) => {
  const [tabs, dispatch] = useReducer(reducer, initializeTabStatus(location));
  const [queryParams, setQueryParams] = useState("");
  const [isShowPaywall, setIsShowPaywall] = useState(false);

  const handleUpdateTab = (tab, updateUrl = true) => {
    dispatch({ type: "UPDATE", tab: tab });
    if (updateUrl)
      history.push(`?tab=${tab}`)
  };

  useEffect(() => {
    setIsShowPaywall(User.showPaywall());
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get("tab") || "inventory";
    handleUpdateTab(tab, false);
  }, []);

  return (
    <React.Fragment>
      <div className="msmcontainer dashboard-container">
        <div className="row d-flex">
          <SideNav />

          <div className="content-wrap asset-tracking-wrap risk-assessment">
            <div className="title-tab-wrap">
              <div className="d-flex justify-content-between">
                <h1>
                  <i
                    style={{ paddingInlineStart: "5px", paddingRight: "5px" }}
                    className="fa fa-warehouse"
                  ></i>
                  <b>Inventory Management</b>
                </h1>
                { isShowPaywall && User.currentInventoryPlan ?
                  (<div className="btn btn-danger mr-5" onClick={() => window.location.reload()} style={{height: '30px', paddingTop: '5px', fontWeight: 'bold'}}>x</div>) : <></>
                }
              </div>
              {isShowPaywall ? (
                <Paywall />
              ) : (
                <>
                  <ul className="nav nav-tabs">
                  <li
                      className={tabs['order-management'] ? "active" : ""}
                      onClick={(e) => {
                        handleUpdateTab("order-management");
                      }}
                    >
                      <a data-toggle="tab" href="#order-management">
                        Order Management
                      </a>
                    </li>
                    <li
                      className={tabs.inventory ? "active" : ''}
                      onClick={(e) => {
                        handleUpdateTab("inventory");
                      }}
                    >
                      <a data-toggle="tab" href="#inventory">
                        Inventory Management
                      </a>
                    </li>
                    
                    <li
                      className={tabs.config ? "active" : ""}
                      onClick={(e) => {
                        handleUpdateTab("config");
                      }}
                    >
                      <a data-toggle="tab" href="#configuration">
                        Configuration
                      </a>
                    </li>
                  </ul>
                </>
              )}
            </div>
            { !isShowPaywall &&
              <div className="tab-content">
                <div
                  id="inventory"
                  className={
                    tabs.inventory ? "tab-pane fade in active" : "tab-pane fade in"
                  }
                >
                  <Inventory openUpdateModalHandler={setIsShowPaywall} />
                </div>
                <div
                  id="configuration"
                  className={
                    tabs.config
                      ? "tab-pane fade in active"
                      : "tab-pane fade"
                  }
                >
                  {tabs.config ? (
                    <Configuration />
                  ) : (
                    ""
                  )}
                </div>
                <div
                  id="order-management"
                  className={
                    tabs['order-management']
                      ? "tab-pane fade in active"
                      : "tab-pane fade"
                  }
                >
                  <div
                    className="tab-content"
                  >{ tabs['order-management']
                    ? <OrderManagement /> : ''
                  }
                  </div>
                </div>
              </div>
            }
          </div>

        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(AssetTrackingTool);
