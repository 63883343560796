import React from 'react';
import { Route, Router, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import cx from 'classnames';
import { setMobileNavVisibility } from '../../reducers/Layout';
import { withRouter } from 'react-router-dom';
import '../../assets/styles/themes.scss';
import PropTypes from 'prop-types';
import UserManagement from '../shared/UserManagement';

const logo = require('../../assets/images/company_logo.png');
import axios, { put } from 'axios';

const contentStyle = {
    backgroundColor: "#282828"
};
const days = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"]
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
const MONTH_MAPPING = {"January": '1' , "February": '2', "March": '3', "April": '4', "May": '5', "June": '6', "July": '7',
    "August": '8', "September": '9', "October": '10', "November": '11', "December": '12'}
const quarters = ["first", "second", "third", "fourth"]
const QUARTERLY_MONTH_MAPPING = {
    "1": ["January", "February", "March"] ,
    "2": ["April", "May", "June"],
    "3": ["July", "August", "September"], "4": ["October", "November", "December"] }
const QUARTERLY_MAPPING = {"first": '1' , "second": '2', "third": '3', "fourth": '4'}
const QUARTERLY_PERIOD_MAPPING = {
    "1": "Month 1" ,
    "2": "Month 2",
    "3": "Month 3" }

class StatsSettings extends React.Component {
    state = { id:'', daily_notify_time: '13:00',
              weekly_notify_day: '',
              monthly_notify_day: '',
              quarterly_notify_month: '',
              quarterly_notify_quarter: '',
              annual_notify_month: '',
              quarterly_notify_day: '',
              annual_notify_day: '',
              week_days: [],
              months: [],
              user_role: '',
              default_days: {"Sunday": '0' , "Monday": '1', "Tuesday": '2', "Wednesday": '3', "Thursday": '4', "Friday": '5', "Saturday": '6'}};

    componentDidMount(){
        let that = this
        axios.get('/users/check_for_user',{
        })
        .then(function(response){
            if(response.data.user && (response.data.user.email || response.data.user.username)){
                console.log(response.data)
                $("#app").data("user_data", response.data.user)
                $("#app").data("company_data", response.data.company)
                $("#app").data("user_role", response.data.user_role)

                that.company_profile_data()
            }
        })
        .catch(function(error){
            console.log(error);
        })
    }

    company_profile_data = () =>  {
        let that = this
        let user_data = $("#app").data("user_data")
        let company_data = $("#app").data("company_data")
        let user_role = $("#app").data("user_role")

        that.setState({
            id: company_data.id,
            daily_notify_time: company_data.daily_notify_time || '',
            week_days: company_data.days_in_week,
            months: QUARTERLY_MONTH_MAPPING[company_data.quarterly_notify_quarter] || [],
            weekly_notify_day: company_data.weekly_notify_day,
            monthly_notify_day: company_data.monthly_notify_day,
            quarterly_notify_month: company_data.quarterly_notify_month,
            quarterly_notify_quarter: company_data.quarterly_notify_quarter,
            annual_notify_month: company_data.annual_notify_month,
            quarterly_notify_day: company_data.quarterly_notify_day,
            annual_notify_day: company_data.annual_notify_day,
            user_role: user_role
        })
    }

    notification_settings = () => {
        return {
                'days_in_week':this.state.week_days,
                'daily_notify_time':this.state.daily_notify_time,
                'weekly_notify_day':this.state.weekly_notify_day,
                'monthly_notify_day':this.state.monthly_notify_day,
                'quarterly_notify_month':this.state.quarterly_notify_month,
                'quarterly_notify_quarter':this.state.quarterly_notify_quarter,
                'quarterly_notify_day':this.state.quarterly_notify_day,
                'annual_notify_month':this.state.annual_notify_month,
                'annual_notify_day':this.state.annual_notify_day
                }
    }

    handleQuarterChange = (event) => {
        event.preventDefault();
        let months = QUARTERLY_MONTH_MAPPING[event.target.value] || []
        this.setState({ months: months, quarterly_notify_quarter: event.target.value });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const url = '/companies/'+this.state.id;
        const formData = new FormData();
        formData.append('id',this.state.id)
        formData.append('stats_update',true)
        formData.append('notification',JSON.stringify(this.notification_settings()))
        put(url, formData, {})
            .then(function(response) {
                $('#new_user').html('')
                $('#new_user').append('<span className="new_user_message">Company stats settings has been successfully updated.</span>')
                $('.new_user_message').fadeOut(15000);
                $("#app").data("company_data", response.data.company)
                console.log(response);
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    renderWeekDays = (weekdays) => {
        let options = this.state.week_days;
        return Object.keys(weekdays).map(data => {
            let info = weekdays[data]
            return (<option key={info} value={info}>{data}</option>);
        });
    };

    renderOptions = (days) => {
        return days.map(data => {
            return (<option key={data} value={data}>{data}</option>);
        });
    }

    renderKeyValueOptions = (days) => {
        return Object.entries(days).map((data, i) => {
            return (<option key={data[0]} value={data[0]}>{data[1]}</option>);
        });
    }

    renderMonths = (options, index) => {
        console.log("Loading options")
        let mapping = options.includes('first') ? QUARTERLY_MAPPING : MONTH_MAPPING
        return options.map(data => {
            return (<option key={index+1}  value={mapping[data]}>{data}</option>);
        });
    }

    onSelectChange = (event) => {
        this.setState({week_days: Array.from(event.target.selectedOptions, (item) => item.value)});
    };

    resetValues = ()=> {
        this.company_profile_data()
    }

    renderSaveButton = (user_role) => {
        if (user_role != 'Observer'){
            return <button type="button" id="update-stats-settings" className="btn btn-primary pull-left" onClick={this.handleSubmit}>Update</button>
        }
    }

    render() {
        let options = null;
        return (
            <div className="content">
                <div className="sub-navbar sub-navbar__header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12 sub-navbar-column">
                                <div className="sub-navbar-header">
                                </div>
                                <ol className="breadcrumb navbar-text navbar-right no-bg">
                                </ol>
                            </div>
                            <div className="col-lg-12">
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="media m-b-3" style={{marginLeft: '62px'}}>
                        <div className="media-left media-middle p-r-2">
                            <div className="avatar">
                            </div>
                        </div>
                        <div className="media-body">
                            <h3 className="f-w-300 m-b-0 m-t-1"><a href="profile-details.html"><span>{}</span></a> <span className="text-muted">
                                </span></h3>
                        </div>
                    </div>

                    <div className="row">
                        <UserManagement source= "stats-settings" />
                        <div className="col-lg-8 admin-content">
                            <div className="panel panel-default b-a-2 no-bg b-gray-dark" style={contentStyle}>
                                <div className="panel-heading">
                                    <h4 className="panel-title">Company Stats Settings</h4>
                                </div>

                                <div className="panel-body">
                                    <div id="new_user"/>
                                    <form className="form-horizontal" onSubmit={this.handleSubmit}>
                                        <div className="form-group">
                                            <label className="col-sm-3 control-label">Days in a week</label>
                                            <div className="col-sm-6">
                                                <select multiple={true} className="form-control days_in_week"  value={this.state.week_days} onChange={this.onSelectChange} style={{height: '132px'}}>
                                                    { this.renderWeekDays(this.state.default_days) };
                                                </select>
                                            </div>
                                        </div>
                                        <h4 className="panel-title">Inspection frequency notification - Global time settings</h4>
                                        <hr/>
                                        <div className="form-group">
                                            <div className="row">
                                                <label className="col-sm-3 control-label">Daily <i class="fa fa-info-circle" aria-hidden="true"></i></label>
                                                <div className="col-sm-6">
                                                    <div className="col-sm-6">
                                                        <input type="time" id="daily_notify_time" name="daily_notify_time"
                                                            value={this.state.daily_notify_time}
                                                            onChange={(e) => this.setState({ daily_notify_time: e.target.value })} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="row">
                                                <label className="col-sm-3 control-label">Weekly <i class="fa fa-info-circle" aria-hidden="true"></i></label>
                                                <div className="col-sm-6">
                                                    <div className="col-sm-6">
                                                        <select className="form-control weekly_notify_day"  value={this.state.weekly_notify_day}
                                                                onChange={(e) => this.setState({ weekly_notify_day: e.target.value })}>
                                                            <option value = ''>Select Day</option>
                                                            { this.renderWeekDays(this.state.default_days) };
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="row">
                                                <label className="col-sm-3 control-label">Monthly <i class="fa fa-info-circle" aria-hidden="true"></i></label>
                                                <div className="col-sm-6">
                                                    <div className="col-sm-6">
                                                        <select className="form-control monthly_notify_day"  value={this.state.monthly_notify_day}
                                                                onChange={(e) => this.setState({ monthly_notify_day: e.target.value })}>
                                                            <option value = ''>Select Day</option>
                                                            { this.renderOptions(days) };
                                                        </select>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                  
                                        <div className="form-group">
                                            <div className="row">
                                                <label className="col-sm-3 control-label">Quarterly <i class="fa fa-info-circle" aria-hidden="true"></i></label>
                                                <div className="col-sm-9">

                                                    <div className="col-sm-4">
                                                        <select className="form-control quarterly_notify_month"  value={this.state.quarterly_notify_month}
                                                                onChange={(e) => this.setState({ quarterly_notify_month: e.target.value })}>
                                                            <option value = ''>Select Month</option>
                                                            {/*{ this.renderOptions(["first","second","third"]) };*/}
                                                            { this.renderKeyValueOptions(QUARTERLY_PERIOD_MAPPING) }
                                                        </select>
                                                        Month
                                                    </div>

                                                    <div className="col-sm-4">
                                                        <select className="form-control quarterly_notify_day"  value={this.state.quarterly_notify_day}
                                                                onChange={(e) => this.setState({ quarterly_notify_day: e.target.value })}>
                                                            <option value = ''>Select Day</option>
                                                            { this.renderOptions(days) };
                                                        </select>
                                                        day
                                                    </div>


                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="row">
                                                <label className="col-sm-3 control-label">Annual <i class="fa fa-info-circle" aria-hidden="true"></i></label>
                                                <div className="col-sm-6">
                                                    <div className="col-sm-6">
                                                        <select className="form-control annual_notify_month"  value={this.state.annual_notify_month}
                                                                onChange={(e) => this.setState({ annual_notify_month: e.target.value })}>
                                                            <option value = ''>Select Month</option>
                                                            { this.renderMonths(months) };
                                                        </select>
                                                        Month of
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <select className="form-control annual_notify_day"  value={this.state.annual_notify_day}
                                                                onChange={(e) => this.setState({ annual_notify_day: e.target.value })}>
                                                            <option value = ''>Select Day</option>
                                                            { this.renderOptions(days) };
                                                        </select>
                                                        day
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <button type="button" id="reset-stats-settings" className="btn btn-primary reset-btn pull-left" onClick={this.resetValues}>Reset</button>
                                    {this.renderSaveButton(this.state.user_role)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default StatsSettings;
