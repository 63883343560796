import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import '../../../assets/stylesheets/common.css';

const ProfileCard = () => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(true);
    const [fields, setFields] = useState();

    useEffect(() => {
        async function fetchData() {
            const result = await axios.get("user_management/profile_info", {
                params: {},
            });
            result.data.imagePreviewUrl = result.data.profile_image;
            setFields(result.data);
            setLoading(false);
        }
        fetchData().then((r) => r);

    }, []);

    const handleChange = (field, e) => {
        setFields({ ...fields, [field]: e.target.value });
    };

    const handleImageUpload = (field, e) => {
        e.preventDefault();
        fields[field] = e.target.files[0];

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            fields['imagePreviewUrl'] = reader.result;
            setFields({ ...fields });
        };
        reader.readAsDataURL(file);
    };

    const handleClose = () => setShow(false);

    const handleShow = () => {
        async function fetchData() {
            const result = await axios.get("/analytics_dashboard/user_info", {});
            result.data.profile_info.imagePreviewUrl = result.data.profile_image;
            setFields(result.data);
        }
        fetchData().then((r) => r);
        setShow(true);
    };

    const handleSubmit = () => {
        async function fetchData() {
            const formData = new FormData();
            formData.append('user[profile_image]', fields.profile_image);
            formData.append('user[email]', fields.email);
            formData.append('user[username]', fields.username);
            formData.append('user[first_name]', fields.first_name);
            formData.append('user[last_name]', fields.last_name);
            formData.append('user[telephone_number]', fields.telephone_number);
            formData.append('user[address]', fields.address);
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };

            const result = await axios.put("user_management/update_profile", formData, config);
            setFields(fields);
        }
        fetchData().then((r) => r);
        setShow(false);

        // Update user details on local storage
        let userDetails = localStorage.getItem("user");
        userDetails = JSON.parse(userDetails);
        userDetails.first_name = fields.first_name;
        userDetails.last_name = fields.last_name;
        localStorage.setItem("user", JSON.stringify(userDetails));
    };

    return (
        <div className="profile-page">
            <div className="profile-navigation">
                <a href="#" className="back-link">← User Profile</a>
                <button className="edit-button" onClick={handleShow}>Edit Profile</button>
            </div>
            <div className="profile-container">
                {loading === false ?
                    <div className="profile-content">
                        <div className="profile-header">
                            <div className="profile-title">
                                <h1>{fields.first_name} {fields.last_name}</h1>
                            </div>
                        </div>
                        <div className="profile-info-row">
                            <div className="profile-info-item">
                                <label>Email</label>
                                <p>{fields.email}</p>
                            </div>
                            <div className="profile-info-item">
                                <label>Company</label>
                                <p>{fields.company}</p>
                            </div>
                            <div className="profile-info-item">
                                <label>Phone Number</label>
                                <p>{fields.telephone_number || fields.phone_number}</p>
                            </div>
                            <div className="profile-info-item">
                                <label>Address</label>
                                <p>{fields.address}</p>
                            </div>
                        </div>
                        {/*<div className="profile-picture-container">*/}
                        {/*    <div className="profile-picture">*/}
                        {/*        <img src={fields.imagePreviewUrl} alt="Profile" />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    : ""}
                {loading === false ?
                    <Modal className="user-modal" show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Job Profile</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form className="form-horizontal">
                                <div className="form-group">
                                    <label className="col-sm-4 control-label">First Name</label>
                                    <div className="col-sm-6">
                                        <input className="form-control" type="text" maxLength="50" placeholder="Enter your First Name" value={fields.first_name} name="user[first_name]" onChange={e => handleChange('first_name', e)} />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="col-sm-4 control-label">Last Name</label>
                                    <div className="col-sm-6">
                                        <input className="form-control" type="text" maxLength="50" placeholder="Enter your Last Name" value={fields.last_name} name="last_name" onChange={e => handleChange('last_name', e)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-sm-4 control-label">Email</label>
                                    <div className="col-sm-6">
                                        <input className="form-control" type="text" maxLength="50" placeholder="Enter your Email" value={fields.email} name="email" onChange={e => handleChange('email', e)} readOnly />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-sm-4 control-label">Phone Number</label>
                                    <div className="col-sm-6">
                                        <input className="form-control" type="number" maxLength="50" placeholder="Enter your Phone Number" value={fields.telephone_number} name="telephone_number" onChange={e => handleChange('telephone_number', e)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-sm-4 control-label">Address</label>
                                    <div className="col-sm-6">
                                        <textarea className="form-control" maxLength="300" placeholder="Address" value={fields.address} name="address" onChange={e => handleChange('address', e)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-sm-4 control-label">Profile Image</label>
                                    <div className="col-sm-6">
                                        <input className="form-control" type="file" name="profile_image" onChange={e => handleImageUpload('profile_image', e)} />
                                    </div>
                                </div>
                            </form>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-danger" onClick={handleClose}>
                                Close
                            </button>
                            <button className="btn btn-primary" onClick={handleSubmit}>
                                Update
                            </button>
                        </Modal.Footer>
                    </Modal>
                    : ""}
            </div>
        </div>
    );
};

export default ProfileCard;
