import React, { useEffect, useRef, useState, useContext } from "react";
import Pagination from "@mui/material/Pagination";
import Cookies from "js-cookie";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import Button from '@mui/material/Button';

import { RelatedAssetContext } from "./RelatedAssetInfo";
import EmptyData from "../../../../assets/images/empty-assets.svg";
import Application from "../../../../config/ApplicationWrapper";
import { toast } from "react-toastify";

const RelatedAsset = () => {
  const [assetLables, setAssetLables] = useState([]);
  const [relatedAssets, setRelatedAssets] = useState([]);
  const { setRefresh, refresh, setRelatedAssetPopup, setAssetAction } =
    useContext(RelatedAssetContext);

  const cookieDetails = Cookies.get("token");
  const lableSize = 4;

  //Filters
  // const [filterName, setFilterName] = useState('')
  // const [sort, setSort] = useState('name');
  // const [sortDirection, setSortDirection] = React.useState('asc');

  // Pagination
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [pagination, setPagination] = useState(true);
  const [pageCounts, setPageCounts] = useState(0);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const updateResults = (resultObj) => {
    let records = resultObj.data;
    setRelatedAssets([...records]);
    records.length > 0 ? setAssetAction(true) : null;
    pagination === false ? setPagination(true) : null;
    setPageCounts(resultObj.metadata ? resultObj.metadata.pages : 0);
  };

  const urlParams = () => {
    return new URLSearchParams({
      page: page,
      pagination: true,
      limit: limit,
    }).toString();
  };

  const removeRelatedAssets = async (id) => {
    let { asstId, step } = getAssetInfo();
    try {
      let url = new URL(
        `${Application.api_url}/api/v2/asset_trackings/${asstId}/related_assets/${id}`
      );

      fetch(url, {
        method: "DELETE",
        headers: { Authorization: cookieDetails },
      }).then(async (response) => {
        if (response.status === 200) {
          setRefresh(Math.random());
          toast.success("Deleted successfully");
        }
      });
    } catch (err) {
      console.log("error===>", err);
    }
  };

  const getRelatedAssetDetails = async (asstId) => {
    try {
      let url = new URL(
        `${Application.api_url}/api/v2/asset_trackings/${asstId}/related_assets`
      );
      url.search = urlParams();
      fetch(url, {
        method: "GET",
        headers: { Authorization: cookieDetails },
      }).then(async (response) => {
        let resultObj = await response.json();
        if (response.status === 200) {
          updateResults(resultObj);
        }
      });
    } catch (err) {
      console.log("error===>", err);
    }
  };

  const getAssetInfo = () => {
    let assetInfo = localStorage.getItem("assetInfo");
    return assetInfo && JSON.parse(assetInfo);
  };

  useEffect(() => {
    let { asstId } = getAssetInfo();

    if (asstId) {
      getRelatedAssetDetails(asstId);
    }
  }, [limit, page, refresh]);

  const NoAssetsFound = () => (
    <div className="no-assets">
      <img src={EmptyData} />
      <p>
        <b>No Data</b>
      </p>
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => {
          setRelatedAssetPopup(true);
        }}
      >
        <i className="fa fa-plus" aria-hidden="true"></i>
        Add Related Assets
      </button>
    </div>
  );

  const ManagerList = (asset, manager, index) => (
      <span>
            <span key={asset.id} className={`asset-manager-details ${index > lableSize && !assetLables.includes(asset.id) ? `${asset.id}_managers_hide` : ''}`}>
                {manager.first_name} {manager.last_name}
            </span>
        {
          index === lableSize ? <MoreItems count={(asset.managers.length - 1) - lableSize } asset_id={asset.id}/> : null
        }
        </span>
  );

  const MoreItems = ({count, asset_id}) => (
      <span key={asset_id} className={`asset-manager-details ${assetLables.includes(asset_id) ? `${asset_id}_managers_hide` : '' }`}
            onClick={() => {
              let labels = assetLables;
              labels.push(asset_id);
              setAssetLables([...labels]);
            }}
      >
            + {count} More
        </span>
  );

  return (
    <div>
      {relatedAssets.length > 0 ? (
        <div className="table-template">
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">
                  <div className="d-flex justify-content-between">
                    Asset Name
                  </div>
                </th>
                <th scope="col">
                  <div className="d-flex justify-content-between">
                    Company
                  </div>
                </th>
                <th scope="col">
                  <div className="d-flex justify-content-between">Group</div>
                </th>
                <th scope="col">
                  <div className="d-flex justify-content-between">
                    Created By
                  </div>
                </th>
                <th scope="col">
                  <div className="d-flex justify-content-between">
                    Responsible Party
                  </div>
                </th>
                <th scope="col">
                  <div className="d-flex justify-content-between">Status</div>
                </th>
                <th scope="col" style={{ textAlign: "left" }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {relatedAssets.map((asset, index) => (
                <tr key={index}>
                  <td>{asset.name}</td>
                  <td>{asset.company_name}</td>
                  <td>{asset.groups[0] && asset.groups[0].name}</td>
                  <td>{asset.creator && asset.creator.full_name}</td>
                  <td>
                    <div className="manager-containers">
                      {
                          asset.managers && asset.managers.map((manager, index) =>
                              ManagerList(asset, manager, index))
                      }
                    </div>
                  </td>
                  <td className="related-status">
                    <span className={`badge badge-light ${asset.status}`}>
                      {asset.status}
                    </span>
                  </td>
                  <td>
                    <span
                      className="delete-action"
                      onClick={() => {
                        removeRelatedAssets(asset.id);
                      }}
                    >
                      <i className="fa fa-trash"></i>
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div
            className="d-flex justify-content-between"
            style={{ alignItems: "center" }}
          >
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={limit}
                onChange={(e) => {
                  setLimit(e.target.value);
                  setPage(1);
                  setPagination(false);
                }}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
              </Select>
            </FormControl>
            {pagination ? (
              <Pagination
                count={pageCounts}
                variant="outlined"
                shape="rounded"
                onChange={handleChangePage}
              />
            ) : null}
          </div>
        </div>
      ) : (
        <NoAssetsFound />
      )}
    </div>
  );
};

export default RelatedAsset;
