import React, { Fragment } from 'react'
import {Table} from "react-bootstrap";

const SharedForms = ({forms}) => {
    const renderFormData = forms.map(form =>
    {
        return (
            <tr>
                <td>{form.form}</td>
                <td>{form.description}</td>
                <td>{form.frequency}</td>
            </tr>
        )
    });

    const renderContent = (forms) => {
        let content;
        if (forms.length == 0){
            content = <h4 className='align-center'> No Forms shared </h4>
        }else{
            content = <Table responsive striped bordered hover>
                        <thead>
                        <tr>
                            <th>Form</th>
                            <th>Description</th>
                            <th>Frequency</th>
                        </tr>
                        </thead>
                        <tbody>
                            {renderFormData}
                        </tbody>
                    </Table>
        }
        return content;
    }

    return (
        <div className="shared-forms">
            <h3>
                <i className="fa fa-file-text-o"></i>
                shared forms
            </h3>
            {renderContent(forms)}
        </div>
    )
}

export default SharedForms;
