import React, { useEffect, useState, useContext } from "react";
import Settings from "../../../../assets/images/settings.svg";
import Application from "../../../../config/ApplicationWrapper";
import Pagination from "@mui/material/Pagination";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { AssetDetailsContext } from "./index";
import Cookies from "js-cookie";
import EmptyData from "../../../../assets/images/empty-assets.svg";

export default function RelatedForms() {
  const [action, setAction] = useState([]);
  const [paginationStatus, setPaginationStatus] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [pageDetails, setPageDetails] = useState([]);
  const [dateStarted, setDateStarted] = useState("");
  const [dateCompleted, setDateCompleted] = useState("");
  const [type, setType] = useState("");
  const cookieDetails = Cookies.get("token");
  const { pageData } = useContext(AssetDetailsContext);

  const getPageDetails = async () => {
    try {
      if (pageData && pageData.id) {
        window.scrollTo(0, 0);
        const res = await fetch(
          `${Application.api_url}/api/v2/asset_trackings/${pageData.id}/forms?limit=${limit}&page=${page}&date_started=${dateStarted}&date_completed=${dateCompleted}`,
          {
            method: "GET",
            headers: { Authorization: cookieDetails },
          }
        );
        const resultObj = await res.json();

        setPageDetails(resultObj && resultObj.data ? resultObj.data : []);

        setPageCount(
          resultObj && resultObj.metadata ? resultObj.metadata.pages : 0
        );
        setPaginationStatus(true);
      }
    } catch (err) {
      console.log("error==>", err);
    }
  };

  useEffect(() => {
    getPageDetails();
  }, [pageData, page, limit, dateStarted, dateCompleted, type, action]);

  const handleChangePage = (_event, value) => {
    setPage(value);
  };

  const NoFormsFound = () => (
    <div className="no-assets">
      <img src={EmptyData} />
      <p>
        <b>No Data</b>
      </p>
    </div>
  );

  return (
    <>
      <div className="title-wrap mb-5">
        <h3 className="title">Asset Forms</h3>

        <div className="header-options">
          <FormControl>
            <label>date started</label>
            <input
              type="date"
              className="form-control"
              onChange={(e) => {
                setDateStarted(e.target.value);
              }}
            />
          </FormControl>

          <FormControl>
            <label>date completed</label>
            <input
              type="date"
              className="form-control"
              onChange={(e) => {
                setDateCompleted(e.target.value);
              }}
            />
          </FormControl>
        </div>
      </div>

      {pageDetails && pageDetails.length > 0 &&
        <>
          <div className="table-template">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">
                    <div className="d-flex justify-content-between">Name</div>
                  </th>
                  <th scope="col">
                    <div className="d-flex justify-content-between">User</div>
                  </th>
                  <th scope="col">
                    <div className="d-flex justify-content-between">Group</div>
                  </th>
                  <th scope="col">
                    <div className="d-flex justify-content-between">Started On</div>
                  </th>
                  <th scope="col">
                    <div className="d-flex justify-content-between">Completed On</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {pageDetails && pageDetails.length > 0 &&
                pageDetails.map((audit, index) => (
                  <tr key={index}>
                    <td><a href={audit.report_link} target="_blank">{audit.name}</a></td>
                    <td>{audit.first_name} {audit.last_name}</td>
                    <td>{audit.group_name}</td>
                    <td>{audit.started}</td>
                    <td>{audit.completed}</td>
                  </tr>
                ))
                }
              </tbody>
            </table>
          </div>
        </>}

      {pageDetails && pageDetails.length > 0 &&
        <>
          <div className="page-limits">
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={limit}
                onChange={(e) => {
                  setPage(1);
                  setLimit(e.target.value);
                  setPaginationStatus(false);
                }}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
              </Select>
            </FormControl>
            {paginationStatus && (
              <Pagination
                count={pageCount}
                variant="outlined"
                shape="rounded"
                onChange={handleChangePage}
              />
            )}
          </div>
        </>}

      {pageDetails && pageDetails.length === 0 &&
        <NoFormsFound />
      }
    </>
  );
}
