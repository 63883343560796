import React from "react"

const CheckoutCartIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_5972_4813)">
        <path
          d="M5.39709 22.1881C5.39709 22.807 5.64293 23.4005 6.08051 23.838C6.5181 24.2756 7.11159 24.5215 7.73043 24.5215C8.34927 24.5215 8.94276 24.2756 9.38034 23.838C9.81793 23.4005 10.0638 22.807 10.0638 22.1881C10.0638 21.5693 9.81793 20.9758 9.38034 20.5382C8.94276 20.1006 8.34927 19.8548 7.73043 19.8548C7.11159 19.8548 6.5181 20.1006 6.08051 20.5382C5.64293 20.9758 5.39709 21.5693 5.39709 22.1881Z"
          stroke="#080808"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.2305 22.1881C18.2305 22.807 18.4763 23.4005 18.9139 23.838C19.3515 24.2756 19.945 24.5215 20.5638 24.5215C21.1826 24.5215 21.7761 24.2756 22.2137 23.838C22.6513 23.4005 22.8971 22.807 22.8971 22.1881C22.8971 21.5693 22.6513 20.9758 22.2137 20.5382C21.7761 20.1006 21.1826 19.8548 20.5638 19.8548C19.945 19.8548 19.3515 20.1006 18.9139 20.5382C18.4763 20.9758 18.2305 21.5693 18.2305 22.1881Z"
          stroke="#080808"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.5638 19.8548H7.73043V3.52148H5.39709"
          stroke="#080808"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.73047 5.8548L24.0638 7.02146L22.8971 15.1881H7.73047"
          stroke="#080808"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5972_4813">
          <rect
            width="28"
            height="28"
            fill="white"
            transform="translate(0.730469 0.0214844)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CheckoutCartIcon
