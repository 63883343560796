import React from 'react';
import HeroSection from './CommonComponents/HeroSection';
import FeaturesSection from './CommonComponents/FeaturesSection';
import ParallaxSection from '../../pages/HomePage/Components/ParallaxSection';
import HomepageDemoSection from '../../pages/HomePage/Components/HomepageDemoSection';

export default function TaskManagement() {
    return (
        <>
            <HeroSection
                heroText={"Task Management"}
                subText={"Create, assign, and track tasks efficiently with SafetyLoop's task management tools."}
            />
            <FeaturesSection
                headerText={"Comprehensive Task Management"}
                subText={"Manage all your tasks from a single platform to ensure productivity and compliance."}
                featureHighlights={[
                    "Create and assign tasks to team members.",
                    "Set priorities and deadlines for tasks.",
                    "Track task status and completion rates.",
                    "Generate task management reports."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for Task Management section here
            />
            <FeaturesSection
                headerText={"Automated Task Alerts"}
                subText={"Receive real-time alerts and reminders for pending and overdue tasks."}
                featureHighlights={[
                    "Set up alerts for pending and overdue tasks.",
                    "Monitor task status and completion rates.",
                    "Ensure deadlines are met with automated reminders."
                ]}
                // Placeholder: Add image for Task Alerts section here
            />
            <FeaturesSection
                headerText={"Task Reporting and Analysis"}
                subText={"Gain insights into task management and optimize workflows for better performance."}
                featureHighlights={[
                    "Analyze task completion rates and performance.",
                    "Identify bottlenecks and improve productivity.",
                    "Generate detailed task management reports."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for Task Reporting section here
            />
            <div className="Home-Page sl-home-wrapper" style={{ paddingTop: 0 }}>
                <ParallaxSection />
            </div>
            <div className="features-homepage-wrapper">
                <HomepageDemoSection mergeTop={false} />
            </div>
        </>
    );
}
