import React from 'react';
import HeroSection from './CommonComponents/HeroSection';
import FeaturesSection from './CommonComponents/FeaturesSection';
import ParallaxSection from '../../pages/HomePage/Components/ParallaxSection';
import HomepageDemoSection from '../../pages/HomePage/Components/HomepageDemoSection';

export default function HazardManagement() {
    return (
        <>
            <HeroSection
                heroText={"Hazard Management"}
                subText={"Identify, assess, and control workplace hazards with SafetyLoop's hazard management tools."}
            />
            <FeaturesSection
                headerText={"Comprehensive Hazard Tracking"}
                subText={"Monitor and control all potential workplace hazards from a single platform."}
                featureHighlights={[
                    "Log and track identified hazards.",
                    "Assign responsibilities for hazard mitigation.",
                    "Monitor hazard resolution progress.",
                    "Generate hazard reports for safety audits."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for Hazard Tracking section here
            />
            <FeaturesSection
                headerText={"Risk Assessment"}
                subText={"Conduct detailed risk assessments and proactively manage workplace safety."}
                featureHighlights={[
                    "Assess risk levels for each hazard.",
                    "Implement control measures for risk reduction.",
                    "Track the effectiveness of risk mitigation."
                ]}
                // Placeholder: Add image for Risk Assessment section here
            />
            <FeaturesSection
                headerText={"Hazard Analysis and Reporting"}
                subText={"Gain insights into hazard trends and improve safety management with data-driven analysis."}
                featureHighlights={[
                    "Analyze hazard trends and patterns.",
                    "Generate detailed hazard analysis reports.",
                    "Use data insights to optimize safety protocols."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for Hazard Analysis section here
            />
            <div className="Home-Page sl-home-wrapper" style={{ paddingTop: 0 }}>
                <ParallaxSection />
            </div>
            <div className="features-homepage-wrapper">
                <HomepageDemoSection mergeTop={false} />
            </div>
        </>
    );
}
