import React, { useEffect, useRef } from "react";
import Filter from "../../../assets/images/Filter.png";
import Pagination from "@mui/material/Pagination";
import StatusPopupPage from "./Popups/Status";
import { NavLink } from "react-router-dom";
import Cookies from "js-cookie";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Application from "../../../config/ApplicationWrapper";
import FormIcon from "../../../assets/images/form.svg";
import { User } from "../../../pages/HomePage/Login";
import { toast } from "react-toastify";

const AchiveRiskAssessment = (props) => {
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [pageCount, setPageCount] = React.useState(0);
  const [sort, setSort] = React.useState("created_at");
  const [filterName, setFilterName] = React.useState("");
  const [dataChange, setDataChange] = React.useState("");
  const [sortingDirection, setSortingDirection] = React.useState({
    priority_order: "desc",
    name: "desc",
    risk_type: "desc",
    created_at: "desc",
  });

  const [rats, setRats] = React.useState([]);

  const [riskTypes, setRiskTypes] = React.useState([]);
  const [approvedUser, setApprovedUser] = React.useState("");
  const [riskId, setRiskId] = React.useState("");
  const [statusModal, setStatusModal] = React.useState(false);
  const [paginationStatus, setPaginationStatus] = React.useState(false);
  const [createdDate, setCreatedDate] = React.useState();
  const [currentQuery, setCurrentQuery] = React.useState('');

  const priorityImageRef = useRef();
  const nameImageRef = useRef();
  const riskImageRef = useRef();
  const createdImageRef = useRef();
  const cookieDetails = Cookies.get("token");

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const changeFilter = (event) => {
    setPage(1);
    setPaginationStatus(false);
    setFilterName(event.target.value);
  };

  const changeRiskType = (event) => {
    setPage(1);
    setPaginationStatus(false);
    setRiskId(event.target.value);
  };

  const sorting = (name) => {
    let direction;
    if (sortingDirection[name] == "desc") {
      if (name == "priority_order") {
        priorityImageRef.current.style.transform = `rotate(180deg)`;
        direction = { ...sortingDirection, ...{ priority_order: "asc" } };
      } else if (name == "name") {
        nameImageRef.current.style.transform = `rotate(180deg)`;
        direction = { ...sortingDirection, ...{ name: "asc" } };
      } else if (name == "risk_type") {
        riskImageRef.current.style.transform = `rotate(180deg)`;
        direction = { ...sortingDirection, ...{ risk_type: "asc" } };
      } else {
        createdImageRef.current.style.transform = `rotate(180deg)`;
        direction = { ...sortingDirection, ...{ created_at: "asc" } };
      }
    } else {
      if (name == "priority_order") {
        priorityImageRef.current.style.transform = `rotate(0deg)`;
        direction = { ...sortingDirection, ...{ priority_order: "desc" } };
      } else if (name == "name") {
        nameImageRef.current.style.transform = `rotate(0deg)`;
        direction = { ...sortingDirection, ...{ name: "desc" } };
      } else if (name == "risk_type") {
        riskImageRef.current.style.transform = `rotate(0deg)`;
        direction = { ...sortingDirection, ...{ risk_type: "desc" } };
      } else {
        createdImageRef.current.style.transform = `rotate(0deg)`;
        direction = { ...sortingDirection, ...{ created_at: "desc" } };
      }
    }
    setSortingDirection(direction);
    setSort(name);
  };

  const onhandleStatus = (val) => {
    setApprovedUser(val);
    setStatusModal(true);
  };
  const onhandleStatusClose = () => setStatusModal(false);

  const duplicateRAT = async (id) => {
    try {
      setPage(1);
      setPaginationStatus(false);
      let duplicate = await fetch(
        `${Application.api_url}/api/v2/rats/${id}/duplicate_rat`,
        {
          method: "POST",
          headers: { Authorization: cookieDetails },
        }
      );

      duplicate = await duplicate.json();

      if (duplicate && duplicate.success === true) {
        toast.success("Duplicate record created successfully");
        setDataChange(duplicate);
      }
    } catch (err) {
      console.log("error===>", err);
    }
  };

  const unArchiveRat = async (id) => {
    try {
      setPage(1);
      setPaginationStatus(false);
      let archive = await fetch(
        `${Application.api_url}/api/v2/rats/${id}/unarchived_rat`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: cookieDetails,
          },
        }
      );

      archive = await archive.json();

      if (archive && archive.success === true) {
        toast.success("Successfully unarchived");
        props.updateLastChanges("Archived");
        setDataChange(archive.rat);
      }
    } catch (err) {
      console.log("error===>", err);
    }
  };

  useEffect(() => {
    async function getRiskTypeDetails() {
      try {
        const riskTypeDetails = await fetch(
          `${Application.api_url}/api/v2/risk_assesments/risk_types`,
          {
            method: "GET",
            headers: { Authorization: cookieDetails },
          }
        );
        const riskType = await riskTypeDetails.json();
        setRiskTypes(
          riskType && riskType.risk_types ? riskType.risk_types : []
        );
      } catch (err) {
        console.log("error===>", err);
      }
    }

    getRiskTypeDetails();
  }, []);

  useEffect(() => {
    const getPageDetails = async () => {
      try {
        var url = new URL(`${Application.api_url}/api/v2/rats`);
        const query = new URLSearchParams({
          page: page,
          "query[name_cont]": filterName,
          "query[risk_type_id_eq]": riskId,
          limit: limit,
          "query[archived_eq]": true,
          direction: sortingDirection[sort],
          "query[created_on]": createdDate ? createdDate : "",
          sort: sort,
        })
        url.search = query.toString();

        setCurrentQuery(query)

        const res = await fetch(url, {
          method: "GET",
          headers: { Authorization: cookieDetails },
        });
        const resultObj = await res.json();
        setRats(resultObj && resultObj.rats ? resultObj.rats : []);
        setPageCount(resultObj && resultObj.meta ? resultObj.meta.pages : 0);
        props.countUpdate(
          resultObj && resultObj.meta ? resultObj.meta.count : 0
        );
        setPaginationStatus(true);
      } catch (err) {
        console.log("error==>", err);
      }
    };

    getPageDetails();
  }, [
    page,
    filterName,
    riskId,
    sortingDirection,
    limit,
    dataChange,
    createdDate,
    props.archiveRATLastChange,
    props.approveRATLastChange,
  ]);
  
  useEffect(() => {
    if (props.currentTab === 'Archived' && currentQuery) {
      props.updateCurrentQuery(currentQuery)
    }
  }, [props.currentTab, currentQuery])

  return (
    <>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col" className="risk-assessment-divider">
              <div
                className="d-flex justify-content-between align-item-center"
                onClick={(e) => {
                  sorting("priority_order");
                }}
              >
                Priority
                <img src={Filter} alt="" ref={priorityImageRef} />
              </div>
            </th>
            <th scope="col" className="risk-assessment-divider">
              <div
                className="d-flex justify-content-between"
                onClick={(e) => {
                  sorting("name");
                }}
              >
                Assessments Name
                <img src={Filter} alt="" ref={nameImageRef} />
              </div>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control assessment-name"
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  onChange={changeFilter}
                />
                <div class="input-group-addon">
                  <span class="input-group-text" id="basic-addon1">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            </th>
            <th scope="col" className="risk-assessment-divider">
              <div
                className="d-flex justify-content-between"
                onClick={(e) => {
                  sorting("risk_type");
                }}
              >
                Risk Type
                <img src={Filter} alt="" ref={riskImageRef} />
              </div>
              <select class="form-control risk-type" onChange={changeRiskType}>
                <option value={""}>All</option>
                {riskTypes.map((val, index) => (
                  <option value={val.id} key={index}>
                    {val.name}
                  </option>
                ))}
              </select>
            </th>
            <th scope="col" className="risk-assessment-divider">
              <div
                className="d-flex justify-content-between"
                onClick={(e) => {
                  sorting("created_at");
                }}
              >
                Created
                <img src={Filter} alt="" ref={createdImageRef} />
              </div>
              <input
                type="date"
                class="form-control assessment-name"
                placeholder="All Dates"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={(e) => {
                  setCreatedDate(e.target.value);
                }}
              />
            </th>
            <th scope="col" className="risk-assessment-divider">
              Status
            </th>
            <th scope="col" className="risk-assessment-divider">
              Shared Group
            </th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {rats &&
            rats.length > 0 &&
            rats.map((val, index) => (
              <tr key={index}>
                <td style={{ textAlign: "center" }}>{val.priority_order}</td>
                <td>
                  {val.name}
                  {val.note_count && val.note_count > 0 ? (
                    <span className="file">
                      <img src={FormIcon} alt="" />
                      <span>{val.note_count}</span>
                    </span>
                  ):""}
                </td>
                <td>
                  {val.ctra_risk_type && val.ctra_risk_type.name
                    ? val.ctra_risk_type.name
                    : ""}
                </td>
                <td>{val.created_at}</td>
                <td style={{ textAlign: "center" }}>
                  {val.ctra_approved_users &&
                    val.ctra_approved_users.length > 0 && (
                      <span
                        className={`badge badge-light ${val.approval_status}`}
                      >
                        {val.approval_status === "Pending_Approval" ? "Pending Approval" : val.approval_status}
                      </span>
                    )}
                </td>
                <td style={{ textAlign: "center" }}>
                  {val.shared_groups_count}
                </td>
                <td
                  className="template-options"
                  style={{ textAlign: "center" }}
                >
                  <a
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="btn dropdown-toggle"
                    data-toggle="dropdown"
                    id="dropdownMenuLink"
                    role="button"
                  >
                    <i class="fa fa-ellipsis-v"></i>
                  </a>
                  <ul aria-labelledby="dropdownMenuLink" class="dropdown-menu">
                    <li>
                      <a
                        class="archive_group_invites"
                        data-toggle="tooltip"
                        role="button"
                        title="View RA"
                      >
                        <div class="row d-flex">
                          <div class="col-sm-3">
                            <i
                              class="fa fa-eye"
                              style={{
                                paddingTop: "5px",
                                fontSize: "16px",
                              }}
                            ></i>
                          </div>
                          <div class="col-sm-9" style={{ paddingTop: "5px" }}>
                            <NavLink
                              to={`/view-risk-assessment/${val.identifier}`}
                              id=""
                              role="button"
                              className="rat-links"
                            >
                              View
                            </NavLink>
                          </div>
                        </div>
                      </a>
                      <a
                        class="archive_group_invites"
                        data-toggle="tooltip"
                        href="#"
                        title="Status"
                      >
                        <div class="row d-flex">
                          <div class="col-sm-3">
                            <i
                              class="fa fa-archive"
                              style={{
                                paddingTop: "5px",
                                fontSize: "16px",
                              }}
                            ></i>
                          </div>
                          <div
                            class="col-sm-9"
                            style={{ paddingTop: "5px" }}
                            onClick={(e) => {
                              onhandleStatus(val.ctra_approved_users);
                            }}
                          >
                            Status
                          </div>
                        </div>
                      </a>
                      {val.approval_status != "Approved" &&
                        (User.role == "Admin" || User.role == "SuperAdmin") && (
                          <a
                            class="archive_group_invites"
                            data-toggle="tooltip"
                            role="button"
                            title="View RA"
                          >
                            <div class="row">
                              <div class="col-sm-3">
                                <i
                                  class="fa fa-pencil-square-o"
                                  style={{
                                    paddingTop: "5px",
                                    fontSize: "16px",
                                  }}
                                ></i>
                              </div>
                              <div
                                class="col-sm-9"
                                style={{ paddingTop: "5px" }}
                              >
                                <NavLink
                                  to={`/edit-risk-assessment/${val.id}`}
                                  id=""
                                  role="button"
                                  className="rat-links"
                                >
                                  Edit
                                </NavLink>
                              </div>
                            </div>
                          </a>
                        )}
                      {(User.role == "Admin" || User.role == "SuperAdmin") && (
                        <a
                          class="archive_group_invites"
                          data-toggle="tooltip"
                          onClick={(e) => {
                            duplicateRAT(val.id);
                          }}
                          title="Duplicate"
                        >
                          <div class="row">
                            <div class="col-sm-3">
                              <i
                                class="fa fa-clone"
                                style={{
                                  paddingTop: "5px",
                                  fontSize: "16px",
                                }}
                              ></i>
                            </div>
                            <div class="col-sm-9" style={{ paddingTop: "5px" }}>
                              Duplicate
                            </div>
                          </div>
                        </a>
                      )}
                      {(User.role == "Admin" || User.role == "SuperAdmin") && (
                        <a
                          class="archive_group_invites"
                          data-toggle="tooltip"
                          title="Archive"
                          onClick={(e) => {
                            unArchiveRat(val.id);
                          }}
                        >
                          <div class="row">
                            <div class="col-sm-3">
                              <i
                                class="fa fa-trash"
                                style={{
                                  paddingTop: "5px",
                                  fontSize: "16px",
                                  color: "#407797",
                                }}
                              ></i>
                            </div>
                            <div
                              class="col-sm-9"
                              style={{
                                paddingTop: "5px",
                                color: "#407797",
                              }}
                            >
                              Un-Archive
                            </div>
                          </div>
                        </a>
                      )}
                    </li>
                  </ul>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div
        className="d-flex justify-content-between"
        style={{ alignItems: "center" }}
      >
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            value={limit}
            onChange={(e) => {
              setLimit(e.target.value);
            }}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={30}>30</MenuItem>
          </Select>
        </FormControl>
        {paginationStatus && (
          <Pagination
            count={pageCount}
            variant="outlined"
            shape="rounded"
            onChange={handleChangePage}
          />
        )}
      </div>
      <StatusPopupPage
        status={statusModal}
        stausDetails={approvedUser}
        closepopup={onhandleStatusClose}
      />
    </>
  );
};

export default AchiveRiskAssessment;
