import React, { useState } from "react";
import { Button } from "react-bootstrap";
import video from "../../../javascript/assets/images/product-pg-video.svg";
import Analytics from "../../../javascript/assets/images/Analytics.png";
import ConnectEveryone from "../../../javascript/assets/images/ConnectEveryone.png";
import Integrate from "../../../javascript/assets/images/Integrate.svg";
import ProcessUser from "../../../javascript/assets/images/process-users.svg";

const Products = () => {
  return (
    <div className="Product-Page">
      <div className="banner-section">
        <div className="home-container">
          <div className="banner-content">
            <div className="banner-inside-content">
              <h1>
                <b>Simplifying compliance, minimizing risk, ensuring safety.</b>
              </h1>
              <p>
              Empowering project controls & teams to achieve successful project delivery through enhanced project intelligence in risk management, progress tracking, and safety compliance.
              </p>
            </div>
            <div className="d-flex">
              <Button className="contact-btn">
                <b> Learn More</b>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="safety-section">
        <div className="home-container">
          <div className="safety-section-details">
            <h4><b>Proactive Safety, Real-time Success</b></h4>
            <h3>Making Safety Choices with Confidence</h3>
            <p>
              Making informed decisions quickly in the context of safety often requires access to accurate and up-to-date information about potential hazards and risks, as well as the ability to analyze and interpret that information effectively. 
            </p>
            <p>
            One way to achieve this is through the use of data analytics and visualization tools to monitor safety metrics and identify potential hazards. Additionally, having a robust incident reporting and investigation process in place can help to quickly gather information about any incidents that do occur and identify root causes.  
            </p>          
            {/* <img src={video} alt="" /> */}
          </div>
        </div>
      </div>
      <div className="explore-section">
        <div className="home-container">
          <div className="explore-details">
            <div className="d-flex">
            <div className="col-xs-12 col-md-4 col-lg-6 explore-image">
             
             <img src={ConnectEveryone} alt="" />
          
         </div>
         <div className="col-xs-12 col-md-8 col-lg-6">
           <div className="connect-everyone-content">
             <h2>
               <b>Building bridges, not walls, with connectivity.</b>
             </h2>
             <h4>Accelerating the beginning, for a successful outcome.</h4>
             <ul className="explore-ul">
               <li>
                  Providing comprehensive visibility, from management to on-site personnel.
               </li>
               <li>
                Enabling seamless operation from any location and at any time, via mobile device access.
               </li>
               <li>
                  Accelerating essential processes through collaboration with trade partners.
               </li>
               <li>
                 Recording, searching, and sharing observations, with online or offline availability.
               </li>
             </ul>
             {/* <Button className="explore-btn" bsStyle="primary">Explore More</Button> */}
           </div>
         </div>
            </div>
            <div className="d-flex">
            <div className="col-xs-12 col-md-6 col-lg-6">
              <div className="analytics-content">
                <h2>
                  <b>Robust reporting and analytics capabilities.</b>
                </h2>
                <h4>
                  SafetyLoop delivers real-time project intelligence to all stakeholders via its proprietary BI and in-system dashboard technology, directly from the construction site.
                </h4>
                <ul className="explore-ul">
                  <li>
                    Accelerating decision making with executive-level BI reporting capabilities.
                  </li>
                  <li>
                    Enhancing collaboration between field and office teams with live safety and ops dashboards
                  </li>
                  <li>
                    Optimizing essential processes by collaborating with trade partners
                  </li>
                  <li>
                    Trade dashboards offer transparency to all stakeholders
                  </li>
                </ul>
                {/* <Button className="explore-btn" bsStyle="primary">Explore More</Button> */}
              </div>
            </div>
            <div className="col-xs-12 col-md-6 col-lg-6 explore-image">
              <img src={Analytics} alt="" />
            </div>
            </div>
            <div className="d-flex">
            <div className="col-xs-12 col-md-6 col-lg-6 explore-image">
                <img src={Integrate} alt="" />
            </div>
            <div className="col-xs-12 col-md-6 col-lg-6">
              <div className="integrate-with-ease-content">
                <h2>
                  <b>Easily integrating with existing systems</b>
                </h2>
                <h4>Integrations and Partners </h4>
                <ul className="explore-ul">
                  <li>
                    Safetyloop seamlessly integrates with top construction technology platforms to streamline your on-site operations.
                  </li>
                  <li>
                    Complete your tech toolbox and achieve excellence across your safety system.
                  </li>
                 
                </ul>
                <Button className="explore-btn " bsStyle="primary">Explore More</Button>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <div className="process-section">
       <div className="home-container">
       <div className="process-section-content">
            <h1>Scalable, Flexible, 100% your process.</h1>
            <p>SafetyLoop fits all sizes of Owners, Engineers and General Contractors.
              With dozens highly configurable modules</p>
            {/* <h3>Explore the platform modules</h3> */}
        </div>
        {/* <div className="process-section-report d-flex">
            <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 ">
              <img src={ProcessUser}  alt="" />
            <h4><b>Safety Plan Management</b></h4>
            <p>Easy to use software that works around your processes.</p>
            </div>

            <div className="col-xs-12 col-sm-6  col-md-3 col-lg-3 ">
              <img src={ProcessUser}  alt="" />
            <h4><b>BI Report</b></h4>
            <p>Easy to use software that works around your processes.</p></div>

            <div className="col-xs-12 col-sm-6  col-md-3 col-lg-3">
              <img src={ProcessUser}  alt="" />
            <h4><b>Pre Task Management</b></h4>
            <p>Easy to use software that works around your processes.</p></div>

            <div className="col-xs-12 col-sm-6  col-md-3 col-lg-3">
              <img src={ProcessUser}  alt="" />
            <h4><b>Equipment Orientations</b></h4>
            <p>Easy to use software that works around your processes.</p></div>

            <div className="col-xs-12 col-sm-6  col-md-3 col-lg-3">
              <img src={ProcessUser}  alt="" />
            <h4><b>Safety Plan Management</b></h4>
            <p>Easy to use software that works around your processes.</p>
            </div>

            <div className="col-xs-12 col-sm-6  col-md-3 col-lg-3">
              <img src={ProcessUser}  alt="" />
            <h4><b>BI Report</b></h4>
            <p>Easy to use software that works around your processes.</p></div>

            <div className="col-xs-12 col-sm-6  col-md-3 col-lg-3">
              <img src={ProcessUser}  alt="" />
            <h4><b>Covid Management</b></h4>
            <p>Easy to use software that helps ensure health standards</p></div>

            <div className="col-xs-12 col-sm-6  col-md-3 col-lg-3">
              <img src={ProcessUser}  alt="" />
            <h4><b>Equipment Orientations</b></h4>
            <p>Easy to use software that works around your processes.</p></div>

        </div> */}
       </div>
      </div>
      <div className="personal-demo-section" >
        <div className="home-container">
          <div className="d-flex personal-demo-content">
            <div className="col-xs-12 col-md-5 col-lg-5 ">
                  <h3><b>Get a personal demo</b></h3>
                  <p>
                    See SafetyLoop in action. Request a demo 
                    and we'll be in touch shortly.
                  </p>
              <a href="/contact">
                <Button bsStyle="warning" className="demo-btn" >Request a Demo</Button>
              </a>
            </div>
          </div>
        </div>
      </div>











      <div className="safety-section">
        <div className="home-container">
          <div className="safety-section-details">
            <h4><b>Asset Management</b></h4>
            <h3>Managing assets with ease and safety</h3>
            <p>
              Maintain a digital record of compliance and get a comprehensive overview of your assets. Access the history and upcoming activities of your assets in a centralized location.
            </p>
            <p>
            
            </p>          
            {/* <img src={video} alt="" /> */}
          </div>
        </div>
      </div>

      


      <div className="explore-section">
        <div className="home-container">
          <div className="explore-details">
            <div className="d-flex">
            <div className="col-xs-12 col-md-4 col-lg-6 explore-image">
             
             <img src={ConnectEveryone} alt="" />
          
         </div>
         <div className="col-xs-12 col-md-8 col-lg-6">
           <div className="connect-everyone-content">
             <h2>
               <b>Building bridges, not walls, with connectivity.</b>
             </h2>
             <h4>Accelerating the beginning, for a successful outcome.</h4>
             <ul className="explore-ul">
               <li>
                  Providing comprehensive visibility, from management to on-site personnel.
               </li>
               <li>
                Enabling seamless operation from any location and at any time, via mobile device access.
               </li>
               <li>
                  Accelerating essential processes through collaboration with trade partners.
               </li>
               <li>
                 Recording, searching, and sharing observations, with online or offline availability.
               </li>
             </ul>
             {/* <Button className="explore-btn" bsStyle="primary">Explore More</Button> */}
           </div>
         </div>
            </div>
            <div className="d-flex">
            <div className="col-xs-12 col-md-6 col-lg-6">
              <div className="analytics-content">
                <h2>
                  <b>Robust reporting and analytics capabilities.</b>
                </h2>
                <h4>
                  SafetyLoop delivers real-time project intelligence to all stakeholders via its proprietary BI and in-system dashboard technology, directly from the construction site.
                </h4>
                <ul className="explore-ul">
                  <li>
                    Accelerating decision making with executive-level BI reporting capabilities.
                  </li>
                  <li>
                    Enhancing collaboration between field and office teams with live safety and ops dashboards
                  </li>
                  <li>
                    Optimizing essential processes by collaborating with trade partners
                  </li>
                  <li>
                    Trade dashboards offer transparency to all stakeholders
                  </li>
                </ul>
                {/* <Button className="explore-btn" bsStyle="primary">Explore More</Button> */}
              </div>
            </div>
            <div className="col-xs-12 col-md-6 col-lg-6 explore-image">
              <img src={Analytics} alt="" />
            </div>
            </div>
            <div className="d-flex">
            <div className="col-xs-12 col-md-6 col-lg-6 explore-image">
                <img src={Integrate} alt="" />
            </div>
            <div className="col-xs-12 col-md-6 col-lg-6">
              <div className="integrate-with-ease-content">
                <h2>
                  <b>Easily integrating with existing systems</b>
                </h2>
                <h4>Integrations and Partners </h4>
                <ul className="explore-ul">
                  <li>
                    Safetyloop seamlessly integrates with top construction technology platforms to streamline your on-site operations.
                  </li>
                  <li>
                    Complete your tech toolbox and achieve excellence across your safety system.
                  </li>
                 
                </ul>
                <Button className="explore-btn " bsStyle="primary">Explore More</Button>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
