import React, { useEffect } from "react";

const Disclaimer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    
    
      <div className="main-wrap common-container privacy-policy">
        <div className="content">
          <div className="container-fluid">
            <div name="termly-embed" data-id="ab7aa3a9-3b1b-44b6-a28f-34c399523fbd" data-type="iframe"></div>

          </div>
        </div>
      </div>
    
  );
};





(function(d, s, id) {
  var js, tjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) return;
  js = d.createElement(s); js.id = id;
  js.src = "https://app.termly.io/embed-policy.min.js";
  tjs.parentNode.insertBefore(js, tjs);
}(document, 'script', 'termly-jssdk'));



export default Disclaimer;