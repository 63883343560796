import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import {
  Modal,
  FormControl as Input,
} from "react-bootstrap";
import { toast } from "react-toastify";
import Application from "../../../../config/ApplicationWrapper";
import axios from "../../../../config/axiosConfig";


export default function CreateMaintenance(props) {
  const { show, closepopup, details, parentId } = props;
  const [onSubmit, setSubmit] = useState(false);
  const [disabled, setDisable] = useState(false);
  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: ""
  });

  useEffect(() => {
    details && setValues(details)
  }, [show])

  const handleChange = (name, event) => {
    setValues({ ...values, [name]: event.target.value });
    setDisable(false);
    setSubmit(false);
  };


  const createOrUpdate = async () => {
    try {
      setSubmit(true);
      setDisable(true);
      if (!values.first_name || !values.last_name) {
        return false
      }

      if (details && details.identifier) {
        await axios.patch(`${Application.api_url}/api/v2/internal_subs/${parentId}/internal_employees/${details.identifier}`, values).then((result) => {
          if (result.data && result.data.success === true) {
            toast.success(`Update Successfully`)
            closepopup(result.data)
          }
        }).catch((error) => {
          error.response.data.message.map((msg) => {
            toast.error(msg)
          });
        })
      } else {
        await axios.post(`${Application.api_url}/api/v2/internal_subs/${parentId}/internal_employees`, values).then((result) => {
          if (result.data && result.data.success === true) {
            toast.success(`Created Successfully`)
            closepopup(result.data)
          }
        }).catch((error) => {
          error.response.data.message.map((msg) => {
            toast.error(msg)
          });
        })
      }

    } catch (err) {
      console.log("err===>", err);
    }
  };

  return (
    <Modal
      bsSize="large"
      show={show}
      onHide={(e) => {
        closepopup();
      }}
      className="create-maintanance-popup internal-sub-employee-popup"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <i className="fa fa-plus" aria-hidden="true"></i>
          <b> {details && details.identifier ? "Update Employee" : "Add Employee"} </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="create-form">
          <div className="row">
            <div className="col-md-6">
              <label>
                First Name<text style={{ color: "red" }}>*</text>
              </label>
              <TextField
                required
                fullWidth
                className="text-field"
                id="outlined-required"
                error={!values.first_name && onSubmit}
                helperText={
                  !values.first_name && onSubmit ? "First Name is required" : ""
                }
                value={values.first_name}
                onChange={(e) => {
                  handleChange("first_name", e);
                }}
              />
            </div>
            <div className="col-md-6">
              <label>
                Last Name<text style={{ color: "red" }}>*</text>
              </label>
              <TextField
                required
                fullWidth
                className="text-field"
                id="outlined-required"
                error={!values.last_name && onSubmit}
                helperText={
                  !values.last_name && onSubmit ? "Last Name is required" : ""
                }
                value={values.last_name}
                onChange={(e) => {
                  handleChange("last_name", e);
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label>Email</label>
              <TextField
                fullWidth
                className="text-field"
                id="outlined-required"
                value={values.email}
                onChange={(e) => {
                  handleChange("email", e);
                }}
              />
            </div>
            <div className="col-md-6">
              <label>Contact Number</label>
              <TextField
                fullWidth
                className="text-field"
                id="outlined-required"
                value={values.phone_number}
                onChange={(e) => {
                  handleChange("phone_number", e);
                }}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn button cancel-btn"
          onClick={() => {
            closepopup()
          }}
        >
          Cancel
        </button>
        <button
          className="btn btn-primary"
          disabled={disabled}
          onClick={(event) => {
            createOrUpdate();
          }}
        >
          {details && details.identifier ? "Update" : "Add Employee & Send information"}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
