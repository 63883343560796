import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button } from 'react-bootstrap'


var axios = require('axios');

const initialState = {
    summary: {},
    client: "",
    results:[],
    all_files:[],
    files:[],
    company_name: "",
    show: false,
    video_url: ''
};


class Client extends React.Component{
    constructor(props)
    {
        super(props);
        this.state = initialState

        this.closeForm = this.closeForm.bind(this);
        this.openForm = this.openForm.bind(this);
    }

    componentDidMount() {
        var that = this;
        that.populateData()
    }

    openForm(file_name){
        var that = this
        axios.post('/clients/msm/download', {
            url: file_name, video_format: true
        })
            .then(function(response) {
                let video_url = response.data[0]['file_url']
                that.setStateIframe(video_url, that)
            })
            .catch(function(error) {
                console.log(error)

            });

    }

    setStateIframe(video_url, that) {
        that.setState({
            show: true,
            video_url: video_url
        });
    }



    closeForm() {
        var that = this
        that.setState({ show: false });
    }


    componentDidUpdate(prevProps) {
        window.scrollTo(0,0);
        let _this = this;
        this.configAccordian(_this, $('.accordion'));
    }

    configAccordian(_this, selector) {
        selector.accordion({
            active: false,
            autoHeight: false,
            navigation: true,
            heightStyle: "content",
            collapsible: true,
            beforeActivate: function (event, ui) {
                let folderId = $(event.currentTarget).attr('folder-id');
                if (folderId) {
                    let id = folderId.replace(':', '_');
                    let path = $(event.currentTarget).attr('folder-path');

                    axios.get('/clients/msm/iauditor/dropbox_nested', {
                        params: {parent_id: folderId, path}
                    }).then(function (response) {
                        ReactDOM.render(_this.renderNestedFolders(response.data), document.getElementById(`child_${folderId.replace(':', '_')}`));

                        _this.configAccordian(_this, $(`#child_${id} .accordion`));
                    }).catch(function (error) {
                        console.log(error);
                    })
                }
            }
        });
    }

    populateData(){
    var that = this;
    $(".loading").show()
    // debugger;
    // var company = this.props.location.state.company_name
    var company = this.props.location.search.split("=")[1];

    axios.get('/clients/msm/iauditor/dropbox_poc',{
        params: {company_name: company}
    })
        .then(function(response){
            $(".loading").hide();
            that.setStateData(response.data, that);
            if(response.data.all_files.length==0) {
                $('.iferror').html('Your company is not set-up for this feature - Please contact your admin');
            }
        })
        .catch(function(error){
            console.log(error);
        })
}
    setStateData(response, that){
    that.setState({
        results: response.folders,
        all_files: response.all_files,
        files: response.files,
        company_name: response.client
    });
}

    files_blank(){
    if (this.state.all_files ==[]) {
        return (
            <div className="small-text">
            Sorry no files found
            </div>
            );
    }
}

    renderTempChildren(temp){
    var res = Object.keys(temp)
    if (res.length > 0 && temp.children.length > 0){
        return temp.children.map((child,key) => {
            return (
                <Fragment>{this.tempfolderResultData(child)}</Fragment>
                );
    })
}
}

tempfolderResultData(child){
    return this.state.results.map((folder,key) => {
        var temp2= {}
        if (folder.id ==child) {
        temp2 = folder
        return(
            <Fragment>{this.tempChildrenData(temp2)}</Fragment>

            );
    }
})
}

renderNestedElements(temp, all_files){
    var res = Object.keys(temp)
    if (res.length > 0) {
        return (
            <div className="row" style={{marginTop:'15px'}}>
                <table className="accordian-table">
                    <thead>
                        <tr>
                            <td>
                            File Name
                            </td>
                            <td>
                            Size
                            </td>
                            <td>
                            Action(s)
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {temp.files.map((r,key) => {
                            return (
                            <tr key = {key}>
                                <td>
                                    <strong>
                                        <div className="small-text" style={{textAlign:'left'}}>
                                                 {r.name}
                                        </div>
                                    </strong>
                                </td>
                                <td>
                                {all_files.filter(x => x.name === r.name).map(x => x.size)} kb
                                </td>
                                <td>
                                    <form action="/clients/msm/download" method="post">
                                        <input name="url" type="hidden" value={r.path}></input>
                                        <input type="submit" value="Open"></input>
                                    </form>
                                </td>
                             </tr>);
                        })
                        }
                    </tbody>
                </table>
            </div>
    );
    }
    else{
        return (<div></div>);
    }
}

tempChildren(temp){
    return (
        <div height="300">
            {this.renderTempChildren(temp)}
            {this.renderNestedElements(temp,this.state.all_files)}
        </div>
        );
}


tempChildrenData(temp){
    var res = Object.keys(temp)
    if (res.length > 0){
        return (
            <div className="accordion">
                <h3>
                    <i className="fa fa-folder"></i>
                    {temp.name}
                </h3>
             {this.tempChildren(temp)}
            </div>
            );
    }
}

folderResultData(child){
    return this.state.results.map((folder,key) => {
        var temp= {}
        if (folder.id ==child) {
        temp = folder
        }
    return(
        <Fragment>{this.tempChildrenData(temp)}</Fragment>
        );
    })
}

folderChildren(child_folder){
    return child_folder.map((child,key) => {
        return (
            <Fragment>{this.folderResultData(child)}</Fragment>
            );
})
}

renderNestedFolders(data){
    return (
        <Fragment>
            {this.renderFolderData(data.folders)}
            {this.renderTempDataChildren(data.files)}
        </Fragment>
    );
}

renderTempDataChildren(files){
    return (
        <div className="row" style={{marginTop:'15px'}}>
            <table className="accordian-table" style={{borderTop:'1px solid #000'}}>
                <thead>
                <tr>
                    <td>
                        File Name
                    </td>
                    <td>
                        Size
                    </td>
                    <td>
                        Action(s)
                    </td>
                </tr>
                </thead>
                <tbody>
                {files.map((r,key) => {
                    var file_name = r.name.split(".")[1]
                    return (
                        <tr key = {key}>
                            <td>
                                <strong>
                                    <div className="small-text" style={{textAlign:'left'}}>
                                        {r.name}
                                    </div>
                                </strong>
                            </td>
                            <td>
                                {r.size} kb
                            </td>
                            <td>

                                {
                                    (file_name == 'mp4' || file_name == 'MOV'?
                                        (<button className="btn btn-primary btn-xs" style={{fontSize: "13px"}}
                                                 onClick={() => { this.openForm(r.path_lower) }}>Play
                                        </button>)
                                        : (<form action="/clients/msm/download" method="post">
                                                <input name="url" type="hidden" value={r.path_lower}></input>
                                                <input type="submit" value="Open"></input>
                                            </form>)
                                )}

                            </td>

                        </tr>)
                })
                }
                </tbody>
            </table>
        </div>
    );
}

renderFolderHeader(folder){
    return (
        <h3 folder-id={folder.id} folder-path={folder.true_folder}>
            <i className="fa fa-folder"></i>
            {folder.name}
        </h3>
        );
}

renderFolderData(folders){
    return folders.map((f,key) => {
        return(
            <Fragment key={key}>
                <div className="small-12 medium-10 columns" style={{paddingLeft:'10px',width: "95%"}}>
                    <div className="accordion ui-accordion ui-widget ui-helper-reset" role="tablist">
                        {this.renderFolderHeader(f)}
                        <div id={`child_${f.id.replace(':', '_')}`}/>
                    </div>
                </div>
            </Fragment>
        );
    })
}

render() {
    const { filters, summary, graph, employees_info, graph_title, company_name } = this.state;
    const { location: { state } } = this.props;

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="small-12 columns client-login-details">
                    Client Logged in: 
                    <strong>
                      {decodeURI(company_name)}
                    </strong>
                    <br/>
                    Updated Hourly
                    <br/>
                    <div className="iferror">
                        
                    </div>
                    
                    
                </div>
            </div>

            {/*<div className="total-files row">*/}
            {/*    <div className="small-12 columns">*/}
            {/*    Total Files  {this.state.files.length}*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className="client-folder row">
                <div className="medium-1 columns">
                    <p></p>
                </div>
                {this.renderFolderData(this.state.results)}
            </div>

            <div>
                <Modal show={this.state.show} onHide={this.closeForm} size="lg"
                       aria-labelledby="contained-modal-title-vcenter"
                       centered="true">

                    <Modal.Body>
                        <iframe
                            src={this.state.video_url}
                            width="560" height="314"
                            style={{"border":"none","overflow":"hidden"}} scrolling="no" frameBorder="0" allowtransparency="true" allow="encrypted-media" allowFullScreen={true}>
                        </iframe>
                    </Modal.Body>

                </Modal>
            </div>

        </div>

        )
    }
}

export default Client;
