import React from "react";


export const ComponentToPrint = React.forwardRef((props, ref) => {
    return (
        <div ref={ref} style={{ textAlign: "center"}} className="print-qr-body">
        <img alt="" src={props.pageData.qr_code ? props.pageData.qr_code.url : ""} />
          <p
            style={{ fontWeight: "500", fontSize: "38px", color: "#080808" }}
          >
            {props.pageData.name}
          </p>
          <span  style={{ fontWeight: "500", fontSize: "20px", color: "#909090" }}>Serial - {props.pageData.serial}</span>
          <p  style={{ fontWeight: "500", fontSize: "27px", color: "#080808" }}>{props.pageData.company_name}</p>
      </div>
    );
  });