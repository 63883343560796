import React, { useEffect, useMemo, useState } from "react";
import CustomTable, {
  ActionMenu,
  CustomPagination,
  HeaderItem,
  TagRender,
} from "../../../shared/components/table";
import {
  Autocomplete,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import useFetch from "../../../shared/components/userFetch";
import { ShowNotification } from "../../../shared/components/showNotification";
import useFilterChange from "../../../shared/components/useFilterChange";
import SearchIcon from "../../../../assets/fonts/Search.svg";
import { withRouter } from "react-router-dom";
import SharedFormEdit from "./SharedFormEdit";

const SharerdFormList = ({ group = false, match }) => {
  const { id } = match.params; // Access the 'id' param from the URL

  const initialState = {
    page: 1,
    limit: 20,
  };
  const {
    handleOnFilterChange,
    filterState,
    filterData,
    setFilterState,
    handleSort,
  } = useFilterChange(initialState);
  const [notification, setNotification] = useState({
    open: false,
    message: "test",
    severity: "success",
  });
  const [editItem, setEditItem] = useState('')

  const {
    data: responseData,
    isLoading,
    error,
    refetch,
  } = useFetch(
    `/api/v2/entity_management/global_projects/${id}/load_group_shared_forms?owned_group=${group ? true : false}&shared_group=true`,
    {
      filters: filterData,
      pagination: { page: filterState.page, limit: filterState.limit },
      sort: filterState.sort,
    }
  );

  const { data = [], metadata = {} } = responseData || {};
  const { groups = [] } = {};

  const groupsFilterData = [
    { label: "All", value: "All" },
    ...groups.map((e) => ({ ...e, label: e.key, id: e.value })),
  ];

  const callRefetch = () => {
    refetch({
      filters: filterData,
      pagination: { page: filterState.page, limit: filterState.limit },
      sort: filterState.sort,
    });
  };
  useEffect(() => {
    if (!isLoading) {
      callRefetch()
    }
  }, [filterState]);

  const handleEdit = (ele) => {
    setEditItem({ ...ele });
  };

  const handleCloseNotification = () => {
    setNotification((prev) => ({
      ...prev,
      open: false,
    })); // Hide notification, retain other state values
  };

  const headers = [
    <TableCell>
      <HeaderItem
        title="Form Name"
        isSort
        handleSort={() => handleSort("company_name")}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            disableClearable
            isOptionEqualToValue={(option, value) => option.value === value}
            onChange={(_, v) =>
              handleOnFilterChange(v || "All", "audit_template_name_cont")
            }
            value={
              groupsFilterData.find(
                (e) => e.value === filterData["audit_template_name_cont"]
              ) || "All"
            }
            options={groupsFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: "100%", background: "#fff", border: "none" }}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell>
      <HeaderItem
        title="Form Frequency"
        isSort
        handleSort={() => handleSort("company_name")}
      />
    </TableCell>,
    <TableCell>
      <HeaderItem
        title="Notification Due"
        isSort
        handleSort={() => handleSort("company_name")}
      />
    </TableCell>,
    <TableCell>
      <HeaderItem
        title="Assignee Members"
        isSort
        handleSort={() => handleSort("group_name")}
      />
    </TableCell>,
    <TableCell className="right" style={{ minWidth: "100px" }}>
      <HeaderItem title="Actions" />
    </TableCell>,
  ];

  const rows = useMemo(
    () =>
      data.map((ele, index) => {
        const {
          template_name,
          due_time,
          inspection_frequency,
          assignee_members = [],
        } = ele || {};
        return (
          <TableRow key={index} className={index % 2 === 0 ? "even-row" : "odd-row"}>
            <TableCell>{template_name}</TableCell>
            <TableCell>{inspection_frequency}</TableCell>
            <TableCell>{due_time}</TableCell>
            <TableCell>
              <TagRender data={assignee_members} />
            </TableCell>
            <TableCell className="right" style={{ minWidth: "100px" }}>
              <ActionMenu>
                {(setClose) => (
                  <MenuItem onClick={() => handleEdit(ele)}>
                    Edit
                  </MenuItem>
                )}
              </ActionMenu>
            </TableCell>
          </TableRow>
        );
      }),
    [data]
  );

  return (
    <>
      <ShowNotification
        open={notification.open}
        onClose={handleCloseNotification}
        severity={notification.severity}
        message={notification.message}
      />
      {editItem.id && <SharedFormEdit edit={editItem} setEdit={setEditItem} setFilterState={setFilterState} />}
      <CustomTable headers={headers} rows={rows} isSliderScroll={false} renderActiveFilter={false} />
      {!!metadata.count && (
        <CustomPagination
          totalPage={metadata.count}
          lastPage={metadata.last}
          currentPage={filterState.page}
          handlePageChange={(_, nextPage) => {
            setFilterState((prev) => ({ ...prev, page: nextPage }));
          }}
          rowOptions={[20, 50, 100]}
          rowPerPage={filterState.limit}
          handleRowPerPageChange={(targetValue) => {
            setFilterState((prev) => ({
              ...prev,
              limit: parseInt(targetValue, 10),
              page: 1,
            }));
          }}
        />
      )}
    </>
  );
};

export default withRouter(SharerdFormList);
