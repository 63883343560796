import React from 'react'
import { Button } from 'react-bootstrap'

export default function BookDemoSection() {
    return (
        <div className="Product-Page">
            <div className="personal-demo-section" >
                <div className="home-container">
                    <div className="d-flex personal-demo-content feature-page-demo-section">
                        <div className="col-xs-12 col-md-5 col-lg-5 ">
                            <h3><b>Experience SafetyLoop in Action</b></h3>
                            <p>
                                Request a demo today and discover the power of streamlined safety.
                            </p>
                            <a href="https://cxtzl04.na1.hs-sales-engage.com/Ctc/LY+23284/cXtZl04/Jl22-6qcW7lCdLW6lZ3p3V8Lpkb3WF7Z2W8_8h3c31qxyfN60bD2lc6pVRW67RVDV8NXr0YW1s1m2n3DbFP_N95Q7FVxndjdW2x4LG87jftWRW5tF2rT7rJM5sW1L3k1c3bzWm1VwTQ138Lw5qjW4scXLF3vP7clW3PRdwc5cyRLJW8CcJ484VV_d9W8tdyVf5gF5k-W3Zvk8c8FncpkW5QKM2q7vpk79W8tCY3R6dwxsjW8NyZkZ3JJznsN3vnLnRTJ89sV2cqLF5hChJ3W5Wz_cK4tgyq_N8FxPjY_5765W73hhnF5Hbg-gVTm4Q-6r1kXLf1mVlDb04" target="_blank">
                                <Button className="contact-btn" >Request Demo</Button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
