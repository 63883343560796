import React  from 'react'
import { Table, Modal, Button } from "react-bootstrap";

const EmployeeTrainings = ({user_trainings, show, onModalClose}) => {
    let user = user_trainings.user_info
    let trainings = user_trainings.trainings

    const renderTrainings = trainings.map((training, index) =>
    {
        return (
            <tr key={index}>
                <td>{training.topic}</td>
                <td>
                    <a href={training.pdf_link} target="_blank">
                        PDF Link
                    </a>
                </td>
                <td>{training.completion_date}</td>
                <td>{training.expiry_date}</td>
                <td>{training.status}</td>
            </tr>
        )
    });

    const renderContent = (trainings) => {
        if (trainings.length == 0){
            return <h2 className='align-center'> No Trainings were attached to the user</h2>
        }

       return <Table responsive striped bordered hover>
                <thead>
                    <tr>
                        <th>Topic</th>
                        <th>Pdf link</th>
                        <th>Completion date</th>
                        <th>Expiration date</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {renderTrainings}
                </tbody>
            </Table>
    }

    return (
        <Modal show={show} onHide={() => onModalClose(false)} animation={true} centered>
            <Modal.Header closeButton>
                <Modal.Title>User Trainings</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="employee-info mb-4">
                    <img className="emp-logo" src={user.profile_image}/>
                    <ul>
                        <li>
                            Username - {user.username}
                        </li>
                        <li>
                            Email - {user.email}
                        </li>
                    </ul>
                </div>
                {renderContent(trainings)}
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btn-primary" onClick={() => onModalClose(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default EmployeeTrainings;
