import React from 'react'
import HeroSection from './CommonComponents/HeroSection'
import FeaturesSection from './CommonComponents/FeaturesSection'
import s1 from '../../../assets/images/sub1.png'
import s2 from '../../../assets/images/sub2.png'
import s3 from '../../../assets/images/sub3.png'
import s4 from '../../../assets/images/sub4.png'
import ParallaxSection from '../../pages/HomePage/Components/ParallaxSection'
import HomepageDemoSection from '../../pages/HomePage/Components/HomepageDemoSection'

export default function SubcontractorManagement() {
  return (
    <>
      <HeroSection heroText={"Streamlined Subcontractor Management"} subText={"Efficiently oversee subcontractors within your safety eco-system using SafetyLoop's comprehensive tools. Enhance control, communication, and compliance for a more robust health and safety management system."} />
      <FeaturesSection
        headerText={"Effortless Subcontractor Listing"}
        subText={"SafetyLoop simplifies the management of subcontractors, helping you maintain a comprehensive directory of your partners."}
        featureHighlights={["Create a centralized list of subcontractors for easy access and tracking.",
          "Quickly add, edit, or remove subcontractor details as needed.",
          "tay organized with up-to-date contact information and project history."]}
        sectionBackground='dark'
        sectionAlign="image-right"
        featureImageSrc={s1}
      />
      <FeaturesSection
        headerText={"Efficient Employee Tracking"}
        subText={"Manage subcontractor employees seamlessly within SafetyLoop to ensure compliance and safety across your projects."}
        featureHighlights={["Associate employees with subcontractor profiles for accurate record-keeping.",
          "Track individual qualifications, certifications, and training requirements.",
          "Monitor workforce compliance and safety across all projects."]}
        featureImageSrc={s2}
      />
      <FeaturesSection
        headerText={"Streamlined Trainings and Documentation"}
        subText={"SafetyLoop helps you centralize training records and public documents, making compliance and audits hassle-free."}
        featureHighlights={["Record and track training sessions for subcontractor employees.",
          "Store essential public documents such as insurance certificates and permits.",
          "Ensure easy access to critical documentation for audits and inspections."]}
        sectionBackground='dark'
        sectionAlign="image-right"
        featureImageSrc={s3}
      />
      <FeaturesSection
        headerText={"Efficient Project & Document Assignment"}
        subText={"SafetyLoop allows you to seamlessly assign subcontractors to specific projects, ensuring efficient project management."}
        featureHighlights={["Assign subcontractors to projects based on their qualifications and expertise.",
          "Monitor project progress, safety, and compliance in real-time.",
          "Facilitate clear communication and collaboration between subcontractors and project teams."]}
        featureImageSrc={s4}
      />
      <div className="Home-Page sl-home-wrapper" style={{ paddingTop: 0 }}>
        <ParallaxSection />
      </div>
      <div className="features-homepage-wrapper">
        <HomepageDemoSection mergeTop={false} />
      </div>
    </>
  )
}
