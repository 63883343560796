import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "../../../../config/axiosConfig";
import Application from "../../../../config/ApplicationWrapper";

export default function ViewPopup(props) {
    const { show, close, details } = props;
    const [pageDetails, setPageDetails] = useState("");

    const handleStatusClose = () => {
        close();
    };

    useEffect(() => {
        getPageDetails(details)
    }, [])


    const getPageDetails = async (details) => {
        try {
            let resultObj = await axios.get(`${Application.api_url}/api/v2/suppliers/${details}`);
            if (resultObj.status == 200) {
                let details = resultObj.data;
                setPageDetails(details && details.data)
            }
        } catch (err) {
            console.log("error==>", err);
        }
    }



    return (
        <Modal
            bsSize="lg"
            show={show}
            onHide={(e) => {
                handleStatusClose();
            }}
            className="qr-modal-wrap add-task-modal pf-model internal-sub-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="pf-model-header">
                        <p className="pf-model-title">View Supplier Info</p>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {pageDetails &&
                    <div>
                        <div className="company-info-header"> 
                            <div className="company-infos">
                                <p className="name">{pageDetails.name}</p>
                                <p className="desc">{pageDetails.company_info}</p>
                            </div> 
                        </div>
                        <div className="company-details">
                            <div className="general-dtls">
                                <p className="title">General Details</p>
                                <ul className="list">
                                    <li className="list-dls"><span>Last Order Date</span><span>{pageDetails.last_order_at}</span></li> 
                                    <li className="list-dls"><span>Phone</span><span>{pageDetails.contact_phone_number}</span></li> 
                                    <li className="list-dls"><span>Name</span><span>{pageDetails.name}</span></li>
                                    <li className="list-dls"><span>Email</span><span>{pageDetails.contact_email}</span></li> 
                                </ul>
                            </div>
                            <div className="admin-details"> 
                                <p>Location</p>
                                <ul className="list">
                                    <li className="list-dls"><span>Address</span><span>{pageDetails.address}</span></li>
                                    <li className="list-dls"><span>City </span><span>{pageDetails.city}</span></li>
                                    <li className="list-dls"><span>State</span><span>{pageDetails.state}</span></li>
                                    <li className="list-dls"><span>Country</span><span>{pageDetails.country}</span></li>
                                    <li className="list-dls"><span>Zip Code</span><span>{pageDetails.zip_code}</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                }
            </Modal.Body>
        </Modal>
    );
}
