import React, { useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Cookies from "js-cookie";
const cookieDetails = Cookies.get("token");
import SignatureCanvas from "react-signature-canvas";
import Application from "../../../../config/ApplicationWrapper";
import axios from "axios";

export default function Signature(props) {
    const { signature, closepopup, status, timeCardId } = props;
    const [signatureType, setSignatureType] = React.useState("write");
    const [imageURL, setImageURL] = React.useState(null);
    const [image, setImage] = React.useState(null);
    let signPad = useRef({});
    const onhandleStatusClose = () => {
        closepopup();
    };

    const clear = () => {
        signPad.current.clear();
        setImageURL("");
    };

    const showSign = () => {
        const URL = signPad.current.getTrimmedCanvas().toDataURL("image/png");
        setImageURL(URL);
    };

    const submit = async () => {
        let photoparams;
        if (signatureType == "write") {
            photoparams = signPad.current.getTrimmedCanvas().toDataURL("image/png");
        } else {
            photoparams = image;
        }
        try {
            const formData = new FormData();
            formData.append("signature", photoparams);
            const config = {
                headers: {
                    "content-type": "multipart/form-data",
                    Authorization: cookieDetails,
                },
            };

            axios.defaults.headers.common["Authorization"] = cookieDetails;

            const result = await axios.patch(
                `${Application.api_url}/api/v2/time_cards/${timeCardId}/${status}`,
                formData
            );

            closepopup(result);
        } catch (err) {
            console.log("error==>", err);
        }
    };

    return (
        <Modal
            show={signature}
            onHide={onhandleStatusClose}
            centered={true}
            className="add-task-modal signature-popup"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <b> Add Signature </b>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormControl>
                    <FormLabel>Signature Type</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        defaultValue={signatureType}
                    >
                        <FormControlLabel
                            control={<Radio />}
                            label="Signature"
                            value="write"
                            onChange={(e) => {
                                setSignatureType(e.target.value);
                            }}
                        />
                        <FormControlLabel
                            value="upload"
                            control={<Radio />}
                            label="Upload Signature"
                            onChange={(e) => {
                                setSignatureType(e.target.value);
                            }}
                        />
                    </RadioGroup>
                </FormControl>
                {signatureType == "upload" && (
                    <div>
                        <FormLabel>Upload Signature</FormLabel>
                        <div className="upload-section">
                            <div className="upload-button">
                                <input
                                    type="file"
                                    onChange={(event) => {
                                        setImage(event.target.files[0]);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {signatureType == "write" && (
                    <div>
                        <FormLabel> Signature</FormLabel>
                        <div className="upload-signature-section">
                            <SignatureCanvas
                                penColor="black"
                                backgroundColor="#cccccc"
                                canvasProps={{
                                    width: 500,
                                    height: 200,
                                    className: "sigCanvas",
                                }}
                                ref={signPad}
                            />
                            <Button
                                style={{
                                    background: "blue",
                                    color: "white",
                                    border: "none",
                                    marginRight: "10px",
                                }}
                                onClick={(e) => {
                                    showSign();
                                }}
                            >
                                Show
                            </Button>
                            <Button
                                style={{
                                    background: "#FFEAEA",
                                    color: "#FF3030",
                                    border: "none",
                                }}
                                onClick={(e) => {
                                    clear();
                                }}
                            >
                                Clear Signature
                            </Button>
                        </div>
                    </div>
                )}
                {imageURL && (
                    <>
                        <img
                            src={imageURL}
                            alt="signature"
                            className="signature"
                            style={{ width: "120px", marginTop: "20px" }}
                        />
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn-secondary" onClick={onhandleStatusClose}>
                    Cancel
                </Button>
                <Button
                    className="btn-primary"
                    onClick={(e) => {
                        submit();
                    }}
                >
                    Mark As Completed
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
