import React, { useState } from "react";
import SubTabs from "../../../shared/components/subTabs";
import useFilterChange from "../../../shared/components/useFilterChange";
import {
  ButtonCreate,
} from "../../../shared/components/table";
import ArchiveJobList from "./ArchiveJobList";
import JobProfileList from "./JobProfileList";
import NewJobProfileCreate from "./NewJobProfileCreate";
import NewJobProfileEdit from "./newJobProfileEdit";
import { connect } from "react-redux";

const subTabs = {
  jp: [
    {
      label: "Active Job Profiles",
      value: "job_profile",
    },
    {
      label: "Archived",
      value: "arch",
    },
  ],
};

const JobProfileWrapper = ({ title, active, pageCount }) => {
  const [edit, setEdit] = useState('');
  const initialState = {
    page: 1,
    limit: 20,
  };
  const [create, setCreate] = useState(false);
  const {
    handleOnFilterChange,
    filterState,
    filterData,
    setFilterState,
    handleSort,
  } = useFilterChange(initialState);
  const {
    handleOnFilterChange: handleOnFilterChangeArchive,
    filterState: filterStateArchive,
    filterData: filterDataArchive,
    setFilterState: setFilterStateArchive,
    handleSort: handleSortArchive,
  } = useFilterChange(initialState);

  return (
    <>
      <SubTabs
        tabList={subTabs[active] || []}
        withTitle
        title={title}
        pageCount={pageCount.jp}
        extras={[
          <div style={{ display: "flex", gap: "10px" }}>
            <ButtonCreate
              label="New Job Profile"
              onClick={() => setCreate(true)}
            />
          </div>,
        ]}
      >
        {(activeKey) => (
          <>
            {activeKey === "job_profile" && (
              <>
                <JobProfileList
                  handleOnFilterChange={handleOnFilterChange}
                  filterData={filterData}
                  filterState={filterState}
                  setFilterState={setFilterState}
                  handleSort={handleSort}
                  initialState={initialState}
                  setEdit={setEdit}
                />
              </>
            )}
            {activeKey === "arch" && (
              <>
                <ArchiveJobList
                  handleOnFilterChange={handleOnFilterChangeArchive}
                  filterData={filterDataArchive}
                  filterState={filterStateArchive}
                  setFilterState={setFilterStateArchive}
                  handleSort={handleSortArchive}
                  initialState={initialState}
                />
              </>
            )}
          </>
        )}
      </SubTabs>
      {create && <NewJobProfileCreate open={create} setOpen={setCreate} setFilterState={setFilterState} />}
      {!!edit && <NewJobProfileEdit edit={edit} setEdit={setEdit} setFilterState={setFilterState} />}
    </>
  );
};


const mapStateToProps = (state) => {
  return {
      pageCount: state.CommonReducer.pageCount
  };
};

export default connect(mapStateToProps)(JobProfileWrapper);