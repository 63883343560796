import React from 'react';
import AlarmIcon from "../../../assets/feature-icons/alarm-icon.png";
import HeroBannerImage from "../../assets/images/incidentmanagement.png";


const IncidentManagement = () => {
    return (
        <div className="incident-management-section">
            <img src={HeroBannerImage}  />
        </div>
    );
};

export default IncidentManagement;