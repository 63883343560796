import React, { useEffect, useState } from "react";
import axios from "../../../config/axiosConfig";
import { NavLink } from "react-router-dom";
import { TableContainer, Paper, Pagination, MenuItem, FormControl, Select, Button } from '@mui/material';
import Filter from "../../../assets/images/Filter.png";
import { toast } from "react-toastify";
import loader from "../../../assets/images/newloader.gif";
import EmptyTable from "../../../assets/images/empty-assets.svg";
import Application from "../../../config/ApplicationWrapper";

const Notified = () => {

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [pageDetails, setPageDetails] = useState([]);
    const [paginationStatus, setPaginationStatus] = useState(false);
    const [name, setSearchByName] = useState();
    const [user, setSearchByUser] = useState();
    const [group, setSearchByGroup] = useState();
    const [severity, setSeverity] = useState();
    const [sorting, setSorting] = useState(false);

    useEffect(() => {
        getPageDetails()
    }, [page, limit, sorting, severity])


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getPageDetails()
        }, 2000)

        return () => clearTimeout(delayDebounceFn)
    }, [name, user, group])

    const getPageDetails = async () => {
        try {
            window.scrollTo(0, 0);
            let resultObj = await axios.get(`${Application.api_url}/api/v2/emergency_plans/notified_alerts`, {
                params: {
                    page: page,
                    limit: limit,
                    user_full_name_cont: name,
                    severity_cont: severity,
                    notify_members_user_full_name_cont: user,
                    notify_groups_group_name_cont: group,
                    sort: sorting ? "emergency_plan_name asc" : "emergency_plan_name desc",
                },
            });
            if (resultObj.status == 200) {
                let details = resultObj.data;
                setPageDetails(details ? details.data : [])
                setPageCount(
                    details && details.metadata ? details.metadata.pages : 0
                );
                setPaginationStatus(true)
            }
        } catch (err) {
            console.log("error==>", err);
        }
    };

    const handleChangePage = (event, value) => {
        setPage(value);
    };


    function ChipDesign({ data, type }) {
        let finalData = data.filter(Boolean);
        if (data && data.length >= 3) {
            finalData = finalData.slice(0, 2);
        }
        let objName = type === "user" ? "full_name" : "name";

        return (
            <div>
                {finalData &&
                    finalData.map((obj, index) => (
                        <span className={`badge badge-light responsible-party`}>
                            {obj && obj[objName]}
                        </span>
                    ))}
                {data && data.length >= 3 && (
                    <span className={`badge badge-light responsible-party more-groups`}>
                        + {data.length - 2} More Groups
                    </span>
                )}
            </div>
        );
    }

    const clearFilter = () => {
        setSearchByGroup("");
        setSearchByName("");
        setSearchByUser("");
        setSeverity("");
    }

    const deactivated = async (id, emergencyPlanId) => {
        let result = await axios.patch(`${Application.api_url}/api/v2/emergency_plans/${emergencyPlanId}/deactivate?notifier_id=${id}`)

        if (result.status == 200 && result.data.success === true) {
            toast.success("Deactivated Successfully")
            getPageDetails()
        }
    }

    const NoAssetsFound = () => (
        <div className="no-assets" style={{ textAlign: "center" }}>
            <img src={EmptyTable} />
            <p>
                <b>No Data</b>
            </p>
        </div>
    );

    return (
        <>
            <div class="card asset-tab-landing">
                <div class="card-body">
                    <div className="d-flex align-item-center justify-content-between header-badge">
                        <div className="d-flex align-item-center">
                            <h3>Notified Alerts </h3>
                        </div>
                    </div>
                    <div class="tab-content">
                        <div
                            id="active-risk-assessment"
                            class="tab-pane fade in active active-risk-assessment"
                        >
                            <div className="nestedTable pf-table">
                                <div className="fields-setting">
                                    <div className="asset-active-filters">
                                        Active Filters :
                                        <span className="active-filters">
                                            <span>Name :</span>
                                            <span className="selected">{name ? name : "All"}</span>
                                            <span className={name ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setSearchByName("") }}>X</span>
                                        </span>
                                        <span className="active-filters">
                                            <span>Severity :</span>
                                            <span className="selected">{severity ? severity : "All"}</span>
                                            <span className={severity ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setSeverity("") }}>X</span>
                                        </span>
                                        <span className="active-filters">
                                            <span>Notify Users :</span>
                                            <span className="selected">{user ? user : "All"}</span>
                                            <span className={user ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setSearchByUser("") }}>X</span>
                                        </span>
                                        <span className="active-filters">
                                            <span>Assigned Groups :</span>
                                            <span className="selected">{group ? group : "All"}</span>
                                            <span className={group ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setSearchByGroup("") }}>X</span>
                                        </span>
                                        <span className="active-filters-clear" onClick={(e) => clearFilter()}>
                                            Clear All
                                        </span>
                                    </div>
                                </div>
                                <TableContainer component={Paper}>
                                    <table class="table table-striped">
                                        <thead>
                                            <tr style={{ height: "100px" }}>
                                                <th scope="col" className="risk-assessment-divider">
                                                    <div
                                                        className="d-flex justify-content-between align-item-center"
                                                        onClick={(e) => {
                                                            setSorting(!sorting);
                                                        }}
                                                    >
                                                        Name
                                                        <img src={Filter} alt="" className={sorting ? "up-arrow" : ""} />
                                                    </div>
                                                    <div class="input-group">
                                                        <input
                                                            type="text"
                                                            class="form-control assessment-name"
                                                            placeholder="Search"
                                                            aria-label="Username"
                                                            id="name"
                                                            aria-describedby="basic-addon1"
                                                            name="name"
                                                            onChange={(e) => {
                                                                setSearchByName(e.target.value)
                                                            }}
                                                            value={name}
                                                        />
                                                        <div class="input-group-addon">
                                                            <span class="input-group-text" id="basic-addon1">
                                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th scope="col" className="risk-assessment-divider">
                                                    Severity
                                                    <div class="input-group">
                                                        <select
                                                            class="form-control risk-type"
                                                            onChange={(e) => {
                                                                setSeverity(e.target.value);
                                                            }}
                                                            value={severity}
                                                        >
                                                            <option value="">All</option>
                                                            <option value="minor">Minor</option>
                                                            <option value="major">Major</option>
                                                        </select>
                                                    </div>
                                                </th>
                                                <th scope="col" className="risk-assessment-divider">
                                                    Activated By
                                                </th>
                                                <th scope="col" className="risk-assessment-divider">
                                                    Deactivated By
                                                </th>
                                                <th scope="col" className="risk-assessment-divider">
                                                    Notify Users
                                                    <div class="input-group">
                                                        <input
                                                            type="text"
                                                            class="form-control assessment-name"
                                                            placeholder="Search"
                                                            aria-label="users"
                                                            id="users"
                                                            aria-describedby="basic-addon1"
                                                            name="users"
                                                            onChange={(e) => {
                                                                setSearchByUser(e.target.value)
                                                            }}
                                                            value={user}
                                                        />
                                                        <div class="input-group-addon">
                                                            <span class="input-group-text" id="basic-addon1">
                                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th scope="col" className="risk-assessment-divider">
                                                    Assigned Groups
                                                    <div class="input-group">
                                                        <input
                                                            type="text"
                                                            class="form-control assessment-name"
                                                            placeholder="Search"
                                                            aria-label="group"
                                                            id="group"
                                                            aria-describedby="basic-addon1"
                                                            name="group"
                                                            onChange={(e) => {
                                                                setSearchByGroup(e.target.value)
                                                            }}
                                                            value={group}
                                                        />
                                                        <div class="input-group-addon">
                                                            <span class="input-group-text" id="basic-addon1">
                                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th scope="col" className="table-action">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pageDetails &&
                                                pageDetails.length > 0 &&
                                                pageDetails.map((val, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="alerts-name">
                                                                <NavLink to={`/view-plan/view/${val.emergency_plan.id}`} className="pf-name-list">
                                                                    {val.emergency_plan.name}
                                                                </NavLink>
                                                            </td>
                                                            <td>
                                                                {val.severity}
                                                            </td>
                                                            <td>
                                                                <p className="action-by">{val.activated_by ? val.activated_by.full_name : "-"}</p>
                                                                <p className="action-time">{val.created_at}</p>
                                                            </td>
                                                            <td>
                                                                {val.deactivated_by ? (
                                                                    <>
                                                                        <p className="action-by">{val.deactivated_by ? val.deactivated_by.full_name : "-"}</p>
                                                                        <p className="action-time">{val.updated_at}</p>
                                                                    </>
                                                                ) : (<p className="no-item">-</p>)}
                                                            </td>
                                                            <td>
                                                                <ChipDesign data={val.assigned_users} type="user" />
                                                            </td>
                                                            <td><ChipDesign data={val.assigned_groups} type="group" /></td>
                                                            <td
                                                                className="template-options"
                                                                style={{ textAlign: "center" }}
                                                            >
                                                                <Button variant="outlined" className="deactivate" disabled={val.activated ? false : true} color="error" onClick={(e) => { deactivated(val.id, val.emergency_plan ? val.emergency_plan.id : "") }}>Deactivate</Button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            {pageDetails && pageDetails.length == 0 &&
                                                <tr>
                                                    <td colspan={6}>
                                                        <NoAssetsFound />
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </TableContainer>
                                <div className="page-limits">
                                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                        <Select
                                            labelId="demo-select-small"
                                            id="demo-select-small"
                                            value={limit}
                                            onChange={(e) => {
                                                setPage(1);
                                                setPaginationStatus(false);
                                                setLimit(e.target.value);
                                            }}
                                        >
                                            <MenuItem value={5}>5</MenuItem>
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={20}>20</MenuItem>
                                            <MenuItem value={30}>30</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {paginationStatus && (
                                        <Pagination
                                            count={pageCount}
                                            variant="outlined"
                                            shape="rounded"
                                            onChange={handleChangePage}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Notified;
