import React from "react";
import Cookies from "js-cookie";
import { Modal } from "react-bootstrap";
import Typography from "@mui/material/Typography";
import Application from "../../../../config/ApplicationWrapper";
import ActivateBlue from "../../../../assets/images/activate-blue.svg";
import Download from "../../../../assets/images/download.svg";
import Share from "../../../../assets/images/share.svg";
import ShareQRPopup from "./ShareQr";

export default function QrPopup(props) {
  const { show, closepopup, qrDetails } = props;
  const [shareQrOpen, setShareQrOpen] = React.useState(false);
  const cookieDetails = Cookies.get("token");

  const onhandleStatusClose = () => {
    closepopup();
  };

  const closeQrSharePopup = () => {
    setShareQrOpen(false)
  }

  let qr_code = encodeURI(qrDetails && qrDetails.qr_code ? qrDetails.qr_code.url : "");

  const download = async (details) => {
    try{
      const res = await fetch(`${Application.api_url}/api/v2/asset_trackings/${details.id}/qr_codes/download`, {
        method: "POST",
        headers: { Authorization: cookieDetails },
      });
      const resultObj = await res.json();
      var a = document.createElement("a"); //Create <a>
      a.href = "data:image/png;base64," + resultObj.image_base64; //Image Base64 Goes here
      a.download = `${details.name}-QR.png`; //File name Here
      a.click();
  } catch(err){
    console.log("error===>", err)
  }
  };

  function printDiv(divName) {
    // var printContents = document.getElementById(divName).innerHTML;
    // var originalContents = document.body.innerHTML;

    // document.body.innerHTML = printContents;

    window.print();
    // window.close();

    // document.body.innerHTML = originalContents;
  }

  return (
    <Modal
      show={show}
      onHide={(e) => {
        onhandleStatusClose();
      }}
      className="qr-modal-wrap add-task-modal signature-popup"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <i
            className="fa fa-qrcode"
            style={{
              fontSize: "19px",
              marginRight: "5px",
            }}
          ></i>
          <b> View QR Code </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="qr-info-item">
          <div className="qr-info-img">
            <img alt="" src={qrDetails.image_url} />
          </div>
          <div className="qr-asset-info">
            <Typography variant="body2" gutterBottom>
              Asset Information
            </Typography>
            <Typography gutterBottom variant="subtitle1" component="div">
              {qrDetails.name}

              <label className="status-label">
                <span className={qrDetails.status}></span>
                {qrDetails.status}
                </label>

              <span className="asset-id">
                Serial - <span>{qrDetails.serial}</span>
              </span>
              <span className="asset-id asset-model">
                Model - <span>{qrDetails.model}</span>
              </span>
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {qrDetails.description}
            </Typography>
          </div>
        </div>


        {/* Print Div */}

        <div className="qr-info-item qr-scan-img">
          <img alt="" src={qrDetails.qr_code ? qrDetails.qr_code.url : ""} />
        </div>
        <div id="qr-scan-img" className="qr-info-item qr-scan-img qr-print-info">
          <div style={{ textAlign: "center" }}>
            <img alt="" src={qrDetails.qr_code ? qrDetails.qr_code.url : ""} />
            <p
              style={{ fontWeight: "500", fontSize: "38px", color: "#080808" }}
            >
              {qrDetails.name}
            </p>
            <span  style={{ fontWeight: "500", fontSize: "20px", color: "#909090" }}>Serial - {qrDetails.serial}</span>
            <p  style={{ fontWeight: "500", fontSize: "27px", color: "#080808" }}>{qrDetails.company_name}</p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn"
          onClick={(e) => {
            printDiv("qr-scan-img");
          }}
        >
          <img src={ActivateBlue} style={{ width: "auto", padding: 0 }} />
          <span className="btn-text">print</span>
        </button>
        <button
          className="btn btn-primary"
          onClick={(e) => {
            download(qrDetails);
          }}
        >
          <img src={Download} style={{ width: "auto", padding: 0 }} />
          <span className="btn-text">download</span>
        </button>

        <button
          className="btn btn-secondary"
          onClick={()=>{setShareQrOpen(true)}}
          // onClick={() =>
          //   (window.location = `mailto:?subject=QR-${qrDetails.name}-${qrDetails.serial}&body=${qr_code}`)
          // }
        >
          <img src={Share} style={{ width: "auto", padding: 0 }} />
          <span className="btn-text">share</span>
        </button>
      </Modal.Footer>
      <ShareQRPopup show={shareQrOpen} closepopup={closeQrSharePopup} data={qrDetails}/>
    </Modal>
  );
}
