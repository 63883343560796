import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Filter from "../../../assets/images/Filter.png";
import Grid from "@mui/material/Grid";
import ArrowUp from "../../../assets/images/ArrowUp.svg";
import ArrowDown from "../../../assets/images/ArrowDown.svg";
import Application from "../../../config/ApplicationWrapper";
import Cookies from "js-cookie";
import Pagination from "@mui/material/Pagination";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import QrPopup from "./Popups/QRCode";
import ChildAsset from "../../../assets/fonts/ChildAsset.svg";
import StatusPopup from "./Popups/Status";
import EmptyData from "../../../assets/images/asset-default.svg";
import loader from "../../../assets/images/newloader.gif";
import EmptyTable from "../../../assets/images/empty-assets.svg";
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import Settings from "../../../assets/images/Settings-new.svg";

const cookieDetails = Cookies.get("token");

export default function AchiveAsset({ countUpdate, countSearchCount }) {
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [pageCount, setPageCount] = React.useState(0);
  const [sort, setSort] = React.useState("");
  let [assetDetails, setAssetDetails] = React.useState([]);
  const [paginationStatus, setPaginationStatus] = React.useState(false);
  const [searchById, setSearchById] = React.useState("");
  const [searchByName, setSearchByName] = React.useState("");
  const [searchByType, setSearchByType] = React.useState("");
  const [searchByDate, setSearchByDate] = React.useState("");
  const [searchByCompany, setSearchByCompany] = React.useState("");
  const [searchByGroup, setSearchByGroup] = React.useState("");
  const [searchByModel, setSearchByModel] = React.useState("");
  const [searchBySerial, setSearchBySerial] = React.useState("");
  const [searchByCreatedBy, setSearchByCreatedBy] = React.useState("");
  const [searchByRP, setSearchByRP] = React.useState("");
  const [searchByStatus, setSearchByStatus] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [openQr, setOpenQr] = React.useState(false);
  const [qrDetails, setQrDetails] = React.useState("");
  const [dataChange, setDataChange] = React.useState("");
  const [statusModal, setStatusModal] = React.useState(false);
  const [statusDetails, setStatusDetails] = React.useState("");

  const [sortingDirection, setSortingDirection] = React.useState({
    asset_id: "desc",
    name: "desc",
    asset_type_name: "desc",
    updated_at: "desc",
    company_name: "desc",
    group: "desc",
    model: "desc",
    serial: "desc",
    creator: "desc",
    managers: "desc",
    created_at: "desc",
  });

  let initialShowMoreFields = {
    responsibleParty: false,
    project: false,
    createdBy: false,
    updated_at: false,
    assetType: true,
    assetName: true,
    model: true,
    serial: true,
    status: true,
    location: true,
  }

  const [showMoreFields, setShowMoreFields] = React.useState(initialShowMoreFields);

  const idRef = useRef();
  const nameRef = useRef();
  const typeRef = useRef();
  const updatedAtRef = useRef();
  const companyNameRef = useRef();
  const groupRef = useRef();
  const modelRef = useRef();
  const serialRef = useRef();
  const creatorRef = useRef();
  const managersRef = useRef();

  const [menuOpen, setMenuOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const prevOpen = React.useRef(open);

  var delayTimer;

  useEffect(() => {
    const getPageDetails = async () => {
      try {
        window.scrollTo(0, 0);

        var url = new URL(`${Application.api_url}/api/v2/asset_trackings`);
        let direction = sort ? sortingDirection[sort] : "";

        url.search = new URLSearchParams({
          page: page,
          limit: limit,
          asset_identifier_cont: searchById,
          name_cont: searchByName,
          asset_type_name_cont: searchByType,
          updated_at_eq: searchByDate,
          company_name_cont: searchByCompany,
          groups_name_cont: searchByGroup,
          model_cont: searchByModel,
          serial_cont: searchBySerial,
          creator_full_name_cont: searchByCreatedBy,
          managers_full_name_cont: searchByRP,
          status_eq: searchByStatus,
          archived_true: true,
          sort: sort + " " + direction,
        }).toString();

        const res = await fetch(url, {
          method: "GET",
          headers: { Authorization: cookieDetails },
        });
        const resultObj = await res.json();

        setAssetDetails(resultObj && resultObj.data ? resultObj.data : []);
        setPageCount(
          resultObj && resultObj.metadata ? resultObj.metadata.pages : 0
        );
        countUpdate(resultObj && resultObj.metadata ? resultObj.metadata : "0");
        countSearchCount(resultObj && resultObj.metadata ? resultObj.metadata : 0);
        setPaginationStatus(true);
      } catch (err) {
        console.log("error==>", err);
      }
    };

    getPageDetails();
  }, [
    page,
    limit,
    searchById,
    searchByName,
    searchByType,
    searchByDate,
    searchByCompany,
    searchByGroup,
    searchByModel,
    searchBySerial,
    searchByCreatedBy,
    searchByRP,
    searchByStatus,
    sort,
    sortingDirection,
    dataChange,
  ]);

  useEffect(() => {
    if (prevOpen.current === true && menuOpen === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = menuOpen;
  }, [menuOpen]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const unArchiveAsset = async (id) => {
    try {
      setPage(1);
      setPaginationStatus(false);
      let unarchive = await fetch(
        `${Application.api_url}/api/v2/asset_trackings/${id}/unarchive`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: cookieDetails,
          },
        }
      );
      toast.success("Successfully unarchived");
      setDataChange(Date.now());
    } catch (err) {
      console.log("error===>", err);
    }
  };

  const removeRelatedAssets = async (parentId, id) => {
    try {
      let url = new URL(`${Application.api_url}/api/v2/asset_trackings/${parentId}/related_assets/${id}`);

      fetch(
        url,
        {
          method: "DELETE",
          headers: { Authorization: cookieDetails },
        }
      ).then(async response => {
        if (response.status === 200) {
          setDataChange(Date.now());
          toast.success('Related asset disconnected successfully');
        }
      })
    } catch (err) {
      console.log("error===>", err);
    }
  }

  const viewQr = async (datas) => {
    setQrDetails(datas);
    setOpenQr(true);
  };

  const changeStatus = async (datas) => {
    setStatusModal(true);
    setStatusDetails(datas);
  };

  const onhandleClose = (data) => {
    setOpenQr(false);
    setStatusModal(false);
    if (data) {
      setDataChange(Math.random());
    }
  };

  const sorting = (name) => {
    let direction;
    if (sortingDirection[name] == "desc") {
      if (name == "asset_id") {
        idRef.current.style.transform = `rotate(180deg)`;
        direction = { ...sortingDirection, ...{ asset_id: "asc" } };
      } else if (name == "name") {
        nameRef.current.style.transform = `rotate(180deg)`;
        direction = { ...sortingDirection, ...{ name: "asc" } };
      } else if (name == "asset_type_name") {
        typeRef.current.style.transform = `rotate(180deg)`;
        direction = { ...sortingDirection, ...{ asset_type_name: "asc" } };
      } else if (name == "updated_at") {
        updatedAtRef.current.style.transform = `rotate(180deg)`;
        direction = { ...sortingDirection, ...{ updated_at: "asc" } };
      } else if (name == "company_name") {
        companyNameRef.current.style.transform = `rotate(180deg)`;
        direction = { ...sortingDirection, ...{ company_name: "asc" } };
      } else if (name == "group") {
        groupRef.current.style.transform = `rotate(180deg)`;
        direction = { ...sortingDirection, ...{ group: "asc" } };
      } else if (name == "model") {
        modelRef.current.style.transform = `rotate(180deg)`;
        direction = { ...sortingDirection, ...{ model: "asc" } };
      } else if (name == "serial") {
        serialRef.current.style.transform = `rotate(180deg)`;
        direction = { ...sortingDirection, ...{ serial: "asc" } };
      } else if (name == "creator") {
        creatorRef.current.style.transform = `rotate(180deg)`;
        direction = { ...sortingDirection, ...{ creator: "asc" } };
      } else {
        managersRef.current.style.transform = `rotate(180deg)`;
        direction = { ...sortingDirection, ...{ managers: "asc" } };
      }
    } else {
      if (name == "asset_id") {
        idRef.current.style.transform = `rotate(0deg)`;
        direction = { ...sortingDirection, ...{ asset_id: "desc" } };
      } else if (name == "name") {
        nameRef.current.style.transform = `rotate(0deg)`;
        direction = { ...sortingDirection, ...{ name: "desc" } };
      } else if (name == "asset_type_name") {
        typeRef.current.style.transform = `rotate(0deg)`;
        direction = { ...sortingDirection, ...{ asset_type_name: "asc" } };
      } else if (name == "updated_at") {
        updatedAtRef.current.style.transform = `rotate(0deg)`;
        direction = { ...sortingDirection, ...{ updated_at: "desc" } };
      } else if (name == "company_name") {
        companyNameRef.current.style.transform = `rotate(0deg)`;
        direction = { ...sortingDirection, ...{ company_name: "desc" } };
      } else if (name == "group") {
        groupRef.current.style.transform = `rotate(0deg)`;
        direction = { ...sortingDirection, ...{ group: "desc" } };
      } else if (name == "model") {
        modelRef.current.style.transform = `rotate(0deg)`;
        direction = { ...sortingDirection, ...{ model: "desc" } };
      } else if (name == "serial") {
        serialRef.current.style.transform = `rotate(0deg)`;
        direction = { ...sortingDirection, ...{ serial: "desc" } };
      } else if (name == "creator") {
        creatorRef.current.style.transform = `rotate(0deg)`;
        direction = { ...sortingDirection, ...{ creator: "desc" } };
      } else {
        managersRef.current.style.transform = `rotate(0deg)`;
        direction = { ...sortingDirection, ...{ managers: "desc" } };
      }
    }
    setSort(name);
    setSortingDirection(direction);
  };

  function searchData(field, value) {
    // clearTimeout(delayTimer);
    // delayTimer = setTimeout(function () {
    switch (field) {
      case "id":
        setSearchById(value);
        break;
      case "name":
        setSearchByName(value);
        break;
      case "asset_type_name":
        setSearchByType(value);
        break;
      case "update":
        setSearchByDate(value);
        break;
      case "company":
        setSearchByCompany(value);
        break;
      case "group":
        setSearchByGroup(value);
        break;
      case "model":
        setSearchByModel(value);
        break;
      case "serial":
        setSearchBySerial(value);
        break;
      case "creator":
        setSearchByCreatedBy(value);
        break;
      case "managers":
        setSearchByRP(value);
        break;
    }
    // }, 2000);
  }

  // function searchChildByName(value, obj, id) {
  //   clearTimeout(delayTimer);
  //   let newArray = [];
  //   delayTimer = setTimeout(function () {
  //     if (value) {
  //       obj.find((result) => {
  //         if (result && result.name && result.name.toLowerCase().indexOf(value.toLowerCase()) > -1) {
  //           newArray.push(result);
  //         }
  //       });

  //       assetDetails.map((i)=>{
  //         if(i.id === id){
  //           i.relatables = newArray;
  //         }
  //       })

  //       setAssetDetails([...assetDetails]);

  //     }
  //   }, 2000);
  // }

  function Managers({ data }) {
    let finalData = data;
    if (data && data.length >= 3) {
      finalData = finalData.slice(0, 2);
    }
    return (
      <div>
        {finalData &&
          finalData.map((obj, index) => (
            <span className={`badge badge-light responsible-party`}>
              {obj.full_name}
            </span>
          ))}
        {data && data.length >= 3 && (
          <span className={`badge badge-light responsible-party more-groups`}>
            + {data.length - 2} More Groups
          </span>
        )}
      </div>
    );
  }

  const NoAssetsFound = () => (
    <div className="no-assets" style={{ textAlign: "center" }}>
      <img src={EmptyTable} />
      <p>
        <b>No Data</b>
      </p>
    </div>
  );

  const handleShowFieldChange = (name, value) => {
    setShowMoreFields({ ...showMoreFields, [name]: value });
    localStorage.setItem("showMoreFileds", JSON.stringify({ ...showMoreFields, [name]: value }));
  }

  const handleToggle = () => {
    setMenuOpen((prevOpen) => !prevOpen);
  };

  const handleMenuClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setMenuOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setMenuOpen(false);
    } else if (event.key === 'Escape') {
      setMenuOpen(false);
    }
  }

  const clearFilter = () => {
    //setShowMoreFields(initialShowMoreFields);
    setSearchById("");
    setSearchByName("");
    setSearchByType("");
    setSearchByDate("");
    setSearchByCompany("");
    setSearchByGroup("");
    setSearchByModel("");
    setSearchBySerial("");
    setSearchByCreatedBy("");
    setSearchByRP("");
    setSearchByStatus("");
    if(document.getElementById("search-archive-asset-type")){
      document.getElementById("search-archive-asset-type").value = "";
    }
    if(document.getElementById("search-archive-asset-name")){
      document.getElementById("search-archive-asset-name").value = "";
    }
    if(document.getElementById("search-archive-asset-group")){
      document.getElementById("search-archive-asset-group").value = "";
    }
    if(document.getElementById("search-archive-asset-model")){
      document.getElementById("search-archive-asset-model").value = "";
    }
    if(document.getElementById("search-archive-asset-serial")){
      document.getElementById("search-archive-asset-serial").value = "";
    }
    if(document.getElementById("search-archive-asset-creator")){
      document.getElementById("search-archive-asset-creator").value = "";
    }
    if(document.getElementById("search-archive-asset-rp")){
      document.getElementById("search-archive-asset-rp").value = "";
    }
    //localStorage.setItem("showMoreFileds", JSON.stringify(initialShowMoreFields));
  }

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
              className="table-arrow"
            >
              {open ? (
                <img src={ArrowUp} alt="" />
              ) : (
                <img src={ArrowDown} alt="" />
              )}
              <img src={ChildAsset} alt="" />
              <span className="relatables-count">{row.relatables.length}</span>
            </IconButton>
          </TableCell>
          <TableCell>
            <NavLink
              to={`/view-asset-tracking/view/${row.id}`}
              id=""
              role="button"
            >
              <img src={row.image_url ? row.image_url : EmptyData} alt="" className="table-row-img" style={{ height: !row.image_url ? "50px" : "" }} />
            </NavLink>
          </TableCell>
          {showMoreFields.assetType &&
            <TableCell>{row.asset_type ? row.asset_type.name : ""}</TableCell>
          }
          {showMoreFields.assetName &&
            <TableCell>{row.name}</TableCell>
          }
          {showMoreFields.updated_at &&
            <TableCell>{row.updated_at}</TableCell>
          }
          {showMoreFields.project &&
            <TableCell>
              {row.asset_groups && row.asset_groups.length > 0 && row.asset_groups[0].group
                ? row.asset_groups[0].group.name
                : ""}
            </TableCell>
          }
          {showMoreFields.model &&
            <TableCell className="align-center">{row.model}</TableCell>
          }
          {showMoreFields.serial &&
            <TableCell className="align-center">{row.serial}</TableCell>
          }
          {showMoreFields.createdBy &&
            <TableCell>{row.creator ? row.creator.full_name : ""}</TableCell>
          }
          {showMoreFields.responsibleParty &&
            <TableCell>
              <Managers data={row.managers} />
            </TableCell>
          }
          {showMoreFields.status &&
            <TableCell className="align-center">
              <span className={`badge badge-light ${row.status}`}>
                {row.status}
              </span>
            </TableCell>
          }
          {showMoreFields.location &&
            <TableCell>
              {row.geo_location_trackings && row.geo_location_trackings.length > 0 ? row.geo_location_trackings[row.geo_location_trackings.length - 1].address.replace(/,[^,]+$/, "") : "-"}
            </TableCell>
          }
          <TableCell className="align-center static-action" style={{
            position: "sticky",
            right: 0,
            background: "white",
            boxShadow: "5px 2px 5px grey",
            minWidth: "130px",
            textAlign: "center"
          }}>
            <a
              aria-expanded="false"
              aria-haspopup="true"
              className="btn dropdown-toggle"
              data-toggle="dropdown"
              id="dropdownMenuLink"
              role="button"
            >
              <i className="fa fa-ellipsis-v"></i>
            </a>
            <ul aria-labelledby="dropdownMenuLink" className="dropdown-menu">
              <li>
                <NavLink
                  to={`/view-asset-tracking/view/${row.identifier}`}
                  id=""
                  role="button"
                >
                  <a
                    className="archive_group_invites asset-links"
                    data-toggle="tooltip"
                    role="button"
                    title="View RA"
                  >
                    <div className="row">
                      <div className="col-sm-3">
                        <i className="fa fa-eye"></i>
                      </div>
                      <div className="col-sm-9 menu-lable">View Asset</div>
                    </div>
                  </a>
                </NavLink>
                <NavLink
                  to={`/edit-asset-tracking/edit/${row.identifier}`}
                  id=""
                  role="button"
                  className="rat-links"
                >
                  <a
                    className="archive_group_invites asset-links"
                    data-toggle="tooltip"
                    role="button"
                    title="View RA"
                  >
                    <div className="row">
                      <div className="col-sm-3">
                        <i className="fa fa-pencil-square-o"></i>
                      </div>
                      <div className="col-sm-9 menu-lable">Edit Asset</div>
                    </div>
                  </a>
                </NavLink>
                <a
                  className="archive_group_invites asset-links"
                  data-toggle="tooltip"
                  role="button"
                  title="View QR"
                  onClick={(e) => {
                    viewQr(row);
                  }}
                >
                  <div className="row">
                    <div className="col-sm-3">
                      <i className="fa fa-qrcode"></i>
                    </div>
                    <div className="col-sm-9 menu-lable">View QR Code</div>
                  </div>
                </a>
                <a
                  className="archive_group_invites asset-links"
                  data-toggle="tooltip"
                  role="button"
                  title="View RA"
                >
                  <div className="row">
                    <div className="col-sm-3">
                      <i className="fa fa-shapes"></i>
                    </div>
                    <div
                      className="col-sm-9 menu-lable"
                      onClick={(e) => {
                        changeStatus(row);
                      }}
                    >
                      Change Status
                    </div>
                  </div>
                </a>
                <a
                  className="archive_group_invites asset-links"
                  data-toggle="tooltip"
                  role="button"
                  title="unarchive"
                  onClick={(e) => {
                    unArchiveAsset(row.identifier);
                  }}
                >
                  <div className="row">
                    <div className="col-sm-3">
                      <i
                        className="fa fa-trash"
                        style={{
                          paddingTop: "5px",
                          fontSize: "16px",
                          color: "#407797",
                        }}
                      ></i>
                    </div>
                    <div
                      className="col-sm-9"
                      style={{
                        paddingTop: "5px",
                        color: "#407797",
                      }}
                    >
                      Unarchive
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="table-cell-nested"></TableCell>
          <TableCell className="table-cell-nested" colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <div className="related-assets-table-header">
                  <h4 className="related-assets-header">Related Assets List</h4>
                  {/*<div className="input-group" className="search-box">*/}
                  {/*  <input*/}
                  {/*    type="text"*/}
                  {/*    className="form-control assessment-name"*/}
                  {/*    placeholder="Search"*/}
                  {/*    aria-label="Username"*/}
                  {/*    aria-describedby="basic-addon1"*/}
                  {/*    name="name"*/}
                  {/*    // onChange={(e) => {*/}
                  {/*    //   searchChildByName(e.target.value, row.relatables, row.id);*/}
                  {/*    // }}*/}
                  {/*  />*/}
                  {/*  <div className="input-group-addon">*/}
                  {/*    <span className="input-group-text" id="basic-addon1">*/}
                  {/*      <i className="fa fa-search" aria-hidden="true"></i>*/}
                  {/*    </span>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </div>
                <div className="table-responsive related-assets-table-scroller">
                  <Table
                    size="small"
                    aria-label="purchases"
                    className="insideTable"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell className="risk-assessment-divider">
                          Image
                        </TableCell>
                        <TableCell className="risk-assessment-divider">
                          Related Asset Name
                        </TableCell>
                        {/* <TableCell className="risk-assessment-divider">
                          Company
                        </TableCell> */}
                        <TableCell className="risk-assessment-divider">
                          Asset Type
                        </TableCell>
                        <TableCell className="risk-assessment-divider">
                          Model #
                        </TableCell>
                        <TableCell className="risk-assessment-divider">
                          Serial No #
                        </TableCell>
                        <TableCell className="risk-assessment-divider">
                          Created By
                        </TableCell>
                        <TableCell className="risk-assessment-divider">
                          Responsible Party
                        </TableCell>
                        <TableCell className="risk-assessment-divider">
                          Group/Project Sites
                        </TableCell>
                        <TableCell className="risk-assessment-divider">
                          Status
                        </TableCell>
                        <TableCell className="risk-assessment-divider">
                          Asset Location
                        </TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row && row.relatables && row.relatables.length > 0 && row.relatables.map((historyRow) => (
                        <TableRow key={historyRow.id}>
                          <TableCell component="th" scope="row">
                            {/* <img src={historyRow.image_url} alt="" /> */}
                            <TableCell>
                              <NavLink
                                to={`/view-asset-tracking/view/${historyRow.identifier}`}
                                id=""
                                role="button"
                              >
                                <img src={historyRow.image_url ? historyRow.image_url : EmptyData} alt="" className="table-row-img" style={{ height: !historyRow.image_url ? "50px" : "" }} />
                              </NavLink>
                            </TableCell>
                          </TableCell>
                          <TableCell>{historyRow.name}</TableCell>
                          {/* <TableCell>{historyRow.company_name}</TableCell> */}
                          <TableCell>{historyRow.asset_type ? historyRow.asset_type.name : ""}</TableCell>
                          <TableCell className="align-center">
                            {historyRow.model}
                          </TableCell>
                          <TableCell className="align-center">
                            {historyRow.serial}
                          </TableCell>
                          <TableCell>
                            {historyRow.creator
                              ? historyRow.creator.full_name
                              : ""}
                          </TableCell>
                          <TableCell>
                            <Managers data={historyRow.managers} />
                          </TableCell>
                          <TableCell>
                            {historyRow.groups &&
                              historyRow.groups.length > 0 && historyRow.groups[0]
                              ? historyRow.groups[0].name
                              : ""}
                          </TableCell>
                          <TableCell className="align-center">
                            {" "}
                            <span
                              className={`badge badge-light ${historyRow.status}`}
                            >
                              {historyRow.status}
                            </span>
                          </TableCell>
                          <TableCell>
                            {historyRow.geo_location_trackings && historyRow.geo_location_trackings.length > 0 ? historyRow.geo_location_trackings[historyRow.geo_location_trackings.length - 1].address.replace(/,[^,]+$/, "") : "-"}
                          </TableCell>
                          <TableCell className="align-center">
                            <a
                              aria-expanded="false"
                              aria-haspopup="true"
                              className="btn dropdown-toggle"
                              data-toggle="dropdown"
                              id="dropdownMenuLink"
                              role="button"
                            >
                              <i className="fa fa-ellipsis-v"></i>
                            </a>
                            <ul
                              aria-labelledby="dropdownMenuLink"
                              className="dropdown-menu"
                            >
                              <li>
                                <NavLink
                                  to={`/view-asset-tracking/view/${historyRow.identifier}`}
                                  id=""
                                  role="button"
                                >
                                  <a
                                    className="archive_group_invites asset-links"
                                    data-toggle="tooltip"
                                    role="button"
                                    title="View RA"
                                  >
                                    <div className="row">
                                      <div className="col-sm-3">
                                        <i className="fa fa-eye"></i>
                                      </div>
                                      <div className="col-sm-9 menu-lable">
                                        View Asset
                                      </div>
                                    </div>
                                  </a>
                                </NavLink>
                                <NavLink
                                  to={`/edit-asset-tracking/edit/${historyRow.identifier}`}
                                  id=""
                                  role="button"
                                  className="rat-links"
                                >
                                  <a
                                    className="archive_group_invites asset-links"
                                    data-toggle="tooltip"
                                    role="button"
                                    title="View RA"
                                  >
                                    <div className="row">
                                      <div className="col-sm-3">
                                        <i className="fa fa-pencil-square-o"></i>
                                      </div>
                                      <div className="col-sm-9 menu-lable">
                                        Edit Asset
                                      </div>
                                    </div>
                                  </a>
                                </NavLink>
                                <a
                                  className="archive_group_invites asset-links"
                                  data-toggle="tooltip"
                                  role="button"
                                  title="View QR"
                                  onClick={(e) => {
                                    viewQr(historyRow);
                                  }}
                                >
                                  <div className="row">
                                    <div className="col-sm-3">
                                      <i className="fa fa-qrcode"></i>
                                    </div>
                                    <div className="col-sm-9 menu-lable">
                                      View QR Code
                                    </div>
                                  </div>
                                </a>
                                <a
                                  className="archive_group_invites asset-links"
                                  data-toggle="tooltip"
                                  role="button"
                                  title="View RA"
                                  onClick={(e) => {
                                    changeStatus(historyRow);
                                  }}
                                >
                                  <div className="row">
                                    <div className="col-sm-3">
                                      <i className="fa fa-shapes"></i>
                                    </div>
                                    <div className="col-sm-9 menu-lable">
                                      Change Status
                                    </div>
                                  </div>
                                </a>
                                <a
                                  className="archive_group_invites asset-links"
                                  data-toggle="tooltip"
                                  role="button"
                                  title="Delink"
                                  onClick={(e) => {
                                    removeRelatedAssets(row.id, historyRow.id);
                                  }}
                                >
                                  <div className="row">
                                    <div className="col-sm-3 archive-menu">
                                      <i className="fa fa-chain-broken"></i>
                                    </div>
                                    <div className="col-sm-9 archive-menu">
                                    Delink
                                    </div>
                                  </div>
                                </a>
                              </li>
                            </ul>
                          </TableCell>
                        </TableRow>
                      ))}
                      {row && row.relatables && row.relatables.length == 0 &&
                        <TableRow>
                          <TableCell colSpan={13}>
                            <NoAssetsFound />
                          </TableCell>
                        </TableRow>
                      }
                    </TableBody>
                  </Table>
                </div>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  return (<>
    {paginationStatus === true && (
      <div className="nestedTable">
        <div className="fields-setting">
          <div className="asset-active-filters">
            Active Filters :
            {showMoreFields.assetType &&
              <span className="active-filters">
                <span>Asset Type :</span>
                <span className="selected">{searchByType ? searchByType : "All"}</span>
                <span className={searchByType ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setPage(1); setSearchByType(""); document.getElementById("search-archive-asset-type").value = "" }}>X</span>
              </span>
            }
            {showMoreFields.assetName &&
              <span className="active-filters">
                <span>Asset Name :</span>
                <span className="selected">{searchByName ? searchByName : "All"}</span>
                <span className={searchByName ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setPage(1); setSearchByName(""); document.getElementById("search-archive-asset-name").value = "" }}>X</span>
              </span>
            }
            {showMoreFields.updated_at &&
              <span className="active-filters">
                <span>Updated On :</span>
                <span className="selected">{searchByDate ? searchByDate : "All"}</span>
                <span className={searchByDate ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setPage(1); setSearchByDate("");}}>X</span>
              </span>
            }
            {showMoreFields.project &&
              <span className="active-filters">
                <span>Group/Project Sites :</span>
                <span className="selected">{searchByGroup ? searchByGroup : "All"}</span>
                <span className={searchByGroup ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setPage(1); setSearchByGroup(""); document.getElementById("search-archive-asset-group").value = "" }}>X</span>
              </span>
            }
            {showMoreFields.model &&
              <span className="active-filters">
                <span>Model :</span>
                <span className="selected">{searchByModel ? searchByModel : "All"}</span>
                <span className={searchByModel ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setPage(1); setSearchByModel(""); document.getElementById("search-archive-asset-model").value = "" }}>X</span>
              </span>
            }
            {showMoreFields.serial &&
              <span className="active-filters">
                <span>Serial No :</span>
                <span className="selected">{searchBySerial ? searchBySerial : "All"}</span>
                <span className={searchBySerial ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setPage(1); setSearchBySerial(""); document.getElementById("search-archive-asset-serial").value = "" }}>X</span>
              </span>
            }
            {showMoreFields.createdBy &&
              <span className="active-filters">
                <span>Created By :</span>
                <span className="selected">{searchByCreatedBy ? searchByCreatedBy : "All"}</span>
                <span className={searchByCreatedBy ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setPage(1); setSearchByCreatedBy(""); document.getElementById("search-archive-asset-creator").value = "" }}>X</span>
              </span>
            }
            {showMoreFields.responsibleParty &&
              <span className="active-filters">
                <span>Responsible Party :</span>
                <span className="selected">{searchByRP ? searchByRP : "All"}</span>
                <span className={searchByRP ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setPage(1); setSearchByRP(""); document.getElementById("search-archive-asset-rp").value = "" }}>X</span>
              </span>
            }
            {showMoreFields.status &&
              <span className="active-filters">
                <span>Status :</span>
                <span className="selected">{searchByStatus ? searchByStatus : "All"}</span>
                <span className={searchByStatus ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setPage(1); setSearchByStatus(""); }}>X</span>
              </span>
            }
            <span className="active-filters-clear" onClick={(e) => clearFilter()}>
              Clear All
            </span>
          </div>
          <div className="show-more-fields">
            <Button
              ref={anchorRef}
              id="composition-button"
              aria-controls={menuOpen ? 'composition-menu' : undefined}
              aria-expanded={menuOpen ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
            >
              <img src={Settings} alt="" /> Column Settings
            </Button>
            <Popper
              open={menuOpen}
              anchorEl={anchorRef.current}
              role={undefined}
              placement="bottom-start"
              transition
              disablePortal
              className="asset-column-settings"
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom-start' ? 'left top' : 'left bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleMenuClose}>
                      <MenuList
                        autoFocusItem={menuOpen}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem >
                          <div className="more-fields">
                            <input
                              type="checkbox"
                              checked={showMoreFields.assetType}
                              style={{ marginRight: "10px", accentColor: "#407797", marginTop: 0 }}
                              onChange={(e) => {
                                handleShowFieldChange("assetType", !showMoreFields.assetType);
                              }}
                            />
                            Asset Type
                          </div>
                        </MenuItem>
                        <MenuItem >
                          <div className="more-fields">
                            <input
                              type="checkbox"
                              checked={showMoreFields.assetName}
                              style={{ marginRight: "10px", accentColor: "#407797", marginTop: 0 }}
                              onChange={(e) => {
                                handleShowFieldChange("assetName", !showMoreFields.assetName);
                              }}
                            />
                            Asset Name
                          </div>
                        </MenuItem>
                        <MenuItem >
                          <div className="more-fields">
                            <input
                              type="checkbox"
                              checked={showMoreFields.updated_at}
                              style={{ marginRight: "10px", accentColor: "#407797", marginTop: 0 }}
                              onChange={(e) => {
                                handleShowFieldChange("updated_at", !showMoreFields.updated_at);
                              }}
                            />
                            Updated On
                          </div>
                        </MenuItem>
                        <MenuItem >
                          <div className="more-fields">
                            <input
                              type="checkbox"
                              checked={showMoreFields.project}
                              style={{ marginRight: "10px", accentColor: "#407797", marginTop: 0 }}
                              onChange={(e) => {
                                handleShowFieldChange("project", !showMoreFields.project);
                              }}
                            />
                            Group/Project Sites
                          </div>
                        </MenuItem>
                        <MenuItem >
                          <div className="more-fields">
                            <input
                              type="checkbox"
                              checked={showMoreFields.model}
                              style={{ marginRight: "10px", accentColor: "#407797", marginTop: 0 }}
                              onChange={(e) => {
                                handleShowFieldChange("model", !showMoreFields.model);
                              }}
                            />
                            Model
                          </div>
                        </MenuItem>
                        <MenuItem >
                          <div className="more-fields">
                            <input
                              type="checkbox"
                              checked={showMoreFields.serial}
                              style={{ marginRight: "10px", accentColor: "#407797", marginTop: 0 }}
                              onChange={(e) => {
                                handleShowFieldChange("serial", !showMoreFields.serial);
                              }}
                            />
                            Serial No
                          </div>
                        </MenuItem>
                        <MenuItem >
                          <div className="more-fields">
                            <input
                              type="checkbox"
                              checked={showMoreFields.createdBy}
                              style={{ marginRight: "10px", accentColor: "#407797", marginTop: 0 }}
                              onChange={(e) => {
                                handleShowFieldChange("createdBy", !showMoreFields.createdBy);
                              }}
                            />
                            Created By
                          </div>
                        </MenuItem>
                        <MenuItem >
                          <div className="more-fields">
                            <input
                              type="checkbox"
                              checked={showMoreFields.responsibleParty}
                              style={{ marginRight: "10px", accentColor: "#407797", marginTop: 0 }}
                              onChange={(e) => {
                                handleShowFieldChange("responsibleParty", !showMoreFields.responsibleParty);
                              }}
                            />
                            Responsible Party
                          </div>
                        </MenuItem>
                        <MenuItem >
                          <div className="more-fields">
                            <input
                              type="checkbox"
                              checked={showMoreFields.status}
                              style={{ marginRight: "10px", accentColor: "#407797", marginTop: 0 }}
                              onChange={(e) => {
                                handleShowFieldChange("status", !showMoreFields.status);
                              }}
                            />
                            Status
                          </div>
                        </MenuItem>
                        <MenuItem >
                            <div className="more-fields">
                              <input
                                type="checkbox"
                                checked={showMoreFields.location}
                                style={{ marginRight: "10px", accentColor: "#407797", marginTop: 0 }}
                                onChange={(e) => {
                                  handleShowFieldChange("location", !showMoreFields.location);
                                }}
                              />
                              Asset Location
                            </div>
                          </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell className="risk-assessment-divider">
                  Related Assets
                </TableCell>
                <TableCell className="risk-assessment-divider">Image</TableCell>
                {showMoreFields.assetType &&
                  <TableCell className="risk-assessment-divider">
                    <Grid
                      className="d-flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      Asset Type
                      <div
                        onClick={(e) => {
                          sorting("asset_type_name");
                        }}
                      >
                        <img src={Filter} alt="" ref={typeRef} />
                      </div>
                    </Grid>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control assessment-name"
                        placeholder="Search"
                        aria-label="Asset Type"
                        aria-describedby="basic-addon1"
                        name="name"
                        id="search-archive-asset-type"
                        // value={searchByType}
                        onKeyUp={(e) => {
                          if(e.key === "Enter") {
                            searchData("asset_type_name", document.getElementById("search-archive-asset-type").value);
                          }
                        }}
                      />
                      <div className="input-group-addon"
                           onClick={(e) => {
                             searchData("asset_type_name", document.getElementById("search-archive-asset-type").value);
                           }}
                      >
                        <span className="input-group-text" id="basic-addon1">
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </span>
                      </div>
                    </div>
                  </TableCell>
                }
                {showMoreFields.assetName &&
                  <TableCell className="risk-assessment-divider">
                    <Grid
                      className="d-flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      Asset Name
                      <div
                        onClick={(e) => {
                          sorting("name");
                        }}
                      >
                        <img src={Filter} alt="" ref={nameRef} />
                      </div>
                    </Grid>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control assessment-name"
                        placeholder="Search"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        name="name"
                        id="search-archive-asset-name"
                        // value={searchByName}
                        onKeyUp={(e) => {
                          if(e.key === "Enter") {
                            searchData("name", document.getElementById("search-archive-asset-name").value);
                          }
                        }}
                      />
                      <div className="input-group-addon"
                           onClick={(e) => {
                             searchData("name", document.getElementById("search-archive-asset-name").value);
                           }}
                      >
                        <span className="input-group-text" id="basic-addon1">
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </span>
                      </div>
                    </div>
                  </TableCell>
                }
                {showMoreFields.updated_at &&
                  <TableCell className="risk-assessment-divider">
                    <Grid
                      className="d-flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      Updated On
                      <div
                        onClick={(e) => {
                          sorting("updated_at");
                        }}
                      >
                        <img src={Filter} alt="" ref={updatedAtRef} />
                      </div>
                    </Grid>
                    <div className="input-group">
                      <input
                        type="date"
                        className="form-control assessment-name"
                        placeholder="Search"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        name="updated"
                        value={searchByDate}
                        onChange={(e) => {
                          searchData("update", e.target.value);
                        }}
                      />
                    </div>
                  </TableCell>
                }
                {showMoreFields.project &&
                  <TableCell className="risk-assessment-divider">
                    <Grid
                      className="d-flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      Group/Project Sites
                      <div
                        onClick={(e) => {
                          sorting("group");
                        }}
                      >
                        <img src={Filter} alt="" ref={groupRef} />
                      </div>
                    </Grid>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control assessment-name"
                        placeholder="Search"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        name="group"
                        id="search-archive-asset-group"
                        // value={searchByGroup}
                        onKeyUp={(e) => {
                          if(e.key === "Enter") {
                            searchData("group", document.getElementById("search-archive-asset-group").value);
                          }
                        }}
                      />
                      <div className="input-group-addon"
                           onClick={(e) => {
                             searchData("group", document.getElementById("search-archive-asset-group").value);
                           }}
                      >
                        <span className="input-group-text" id="basic-addon1">
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </span>
                      </div>
                    </div>
                  </TableCell>
                }
                {showMoreFields.model &&
                  <TableCell className="risk-assessment-divider">
                    <Grid
                      className="d-flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      Model#
                      <div
                        onClick={(e) => {
                          sorting("model");
                        }}
                      >
                        <img src={Filter} alt="" ref={modelRef} />
                      </div>
                    </Grid>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control assessment-name"
                        placeholder="Search"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        name="model"
                        id="search-archive-asset-model"
                        // value={searchByModel}
                        onKeyUp={(e) => {
                          if(e.key === "Enter") {
                            searchData("model", document.getElementById("search-archive-asset-model").value);
                          }
                        }}
                      />
                      <div className="input-group-addon"
                           onClick={(e) => {
                             searchData("model", document.getElementById("search-archive-asset-model").value);
                           }}
                      >
                        <span className="input-group-text" id="basic-addon1">
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </span>
                      </div>
                    </div>
                  </TableCell>
                }
                {showMoreFields.serial &&
                  <TableCell className="risk-assessment-divider">
                    <Grid
                      className="d-flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      Serial No #
                      <div
                        onClick={(e) => {
                          sorting("serial");
                        }}
                      >
                        <img src={Filter} alt="" ref={serialRef} />
                      </div>
                    </Grid>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control assessment-name"
                        placeholder="Search"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        name="serial"
                        id="search-archive-asset-serial"
                        // value={searchBySerial}
                        onKeyUp={(e) => {
                          if(e.key === "Enter") {
                            searchData("serial", document.getElementById("search-archive-asset-serial").value);
                          }
                        }}
                      />
                      <div className="input-group-addon"
                           onClick={(e) => {
                             searchData("serial", document.getElementById("search-archive-asset-serial").value);
                           }}
                      >
                        <span className="input-group-text" id="basic-addon1">
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </span>
                      </div>
                    </div>
                  </TableCell>
                }
                {showMoreFields.createdBy &&
                  <TableCell className="risk-assessment-divider">
                    <Grid
                      className="d-flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      Created By
                      <div
                        onClick={(e) => {
                          sorting("creator");
                        }}
                      >
                        <img src={Filter} alt="" ref={creatorRef} />
                      </div>
                    </Grid>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control assessment-name"
                        placeholder="Search"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        name="createdBy"
                        id="search-archive-asset-creator"
                        // value={searchByCreatedBy}
                        onKeyUp={(e) => {
                          if(e.key === "Enter") {
                            searchData("creator", document.getElementById("search-archive-asset-creator").value);
                          }
                        }}
                      />
                      <div className="input-group-addon"
                           onClick={(e) => {
                             searchData("creator", document.getElementById("search-archive-asset-creator").value);
                           }}
                      >
                        <span className="input-group-text" id="basic-addon1">
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </span>
                      </div>
                    </div>
                  </TableCell>
                }
                {showMoreFields.responsibleParty &&
                  <TableCell className="risk-assessment-divider">
                    <Grid
                      className="d-flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      Responsible Party
                      <div
                        onClick={(e) => {
                          sorting("managers");
                        }}
                      >
                        <img src={Filter} alt="" ref={managersRef} />
                      </div>
                    </Grid>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control assessment-name"
                        placeholder="Search"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        name="rp"
                        id="search-archive-asset-rp"
                        // value={searchByRP}
                        onKeyUp={(e) => {
                          if(e.key === "Enter") {
                            searchData("managers", document.getElementById("search-archive-asset-rp").value);
                          }
                        }}
                      />
                      <div className="input-group-addon"
                           onClick={(e) => {
                             searchData("managers", document.getElementById("search-archive-asset-rp").value);
                           }}
                      >
                        <span className="input-group-text" id="basic-addon1">
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </span>
                      </div>
                    </div>
                  </TableCell>
                }
                {showMoreFields.status &&
                  <TableCell className="risk-assessment-divider">
                    Status
                    <div className="input-group">
                      <select
                        className="form-control risk-type"
                        onChange={(e) => {
                          setSearchByStatus(e.target.value);
                        }}
                        value={searchByStatus}
                      >
                        <option value="">All</option>
                        <option value="Idle">Idle</option>
                        <option value="Repair">Repair</option>
                        <option value="In Use">In Use</option>
                      </select>
                    </div>
                  </TableCell>
                }
                {showMoreFields.location &&
                    <TableCell className="risk-assessment-divider">
                      <Grid
                        className="d-flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        Asset Location
                      </Grid>
                    </TableCell>
                  }
                <TableCell className="static-action" style={{
                    position: "sticky",
                    right: 0,
                    boxShadow: "5px 2px 5px grey",
                    zIndex: 2,
                    minWidth: "150px",
                    textAlign: "center"
                  }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assetDetails &&
                assetDetails.map((row, index) => <Row key={index} row={row} />)}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="page-limits">
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={limit}
              onChange={(e) => {
                setPage(1);
                setPaginationStatus(false);
                setLimit(e.target.value);
              }}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
            </Select>
          </FormControl>
          {paginationStatus && (
            <Pagination
              count={pageCount}
              variant="outlined"
              shape="rounded"
              onChange={handleChangePage}
            />
          )}
        </div>
        <QrPopup show={openQr} closepopup={onhandleClose} qrDetails={qrDetails} />
        <StatusPopup
          show={statusModal}
          closepopup={onhandleClose}
          details={statusDetails}
        />
      </div>
    )}
    {paginationStatus === false &&
      <div className="new-loader">
        <img src={loader} alt="" />
      </div>
    }
  </>
  );
}
