import React, { useEffect, useState } from "react";
import { Modal, FormControl as Input } from "react-bootstrap";
import { toast } from "react-toastify";
import Application from "../../../../config/ApplicationWrapper";
import axios from "../../../../config/axiosConfig";
import DeleteIcon from "../../../../assets/fonts/Delete_new.svg";

export default function Add(props) {
  const { show, close, details } = props;
  const [onSubmit, setSubmit] = useState(false);
  const [disabled, setDisable] = useState(false);
  const [formValues, setFormValues] = useState([{ name: "" }]);
  const [pageDetails, setPageDetails] = useState();

  useEffect(() => {
    details && setPageDetails(details);
    details && setFormValues([{ name: details.name }]);
  }, [show]);

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
    setDisable(false);
  };

  let addFormFields = () => {
    setFormValues([...formValues, { name: "" }]);
  };

  let removeFormFields = (id) => {
    let values = [...formValues];
    setFormValues(values.filter((obj, index) => index != id));
  };

  const createOrUpdate = async () => {
    try {
      setSubmit(true);
      setDisable(true);
      for (const key in formValues) {
        if (formValues[key].name == "") {
          return false; // Return true if any value is empty
        }
      }

      if (details && details.identifier) {
        await axios
          .patch(
            `${Application.api_url}/api/v2/inventory_locations/${pageDetails.identifier}`,
            { name: formValues[0].name }
          )
          .then((result) => {
            if (result.data && result.data.success === true) {
              toast.success(`Update Successfully`);
              close(result.data);
            }
          })
          .catch((error) => toast.error(error.response.data.message));
      } else {
        await axios
          .post(
            `${Application.api_url}/api/v2/inventory_locations/bulk_create`,
            { locations: formValues }
          )
          .then((result) => {
            if (result.data && result.data.success === true) {
              toast.success(`Created Successfully`);
              close(result.data);
            }
          })
          .catch((error) => toast.error(error.response.data.message));
      }
    } catch (err) {
      console.log("err===>", err);
    }
  };

  return (
    <Modal
      show={show}
      onHide={(e) => {
        close();
      }}
      className="new-model-popup inventory-config-location"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {details && details.identifier && (
            <i className="fa fa-plus" aria-hidden="true"></i>
          )}
          <b>
            {" "}
            {details && details.identifier
              ? "Update Location"
              : "Add Location"}{" "}
          </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="new-form">
          <div className="row">
            <div className="col-md-12">
              <label>
                Location<text style={{ color: "red" }}>*</text>
              </label>
              {formValues.map((element, index) => (
                <>
                  <input
                    name="name"
                    id="name"
                    className="input-new"
                    key={index}
                    value={element.name}
                    onChange={(e) => handleChange(index, e)}
                  />
                  {index != 0 && (
                    <img
                      src={DeleteIcon}
                      alt="delete-icon"
                      className="location-dlt"
                      onClick={() => {
                        removeFormFields(index);
                      }}
                    />
                  )}
                  {onSubmit && !element.name && (
                    <p className="required-fields">Please enter the location</p>
                  )}
                </>
              ))}
              {!details && (
                <div
                  className="add-more"
                  onClick={() => {
                    addFormFields();
                  }}
                >
                  <span className="add-icon">+</span>
                  <span>Add Value</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn button cancel-btn"
          onClick={() => {
            close();
          }}
        >
          Cancel
        </button>
        <button
          className="btn btn-primary"
          disabled={disabled}
          onClick={(event) => {
            createOrUpdate();
          }}
        >
          {details && details.identifier ? "Update" : "Create"}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
