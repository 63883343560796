import React from 'react';
import './Modal.css'; // We'll add some CSS for the modal

const Modal = ({ isVisible, children }) => {
    if (!isVisible) {
        return null;
    }

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                {children}
            </div>
        </div>
    );
};

export default Modal;