import React from 'react';
import HeroSection from './CommonComponents/HeroSection';
import FeaturesSection from './CommonComponents/FeaturesSection';
import ParallaxSection from '../../pages/HomePage/Components/ParallaxSection';
import HomepageDemoSection from '../../pages/HomePage/Components/HomepageDemoSection';

export default function EmergencyManagement() {
    return (
        <>
            <HeroSection
                heroText={"Emergency Management"}
                subText={"Prepare and respond to emergency situations with SafetyLoop's emergency management tools."}
            />
            <FeaturesSection
                headerText={"Comprehensive Emergency Planning"}
                subText={"Develop detailed emergency plans and ensure safety during critical situations."}
                featureHighlights={[
                    "Create emergency response plans for various scenarios.",
                    "Assign roles and responsibilities for emergency response.",
                    "Set up automated alerts for emergency drills.",
                    "Track emergency plan effectiveness and compliance."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for Emergency Planning section here
            />
            <FeaturesSection
                headerText={"Real-Time Emergency Alerts"}
                subText={"Receive real-time alerts and notifications during emergencies."}
                featureHighlights={[
                    "Send alerts to team members and stakeholders.",
                    "Monitor response times and actions during emergencies.",
                    "Track communication and coordination during incidents."
                ]}
                // Placeholder: Add image for Emergency Alerts section here
            />
            <FeaturesSection
                headerText={"Post-Incident Analysis"}
                subText={"Analyze emergency responses and improve future safety plans."}
                featureHighlights={[
                    "Review post-incident actions and outcomes.",
                    "Identify areas for improvement in emergency plans.",
                    "Generate post-incident reports for compliance."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for Post-Incident Analysis section here
            />
            <div className="Home-Page sl-home-wrapper" style={{ paddingTop: 0 }}>
                <ParallaxSection />
            </div>
            <div className="features-homepage-wrapper">
                <HomepageDemoSection mergeTop={false} />
            </div>
        </>
    );
}
