import React from "react";

const UserGroups = ({groups}) => {
    const renderTableData = () => {
        return (groups.map((group, index) => {
                return <tr key={index}>
                    <td>
                        <div className="d-flex align-items-center">
                            <div className="avatar">
                            <span className="avatar-title rounded-circle border border-white bg-info">
                                G </span></div><span className="ml-mt" >{group.name}</span>
                        </div></td>
                    <td>{group.description}</td>
                    <td>{group.type}</td>
                </tr>
            })
        )
    }

    return (
        <div className="mb-20">
            <div className="card">
                <div className="card-body content">
                    <h5>Groups</h5>
                    <table className="table table-bordered table-striped">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Type</th>
                        </tr>
                        </thead>
                        <tbody>
                        {renderTableData()}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default UserGroups