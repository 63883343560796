import React, { useEffect } from "react";
import axios from "../../config/axiosConfig"; 
import Cookies from "js-cookie"; 
const SubcontractorLogin = () => {

    useEffect(() => {
        login()
    })

    const login = async () => { 
        try {
            const params = new URLSearchParams(window.location.search); 
            let token = params.get('token'); 
            let userInfo = {
                user: {
                    token: token
                },
            };
            $.ajax({
                type: "POST",
                url: "/users/sign_in",
                dataType: "json",
                data: userInfo,
                success: function (res) {
                    res.user.company_name = res.company_name
                    res.user.company_identifier = res.company_identifier
                    res.user.is_internal_user = res.is_internal_user
                   localStorage.setItem("user", JSON.stringify(res.user))
                   Cookies.set('token', res.authenticate_token);
                   axios.defaults.headers.common = {'Authorization': res.authenticate_token} 
                   window.location.href = "/internalsub_dashboard";
                },
            }); 
        } catch (err) {
            console.log("err===>", err);
        }
    }

    return (
        <></>
    )
};

export default SubcontractorLogin;