import React, {useState} from "react";
import {
    getSavedWidgets
} from "../../utils/util";
import FormsGroupByPanel from "./FormsGroupByPanel";
import useFetchUserData from "../../hooks/useFetchUserData";
import useFetchFiltersData from "../../hooks/userFetchFiltersData";

const FormsGroupByAnalytics = () => {
    const [widgets, setWidgets] = useState([]);
    const { filters } = useFetchFiltersData();
    const { user } = useFetchUserData();

    const OnDuplicateChart = (data) => {
        setWidgets(data);
    };

    const renderChartPanel = getSavedWidgets(user).map((item, index) => {
        return(
            <FormsGroupByPanel formFilters={filters} item={item} OnDuplicateChart={OnDuplicateChart} key={index}/>
        )
    });

    const renderContent = (widgets) => {
        return renderChartPanel;
    }

    return(
        <div className="chart-wrap forms-group-chart" id="forms-group-chart">
            {renderContent(widgets)}
        </div>
    )
}

export default FormsGroupByAnalytics;