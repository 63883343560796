import '../../assets/styles/themes.scss'
import React, { useEffect, useState } from "react"
import CheckoutArrowUpLineIcon from "./CheckoutArrowUpLineIcon"
import CheckoutArrowDownLineIcon from "./CheckoutArrowDownLineIcon"
import CheckoutTickBackGroundGreen from "./CheckoutTickBackGroundGreen.svg"

const CheckoutCollapse = ({ showSuffix, isOpen, children, isReverse, onClickCollapse, title }) => {
  const [open, setOpen] = useState(isOpen)

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  return (
    <div className={`eCollapse ${isReverse ? "isReverse" : ""}`}>
      <div
        className="eCollapse__header"
        onClick={() => {
          setOpen((pre) => !pre)
          onClickCollapse && onClickCollapse()
        }}
      >
        {open ? <CheckoutArrowUpLineIcon /> : <CheckoutArrowDownLineIcon />}
        <p className="mb-0">{title}</p>
        <div className={`suffixIcon ${showSuffix ? "showSuffix" : ""}`}>
          <img src={CheckoutTickBackGroundGreen} alt="" />
        </div>
      </div>
      <div className={`eCollapse--content ${open ? "open" : "close"}`}>
        <div style={{ marginBottom: "16px", marginTop: "16px" }}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default CheckoutCollapse
