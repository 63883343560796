import { useEffect, useState} from 'react';
import axios from 'axios';

let next_day = new Date(new Date().setDate(new Date().getDate() + 1));
const initialState = { form_types: [], completed_forms: {},
    audit_actions_stats: {},
    forms_per_day: [
        [Date.now(), 5, new Date().toLocaleDateString()],
        [next_day, 3, next_day.toLocaleDateString()]
    ] }

const useFetchChartData = (query) => {
    const [stats, setStats] = useState(initialState);
    const [loading, setLoading] = useState(true);
    const [error, SetError] = useState(false);

    const updateResponseData = (response, stats) => {
        let types = ['form_types', 'completed_forms', 'daily']
        let seriesOneData = []
        let forms_data = response.forms_per_day

        forms_data.forEach(function(entry) {
            seriesOneData.push([Date.parse(entry.name), entry.y, entry.name]);
        });

        response.forms_per_day = seriesOneData

        for (const element of types) {
            if (query['chart_type'] === element ){
                response[element] = stats[element]
            }
        }
        return response
    }

    useEffect(() => {
        const fetchData = async () => {
            //setLoading(true);
            await new Promise((resolve) => setTimeout(resolve, 500));
          
            try {
              let search_query = Object.entries(query).reduce(
                (a, [k, v]) => (v ? ((a[k] = v), a) : a),
                {}
              );
              let params =
                Object.keys(search_query).length === 0
                  ? search_query
                  : Object.assign({ filter: true }, search_query);
              
              const { data: response } = await axios.get(
                "analytics_dashboard/forms_stats_data",
                { params: params }
              );
          
              let result = updateResponseData(response, stats);
              setStats(result);
            } catch (error) {
              SetError(true);
              console.error(error);
            } finally {
              setLoading(false);
            }
          };

        fetchData().then(r => {
            setLoading(false);
            console.log('render success');
        });
    }, [query]);

    return {
        stats,
        loading,
        error
    };
};

export default useFetchChartData;