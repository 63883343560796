import React, { useState } from "react";
import { Button } from "react-bootstrap";
import video from "../../../javascript/assets/images/product-pg-video.svg";
import Analytics from "../../../javascript/assets/images/Analytics.png";
import ConnectEveryone from "../../../javascript/assets/images/ConnectEveryone.png";
import SolutionsEllipse from "../../../javascript/assets/images/solutions-ellipse.png";
import Client from "../../../javascript/assets/images/client.png";
import Message from "../../../javascript/assets/images/message.svg";
import Star from "../../../javascript/assets/images/star.svg";

const Solutions = () => {
  return (
    <div className="Solutions-Page">
      <div className="banner-section">
        <div className="home-container">
          <div className="banner-content">
            <div className="banner-inside-content">
              <h1>
                <b>Making Safety Visible</b>
              </h1>
              <p>
                Assisting project management teams in maintaining project schedules by enhancing overall project intelligence, including risk management, progress tracking, and safety measures.
              </p>
            </div>
            <div className="d-flex">
              {/* <Button className="contact-btn">
                <b> Learn More</b>
              </Button> */}
            </div>
          </div>
        </div>
      </div>
      <div className="safety-section">
        <div className="home-container">
          <div className="safety-section-details">
            <h4>
              <b>Safety Management and Risk Prevention.</b>
            </h4>
            <h3>Having difficulty obtaining precise progress reports?</h3>
            <p>
              Meeting the demands of client and management reporting can be difficult. It is not always feasible to be on-site constantly, yet accurate status information is still needed. 
            </p>
            <p>
              We provide a solution to give you real-time project intelligence on risk, safety, and progress, directly from the job site, to impress your clients and leaders.
            </p>
            <img src={video} alt="" />
          </div>
        </div>
      </div>
      <div className="explore-section">
        <div className="home-container">
          <div className="explore-details">
            <div className="d-flex">
              <div className="col-xs-12 col-md-4 col-lg-6 explore-image">
                <img src={ConnectEveryone} alt="" />
              </div>
              <div className="col-xs-12 col-md-8 col-lg-6">
                <div className="connect-everyone-content">
                  <h2>
                    <b>Make Informed Decisions—Faster</b>
                  </h2>
                  <h4>Prevent safety issues and keep projects on schedule.</h4>
                  <p className="connect-everyone-quotes">
                    Fast access to live leading, mid-stream and lagging
                    indicators direct from all jobsites. Drive prevention and
                    reduce issue response time.
                  </p>
                  <p className="connect-everyone-quotes">
                    With automated tracking of trade partner compliance, issues,
                    injury incidents, stop works notifications and more—you'll
                    stay ahead and close-out issues faster.
                  </p>
                  <p className="connect-everyone-quotes">
                    Save time with automated reports that are up to 43% more
                    accurate.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="scheduling-section">
        <div className="home-container">
          <div className="explore-details">
            <div className="d-flex">
              <div className="col-xs-12 col-md-6 col-lg-6">
                <div className="analytics-content">
                  <h2>
                    <b>On-time Planning and Scheduling</b>
                  </h2>
                  <h4>Improve productivity by up to 16%.</h4>
                  <p className="planning-scheduling">
                    Close schedule issues and reduce delay risk with real-time,
                    automated labor hours by trade partner by worker.
                  </p>
                  <ul className="explore-ul">
                    <li>
                      Speed-up hours reconciliation to manage cashflow and help
                      clients manage capital expenditure.
                    </li>
                    <li>
                      Simplify trade partner payment with visibility of pre-task
                      plans, daily report activities and labor hours-preventing
                      over or under payment.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xs-12 col-md-6 col-lg-6 explore-image">
                <img src={Analytics} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="solutions-cards">
        <div className="home-container">
          <div className="solution-card-section">
            <div className="solution-card-clo col-xs-12 col-md-12 col-lg-4">
              <div className="solutions-cards-details">
                <img src={SolutionsEllipse} alt="" />
                <h4>Live Trade Partner Scorecards</h4>
                <p>
                  Augment bid review processes with historic Project IQ. Use
                  issues, issue severity, injuries, incidents, safety engagement
                  and more. Ensure trade partners are up for the job—prior to
                  appointment.
                </p>
              </div>
            </div>
            <div className="solution-card-clo  col-xs-12 col-md-12 col-lg-4">
              <div className="solutions-cards-details">
                <img src={SolutionsEllipse} alt="" />
                <h4>Speed-up On-Boarding</h4>
                <p>
                  Digitize and simplify trade partner and worker onboarding.
                  With connectivity to your ERP and Project Management tools,
                  you'll reduce duplication, save time (up to 45 minutes per
                  worker) and improve compliance before starting on-site.
                </p>
              </div>
            </div>
            <div className="solution-card-clo  col-xs-12 col-md-12 col-lg-4">
              <div className="solutions-cards-details">
                <img src={SolutionsEllipse} alt="" />
                <h4>Automated Certificates and Renewals Tracking</h4>
                <p>
                  Trade partners provide all certificates of insurance data
                  (COI) and workforce certifications through HammerTech. Renewal
                  dates are automatically tracked with notifications sent out to
                  prevent lapse and maintain coverage.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="client-says">
        <div className="home-container">
          <div className="d-flex">
            <div className="col-xs-6 col-md-6 col-lg-4">
              <h1>
                <b>Our client says</b>
              </h1>
              <img src={Message} alt="" className="message-img" />
            </div>
            <div className="col-xs-6 col-md-6 col-lg-8">
              <div>
                <div className="star-rating">
                  <span>
                    <img src={Star} alt="" className="stars" />
                  </span>
                  <span>
                    <img src={Star} alt="" className="stars" />
                  </span>
                  <span>
                    <img src={Star} alt="" className="stars" />
                  </span>
                  <span>
                    <img src={Star} alt="" className="stars" />
                  </span>
                  <span>
                    <img src={Star} alt="" className="stars" />
                  </span>
                </div>
                <p className="client-feedback">
                  Instant reporting of critical incidents protects the health of
                  our staff and our bottom line. They see the doctor more
                  promptly, I can modify their work same-day, prevent strain and
                  have ZERO missed workdays in most cases. We expect to get a
                  rebate of nearly $100,000 from Partners in Injury Reduction
                  this year, in huge part due to Safetyloop.
                </p>
                <div className="client-review">
                  <div>
                    <img src={Client} alt="" className="client-img" />
                  </div>
                  <div className="client-details">
                    <h3>
                      <b>Andry Ford</b>
                    </h3>
                    <p>CEO at OZZ Eletric, Ontario.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Solutions;
