import { Switch, FormControl, Select, MenuItem, Pagination, Drawer } from "@mui/material";
import React, { useEffect, useReducer, useRef, useState } from "react";
import moment from "moment";
import EditIcon from "../../../../assets/fonts/edit.svg"
import SearchIcon from "../../../../assets/images/searchbar.svg"
import LocationIcon from "../../../../assets/images/Location.svg"
import ProfileIcon from "../../../../assets/fonts/Profile.svg"
import { withRouter } from "react-router-dom";
import axios from "../../../../config/axiosConfig";
import Application from "../../../../config/ApplicationWrapper";
import { toast } from "react-toastify";
import Loader from "../../../../components/NewLoader";


const initialState = {
    name: "",
    contact_email: "",
    contact_phone_number: "",
    city: "",
    state: "",
    country: "",
    zip_code: "",
    last_order_at: "",
    address: "",
    identifier: ""
};


const SupplierList = ({ history }) => {
    const mounted = useRef(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(30);
    const [pageDetails, setPageDetails] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [onSubmit, setSubmit] = useState(false);
    const [disabled, setDisable] = useState(false);
    const [count, setCount] = useState(0);
    const [name, setSearchByName] = useState();
    const [date, setSearchByDate] = useState();

    useEffect(() => {
        getPageDetails()
    }, [page, limit])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            mounted.current && getPageDetails()
            mounted.current = true;
        }, 2000)

        return () => clearTimeout(delayDebounceFn)
    }, [name, date])


    const getPageDetails = async () => {
        try {
            await axios.get(`${Application.api_url}/api/v2/suppliers`, {
                params: {
                    page: page,
                    limit: limit,
                    name_cont: name,
                    last_order_at_eq: date
                },
            }).then((res) => {
                if (res.data && res.data.success === true) {
                    let details = res.data;
                    setPageDetails(details ? details.data : [])
                    setPageCount(
                        details && details.metadata ? details.metadata.pages : 0
                    );
                    setCount(
                        details && details.metadata ? details.metadata.count : 0
                    );
                    setLoading(false)
                }
            }).catch((err) => console.log("err=>", err))
        } catch (err) {
            console.log("Err==>", err)
        }
    }

    const formReducer = (state, action) => {
        switch (action.type) {
            case "HANDLE INPUT CHANGE":
                return { ...state, [action.field]: action.payload };
            case "UPDATE":
                return { ...state, ...action.payload };
            case "RESET":
                return initialState
            default:
                return state;
        }
    };

    const [formValue, dispatch] = useReducer(formReducer, initialState);



    const handleTextChanges = (e) => {
        setDisable(false)
        dispatch({ type: "HANDLE INPUT CHANGE", field: e.target.name, payload: e.target.value });
    };

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const viewSupplier = (val) => {
        history.push(`/view_supplier/${val.identifier}/${val.name}`)
    }

    const createSupplier = async () => {
        try {
            setSubmit(true)
            setDisable(true)
            if (!formValue.name) {
                return false
            }
            if (formValue.identifier) {
                await axios.patch(`${Application.api_url}/api/v2/suppliers/${formValue.identifier}`, formValue).then((res) => {
                    if (res.data && res.data.success === true) {
                        getPageDetails()
                        setShow(false)
                        dispatch({ type: "RESET" })
                        toast.success("Updated successfully")
                    }
                }).catch((err) => console.log("err=>", err))
            } else {
                if(!formValue.contact_email){
                    delete formValue.contact_email
                }
                await axios.post(`${Application.api_url}/api/v2/suppliers`, formValue).then((res) => {
                    if (res.data && res.data.success === true) {
                        getPageDetails()
                        setShow(false)
                        dispatch({ type: "RESET" })
                        toast.success("Created successfully")
                    }
                }).catch((err) => console.log("err=>", err))
            }

            setSubmit(false)

        } catch (err) {
            console.log("Err==>", err)
        }
    }

    const list = () => (
        <div className="inventory-add-supplier">
            <div className="header">
                <h4>{`${formValue.identifier ? "update" : "Create"} Supplier`}</h4>
                <span onClick={() => {setSubmit(false); setShow(false)}}>X</span>
            </div>
            <div className="body">
                <div className="fields">
                    <label className="label-name">Supplier Name <text style={{ color: "red" }}>*</text></label>
                    <input name="name" id="name" className="new-input" value={formValue.name} onChange={(e) => { handleTextChanges(e) }} />
                    {onSubmit && !formValue.name && <p className="required-fields">Please enter the name</p>}
                </div>
                <div className="fields">
                    <label className="title"><img src={ProfileIcon} alt="profile-icon" className="mr-1" />Contact Information</label>
                    <div className="contact-info">
                        <div>
                            <label >Email</label>
                            <input name="contact_email" id="contact_email" className="new-input" value={formValue.contact_email} onChange={(e) => { handleTextChanges(e) }} />
                        </div>
                        <div>
                            <label >Contact Number</label>
                            <input name="contact_phone_number" id="contact_phone_number" value={formValue.contact_phone_number} className="new-input" onChange={(e) => { handleTextChanges(e) }} />
                        </div>
                    </div>
                </div>
                <div className="fields">
                    <label className="title"><img src={LocationIcon} alt="location-icon" />Location</label>
                    <div className="location">
                        <label >Address</label>
                        <input name="address" id="address" className="new-input" value={formValue.address} onChange={(e) => { handleTextChanges(e) }} />
                        <div>
                            <div className="city-state">
                                <div>
                                    <label >City</label>
                                    <input name="city" id="city" className="new-input" value={formValue.city} onChange={(e) => { handleTextChanges(e) }} />
                                </div>
                                <div>
                                    <label >State</label>
                                    <input name="state" id="state" className="new-input" value={formValue.state} onChange={(e) => { handleTextChanges(e) }} />
                                </div>
                            </div>
                            <div className="country-zip">
                                <div>
                                    <label >Country</label>
                                    <input name="country" id="country" className="new-input" value={formValue.country} onChange={(e) => { handleTextChanges(e) }} />
                                </div>
                                <div>
                                    <label >Zip Code</label>
                                    <input name="zip_code" id="zip_code" className="new-input" value={formValue.zip_code} onChange={(e) => { handleTextChanges(e) }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fields">
                    <label>Last Order Date</label>
                    <input type="date" name="last_order_at" id="last_order_at" className="new-input" value={formValue.last_order_at} onChange={(e) => { handleTextChanges(e) }} />
                </div>
            </div>
            <div className="footer">
                <button className="btn button cancel-btn" onClick={() => {
                    setSubmit(false); setShow(false)
                }}>Cancel</button>
                <button className="btn btn-primary" disabled={disabled} onClick={() => { createSupplier() }}>{formValue.identifier ? "Update" : "Create"}</button>
            </div>
        </div>
    );

    const archive = async (data) => {
        try {
            await axios.patch(`${Application.api_url}/api/v2/suppliers/${data.identifier}`, { is_active: !data.is_active }).then((result) => {
                if (result.data && result.data.success === true) {
                    toast.success(data.is_active ? 'Deactivated Successfully' : 'Activated Successfully')
                    getPageDetails()
                }
            }).catch((err) => console.log("err=>", err))
        } catch (err) {
            console.log("err==>", err)
        }
    }

    const clearFilter = () => {
        setSearchByName("");
        setSearchByDate("");
    }

    return (
        <div className="supplier-list">
            <div className="title">
                <h4>Supplier List <span>{count}</span></h4>
                <div className="add-new-btn">
                    <button onClick={() => setShow(true)}>+ Add Supplier List</button>
                </div>
            </div>
            <div className="fields-setting">
                <div className="asset-active-filters">
                    Active Filters :
                    <span className="active-filters">
                        <span>Supplier Name :</span>
                        <span className="selected">{name ? name : "All"}</span>
                        <span className={name ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setSearchByName("") }}>X</span>
                    </span>
                    <span className="active-filters">
                        <span>Last Order Date :</span>
                        <span className="selected">{date ? date : "All"}</span>
                        <span className={date ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setSearchByDate("") }}>X</span>
                    </span>
                    <span className="active-filters-clear" onClick={(e) => clearFilter()}>
                        Clear All
                    </span>
                </div>
            </div>
            <div className="page-content">
                <table>
                    <thead>
                        <tr>
                            <th className="divider">
                                Supplier Name
                                {pageDetails.length > 0 &&
                                    <div className="search-col">
                                        <input type="text" name="search" id="search" value={name} className="search-btn" onChange={(e) => { setSearchByName(e.target.value) }} />
                                        <img src={SearchIcon} alt="Search Icon" className="search-icon" placeholder="Search" />
                                    </div>
                                }
                            </th>
                            <th className="divider">
                                Contact Information
                            </th>
                            <th className="divider">
                                Last Order Date
                                {pageDetails.length > 0 &&
                                    <div className="search-col">
                                        <input type="date" name="search" id="search" value={date} className="search-btn" placeholder="All" onChange={(e) => { setSearchByDate(e.target.value) }} />
                                    </div>
                                }
                            </th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!loading &&
                            pageDetails.length > 0 &&
                            pageDetails.map((val, index) => {
                                val.last_order_at = val.last_order_at && moment(val.last_order_at).format('yyyy-MM-DD');
                                return (
                                    <tr>
                                        <td className="text-underline" onClick={() => { viewSupplier(val) }}>{val.name}</td>
                                        <td>
                                            <p className="action-by">{val.contact_phone_number}</p>
                                            <p className="action-time">{val.contact_email}</p>
                                        </td>
                                        <td>{val.last_order_at}</td>
                                        <td>
                                            <img src={EditIcon} alt="edit" onClick={() => { dispatch({ type: "UPDATE", payload: val }); setShow(true) }} />
                                            <Switch
                                                checked={val.is_active}
                                                onClick={(event) => archive(val)}
                                            />
                                        </td>
                                    </tr>
                                )
                            })}
                        <Loader pageDetails={pageDetails} loading={loading} btnName={"+ Add Supplier List"} open={() => { setShow(true) }} />
                    </tbody>
                </table>
            </div>
            <div className="page-limits">
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={limit}
                        onChange={(e) => {
                            setPage(1);
                            setLoading(true);
                            setLimit(e.target.value);
                        }}
                    >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                    </Select>
                </FormControl>
                <Pagination
                    count={pageCount || 1}
                    variant="outlined"
                    shape="rounded"
                    onChange={handleChangePage}
                />
            </div>
            <Drawer
                anchor={'right'}
                open={show}
                onClose={() => {setSubmit(false); setShow(false)}}
            >
                {list('right')}
            </Drawer>
        </div>
    )

}

export default withRouter(SupplierList);