import React from 'react';
import { BrowserRouter as Router, Route, Link, withRouter,Redirect, NavLink} from "react-router-dom";
import Login, {User} from "../../pages/HomePage/Login";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BarChartData from '../../config/bar_chart_data.json';
import TrainingChart from '../../config/drilldown_chart.json';
import PageSubNavBar from '../../pages/shared/PageSubNavBar';

const initialState = {
    summary: null,
    client: [],
    result:[],
    audit_types:[],
    owners:[],
    projects: [],
    emp_ids: [],
    fields: {company: '', project:'', employee_id: '', employee_name: '', training_type: '', training_topic: '', training_status: '', drill_down: "", chart_type: ""}
};

class ROTGraphs extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialState
        this.chart_empty_content = this.chart_empty_content.bind(this);
    }

    renderGraph(results, type){
        var that = this; var is_changed = "false"
        let chart_data = this.props.graph
        let fields = that.state.fields;
        var params = that.props.params
        var display = fields["drill_down"] == "" ? "false" : "true"
        if (params && params.drill_down != "" && params.chart_type == "child"){
          display = "true"
        }
        if (chart_data){
            // BarChartData["title"]["text"] = "Projects-Training Status"
            // BarChartData["subtitle"]["text"] = "Any project showing red area indicates that there is an employee with expired trainings assoicated with this project"
            // BarChartData["yAxis"]["title"]["text"] = "Count Of Employees"
            // BarChartData["tooltip"]["pointFormat"] = "<b>{point.y}</b>"
            // BarChartData["series"][0]["data"] = chart_data.projects.data
            // BarChartData.plotOptions["series"]["point"]["events"]["click"] = function () {
            //   $.each(fields, function(key,value){
            //     if (params.hasOwnProperty(key)){
            //         fields[key] = params[key];
            //     }
            //   });

            //   fields["project"] = this.project_id
            //   fields["drill_down"] = ""
            //   that.setState({fields});
            //   that.props.onFilterChange(this.project_id, that);
            // }
            // Highcharts.chart('projects_graph', BarChartData,
            //     function (chart) {
            //         var data = BarChartData.series[0].data
            //         var msg = that.chart_empty_content(data)
            //         if (msg != ""){
            //             chart.renderer.text(msg, 140, 140)
            //                 .css({
            //                     color: '#4572A7',
            //                     fontSize: '18px'
            //                 })
            //                 .add();

            //         }
            //     });

            // //element training grpah
            // if (display ==  "false" || params.drill_down == "" || params.drill_down == "self_training"){
            //     if (chart_data){
            //         TrainingChart["title"]["text"] = "Trainings and Certifications"
            //         TrainingChart["chart"]["type"] = "bar"
            //         TrainingChart["series"][0]["data"] = chart_data.trainings.types
            //         TrainingChart["drilldown"]["series"] = chart_data.trainings.topics
            //         TrainingChart["plotOptions"]["series"]["events"]["click"]=  function(event){
            //             $.each(fields, function(key,value){
            //                 if (that.props.params.hasOwnProperty(key)){
            //                     fields[key] = that.props.params[key];
            //                 }
            //             });

            //             if (event.point.type != undefined){
            //                 fields["training_type"] = event.point.name
            //                 fields["training_topic"] = ""
            //                 fields["chart_type"] = "parent"

            //             }else{
            //                 fields["training_topic"] = event.point.name
            //                 fields["chart_type"] = "child"
            //             }
            //             is_changed = "true";fields["drill_down"] = "element_training"

            //             if (fields["training_type"] == params.training_type){
            //                 is_changed = "false"
            //             }

            //             if (fields["training_topic"] != params.training_topic){
            //                 is_changed = "true"
            //             }

            //             if(is_changed == "true"){
            //                 // that.setState({fields});
            //                 // that.props.onFilterChange(this.project_id, that);
            //                 that.props.onFilterChange(this.project_id, fields);

            //             }
            //         }
            //         TrainingChart["chart"]["events"]["drillup"]=  function(event){
            //             fields["training_type"] = ""
            //             fields["training_topic"] = ""
            //             fields["chart_type"] = ""
            //             fields["drill_down"] = ""
            //             that.props.onFilterChange(this.project_id, fields);

            //         }
            //         Highcharts.chart('element_training_graph', TrainingChart,
            //             function (chart) { // on complete
            //                 if (TrainingChart.series[0].data.length < 1) {
            //                     chart.renderer.text('No Data Available', 140, 140)
            //                         .css({
            //                             color: '#4572A7',
            //                             fontSize: '20px'
            //                         })
            //                         .add();
            //                 }

            //             });
            //     }
            // }


        }
    }

    chart_empty_content(data){
      var msg = ""
      var empty_set = data.filter(function( obj ) { return obj.y == 0 });
      if (data.length < 1){
        msg = "No Data Available"
      }
      else if (empty_set.length == data.length) {
        msg = "No users are associated with the Projects"
      }
      return msg;
    }

    render() {
        const { graph, params } = this.props;
        return (

            <div className="row">

                {this.renderGraph(graph)}

                <div className="col-lg-6" id="projects_graph">

                </div>

                <div className="col-lg-6 el-tr-gr" id="element_training_graph">

                </div>

            </div>

            );
    }
}

export default ROTGraphs;
