import React from "react";
import loader from "../assets/images/newloader.gif";
import EmptyTable from "../assets/images/empty-assets.svg";
import { Button } from "@mui/material";

const Loader = ({ pageDetails, loading, btnName, open, colSpan=6 }) => {
    return (
        <>
            {
                loading ? (
                    <tr>
                        < td colSpan={colSpan} >

                            <div className="new-loader" >
                                <img src={loader} alt="" />
                            </div >
                        </td >
                    </tr >
                ) : pageDetails && pageDetails.length == 0 && (
                    <tr>
                        < td colSpan={colSpan} >
                            <div className="no-assets" style={{ textAlign: "center", padding: "80px" }}>
                                <img src={EmptyTable} />
                                <p>
                                    <b>No Data</b>
                                </p>
                                {btnName &&
                                <div className="add-new-btn" style={{display:"inline-block"}}>
                                    <button onClick={() => open()}>{btnName}</button>
                                </div>
                                    // <div className="add-btn">
                                    //     <Button variant="contained" className="btn-create" onClick={(e) => { open() }}>{btnName}</Button>
                                    // </div>
                                }
                            </div>
                        </td >
                    </tr >
                )
            }
        </>
    );
};

export default Loader;
