import React, { useState, useRef, useEffect } from "react"; 
import ArrowRight from "../../assets/fonts/ArrowRight.svg";
import CompanyIcon from "../../assets/images/companyicon.svg"
import _ from "lodash";
import Cookies from "js-cookie";

const QuickSettings = () => {
    const dragItem = useRef();
    const dragOverItem = useRef();
    const [list, setList] = useState([{
        id: 1,
        title: "Company Settings",
        desc: "Manage your company settings and performance",
        link: "/company_profile",
    }, {
        id: 2,
        title: "Documents",
        desc: "Manage Documents",
        link: "/company_documents_beta",

    }, {
        id: 3,
        title: "Form Builder",
        desc: "Manage your forms",
        link: "/template/new_forms",

    }]);

    useEffect(() => {
        let quickLink = Cookies.get("quick-settings");
        if (quickLink) {
            let sorted = []
            quickLink = JSON.parse(quickLink);
            quickLink.map(k => {
                list.filter(obj => {
                    if (obj.id === k) {
                        sorted.push(obj);
                    }
                })

            })
            setList(sorted);
        }
    }, [])

    const dragStart = (e, position) => {
        dragItem.current = position;
        console.log(e.target.innerHTML);
    };

    const dragEnter = (e, position) => {
        dragOverItem.current = position;
        console.log(e.target.innerHTML);
    };

    const drop = (e) => {
        const copyListItems = [...list];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        setList(copyListItems);
        let ids = _.map(copyListItems, "id");
        Cookies.set("quick-settings", ids)
    };

    return (
        <div className="my-saftyloop">
            <div className="quick-dlts">
                <h3 className="dashboard-quick-link">Setting</h3>
                <div className="quick-links">
                    {
                        list &&
                        list.map((item, index) => (
                            <div className="quick-link-cards"
                                onDragStart={(e) => dragStart(e, index)}
                                onDragEnter={(e) => dragEnter(e, index)}
                                onDragEnd={drop}
                                key={index}
                                onClick={(e) => { window.location.href= item.link}}
                                draggable> 
                                    <div className="title">
                                        <h2 className="cursor-pointer">{item.title}</h2>
                                        <img src={ArrowRight} alt="" className="cursor-pointer"/>
                                    </div>
                                    <p>{item.desc}</p>
                                    <img src={CompanyIcon} alt="" /> 
                            </div>
                        ))}
                </div>
            </div>
        </div>
    )
}

export default QuickSettings;