import React from 'react';
import DocumentIcon from "../../../assets/feature-icons/Document.png";
import FileUploadIcon from "../../../assets/feature-icons/Upload.png";
import CloseIcon from "../../../assets/feature-icons/Close.png";
import Success from "../../../assets/feature-icons/Success.png";
import PDF1 from "../../../assets/feature-icons/pdf1.png";
import IMG1 from "../../../assets/feature-icons/img1.png";
import Delete from "../../../assets/feature-icons/Delete.png";
import Plus from "../../../assets/feature-icons/Plus.png";
import HeroBannerImage from "../../assets/images/documentmanagement.png";

const DocumentManagement = () => {
    return (
        <div className="contractor-management-section">
      
                <img src={HeroBannerImage}  />
            
           

            <div className="container-section-placeholder"></div>
            
        </div>
    );
};

export default DocumentManagement;
