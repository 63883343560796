import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { User } from '../../../HomePage/Login';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { uploadFiles } from '../../../../utils/bulkUploadLogic';
import { Box, styled, Typography } from '@mui/material';


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#07bc0c' : '#07bc0c',
  },
}));

function LinearProgressWithLabel(props) {
  return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <BorderLinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography
              variant="body2"
              sx={{ color: 'text.secondary' }}
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
  );
}
export default function FileUploadComponent(props) {
  const { show, closepopup, details, parentId, onUploadSuccess } = props;
  const [progress, setProgress] = useState({count: 0, name: '', closeModal: false});
  const [disableBtn, setDisabledBtn] = useState(false);
  let currentUploadCount = 0;

  useEffect(() => {
    toast.options = {
      "preventDuplicates": true
    }
  })

  useEffect(() => {
    setProgress({count: 0, name: '', closeModal: false});
    setDisabledBtn(false);
  }, [show])

  const handleDragOver = (e) => {
    e.preventDefault();
    var dropArea = document.getElementById('dropArea');
    dropArea.classList.add('dragover');
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const items = e.dataTransfer.items;

    $('.drag_folder_upload').show();
    setDisabledBtn(true);
    // $('.dropArea').hide();
    // Commented the notification since already uploadfiles function has toast notification already
    // toast.info('Upload in progress!')
    uploadFiles(items, parentId, onUploadSuccess, setProgress, progress);
    $('.drag_folder_upload--close').show();
  };

  return (
      <Modal
          show={show}
          backdrop="static"
          keyboard={false}
          onHide={(e) => {
            disableBtn ? null : closepopup();
          }}
          className="qr-modal-wrap add-task-modal pf-model docs-model"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="pf-model-header">
              <p className="pf-model-title">Bulk Upload</p>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div class="dropArea" id="dropArea" onDragOver={handleDragOver} onDrop={handleDrop}>
              <div class="drag_and_drop">
                <i class="fas fa-download fa-3x"></i>
                <span>DRAG AND DROP HERE</span>
              </div>
            </div >
            <div className="span drag_folder_upload" style={{display: "none", fontWeight: "bold", fontSize: "17px", color: "green"}}>
              <p style={{ color: '#080808', fontSize: '17px', fontWeight: '500'}}>Upload in progress, please wait!</p>
              <p style={{ color: '#ff2f30', fontSize: '13px', fontWeight: '500'}}>Do not refresh or leave this page until all uploads are processed.</p>
            </div>
            <Box className="span drag_folder_upload" sx={{ width: '100%', display:"none" }}>
              <LinearProgressWithLabel value={progress.count}  variant="determinate"/>
              <p style={{ color: '#080808', fontSize: '13px', padding: '10px 0 0'}}>{progress.name && `${progress.name} is processed!`}</p>
            </Box>
          </div>
        </Modal.Body>
      </Modal >
  );
}
