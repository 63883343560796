import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  styled,
  Paper,
  Button,
  Slider,
  IconButton,
  Menu,
  Stack,
  Chip,
  Pagination,
  StepConnector,
  ListItemText,
  MenuItem,
  Checkbox,
  TableCell,
} from "@mui/material";

export const HeaderItem = ({
  title = false,
  isSort = false,
  handleSort,
  filter = false,
}) => {
  return (
    <div className="flex flex-c g10">
      <div className="flex sb">
        <span style={{ color: "#080808", fontWeight: 500 }}>{title}</span>
        {isSort && (
          <div style={{ cursor: "pointer" }} onClick={handleSort}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <g clip-path="url(#clip0_9262_4360)">
                <path
                  d="M9.66663 11.3333L5.49996 11.3333"
                  stroke="#5C5C5C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.3334 8L3.83337 8"
                  stroke="#5C5C5C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13 4.66675L3 4.66675"
                  stroke="#5C5C5C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_9262_4360">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        )}
      </div>
      <div className="flex">{filter}</div>
    </div>
  );
};
const CustomTableContainer = styled(TableContainer)({
  '.even-row': {
    backgroundColor:' #F5FCFF',
  },
  '.even-row .left, .even-row .right': {
    backgroundColor:' #F5FCFF',
  },
  '.odd-row': {
    backgroundColor: '#ffffff'
  },
  ".custom-placeholder": {
    color: "transparent",
  },
  '.search-input': {
    background: '#fff',
    color: '#080808',
    padding: '3px 5px',
    height: '38px',
    lineHeight: '1em',
    '.MuiFormLabel-root': {
      lineHeight: '1em',
    },
    '.MuiInputBase-input': {
      height: '30px',
      lineHeight: '1em',
      padding: '2.5px 4px 2.5px 8px'
    }
  },
  ".flex": {
    display: "flex",
  },
  ".sb": {
    justifyContent: "space-between",
  },
  ".g10": {
    gap: "10px",
  },
  ".flex-c": {
    flexDirection: "column",
  },
  ".mw100": {
    minWidth: "100px !important",
  },
  ".mw150": {
    minWidth: "150px !important",
  },
  ".wrap": {
    flexWrap: "wrap",
  },
  ".w100": {
    width: "100%",
  },
  ".left": {
    position: "sticky",
    left: 0,
    background: "white",
    zIndex: 2,
    "&.MuiTableCell-head": {
      zIndex: 4,
    },
  },
  ".right": {
    position: "sticky",
    right: 0,
    zIndex: 2,
    background: "white",
    "&.MuiTableCell-head": {
      zIndex: 4,
    },
  },
  ".left:after": {
    content: `''`,
    position: "absolute",
    inset: 0,
    borderRight: "2px solid rgba(0, 0, 0, 0.2)",
    zIndex: -1,
    filter: "blur(2px)",
  },
  ".right:after": {
    content: `''`,
    position: "absolute",
    inset: 0,
    borderLeft: "2px solid rgba(0, 0, 0, 0.2)",
    zIndex: -1,
    filter: "blur(2px)",
  },
  ".MuiTableCell-head": {
    background: "#E0F0F9",
    zIndex: 3,
  },
  ".MuiTableCell-root": {
    minWidth: "250px",
    padding: '10px'
  },
  ".desc": {
    color: "#909090",
    fontSize: "12px",
    lineHeight: "16px",
  },
  ".link-text:hover": {
    "span": {
      color: "#080808"
    }
  },
  ".elips150": {
    maxWidth: "150px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: '#080808',
    fontWeight: '700',
    textDecoration: 'underline'
  },
  ".elips275": {
    maxWidth: '300px'
  },
  borderRight: "1px solid #D3D3D3",
  borderLeft: "1px solid #D3D3D3",
  boxShadow: "none",
  borderTopLeftRadius: "initial !important",
  borderTopRightRadius: "initial",
  ".MuiInputBase-formControl": {
    fieldset: {
      border: "none",
    },
    ".MuiAutocomplete-popupIndicator": {
      transform: "rotate(360deg)",
    },
  },
  th: {
    ".MuiFormLabel-root.MuiInputLabel-shrink": {
      display: "none",
    },
  },
});

export const CustomBotton = styled(Button)({
  borderRadius: "4px",
  display: "flex",
  height: "40px",
  padding: "8px 16px",
  justifyContent: "center",
  alignItems: "center",
  fontWeight: 700,
  fontSize: "14px",
  textTransform: "none",
  "&.filled": {
    border: "1.5px solid #FFF",
    background: "#FFF",
    color: "#5C5C5C",
    boxShadow: "none",
  },
  "&.outlined": {
    border: "2px solid #FFF",
    color: "#FFF",
    background: "transparent",
    boxShadow: "none",
  },
});

export const ButtonCreate = ({ label = "Create", onClick = () => { } }) => {
  return (
    <CustomBotton
      type="button"
      variant="contained"
      size="small"
      className="filled"
      startIcon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M13.1854 7.8522H2.81455M8 2.66675V13.0376V2.66675Z"
            stroke="#5C5C5C"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      }
      onClick={onClick}
    >
      {label}
    </CustomBotton>
  );
};

export const ButtonExport = ({ label = "Export", onClick = () => { } }) => {
  return (
    <CustomBotton
      type="button"
      variant="contained"
      size="small"
      className="outlined"
      onClick={onClick}
      startIcon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M2.66662 11.3333V12.6666C2.66662 13.0202 2.80709 13.3593 3.05714 13.6094C3.30719 13.8594 3.64633 13.9999 3.99995 13.9999H12C12.3536 13.9999 12.6927 13.8594 12.9428 13.6094C13.1928 13.3593 13.3333 13.0202 13.3333 12.6666V11.3333"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.66662 7.33325L7.99995 10.6666L11.3333 7.33325"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8 2.66675V10.6667"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      }
    >
      {label}
    </CustomBotton>
  );
};

export const InviteButton = ({ label = "Export", onClick = () => { } }) => {
  return (
    <CustomBotton
      type="button"
      variant="contained"
      size="small"
      className="outlined"
      style={{ background: "#009E1F" }}
      onClick={onClick}
      startIcon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
        >
          <path
            d="M3.4609 8.00033L6.79423 11.3337L13.4609 4.66699"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      }
    >
      {label}
    </CustomBotton>
  );
};

export const ButtonOutlined = ({ label, onClick }) => {
  return (
    <CustomBotton
      type="button"
      variant="contained"
      size="small"
      className="outlined"
      onClick={onClick}
      startIcon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M2.66662 11.3333V12.6666C2.66662 13.0202 2.80709 13.3593 3.05714 13.6094C3.30719 13.8594 3.64633 13.9999 3.99995 13.9999H12C12.3536 13.9999 12.6927 13.8594 12.9428 13.6094C13.1928 13.3593 13.3333 13.0202 13.3333 12.6666V11.3333"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.66662 7.33325L7.99995 10.6666L11.3333 7.33325"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8 2.66675V10.6667"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      }
    >
      {label}
    </CustomBotton>
  );
};

export const ButtonWithLinkIcon = ({ label, onClick }) => {
  return (
    <CustomBotton
      type="button"
      variant="contained"
      size="small"
      className="outlined"
      onClick={onClick}
      startIcon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M6.66667 9.33342C6.88392 9.55515 7.14323 9.73131 7.42942 9.85156C7.7156 9.97182 8.02291 10.0338 8.33333 10.0338C8.64376 10.0338 8.95106 9.97182 9.23725 9.85156C9.52343 9.73131 9.78275 9.55515 10 9.33342L12.6667 6.66676C13.1087 6.22473 13.357 5.62521 13.357 5.00009C13.357 4.37497 13.1087 3.77545 12.6667 3.33342C12.2246 2.89139 11.6251 2.64307 11 2.64307C10.3749 2.64307 9.77536 2.89139 9.33333 3.33342L9 3.66676"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.33333 6.66665C9.11608 6.44492 8.85677 6.26876 8.57058 6.14851C8.2844 6.02825 7.97709 5.96631 7.66667 5.96631C7.35624 5.96631 7.04894 6.02825 6.76275 6.14851C6.47656 6.26876 6.21725 6.44492 6 6.66665L3.33333 9.33332C2.89131 9.77534 2.64298 10.3749 2.64298 11C2.64298 11.6251 2.89131 12.2246 3.33333 12.6666C3.77536 13.1087 4.37488 13.357 5 13.357C5.62512 13.357 6.22464 13.1087 6.66667 12.6666L7 12.3333"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      }
    >
      {label}
    </CustomBotton>
  );
};

export const ActionMenu = ({ children }) => {
  const [menuAnchor, setMenuAnchor] = useState(null);
  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };
  return (
    <>
      <IconButton
        onClick={(event) => handleMenuOpen(event)}
        style={{
          background: "#f6f6f6",
          border: "1px solid #ECECEC",
          borderRadius: "10%",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_9034_91554)">
            <path
              d="M13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12Z"
              fill="#080808"
              stroke="#080808"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12Z"
              fill="#080808"
              stroke="#080808"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12Z"
              fill="#080808"
              stroke="#080808"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_9034_91554">
              <rect
                width="24"
                height="24"
                fill="white"
                transform="matrix(0 -1 1 0 0 24)"
              />
            </clipPath>
          </defs>
        </svg>
      </IconButton>
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
        variant="menu"
      >
        {children(handleMenuClose)}
      </Menu>
    </>
  );
};

export const TagRender = ({ data = [], noLimit }) => {
  const [showExtra, setExtra] = useState(false);
  if (!data.length) {
    return "-";
  }
  const limit = noLimit ? data.length : 1;
  const slicedData = showExtra ? data.slice(0) : data.slice(0, limit);
  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        flexWrap: "wrap",
        maxWidth: noLimit ? "100%" : "350px",
        flexWrap: "wrap",
        gap: "10px",
      }}
    >
      {slicedData.map((e, i) => (
        <Chip
          key={i}
          label={e}
          title={e}
          variant="outlined"
          className="elips150"
          sx={{
            backgroundColor: "#F6F6F6",
            color: "#080808",
            borderColor: "#F6F6F6",
            borderRadius: "19px",
            marginLeft: "0px !important",
          }}
        />
      ))}
      {data.length > limit && (
        <Button
          style={{ color: "#407797" }}
          size="small"
          variant="text"
          onClick={() => setExtra(!showExtra)}
        >
          {showExtra ? "Show Less" : `+${data.length - limit} More`}
        </Button>
      )}
    </Stack>
  );
};

const CustomSlider = styled(Slider)({
  color: '#407797', // Custom primary color
  height: 8,
  '& .MuiSlider-track': {
    height: 8,
    borderRadius: 4,
    backgroundColor: '#407797', // Custom track color
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 44,
    '&:after': {
      content: "'<  >'",
      fontSize: 16,
      fontWeight: 700,
      textAlign: 'center',
      padding: 7
    },
    borderRadius: 5,
    backgroundColor: '#fff', // Custom thumb color
    border: '2px solid #407797', // Border color for thumb
    '&:hover': {
      boxShadow: 'inherit',
    },
    '&.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
  },
  '& .MuiSlider-rail': {
    height: 8,
    borderRadius: 4,
    backgroundColor: '#ccc', // Custom rail color
  },
});

const HorizontalScrollSlider = ({ scrollContainerRef }) => {
  const [scrollPos, setScrollPos] = useState(0);

  // Handle slider value change
  const handleSliderChange = (event, newValue) => {
    setScrollPos(newValue);
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      const maxScrollLeft =
        scrollContainer.scrollWidth - scrollContainer.clientWidth;
      scrollContainer.scrollLeft = (newValue / 100) * maxScrollLeft;
    }
  };

  // Sync slider with actual scroll position of the table container
  const syncSliderWithScroll = () => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      const maxScrollLeft =
        scrollContainer.scrollWidth - scrollContainer.clientWidth;
      const currentScroll = (scrollContainer.scrollLeft / maxScrollLeft) * 100;
      setScrollPos(currentScroll);
    }
  };

  // Add scroll event listener to update slider as user scrolls manually
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", syncSliderWithScroll);
    }
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", syncSliderWithScroll);
      }
    };
  }, [scrollContainerRef]);

  return (
    <CustomSlider
      value={scrollPos}
      onChange={handleSliderChange}
      aria-labelledby="horizontal-slider"
      sx={{ width: '20%', alignSelf: "right" }}
    />
  );
};

const PaginationStyle = styled(Pagination)({
  ".Mui-selected": {
    borderRadius: "4px",
    backgroundColor: "#407797 !important",
    color: "#fff !important",
  },
  ".MuiPaginationItem-sizeMedium": {
    borderRadius: "4px",
    backgroundColor: "#F6F6F6",
    color: "#080808",
  },
});

export const CustomPagination = ({
  totalPage,
  lastPage,
  currentPage,
  handlePageChange,
  rowOptions = [20, 50, 100],
  rowPerPage,
  handleRowPerPageChange,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        border: "1px solid #D3D3D3",
        padding: "12px 24px",
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        style={{ flex: 1, alignItems: "center" }}
      >
        <span style={{ fontSize: 12 }}>Show No. Records</span>
        {rowOptions.map((option, i) => {
          const selected = option === rowPerPage;
          return (
            <Button
              key={i}
              size="small"
              variant="outlined"
              disabled={selected}
              onClick={() => handleRowPerPageChange(option)}
              style={{
                fontSize: 14,
                padding: "5px 8px",
                backgroundColor: selected ? "#407797" : "#F6F6F6",
                color: selected ? "#fff" : "#080808",
                borderRadius: 4,
                minWidth: "30px",
                border: "1px solid transparent",
              }}
            >
              {option}
            </Button>
          );
        })}
      </Stack>
      <div>
        <PaginationStyle
          count={totalPage || 10}
          page={currentPage}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export const CustomConnector = styled(StepConnector)(({ theme }) => ({
  '& .MuiStepConnector-line': {
    borderColor: '#909090', // Set your desired connector color
    borderStyle: 'dashed',  // Makes the line dashed
    borderWidth: 2,
  },
}));

export const DynamicSVG = ({ text = '', imgUrl = null, isUser }) => {
  const firstLetter = text.charAt(0).toUpperCase();
  const bgColor = "#65B5E3";
  const textColor = "#FFFFFF";
  const width = 35;
  const height = 35;

  return imgUrl ? (
    <img
      src={imgUrl}
      alt={'text'}
      width={width}
      height={width}
      style={{ objectFit: "cover", borderRadius: 5 }}
    />
  ) : (
    <>
      {isUser ? <svg width="109" height="139" viewBox="0 0 109 139" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M67.1098 98.5996H41.8902L22.389 102.945C9.32526 105.856 0.0487804 117.299 0.0487804 130.502V139H108.951V130.502C108.951 117.299 99.6747 105.856 86.611 102.945L67.1098 98.5996Z" fill="#6E83B7" />
        <path d="M54.5 116.681C64.1814 116.681 72.1293 109.247 72.7916 99.8656L67.1098 98.5996H41.8902L36.2084 99.8656C36.8707 109.247 44.8189 116.681 54.5 116.681Z" fill="#EDEFF1" />
        <path d="M41.8902 84.5266V98.5995C41.8902 105.465 47.5357 111.03 54.5 111.03C61.4643 111.03 67.1098 105.465 67.1098 98.5995V84.5266H41.8902Z" fill="#FFD3B3" />
        <path d="M41.8902 90.9424C45.7589 92.5954 50.0224 93.5142 54.5 93.5142C58.9776 93.5142 63.2411 92.5957 67.1098 90.9424V84.5264H41.8902V90.9424Z" fill="#FCB98B" />
        <path d="M80.5793 45.2034V62.1546C80.5793 76.3535 68.9032 87.8639 54.5 87.8639C40.0968 87.8639 28.4207 76.3535 28.4207 62.1546V45.2034H80.5793Z" fill="#FFD3B3" />
        <path d="M73.7012 45.2034L80.5793 62.1546V45.2034H73.7012Z" fill="#6E83B7" />
        <path d="M35.2988 45.2034L28.4207 62.1546V45.2034H35.2988Z" fill="#6E83B7" />
        <path d="M24.4085 59.0469H28.4207V45.2034H24.4085C22.8257 45.2034 21.5427 46.4682 21.5427 48.0286V56.2217C21.5427 57.782 22.8257 59.0469 24.4085 59.0469Z" fill="#FFD3B3" />
        <path d="M84.5915 59.0469H80.5793V45.2034H84.5915C86.1743 45.2034 87.4573 46.4682 87.4573 48.0286V56.2217C87.4573 57.782 86.1743 59.0469 84.5915 59.0469Z" fill="#FFD3B3" />
        <path d="M86.0654 130.524H88.9312V139H86.0654V130.524Z" fill="#466089" />
        <path d="M20.0688 130.524H22.9346V139H20.0688V130.524Z" fill="#466089" />
        <path d="M23.8354 45.2033V34.185C23.8354 17.5163 37.5915 3.95532 54.5 3.95532C71.4085 3.95532 85.1646 17.5163 85.1646 34.185V45.2033H23.8354Z" fill="#FFE352" />
        <path d="M54.5 36.8323C70.4215 36.8323 92.9024 40.118 92.9024 40.118V45.2034H54.5H16.0976V40.118C16.0976 40.118 38.5785 36.8323 54.5 36.8323Z" fill="#FFE352" />
        <path d="M54.5 3.95532C48.2642 3.95532 42.4603 5.80442 37.6138 8.96893L39.4256 37.5439H69.5744L71.3862 8.96893C66.5397 5.80442 60.7358 3.95532 54.5 3.95532Z" fill="#FFB236" />
        <path d="M43.461 33.484H65.5387L67.5035 2.49324C63.483 0.890787 59.0963 0 54.5 0C49.9037 0 45.517 0.890787 41.4965 2.49324L43.461 33.484Z" fill="#FFE352" />
        <path d="M81.7256 120.823V101.856L73.7012 100.068V120.636C73.7012 126.877 68.5688 131.937 62.2378 131.937H46.7622C40.4312 131.937 35.2988 126.877 35.2988 120.636V100.068L27.2744 101.856V120.823C27.2744 123.094 26.5803 125.313 25.2823 127.189L22.9756 130.524V139H86.0244V130.524L83.7177 127.189C82.4197 125.313 81.7256 123.094 81.7256 120.823Z" fill="#FFE352" />
      </svg>
        : <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
          <rect
            x="0"
            y="0"
            width={width}
            height={height}
            rx="5" // Border radius
            fill={bgColor}
          />
          <text
            x="50%"
            y="50%"
            dy=".3em" // Adjust for vertical alignment
            textAnchor="middle"
            fill={textColor}
            fontSize="20px"
            fontWeight="bold"
          >
            {firstLetter}
          </text>
        </svg>}
    </>
  );
};

const ColumnSettings = ({ options, handler, stateData, columnSettingsName }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const menuRef = useRef(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (value) => {
    const newChecked = { ...stateData };

    if (newChecked[value]) {
      newChecked[value] = false;
    } else {
      newChecked[value] = true;
    }
    localStorage.setItem(columnSettingsName, JSON.stringify(newChecked));
    handler(newChecked);
  };

  // Close the menu when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target) && anchorEl) {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [anchorEl]);

  return (
    <>
      <div onClick={handleClick} style={{ cursor: 'pointer', marginRight: 'auto', paddingLeft: '10px' }}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_9101_39281)">
            <path d="M10.325 4.317C10.751 2.561 13.249 2.561 13.675 4.317C13.7389 4.5808 13.8642 4.82578 14.0407 5.032C14.2172 5.23822 14.4399 5.39985 14.6907 5.50375C14.9414 5.60764 15.2132 5.65085 15.4838 5.62987C15.7544 5.60889 16.0162 5.5243 16.248 5.383C17.791 4.443 19.558 6.209 18.618 7.753C18.4769 7.98466 18.3924 8.24634 18.3715 8.51677C18.3506 8.78721 18.3938 9.05877 18.4975 9.30938C18.6013 9.55999 18.7627 9.78258 18.9687 9.95905C19.1747 10.1355 19.4194 10.2609 19.683 10.325C21.439 10.751 21.439 13.249 19.683 13.675C19.4192 13.7389 19.1742 13.8642 18.968 14.0407C18.7618 14.2172 18.6001 14.4399 18.4963 14.6907C18.3924 14.9414 18.3491 15.2132 18.3701 15.4838C18.3911 15.7544 18.4757 16.0162 18.617 16.248C19.557 17.791 17.791 19.558 16.247 18.618C16.0153 18.4769 15.7537 18.3924 15.4832 18.3715C15.2128 18.3506 14.9412 18.3938 14.6906 18.4975C14.44 18.6013 14.2174 18.7627 14.0409 18.9687C13.8645 19.1747 13.7391 19.4194 13.675 19.683C13.249 21.439 10.751 21.439 10.325 19.683C10.2611 19.4192 10.1358 19.1742 9.95929 18.968C9.7828 18.7618 9.56011 18.6001 9.30935 18.4963C9.05859 18.3924 8.78683 18.3491 8.51621 18.3701C8.24559 18.3911 7.98375 18.4757 7.752 18.617C6.209 19.557 4.442 17.791 5.382 16.247C5.5231 16.0153 5.60755 15.7537 5.62848 15.4832C5.64942 15.2128 5.60624 14.9412 5.50247 14.6906C5.3987 14.44 5.23726 14.2174 5.03127 14.0409C4.82529 13.8645 4.58056 13.7391 4.317 13.675C2.561 13.249 2.561 10.751 4.317 10.325C4.5808 10.2611 4.82578 10.1358 5.032 9.95929C5.23822 9.7828 5.39985 9.56011 5.50375 9.30935C5.60764 9.05859 5.65085 8.78683 5.62987 8.51621C5.60889 8.24559 5.5243 7.98375 5.383 7.752C4.443 6.209 6.209 4.442 7.753 5.382C8.753 5.99 10.049 5.452 10.325 4.317Z" stroke="#407797" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="#407797" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_9101_39281">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>

      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        ref={menuRef} // Attach ref to the Menu
      >
        {options.map((option) => (
          <MenuItem key={option.value} onClick={() => handleToggle(option.value)}>
            <Checkbox
              checked={stateData[option.value]}
              onChange={() => handleToggle(option.value)}
            />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

const CustomTable = ({
  headers = [],
  rows = [],
  isStickyHeader = true,
  isSliderScroll = true,
  renderActiveFilter = true,
  enableColumnSettings = false,
  columnSettingsHandler,
  columnSettingsState,
  columnSettingsName = 'usersField',
  columnSettingsOptions = []
}) => {
  const tableContainer = useRef(null);
  const borderStyle = renderActiveFilter
    ? { borderRight: "1px solid #D3D3D3", borderLeft: "1px solid #D3D3D3" }
    : {};
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "16px 24px",
          gap: "16px",
          justifyContent: 'space-between',
          ...borderStyle,
        }}
      >
        {renderActiveFilter}
        {rows.length > 0 && isSliderScroll && (
          <HorizontalScrollSlider
            style={{ color: "#407797" }}
            scrollContainerRef={tableContainer}
          />
        )}
        {enableColumnSettings && <ColumnSettings columnSettingsName={columnSettingsName} options={columnSettingsOptions} handler={columnSettingsHandler} stateData={columnSettingsState} />}
      </div>
      <CustomTableContainer
        ref={tableContainer}
        style={{ maxHeight: isStickyHeader ? "100vh" : "auto" }}
        component={Paper}
      >
        <Table sx={{ maxWidth: "100%" }} stickyHeader={isStickyHeader}>
          <TableHead>
            <TableRow>{headers}</TableRow>
          </TableHead>
          <TableBody>{
          rows.length === 0 ? <TableRow>
            <TableCell colSpan={headers.length} align="center">No Records Found</TableCell>
          </TableRow> : rows
          }</TableBody>
        </Table>
      </CustomTableContainer>
    </>
  );
};

export default CustomTable;
