import React, { useState, useEffect } from "react";
import StatsCard from "../../components/StatsCard";
import axios from "axios";

let audits_stats_by_frequency = {
  DAILY: { completed: 0, milestone: 0, percentage: 0 },
  WEEKLY: { completed: 0, milestone: 0, percentage: 0 },
  MONTHLY: { completed: 0, milestone: 0, percentage: 0 },
  QUARTERLY: { completed: 0, milestone: 0, percentage: 0 },
  ANNUAL: { completed: 0, milestone: 0, percentage: 0 },
};

const AuditSchedulesOverview = ({}) => {
  const [stats, setStats] = useState(audits_stats_by_frequency);
  const [loading, setLoading] = useState(false);
  const [error, SetError] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const result = await axios("analytics_dashboard/audits_stats");
        setStats(result.data);
      } catch (error) {
        SetError(true);
      }
    }
    fetchData().then((r) => {
      setLoading(false);
      console.log("render success");
    });
  }, []);

  delete stats.unscheduled;
  const renderedCard = Object.keys(stats).map((data) => {
    let info = stats[data];
    return (
      <StatsCard
        key={data}
        label={data}
        stats={info}
        loading={loading}
        error={error}
      />
    );
  });

  return <div className="stats-list">{renderedCard}</div>;
};

export default AuditSchedulesOverview;
