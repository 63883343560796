import axios from 'axios';
import Application from './ApplicationWrapper';

const getCsrfTokenFromCookie = () => {
  const name = 'X-CSRF-Token=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');
  console.log(cookieArray)
  const cookie = cookieArray.find((elem) => elem.trim().startsWith(name))
  console.log(cookie)
  if (cookie) {
    const result = cookie.toString().trim().split('=')
    return result[1]
  }

  return null; // CSRF token not found
};

const csrfToken = getCsrfTokenFromCookie();

const axiosInstance = axios.create({
  baseURL: `${Application.api_url}/api/v2`,
  withCredentials: true
});

if (csrfToken)
  axiosInstance.defaults.headers['X-CSRF-TOKEN'] = csrfToken

axiosInstance.defaults.headers['Content-Type'] = 'application/json'

export default axiosInstance;
   