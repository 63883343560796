import React, { useEffect, useState, useReducer } from "react";
import { withRouter } from "react-router-dom";
import SideNav from "../../../../components/SideNav";
import ArrowUp from "../../../../assets/fonts/ArrowRight.svg";
import Inventory from "../../../../assets/fonts/Inventory.svg";
import axios from "../../../../config/axiosConfig";
import Application from "../../../../config/ApplicationWrapper";
import InventoryDetails from "./inventoryDetail";
import StockHistory from "./stockHistory";
import HistoricalLogs from "./historicalLogs";

const initialTabStatus = {
  inventoryDetails: true,
  stockHistory: false,
  historicalLogs: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE":
      state[action.tab] = true;
      return { ...state };
    default:
      return state;
  }
};

const ViewInventory = ({ history, match }) => {
  const id = match.params.id;
  const path = match.params.path;
  const [tabs, dispatch] = useReducer(reducer, initialTabStatus);
  const [pageDetails, setPageDetails] = useState([]);
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    getPageDetails(); 
    if (path) { 
      setActiveTab(2);
    }
  }, [path]);

  const handleUpdateTab = (tab) => {
    dispatch({ type: "UPDATE", tab: tab });
  };

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const getPageDetails = async () => {
    try {
      axios
        .get(`${Application.api_url}/api/v2/inventories/${id}`)
        .then((res) => {
          setPageDetails(res.data.data);
        })
        .catch((err) => {
          console.log("err===>", err);
        });
    } catch (err) {
      console.lof("err==>", err);
    }
  };

  const goBack = () => {
    history.push(`/inventory`);
  };

  return (
    <div className="msmcontainer dashboard-container">
      <div className="row d-flex">
        <SideNav />
        <div className="content-wrap view-inventory">
          <div className="title-card">
            <div className="title-content">
              <div>
                <img
                  src={ArrowUp}
                  alt="back-arrow"
                  className="back-arrow"
                  onClick={(e) => {
                    goBack();
                  }}
                />
              </div>
              <div className="title">
                <div className="supplier">
                  <div className="supplier-dtls">
                    <img src={Inventory} alt="supplier-icon" />
                    <h4>
                      <b>{pageDetails.name}</b>
                    </h4>
                  </div>
                  <div className="breadcrumbs">
                    <span onClick={() => goBack()}>Inventory List &gt;</span>
                    <span className="current-pg">View Inventory</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="inventory-details">
            <div className="inventory-overview">
              <span className="desc">
                {pageDetails.notes != "null" && pageDetails.notes}
              </span>
              <span className="divider-line"></span>
              <div className="inventory-info">
                <div className="details">
                  <span className="field">Inventory Type</span>
                  <span className="value">
                    {pageDetails.inventory_type_name}
                  </span>
                </div>
                <div className="details">
                  <span className="field">Barcode/Serial Number</span>
                  <span className="value">{pageDetails.serial_no}</span>
                </div>
                <div className="details">
                  <span className="field">Supplier</span>
                  <span className="value">{pageDetails.supplier_name}</span>
                </div>
                <div className="details">
                  <span className="field">Location</span>
                  <span className="value">
                    {pageDetails.inventory_location_name}
                  </span>
                </div>
                <div className="details">
                  <span className="field">Notification Alert</span>
                  <span className="value">
                    {pageDetails.notification_alert}
                  </span>
                </div>
                <div className="details">
                  <span className="field">Reorder Status</span>
                  <span className="value">
                    {pageDetails.reorder_status ? "Reorder" : "-"}
                  </span>
                </div>
              </div>
            </div>
            <div className="inventory-img">
              <img
                src={pageDetails.image_url}
                alt="inventory-img"
                style={{ width: "144px", height: "144px" }}
              />
            </div>
          </div>
          <div className="tabs-container">
            <ul className="tab">
              <li
                onClick={() => {
                  handleUpdateTab("inventoryDetails");
                  handleTabClick(1);
                }}
                className={activeTab === 1 ? "active" : ""}
              >
                Inventory Detail
              </li>
              <li
                onClick={() => {
                  handleUpdateTab("stockHistory");
                  handleTabClick(2);
                }}
                className={activeTab === 2 ? "active" : ""}
              >
                Stock History
              </li>
              <li
                onClick={() => {
                  handleUpdateTab("historicalLogs");
                  handleTabClick(3);
                }}
                className={activeTab === 3 ? "active" : ""}
              >
                Historical Logs
              </li>
            </ul>
          </div>
          <div className="tabs-list">
            <div
              className="tab-content"
              style={{ display: activeTab === 1 ? "block" : "none" }}
            >
              {tabs.inventoryDetails && pageDetails && !path &&(
                <InventoryDetails pageDetails={pageDetails} />
              )}
            </div>

            <div
              className="tab-content"
              style={{ display: activeTab === 2 ? "block" : "none" }}
            >
              {(tabs.stockHistory || path) && <StockHistory id={id} />}
            </div>
            <div
              className="tab-content"
              style={{ display: activeTab === 3 ? "block" : "none" }}
            >
              {tabs.historicalLogs && <HistoricalLogs id={id} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ViewInventory);
