import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { COUNTRIES } from "../../../constants";
import { TextField, Autocomplete } from "@mui/material";
import axios from "config/axiosConfig";
import Application from "config/ApplicationWrapper";
import { toast } from "react-toastify";

function CreditCardForm(props) {
  const { show, handleClose, selectedPlan } = props;
  const [countries, setCountries] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [onSubmit, setOnSubmit] = useState(false);
  const [onRequest, setOnRequest] = useState(false);
  const [info, setInfo] = useState({
    first_name: "",
    last_name: "",
    full_number: "",
    card_type: "",
    cvv: "",
    expiration_month: "",
    expiration_year: "",
    billing_address: "",
    billing_address_2: "",
    billing_country: "",
    billing_city: "",
    billing_state: "",
    billing_zip: "",
  });

  const OPTIONAL_FIELDS = ["billing_address_2"];
  const LABEL_FIELDS = {
    first_name: "First Name on Card",
    last_name: "Last Name on Card",
    full_number: "Credit Card Number",
    card_type: "Card Type",
    cvv: "CVV",
    expiration_month: "Expiration Month",
    expiration_year: "Expiration Year",
    billing_address: "Billing Address 1",
    billing_address_2: "Billing Address 2",
    billing_country: "Billing Country",
    billing_city: "City",
    billing_state: "State",
    billing_zip: "Zip",
  };

  const isValidForm = () => {
    let isValid = true;
    for (let key in info) {
      if (!info[key] && !OPTIONAL_FIELDS.includes(key)) {
        isValid = false;
      }
    }
    return isValid;
  };

  const resetInfo = () => {
    setInfo({
      first_name: "",
      last_name: "",
      full_number: "",
      card_type: "",
      cvv: "",
      expiration_month: "",
      expiration_year: "",
      billing_address: "",
      billing_address_2: "",
      billing_country: "",
      billing_city: "",
      billing_state: "",
      billing_zip: "",
    });
  };

  const getStateList = async () => {
    let list = await axios.get(
      `${Application.api_url}/api/v2/common/state_list`
    );
    if (list.data && list.data.success == true) {
      setStateList(list.data.data);
    }
  };

  const submitForm = () => {
    console.log(info);
    setOnSubmit(true);
    if (isValidForm()) {
      setOnRequest(true);
      axios
        .post(
          `${Application.api_url}/api/v2/inventories/subscribe_inventory_plan`,
          {
            credit_card_attributes: {
              ...info,
            },
            external_id: selectedPlan.id,
          }
        )
        .then((response) => {
          if (response.data.success) {
            toast.success("Payment successful");
            handleClose(true);
          }
        }).catch((error) => {
          const errors = error.response.data.message.credit_card_attributes || error.response.data.message;
          if (typeof errors === 'string') {
            toast.error(errors);
          } else if (typeof errors === 'object') {
            for (let key in errors) {
              toast.error(`${LABEL_FIELDS[key]} ${errors[key]}`);
            }
          }
        }).finally(() => {
          setOnRequest(false);
        });
    } else {
      console.log("Form is invalid");
    }
  };

  useEffect(() => {
    resetInfo();
    getStateList();
    setCountries(COUNTRIES);
  }, []);

  return (
    <div>
      <Modal
        show={show}
        bsSize="large"
        onHide={(e) => {
          setOnSubmit(false);
          handleClose(false);
        }}
        className="qr-modal-wrap add-task-modal signature-popup"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <i
              className="fa fa-credit-card"
              style={{
                fontSize: "19px",
                marginRight: "5px",
              }}
            ></i>
            <b>Payment</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h2>Card Details</h2>
          <div className="paywall__credit-card__row">
            <TextField
              autoFocus
              margin="dense"
              id="first_name"
              label="First Name on Card"
              type="text"
              value={info.first_name}
              fullWidth
              error={!info.first_name && onSubmit === true}
              helperText={
                !info.first_name && onSubmit === true
                  ? "Please enter your first name"
                  : ""
              }
              onChange={(e) => {
                setInfo({ ...info, first_name: e.target.value });
              }}
            />
            <TextField
              margin="dense"
              id="last_name"
              label="Last Name on Card"
              type="text"
              value={info.last_name}
              error={!info.last_name && onSubmit === true}
              helperText={
                !info.last_name && onSubmit === true
                  ? "Please enter your last name"
                  : ""
              }
              onChange={(e) => {
                setInfo({ ...info, last_name: e.target.value });
              }}
              fullWidth
            />
          </div>
          <div className="paywall__credit-card__row">
            <TextField
              margin="dense"
              id="card_number"
              label="Credit Card Number"
              type="text"
              value={info.full_number}
              fullWidth
              error={!info.full_number && onSubmit === true}
              helperText={
                !info.full_number && onSubmit === true
                  ? "Please enter your credit card number"
                  : ""
              }
              onChange={(e) => {
                setInfo({ ...info, full_number: e.target.value });
              }}
            />
            <Autocomplete
              disablePortal
              id="card_type"
              sx={{ width: "100%" }}
              className="mt-2"
              options={[
                { label: "Visa", value: "visa" },
                { label: "Master Card", value: "master" },
              ]}
              getOptionLabel={(option) => option.label}
              onChange={(_event, value) =>
                setInfo({ ...info, card_type: value.value })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Card Type"
                  error={!info.card_type && onSubmit === true}
                  helperText={
                    !info.card_type && onSubmit === true
                      ? "Please select your card type"
                      : ""
                  }
                />
              )}
            />
          </div>
          <div className="paywall__credit-card__row">
            <TextField
              margin="dense"
              id="cvv"
              label="CVV"
              type="number"
              value={info.cvv}
              fullWidth
              error={!info.cvv && onSubmit === true}
              helperText={
                !info.cvv && onSubmit === true
                  ? "Please enter your CVV number"
                  : ""
              }
              onChange={(e) => {
                setInfo({ ...info, cvv: e.target.value });
              }}
            />
            <div className="paywall__credit-card__row">
              <TextField
                margin="dense"
                id="expiration_month"
                label="Expiration Month"
                type="number"
                value={info.expiration_month}
                fullWidth
                error={!info.expiration_month && onSubmit === true}
                helperText={
                  !info.expiration_month && onSubmit === true
                    ? "Please enter your expiration month"
                    : ""
                }
                onChange={(e) => {
                  setInfo({ ...info, expiration_month: e.target.value });
                }}
              />
              <TextField
                margin="dense"
                id="expiration_year"
                label="Expiration Year"
                type="number"
                error={!info.expiration_year && onSubmit === true}
                helperText={
                  !info.expiration_year && onSubmit === true
                    ? "Please enter your expiration year"
                    : ""
                }
                value={info.expiration_year}
                fullWidth
                onChange={(e) => {
                  setInfo({ ...info, expiration_year: e.target.value });
                }}
              />
            </div>
          </div>
          <h2>Billing Details</h2>
          <TextField
            margin="dense"
            id="address1"
            label="Billing Address 1"
            type="text"
            value={info.billing_address}
            fullWidth
            error={!info.billing_address && onSubmit === true}
            helperText={
              !info.billing_address && onSubmit === true
                ? "Please enter your billing address"
                : ""
            }
            onChange={(e) => {
              setInfo({ ...info, billing_address: e.target.value });
            }}
          />
          <TextField
            margin="dense"
            id="address2"
            label="Billing Address 2"
            type="text"
            value={info.billing_address_2}
            fullWidth
            onChange={(e) => {
              setInfo({ ...info, billing_address_2: e.target.value });
            }}
          />
          <div className="paywall__credit-card__row">
            <Autocomplete
              disablePortal
              id="country"
              sx={{ width: "100%" }}
              className="mt-2"
              options={countries}
              onChange={(_event, country) => {
                setInfo({ ...info, billing_country: country.code });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Billing Country"
                  error={!info.billing_country && onSubmit === true}
                  helperText={
                    !info.billing_country && onSubmit === true
                      ? "Please select your billing country"
                      : ""
                  }
                />
              )}
            />
            <Autocomplete
              disablePortal
              id="state"
              sx={{ width: "100%" }}
              className="mt-2"
              options={stateList}
              onChange={(_event, state) =>
                setInfo({ ...info, billing_state: state.value })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Billing State"
                  error={!info.billing_state && onSubmit === true}
                  helperText={
                    !info.billing_state && onSubmit === true
                      ? "Please select your billing state"
                      : ""
                  }
                />
              )}
            />
          </div>
          <div className="paywall__credit-card__row mt-1">
            <TextField
              margin="dense"
              id="city"
              label="City"
              type="text"
              value={info.billing_city}
              fullWidth
              error={!info.billing_city && onSubmit === true}
              helperText={
                !info.billing_city && onSubmit === true
                  ? "Please enter your city"
                  : ""
              }
              onChange={(e) => {
                setInfo({ ...info, billing_city: e.target.value });
              }}
            />
            <TextField
              margin="dense"
              id="zip"
              label="Zip"
              type="text"
              value={info.billing_zip}
              fullWidth
              error={!info.billing_zip && onSubmit === true}
              helperText={
                !info.billing_zip && onSubmit === true
                  ? "Please enter your zip code"
                  : ""
              }
              onChange={(e) => {
                setInfo({ ...info, billing_zip: e.target.value });
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={submitForm} disabled={onRequest}>
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CreditCardForm;
