import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import moment from "moment";
import DollarIcon from "../../../../assets/fonts/Dollar.svg";
import LowStockThresholdIcon from "../../../../assets/fonts/LowStockThresholdIcon.svg";
import DatepickerIcon from "../../../../assets/fonts/Datepicker.svg";
import InventoryLevelOne from "../../../../assets/fonts/InventoryLevelOne.svg";
import Application from "../../../../config/ApplicationWrapper";
import axios from "../../../../config/axiosConfig";

const InventoryDetails = ({ pageDetails }) => {
  const [unitPriceDetails, setUnitPriceDetails] = useState([]);
  const [quantity, setQuantity] = useState([]);

  const [startDate, setStartDate] = useState(moment().format('yyyy-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('yyyy-MM-DD'));
  const [newStartDate, setNewStartDate] = useState(moment().format('yyyy-MM-DD'));
  const [newEndDate, setNewEndDate] = useState(moment().format('yyyy-MM-DD'));

  useEffect(() => {
    unitChart()
    quantityChart()
  }, []);

  const unitChart = async() => {
    try {
      axios
        .get(`${Application.api_url}/api/v2/inventories/chart_data`, {
          params: {
            start_date: startDate,
            end_date: endDate,
            aggregated_field: "unit_price",
            operator: "COUNT",
          },
        })
        .then((res) => {
          setUnitPriceDetails(res.data.data);
        })
        .catch((err) => {
          console.log("err===>", err);
        }); 
    } catch (err) {
      console.log("err==>", err);
    }
  }

  const quantityChart = async() => {
    axios
    .get(`${Application.api_url}/api/v2/inventories/chart_data`, {
      params: {
        start_date: newStartDate,
        end_date: newEndDate,
        aggregated_field: "quantity",
        operator: "COUNT",
      },
    })
    .then((res) => {
      setQuantity(res.data.data);
    })
    .catch((err) => {
      console.log("err===>", err);
    });
  }

  return (
    <div className="inventory-details-pg">
      <div className="title">
        <h2>Inventory Detail</h2>
      </div>
      <div className="header-widgets">
        <div className="widgets-details">
          <img src={InventoryLevelOne} alt="InventoryLevelOne" />
          <div className="info">
            <span className="title">Total Quantity </span>
            <span className="count">
              {Number(pageDetails.total_inventory_value) /
                Number(pageDetails.unit_price)}
            </span>
          </div>
        </div>
        <div className="widgets-details">
          <img src={LowStockThresholdIcon} alt="LowStockThresholdIcon" />
          <div className="info">
            <span className="title">Low Stock Threshold</span>
            <span className="count">{pageDetails.low_stock_threshold}</span>
          </div>
        </div>
        <div className="widgets-details">
          <img src={DollarIcon} alt="DollarIcon" />
          <div className="info">
            <span className="title">Unit Price</span>
            <span className="count">${pageDetails.unit_price}</span>
          </div>
        </div>
        <div className="widgets-details">
          <img src={DollarIcon} alt="DollarIcon" />
          <div className="info">
            <span className="title">Total Inventory Value</span>
            <span className="count">${pageDetails.total_inventory_value}</span>
          </div>
        </div>
        <div className="widgets-details">
          <img src={DatepickerIcon} alt="Datepicker" />
          <div className="info">
            <span className="title">Last Order Date</span>
            <span className="count">{pageDetails.last_order_date}</span>
          </div>
        </div>
        <div className="widgets-details">
          <img src={DatepickerIcon} alt="Datepicker" />
          <div className="info">
            <span className="title">Expiration Date </span>
            <span className="count">{pageDetails.expiration_date}</span>
          </div>
        </div>
      </div>
      <div className="row chart-details">
        <div className="col-md-6">
          <div className="date-picker">
            <div className="date-selectors">
              <input
                type="date"
                className="date-range-picker" 
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <span> to </span>
              <input
                type="date"
                value={endDate}
                className="date-range-picker" 
                onChange={(e) => setEndDate(e.target.value)}
              />
              <button onClick={()=>{unitChart()}}>Apply</button>
            </div>
          </div>
          <Chart
            options={{
              chart: {
                id: "basic-bar",
              },
              xaxis: {
                categories: unitPriceDetails.map((val) => val.date),
              },
              responsive: [
                {
                  breakpoint: 1000,
                  options: {
                    plotOptions: {
                      bar: {
                        horizontal: false,
                      },
                    },
                    legend: {
                      position: "bottom",
                    },
                  },
                },
              ],
            }}
            series={[
              {
                name: "Unit Price",
                data: unitPriceDetails.map((val) => val.total),
              },
            ]}
            type="line"
          />
        </div>
        <div className="col-md-6">
          <div className="date-picker">
            <div className="date-selectors">
              <input
                type="date"
                className="date-range-picker"
                value={newStartDate}
                onChange={(e) => setNewStartDate(e.target.value)}
              />
              <span> to </span>
              <input
                type="date"
                className="date-range-picker"
                value={newEndDate}
                onChange={(e) => setNewEndDate(e.target.value)}
              />
              <button onClick={()=>{quantityChart()}}>Apply</button>
            </div>
          </div>
          <Chart
            options={{
              chart: {
                id: "basic-bar",
              },
              xaxis: {
                categories: quantity.map((val) => val.date),
              },
              responsive: [
                {
                  breakpoint: 1000,
                  options: {
                    plotOptions: {
                      bar: {
                        horizontal: false,
                      },
                    },
                    legend: {
                      position: "bottom",
                    },
                  },
                },
              ],
            }}
            series={[
              {
                name: "Quantity",
                data: quantity.map((val) => val.total),
              },
            ]}
            type="bar"
          />
        </div>
      </div>
    </div>
  );
};

export default InventoryDetails;
