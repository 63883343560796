import React from 'react';
import { Button } from "react-bootstrap";

export default function HeroSection({ heroText, subText, ctaButtonTitle = "Book a demo" }) {
    return (
        <div className='feature-page'>
            <div className="banner-section">
                <div className="home-container">
                    <div className="banner-content">
                        <div className="banner-inside-content">
                            <h1><b>{heroText}</b></h1>
                            <p>{subText}</p>
                        </div>
                        <div className="d-flex">
                            <a href="/contact">
                                <Button className="contact-btn">
                                    <b>{ctaButtonTitle}</b>
                                </Button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
