import React, { useEffect, useState } from "react";
import axios from "../../../../config/axiosConfig";
import { TableContainer, Paper, Pagination, MenuItem, FormControl, Select } from '@mui/material';
import Application from "../../../../config/ApplicationWrapper";
import Loader from "../../../../components/NewLoader";

const InternalSubNewForm = ({ id }) => {
    let userDetails = localStorage.getItem("user");
    userDetails = JSON.parse(userDetails);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(30);
    const [pageCount, setPageCount] = useState(0);
    const [pageDetails, setPageDetails] = useState([]);
    const [name, setSearchByName] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getPageDetails()
        }, 2000)

        return () => clearTimeout(delayDebounceFn)
    }, [limit])

    const getPageDetails = async () => {
        try {
            window.scrollTo(0, 0);
            setLoading(true)
            let resultObj = await axios.get(`${Application.api_url}/api/v2/internal_subs/${userDetails.company_identifier}/internal_templates`, {
                params: {
                    page: page,
                    limit: limit,
                    audit_template_name_cont: name,
                },
            });
            if (resultObj.status == 200) {
                let details = resultObj.data;
                setPageDetails(details ? details.data : [])
                setPageCount(
                    details && details.metadata ? details.metadata.pages : 0
                );
                setLoading(false)
            }
        } catch (err) {
            console.log("error==>", err);
        }
    };

    const handleChangePage = (event, value) => {
        setPage(value);
        getPageDetails()
    };

    const hanldeLimitChange = (value) => {
        setPage(1);
        setLimit(value);
    }

    return (
        <> 
            <h2 className="internal_sub_web_table_title">New Form</h2>
            <div className="nestedTable pf-table internal_sub_web_table">
                <TableContainer component={Paper}>
                    <table class="table table-striped">
                        <thead>
                            <tr style={{ height: "100px" }}>
                                <th scope="col" className="risk-assessment-divider">
                                    <div className="d-flex justify-content-between align-item-center">
                                        Forms Name
                                    </div>
                                    <div class="input-group">
                                        <input
                                            type="text"
                                            class="form-control assessment-name"
                                            placeholder="Search"
                                            aria-label="Username"
                                            id="name"
                                            aria-describedby="basic-addon1"
                                            name="name"
                                            onChange={(e) => {
                                                setSearchByName(e.target.value)
                                            }}
                                            value={name}
                                        />
                                        <div class="input-group-addon">
                                            <span class="input-group-text" id="basic-addon1">
                                                <i class="fa fa-search" aria-hidden="true"></i>
                                            </span>
                                        </div>
                                    </div>
                                </th>
                                <th scope="col" className="risk-assessment-divider">
                                    Source Company
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {pageDetails &&
                                pageDetails.length > 0 &&
                                pageDetails.map((val, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                {val.audit_template && val.audit_template.name}
                                            </td>
                                            <td>
                                                {val.parent_company && val.parent_company.name}
                                            </td>
                                        </tr>
                                    )
                                })}
                            <Loader pageDetails={pageDetails} loading={loading} />
                        </tbody>
                    </table>
                </TableContainer>
                <div className="page-limits">
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={limit}
                            onChange={(e) => {
                                hanldeLimitChange(e.target.value);
                            }}
                        >
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={30}>30</MenuItem>
                        </Select>
                    </FormControl>
                    {!loading && (
                        <Pagination
                            count={pageCount}
                            page={page}
                            variant="outlined"
                            shape="rounded"
                            onChange={handleChangePage}
                        />
                    )}
                </div>
            </div>
        </>
    );
}

export default InternalSubNewForm;
