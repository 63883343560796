import React from "react"

const PricingCircleTick = (props) => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      {...props}>
      <path
        d="M4.96811 9.52002C7.66234 9.52002 9.84645 7.38889 9.84645 4.76002C9.84645 2.13114 7.66234 0 4.96811 0C2.27389 0 0.0897827 2.13114 0.0897827 4.76002C0.0897827 7.38889 2.27389 9.52002 4.96811 9.52002Z"
        fill="#currentColor"/>
      <path d="M4.09003 6.81868L2.73629 5.47397C2.73629 5.47397 2.68751 5.34308 2.73629 5.29548L3.44364 4.6053C3.44364 4.6053 3.5778 4.5577 3.62658 4.6053L4.09003 5.06938C4.09003 5.06938 4.22418 5.11698 4.27296 5.06938L6.28527 3.10588C6.28527 3.10588 6.41943 3.05828 6.46821 3.10588L7.17557 3.79607C7.17557 3.79607 7.22435 3.927 7.17557 3.9746L4.26077 6.81868C4.26077 6.81868 4.12661 6.86628 4.07783 6.81868H4.09003Z"
            fill="white"/>
    </svg>

  )
}

export default PricingCircleTick
