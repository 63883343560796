import React from "react";
import {
    buildStyles,
    CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import RadialSeparators from "./RadialSeparators";
import "./custom.css";
import ErrorFallback from "./ErrorFallback";

const StatsCard = ({ label, stats, key, loading, error }) => {
    let stats_missed = stats.missed;
    let isPathColor = stats_missed > 0 ? "#C07283" : "#86AD77";
    const isValue = stats.missed > 0 ? stats.missed : stats.completed;
    return (
        <div className={loading ? "stats-widget loader" : "stats-widget"}>
            <span className="stats-frequency-label">{label}</span>
            {!error && (
                <React.Fragment>
                    <div className="circular-wrap">
                        <CircularProgressbarWithChildren
                            value={isValue}
                            maxValue={stats.milestone}
                            text={`${stats.percentage}%`}
                            strokeWidth={10}
                            styles={buildStyles({
                                strokeLinecap: "butt",
                                pathTransitionDuration: 0.5,
                                textColor: "#f88",
                                pathColor: `${isPathColor}`,
                            })}
                        >
                            <RadialSeparators
                                count={stats.milestone}
                                style={{
                                    background: "#fff",
                                    width: "2px",
                                    // This needs to be equal to props.strokeWidth
                                    height: `${10}%`,
                                }}
                            />
                        </CircularProgressbarWithChildren>
                    </div>
                    <a className="stats-frequency-label" href="/stats">
                        C-{stats.completed}/G-{stats.milestone}
                    </a>
                    <a
                        className="stats-frequency-missing-label"
                        href={`/stats?frequency=${label.toLowerCase()}`}
                    >
                        /M-{stats.missed}
                    </a>
                </React.Fragment>
            )}

            {error && (<ErrorFallback/>)}
        </div>
    );
};

export default StatsCard;