import React from "react";
import { Modal } from "react-bootstrap";

export default function ShowImagePopup(props) {
    const { show, closepopup, details } = props;

    const onhandleStatusClose = () => {
        closepopup();
    };

    return (
        <Modal
            show={show}
            onHide={(e) => {
                onhandleStatusClose();
            }}
            className="qr-modal-wrap add-task-modal pf-model"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="pf-model-header">
                        <p className="pf-model-title"> Attachment Preview </p>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <img src={details} alt="image" style={{width: "100%"}}/>
            </Modal.Body>
        </Modal>
    );
}
