import React, { useEffect, useState, useContext } from "react";
import Settings from "../../../../assets/images/settings.svg";
import Application from "../../../../config/ApplicationWrapper";
import Pagination from "@mui/material/Pagination";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { AssetDetailsContext } from "./index";
import Cookies from "js-cookie";
import EmptyData from "../../../../assets/images/empty-assets.svg";

export default function HistoricalLogs() {
  const actions = [
    { label: "add", name: "add" },
    { label: "delete", name: "delete" },
    { label: "edit", name: "edit" },
  ];
  const [action, setAction] = useState([]);
  const [paginationStatus, setPaginationStatus] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [pageDetails, setPageDetails] = useState([]);
  const [date, setDate] = useState("");
  const [type, setType] = useState("");
  const [actionType, setActionType] = useState([]);
  const cookieDetails = Cookies.get("token");
  const { pageData } = useContext(AssetDetailsContext);

  useEffect(() => {
    const getPageDetails = async () => {
      try {
        if (pageData && pageData.id) {
          window.scrollTo(0, 0);

          const res = await fetch(
            `${Application.api_url}/api/v2/asset_trackings/${pageData.id}/logs?page=${page}&limit=${limit}&created_at_eq=${date}&notes_eq=${action}`,
            {
              method: "GET",
              headers: { Authorization: cookieDetails },
            }
          );
          const resultObj = await res.json();

          const actionDetails = await fetch(
            `${Application.api_url}/api/v2/common/log_notes?asset_id=${pageData.id}`,
            {
              method: "GET",
              headers: { Authorization: cookieDetails },
            }
          );
          const actionObj = await actionDetails.json();

          setActionType(actionObj && actionObj.data ? actionObj.data : [])

          setPageDetails(resultObj && resultObj.data ? resultObj.data : []);


          setPageCount(
            resultObj && resultObj.metadata ? resultObj.metadata.pages : 0
          );
          setPaginationStatus(true);
        }
      } catch (err) {
        console.log("error==>", err);
      }
    };

    getPageDetails();
  }, [pageData, page, limit, date, type, action]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const NoAssetsFound = () => (
    <div className="no-assets">
      <img src={EmptyData} />
      <p>
        <b>No Data</b>
      </p>
    </div>
  );





  return (
    <>
      <div className="title-wrap">
        <h3 className="title">Historical Logs</h3>

        <div className="header-options">
          <FormControl>
            <label>date log</label>
            <input
              type="date"
              className="form-control"
              onChange={(e) => {
                setDate(e.target.value);
              }}
            />
          </FormControl>

          <FormControl>
            <label>action type</label>
            <select
              placeholder="Select Action"
              className="form-control"
              onChange={(e) => {
                setAction(e.target.value);
                setPage(1);
                setPaginationStatus(false);
              }}
            >
              <option value="">
                select
              </option>
              {actionType && actionType.length > 0 && actionType.map((e) => (
                <option key={e.key} value={e.value}>
                  {e.value}
                </option>
              ))}
            </select>
          </FormControl>
        </div>
      </div>

      {pageDetails && pageDetails.length > 0 &&
        <>
          <ul className="logs-list">
            {pageDetails &&
              pageDetails.map((obj, index) => (
                <li>
                  <label>{obj.activity}</label>
                  <div className="log-info">
                    <div className="log-icon">
                      <img src={Settings} />
                    </div>
                    <div className="info-wrap">
                    <h3>
                      {obj && obj.performed_by && obj.performed_by.full_name ? (
                        <>
                          {obj.performed_by.full_name}
                          <span>
                            has {obj.action_type} {obj.notes}
                          </span>
                        </>
                      ) : (
                        "Information not available"
                      )}
                    </h3>

                      <div className="status">
                        <p>{obj.created_at}</p>
                        <span>
                          {obj.activity == "form" ? (
                            <a href={'/audits/' + obj.data.audit_id + '/report.pdf'} >
                              {obj.action_type} <strong>{obj.notes}</strong>
                            </a>
                          ) : (
                            <>
                              {obj.action_type} <strong>{obj.notes}</strong>
                            </>
                          )
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
          </ul>

          <div className="page-limits">
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={limit}
                onChange={(e) => {
                  setPage(1);
                  setLimit(e.target.value);
                  setPaginationStatus(false);
                }}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
              </Select>
            </FormControl>
            {paginationStatus && (
              <Pagination
                count={pageCount}
                variant="outlined"
                shape="rounded"
                onChange={handleChangePage}
              />
            )}
          </div>
        </>}

      {pageDetails && pageDetails.length === 0 &&
        <NoAssetsFound />
      }
    </>
  );
}
