import React from 'react'
import HeroSection from './CommonComponents/HeroSection'
import FeaturesSection from './CommonComponents/FeaturesSection'
import ParallaxSection from '../../pages/HomePage/Components/ParallaxSection'
import HomepageDemoSection from '../../pages/HomePage/Components/HomepageDemoSection'
import r1 from '../../../assets/images/doc1.png'
import r2 from '../../../assets/images/doc2.png'
import r3 from '../../../assets/images/doc3.png'

export default function CompanyDocumentManagement() {
  return (
    <>
      <HeroSection heroText={"Seamless Company Document Management"} subText={"Effortlessly organize, store, and manage all your company documents with SafetyLoop. Streamline document access, version control, and compliance for a more efficient safety management system."} />
      <FeaturesSection
        headerText={"Effortless Company Document Management"}
        subText={"SafetyLoop simplifies the way you handle and organize company documents, ensuring compliance and accessibility."}
        featureHighlights={["Centralize all important company documents in one secure repository.",
          "Easily categorize and tag documents for quick retrieval.",
          "Streamline document sharing and collaboration among teams.",
          "Ensure version control and track document changes over time."]}
        sectionBackground='dark'
        sectionAlign="image-right"
        featureImageSrc={r1}
      />
      <FeaturesSection
        headerText={"Create and Customize Documents with Ease"}
        subText={"Design and generate new documents seamlessly, tailoring them to your organization's specific needs."}
        featureHighlights={["Use built-in templates or create custom documents from scratch.",
          "Incorporate branding and styling for a professional look.",
          "Define document access and permissions to control distribution.",
          "Automate document creation processes for efficiency."]}
        featureImageSrc={r2}
      />
      <FeaturesSection
        headerText={"Stay Compliant with Required Documents"}
        subText={"SafetyLoop empowers you to track and manage required documents, ensuring compliance across the board."}
        featureHighlights={["Easily identify and monitor mandatory documents for different roles and projects.",
          "Create and manage a document matrix to map requirements and responsibilities.",
          "Receive alerts and notifications for document expirations and updates.",
          "Simplify audits and reporting with a comprehensive view of document compliance."]}
        sectionBackground='dark'
        sectionAlign="image-right"
        featureImageSrc={r3}
      />
      <div className="Home-Page sl-home-wrapper" style={{ paddingTop: 0 }}>
        <ParallaxSection />
      </div>
      <div className="features-homepage-wrapper">
        <HomepageDemoSection mergeTop={false} />
      </div>
    </>
  )
}
