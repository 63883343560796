import React, { useEffect, useReducer, useRef, useState } from "react";
import { Switch, Pagination, Drawer } from "@mui/material";
import moment from "moment";
import { withRouter } from "react-router-dom";
import axios from "../../../../config/axiosConfig";
import Application from "../../../../config/ApplicationWrapper";
import { toast } from "react-toastify";
import Loader from "../../../../components/NewLoader";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import EditIcon from "../../../../assets/fonts/edit.svg";
import SearchIcon from "../../../../assets/images/searchbar.svg";
import LocationIcon from "../../../../assets/images/Location.svg";
import ProfileIcon from "../../../../assets/fonts/Profile.svg";
import { User } from "../../../HomePage/Login";

const initialState = {
  name: "",
  contact_email: "",
  contact_phone_number: "",
  city: "",
  state: "",
  country: "",
  zip_code: "",
  last_order_at: "",
  address: "",
  identifier: ""
};

const UserList = ({ history }) => {
  const mounted = useRef(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(30);
  const [pageDetails, setPageDetails] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [onSubmit, setSubmit] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [count, setCount] = useState(0);
  const [searchName, setSearchByName] = useState("");
  const [searchEmployeeId, setSearchEmployeeId] = useState("");

  useEffect(() => {
    getPageDetails();
  }, [page, limit]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (mounted.current) getPageDetails();
      mounted.current = true;
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchName, searchEmployeeId]);

  const handleCheckboxChange = async (value, itemName, userId) => {
    try {
      const res = await axios.patch(`${Application.api_url}/api/v2/users/${userId}/toggle_menu_item`, {
        item_name: itemName,
        visibility: value
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      })

      if (res.data.success) {
        toast.success('Visibility updated.')
        let key;

        if (itemName === 'INVENTORY')
          key = 'inventory_item_visible'
        else if (itemName === 'PICKER')
          key = 'picker_item_visible'
        else if (itemName === 'RECEIVER')
          key = 'receiver_item_visible'
        else if (itemName == 'ONLY_INVENTORY')
          key = 'inventory_only'

        if (key) {
          const index = pageDetails.findIndex(user => user.id === userId)
          pageDetails[index][key] = value
          setPageDetails([...pageDetails.slice(0, index), pageDetails[index], ...pageDetails.slice(index + 1)])
        }
      }
    } catch (err) {
      toast.error(err.response.data.message);
    }
  }

  const getPageDetails = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${Application.api_url}/api/v2/users`, {
        params: { page, limit, full_name_cont: searchName, employee_id_cont: searchEmployeeId }
      });
      const details = response.data;
      if (details && details.success) {
        setPageDetails(details.data || []);
        setPageCount(details.metadata && details.metadata.pages || 0);
        setCount(details.metadata && details.metadata.count || 0);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching page details:", error);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const clearFilters = () => {
    setSearchByName("");
    setSearchEmployeeId("");
  };

  return (
    <div className="supplier-list">
      <div className="title">
        <h4>User List <span>{count}</span></h4>
      </div>
      <div className="fields-setting">
        <div className="asset-active-filters">
          Active Filters :
          <span className="active-filters">
            <span>Full Name :</span>
            <span className="selected">{searchName ? searchName : "All"}</span>
            <span className={searchName ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setSearchByName("") }}>X</span>
          </span>
          <span className="active-filters">
                      Employee ID: {searchEmployeeId || "All"}
            {searchEmployeeId && <span onClick={() => setSearchEmployeeId("")}>X</span>}
                  </span>
          <span className="active-filters-clear" onClick={clearFilters}>Clear All</span>
        </div>
      </div>
      <div className="page-content">
        <table>
          <thead>
          <tr>
              <th>
                Full Name
                <div className="search-col">
                  <input name="name" value={searchName} onChange={(e) => { setSearchByName(e.target.value) }} className="search-btn" />
                  <img src={SearchIcon} alt="Search Icon" className="search-icon" placeholder="Search" />
                </div>
              </th>
              <th>
                Employee ID
                {pageDetails.length > 0 &&
                  <div className="search-col">
                    <input type="text" name="search" id="search" value={searchEmployeeId} className="search-btn" onChange={(e) => { setSearchEmployeeId(e.target.value) }} />
                    <img src={SearchIcon} alt="Search Icon" className="search-icon" placeholder="Search" />
                  </div>
                }
              </th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody>
          {loading ? (
            <Loader pageDetails={pageDetails} loading={loading} open={() => { setShow(true) }} />
          ) : (
            pageDetails.map((val) => (
              <tr key={val.id}>
                <td>{val.full_name}</td>
                <td>{val.employee_id}</td>
                <td>

                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Inventory Ordering</Tooltip>}
                  >
                    <Switch checked={val.inventory_item_visible} onChange={(_) => handleCheckboxChange(!val.inventory_item_visible, 'INVENTORY', val.id)} />
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Picker</Tooltip>}
                  >
                    <Switch checked={val.picker_item_visible} onChange={(_) => handleCheckboxChange(!val.picker_item_visible, 'PICKER', val.id)} />
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Receiver</Tooltip>}
                  >
                    <Switch checked={val.receiver_item_visible} onChange={(_) => handleCheckboxChange(!val.receiver_item_visible, 'RECEIVER', val.id)} />
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Only Inventory</Tooltip>}
                  >
                    <Switch checked={val.inventory_only} onChange={(_) => handleCheckboxChange(!val.inventory_only, 'ONLY_INVENTORY', val.id)} />
                  </OverlayTrigger>
                </td>
              </tr>
            ))
          )}
          </tbody>
        </table>
        <Pagination count={pageCount} page={page} onChange={handlePageChange} />
      </div>
    </div>
  );
};

export default withRouter(UserList);
