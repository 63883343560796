import React, {Fragment, useEffect} from "react";
import Highcharts from 'highcharts'
import Chart from "../../components/Chart";

// import exporting from "highcharts/modules/exporting";
// exporting(Highcharts);

const HourlyChart = ({stats, query, onFilterSelect, error}) => {
    const categories = Object.keys(stats)
    const values = Object.values(stats)
    const filters = Object.assign({}, query);

    const handleChange = (event, data) => {
        let params = { ['chart_type']: 'completed_forms',  ['hourly_chart']: event.category, date: '' }
        if (query['chart_type'] == 'form_types'){
            params['template_id'] = ''
        }
        onFilterSelect(Object.assign({}, query, params))
    };

    let chartOptions = {
        "chart": {
            "type": "column"
        },
        "title": {
            "text": "Completed Forms"
        },
        "xAxis": {
            "categories": categories,
            "title": {
                "text": null
            },
            "gridLineWidth": 0,
            "enabled": false

        },
        "yAxis": {
            "min": 0,
            "title": {
                "text": "",
                "align": "high"
            },
            "labels" : {
                "enabled": false
            },
            "gridLineWidth": 0
        },
        "tooltip": {
            "valueSuffix": " "
        },
        "plotOptions": {
            "series": {
                "pointWidth": 60,
                "cursor": "pointer",
                "colorByPoint": true,
                "point": {
                    "events": {
                        click: function (e) {
                            handleChange(this, query)
                        }
                    }
                }
            },
            "bar": {
                "dataLabels": {
                    "enabled": false,
                    "crop": false,
                    "overflow": "none"

                }
            }
        },
        "colors": [
            "#639dcf",
            "#306a9c",
            "#18344E",
            "#132A3E"
        ],
        "legend": {
            "layout": "vertical",
            "align": "right",
            "verticalAlign": "top",
            "x": -40,
            "y": 80,
            "floating": true,
            "borderWidth": 1,
            "backgroundColor": "#FFFFFF",
            "shadow": true
        },
        "credits": {
            "enabled": false
        },
        "series": [{
            "showInLegend": false,
            "name": "Completed Forms",
            "data": values
        }],
        "exporting": {
            "enabled": true
        }
    }

    return (
            <Fragment>
                <Chart options={chartOptions} type={'chart'} highcharts={Highcharts} />
            </Fragment>
        )
}

export default HourlyChart;
