import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Button, TextField } from '@mui/material';
import axios from "@config/axiosConfig";
import Application from "@config/ApplicationWrapper";
import { toast } from "react-toastify";

const initialValues = {
    name: "",
    expiry_date: "",
    parent_id: "",
};

const CreateFolder = ({ show, closePopup, details, parentId, companyId }) => {

    const [validation, setValidation] = useState(true);
    const [onSubmit, setSubmit] = useState(false);
    const [values, setValues] = useState(initialValues);

    useEffect(() => {
        if (details) {
            setValues({ ...values, ...details });
        }
    }, [])

    const handleStatusClose = () => {
        closePopup();
        resetForm();
    };

    const resetForm = () => {
        setValues(initialValues);
        setValidation(true);
        setSubmit(false);
    };

    const handleChange = (name, value) => {
        setValues({ ...values, [name]: value });
    }

    const create = async () => {
        setSubmit(true);
        if (!values.name.trim()) {
            setValidation(false)
            return false;
        }

        let doc_type = "folder";
        if (details) doc_type = details.is_directory ? "folder" : "file";

        const users = JSON.parse(localStorage.getItem('user'));
        const formData = new FormData();

        formData.append("company_identifier", companyId);
        formData.append("doc_detail[doc_type]", doc_type);
        formData.append("doc_detail[user_id]", users.id);
        formData.append("doc_detail[expiry_date]", values.expiry_date);
        parentId && formData.append("doc_detail[parent_id]", parentId);

        if (doc_type === "file") {
            formData.append("doc_detail[file_name]", values.name.replace(/\.[^/.]+$/, ""));
        } else {
            formData.append("doc_detail[folder_name]", values.name);
        }

        let url = `${Application.api_url}/api/v2/company_documents/${companyId}/upload`;
        if (details) url = `${Application.api_url}/api/v2/company_documents/${details.slug}`;

        try {
            const result = await axios({
                method: "PATCH",
                url: url,
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' }
            });

            if (result.data && result.data.success === true) {
                toast.success(`${details ? "Updated" : "Created" } Successfully`)
                setValues("");
                setSubmit(false)
                closePopup(result.data);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <Modal
            show={show}
            onHide={(e) => {handleStatusClose();}}
            className="qr-modal-wrap add-task-modal pf-model docs-model"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="pf-model-header">
                        <p className="pf-model-title">{details ? "Update Details" : "Create Folder"} </p>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="row">
                        <div className="col-md-12">
                        <label>
                            {details && details.is_directory ? "Folder Name" : "File Name"} <text className="label-text">*</text>
                        </label>
                            <TextField
                                required
                                fullWidth
                                className="text-field"
                                id="outlined-required"
                                error={!validation && onSubmit}
                                helperText={
                                    !validation && onSubmit ? "Folder Name is required" : ""
                                }
                                value={values.name}
                                onChange={(e) => {
                                    setSubmit(false);
                                    handleChange("name", e.target.value);
                                }}
                            />
                        </div>
                        <div className="col-md-12">
                            <label>
                                Expiry Date
                            </label>
                            <input
                                type="date"
                                min={new Date().toJSON().split("T")[0]}
                                className="date-field"
                                id="expiry_date"
                                placeholder="Search"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                name="expiry_date"
                                data-date="MM DD YYYY"
                                data-date-format="MM DD YYYY"
                                value={values.expiry_date}
                                onChange={(e) => {
                                    handleChange("expiry_date", e.target.value);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="submit-btns">
                    <Button className="btn-cancel" onClick={(e) => { handleStatusClose() }}>Cancel</Button>
                    <Button variant="contained"
                            onClick={(event) => {
                                event.currentTarget.disabled = true;
                                create()
                            }}>
                        {details ? "Update" : "Create"}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default CreateFolder;

