import React from 'react';
import useUpdate from '../../../shared/components/useUpdate';
import NewGroup from './newGroup';

const CreateGroup = ({open, setOpen, setFilterState, refetch}) => {
    const {mutate, isLoading: isMutating, error} = useUpdate('/api/v2/entity_management/groups');
    return (
        <NewGroup open={open} mutate={mutate} setOpen={setOpen} setFilterState={setFilterState} refetch={refetch} isMutating={isMutating} />
    )
}

export default CreateGroup;
