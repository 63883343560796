import React from "react";

const RecordsNotFound = () => {
    return (
        <div className="loading-block">
            <p>No Records found</p>
        </div>
    )
}

export default RecordsNotFound