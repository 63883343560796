import React, {useContext, useEffect, useRef, useState} from "react";
import Filter from "../../../assets/images/Filter.png";
import Pagination from "@mui/material/Pagination";
import Cookies from "js-cookie";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Application from "../../../config/ApplicationWrapper";
import {RiskTypeContext} from "./index";
import {toast} from "react-toastify";

const ArchivedRiskType = ({refresh}) => {
    const { setArchivedCount } = useContext(RiskTypeContext);
    const [riskTypes, setRiskTypes] = useState([]);
    const [dataChange, setDataChange] = useState();

    const riskImageRef = useRef();
    const cookieDetails = Cookies.get("token");

    //Filters
    const [filterName, setFilterName] = useState('')
    const [sort, setSort] = React.useState('name');
    const [sortDirection, setSortDirection] = React.useState('asc');

    // Pagination
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [pagination, setPagination] = useState(true);
    const [pageCounts, setPageCounts] = useState(0);

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const changeFilter = (event) => {
        setFilterName(event.target.value)
        setPagination(false);
        page > 1 ? setPage(1) : null
    }

    const sorting = () => {
        let newDirection = sortDirection === 'asc' ? 'desc' : 'asc';
        let deg = newDirection == "desc" ? "180" : "0"
        riskImageRef.current.style.transform = `rotate(${deg}deg)`;
        setSortDirection(newDirection)
    }

    const onhandleRiskTypeRecover = async (riskId) => {
        try {
            fetch(`${Application.api_url}/api/v2/risk_assesments/risk_types/${riskId}/un_archive`, {
                method: "PATCH",
                headers: { Authorization: cookieDetails, 'Content-Type': 'application/json' },
            }).then(async response => {
                let resultObj = await response.json()
                if(resultObj.success) {
                    setDataChange(resultObj)
                    toast.success('Unarchived successfully');
                }
            })
        } catch (err) {
            console.log("error===>", err);
        }
    };

    const urlParams = () => {
        return new URLSearchParams({
            page: page,
            pagination: true,
            "query[name_cont]": filterName,
            limit: limit,
            archived: true,
            "query[archived_eq]": true,
            sort: sort,
            direction: sortDirection
        }).toString();
    }

    const updateResults = (resultObj) => {
        pagination == false ? setPagination(true) : null;
        setRiskTypes(resultObj.risk_types ? resultObj.risk_types : []);
        setPageCounts(resultObj.meta ? resultObj.meta.pages : 0);
        setArchivedCount(resultObj.meta ? resultObj.meta.count : 0);
    }

    const getArchivedRiskTypeDetails = async () => {
        try {
            let url = new URL(`${Application.api_url}/api/v2/risk_assesments/risk_types`);
            url.search = urlParams();

            fetch(
                url,
                {
                    method: "GET",
                    headers: { Authorization: cookieDetails },
                }
            ).then(async response => {
                let resultObj = await response.json();
                if(resultObj.success) {
                    updateResults(resultObj)
                }
            })
        } catch (err) {
            console.log("error===>", err);
        }
    }

    useEffect(() => {
        getArchivedRiskTypeDetails();
    }, [filterName, dataChange, refresh, sortDirection, limit, page]);

    return (
        <>
            <table class="table table-striped">
                <thead>
                <tr>
                    <th scope="col" style={{width:"40%"}}>
                        <div
                            className="d-flex justify-content-between"
                            onClick={(e) => {
                                sorting("risk_type");
                            }}
                        >
                            Risk Type
                            <img src={Filter} alt="" ref={riskImageRef} />
                        </div>
                        <div class="input-group">
                            <input
                                type="text"
                                class="form-control assessment-name"
                                placeholder="Search"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                onChange={(event) => changeFilter(event)}
                            />
                            <div class="input-group-addon">
                  <span class="input-group-text" id="basic-addon1">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </span>
                            </div>
                        </div>
                    </th>
                    <th scope="col" style={{width:"30%"}}></th>

                    <th scope="col" style={{textAlign:"left"}}>Action</th>
                </tr>
                </thead>
                <tbody>
                {riskTypes &&
                    riskTypes.length > 0 &&
                    riskTypes.map((val, index) => (
                        <tr key={index}>
                            <td>{val.name}</td>
                            <td></td>
                            <td className="template-options">
                                <a
                                    aria-expanded="false"
                                    aria-haspopup="true"
                                    className="btn dropdown-toggle"
                                    data-toggle="dropdown"
                                    href="#"
                                    id="dropdownMenuLink"
                                    role="button"
                                >
                                    <i className="fa fa-ellipsis-v"></i>
                                </a>
                                <ul aria-labelledby="dropdownMenuLink" className="dropdown-menu">
                                    <li>

                                        <a
                                            className="archive_group_invites"
                                            data-toggle="tooltip"
                                            href="#"
                                            title="Unarchive"
                                        >
                                            <div className="row" style={{display: "flex"}}
                                                 onClick={() => {
                                                     onhandleRiskTypeRecover(val.id);
                                                 }}
                                            >
                                                <div className="col-sm-3">
                                                    <i
                                                        className="fa fa-recycle"
                                                        style={{
                                                            paddingTop: "5px",
                                                            fontSize: "16px",
                                                            color: "#195701"
                                                        }}
                                                    ></i>
                                                </div>
                                                <div className="col-sm-9" style={{paddingTop: "5px"}}>
                                                    Unarchive
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div
                className="d-flex justify-content-between"
                style={{ alignItems: "center" }}
            >
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <Select labelId="demo-select-small" id="demo-select-small" value={limit}
                            onChange={(e)=>{
                                setLimit(e.target.value)
                                setPage(1)
                                setPagination(false)
                            }}
                    >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                    </Select>
                </FormControl>
                {
                    pagination ?
                        <Pagination
                            count={pageCounts}
                            variant="outlined"
                            shape="rounded"
                            onChange={handleChangePage}
                        /> : null
                }
            </div>
        </>
    );
};

export default ArchivedRiskType;
