import React, { useState } from "react";
import { Button, Modal, Typography, Box, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import useFetch from "../../../shared/components/userFetch";
import FormWrapper from "../../../shared/components/formWrapper";
import { ShowNotification, showToast } from "../../../shared/components/showNotification";
import { NewInviteForm } from "./NewInviteForm";
import Loader from "../../../shared/components/loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  height: "30%",
  backgroundColor: "#fff",
  boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.30)",
  borderRadius: "12px",
  p: 4,
};

const NewInite = ({
  open,
  setOpen,
  initialValues = null,
  mutate,
  setFilterState,
  isMutating
}) => {
  const isEdit = !!initialValues;
  const [notification, setNotification] = useState({
    open: false,
    message: "test",
    severity: "success",
  });
  const { data: baseData = [], isLoading: baseDataLoading } = useFetch(
    `/api/v2/entity_management/group_join_invites/base_data `
  );

  const defaultValues = isEdit
    ? { ...initialValues, public_group_id: initialValues.public_group_id }
    : {
        public_group_id: "",
      };
  const {
    control,
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues,
  });
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSubmit = (data) => {
    console.log(data);
    const payload = {
      public_group_id: data.group_managers.value,
    };

    if (isEdit) {
      payload.id = initialValues.id;
    }
    mutate(payload, {
      onSuccess: () => {
        showToast({ message: `Invite ${isEdit ? "Updated" : "Created"} Successfully!` });
        setTimeout(() => {
          setFilterState({ page: 1, limit: 20 });
          handleClose(); // Close the modal after submission
        }, 0);
      },
    });
  };

  if (baseDataLoading) {
    return <Modal
      open={open}
      onClose={() => false}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <FormWrapper>
        <Loader />
      </FormWrapper>
    </Modal>;
  }

  const handleCloseNotification = () => {
    setNotification((prev) => ({
      ...prev,
      open: false,
    })); // Hide notification, retain other state values
  };

  return (
    <>
      <ShowNotification
        open={notification.open}
        onClose={handleCloseNotification}
        severity={notification.severity}
        message={notification.message}
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <FormWrapper>
          <Box sx={style}>
            <Typography id="modal-title" variant="h6" component="h2">
              {isEdit ? "Update" : "New"} invite request
            </Typography>
            <p style={{ color: "#5C5C5C", fontSize: "14px" }}>
              Use this to send an invite request to join another global project
              in the ecosystem
            </p>
            <form
              onSubmit={handleSubmit(onSubmit)}
              // style={{ height: "calc(100% - 50px)" }}
            >
              <Box
                sx={{ mt: 2 }}
                // style={{ overflowY: "scroll", height: "calc(100% - 40px)" }}
              >
                <NewInviteForm
                  control={control}
                  errors={errors}
                  register={register}
                  setValue={setValue}
                  baseData={baseData}
                />
              </Box>

              <Box
                mt={2}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <div />
                <div>
                  <Button
                    style={{
                      textTransform: "none",
                      backgroundColor: "#ECECEC",
                      color: "#5C5C5C",
                      marginRight: "10px",
                      outline: "none",
                    }}
                    onClick={() => setOpen(false)}
                    sx={{ mr: 1 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{
                      textTransform: "none",
                      backgroundColor: "#407797",
                      color: "#fff",
                      outline: "none",
                    }}
                    variant="contained"
                    htmlType="button"
                    type="submit"
                    disabled={isMutating}
                    endIcon={isMutating ? <CircularProgress size={16} color="inherit" /> : null} 
                    color="primary"
                  >
                    Submit
                  </Button>
                </div>
              </Box>
            </form>
          </Box>
        </FormWrapper>
      </Modal>
    </>
  );
};

export default NewInite;
