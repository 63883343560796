import React, {useRef} from "react";
import { Modal } from "react-bootstrap";
import Typography from "@mui/material/Typography";
import { useReactToPrint } from 'react-to-print';
import { ComponentToPrint } from './ComponentToPrint';

export default function QrPopup(props) {
  const { show, closepopup, qrDetails } = props;

  const onhandleStatusClose = () => {
    closepopup();
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Modal
      show={show}
      onHide={(e) => {
        onhandleStatusClose();
      }}
      className="qr-modal-wrap add-task-modal signature-popup"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <i
            class="fa fa-qrcode"
            style={{
              fontSize: "19px",
              marginRight: "5px",
            }}
          ></i>
          <b> View QR Code </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="qr-info-item">
          <div className="qr-asset-info">
            <Typography variant="body2" gutterBottom className="qr-info-title">
              QR Information
            </Typography>
            <div className="qr-details">
            <Typography gutterBottom variant="subtitle1" component="div" className="qr-created-by">
              Created By:

              <label className="status-label">
                {qrDetails.created_by ? qrDetails.created_by.last_name + " " + qrDetails.created_by.first_name : "-"}
              </label>
            </Typography>
            <Typography gutterBottom variant="subtitle1" component="div" className="qr-created-by">
              Created On:

              <label className="status-label">
              {qrDetails.created_at }
              </label>
            </Typography>
            </div>
          </div>
        </div>
        <div id="qr-scan-img">
          <div style={{ textAlign: "center" }}>
            <img alt="" src={qrDetails.qr_url} />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-secondary"
          onClick={()=> window.open(`company/${qrDetails.company_id}/qr_code/${qrDetails.id}`, "_blank")}
        >
          <img style={{ width: "auto", padding: 0 }} />
          <span className="btn-text">QR Code Link</span>
        </button>

        <button
          className="btn btn-secondary"
          onClick={()=>{handlePrint()}}
        >
          <img  style={{ width: "auto", padding: 0 }} />
          <span className="btn-text">Print</span>
        </button>
      </Modal.Footer>
      <ComponentToPrint ref={componentRef} pageData={qrDetails}/>
    </Modal>
  );
}
