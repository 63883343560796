import React, { useEffect, useState, useContext } from "react";
import Pagination from "@mui/material/Pagination";
import Cookies from "js-cookie";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import EmptyData from "../../../../assets/images/empty-assets.svg";
import Application from "../../../../config/ApplicationWrapper";
import CreateMaintenance from "../Popups/Maintenance";
import { toast } from "react-toastify";
import { AssetCreateContext } from "./index";

const AssetMaintance = () => {
  const [pagaData, setPageData] = useState([]);

  const cookieDetails = Cookies.get("token");
  const { setTabKey } = useContext(AssetCreateContext);

  // Pagination
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [pagination, setPagination] = useState(true);
  const [pageCounts, setPageCounts] = useState(0);
  const [lastUpdate, setLastUpdate] = useState(0);
  const [maintenancePopup, setMaintenancePopup] = useState(false);
  const [editData, setEditData] = useState(false);

  let getAssetId = localStorage.getItem("assetInfo");
  let asstId = getAssetId ? JSON.parse(getAssetId) : "";

  useEffect(() => {
    try {
      async function getPageDetails() {
        if (asstId && asstId.asstId) {
          const groupDetails = await fetch(
            `${Application.api_url}/api/v2/asset_trackings/${asstId.asstId}/maintenance_schedules?limit=${limit}&page=${page}`,
            {
              method: "GET",
              headers: { Authorization: cookieDetails },
            }
          );
          const details = await groupDetails.json();
          setPageData(details && details.data ? details.data : []);
          setPageCounts(
            details && details.metadata ? details.metadata.pages : 1
          );
          setPagination(true);
        }
      }
      getPageDetails();
    } catch (err) {
      console.log("err===>", err);
    }
  }, [lastUpdate, limit, page]);

  const NoAssetsFound = () => (
    <div className="no-assets">
      <img src={EmptyData} />
      <p>
        <b>No Data</b>
      </p>
    </div>
  );

  const removeAssetsMaintance = async (assetId) => {
    const groupDetails = await fetch(
      `${Application.api_url}/api/v2/asset_trackings/${asstId.asstId}/maintenance_schedules/${assetId}`,
      {
        method: "DELETE",
        headers: { Authorization: cookieDetails },
      }
    );
    setLastUpdate(Date.now());
    toast.success("Deleted Successfully");
  };

  const openMaintenancePopup = (data) => {
    setMaintenancePopup(true);
    if (data) {
      setEditData(data);
    }
  };

  const closeMaintenancepopup = (data) => {
    setMaintenancePopup(false);
    if (data) {
      let message = editData ? "Updated Successfully" : "Created Successfully"
      toast.success(message);
      setLastUpdate(Date.now());
    }
    setEditData(false)
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <div className="relatedAssets asset-maintance">
      <div className="header d-flex">
        <h3 className="title">
          <b>Maintenance Schedule</b>
        </h3>
        <button
          type="button"
          className="btn btn-primary add-maintance-btn"
          onClick={() => {
            openMaintenancePopup();
          }}
        >
          <i className="fa fa-plus" aria-hidden="true"></i>
          Add Schedule
        </button>
      </div>
      <div className="body">
        <div className="table-template">
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">
                  <div className="d-flex justify-content-between">
                    Maintenance Name
                  </div>
                </th>
                <th scope="col">
                  <div className="d-flex justify-content-between">
                    Maintenance Service Intervals
                  </div>
                </th>
                <th scope="col">
                  <div className="d-flex justify-content-between">
                    Location of Maintenance
                  </div>
                </th>
                <th scope="col">
                  <div className="d-flex justify-content-between">
                    Responsible Party
                  </div>
                </th>
                <th scope="col" style={{ textAlign: "left" }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {pagaData &&
                pagaData.length > 0 &&
                pagaData.map((asset, index) => (
                  <tr key={index}>
                    <td>{asset.name}</td>
                    <td>{asset.service_intervals} {asset.unit}</td>
                    <td>{asset.location}</td>
                    <td>
                      <div className="responsible_parties">
                        {asset.responsible_parties &&
                          asset.responsible_parties.map((obj, index) => (
                            <span
                              className={`badge badge-light responsible-party`}
                            >
                              {obj.full_name}
                            </span>
                          ))}
                      </div>
                    </td>
                    <td>
                      <a
                        aria-expanded="false"
                        aria-haspopup="true"
                        className="btn dropdown-toggle"
                        data-toggle="dropdown"
                        id="dropdownMenuLink"
                        role="button"
                      >
                        <i className="fa fa-ellipsis-v"></i>
                      </a>
                      <ul
                        aria-labelledby="dropdownMenuLink"
                        className="dropdown-menu"
                      >
                        <li>
                          <a
                            className="archive_group_invites"
                            data-toggle="tooltip"
                            role="button"
                            title="Edit"
                            onClick={() => openMaintenancePopup(asset)}
                          >
                            <div className="row">
                              <div className="col-sm-3">
                                <i
                                  className="fa fa-pencil-square-o"
                                  style={{
                                    paddingTop: "5px",
                                    fontSize: "16px",
                                  }}
                                ></i>
                              </div>
                              <div
                                className="col-sm-9"
                                style={{ paddingTop: "5px" }}
                              >
                                Edit
                              </div>
                            </div>
                          </a>
                          <a
                            className="archive_group_invites asset-links"
                            data-toggle="tooltip"
                            role="button"
                            title="Delete"
                            onClick={(e) => {
                              removeAssetsMaintance(asset.id);
                            }}
                          >
                            <div className="row">
                              <div className="col-sm-3">
                                <i className="fa fa-trash"></i>
                              </div>
                              <div className="col-sm-9 archive-menu">Delete</div>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>
                ))}
              {pagaData.length === 0 && (
                <tr>
                  <td colSpan={5}>
                    <NoAssetsFound />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {pagaData && pagaData.length > 0 && (
            <div
              className="d-flex justify-content-between"
              style={{ alignItems: "center" }}
            >
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={limit}
                  onChange={(e) => {
                    setLimit(e.target.value);
                    setPage(1);
                    setPagination(false);
                  }}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                </Select>
              </FormControl>
              {pagination ? (
                <Pagination
                  count={pageCounts}
                  variant="outlined"
                  shape="rounded"
                  onChange={handleChangePage}
                />
              ) : null}
            </div>
          )}
        </div>
      </div>
      <div className="d-flex footer">
        <button
          className="btn button"
          onClick={() => {
            setTabKey("4");
          }}
        >
          Skip
        </button>
        <div>
          <button
            className="btn button"
            onClick={() => {
              setTabKey("1");
            }}
          >
            Previous
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              setTabKey("4");
            }}
          >
            Next
          </button>
        </div>
      </div>
      <CreateMaintenance
        show={maintenancePopup}
        closepopup={closeMaintenancepopup}
        data={editData}
        parentId={asstId.asstId}
      />
    </div>
  );
};

export default AssetMaintance;
