import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import {Button} from '@mui/material';
import Low from "../../../assets/fonts/Low.svg";
import High from "../../../assets/fonts/High.svg";
import axios from "../../../config/axiosConfig";
import Application from "../../../config/ApplicationWrapper";
import { toast } from "react-toastify";

export default function ActivatePopup(props) {
    const { show, closepopup, details } = props;
    const[level, setLevel] = useState("minor");

    const onhandleStatusClose = () => {
        closepopup();
    };

    const activated = async () => {
        let result = await axios.patch(`${Application.api_url}/api/v2/emergency_plans/${details.identifier}/activate`, {
            severity: level
        })

        if (result.data && result.data.success === true) {
            toast.success("Activated Successfully")
            onhandleStatusClose()
        }
    }

    return (
        <Modal
            bsSize="large"
            show={show}
            onHide={(e) => {
                onhandleStatusClose();
            }}
            className="qr-modal-wrap add-task-modal pf-model"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="pf-model-header">
                        <p className="pf-model-title"> Activate Emergency Plan </p><p className="pf-name"> - {details.name}</p>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <p className="select-type">Select Severity</p>
                    <div className="activate-types">
                        <div className={level == "minor" && "div-select"} onClick={(e)=>{setLevel("minor")}}>
                            <h3><img src={Low} alt="low"/>Minor Emergencies </h3>
                            <p>which may or may not cause damage or injuries and which are not likely to develop to a magnitude that warrants evacuation and can be controlled effectively using the resources available within the workplace.</p>
                        </div>
                        <div className={level == "major" && "div-select"} onClick={(e)=>{setLevel("major")}}>
                            <h3><img src={High} alt="High"/>Major Emergencies </h3>
                            <p>which may or may not cause damage or injuries within the plant / section of the factory but are likely to develop or have developed to a magnitude that warrants evacuation from the plant / section and can be controlled effectively using resources within the workplace.</p>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
            <div className="submit-btns">
                <Button className="btn-cancel" onClick={(e) => { onhandleStatusClose() }}>Cancel</Button>
                <Button variant="contained" onClick={(e) => { activated() }}>
                    Activate
                </Button>
            </div>
            </Modal.Footer>
        </Modal>
    );
}
