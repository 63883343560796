import React, { useEffect, useState } from "react";
import axios from "axios";
import ErrorFallback from "../../components/ErrorFallback";

const initialState = {
  total_audits: 0,
  total_auditors: 0,
  average_daily_audits: 0,
};

const AnalyticsData = ({ query }) => {
  const [summary, setSummary] = useState(initialState);
  const [loading, SetLoading] = useState(true);
  const [error, SetError] = useState(false);

  useEffect(() => {
    async function fetchData() {
      //SetLoading(true);
      try {
        let search_query = Object.entries(query).reduce(
          (a, [k, v]) => (v ? ((a[k] = v), a) : a),
          {}
        );
  
        // Simulating a delay of 3 seconds
        // await new Promise((resolve) => setTimeout(resolve, 5000));
  
        const result = await axios.get("analytics_dashboard/summary_data", {
          params: search_query,
        });
        console.log(result.data.summary);
        setSummary(result.data.summary);
      } catch (e) {
        SetError(true);
      }
    }
  
    fetchData().then((r) => {
      SetLoading(false);
      console.log(r);
      console.log(" ===== API request ====");
    });
  }, [query]);

  return (
    <div className="audit-wrap">
      <div className="row">
        <div className="col-md-4">
          <div className={loading ? "card loader" : "card"}>
            <h2>Average Daily Forms</h2>
            {!error && (
              <div className="audit-info">
                <span>
                  <i className="fa fa-calendar"></i>
                </span>
                <span id="avg_audits" className="daily-audit">
                  {summary.average_daily_audits}
                </span>
              </div>
            )}
            {error && (<ErrorFallback/>)}
          </div>
        </div>

        <div className="col-md-4">
          <div className={loading ? "card loader" : "card"}>
            <h2>All forms</h2>
            {!error && (
              <div className="audit-info">
                <span>
                  <i className="fa fa-archive"></i>
                </span>
                <span id="required_audits" className="daily-audit">
                  {summary.total_audits}
                </span>
              </div>
            )}
            {error && (<ErrorFallback/>)}
          </div>
        </div>

        <div className="col-md-4">
          <div className={loading ? "card loader" : "card"}>
            <h2>Total Auditors</h2>
            {!error && (
              <div className="audit-info">
                <span>
                  <i className="fa fa-address-card"></i>
                </span>
                <span id="total_auditors" className="daily-audit">
                  {summary.total_auditors}
                </span>
              </div>
            )}
            {error && (<ErrorFallback/>)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsData;
