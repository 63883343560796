import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Application from "../../../../config/ApplicationWrapper";
import { Modal, FormControl as Input } from "react-bootstrap";
import Cookies from "js-cookie";
import Autocomplete from "react-google-autocomplete";
import FormHelperText from "@mui/material/FormHelperText";

let initialValue = {
  address: "",
  lat: "",
  lon: "",
};

export default function CreateGeoLocationPopup(props) {
  const { show, closepopup, data, parentId } = props;
  const cookieDetails = Cookies.get("token");
  const [onSubmit, setSubmit] = useState(false);
  const [values, setValues] = useState(initialValue);

  useEffect(() => {
    if (data && data.id) {
      setValues(data);
    }
  }, [data]);

  const handleChange = (place) => {
    let address = place.formatted_address;
    let lat = place.geometry.location.lat();
    let lng = place.geometry.location.lng();
    setValues({ ...values, ...{ address: address, lat: lat, lon: lng } });
  };

  const updateGeoLocation = async () => {
    try {
      setSubmit(true);

      if (!values.address) {
        return false;
      }

      let getAssetId = localStorage.getItem("assetInfo");
      let assetId = getAssetId ? JSON.parse(getAssetId) : "";

      let newAssetId;
      if(parentId){
        newAssetId = parentId;
      } else if(assetId && assetId.asstId){
        newAssetId = assetId.asstId;
      }

      let url = `${Application.api_url}/api/v2/asset_trackings/${newAssetId}/geo_location_trackings`;
      let method = "POST";
      if (data && data.id) {
        url = `${Application.api_url}/api/v2/asset_trackings/${newAssetId}/geo_location_trackings/${data.id}`;
        method = "PUT";
      }

      const create = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: cookieDetails,
        },
        body: JSON.stringify(values),
      });
      if (create && create.status === 200) {
        setValues(initialValue);
        setSubmit(false);
        closepopup(Date.now());
      }
    } catch (err) {
      console.log("err===>", err);
    }
  };

  return (
    <Modal
      show={show}
      onHide={(e) => {
        onhandleStatusClose();
        setValues(initialValue);
      }}
      className="create-maintanance-popup"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <i className="fa fa-plus" aria-hidden="true"></i>
          <b> {data && data.id ? "Edit" : "Add"} Location </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="create-form">
          <div className="row">
            <div className="col-md-12">
              <label>
                Location <text style={{ color: "red" }}>*</text>
              </label>
              <Autocomplete
                apiKey={"AIzaSyBFtyJFE7Y6FhOOX8R7z5XnYOLyk6Gfirk"}
                style={{
                  width: "100%",
                  height: "50px",
                  border: "1px solid #D3D3D3",
                }}
                placeholder=""
                onPlaceSelected={(place) => {
                  handleChange(place);
                }}
                options={{
                  types: ["geocode", "establishment"],
                }}
                defaultValue={values.address}
              />
              <FormHelperText
                className="inputErrorMsg"
                error={!values.address && onSubmit}
              >
                {!values.address && onSubmit
                  ? "Location is required"
                  : ""}
              </FormHelperText>
              {/* <TextField
                required
                fullWidth
                className="text-field"
                id="outlined-required"
                error={!values.address && onSubmit}
                helperText={
                  !values.address && onSubmit
                    ? "Location Name is required"
                    : ""
                }
                value={values.address}
                onChange={(e) => {
                  handleChange("address", e);
                }}
              /> */}
            </div>
            <div className="col-md-12">
              <label>Latitude</label>
              <TextField
                required
                fullWidth
                className="text-field"
                id="outlined-required"
                disabled={true}
                value={values.lat}
                // onChange={(e) => {
                //   handleChange("lat", e);
                // }}
              />
            </div>
            <div className="col-md-12">
              <label>Longitude</label>
              <TextField
                required
                fullWidth
                className="text-field"
                id="outlined-required"
                disabled={true}
                value={values.lon}
                // onChange={(e) => {
                //   handleChange("lon", e);
                // }}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn button cancel-btn"
          onClick={() => {
            closepopup();
            setValues(initialValue);
          }}
        >
          Cancel
        </button>
        <button
          className="btn btn-primary"
          onClick={(event) => {
            updateGeoLocation();
            event.currentTarget.disabled = true;
          }}
        >
          {data && data.id ? "Update" : "Create"}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
