import React, {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import { withRouter, useHistory } from "react-router-dom";
import SearchIcon from "../../../../assets/fonts/Search.svg";
import _ from "lodash";
import {Button, TextField} from "@mui/material";
import axios from "../../../../config/axiosConfig";
import Application from "../../../../config/ApplicationWrapper";
import {toast} from "react-toastify";
import AccordionContent from "../AccordionContent";
import BulkEntryContent from "../BulkEntryContent";


const AddBulkWorkEntry = ({ history, userIds, cardId, groupIds, date, show, close, groups, billingTos, classifications, costCodes, lunchIntervals, timeTypes }) => {
    const [onSubmit, setSubmit] = useState();
    const [loading, setLoading] = useState(true);

    const create = async () => {
        try {
            setLoading(true);
            setSubmit(new Date());
            window.location.reload();
            toast.success('Bulk Created Work Entry successfully!')
        } catch (err) {
            console.log("err===>", err);
        }
    }

    return (
        <Modal
            bsSize="lg"
            show={show}
            onHide={(e) => {

            }}
            className="qr-modal-wrap add-task-modal pf-model Activate multi-select tt-bulk-entry"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h2>Create Bulk Work Entry</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="add-internal-subs">
                    <BulkEntryContent
                        groups={groups}
                        costCodes={costCodes}
                        timeTypes={timeTypes}
                        classifications={classifications}
                        billingTos={billingTos}
                        lunchIntervals={lunchIntervals}
                        userIds={userIds}
                        groupIds={groupIds}
                        cardId={cardId}
                        date={date}
                        onSubmit={onSubmit}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="submit-btns">
                    <Button className="btn-cancel" onClick={(e) => {
                        close()
                    }}>
                        Cancel
                    </Button>
                    <Button variant="contained" className="btn-update" onClick={(e) => {
                        create()
                    }}>
                        Apply To All
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};
export default withRouter(AddBulkWorkEntry)
