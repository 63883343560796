import React from "react";
import loader from "../assets/images/newloader.gif"; 

const MobileLoader = ({ loading }) => {
    return (
        <>
            {
                loading ?
                    <div className="new-loader mobile-view" >
                        <img src={loader} alt="" />
                    </div >
                    : ""
            }
        </>
    );
};

export default MobileLoader;