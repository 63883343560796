import React, { useContext, useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import ModalTruck from "../../../../assets/images/modal-truck.png";
import EmptyData from "../../../../assets/images/empty-assets.svg";
import AssetDefault from "../../../../assets/images/asset-default.svg";


import Typography from "@mui/material/Typography";
import { Checkbox, Icon, InputBase, OutlinedInput } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Application from "../../../../config/ApplicationWrapper";
import Cookies from "js-cookie";
import { RelatedAssetContext } from "./RelatedAssetDetails";
import { toast } from "react-toastify";
import loader from "../../../../assets/images/newloader.gif";

export default function RelatedAssetModal({ assetId, relatedAssetId }) {
    let delayTimer;
    const cookieDetails = Cookies.get("token");
    const { relatedAssetPopup, setRelatedAssetPopup, setRefresh } = useContext(RelatedAssetContext);
    const [parentAsset, setParentAsset] = useState();



    // Assets Actions
    const [assetData, setAssetData] = useState([])
    const [selectedAssets, setSelectedAssets] = useState();
    const [selectedIds, setSelectedIds] = useState([]);

    // Filters
    const [filterName, setFilterName] = useState('');
    const [loading, setLoading] = useState(true);

    // Pagination
    // const [limit, setLimit] = useState(30);

    const updateParentAsset = (resultObj) => {
        setParentAsset(resultObj);
    }

    const relatedAssets = (data, asstId) => {
        let assetDetail = data.find(data => data.id == asstId)
        console.log("relating", assetDetail)
        let relatables = assetDetail ? assetDetail.relatables : [];
        console.log("relatables", relatables)

        let selectedAssetIds = relatables.map(asset => asset.id)

        setSelectedIds([...selectedAssetIds])
        setSelectedAssets([...relatables])
    }

    const updateSelectedAssets = (asset) => {
        let assetDatas = selectedAssets || [];
        let selectedId = selectedIds || [];

        const findIndex = assetDatas.findIndex(data => data.id === asset.id)

        if (findIndex === -1) {
            assetDatas.push(asset)
            selectedId.push(asset.id)
        }
        else {
            assetDatas.splice(findIndex, 1)
            selectedId.splice(findIndex, 1)
        }

        setSelectedAssets([...assetDatas])
        setSelectedIds([...selectedId])
    }


    const urlParams = () => {
        return new URLSearchParams({
            // page: page,
            // pagination: true,
            name_cont: filterName,
            // limit: limit,
            // sort: sort,
            // direction: sortDirection
        }).toString();
    }

    const getParentAssetDetails = (asstId) => {
        try {
            let url = new URL(`${Application.api_url}/api/v2/asset_trackings/${asstId}`);

            fetch(
                url,
                {
                    method: "GET",
                    headers: { Authorization: cookieDetails },
                }
            ).then(async response => {
                let resultObj = await response.json()
                if (response.status === 200) {
                    updateParentAsset(resultObj.data)
                }
            })
        } catch (err) {
            console.log("error===>", err);
        }
    }

    const addRelatedAssets = async (asstId) => {
        try {
            let url = new URL(`${Application.api_url}/api/v2/asset_trackings/${asstId}/related_assets`);

            fetch(
                url,
                {
                    method: "POST",
                    headers: { Authorization: cookieDetails, 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        related_asset_ids: selectedIds.join(','),
                    }),
                }
            ).then(async response => {
                let resultObj = await response.json()
                if (response.status === 200) {
                    setRefresh(Math.random());
                    setRelatedAssetPopup(false);
                    toast.success('Added successfully');
                }
            })
        } catch (err) {
            console.log("error===>", err);
        }
    }

    const fetchAssetDetails = (asstId, relatedLoad) => {
        try {
            fetch(
                `${Application.api_url}/api/v2/asset_trackings?archived_true=${false}&name_cont=${filterName}`,
                {
                    method: "GET",
                    headers: { Authorization: cookieDetails },
                }
            ).then(async response => {
                let resultObj = await response.json()
                if (resultObj.success) {

                    let childAssets = [];
                    resultObj.data.filter(asset => {
                        if (asset.relatables && asset.relatables.length > 0 &&  asset.id != asstId) {
                            asset.relatables.filter(res => {
                                childAssets.push(res.id);
                            })
                        }
                    })

                    resultObj.data = resultObj.data.filter((obj) => {
                        if ((childAssets.includes(obj.id) === false && obj.relatables.length === 0 )||  ( filterName == "" && obj.id == asstId)) {
                            return obj;
                        }
                    })
                    
                    setAssetData(resultObj.data);
                    if (relatedLoad) {
                        relatedAssets(resultObj.data, asstId);
                    }
                    setLoading(false)
                }
            })
        } catch (err) {
            console.log("error===>", err);
        }
    }

    useEffect(() => {
        if (relatedAssetPopup) {
            console.log("entering", assetId)
            getParentAssetDetails(assetId)
            fetchAssetDetails(assetId, true);
        }
    }, [relatedAssetPopup]);

    useEffect(() => {
        if (relatedAssetPopup) {
            console.log("entering", assetId)
            getParentAssetDetails(assetId)
            fetchAssetDetails(assetId, false);
        }
    }, [filterName])

    const onhandleStatusClose = () => {
        clearFilterName();
        setRelatedAssetPopup(false);
        setSelectedAssets();
        setSelectedIds([]);
    };

    const clearFilterName = () => {
        if (filterName.length > 0) {
            setFilterName('')
        }
    }

    const onhandleRelatedAssets = () => {
        addRelatedAssets(assetId);
        clearFilterName();
    }

    const NoAssetsFound = () => (
        <div className="no-assets-data">
            <div className="d-flex">
                <div className="no-assets-preview">
                    <img src={EmptyData} />
                    <p className="no-assets-preview-details"><b>No  Related Asset Selected</b></p>
                </div>
            </div>
        </div>
    );

    const NoDataFound = () => (
        <div className="no-assets-search-data">
          <div className="d-flex">
            <div className="no-assets-preview">
              <img src={EmptyData} />
              <p className="no-assets-preview-details">
                <b>No Data</b>
              </p>
            </div>
          </div>
        </div>
      );

    const AssetTemplate = ({ asset, index }) => (
        <div key={index} className="d-flex asset-data">
            <div className="">
                <Checkbox
                    checked={selectedIds.includes(asset.id)}
                    style={{ transform: "scale(1.2)" }}
                    onClick={() => {
                        updateSelectedAssets(asset)
                    }}
                />
            </div>
            <div className="">
                <img src={asset.image_url || AssetDefault} alt='' className="asset-image" />
            </div>
            <div className="asset-details-container">
                <h4 className="asset-name"><b>{asset.name || "Measuring wheel"}</b></h4>
                <p className="asset-description info-light">Serial - {asset.serial}</p>
            </div>
        </div>
    );

    const SelectedAssetTemplate = ({ asset, index }) => (
        <div key={index} className="d-flex selected-asset-data">
            <div className="d-flex selected-asset-details-container">
                <div className="">
                    <img src={asset.image_url || AssetDefault} alt='' className="asset-image" />
                </div>
                <div className="selected-asset-box">
                    <h4 className="asset-name"><b>{asset.name || "Measuring wheel"}</b></h4>
                    <p className="asset-description info-light">Serial - {asset.serial}</p>
                </div>
            </div>
            <div>
                <InputAdornment position="end">
                    <i className="fa fa-trash asset-delete" onClick={() => { updateSelectedAssets(asset) }}></i>
                </InputAdornment>
            </div>
        </div>
    );

    return (
        <Modal bsSize="large" show={relatedAssetPopup} className="signature-popup related-asset-popup">
            <Modal.Header closeButton>
                <Modal.Title className="title">
                    <i className="fa fa-plus"></i>
                    <b> Add Related Assets </b>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    parentAsset &&
                    <div className="d-flex parent-info-section">
                        <div className="col-sm-2 image-container">
                            <img src={parentAsset.image_url || AssetDefault} alt='' />
                        </div>
                        <div className="col-sm-10">
                            <Typography className="info-light default-style" variant="body2" gutterBottom>
                                Parent Asset Information
                            </Typography>
                            <Typography className="d-flex info-centered default-style" gutterBottom variant="subtitle1"
                                component="div">
                                <b>{parentAsset.name}</b>
                                <span className="asset-track-label d-flex info-centered">
                                    <span className={`asset-status ${parentAsset.status}`}></span>
                                    {parentAsset.status}
                                </span>
                                <p className="asset-track-label info-light">Serial -
                                    <span style={{ color: '#080808' }}> {parentAsset.serial} </span>
                                </p>
                            </Typography>
                            <Typography variant="body2" color="text.secondary" className="info-light default-style">
                                {parentAsset.description}
                            </Typography>
                        </div>
                    </div>
                }
                <div className="d-flex asset-section">
                    <div className="col-md-5 asset-list-container">
                        <div className="header">
                            <h4><b>Assets List</b></h4>
                        </div>
                        <div className="body">
                            <div className="asset-search">
                                <FormControl sx={{ m: 0, width: '100%' }} variant="outlined">
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={'text'}
                                        inputProps={{ style: { fontSize: 15, padding: "9.5px 9px" } }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <i className="fa fa-search" style={{ fontSize: "14px", color: "#909090" }}></i>
                                            </InputAdornment>
                                        }
                                        notched={false}
                                        placeholder="Search Asset"
                                        onChange={(e) => {
                                            let searchText = e.target.value
                                            clearTimeout(delayTimer);
                                            delayTimer = setTimeout(function () {
                                                setFilterName(searchText)
                                            }, 2000)
                                        }}
                                    />
                                </FormControl>
                            </div>
                            <div className="asset-list">
                                {
                                    !loading && assetData && assetData.length > 1 && assetData.map((data, index) => {
                                        return parentAsset && data.id !== parentAsset.id ?
                                            <AssetTemplate asset={data} index={index} />
                                            : null
                                    })
                                }
                                {
                                    !loading && assetData && assetData.length == 1 && 
                                            <NoDataFound/>  
                                }
                                {
                                    loading && (
                                        <div className="new-loader">
                                            <img src={loader} alt="" />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7 selected-assets-container">
                        <div className="header-section">
                            <h4><b>Selected Related Asset</b></h4>
                            <span className="badge badge-light">
                                {selectedAssets && selectedAssets.length}
                            </span>
                        </div>
                        <div className="selected-asset-list">
                            {
                                selectedAssets && selectedAssets.length > 0 ?
                                    selectedAssets.map((data, index) => (
                                        <SelectedAssetTemplate asset={data} index={index} />
                                    ))
                                    :
                                    <NoAssetsFound />
                            }
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn button"
                    onClick={() => {
                        onhandleStatusClose();
                    }}
                >
                    Cancel
                </button>
                <button
                    className="btn btn-primary"
                    onClick={() => {
                        onhandleRelatedAssets();
                    }}
                >
                    Add
                </button>
            </Modal.Footer>
        </Modal>
    );
}
