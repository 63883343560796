import React, { useRef, useEffect, useState } from 'react';
import Select from 'react-select';
import OrderItemActionMenu from './OrderItemActionMenu';
import {approveAllAsAdmin, approveAsAdmin, orderItemSingleUpdate} from "../utils";
import RejectModal from '../popups/RejectModal';
import BackorderModal from "../popups/BackorderModal";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const OrderRequestItemTable = ({ orderItems, pickers, fetchDetails }) => {
  const [pickerData, setPickerData] = useState([]);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [rejectId, setRejectId] = useState(null);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [hoveredCheckboxIndex, setHoveredCheckboxIndex] = useState(null);
  const [checkedStates, setCheckedStates] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [selectedPickers, setSelectedPickers] = useState({});
  const [backorderItem, setBackorderItem] = useState(null);
  const [showBackorderModal, setShowBackorderModal] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);

  const tooltipRef = useRef(null);

  useEffect(() => {
    const selectData = pickers.map((picker) => ({
      label: picker.full_name,
      value: picker.identifier,
    }));
    setPickerData(selectData);
    initSelectedPickers(selectData);
    initThirdPartyCheckStates();
  }, [pickers]);

  const initSelectedPickers = (pickerData) => {
    const initialSelectedPickers = orderItems.reduce((hsh, item) => {
      hsh[item.identifier] = pickerData.filter(picker => item.requested_picker_identifiers.includes(picker.value));
      return hsh;
    }, {});
    setSelectedPickers(initialSelectedPickers);
  };

  const initThirdPartyCheckStates = () => {
    const initialCheckedStates = orderItems.reduce((hsh, item) => {
      hsh[item.identifier] = item.is_supplied_by_3rd_party;
      return hsh;
    }, {});
    setCheckedStates(initialCheckedStates);
  };

  const sortedOrderItems = orderItems.sort((a, b) => a.inventory_item.name.localeCompare(b.inventory_item.name));

  // Handle select/deselect all checkboxes
  const handleSelectAll = () => {
    const newCheckedState = !selectAll;
    setSelectAll(newCheckedState);

    const updatedCheckedStates = Object.fromEntries(
      orderItems.map((item) => [item.identifier, newCheckedState])
    );
    setCheckedStates(updatedCheckedStates);

    // Update the count of selected checkboxes
    setSelectedCount(newCheckedState ? orderItems.length : 0);
  };

  // Handle individual checkbox change
  const handleCheckboxChange = (checked, itemId) => {
    setCheckedStates((prevState) => {
      const updatedStates = { ...prevState, [itemId]: checked };
      setSelectedCount(Object.values(updatedStates).filter((isChecked) => isChecked).length);
      setSelectAll(Object.values(updatedStates).every((state) => state)); // Sync selectAll if all are checked
      return updatedStates;
    });
  };

  const handleApprove = async (_, itemId) => {
    await approveAsAdmin(itemId);
    fetchDetails();
  };

  const openRejectModal = (itemId) => {
    setShowRejectModal(true);
    setRejectId(itemId);
  };

  const closeRejectModal = () => {
    setShowRejectModal(false);
    setRejectId(null);
  };

  const openBackorderModal = (item) => {
    setShowBackorderModal(true);
    setBackorderItem(item);
  };

  const handleMouseEnter = (index) => {
    setHoveredCheckboxIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredCheckboxIndex(null);
  };

  const closeBackorderModal = () => {
    setShowBackorderModal(false);
    setBackorderItem(null);
  };
  const toggleDropdown = (itemId) => {
    setOpenDropdownId((prevId) => (prevId === itemId ? null : itemId));
  };

  const handlePickerChange = async (selectedOptions, itemId) => {
    await setSelectedPickers((prev) => ({
      ...prev,
      [itemId]: selectedOptions
    }));
    const requestedPickerIdentifiers = selectedOptions.map(item => item.value);
    await orderItemSingleUpdate(itemId, {
      requested_picker_identifiers: requestedPickerIdentifiers
    });
  };

  const handleApproveAll = async () => {
    Object.filter = (obj, predicate) =>
      Object.fromEntries(Object.entries(obj).filter(predicate));

    const item_identifiers = Object.keys(Object.filter(checkedStates, ([_, state]) => state));

    await approveAllAsAdmin({ item_identifiers })

    await fetchDetails()
  }

  return (
    <React.Fragment>
      {selectedCount > 0 && (
        <div style={{ display: 'flex', alignItems: 'center', padding: '10px', backgroundColor: '#f0f8ff', marginBottom: '10px' }}>
          <input type="checkbox" checked readOnly />
          <span style={{ marginLeft: '8px' }}>Selected Items: {selectedCount}</span>
          <button style={{ marginLeft: '10px' }} onClick={handleApproveAll} className={'btn btn-success'}>Approve All</button>
        </div>
      )}
      <table className="item-list">
        <thead>
          <tr>
            <th>
              <input
                id="select-all-order-items"
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAll}
              />
            </th>
            <th style={{ minWidth: '240px' }}>Item Name</th>
            <th>Qty Requested</th>
            <th>Qty Approved</th>
            <th style={{ width: '270px' }}>Status / Change Status</th>
            <th>Delivery Status</th>
            <th style={{ width: '270px' }}>Picker</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {sortedOrderItems.map((item, index) => (
            <tr key={item.identifier}>
              <td>
                <input
                  type="checkbox"
                  name="by-row-selected-order-item"
                  checked={!!checkedStates[item.identifier]}
                  onChange={(event) => handleCheckboxChange(event.target.checked, item.identifier)}
                />
              </td>
              <td style={{width: '140px'}}>
                <div>
                  {item.inventory_item.name}
                  {item.inventory_stock_status !== 'normal' &&
                    <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{item.inventory_stock_status === 'low_in_stock' ? 'Low in stock' : 'Out of stock'}</Tooltip>}
                    >
                        <i
                          className={"fa fa-exclamation-triangle ml-2"}
                          style={{color: item.inventory_stock_status === 'low_in_stock' ? '#ffc107' : '#dc3545'}}
                        ></i>
                    </OverlayTrigger>
                  }
                </div>
            <div style={{fontSize: '11px'}}>{item.inventory_item.serial_no}</div>
      </td>
      <td>{item.quantity}</td>
      <td>{item.place_order_quantity || '-'}</td>
      <td>
                <button className={`btn ${item.button_status === 'approve' ? 'selected-button-approve' : ''}`} style={{ backgroundColor: 'rgb(63, 185, 80)', color: '#fff' }} onClick={(event) => handleApprove(event, item.identifier)}>Approve</button>
                <button className={`btn ml-2 ${item.button_status === 'reject' ? 'selected-button-reject' : ''}`} style={{backgroundColor:'#FF3030', color:'#fff'}}  onClick={() => openRejectModal(item.identifier)}>Reject</button>
                <button className={`btn ml-2 ${item.button_status === 'backorder' ? 'selected-button-backorder' : ''}`} style={{backgroundColor:'#fc8543', color:'#fff'}} onClick={() => openBackorderModal(item)}>Backorder</button>
              </td>
              <td>
              {item.delivery_request_item && item.delivery_request_item.status_text === 'Picked' && (
                    <img src="/main-images/batch-picking.png" style={{ width: "40px" }} />
                )}
                {item.delivery_request_item && item.delivery_request_item.status_text === 'Out For Delivery' && (
                    <img src="/main-images/delivery-truck.png" style={{ width: "40px" }} />
                )}
                {item.delivery_request_item && item.delivery_request_item.status_text === 'Delivered' && (
                    <img src="/main-images/delivered.png" style={{ width: "40px" }} />
                )}
                {item.delivery_request_item && item.delivery_request_item.status_text === 'Declined By Picker' && (
                    <img src="/main-images/rejected.png" style={{ width: "40px" }} />
                )}
                {item.delivery_request_item && item.delivery_request_item.status_text === 'Picker Accepted' && (
                    <img src="/main-images/accept.png" style={{ width: "40px", marginLeft:'7px' }} />
                )}
                <span style={{marginLeft:'10px'}}>
                  {item.delivery_request_item && item.delivery_request_item.status_text || '-'}
                </span>
                
            </td>
              <td>
              <div className="row">
              <div className="col-md-2">
              <input
                      className="PrivateSwitchBase-input"
                      type="checkbox"
                      data-toggle="tooltip"
                      data-indeterminate="false"
                      aria-label="Checkbox demo"
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                      onChange={(event) => handleCheckboxChange(event.target.checked, item.identifier)}
                      checked={checkedStates[item.identifier]}
                    />
                    {hoveredCheckboxIndex === index && (
                      <div
                        ref={tooltipRef}
                        style={{
                          backgroundColor: '#333',
                          color: '#fff',
                          padding: '5px 10px',
                          borderRadius: '4px',
                          zIndex: 9999,
                          position: 'absolute',
                          width: '120px',
                          marginLeft: '-40px'
                        }}
                      >
                        Supplied by 3rd party
                      </div>
                    )}
                  
                </div>
                <div className="col-md-10">
                <Select
                  name="picker"
                  value={selectedPickers[item.identifier] || []}
                  onChange={(selectedOptions) => handlePickerChange(selectedOptions, item.identifier)}
                  options={pickerData}
                  isClearable={false}
                  isMulti
                  isDisabled={!!checkedStates[item.identifier]} // Disable Select if checkbox is checked
                />
                </div>
                </div>
              </td>
              <td>
              <OrderItemActionMenu
                  itemId={item.identifier}
                  isOpen={openDropdownId === item.identifier}
                  onToggle={toggleDropdown}
                  item={item}
                  fetchDetails={fetchDetails}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showRejectModal && <RejectModal itemId={rejectId} show={showRejectModal} close={closeRejectModal} fetchDetails={fetchDetails} />}
      {showBackorderModal && <BackorderModal item={backorderItem} show={showBackorderModal} close={closeBackorderModal} fetchDetails={fetchDetails} />}
    </React.Fragment>
  );
};

export default OrderRequestItemTable;