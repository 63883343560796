import React, { useEffect, useState, Component } from 'react';
import { User } from "pages/HomePage/Login";
import axios, { post } from "axios";

class RemainingTrialDays extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subscriptionStatus: null, // To store subscription status
        };
    }

    
    componentDidMount() {
        // Make sure to replace 'User.company.active_subscription?.external_id' with the actual way you get the subscription ID
        // debugger;
        // console.log(User);
        const subscriptionId = User.companyInfo.external_id;
        
        if (subscriptionId) {
            axios.get(`/api/v2/subscriptions/${subscriptionId}`)
                .then(response => {
                    this.setState({ subscriptionStatus: response.data.subscription.state });
                    console.log(response.data.subscription.state)
                })
                .catch(error => console.error('Error fetching subscription status:', error));
        }
    }


    render() {
        
        const { days } = this.props;
        const { subscriptionStatus } = this.state;
        
        return (
            <div style={{ color: "rgb(240, 173, 78)", backgroundColor: "rgba(240, 173, 78, 0.3)", display: "flex", alignItems: "center", padding: "11px 8px", float: "left", marginRight: "16px" }}>
                {subscriptionStatus == "trialing" ? (
                    <span>
                        <span>Welcome, you are currently in our free trial </span>
                        {/* <a style={{color: "#f0ad4e", fontWeight: "600", textDecoration: "underline", lineHeight: "41px !important"}} href="/company_profile/billing_page" className="waves-effect waves-light">Pay Now</a> */}
                    </span>
                ) : User.isPastDue == "Past Due" ? (
                    <span>
                        <span>Your subscription is past due. </span>
                        <a style={{color: "#f0ad4e", fontWeight: "600", textDecoration: "underline", lineHeight: "41px !important"}} href="/company_profile/billing_page" className="waves-effect waves-light">Pay Now</a>
                    </span>
                ) : User.isPastDue != "Past Due" && (
                    <span>
                        <span style={{ marginRight: "8px" }}> Your trial will expire in {days} days.</span>
                        {User.role === 'Admin' &&
                            (<a style={{ color: "#f0ad4e", fontWeight: "600", textDecoration: "underline", lineHeight: "41px !important" }} href="/company_profile/billing_page" className="waves-effect waves-light">Upgrade Now!</a>)}
                    </span>
                )}
            </div>
        );
    }
}

export default RemainingTrialDays
