import React from "react";
import {BrowserRouter as Router, Route,NavLink,HashRouter,Redirect,Link} from "react-router-dom";

class Header extends React.Component {

    render() {

    return (
        <div className="ff top-header">
            <ul className="nav nav-pills">
                <li role="presentation" >
                    <NavLink to="/" role="button">Home</NavLink>
                </li>
                <li role="presentation">
                    <NavLink to="/about-us" role="button">About Us</NavLink>
                </li>
                <li role="presentation">
                    <NavLink to="/contact-us" role="button">Contact Us</NavLink>
                </li>
                <li role="presentation">
                    <NavLink to="/marketing" role="button">Marketing</NavLink>
                </li>
                <li role="presentation">
                    <NavLink to="/login" role="button">Login</NavLink>
                </li>
                <li role="presentation">
                    <NavLink to="/register" role="button">SignUp</NavLink>
                </li>
            </ul>
        </div>
        )

}
}

export default Header;
