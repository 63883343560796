import React, { useEffect, useState } from "react";
import axios from "../../../config/axiosConfig";
import { NavLink } from "react-router-dom";
import { Checkbox, Button, TableContainer, Paper, Pagination, MenuItem, FormControl, Select } from '@mui/material';
import Filter from "../../../assets/images/Filter.png";
import { toast } from "react-toastify";
import loader from "../../../assets/images/newloader.gif";
import EmptyTable from "../../../assets/images/empty-assets.svg";
import ActiveIcon from "../../../assets/fonts/active.svg";
import ActivatePopup from "../Popups/Activate";
import Application from "../../../config/ApplicationWrapper";

export default function Active({ updateActiveCount, updateChanges, updateActiveRecord }) {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [pageDetails, setPageDetails] = useState([]);
    const [paginationStatus, setPaginationStatus] = useState(false);
    const [name, setSearchByName] = useState();
    const [user, setSearchByUser] = useState();
    const [group, setSearchByGroup] = useState();
    const [selected, setSelected] = useState([]);
    const [open, setOpen] = useState(false);
    const [activate, setActivate] = useState();
    const [sorting, setSorting] = useState(false);

    useEffect(() => {
        getPageDetails()
    }, [page, limit, sorting, updateActiveRecord])


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getPageDetails()
        }, 2000)

        return () => clearTimeout(delayDebounceFn)
    }, [name, user, group])

    const getPageDetails = async () => {
        try {
            window.scrollTo(0, 0);
            let resultObj = await axios.get(`${Application.api_url}/api/v2/emergency_plans`, {
                params: {
                    page: page,
                    limit: limit,
                    archived_eq: false,
                    name_cont: name,
                    notify_members_user_full_name_cont: user,
                    notify_groups_group_name_cont: group,
                    sort: sorting ? "name asc" : "name desc",
                },
            });
            if (resultObj.status == 200 && resultObj.data.success === true) {
                let details = resultObj.data;
                setPageDetails(details ? details.data : [])
                setPageCount(
                    details && details.metadata ? details.metadata.pages : 0
                );
                updateActiveCount(details && details.metadata ? details.metadata.count : 0
                )
                setPaginationStatus(true)
            }
        } catch (err) {
            console.log("error==>", err);
        }
    };

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };


    function ChipDesign({ data, type }) {
        let finalData = data.filter(Boolean);
        if (data && data.length >= 3) {
            finalData = finalData.slice(0, 2);
        }
        let objName = type === "user" ? "full_name" : "name";

        return (
            <div>
                {finalData &&
                    finalData.map((obj, index) => (
                        <span className={`badge badge-light responsible-party`}>
                            {obj && obj[objName]}
                        </span>
                    ))}
                {data && data.length >= 3 && (
                    <span className={`badge badge-light responsible-party more-groups`}>
                        + {data.length - 2} More Groups
                    </span>
                )}
            </div>
        );
    }


    const active = (data) => {
        setOpen(true)
        setActivate(data);
    }

    const onhandleClose = (data) => {
        setOpen(false);
    };

    const clearFilter = () => {
        setSearchByGroup("");
        setSearchByName("");
        setSearchByUser("");
    }

    const archive = async (id) => {
        let result = await axios.patch(`${Application.api_url}/api/v2/emergency_plans/bulk_archive`,
            {
                ids: id
            }
        );

        if (result.status == 200 && result.data.success === true) {
            toast.success("Archive Successfully")
            setSelected([])
            getPageDetails()
            updateChanges()
        }
    }

    const NoAssetsFound = () => (
        <div className="no-assets" style={{ textAlign: "center" }}>
            <img src={EmptyTable} />
            <p>
                <b>No Data</b>
            </p>
        </div>
    );

    return (
        <>
            {paginationStatus === true && (
                <div className="nestedTable pf-table">
                    <div className="fields-setting">
                        <div className="asset-active-filters">
                            Active Filters :
                            <span className="active-filters">
                                <span>Name :</span>
                                <span className="selected">{name ? name : "All"}</span>
                                <span className={name ? "clr-selected" : "clr-selected hidden" } onClick={(e) => { setSearchByName("") }}>X</span>
                            </span>
                            <span className="active-filters">
                                <span>Notify Users :</span>
                                <span className="selected">{user ? user : "All"}</span>
                                <span className={user ? "clr-selected" : "clr-selected hidden" } onClick={(e) => { setSearchByUser("") }}>X</span>
                            </span>
                            <span className="active-filters">
                                <span>Assigned Groups :</span>
                                <span className="selected">{group ? group : "All"}</span>
                                <span className={group ? "clr-selected" : "clr-selected hidden" } onClick={(e) => { setSearchByGroup("") }}>X</span>
                            </span>
                            <span className="active-filters-clear" onClick={(e) => clearFilter()}>
                                Clear All
                            </span>
                        </div>
                        {selected.length > 0 &&
                            <div className="bulk-action">
                                <Button variant="outlined" color="error" onClick={(e) => { archive(selected) }}>Archive</Button>
                            </div>
                        }
                    </div>
                    <TableContainer component={Paper}>
                        <table class="table table-striped">
                            <thead>
                                <tr style={{ height: "100px" }}>
                                    <th scope="col" className="risk-assessment-divider">

                                    </th>
                                    <th scope="col" className="risk-assessment-divider">
                                        <div
                                            className="d-flex justify-content-between align-item-center"
                                            onClick={(e) => {
                                                setSorting(!sorting);
                                            }}
                                        >
                                            Name
                                            <img src={Filter} alt="" className={sorting ? "up-arrow" : ""} />
                                        </div>
                                        <div class="input-group">
                                            <input
                                                type="text"
                                                class="form-control assessment-name"
                                                placeholder="Search"
                                                aria-label="Username"
                                                id="name"
                                                aria-describedby="basic-addon1"
                                                name="name"
                                                onChange={(e) => {
                                                    setSearchByName(e.target.value)
                                                }}
                                                value={name}
                                            />
                                            <div class="input-group-addon">
                                                <span class="input-group-text" id="basic-addon1">
                                                    <i class="fa fa-search" aria-hidden="true"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </th>
                                    <th scope="col" className="risk-assessment-divider">
                                        Description
                                    </th>
                                    <th scope="col" className="risk-assessment-divider">
                                        Notify Users
                                        <div class="input-group">
                                            <input
                                                type="text"
                                                class="form-control assessment-name"
                                                placeholder="Search"
                                                aria-label="users"
                                                id="users"
                                                aria-describedby="basic-addon1"
                                                name="users"
                                                onChange={(e) => {
                                                    setSearchByUser(e.target.value)
                                                }}
                                                value={user}
                                            />
                                            <div class="input-group-addon">
                                                <span class="input-group-text" id="basic-addon1">
                                                    <i class="fa fa-search" aria-hidden="true"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </th>
                                    <th scope="col" className="risk-assessment-divider">
                                        Assigned Groups
                                        <div class="input-group">
                                            <input
                                                type="text"
                                                class="form-control assessment-name"
                                                placeholder="Search"
                                                aria-label="group"
                                                id="group"
                                                aria-describedby="basic-addon1"
                                                name="group"
                                                onChange={(e) => {
                                                    setSearchByGroup(e.target.value)
                                                }}
                                                value={group}
                                            />
                                            <div class="input-group-addon">
                                                <span class="input-group-text" id="basic-addon1">
                                                    <i class="fa fa-search" aria-hidden="true"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </th>
                                    <th scope="col" className="table-action">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pageDetails &&
                                    pageDetails.length > 0 &&
                                    pageDetails.map((val, index) => {
                                        const isItemSelected = isSelected(val.id);
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <Checkbox
                                                        color="primary"
                                                        checked={isItemSelected}
                                                        onClick={(event) => handleClick(event, val.id)}
                                                        inputProps={{
                                                            'aria-labelledby': labelId,
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <NavLink to={`/view-plan/view/${val.id}`} className="pf-name-list">
                                                        {val.name}
                                                    </NavLink>
                                                </td>
                                                <td>
                                                    <p className="plans-desc">{val.description}</p>
                                                </td>
                                                <td>
                                                    <ChipDesign data={val.assigned_users} type="user" />
                                                </td>
                                                <td><ChipDesign data={val.assigned_groups} type="group" /></td>
                                                <td
                                                    className="template-options"
                                                    style={{ textAlign: "center" }}
                                                >
                                                    <a
                                                        aria-expanded="false"
                                                        aria-haspopup="true"
                                                        class="btn dropdown-toggle"
                                                        data-toggle="dropdown"
                                                        href="#"
                                                        id="dropdownMenuLink"
                                                        role="button"
                                                    >
                                                        <i class="fa fa-ellipsis-v"></i>
                                                    </a>
                                                    <ul aria-labelledby="dropdownMenuLink" class="dropdown-menu">
                                                        <li>
                                                            <a
                                                                class="archive_group_invites"
                                                                data-toggle="tooltip"
                                                                role="button"
                                                                title="View"
                                                            >
                                                                <div class="row">
                                                                    <div class="col-sm-3">
                                                                        <i
                                                                            class="fa fa-eye"
                                                                            style={{
                                                                                paddingTop: "5px",
                                                                                fontSize: "16px",
                                                                            }}
                                                                        ></i>
                                                                    </div>
                                                                    <div class="col-sm-9" style={{ paddingTop: "5px" }}>
                                                                        <NavLink
                                                                            to={`/view-plan/view/${val.identifier}`}
                                                                            id=""
                                                                            role="button"
                                                                            className="rat-links"
                                                                        >
                                                                            View Info
                                                                        </NavLink>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            <a
                                                                class="archive_group_invites"
                                                                data-toggle="tooltip"
                                                                title="Edit"
                                                            >
                                                                <div class="row">
                                                                    <div class="col-sm-3">
                                                                        <i
                                                                            class="fa fa-pencil-square-o"
                                                                            style={{
                                                                                paddingTop: "5px",
                                                                                fontSize: "16px",
                                                                                cursor: "pointer"
                                                                            }}
                                                                        ></i>
                                                                    </div>
                                                                    <div
                                                                        class="col-sm-9"
                                                                        style={{ paddingTop: "5px" }}
                                                                    >
                                                                        <NavLink
                                                                            to={`/edit-plan/edit/${val.identifier}`}
                                                                            id=""
                                                                            role="button"
                                                                            className="rat-links"
                                                                        >
                                                                            Edit
                                                                        </NavLink>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            <a class="archive_group_invites" href="#" title="Activate">
                                                                <div class="row">
                                                                    <div class="col-sm-3 active-icon">
                                                                        <img
                                                                            src={ActiveIcon}
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        class="col-sm-9"
                                                                        style={{ paddingTop: "5px", paddingLeft: "21px",cursor: "pointer" }}
                                                                        onClick={(e) => {
                                                                            active(val);
                                                                        }}
                                                                    >
                                                                        Activate
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            <a
                                                                class="archive_group_invites"
                                                                data-toggle="tooltip"
                                                                title="Archive"
                                                                onClick={(e) => {
                                                                    archive([val.id]);
                                                                }}
                                                            >
                                                                <div class="row">
                                                                    <div class="col-sm-3">
                                                                        <i
                                                                            class="fa fa-trash"
                                                                            style={{
                                                                                paddingTop: "5px",
                                                                                fontSize: "16px",
                                                                                color: "#FF3030",
                                                                                cursor: "pointer"
                                                                            }}
                                                                        ></i>
                                                                    </div>
                                                                    <div
                                                                        class="col-sm-9"
                                                                        style={{
                                                                            paddingTop: "5px",
                                                                            color: "#FF3030",
                                                                        }}
                                                                    >
                                                                        Archive
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                {pageDetails && pageDetails.length == 0 &&
                                    <tr>
                                        <td colspan={6}>
                                            <NoAssetsFound />
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </TableContainer>
                    <div className="page-limits">
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={limit}
                                onChange={(e) => {
                                    setPage(1);
                                    setPaginationStatus(false);
                                    setLimit(e.target.value);
                                }}
                            >
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={30}>30</MenuItem>
                            </Select>
                        </FormControl>
                        {paginationStatus && (
                            <Pagination
                                count={pageCount}
                                variant="outlined"
                                shape="rounded"
                                onChange={handleChangePage}
                            />
                        )}
                    </div>
                </div>
            )
            }
            {
                paginationStatus === false &&
                <div className="new-loader">
                    <img src={loader} alt="" />
                </div>
            }
            {activate &&
                <ActivatePopup
                    show={open}
                    closepopup={onhandleClose}
                    details={activate}
                />
            }
        </>
    );
}
