import React, { useEffect, useState } from "react";
import axios from "../../../../config/axiosConfig";
import { TableContainer, Paper, Pagination, MenuItem, FormControl, Select } from '@mui/material';
import Application from "../../../../config/ApplicationWrapper";
import Loader from "../../../../components/NewLoader";

const InternalSubTrainging = ({ id }) => {
    let userDetails = localStorage.getItem("user");
    userDetails = JSON.parse(userDetails);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(30);
    const [pageCount, setPageCount] = useState(0);
    const [pageDetails, setPageDetails] = useState([]);
    const [name, setSearchByName] = useState();
    const [assignee, setAssignee] = useState();
    const [completionDate, setCompletionDate] = useState();
    const [expirationDate, setExpirationDate] = useState();
    const [status, setStatus] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getPageDetails()
        }, 2000)

        return () => clearTimeout(delayDebounceFn)
    }, [limit])

    const getPageDetails = async () => {
        try {
            window.scrollTo(0, 0);
            setLoading(true)
            let resultObj = await axios.get(`${Application.api_url}/api/v2/internal_subs/${userDetails.company_identifier}/internal_trainings`, {
                params: {
                    page: page,
                    limit: limit,
                    topic_name_cont: name,
                    full_name_cont: assignee,
                    completed_date: completionDate,
                    expiry_date: expirationDate,
                    status_cont: status,
                },
            });
            if (resultObj.status == 200) {
                let details = resultObj.data;
                setPageDetails(details ? details.data : [])
                setPageCount(
                    details && details.metadata ? details.metadata.pages : 0
                );
                setLoading(false)
            }
        } catch (err) {
            console.log("error==>", err);
        }
    };

    const handleChangePage = (event, value) => {
        setPage(value);
        getPageDetails()
    };

    const hanldeLimitChange = (value) => {
        setPage(1);
        setLimit(value);
    }

    return (
        <> 
            <h2 className="internal_sub_web_table_title">Training</h2>
            <div className="nestedTable pf-table internal_sub_web_table">
            <TableContainer component={Paper}>
                                    <table class="table table-striped">
                                        <thead>
                                            <tr style={{ height: "100px" }}>
                                                <th scope="col" className="risk-assessment-divider">
                                                    <div className="d-flex justify-content-between align-item-center">
                                                        Title
                                                    </div>
                                                    <div class="input-group">
                                                        <input
                                                            type="text"
                                                            class="form-control assessment-name"
                                                            placeholder="Search"
                                                            aria-label="Username"
                                                            id="name"
                                                            aria-describedby="basic-addon1"
                                                            name="name"
                                                            onChange={(e) => {
                                                                setSearchByName(e.target.value)
                                                            }}
                                                            value={name}
                                                        />
                                                        <div class="input-group-addon">
                                                            <span class="input-group-text" id="basic-addon1">
                                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th scope="col" className="risk-assessment-divider">
                                                    <div className="d-flex justify-content-between align-item-center">
                                                        Assignee
                                                    </div>
                                                    <div class="input-group">
                                                        <input
                                                            type="text"
                                                            class="form-control assessment-name"
                                                            placeholder="Search"
                                                            aria-label="Username"
                                                            id="name"
                                                            aria-describedby="basic-addon1"
                                                            name="name"
                                                            onChange={(e) => {
                                                                setAssignee(e.target.value)
                                                            }}
                                                            value={assignee}
                                                        />
                                                        <div class="input-group-addon">
                                                            <span class="input-group-text" id="basic-addon1">
                                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th scope="col" className="risk-assessment-divider">
                                                    <div className="d-flex justify-content-between align-item-center">
                                                        Completion Date
                                                    </div>
                                                    <div class="input-group">
                                                        <input
                                                            type="date"
                                                            class="form-control assessment-name"
                                                            placeholder="Search"
                                                            aria-label="Username"
                                                            id="completionDate"
                                                            aria-describedby="basic-addon1"
                                                            name="completionDate"
                                                            onChange={(e) => {
                                                                setCompletionDate(e.target.value)
                                                            }}
                                                            value={completionDate}
                                                        />
                                                    </div>
                                                </th>
                                                <th scope="col" className="risk-assessment-divider">
                                                    <div className="d-flex justify-content-between align-item-center">
                                                        Expiration Date
                                                    </div>
                                                    <div class="input-group">
                                                        <input
                                                            type="date"
                                                            class="form-control assessment-name"
                                                            placeholder="Search"
                                                            aria-label="Username"
                                                            id="expirationDate"
                                                            aria-describedby="basic-addon1"
                                                            name="expirationDate"
                                                            onChange={(e) => {
                                                                setExpirationDate(e.target.value)
                                                            }}
                                                            value={expirationDate}
                                                        />
                                                    </div>
                                                </th>
                                                <th scope="col" className="risk-assessment-divider">
                                                    <div className="d-flex justify-content-between align-item-center">
                                                        Status
                                                    </div>
                                                    <div class="input-group">
                                                        <select
                                                            class="form-control risk-type"
                                                            onChange={(e) => {
                                                                setStatus(e.target.value);
                                                            }}
                                                            value={status}
                                                        >
                                                            <option value="">All</option>
                                                            <option value="active">Active</option>
                                                            <option value="expired">Expired</option>
                                                        </select>
                                                    </div>
                                                </th> 
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pageDetails &&
                                                pageDetails.length > 0 &&
                                                pageDetails.map((val, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                {val.title}
                                                            </td>
                                                            <td>
                                                                <ChipDesign data={val.training_participants} type="user" />
                                                            </td>
                                                            <td>
                                                                {val.completion_date}
                                                            </td>
                                                            <td>
                                                                {val.expiry_date}
                                                            </td>
                                                            <td>
                                                                <CheckValidate date={val.expiry_date} />
                                                            </td> 
                                                        </tr>
                                                    )
                                                })}
                                            <Loader pageDetails={pageDetails} loading={loading} />
                                        </tbody>
                                    </table>
                                </TableContainer>
                <div className="page-limits">
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={limit}
                            onChange={(e) => {
                                hanldeLimitChange(e.target.value);
                            }}
                        >
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={30}>30</MenuItem>
                        </Select>
                    </FormControl>
                    {!loading && (
                        <Pagination
                            count={pageCount}
                            page={page}
                            variant="outlined"
                            shape="rounded"
                            onChange={handleChangePage}
                        />
                    )}
                </div>
            </div>
        </>
    );
}

export default InternalSubTrainging;
