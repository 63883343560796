import React from 'react';
import { Modal } from "react-bootstrap";

  const FileUploadComponent = ({ show, closePopup, companyId, parentId, onUploadSuccess }) => {
  const handleDragOver = (e) => {
    e.preventDefault();
    const dropArea = document.getElementById('dropArea');
    dropArea.classList.add('dragover');
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const items = e.dataTransfer.items;

    getFilesDataTransferItems(items)
        .then(files => {
          $(".drag_folder_upload").show();
          $(".dropArea").hide();
          folderUploadAjax(files);
        });
  };

  function getFilesDataTransferItems(dataTransferItems) {
    function traverseFileTreePromise(item, path = "", folder) {
      return new Promise(resolve => {
        if (item.isFile) {
          item.file(file => {
            const reader = new FileReader();
            reader.onloadend = () => {
              folder.push({ file_path: reader.result, file_name: file.name, is_internal_doc: true });
              resolve(file);
            }
            reader.readAsDataURL(file)
          });
        } else if (item.isDirectory) {
          let dirReader = item.createReader();
          dirReader.readEntries(entries => {
            let entriesPromises = [];
            let sub_folder = [];
            folder.push({ folder_name: item.name, is_internal_doc: true, sub_folder: sub_folder });

            for (const entry of entries)
              entriesPromises.push(
                  traverseFileTreePromise(entry, path || "" + item.name + "/", sub_folder)
              );
            resolve(Promise.all(entriesPromises));
          });
        }
      });
    }

    let files = [];
    return new Promise((resolve, reject) => {
      let entriesPromises = [];
      for (let it of dataTransferItems)
        entriesPromises.push(
            traverseFileTreePromise(it.webkitGetAsEntry(), null, files)
        );
      Promise.all(entriesPromises).then(entries => {
        resolve(files);
      });
    });
  }

  function folderUploadAjax(drag_files) {
    const users = JSON.parse(localStorage.getItem('user'));
    const data = {
      files: drag_files,
      is_drag_folder: true,
      company_identifier: companyId,
      parent_id: parentId 
    };
    $.ajax({
      url: `/company_documents/${users.company_id}/upload`,
      type: 'PATCH',
      data: data,
      dataType: 'json',
      success: function (data, status) {
        if (status === "success") {
          closePopup(); 
          onUploadSuccess(); 
          toast.success('Files uploaded successfully');
        } else {
          toast.error("Something went wrong");
        }
      },
      error: function(xhr, status, error) {
        console.error("request failed:", error);
        toast.error("Something went wrong during the upload process.");
      }
    });
  }

  // Function to send data to API remains the same

  return (
      <Modal className="qr-modal-wrap add-task-modal pf-model docs-model" show={show} onHide={(e) => { closePopup(); }}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="pf-model-header">
              <p className="pf-model-title">Bulk Upload</p>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="dropArea" id="dropArea" onDragOver={handleDragOver} onDrop={handleDrop}>
              <div className="drag_and_drop">
                <i className="fas fa-download fa-3x"></i>
                <span>DRAG AND DROP HERE</span>
              </div>
            </div >
            <div className="span drag_folder_upload" style={{display:"none", fontWeight:"bold", fontSize:"17px", color:"green"}}>
              Upload in progress, please wait!
            </div>
          </div>
        </Modal.Body>
      </Modal >
  );
}

export default FileUploadComponent;