import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Application from "../../../../config/ApplicationWrapper";
import { Modal, FormControl as Input } from "react-bootstrap";
import Cookies from "js-cookie";
import axios from "axios";

export default function CreateAssetType(props) {
  const { show, closepopup, data, parentId } = props;
  const cookieDetails = Cookies.get("token");
  const [onSubmit, setSubmit] = useState(false);
  const [name, setName] = useState("");

  useEffect(() => {
    if (data && data.id) {
      setName(data.name);
    }
  }, [data]);

  const updateDocs = async () => {
    try {
      setSubmit(true);

      if (!name) {
        return false;
      }

      let apiUrl;
      let method;

      if (data && data.id) {
        apiUrl = `${Application.api_url}/api/v2/asset_types/${data.id}`;
        method = "PUT";
      } else {
        apiUrl = `${Application.api_url}/api/v2/asset_types`;
        method = "POST";
      }

      axios.defaults.headers.common["Authorization"] = cookieDetails;
      const result = await axios({
        method: method,
        url: apiUrl,
        data: { name },
      });

      if (result && result.status === 200) {
        setName("");
        setSubmit(false);
        closepopup(Date.now());
      }
    } catch (err) {
      console.log("err===>", err);
    }
  };

  return (
    <Modal
      show={show}
      onHide={(e) => {
        onhandleStatusClose();
      }}
      className="create-maintanance-popup"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {!data && <i className="fa fa-plus" aria-hidden="true"></i>}
          <b> {data && data.id ? "Update" : "Create"} Asset Type </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="create-form">
          <div className="row">
            <div className="col-md-12">
              <label>
                Asset Type Name <text style={{ color: "red" }}>*</text>
              </label>
              <TextField
                required
                fullWidth
                className="text-field"
                id="outlined-required"
                error={!name && onSubmit}
                helperText={
                  !name && onSubmit ? "Asset Type Name is required" : ""
                }
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn button cancel-btn"
          onClick={() => {
            closepopup();
          }}
        >
          Cancel
        </button>
        <button
          className="btn btn-primary"
          onClick={() => {
            updateDocs();
          }}
        >
          {data && data.id ? "Update" : "Create"}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
