import React, { useState } from "react";
import CEOJenny from "../../assets/images/CEO-Jenny.svg";
import Jerome from "../../assets/images/Jerome.svg";
import Savannah from "../../assets/images/Savannah.svg";
import Bessie from "../../assets/images/Bessie.svg";
import Leslie from "../../assets/images/Leslie.svg";
import Arlene from "../../assets/images/Arlene.svg";
import Marvin from "../../assets/images/Marvin.svg";
import Darrell from "../../assets/images/Darrell.svg";
import Dianne from "../../assets/images/Dianne.svg";
import Linkedin from "../../assets/images/linkedin.svg";
import Email from "../../assets/images/email.svg";
import Client from "../../../javascript/assets/images/client.png";
import Message from "../../../javascript/assets/images/message.svg";
import Star from "../../../javascript/assets/images/star.svg";

const Teams = () => {
  return (
    <div className="Team-Page">
      <div className="banner-section">
        <div className="home-container">
          <div className="banner-content">
            <div className="banner-inside-content">
              <h1>
                <b>Our Team</b>
              </h1>
              <p>
                We started this journey with one thing in mind, to build a
                software solution that would make safety operations and site
                collaboration so easy, it would change the way companies think
                and manage their job sites. We bootstrapped this company with
                our ideas and our talent.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="safety-management-section">
        <div className="home-container">
          <div className="d-flex safety-management-content">
            <div className="col-xs-12 col-md-8 col-xl-8 content-details">
              <h1>
                <b>
                  We are making safety management easier, faster, and
                  better for you!
                </b>
              </h1>
              <p>
                Since 2017, our goal has been to remove the headache of
                paperwork and help companies like yours achieve workplace safety
                goals. We are passionate about equipping companies of all sizes
                and in all industries with the tools they need to successfully
                maintain, manage, and build their workplace safety program.
                Development of the SiteDocs Safety Management Program is
                continuously informed by our in-house safety expertise team as
                well as through extensive customer collaboration.
              </p>
              <p>
                We are confident that SiteDocs will play a key role in helping
                companies improve safety and reduce injuries on site for many
                years to come, making safety compliance not only more
                achievable, but significantly more enjoyable.
              </p>
            </div>
            <div className="col-xs-12 col-md-4 col-xl-4 CEO-section">
              <img src={CEOJenny} alt="" />
              <h2 style={{ color: "#407797" }}>
                <b>Jenny Wilson</b>
              </h2>
              <h4>Chief Executive Officer</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="OurLeaders-section">
        <div className="home-container">
          <h3 style={{ color: "#407797" }}>
            <b>Meet</b>
          </h3>
          <h1>
            <b>Our Leadership</b>
          </h1>
          <div className="d-flex leaders ">
            <div className="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <div className="leader-details">
                <img src={Jerome} alt="" className="leaders-image" />
                <h4 style={{ color: "#407797" }}>
                  <b>Jerome Bell</b>
                </h4>
                <h5>Chief Product Officer</h5>
                <div className="d-flex">
                  <img src={Email} alt="" />
                  <img src={Linkedin} alt="" />
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <div className="leader-details">
                <img src={Savannah} alt="" className="leaders-image" />
                <h4 style={{ color: "#407797" }}>
                  <b>Savannah Nguyen</b>
                </h4>
                <h5>Chief Innovation Officer</h5>
                <div className="d-flex">
                  <img src={Email} alt="" />
                  <img src={Linkedin} alt="" />
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <div className="leader-details">
                {" "}
                <img src={Bessie} alt="" className="leaders-image" />
                <h4 style={{ color: "#407797" }}>
                  <b>Bessie Cooper</b>
                </h4>
                <h5>Chief Customer Officer</h5>
                <div className="d-flex">
                  <img src={Email} alt="" />
                  <img src={Linkedin} alt="" />
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <div className="leader-details">
                <img src={Leslie} alt="" className="leaders-image" />
                <h4 style={{ color: "#407797" }}>
                  <b>Leslie Alexander</b>
                </h4>
                <h5>Chief Sales Officer</h5>
                <div className="d-flex">
                  <img src={Email} alt="" />
                  <img src={Linkedin} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex leaders ">
            <div className="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <div className="leader-details">
                {" "}
                <img src={Arlene} alt="" className="leaders-image" />
                <h4 style={{ color: "#407797" }}>
                  <b> Arlene McCoy</b>
                </h4>
                <h5>Director of Operations</h5>
                <div className="d-flex">
                  <img src={Email} alt="" />
                  <img src={Linkedin} alt="" />
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <div className="leader-details">
                <img src={Marvin} alt="" className="leaders-image" />
                <h4 style={{ color: "#407797" }}>
                  <b>Marvin McKinney</b>
                </h4>
                <h5>Director of Safety & Integration</h5>
                <div className="d-flex">
                  <img src={Email} alt="" />
                  <img src={Linkedin} alt="" />
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <div className="leader-details">
                {" "}
                <img src={Darrell} alt="" className="leaders-image" />
                <h4 style={{ color: "#407797" }}>
                  <b>Darrell Steward</b>
                </h4>
                <h5>Controller</h5>
                <div className="d-flex">
                  <img src={Email} alt="" />
                  <img src={Linkedin} alt="" />
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3 col-xl-3">
              <div className="leader-details">
                <img src={Dianne} alt="" className="leaders-image" />
                <h4 style={{ color: "#407797" }}>
                  <b>Dianne Russell</b>
                </h4>
                <h5>Director of Technology</h5>
                <div className="d-flex">
                  <img src={Email} alt="" />
                  <img src={Linkedin} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="client-says">
        <div className="home-container">
          <div className="d-flex">
            <div className="col-xs-6 col-md-6 col-lg-4">
              <h1>
                <b>Our client says</b>
              </h1>
              <img src={Message} alt="" className="message-img" />
            </div>
            <div className="col-xs-6 col-md-6 col-lg-8">
              <div>
                <div className="star-rating">
                  <span>
                    <img src={Star} alt="" className="stars" />
                  </span>
                  <span>
                    <img src={Star} alt="" className="stars" />
                  </span>
                  <span>
                    <img src={Star} alt="" className="stars" />
                  </span>
                  <span>
                    <img src={Star} alt="" className="stars" />
                  </span>
                  <span>
                    <img src={Star} alt="" className="stars" />
                  </span>
                </div>
                <p className="client-feedback">
                  Instant reporting of critical incidents protects the health of
                  our staff and our bottom line. They see the doctor more
                  promptly, I can modify their work same-day, prevent strain and
                  have ZERO missed workdays in most cases. We expect to get a
                  rebate of nearly $100,000 from Partners in Injury Reduction
                  this year, in huge part due to Safetyloop.
                </p>
                <div className="client-review">
                  <div>
                    <img src={Client} alt="" className="client-img" />
                  </div>
                  <div className="client-details">
                    <h3>
                      <b>Andry Ford</b>
                    </h3>
                    <p>CEO at OZZ Eletric, Ontario.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Teams;
