import React, { useEffect, useMemo, useState } from "react";
import CustomTable, {
  ActionMenu,
  ButtonCreate,
  CustomPagination,
  HeaderItem,
  InviteButton,
} from "../../../shared/components/table";
import {
  MenuItem,
  TableCell,
  TableRow,
} from "@mui/material";
import useFetch from "../../../shared/components/userFetch";
import usePatch from "../../../shared/components/usePatch";
import { showToast } from "../../../shared/components/showNotification";
import { connect } from "react-redux";
import { setPageCount } from "../../../../reducers/common";

const InvitesPendingList = ({
  filterData,
  filterState,
  setFilterState,
  handleSort,
  pageCount
}) => {
  const {
    data: responseData,
    loading,
    error,
    refetch,
  } = useFetch("/api/v2/entity_management/global_projects/pending_invites", {
    filters: filterData,
    pagination: { page: filterState.page, limit: filterState.limit },
    sort: filterState.sort,
  });
  const { mutate, isLoading: isRestoring } = usePatch(
    "/api/v2/entity_management/global_projects/accept_group_join_invite"
  );
  const { data = [], metadata = {} } = responseData || {};
 
  useEffect(() => {
    metadata.count && setPageCount({agp: {
      assigned_global_project: pageCount.agp.assigned_global_project,
      invite_pending_accept: metadata.count || pageCount.agp.invite_pending_accept,
      invite_request: pageCount.agp.invite_request
    }})
  }, [metadata.count]);

  const callRefetch = () => {
    refetch({
      filters: filterData,
      pagination: { page: filterState.page, limit: filterState.limit },
      sort: filterState.sort,
    });
  };
  useEffect(() => {
    if (!loading) {
      refetch({
        filters: filterData,
        pagination: { page: filterState.page, limit: filterState.limit },
        sort: filterState.sort,
      });
    }
  }, [filterState]);


  // const handleRestore = (ele, setClose) => {
  //   setDeleteItem({ ...ele, setClose });
  //   setAlertOpen(true);
  // };

  const handleOk = (ele) => {
    mutate(
      { id: ele.id },
      {
        onSuccess: () => {
          showToast({ message: "Successfully joined the Global Project!" });
          callRefetch();
        },
      },
      '/api/v2/entity_management/global_projects/accept_group_join_invite'
    );
  };

  const headers = [
    <TableCell>
      <HeaderItem
        title="Company Name"
        isSort
        handleSort={() => handleSort("company_name")}
      />
    </TableCell>,
    <TableCell>
      <HeaderItem
        title="Group Name"
        isSort
        handleSort={() => handleSort("group_name")}
      />
    </TableCell>,
    <TableCell className="right" style={{ minWidth: "100px" }}>
      <HeaderItem title="Actions" />
    </TableCell>,
  ];

  const rows = useMemo(
    () =>
      data.map((ele, index) => {
        const {
          company_name,
          group_name,
        } = ele || {};
        return (
          <TableRow key={index} className={index % 2 === 0 ? "even-row" : "odd-row"}>
            <TableCell>
              {company_name}
            </TableCell>
            <TableCell>
              {group_name}
            </TableCell>
            <TableCell className="right" style={{ minWidth: '100px' }}>
              {/* <ActionMenu>
                {(setClose) => (
                    <MenuItem onClick={() => handleRestore(ele, setClose)}>
                      Restore
                    </MenuItem>
                )}
              </ActionMenu> */}
              <InviteButton
                background="#65B5E3"
                color="#FFF"
                label="Accept Invite"
                onClick={() => handleOk(ele)}
              />
            </TableCell>
          </TableRow>
        );
      }),
    [data]
  );


  return (
    <>
      <CustomTable
        headers={headers}
        rows={rows}
        isSliderScroll={false}
      />
      {!!metadata.count && (
        <CustomPagination
          totalPage={metadata.last}
          lastPage={metadata.last}
          currentPage={filterState.page}
          handlePageChange={(_, nextPage) => {
            setFilterState((prev) => ({ ...prev, page: nextPage }));
          }}
          rowOptions={[20, 50, 100]}
          rowPerPage={filterState.limit}
          handleRowPerPageChange={(targetValue) => {
            setFilterState((prev) => ({
              ...prev,
              limit: parseInt(targetValue, 10),
              page: 1,
            }));
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
      pageCount: state.CommonReducer.pageCount
  };
};
const mapDispatchToProps = (dispatch) => ({
  setPageCount: (value) => dispatch(setPageCount(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(InvitesPendingList);