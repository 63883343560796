import React from 'react'
import HeroSection from './CommonComponents/HeroSection'
import FeaturesSection from './CommonComponents/FeaturesSection'
import asset_mgmt from '../../../assets/images/asset1.png'
import asset2 from '../../../assets/images/asset2.png'
import asset3 from '../../../assets/images/asset3.png'
import ParallaxSection from '../../pages/HomePage/Components/ParallaxSection'
import HomepageDemoSection from '../../pages/HomePage/Components/HomepageDemoSection'
import HeroBannerImage from "../../assets/images/asset_management.png";

export default function AssetManagement() {
  return (
    <div className="corrective-actions-section">
      <img src={HeroBannerImage}  />
    </div>
  )
}
