import React from 'react'
import HeroSection from './CommonComponents/HeroSection'
import FeaturesSection from './CommonComponents/FeaturesSection'
import form1 from '../../../assets/images/form1.png'
import form2 from '../../../assets/images/form2.png'
import form3 from '../../../assets/images/form3.png'
import ParallaxSection from '../../pages/HomePage/Components/ParallaxSection'
import HomepageDemoSection from '../../pages/HomePage/Components/HomepageDemoSection'

export default function FormCreation() {
  return (
    <>
      <HeroSection heroText={"Effortless Form Creation"} subText={"Simplify the process of creating and customizing forms with SafetyLoop's intuitive tools. Tailor forms to your specific needs for a more streamlined safety management system."} />
      <FeaturesSection
        headerText={"Effortless Form Creation"}
        subText={"SafetyLoop empowers you to create customized forms effortlessly, tailored to your specific safety and compliance needs."}
        featureHighlights={["Initiate new forms quickly, saving time and resources",
          "Choose from a variety of form templates or start from scratch",
          "Drag-and-drop interface for easy form element customization",
          "Define form fields, questions, and response types with precision",
          "Real-time collaboration and editing with team members for form creation"]}
        sectionBackground='dark'
        sectionAlign="image-right"
        featureImageSrc={form1}
      />
      <FeaturesSection
        headerText={"View Completed Forms"}
        subText={"Access and review completed forms with ease. SafetyLoop's 'View Completed Forms' feature ensures you have full visibility into your safety data."}
        featureHighlights={["Effortlessly retrieve and view all submitted forms in one centralized location",
          "Filter and sort forms by date, location, type, or any custom criteria",
          "Generate insightful reports and analytics based on completed forms",
          "Stay compliant and informed by accessing historical safety data at your fingertips"]}
        featureImageSrc={form2}
      />
      <FeaturesSection
        headerText={"Create Reusable Form Templates"}
        subText={"Empower your organization with the ability to design and maintain reusable form templates for consistent data collection."}
        featureHighlights={["Build custom form templates tailored to your industry and requirements",
          "Include standardized questions, fields, and compliance elements",
          "Maintain a library of templates for future use across projects",
          "Edit and update templates as regulations or needs evolve",
          "Ensure consistency and uniformity in data collection across your organization"]}
        sectionBackground='dark'
        sectionAlign="image-right"
        featureImageSrc={form3}
      />
      <div className="Home-Page sl-home-wrapper" style={{ paddingTop: 0 }}>
        <ParallaxSection />
      </div>
      <div className="features-homepage-wrapper">
        <HomepageDemoSection mergeTop={false} />
      </div>
    </>
  )
}
