import React, { Component } from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

function trClassFormat(rowData, rIndex) {
    return rIndex % 2 === 0 ? 'tr-function-example' : '';
}

function columnClassNameFormat(fieldValue, row, rowIdx, colIdx) {
    // debugger;
    if(row.expiry_date=="") {
        return "td-column-function-even-example"
    } else if(row.status=="Expired") {
        return "td-column-function-odd-example";
    } else {
        switch(row.expiring_in) {
            case "expired_in_30":
                return "td-column-function-expiring-thirty";
            case "expired_in_60":
                return "td-column-function-expiring-sixty";
            default:
                return "td-column-function-even-example";
        }
    }
}

function pdfLink(rowData, row, rIndex) {
    rowData = rowData ? rowData : ''

    if (row.is_external_training){
        let pdf_url = `/record_of_trainings/${row.schedule_id}/edit?user=${row.user}&pdf=true`
        let edit_url = `/record_of_trainings/${row.schedule_id}/edit?edit=true`
        return (<div>
            <a href={pdf_url} target="_blank">PDF Link</a>
            <br />
            <a href={edit_url} target="_blank">Edit Record</a>
        </div>);

    }else{
        let sr = rowData.split("/");
        let temp = sr[0] + "/" + sr[1] + "/" + sr[2];
        return (<div>
            <a href={rowData} target="_blank">PDF Link</a>
            <br />

            <a href={temp} target="_blank">Edit Record</a>
        </div>);
    }
}

function columnExpiredFormat(fieldValue, row, rowIdx, colIdx) {
    // fieldValue is column value
    // row is whole row object
    // rowIdx is index of row
    // colIdx is index of column
    if(row) {
      // console.log(row.status);
      // if(row.status==""){
      //   row.status = "Needs Training"
      // }
      
    }
    // debugger;
    return row.status == "Expired" ? 'td-column-function-odd-example' : ''
}

function dateSortFunc(a,b,order) {
    // debugger;
    console.log(a.completion_date);
    if (order === 'asc')
     {
       return Date.parse(a.completion_date) - Date.parse(b.completion_date)
     }
    else if (order === 'desc') {
       return  Date.parse(b.completion_date) - Date.parse(a.completion_date) 
     }
    
}


class ROTTrainingData extends Component {
  onToggleDropDown = (toggleDropDown) => {
    toggleDropDown();
  }

  renderSizePerPageDropDown = (props) => {
      return (
          <SizePerPageDropDown
          open={ props.open }
          className='my-size-per-page'
          btnContextual='btn-warning'
          variation='dropup'
          onClick={ () => this.onToggleDropDown(props.toggleDropDown) }/>
       );
  }

  render_action_link = (fieldValue, row, rowIdx, colIdx) => {
      let action_txt = fieldValue ? 'UnArchive' : 'Archive'
      let participant_id = row.participant_id;
      let archive_url = `/record_of_trainings/archive?training_participant_id=${participant_id}`
      let delete_url  = `/record_of_trainings/soft_delete?training_participant_id=${participant_id}`
      let manage_permission = row.manage_permission
      // console.log(row)

      if (manage_permission){
          return (
              <div>
                  <a href={archive_url}>{action_txt}</a> / <a href={delete_url}>Delete</a>
              </div>

          );
      }else{
          return (
              <div>
                  <span>---</span>
              </div>
              )
      }
  }





  render() {
  const options = {
      sizePerPageDropDown: this.renderSizePerPageDropDown,
      sizePerPageList: [ {
          text: '5', value: 5
      }, {
          text: '10', value: 10
      }, {
          text: 'All', value: 15
      } ],
      prePage: 'Prev', // Previous page button text
      nextPage: 'Next', // Next page button text
      firstPage: 'First', // First page button text
      lastPage: 'Last', // Last page button text
      prePageTitle: 'Go to previous', // Previous page button title
      nextPageTitle: 'Go to next', // Next page button title
      firstPageTitle: 'Go to first', // First page button title
      lastPageTitle: 'Go to Last' // Last page button title
  };

  return (
          <BootstrapTable data={this.props.data} trClassName={ trClassFormat }  options={ options } pagination exportCSV>
            <TableHeaderColumn isKey dataField='id' hidden>ID </TableHeaderColumn>
            <TableHeaderColumn dataField='user' width="10%" dataSort={ true } filter={ { type: 'TextFilter'} }>Full Name</TableHeaderColumn>
            <TableHeaderColumn dataField='first_name' width="10%"  filter={ { type: 'TextFilter'} }>First Name</TableHeaderColumn>
            <TableHeaderColumn dataField='last_name' width="10%" filter={ { type: 'TextFilter'} }>Last Name</TableHeaderColumn>
            <TableHeaderColumn dataField='topic'  dataSort={ true } filter={ { type: 'TextFilter'} }>Training Topic</TableHeaderColumn>
            <TableHeaderColumn dataField='pdf_link' width="10%" dataFormat={ pdfLink }>PDF Link</TableHeaderColumn>
            
            
            <TableHeaderColumn dataField='completion_date' width="10%" dataSort={ true } sortFunc={ dateSortFunc }>Completion Date</TableHeaderColumn>


            <TableHeaderColumn dataField='expiry_date' width="10%" dataSort={ true } columnClassName={ columnExpiredFormat }>Expiration Date</TableHeaderColumn>
            <TableHeaderColumn dataField='status'  width="10%" dataSort={ true } columnClassName={ columnClassNameFormat }>Status</TableHeaderColumn>
            <TableHeaderColumn dataField='archived'  width="10%" dataFormat={ this.render_action_link }>Action</TableHeaderColumn>
          </BootstrapTable>
           );
  }
  }

export default ROTTrainingData;
