import React, { useEffect, useMemo, useState } from "react";
import ActiveFilters from "../../../shared/components/activeFilters";
import CustomTable, { ActionMenu, CustomPagination, HeaderItem, TagRender } from "../../../shared/components/table";
import {
    Autocomplete,
    Box,
    Button,
    Collapse,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
} from "@mui/material";
import useFetch from "../../../shared/components/userFetch";
import SearchIcon from "../../../../assets/fonts/Search.svg";
import { UserGroupIcons } from "../../../shared/components/userGroupIcons";
import usePatch from "../../../shared/components/usePatch";
import ViewQR from "./ViewQR";
import { showToast } from "../../../shared/components/showNotification";
import { connect } from "react-redux";
import { setPageCount } from "../../../../reducers/common";
import AlertDialog from "../../../shared/components/alretDialog";


const CompanyGroupsArchive = ({
    handleOnFilterChange,
    filterData,
    filterState,
    setFilterState,
    handleSort,
    pageCount,
    setPageCount
}) => {
    const {
        data: responseData,
        isLoading,
        error,
        refetch,
    } = useFetch(
        "/api/v2/entity_management/groups/archived",
        { filters: filterData, pagination: { page: filterState.page, limit: filterState.limit }, sort: filterState.sort }
    );
    const { data: baseData, isLoading: baseDataLoading } = useFetch('/api/v2/entity_management/groups/base_data?archived_eq=true');
    const { mutate, isLoading: isRestoring } = usePatch();
    const [viewQR, setViewQR] = useState({});
    const [selectedRows, setSelectedRows] = useState([]);
    const [deleteItem, setDeleteItem] = useState(null);
    const [alertOpen, setAlertOpen] = useState(false);
    const { data = [], metadata = {} } = responseData || {};
    const {
        admin_users = [],
        groups = [],
        types = [],
        permissions: {
            allow_restore = true
        } = {}
    } = baseData || {};

    useEffect(() => {
        metadata.count && setPageCount({cg: {
            comp_group: pageCount.cg.comp_group,
          group_arch: metadata.count || pageCount.cg.group_arch 
        }})
      }, [metadata.count]);

      const handleRestore = (ele, setClose) => {
        setDeleteItem({ ...ele, setClose });
        setAlertOpen(true);
      };

    const handleOk = () => {
        mutate({ id: deleteItem.id }, { 
            onSuccess: () => {
                showToast({ message: 'Restored Successfully' }), callRefetch()
            },
            onError: (e) => {
                showToast({ type: 'error', message: typeof e === 'string' ? e : e.errors.toString() || '' });        
              } 
        }, `/api/v2/entity_management/groups/${deleteItem.id}/restore`);
        if (alertOpen) {
            setDeleteItem(null);
            setAlertOpen(false);
          }
    }

    const callRefetch = () => {
        refetch({
            filters: filterData,
            pagination: { page: filterState.page, limit: filterState.limit },
            sort: filterState.sort,
        });
    }
    useEffect(() => {
        if (!isLoading) {
            callRefetch();
        }
    }, [filterState]);

    const groupsFilterData = [
        { label: "All", value: "All" },
        ...groups.map((e) => ({ ...e, label: e.key, id: e.value })),
    ];

    const adminFilterData = [
        { label: "All", value: "All" },
        ...admin_users.map((e) => ({ ...e, label: e.key, id: e.value })),
    ];

    const handleSelectedRows = (id) => {
        if (!selectedRows.includes(id)) {
            setSelectedRows(prev => ([...prev, id]));
        } else {
            const newValues = selectedRows.filter(e => e !== id);
            setSelectedRows(newValues);
        }
    }

    const headers = [
        <TableCell>
            <HeaderItem
                title="Group Name"
                isSort
                handleSort={() => handleSort("name")}
                filter={
                    <Autocomplete
                        size="small"
                        defaultValue="All"
                        value={
                            groupsFilterData.find(
                                (e) => e.value === filterData["id_eq"]
                            ) || "All"
                        }
                        isOptionEqualToValue={(option, value) => option.value === value}
                        disableClearable
                        onChange={(_, v) =>
                            handleOnFilterChange(v || "All", "id_eq")
                        }
                        options={groupsFilterData}
                        popupIcon={<img src={SearchIcon} />}
                        sx={{ width: "100%", background: "#fff", border: 'none' }}
                        renderOption={(props, option) => <li {...props}>{option.label}</li>}
                        renderInput={(params) => <TextField {...params} label="Search" />}
                    />
                }
            />
        </TableCell>,
        <TableCell>
            <HeaderItem
                title="Description"
            />
        </TableCell>,
        <TableCell>
            <HeaderItem
                title="Admin"
                filter={
                    <Autocomplete
                        size="small"
                        defaultValue="All"
                        value={
                            adminFilterData.find(
                                (e) => e.value === filterData["admin_id_eq"]
                            ) || "All"
                        }
                        disableClearable
                        isOptionEqualToValue={(option, value) => option.value === value}
                        onChange={(_, v) =>
                            handleOnFilterChange(v || "All", "admin_id_eq")
                        }
                        options={adminFilterData}
                        popupIcon={<img src={SearchIcon} />}
                        sx={{ width: "100%", background: "#fff", border: 'none' }}
                        renderOption={(props, option) => <li {...props}>{option.label}</li>}
                        renderInput={(params) => <TextField {...params} label="Search" />}
                    />
                }
            />
        </TableCell>,
        <TableCell>
            <HeaderItem
                title="Type"
                isSort
                handleSort={() => handleSort("type_eq")}
                filter={
                    <Autocomplete
                        size="small"
                        defaultValue="All"
                        disableClearable
                        value={filterData["type_eq"] || "All"}
                        isOptionEqualToValue={(option, value) => option.value === value}
                        onChange={(_, v) =>
                            handleOnFilterChange(v.value || "All", "type_eq")
                        }
                        options={[
                            { label: "All", value: "All" },
                            ...types.map((e) => ({ ...e, label: e.key, id: e.value })),
                        ]}
                        popupIcon={<img src={SearchIcon} />}
                        sx={{ width: "100%", background: "#fff", border: 'none' }}
                        renderOption={(props, option) => <li {...props}>{option.label}</li>}
                        renderInput={(params) => <TextField {...params} label="Search" />}
                    />
                }
            />
        </TableCell>,
        <TableCell className="mw150">
            <HeaderItem
                title="Shared Forms"
            />
        </TableCell>,
        // <TableCell className="mw150">
        //     <HeaderItem
        //         title="Company Docs"
        //     />
        // </TableCell>,
        // <TableCell className="mw150">
        //     <HeaderItem
        //         title="QR Code"
        //     />
        // </TableCell>,
        <TableCell className="right mw100">
            <HeaderItem
                title="Actions"
            />
        </TableCell>,
    ];

    const rows = data.map((ele, index) => {
        const {
            name = '',
            shared_forms_count = 0,
            company_docs_count = 0,
            description = '-',
            admin_name = '-',
            type = '-'
        } = ele || {};
        return (
            <>
                <TableRow key={index} className={index % 2 === 0 ? "even-row" : "odd-row"}>
                    <TableCell>
                        <div className="flex g10">
                            {selectedRows.includes(ele.id) ? <span style={{ rotate: '90deg', cursor: 'pointer', visibility: ele.children && ele.children.length ? 'visible' : 'hidden' }} onClick={() => handleSelectedRows(ele.id)}>{UserGroupIcons.ArrowRight}</span> : <span style={{ cursor: 'pointer', visibility: ele.children && ele.children.length ? 'visible' : 'hidden' }} onClick={() => handleSelectedRows(ele.id)}>{UserGroupIcons.ArrowRight}</span>}
                            <div className="flex flex-c">
                                <div className="elips150" title={name}>
                                    {name}
                                </div>
                            </div>
                        </div>
                    </TableCell>
                    <TableCell>{description}</TableCell>
                    <TableCell>{admin_name}</TableCell>
                    <TableCell>{type}</TableCell>
                    <TableCell className="mw150">{shared_forms_count}</TableCell>
                    {/* <TableCell className="mw150">{company_docs_count}</TableCell> */}
                    {/* <TableCell className="mw150">
                        <Button variant="text" style={{textDecoration: 'underline', textTransform: "none"}} onClick={() => setViewQR(ele)}>View QR Code</Button>
                    </TableCell> */}
                    <TableCell className="right mw100">
                        <ActionMenu>
                        {(setClose) => {
                            const menuItems = [];
                            if (allow_restore) {
                                menuItems.push(
                                    <MenuItem onClick={() => handleRestore(ele, setClose)}>Restore</MenuItem>
                                );
                            }
                            return menuItems;
                        }}
                        </ActionMenu>
                    </TableCell>
                </TableRow>
            </>
        );
    });

    return (
        <>
           <AlertDialog
                alertOpen={alertOpen}
                handleClose={() => setAlertOpen(false)}
                handleOk={handleOk}
                title="Restore Compnay Groups"
                description = 'Are You sure you want to restore this group?'
                okText="Restore"
            />
            <CustomTable
                headers={headers}
                rows={rows}
                renderActiveFilter={
                    <>
                        <ActiveFilters filterData={filterState} update={setFilterState} keyName='cg' />
                    </>
                }
            />
            {!!metadata.count && (
                <CustomPagination
                    totalPage={metadata.last}
                    currentPage={filterState.page}
                    handlePageChange={
                        (_, nextPage) => {
                            setFilterState((prev) => ({ ...prev, page: nextPage }))
                        }
                    }
                    rowOptions={[20, 50, 100]}
                    rowPerPage={filterState.limit}
                    handleRowPerPageChange={
                        (targetValue) => {
                            setFilterState((prev) => ({
                                ...prev,
                                limit: parseInt(targetValue, 10),
                                page: 1,
                            }))
                        }
                    }
                />
            )}
            {!!viewQR.id && <ViewQR onClose={() => setViewQR({})} open={!!viewQR.id} recordId={viewQR.id} />}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        pageCount: state.CommonReducer.pageCount
    };
  };
  const mapDispatchToProps = (dispatch) => ({
    setPageCount: (value) => dispatch(setPageCount(value))
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(CompanyGroupsArchive);