import React, { useEffect, useState } from "react";
import SubTabs from "../../../shared/components/subTabs";
import useFilterChange from "../../../shared/components/useFilterChange";
import GlobalProjectList from "./GlobalProjectList";
import InvitesPendingList from "./InvitesPendingList";
import InvitesRequestList from "./InvitesRequestList";
import EditGlobalProject from "./EditGlobalProject";
import Cookies from "js-cookie";
import { ButtonCreate } from "../../../shared/components/table";
import NewInviteRequest from "./NewInviteRequest";
import { connect } from "react-redux";

const subTabs = {
    agp: [
    {
      label: "Assigned Global Projects List",
      value: "assigned_global_project",
    },
    {
      label: "Invites Pending Acceptance",
      value: "invite_pending_accept",
    },
    {
      label: "Invites Requested",
      value: "invite_request",
    },
  ],
};

const GlobalProjectWrapper = ({ title, active, pageCount }) => {
  const [edit, setEdit] = useState('');
  const [inviteEdit, setInviteEdit] = useState('');
  const [create, setCreate] = useState(false);
  const [currentTab, setCurrentTab] = useState('');
  const initialState = {
    page: 1,
    limit: 20,
  };
  const {
    handleOnFilterChange,
    filterState,
    filterData,
    setFilterState,
    handleSort,
  } = useFilterChange(initialState);
  const {
    handleOnFilterChange: handleOnFilterChangeArchive,
    filterState: filterStateArchive,
    filterData: filterDataArchive,
    setFilterState: setFilterStateArchive,
    handleSort: handleSortArchive,
  } = useFilterChange(initialState);
  const {
    handleOnFilterChange: handleOnFilterRequest,
    filterState: filterStateRequest,
    filterData: filterDataRequest,
    setFilterState: setFilterStateRequest,
    handleSort: handleSortRequest,
  } = useFilterChange(initialState);
  
  useEffect(() => {
    if(Cookies.get("tab")) {
      setCurrentTab(Cookies.get("tab"));
    }
  }, [Cookies.get("tab")]);

  return (
    <>
      <SubTabs
        tabList={subTabs[active] || []}
        withTitle
        title={title}
        pageCount={pageCount.agp}
        extras={[
          <div style={{ display: "flex", gap: "10px" }}>
            <ButtonCreate
               background="#65B5E3"
               color="#FFF"
               label="New Invite"
              onClick={() => setCreate(true)}
            /> 
          </div>,
        ]}
      >
        {(activeKey) => (
          <>
            {activeKey === "assigned_global_project" && (
              <>
                <GlobalProjectList
                  handleOnFilterChange={handleOnFilterChange}
                  filterData={filterData}
                  filterState={filterState}
                  setFilterState={setFilterState}
                  handleSort={handleSort}
                  initialState={initialState}
                  setEdit={setEdit}
                />
              </>
            )}
            {activeKey === "invite_pending_accept" && (
              <>
                <InvitesPendingList
                  handleOnFilterChange={handleOnFilterChangeArchive}
                  filterData={filterDataArchive}
                  filterState={filterStateArchive}
                  setFilterState={setFilterStateArchive}
                  handleSort={handleSortArchive}
                  initialState={initialState}
                />
              </>
            )}
            { activeKey === "invite_request" && (
               <>
               <InvitesRequestList
                 handleOnFilterChange={handleOnFilterRequest}
                 filterData={filterDataRequest}
                 filterState={filterStateRequest}
                 setFilterState={setFilterStateRequest}
                 handleSort={handleSortRequest}
                 initialState={initialState}
                 setEdit={setInviteEdit}
               />
             </>
            )}
          </>
        )}
      </SubTabs>
      {create && <NewInviteRequest open={create} setOpen={setCreate} setFilterState={setFilterState} />}
      {/* {!!inviteEdit && <EditGlobalProject edit={inviteEdit} setEdit={setInviteEdit} />} */}
      {!!edit && <EditGlobalProject edit={edit} setEdit={setEdit} />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
      pageCount: state.CommonReducer.pageCount
  };
};

export default connect(mapStateToProps)(GlobalProjectWrapper);