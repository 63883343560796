import React, { useEffect, useReducer, useState } from "react";
import { withRouter } from "react-router-dom";
import DeliveryIcon from "../../../assets/fonts/Delivery.svg";
import InventoryFrameIcon from "../../../assets/fonts/InventoryFrame.svg";
import InventoryLevelOneIcon from "../../../assets/fonts/InventoryLevelOne.svg";
import InventoryLevelThreeIcon from "../../../assets/fonts/InventoryLevelThree.svg";
import InventoryLevelSixIcon from "../../../assets/fonts/InventoryLevelSix.svg";
import RequestOrderIcon from "../../../assets/fonts/RequestOrder.svg";
import Active from "./Active";
import Archive from "./Archived";
import axios from "../../../config/axiosConfig";
import Application from "../../../config/ApplicationWrapper";
import BulkImport from "./popup/BulkImport";
import { User } from "../../../pages/HomePage/Login";
import { toast } from "react-toastify";

const initialTabStatus = {
  inventoryList: true,
  archived: false,
  orderRequest: false,
  backOrder:false,
  orderInProgress: false,
  orderHistory: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE":
      state[action.tab] = true;
      return { ...state };
    default:
      return state;
  }
};

const Inventory = ({ history, openUpdateModalHandler }) => {
  const [tabs, dispatch] = useReducer(reducer, initialTabStatus);
  const [activeTab, setActiveTab] = useState(1);
  const [pageDetails, setPageDetails] = useState();
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState();
  const [settings, setSettings] = useState(false);
  const [search, setSearch] = useState({
    name_cont: "",
    inventory_type_name_cont: "",
    status_eq: "",
    supplier_name_cont: "",
    serial_no_cont: "",
    low_stock_threshold_eq: "",
    unit_price_eq: "",
    inventory_location_name_cont: "",
    expiration_date_eq: "",
    notification_alert_eq: "",
    last_order_date_eq: "",
  });

  useEffect(() => {
    try {
      axios
        .get(`${Application.api_url}/api/v2/inventories/general_info`, {
          params: {
            ...search,
          },
        })
        .then((res) => {
          let details = res.data;
          if (details && details.data) {
            setPageDetails(details.data);
          }
        })
        .catch((error) => {
          if (error.response.status == 422) {
            history.push("/");
            toast.warning('Permission denied.')
          }
          console.log("err==>", error);
        });
    } catch (err) {
      console.log("error==>", err);
    }
  }, [refresh, search]);

  const handleUpdateTab = (tab) => {
    dispatch({ type: "UPDATE", tab: tab });
  };

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const create = () => {
    history.push("/create-inventory");
  };

  const exportData = async () => {
    try {
      axios
        .get(`${Application.api_url}/api/v2/inventories/export`, {
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "inventory_export.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log("err==>", error);
        });
    } catch (err) {
      console.log("error==>", err);
    }
  };

  const openUpdatePlanModal = async () => {
    openUpdateModalHandler(true)
  }

  const searchFilter = (data) => {
    setSearch(data);
  };

  return (
    <div className="inventory">
      <div className="total-count">
        <div className="total-inventory">
          <div className="delivery">
            <img src={DeliveryIcon} alt="delivery-icon" />
            <span>Total Inventory</span>
          </div>
          <span className="total-inventory-count">
            {pageDetails && pageDetails.total_count}
          </span>
          <div className="active">
            <span className="count">
              {pageDetails && pageDetails.total_actives}
            </span>
            <span className="title">Active</span>
          </div>
          <div className="archive">
            <span className="count">
              {pageDetails && pageDetails.total_archives}
            </span>
            <span className="title">Archive</span>
          </div>
        </div>
        <div className="total-inventory-value">
          <div className="total-val">
            <span>
              <img src={InventoryFrameIcon} alt="frame-icon" />
              Total Inventory Value
            </span>
          </div>
          <span className="total-amount">
            $ {pageDetails && Number(pageDetails.total_value).toLocaleString()}
          </span>
        </div>
        <div className="current-stock">
          <div className="title">Current Stock Level</div>
          <div className="stock-level">
            <span>In-Stock</span>
            <div className="order-req">
              <img src={InventoryLevelOneIcon} alt="delivery-icon" />
              <span>{pageDetails && pageDetails.high_stock}</span>
            </div>
          </div>
          <div className="stock-level">
            <span>Low Stock</span>
            <div className="order-req">
              <img src={InventoryLevelThreeIcon} alt="delivery-icon" />
              <span>{pageDetails && pageDetails.low_stock}</span>
            </div>
          </div>
          <div className="stock-level">
            <span>Out-Of-Stock</span>
            <div className="order-req">
              <img src={InventoryLevelSixIcon} alt="delivery-icon" />
              <span>{pageDetails && pageDetails.out_of_stock}</span>
            </div>
          </div>
        </div>
        <div className="order-request">
          <span>Orders Request</span>
          <div className="order-res-count">
            <img src={RequestOrderIcon} alit="" />
            <span>{pageDetails && Math.floor(pageDetails.total_order_requests)}</span>
          </div>
        </div>
       
      </div>
      <div className="inventory-pages">
        <div className="container-new">
          <div className="header">
            <div className="title-tabs">
              <div className="title-create-btn-group">
                <h4>
                  Inventory Management
                  &nbsp;
                  <span class="badge badge-danger">{ User.currentInventoryPlan }</span>
                  <a
                    style={{fontSize:'12px', marginLeft:'5px', cursor:'pointer'}}
                    id="update-plan-btn"
                    onClick={() => openUpdatePlanModal() }
                  >
                    Upgrade Now?
                  </a>
                </h4>
                <div className="btn-col">
                  
                  <button
                    className="export-btn"
                    id="export-btn"
                    onClick={() => exportData()}
                  >
                    Export
                  </button>
                  <button
                    className="bulk-import"
                    id="bulk-import"
                    onClick={() => {
                      setShow(true);
                    }}
                  >
                    Bulk Import
                  </button>
                  <button
                    className="create"
                    id="create"
                    onClick={() => {
                      create();
                    }}
                  >
                    + Create Inventory
                  </button>
                </div>
              </div>
              <div className="tabs-container">
                <ul className="tab">
                  <li
                    onClick={() => {
                      handleUpdateTab("inventoryList");
                      handleTabClick(1);
                    }}
                    className={activeTab === 1 ? "active" : ""}
                  >
                    <span className="count">
                      Inventory List
                      <span>{pageDetails && parseInt(pageDetails.total_actives, 10)}</span>
                    </span>
                  </li>
                  <li
                    onClick={() => {
                      handleUpdateTab("archived");
                      handleTabClick(2);
                    }}
                    className={activeTab === 2 ? "active" : ""}
                  >
                    <span className="count">
                      Archived
                      <span>
                        {pageDetails && parseInt(pageDetails.total_archives, 10)}
                      </span>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={`tabs-list ${settings ? "show" : ""}`}>
            <div
              className="tab-content"
              style={{ display: activeTab === 1 ? "block" : "none" }}
            >
              {tabs.inventoryList && (
                <Active
                  refresh={refresh}
                  searching={(data) => searchFilter(data)}
                  showSettings={(data) => setSettings(data)}
                />
              )}
            </div>

            <div
              className="tab-content"
              style={{ display: activeTab === 2 ? "block" : "none" }}
            >
              {tabs.archived && <Archive />}
            </div>
          </div>
        </div>
      </div>
      {show && (
        <BulkImport
          show={show}
          close={() => {
            setShow(false);
            setRefresh(Date.now());
          }}
        />
      )}
    </div>
  );
};

export default withRouter(Inventory);
