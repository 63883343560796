import React, {useState, useEffect} from "react";
import {Button, Modal} from "react-bootstrap";
import {COUNTRIES} from "../../../constants";
import {TextField, Autocomplete} from "@mui/material";
import axios from "../../../../config/axiosConfig";
import Application from "../../../../config/ApplicationWrapper";
import {toast} from "react-toastify";
import EditIcon from "../../../../assets/fonts/edit.svg";
import loader from "../../../../assets/images/newloader.gif";
import noCard from "../../../../assets/images/Group 55952.svg"

function CreditCardForm(props) {
    const {show, handleClose, selectedPlan} = props;
    const [countries, setCountries] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [onSubmit, setOnSubmit] = useState(false);
    const [onRequest, setOnRequest] = useState(false);
    const [selectedTab, setSelectedTab] = useState('new-payment');
    const [selectedPayment, setSelectedPayment] = useState('0');
    const [cards, setCards] = useState([]);
    const [selectedCard, setSelectedCard] = useState();
    const [loading, setLoading] = useState(false);

    const [info, setInfo] = useState({
        first_name: "",
        last_name: "",
        full_number: "",
        card_type: "",
        cvv: "",
        expiration_month: "",
        expiration_year: "",
        billing_address: "",
        billing_address_2: "",
        billing_country: "",
        billing_city: "",
        billing_state: "",
        billing_zip: "",
    });

    const OPTIONAL_FIELDS = ["billing_address_2"];
    const LABEL_FIELDS = {
        first_name: "First Name on Card",
        last_name: "Last Name on Card",
        full_number: "Credit Card Number",
        card_type: "Card Type",
        cvv: "CVV",
        expiration_month: "Expiration Month",
        expiration_year: "Expiration Year",
        billing_address: "Billing Address 1",
        billing_address_2: "Billing Address 2",
        billing_country: "Billing Country",
        billing_city: "City",
        billing_state: "State",
        billing_zip: "Zip",
    };

    const isValidForm = () => {
        let isValid = true;
        for (let key in info) {
            if (!info[key] && !OPTIONAL_FIELDS.includes(key)) {
                isValid = false;
            }
        }
        return isValid;
    };

    const resetInfo = () => {
        setInfo({
            first_name: "",
            last_name: "",
            full_number: "",
            card_type: "",
            cvv: "",
            expiration_month: "",
            expiration_year: "",
            billing_address: "",
            billing_address_2: "",
            billing_country: "",
            billing_city: "",
            billing_state: "",
            billing_zip: "",
        });
    };

    const getStateList = async () => {
        let list = await axios.get(
            `${Application.api_url}/api/v2/common/state_list`
        );
        if (list.data && list.data.success == true) {
            setStateList(list.data.data);
        }
    };

    const fetchCards = async () => {
        setLoading(true)
        let list = await axios.get(
            `${Application.api_url}/api/v2/payment_methods`
        );
        if (list.data && list.data.success == true) {
            setLoading(false)
            setCards(list.data.data);
        }
        if (!list.data && list.status == 204) {
            setLoading(false)
        }
    };

    const submitForm = () => {
        console.log(info);
        setOnSubmit(true);
        if (selectedTab == 'new-payment' ? isValidForm() : true) {
            setOnRequest(true);

            let data = selectedTab == 'new-payment' ?
                {
                    credit_card_attributes: {
                    ...info,
                    },
                    external_id: selectedPlan.id
                } :
                {
                    external_id: selectedPlan.id,
                    payment_profile_id: selectedCard
                }

            axios
                .post(
                    `${Application.api_url}/api/v2/time_cards/subscribe_time_tracker_plan`,
                    data
                )
                .then((response) => {
                    if (response.data.success) {
                        toast.success("Payment successful");
                        handleClose(true);
                    }
                }).catch((error) => {
                const errors = error.response.data.message.credit_card_attributes || error.response.data.message;
                if (typeof errors === 'string') {
                    toast.error(errors);
                } else if (typeof errors === 'object') {
                    for (let key in errors) {
                        toast.error(`${LABEL_FIELDS[key]} ${errors[key]}`);
                    }
                }
            }).finally(() => {
                setOnRequest(false);
            });
        } else {
            console.log("Form is invalid");
        }
    };

    const handleTabChange = (e) => {
        setSelectedTab(e.target.value);
    };

    const handleCardChange = (e) => {
        setSelectedCard(e.target.value);
    };

    useEffect(() => {
        resetInfo();
        getStateList();
        setCountries(COUNTRIES);
    }, []);

    useEffect(() => {
        if(selectedTab == 'existing-payment') {
            fetchCards();
        }
    }, [selectedTab]);

    return (
        <div>
            <Modal
                show={show}
                bsSize="large"
                onHide={(e) => {
                    setOnSubmit(false);
                    handleClose(false);
                }}
                className="qr-modal-wrap add-task-modal signature-popup"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <i
                            className="fa fa-credit-card"
                            style={{
                                fontSize: "19px",
                                marginRight: "5px",
                            }}
                        ></i>
                        <b>Payment</b>
                        <h2>Select Payment Method</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="payment-methods">
                        <form>
                            <div className="d-flex row">
                                <div
                                    className={`col-md-6 ${selectedTab === 'new-payment' ? 'active-payment-tab' : ''}`}>
                                    <label className="">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div style={{paddingRight: '10px'}}>
                                                <input
                                                    type="radio"
                                                    name="payment-method"
                                                    value="new-payment"
                                                    checked={selectedTab === 'new-payment'}
                                                    onChange={handleTabChange}
                                                    className="d-flex align-items-center justify-content-center"
                                                />
                                            </div>
                                            <h2><strong>New Payment Method</strong></h2>
                                        </div>
                                        <p>If you want to make new payment method click on it.</p>
                                    </label>
                                </div>

                                <div
                                    className={`col-md-6 ${selectedTab === 'existing-payment' ? 'exist-payment-tab' : ''}`}>
                                    <label className="">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div style={{paddingRight: '10px'}}>
                                                <input
                                                    type="radio"
                                                    name="payment-method"
                                                    value="existing-payment"
                                                    checked={selectedTab === 'existing-payment'}
                                                    onChange={handleTabChange}
                                                    className="d-flex align-items-center justify-content-center"
                                                />
                                            </div>
                                            <h2><strong>Existing Payment Method</strong></h2>
                                        </div>
                                        <p>If you want make the previously used payment method click on it.</p>
                                    </label>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="tab-content">
                        {selectedTab === 'new-payment' && (
                            <div id="new-payment" className="">
                                <div>
                                    <h2>New Payment Method</h2>
                                    <h4>Card Details</h4>
                                    <div className="paywall__credit-card__row">
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            id="first_name"
                                            label="First Name on Card"
                                            type="text"
                                            value={info.first_name}
                                            fullWidth
                                            error={!info.first_name && onSubmit === true}
                                            helperText={
                                                !info.first_name && onSubmit === true
                                                    ? "Please enter your first name"
                                                    : ""
                                            }
                                            onChange={(e) => {
                                                setInfo({...info, first_name: e.target.value});
                                            }}
                                        />
                                        <TextField
                                            margin="dense"
                                            id="last_name"
                                            label="Last Name on Card"
                                            type="text"
                                            value={info.last_name}
                                            error={!info.last_name && onSubmit === true}
                                            helperText={
                                                !info.last_name && onSubmit === true
                                                    ? "Please enter your last name"
                                                    : ""
                                            }
                                            onChange={(e) => {
                                                setInfo({...info, last_name: e.target.value});
                                            }}
                                            fullWidth
                                        />
                                    </div>
                                    <div className="paywall__credit-card__row">
                                        <TextField
                                            margin="dense"
                                            id="card_number"
                                            label="Credit Card Number"
                                            type="text"
                                            value={info.full_number}
                                            fullWidth
                                            error={!info.full_number && onSubmit === true}
                                            helperText={
                                                !info.full_number && onSubmit === true
                                                    ? "Please enter your credit card number"
                                                    : ""
                                            }
                                            onChange={(e) => {
                                                setInfo({...info, full_number: e.target.value});
                                            }}
                                        />
                                        <Autocomplete
                                            disablePortal
                                            id="card_type"
                                            sx={{width: "100%"}}
                                            className="mt-2"
                                            options={[
                                                {label: "Visa", value: "visa"},
                                                {label: "Master Card", value: "master"},
                                            ]}
                                            getOptionLabel={(option) => option.label}
                                            onChange={(_event, value) =>
                                                setInfo({...info, card_type: value.value})
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Card Type"
                                                    error={!info.card_type && onSubmit === true}
                                                    helperText={
                                                        !info.card_type && onSubmit === true
                                                            ? "Please select your card type"
                                                            : ""
                                                    }
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="paywall__credit-card__row">
                                        <TextField
                                            margin="dense"
                                            id="cvv"
                                            label="CVV"
                                            type="number"
                                            value={info.cvv}
                                            fullWidth
                                            error={!info.cvv && onSubmit === true}
                                            helperText={
                                                !info.cvv && onSubmit === true
                                                    ? "Please enter your CVV number"
                                                    : ""
                                            }
                                            onChange={(e) => {
                                                setInfo({...info, cvv: e.target.value});
                                            }}
                                        />
                                        <div className="paywall__credit-card__row">
                                            <TextField
                                                margin="dense"
                                                id="expiration_month"
                                                label="Expiration Month"
                                                type="number"
                                                value={info.expiration_month}
                                                fullWidth
                                                error={!info.expiration_month && onSubmit === true}
                                                helperText={
                                                    !info.expiration_month && onSubmit === true
                                                        ? "Please enter your expiration month"
                                                        : ""
                                                }
                                                onChange={(e) => {
                                                    setInfo({...info, expiration_month: e.target.value});
                                                }}
                                            />
                                            <TextField
                                                margin="dense"
                                                id="expiration_year"
                                                label="Expiration Year"
                                                type="number"
                                                error={!info.expiration_year && onSubmit === true}
                                                helperText={
                                                    !info.expiration_year && onSubmit === true
                                                        ? "Please enter your expiration year"
                                                        : ""
                                                }
                                                value={info.expiration_year}
                                                fullWidth
                                                onChange={(e) => {
                                                    setInfo({...info, expiration_year: e.target.value});
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <h2>Billing Details</h2>
                                    <TextField
                                        margin="dense"
                                        id="address1"
                                        label="Billing Address 1"
                                        type="text"
                                        value={info.billing_address}
                                        fullWidth
                                        error={!info.billing_address && onSubmit === true}
                                        helperText={
                                            !info.billing_address && onSubmit === true
                                                ? "Please enter your billing address"
                                                : ""
                                        }
                                        onChange={(e) => {
                                            setInfo({...info, billing_address: e.target.value});
                                        }}
                                    />
                                    <TextField
                                        margin="dense"
                                        id="address2"
                                        label="Billing Address 2"
                                        type="text"
                                        value={info.billing_address_2}
                                        fullWidth
                                        onChange={(e) => {
                                            setInfo({...info, billing_address_2: e.target.value});
                                        }}
                                    />
                                    <div className="paywall__credit-card__row">
                                        <Autocomplete
                                            disablePortal
                                            id="country"
                                            sx={{width: "100%"}}
                                            className="mt-2"
                                            options={countries}
                                            onChange={(_event, country) => {
                                                setInfo({...info, billing_country: country.code});
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Billing Country"
                                                    error={!info.billing_country && onSubmit === true}
                                                    helperText={
                                                        !info.billing_country && onSubmit === true
                                                            ? "Please select your billing country"
                                                            : ""
                                                    }
                                                />
                                            )}
                                        />
                                        <Autocomplete
                                            disablePortal
                                            id="state"
                                            sx={{width: "100%"}}
                                            className="mt-2"
                                            options={stateList}
                                            onChange={(_event, state) =>
                                                setInfo({...info, billing_state: state.value})
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Billing State"
                                                    error={!info.billing_state && onSubmit === true}
                                                    helperText={
                                                        !info.billing_state && onSubmit === true
                                                            ? "Please select your billing state"
                                                            : ""
                                                    }
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="paywall__credit-card__row mt-1">
                                        <TextField
                                            margin="dense"
                                            id="city"
                                            label="City"
                                            type="text"
                                            value={info.billing_city}
                                            fullWidth
                                            error={!info.billing_city && onSubmit === true}
                                            helperText={
                                                !info.billing_city && onSubmit === true
                                                    ? "Please enter your city"
                                                    : ""
                                            }
                                            onChange={(e) => {
                                                setInfo({...info, billing_city: e.target.value});
                                            }}
                                        />
                                        <TextField
                                            margin="dense"
                                            id="zip"
                                            label="Zip"
                                            type="text"
                                            value={info.billing_zip}
                                            fullWidth
                                            error={!info.billing_zip && onSubmit === true}
                                            helperText={
                                                !info.billing_zip && onSubmit === true
                                                    ? "Please enter your zip code"
                                                    : ""
                                            }
                                            onChange={(e) => {
                                                setInfo({...info, billing_zip: e.target.value});
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        {selectedTab === 'existing-payment' &&
                            <div id="existing-payment" className="">
                                <div>
                                    <h2>Existing Payment Method</h2>
                                    {!loading && [].length > 0 && (
                                        <table className="table table-borderless">
                                            <thead>
                                            <th>Select Card</th>
                                            <th>Card Details</th>
                                            <th>Expiration Date</th>
                                            <th>Actions</th>
                                            </thead>
                                            <tbody>
                                            {
                                                [].map((val, index) => {
                                                    let card = val.payment_profile
                                                    return (
                                                        <tr>
                                                            <td className='d-flex'>
                                                                <div style={{marginLeft: '45%'}}>
                                                                    <form>
                                                                        <input
                                                                            type="radio"
                                                                            name="new-payment"
                                                                            value={index}
                                                                            checked={index == selectedPayment}
                                                                            onChange={(e) => {
                                                                                setSelectedCard(card.id)
                                                                                setSelectedPayment(e.target.value);
                                                                            }}
                                                                        />
                                                                    </form>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <i
                                                                        className="fa fa-credit-card"
                                                                        style={{
                                                                            fontSize: "19px",
                                                                            marginRight: "15px",
                                                                            color: 'cadetblue'
                                                                        }}
                                                                    ></i>
                                                                    {card.card_type.toUpperCase()} {card.masked_card_number}
                                                                </div>
                                                            </td>
                                                            <td>{card.expiration_month}/{card.expiration_year}</td>
                                                            <td>
                                                                <div className="">
                                                                    {/*<i*/}
                                                                    {/*    className="fa fa-pencil-square-o"*/}
                                                                    {/*    style={{*/}
                                                                    {/*        paddingTop: "5px",*/}
                                                                    {/*        fontSize: "16px",*/}
                                                                    {/*        color: 'blue'*/}
                                                                    {/*    }}*/}
                                                                    {/*></i>*/}
                                                                    <i
                                                                        className="fa fa-trash"
                                                                        style={{
                                                                            paddingTop: "5px",
                                                                            fontSize: "16px",
                                                                            color: 'red'
                                                                        }}
                                                                    ></i>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    )}
                                    { !loading && [].length === 0 && (
                                        <div className="no-payment-card p-10">
                                            <div>
                                                <img src={noCard} alt="No card" style={{paddingLeft: '20%'}}/>
                                                <h3 style={{margin: '8px 0 0 0'}}>No Existing Payment Method</h3>
                                                <p style={{marginTop: '5px'}}>Please make new payment method</p>
                                                <button onClick={() => setSelectedTab('new-payment')} className="add-new-payment-btn">
                                                    <i className="fa fa-plus"
                                                       style={{color: '#407797', fontSize: '15px', paddingRight: '8px'}}
                                                    >
                                                    </i>
                                                    <span style={{color: '#407797'}}>New Payment Method</span>
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                    {loading && (
                                        <div className="new-loader" >
                                            <img src={loader} alt="" />
                                        </div >
                                    )}
                                </div>
                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer style={{textAlign: 'end'}}>
                    <Button className="btn" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button className="btn btn-secondary" onClick={submitForm} disabled={onRequest}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default CreditCardForm;
