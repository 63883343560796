import React, { useContext, useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { AssetDetailsContext } from "./index";
import ActivateBlue from "../../../../assets/images/activate-blue.svg";
import Share from "../../../../assets/images/share.svg";
import Download from "../../../../assets/images/download.svg";
import EmptyData from "../../../../assets/images/asset-default.svg";
import Application from "../../../../config/ApplicationWrapper";
import Cookies from "js-cookie";
import { useReactToPrint } from 'react-to-print';
import { ComponentToPrint } from './ComponentToPrint';
import ShareQRPopup from "../Popups/ShareQr";


export default function AssetInfoView() {
  const { pageData } = useContext(AssetDetailsContext);
  const [statusValue, setStatus] = React.useState(pageData.status);
  const [printQR, setPrintQR] = React.useState(false);
  const [shareQrOpen, setShareQrOpen] = React.useState(false);
  const cookieDetails = Cookies.get("token");
  let qr_code = encodeURI(pageData && pageData.qr_code ? pageData.qr_code.url : "");


  useEffect(() => {
    if (pageData.status) {
      setStatus(pageData.status);
    }
  }, [pageData]);


  const download = async (details) => {
    try{
      const res = await fetch(`${Application.api_url}/api/v2/asset_trackings/${details.id}/qr_codes/download`, {
        method: "POST",
        headers: { Authorization: cookieDetails },
      });
      const resultObj = await res.json();
      var a = document.createElement("a"); //Create <a>
      a.href = "data:image/png;base64," + resultObj.image_base64; //Image Base64 Goes here
      a.download = `${details.name}-QR.png`; //File name Here
      a.click();
  } catch(err){
    console.log("error===>", err)
  }
  };


  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const closepopup = () => {
    setShareQrOpen(false)
  }

  return (
    <div className="AssetInfoView">
      <h4 className="header">Asset Information</h4>
      <div className="body">
        <div className="row">
          <div className="col-md-7">
            <div className="row">
              <div className="col-md-12">
                <label className="Asset-view-lable">Asset Name</label>
                <TextField
                  fullWidth
                  required
                  inputProps={{ className: "asset-view-text-field" }}
                  id="outlined-required"
                  value={pageData.name}
                  disabled={true}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#080808",
                    },
                  }}
                />
              </div>
              <div className="col-md-12">
                <label className="Asset-view-lable">Status</label>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  inputProps={{ className: "asset-view-text-field" }}
                  className="status-field"
                  defaultValue={statusValue}
                  fullWidth
                >
                  <FormControlLabel
                    checked={statusValue == "In Use" ? true : false}
                    disabled={statusValue == "In Use" ? false : true}
                    className="status-field"
                    value="In Use"
                    style={{ fontSize: "14px" }}
                    control={<Radio />}
                    label="In Use"
                  />
                  <FormControlLabel
                    value="Repair"
                    checked={statusValue == "Repair" ? true : false}
                    disabled={statusValue == "Repair" ? false : true}
                    control={<Radio />}
                    label="Repair"
                  />
                  <FormControlLabel
                    value="Idle"
                    checked={statusValue == "Idle" ? true : false}
                    disabled={statusValue == "Idle" ? false : true}
                    control={<Radio />}
                    label="Idle"
                  />
                </RadioGroup>
              </div>
              <div className="col-md-6">
                <label className="Asset-view-lable">Asset Type</label>
                <TextField
                  required
                  fullWidth
                  inputProps={{ className: "asset-view-text-field" }}
                  id="outlined-required"
                  value={pageData.asset_type ? pageData.asset_type.name : ""}
                  disabled={true}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#080808",
                    },
                  }}
                />
              </div>
              <div className="col-md-6">
                <label className="Asset-view-lable">Model #</label>
                <TextField
                  required
                  fullWidth
                  inputProps={{ className: "asset-view-text-field" }}
                  id="outlined-required"
                  value={pageData.model}
                  disabled={true}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#080808",
                    },
                  }}
                />
              </div>
              <div className="col-md-6">
                <label className="Asset-view-lable">Serial # </label>
                <TextField
                  required
                  fullWidth
                  inputProps={{ className: "asset-view-text-field" }}
                  id="outlined-required"
                  value={pageData.serial}
                  disabled={true}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#080808",
                    },
                  }}
                />
              </div>
              <div className="col-md-6">
                <label className="Asset-view-lable">Manufacturer</label>
                <TextField
                  required
                  fullWidth
                  inputProps={{ className: "asset-view-text-field" }}
                  id="outlined-required"
                  value={pageData.manufacturer}
                  disabled={true}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#080808",
                    },
                  }}
                />
              </div>
              <div className="col-md-6">
                <label className="Asset-view-lable">
                  Current Mileage/Hours or Kilometers
                </label>
                <TextField
                  required
                  fullWidth
                  inputProps={{ className: "asset-view-text-field" }}
                  id="outlined-required"
                  value={`${pageData.tracker_type}  ${pageData.tracked_amount}`}
                  disabled={true}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#080808",
                    },
                  }}
                />
              </div>
              <div className="col-md-6">
                <label className="Asset-view-lable">Company</label>
                <TextField
                  required
                  fullWidth
                  inputProps={{ className: "asset-view-text-field" }}
                  id="outlined-required"
                  value={pageData.company_name}
                  disabled={true}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#080808",
                    },
                  }}
                />
              </div>
              <div className="col-md-12">
                <label className="Asset-view-lable">Group</label>
                <div className="asset-group-field"> 
                  <TextField
                    required
                    fullWidth
                    inputProps={{ className: "asset-view-text-field" }}
                    id="outlined-required"
                    value={pageData.asset_groups && pageData.asset_groups.length > 0 ? pageData.asset_groups[0].group.name : ""}
                    disabled={true}
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#080808",
                      },
                    }}
                  />
                  {
                    pageData.group_archived ?
                        <span className="badge badge-light ArchivedStatus">Archived</span> : null
                  }
                </div>
              </div>
              <div className="col-md-12">
                <label className="Asset-view-lable">Responsible Party</label>
                <div className="asset-responsible-party">
                  {pageData.managers &&
                    pageData.managers.map((obj) => (
                      <span className="responsible-party">{obj.full_name}</span>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="row">
              <div className="col-md-12">
                <div className="create-information-text-area">
                  <label className="Asset-view-lable">Description</label>
                  <textarea
                    className="create-asset-textarea"
                    aria-label="empty textarea"
                    disabled={true}
                    value={pageData.description}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="create-information-text-area">
                  <label className="Asset-view-lable">Notes</label>
                  <textarea
                    className="create-asset-textarea"
                    aria-label="empty textarea"
                    disabled={true}
                    value={pageData.notes && pageData.notes != "null" ? pageData.notes : ""}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <label className="Asset-view-lable">Image</label>
                <span className="show-image">
                  <img src={pageData.image_url ? pageData.image_url : EmptyData} alt="" />
                </span>
              </div>
              <div className="col-md-12 ">
                <label className="Asset-view-lable">Asset QR Code</label>
                <span className="show-qr">
                  <img
                    src={
                      pageData && pageData.qr_code ? pageData.qr_code.url : ""
                    }
                    alt=""
                  />
                  <div className="qr-btn">
                    <button
                      className="btn"
                      onClick={(e) => {
                        setPrintQR(true)
                        handlePrint();
                      }}
                    >
                      <img src={ActivateBlue} style={{width: "auto", padding: 0}}/>
                      <span className="btn-text">print</span>
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={(e) => {
                        download(pageData);
                      }}
                    >
                      <img src={Download} style={{width: "auto", padding: 0}}/>
                      <span className="btn-text">download</span>
                    </button>

                    <button
                      className="btn btn-secondary"
                      onClick={()=>{setShareQrOpen(true)}}
                      // onClick={() =>
                      //   (window.location = `mailto:?subject=QR-${pageData.name}-${pageData.serial}&body=${qr_code}`)
                      // }
                    >
                       <img src={Share} style={{width: "auto", padding: 0}}/>
                      <span className="btn-text">share</span>
                    </button>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
        <ComponentToPrint ref={componentRef} pageData={pageData} printQR={printQR}/>
        <ShareQRPopup show={shareQrOpen} closepopup={closepopup} data={pageData}/>
      </div>
    </div>
  );
}
