import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import Cookies from "js-cookie";
import Application from "../../../../config/ApplicationWrapper";
const cookieDetails = Cookies.get("token");

export default function Controls(props) {
  const { show, closepopup, hazardId, type } = props;
  const [name, setName] = React.useState("");
  const onhandleStatusClose = () => {
    setName("");
    closepopup();
  };

  useEffect(()=>{
    setName("")
  },[])

  const saveControls = async () => {
    try {
      let ratsData = await fetch(
        `${Application.api_url}/api/v2/risk_assesments/controls`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: cookieDetails,
          },
          body: JSON.stringify({
            name: name,
            control_type: type,
            hazard_id: hazardId,
          }),
        }
      );
      ratsData = await ratsData.json();
      setName("");
      closepopup(ratsData);
    } catch (err) {
      console.log("error==>", err);
    }
  };

  return (
    <Modal
      show={show}
      onHide={(e) => {
        onhandleStatusClose();
      }}
      centered={true}
      className="add-task-modal signature-popup"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <b>{type == "Link" ? "Enter Link" : "Enter Control"}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <textarea
          id="controler"
          name="controler"
          style={{ width: "100%", padding: "5px" }}
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          rows="10"
        ></textarea>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn-secondary"
          onClick={(e) => {
            onhandleStatusClose();
          }}
        >
          Cancel
        </Button>
        <Button
          className="btn-primary"
          onClick={(e) => {
            saveControls();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
