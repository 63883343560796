import React from "react";
import Highcharts from 'highcharts';
import Chart from "../../components/Chart";
import useFormsGroupByData from "../../hooks/useFormsGroupByData";
import ErrorFallback from "../../components/ErrorFallback";
import RecordsNotFound from "../../components/RecordsNotFound";
import {colorsList, currentYear, dataNotFound, generateUsersListsOptions} from "../../utils/util";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import Select from "react-select";
exportData(Highcharts);
const initialState = { year: currentYear, frequency: 'month', month: '', user: '' }

const FormsGroupByChart = ({  filters  }) => {
    const { stats, loading, error } = useFormsGroupByData(filters);
    const options = {
        chart: {
            type: 'column'
        },
        title: {
            text: ''
        },
        colors: colorsList,
        xAxis: {
            categories: stats.categories
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Total forms completed'
            },
            stackLabels: {
                enabled: true,
                style: {
                    fontWeight: 'bold',
                    color: ( // theme
                        Highcharts.defaultOptions.title.style &&
                        Highcharts.defaultOptions.title.style.color
                    ) || 'gray'
                }
            }
        },
        legend: {
            reversed: true
        },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
        },
        plotOptions: {
            series: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true,
                    formatter:function() {
                        if(this.y != 0) {
                            return this.y;
                        }
                    }
                }
            }
        },
        series: stats.series,
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: [
                        "viewFullscreen", "printChart", "separator", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG",
                        "separator", "downloadCSV", "downloadXLS"
                    ]
                },
            },
            enabled: true,
        }
    }
    const record_not_found = dataNotFound(stats.series)

    return(
        <div className="chart-wrap">
            <div className={loading ? "loader" : ""}>
                {loading && (
                    <div className="loading-block">
                        <h4></h4>
                    </div>
                )}
                {!error && !record_not_found && (
                    <Chart options={options} type={'chart'} highcharts={Highcharts} />
                )}
                {error && (<ErrorFallback/>)}
                {!loading && record_not_found == true && (<RecordsNotFound/>)}
            </div>
        </div>
    )
}

export default FormsGroupByChart;