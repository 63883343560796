import React, { useState } from "react";
import FormsGroupByFilters from "./FormsGroupByFilters";
import FormsGroupByChart from "./FormsGroupByChart";
import { saveWidgets } from "../../utils/util";
import Cookies from "js-cookie";
const logo = require("../../assets/images/logo_safety.png");

const FormsGroupByPanel = ({ formFilters, item, OnDuplicateChart, index }) => {
    const [filters, setFilters] = useState(item);

    const onFilterSelect = (fields) => {
        setFilters(fields);
    };

    const onFilterReset = (fields) => {
        setFilters(fields);
    };

    const OnDuplicateChartClick = () => {
        let user_cookie = Cookies.get("username");

        if (user_cookie != undefined) {
            let cookie_name = `${user_cookie}_widgets`;
            let data = Cookies.get(cookie_name);

            if (data != undefined) {
                let widgets = JSON.parse(data);
                widgets.push(filters);
                Cookies.set(cookie_name, JSON.stringify(widgets));
                OnDuplicateChart(widgets);
            }
        }
    };

    const printPageArea = (event, index) => {
        let printContents, popupWin, is_chrome;
        printContents = $(event.currentTarget.closest('.forms-group-panel')).html();
        popupWin = window.open("", "_blank", "top=0,left=0,height=100%,width=auto");
        is_chrome = Boolean(popupWin.chrome);
        popupWin.document.open();
        popupWin.document.write("<html>");
        popupWin.document.write(
            "<style>img { -webkit-print-color-adjust: exact; } .row { display: flex } .btn-primary {display:none} .card.forms-group-panel {padding: 20px; width: 90%} .forms-group-filter .row {width: 100%} .forms-group-filter .row > div {flex: 0 0 auto; width: 33%; padding-right: 20px} .control-label {display: block; margin-bottom: 10px} .forms-group-filter {margin: 15px 0 35px;} .Select-input, input {display: none}  .Select-control {border: 1px solid #000; padding: 5px 7px} .print-logo-wrap { width: 100%; display: flex; align-items: center; padding-bottom: 15px; margin-bottom: 15px; border-bottom: 1px solid #000 } .print-logo { display: block; width: 200px; margin-right: 25px }  </style>    </head>"
        );
        popupWin.document.write("<body>");
        popupWin.document.write(printContents);
        popupWin.document.write("</body></html>");

        if (is_chrome) {
            setTimeout(function () {
                // wait until all resources loaded
                popupWin.document.close(); // necessary for IE >= 10
                popupWin.focus(); // necessary for IE >= 10
                popupWin.print(); // change window to winPrint
                popupWin.close(); // change window to winPrint
            }, 100);
        } else {
            popupWin.document.close(); // necessary for IE >= 10
            popupWin.focus(); // necessary for IE >= 10
            popupWin.print();
            popupWin.close();
        }
        return true;
    };

    return (
        <div className="card forms-group-panel" identifier={index}>
            <div className="row">
                <div className="col-md-4 print-logo-wrap">
                    <img className="print-logo" src={logo} />
                    {/* <h1>Forms Group By Data</h1> */}
                </div>
                <div className="col-md-3">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={OnDuplicateChartClick}
                    >
                        Save Widget
                    </button>
                </div>
                <div className="col-md-5">
                    <i
                        style={{ fontSize: "20px", color: "#407797", float: "right" }}
                        className="fa fa-print"
                        id="print-page"
                        aria-hidden="true"
                        onClick={(e) => printPageArea(e, index)}
                    ></i>
                </div>
            </div>
            <FormsGroupByFilters
                formFilters={formFilters}
                filters={filters}
                onFilterSelect={onFilterSelect}
                onFilterReset={onFilterReset}
            />
            <FormsGroupByChart filters={filters} />
        </div>
    );
};

export default FormsGroupByPanel;
