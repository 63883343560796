import React from 'react';
import { Route, Router, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import cx from 'classnames';
import { setMobileNavVisibility } from '../../reducers/Layout';
import { withRouter } from 'react-router-dom';
import '../../assets/styles/themes.scss';
import PropTypes from 'prop-types';
import UserManagement from '../shared/UserManagement';
import {Time_zones_list} from '../shared/TimeZoneList';

import axios, { post } from 'axios';

const site_key = '6Ldqdk0UAAAAAEPyCg3XwNbIrBuFWY_J6XvwEolS'

class NewCompanyProfile extends React.Component {
    constructor(props) {
    super(props);
    const initialState = {
        fields: {name:'',industry:'',state:'',city:'',province:'', address:''},
        errors: {},
        timezone: ''
    };
    this.state = initialState

    // This binding is necessary to make `this` work in the callback
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    // this.executeCaptcha = this.executeCaptcha.bind(this);
    this.resetfilter = this.resetfilter.bind(this);
}

    // executeCaptcha(){
    // if(this.handleValidation()){
    //     recaptchaInstance.execute();
    // }else{
    //     console.log("Form has errors.")
    // }
// }


    handleValidation(){
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if(!fields["name"]){
        formIsValid = false;
        errors["name"] = "Required";
    }

    if(!fields["industry"]){
        formIsValid = false;
        errors["industry"] = "Required";
    }

    if(!fields["state"]){
        formIsValid = false;
        errors["state"] = "Required";
    }

    if(!fields["city"]){
        formIsValid = false;
        errors["city"] = "Required";
    }

    if(!fields["address"]){
        formIsValid = false;
        errors["address"] = "Required";
    }

    this.setState({errors: errors});
    return formIsValid;
}
//     componentDidMount() {
//         // Create a script to make sure the reCAPTCHA API is called.
//         const { grecaptcha } = window;
//         grecaptcha.render('recaptcha', {
// //            sitekey  : '6LcPhE0UAAAAAHC7exOMnQCTBA-2R8I3Z2j-tfoI', // localhost site key
//             sitekey  : site_key //qa.msmmecore recaptcha site key
// //            callback : this.onSubmit
//         });
//     }

    onRecaptcha = (e) => {
        e.preventDefault();
        if(this.handleValidation()){
            var googleResponse = $('#h-recaptcha-response').val();
            if(googleResponse=='')
            {
                $("#recpatcha-message").html("<span>Please check reCaptcha to continue.</span>");
                return false;
            }else{
                this.handleSubmit(e)
            }
        }else{
            console.log("Form has errors.")
        }

}

    handleChange(field, e){
        let fields = this.state.fields;
        let value = e.target.value;
        fields[field] = value
        let text = /^[A-Za-z]+$/;
        if(field == "city"){
            if (value === "" || text.test(value))
                this.setState({fields});
        }else {
            this.setState({fields});
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        let errors = {};
        var that = this;
        if(this.handleValidation()){
            const url = '/companies';
            var company = {
                name: this.state.fields['name'],
                industry: this.state.fields['industry'],
                state: this.state.fields['state'],
                city: this.state.fields['city'],
                province: this.state.fields['province'],
                address: this.state.fields['address'],
                timezone: this.state.fields['timezone']
            }
            post(url, company)
                .then(function(response) {
                    $('#new_user').append('<span class="new_user_message">Company has been successfully created.</span>')
                    $('.new_user_message').fadeOut(15000);
                })
                .catch(function(error) {
                    errors["invalid_user"] = "Something went wrong";
                    that.setState({errors: errors});
                    console.log(error);
                });
        }else{
            console.log("Form has errors.")
        }
}

    resetfilter(){
    let fields = this.state.fields;
    fields = {name:'',industry:'',state:'',city:'',province:'',address:''}
    this.setState({fields});
    }

render() {
    const renderedZones = Time_zones_list.map(zone => {
        return (<option key={zone["id"]} value={zone["value"]}>{zone["label"]}</option>);
    });
    return (
        <div className="content">
            <div className="sub-navbar sub-navbar__header-breadcrumbs">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 sub-navbar-column">
                            <div className="sub-navbar-header"></div>
                            <ol className="breadcrumb navbar-text navbar-right no-bg"></ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sub-navbar sub-navbar__header-breadcrumbs">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 sub-navbar-column">
                            <ol className="breadcrumb navbar-text navbar-left no-bg">
                                <li className="current-parent">
                                    <NavLink id="home-link-icon" to="/" className="current-parent" role="button"><i className="fa fa-fw fa-home"></i></NavLink>
                                </li>
                                <li className="active">
                                    <NavLink id="dashboard-link" to="/" className="current-parent" role="button">Dashboard</NavLink>
                                </li>
                                <li className="active">New Company</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <UserManagement source= "new-company" />
                    <div className="col-lg-10 admin-content">
                        <div className="panel panel-default   b-a-2 no-bg b-gray-dark">
                            <div className="panel-heading">
                                <h4 className="panel-title">New Profile</h4>
                            </div>

                            <div className="panel-body">
                                <div id="new_user"/>
                                <span style={{color: "red"}}>{this.state.errors["invalid_user"]}</span>
                                    <form className="form-horizontal">
                                        <div className="form-group">
                                            <label className="col-sm-3 control-label">Company Name<span className="text-danger">*</span></label>
                                            <div className="col-sm-6">
                                                <input className="form-control" ref="name" id="name" maxLength="50" placeholder="Enter your Company Name" onChange={this.handleChange.bind(this, "name")} value={this.state.fields["name"]}/>
                                                <span style={{color: "red"}}>{this.state.errors["name"]}</span>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label className="col-sm-3 control-label">Industry<span className="text-danger">*</span></label>
                                            <div className="col-sm-6">
                                                <input className="form-control" ref="industry" id="industry" maxLength="50" placeholder="Enter your Industry" onChange={this.handleChange.bind(this, "industry")} value={this.state.fields["industry"]}/>
                                                <span style={{color: "red"}}>{this.state.errors["industry"]}</span>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label className="col-sm-3 control-label">State/Province<span className="text-danger">*</span></label>
                                            <div className="col-sm-6">
                                                <select className="form-control" id="state" value={this.state.fields["state"]} onChange={this.handleChange.bind(this,"state")}>
                                                    <option value=''>Please Select State/Province</option>
                                                    <option value="AB">Alberta</option>
                                                    <option value="BC">British Columbia</option>
                                                    <option value="MB">Manitoba</option>
                                                    <option value="NB">New Brunswick</option>
                                                    <option value="NL">Newfoundland and Labrador</option>
                                                    <option value="NS">Nova Scotia</option>
                                                    <option value="ON">Ontario</option>
                                                    <option value="PE">Prince Edward Island</option>
                                                    <option value="QC">Quebec</option>
                                                    <option value="SK">Saskatchewan</option>
                                                    <option value="NT">Northwest Territories</option>
                                                    <option value="NU">Nunavut</option>
                                                    <option value="YT">Yukon</option>
                                                    <option value="AL">AL</option>
                                                    <option value="AK">AK</option>
                                                    <option value="AR">AR</option>
                                                    <option value="AZ">AZ</option>
                                                    <option value="CA">CA</option>
                                                    <option value="CO">CO</option>
                                                    <option value="CT">CT</option>
                                                    <option value="DC">DC</option>
                                                    <option value="DE">DE</option>
                                                    <option value="FL">FL</option>
                                                    <option value="GA">GA</option>
                                                    <option value="HI">HI</option>
                                                    <option value="IA">IA</option>
                                                    <option value="ID">ID</option>
                                                    <option value="IL">IL</option>
                                                    <option value="IN">IN</option>
                                                    <option value="KS">KS</option>
                                                    <option value="KY">KY</option>
                                                    <option value="LA">LA</option>
                                                    <option value="MA">MA</option>
                                                    <option value="MD">MD</option>
                                                    <option value="ME">ME</option>
                                                    <option value="MI">MI</option>
                                                    <option value="MN">MN</option>
                                                    <option value="MO">MO</option>
                                                    <option value="MS">MS</option>
                                                    <option value="MT">MT</option>
                                                    <option value="NC">NC</option>
                                                    <option value="NE">NE</option>
                                                    <option value="NH">NH</option>
                                                    <option value="NJ">NJ</option>
                                                    <option value="NM">NM</option>
                                                    <option value="NV">NV</option>
                                                    <option value="NY">NY</option>
                                                    <option value="ND">ND</option>
                                                    <option value="OH">OH</option>
                                                    <option value="OK">OK</option>
                                                    <option value="OR">OR</option>
                                                    <option value="PA">PA</option>
                                                    <option value="RI">RI</option>
                                                    <option value="SC">SC</option>
                                                    <option value="SD">SD</option>
                                                    <option value="TN">TN</option>
                                                    <option value="TX">TX</option>
                                                    <option value="UT">UT</option>
                                                    <option value="VT">VT</option>
                                                    <option value="VA">VA</option>
                                                    <option value="WA">WA</option>
                                                    <option value="WI">WI</option>
                                                    <option value="WV">WV</option>
                                                    <option value="WY">WY</option>
                                                </select>
                                                <span style={{color: "red"}}>{this.state.errors["state"]}</span>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label className="col-sm-3 control-label">Company Time zone<span className="text-danger">*</span></label>
                                            <div className="col-sm-6">
                                                <select className="form-control" id="timezone" value={this.state.fields["timezone"]} onChange={this.handleChange.bind(this,"timezone")}>
                                                    {renderedZones}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label className="col-sm-3 control-label">City<span className="text-danger">*</span></label>
                                            <div className="col-sm-6">
                                                <input className="form-control" ref="city" id="city" maxLength="60" placeholder="Enter your City" onChange={this.handleChange.bind(this, "city")} value={this.state.fields["city"]}/>
                                                <span style={{color: "red"}}>{this.state.errors["city"]}</span>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label className="profile-edit-profile" className="col-sm-3 control-label">Address<span className="text-danger">*</span></label>
                                            <div className="col-sm-6">
                                                <textarea className="form-control" ref="address" id="address" maxLength="300" placeholder="" onChange={this.handleChange.bind(this, "address")} value={this.state.fields["address"] || ''}/>
                                                <span style={{color: "red"}}>{this.state.errors["address"]}</span>
                                            </div>
                                        </div>

                                        {/* <div className="form-group ">
                                            <div className="col-sm-6 recpatcha-center">
                                                <div id="recpatcha-message" />
                                                <div id="recpatcha-success" />
                                                <div id="recaptcha" />
                                            </div>
                                        </div> */}


                                     </form>
                                    <button type="button" id="new-company-reset" className="btn btn-primary reset-btn pull-left" onClick={this.resetfilter.bind()}>Reset</button>
                                    <button type="button" id="new-company-save" className="btn btn-primary pull-left" onClick={this.onRecaptcha.bind(this)}>Save</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

)
}

}

export default NewCompanyProfile;
