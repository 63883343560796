import React,{useEffect, useState} from "react";
import { Modal, Button } from "react-bootstrap";
import Cookies from "js-cookie";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Application from "../../../../config/ApplicationWrapper";

const cookieDetails = Cookies.get("token");

export default function Approver(props) {
  const { show, closepopup, ratId, data } = props;
  const [approver, setApprover] = React.useState([]);
  const [pageDetails, setPageDetails] = React.useState([]);
  const [pageData, setPageData] = React.useState([]);
  const [onSubmit, setSubmit] = useState(false);

  useEffect(()=>{
    async function getGroupDetails() {
      try {
        const groupDetails = await fetch(
          `${Application.api_url}/api/v2/rats/ctra_approvers`,
          {
            method: "GET",
            headers: { Authorization: cookieDetails },
          }
        );
        const details = await groupDetails.json();
        setPageDetails(
          details && details.approvers ? details.approvers : []
        );
      } catch (err) {
        console.log("error===>", err);
      }
    }

    getGroupDetails();
  },[])

  useEffect(()=>{
    if(data && data.length > 0){
      data.forEach(element => {
        element.full_name = element.display_name;
        element.id = element.user_id;
      });
      setPageData(data)
    }
  },[data])
  console.log("pageData===>", pageData)
  const onhandleStatusClose = () => {
    closepopup();
  };

  const saveApprover = async () => {
    try {
      setSubmit(true);

      let approver_ids = ""
      if(approver.length > 0){
        approver_ids = approver.map( function( el ){ 
          return el.id; 
        }).toString();
      }

      let data = await fetch(
        `${Application.api_url}/api/v2/rats/${ratId}`,
        {
          method: "PUT",
          headers: {
            "content-type": "application/json",
            Authorization: cookieDetails,
          },
          body: JSON.stringify({
            approvers: approver_ids,
          }),
        }
      );
      data = await data.json();
      setSubmit(false);
      closepopup(data);
    } catch (err) {
      console.log("error==>", err);
    }
  };

  return (
    <Modal
      show={show}
      centered={true}
      className="add-task-modal signature-popup"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <b>Approvers </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Autocomplete
            multiple={true}
            limitTags={2}
            id="multiple-limit-tags"
            options={pageDetails}
            getOptionLabel={(options) => options.full_name}
            defaultValue={data}
            onChange={(event, value) => setApprover(value)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => <li {...props} key={option.id}>{option.full_name}</li>}
            renderInput={(params) => (
              <TextField {...params} label="Approvers" placeholder="Approvers" />
            )}
            sx={{ width: '500px' }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn-secondary"
          onClick={(e) => {
            onhandleStatusClose();
            setSubmit(false);
          }}
        >
          Cancel
        </Button>
        <Button
          className="btn-primary"
          onClick={(event) => {
            saveApprover();
            event.currentTarget.disabled = true;
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
