import React, { useEffect, useRef, useState } from "react";
import { NavLink, withRouter } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import ArrowUp from "../../../../../assets/fonts/ArrowRight.svg";
import RightArrow from "../../../../../assets/images/ArrowRight.svg";
import axios from "../../../../../config/axiosConfig";
import Application from "../../../../../config/ApplicationWrapper";
import Loader from "../../../../../components/MobileViewLoader";
import loader from "../../../../../assets/images/newloader.gif";
import Searchbar from "../../../../../assets/images/searchbar.svg";


const NewForms = ({ history }) => {
    let userDetails = localStorage.getItem("user");
    userDetails = JSON.parse(userDetails);

    const mounted = useRef(false);
    const [pageDetails, setPageDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [search, setSearch] = useState();

    useEffect(() => {
        getPageDetails(userDetails)
        setLoading(false)
    }, [])

    useEffect(() => {  
        const redirectTimer = setTimeout(() => { 
            mounted.current && getPageDetails(userDetails, 1)
            mounted.current = true;
        }, 2000);
    
        return () => clearTimeout(redirectTimer);
      }, [search]);

    const getPageDetails = async (details, page) => {
        try {
            window.scrollTo(0, 0); 
            let resultObj = await axios.get(`${Application.api_url}/api/v2/internal_subs/${details.company_identifier}/internal_templates`, {
                params: {
                    page: page ? page : pageNumber,
                    limit: 20,
                    audit_template_name_cont: search
                },
            });
            if (resultObj.data && resultObj.data.success == true) {
                let details = resultObj.data;
                if(!search){
                    setPageDetails(prevItems => [...prevItems, ...details.data])
                    setPageNumber(pageNumber + 1);
                    setPageCount(
                        details && details.metadata ? details.metadata.pages : 0
                    );
                } else {
                    setPageDetails(details.data)
                }
            }
        } catch (err) {
            console.log("error==>", err);
        }
    };

    const goBack = () => {
        history.push('/internalsub_dashboard');
    }

    return (
        <>
            <Loader loading={loading} />
            {!loading &&
                <div className="internal-sub-new-forms">
                    <div className="title">
                        <img src={ArrowUp} alt="back-arrow" className="back-arrow" onClick={(e) => { goBack() }} /><span className="role">New Form</span>
                    </div>
                    <div className="search-bar">
                        <input className="search-input" placeholder="Search" onChange={(e) => { setSearch(e.target.value) }} />
                        <img src={Searchbar} alt="Searchbar" className="search-icon" />
                    </div>
                    <div className="forms-list">
                        <InfiniteScroll
                            dataLength={pageDetails.length}
                            next={(e) => { getPageDetails(userDetails) }}
                            hasMore={pageCount >= pageNumber}
                            loader={
                                <div className="new-loader mobile-view" >
                                    <img src={loader} alt="" />
                                </div >
                            }
                        //endMessage={<p>No more items to load</p>}
                        >
                            {
                                pageDetails.length > 0 &&
                                pageDetails.map((val, index) => (
                                    <div className="new-forms">
                                        <p className="form-name">{val.audit_template && val.audit_template.name}</p>
                                        <p className="form-cmpy">{val.parent_company && val.parent_company.name}</p>
                                        <NavLink
                                            to={`/inter_sub_m_new_form_group/${val.identifier}`}
                                            id=""
                                            role="button"
                                            className="rat-links"
                                        >
                                            <div className="start-forms">
                                                <span>Start From</span>
                                                <img src={RightArrow} alt="back-arrow" className="back-arrow" />
                                            </div>
                                        </NavLink>
                                    </div>
                                ))}
                        </InfiniteScroll>
                    </div>
                </div>
            }
        </>
    )
}

export default withRouter(NewForms);