import React, { Fragment } from 'react'
import { PanelGroup, Panel, Table } from 'react-bootstrap';
import CompanyInfo from '../../components/SubContractor/CompanyInfo';
import EmployeesList from '../../components/SubContractor/EmployeesList';
import ProjectInfo from '../../components/SubContractor/ProjectInfo';
import SharedDocuments from "../../components/SubContractor/SharedDocuments";


const SubContractorInfo = ({item, index, subcontractor, project_shared_info, onClientSelect, onProjectSelect, onEmployeeSelect, isOpen, user_trainings, onModalClose}) => {
    const renderProjectsPanel = subcontractor.assigned_projects.map((project, index) => {
        return(
            <ProjectInfo project={project}
                         key={index}
                         project_shared_info={project_shared_info}
                         client={item}
                         index={index}
                         onProjectSelect={onProjectSelect}/>
        )
    });

    const renderContent = (projects) => {
        if (projects.length == 0){
            return <h2 className='align-center'> No Projects were Assigned</h2>
        }
        return renderProjectsPanel;
    }

    return (
        <Panel eventKey={index}>
            <Panel.Heading>
                <Panel.Title toggle onClick={() => onClientSelect(item)}  data-comapnyid={subcontractor.base_info.id}>
                    <div className="sub-accordion-title">
                        <img
                            alt={item.name}
                            onError={(event) => (event.target.style.display = "none")}
                            src={item.logo}
                        />
                        <h3>{item.company}</h3>
                    </div>
                </Panel.Title>
            </Panel.Heading>
            <Panel.Body collapsible>
                <div className="row">
                    <div className="col-md-6">
                        <CompanyInfo client={subcontractor.base_info}/>
                    </div>
                    <div className="col-md-6">
                        <EmployeesList employees={subcontractor.employees} onEmployeeSelect={onEmployeeSelect} isOpen={isOpen} user_trainings={user_trainings} onModalClose={onModalClose}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <SharedDocuments documents={subcontractor.shared_documents} clientid={subcontractor.base_info.id} item={item}/>
                        <h2> Assigned Projects</h2>
                        <PanelGroup accordion id="accordion-child" defaultActiveKey="">
                            {renderContent(subcontractor.assigned_projects)}
                        </PanelGroup>

                    </div>
                    
                </div>
            </Panel.Body>
        </Panel>
    )
}

export default SubContractorInfo;
