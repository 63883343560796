import React, {useEffect, useState} from "react";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Info from "../../../assets/images/Info.svg"
import Application from "../../../config/ApplicationWrapper";
import Cookies from "js-cookie";
import {OverlayTrigger ,Popover} from "react-bootstrap";
import {Typography} from "@mui/material";
import {toast} from "react-toastify";

const RiskRangeSettings = () => {
    let initialRange = {
        min: "",
        max: "",
        red: "",
        yellow: "",             
        green: "",
        f_legend: "",
        p_legend: "",
        np_legend: "",
        sp_legend: "",
        spp_legend: "",
        total_legend: "",
        risk_calc: "",
    }
    const [riskRange, setRiskRange] = useState(initialRange);
    const [reload, setReload] = useState(false)
    const cookieDetails = Cookies.get("token");

    const getPageDetails = async () => {
        try {
            var url = new URL(`${Application.api_url}/api/v2/risk_assesments/risk_ranges`);

            const res = await fetch(url, {
                method: "GET",
                headers: { Authorization: cookieDetails },
            });
            const resultObj = await res.json();
            setRiskRange(
                resultObj && resultObj.risk_range ? resultObj.risk_range : {}
            );
            setReload(false)
        } catch (err) {
            console.log("error==>", err);
        }
    };

    const updatePageDetails = async () => {
        try {
            let res = await fetch(
                `${Application.api_url}/api/v2/risk_assesments/risk_ranges`,
                {
                    method: "POST",
                    headers: {
                        "content-type": "application/json",
                        Authorization: cookieDetails,
                    },
                    body: JSON.stringify({
                        risk_range: riskRange,
                    }),
                }
            );
            const resultObj =  await res.json();
            setRiskRange(
                resultObj && resultObj.risk_range ? resultObj.risk_range : []
            );
            setReload(true)
            toast.success('Updated successfully');
        } catch (err) {
            console.log("error==>", err);
        }
    }

    const popoverHoverFocus = (text) => (
        <Popover id="popover-trigger-hover-focus" >
            {text}
        </Popover>
    );

    useEffect(() => {
        getPageDetails();
    }, [reload]);

    return (
        <div className="riskRangeSettings">
            <div class="card">
                <div class="card-body">
                    <div className="d-flex align-item-center justify-content-between header-badge">
                        <div className="d-flex align-item-center">
                            <h3>Risk Range Settings</h3>
                        </div>
                        <div className="btn-style">
                            {/*<button type="button" className="btn btn-outline-primary">*/}
                            {/*    Cancel*/}
                            {/*</button>*/}
                                <button type="button" class="btn btn-primary" onClick={(event) => {
                                    event.preventDefault();
                                    updatePageDetails()
                                }}>
                                    Update
                                </button>
                        </div>
                    </div>
                   <div className="rangeSetting">
                       <Grid container spacing={1}>
                           <Grid item xs={4} >
                               <div className="rangeSection">
                                   <h4>
                                       Hazards Range of Risk
                                       <OverlayTrigger
                                           trigger={['hover', 'focus']}
                                           placement="bottom"
                                           overlay={popoverHoverFocus("In your hazards you will have a range to select from for each section, choose a min and a max.")}
                                       >
                                           <img src={Info} alt=""/>
                                       </OverlayTrigger>
                                   </h4>

                                   <Grid container spacing={2}>
                                       <Grid item xs={6}>
                                           <label>Minimum</label>
                                           <TextField style={{width:"100%"}} id="outlined-basic" placeholder="For example: 1"
                                                      variant="outlined" InputLabelProps={{shrink:false, style: {fontSize: 15}}}
                                                      inputProps={{style: {fontSize: 15}}}
                                                      InputProps={{notched:false}} value={riskRange && riskRange.min}
                                                      onChange={(e) => {
                                                          setRiskRange({
                                                              ...riskRange,
                                                              ...{ min: e.target.value },
                                                          });
                                                      }}
                                           />

                                       </Grid>
                                       <Grid item xs={6}>
                                           <label>Maximum</label>
                                           <TextField style={{width:"100%"}} id="outlined-basic" placeholder="For example: 15"
                                                      variant="outlined" InputLabelProps={{shrink:false, style: {fontSize: 15}}}
                                                      inputProps={{style: {fontSize: 15}}}
                                                      InputProps={{notched:false}} value={riskRange && riskRange.max}
                                                      onChange={(e) => {
                                                          setRiskRange({
                                                              ...riskRange,
                                                              ...{ max: e.target.value },
                                                          });
                                                      }}
                                           />

                                       </Grid>

                                   </Grid>
                               </div>
                           </Grid>
                           <Grid item xs={4} >
                               <div className="rangeSection">
                                   <h4>
                                       Calculation
                                       <OverlayTrigger
                                           trigger={['hover', 'focus']}
                                           placement="bottom"
                                           overlay={popoverHoverFocus("Calculation type controls how a hazard pre and post calculations are done in the Risk Assessment section")}
                                       >
                                           <img src={Info} alt=""/>
                                       </OverlayTrigger>
                                   </h4>
                                   <RadioGroup
                                       row
                                       aria-labelledby="demo-row-radio-buttons-group-label"
                                       name="row-radio-buttons-group"
                                   >
                                       <FormControlLabel value="" control={<Radio />}
                                                         label={<Typography sx={{ fontSize: 15, fontFamily: 'Lato !important' }}>Multiplication</Typography>}
                                                         checked={riskRange && riskRange.risk_calc === "Multiplication"}
                                                         onChange={(e) => {
                                                             setRiskRange({
                                                                 ...riskRange,
                                                                 ...{ risk_calc: "Multiplication" },
                                                             });
                                                         }}
                                       />
                                       <FormControlLabel value="" control={<Radio />}
                                                         label={<Typography sx={{ fontSize: 15, fontFamily: 'Lato !important' }}>Addition</Typography>}
                                                         checked={riskRange && riskRange.risk_calc === "Addition"}
                                                         onChange={(e) => {
                                                             setRiskRange({
                                                                 ...riskRange,
                                                                 ...{ risk_calc: "Addition" },
                                                             });
                                                         }}
                                       />
                                   </RadioGroup>
                               </div>
                           </Grid>
                           <Grid item xs={4}>
                               <div className="rangeSection">
                                   <h4>
                                       Color Coded Risk Range Section (Green, Yellow, Red)
                                       <OverlayTrigger
                                           trigger={['hover', 'focus']}
                                           placement="bottom"
                                           overlay={popoverHoverFocus("Risk ranges must be inputted in a certain way to work (i.e. '1 - 10', '11-50', etc)")}
                                       >
                                           <img src={Info} alt=""/>
                                       </OverlayTrigger>
                                   </h4>
                                   <Grid container spacing={2}>
                                       <Grid item xs={4}>
                                           <div style={{display:"flex" , alignItems:"baseline"}}><div style={{background: "green"}} className="Green circle"></div>
                                               <label style={{color:"green"}}>Green Range</label></div>
                                           <TextField style={{width:"100%"}} id="outlined-basic" placeholder="XX-XX"
                                                      variant="outlined" InputLabelProps={{shrink:false, style: {fontSize: 15}}}
                                                      inputProps={{style: {fontSize: 15}}}
                                                      InputProps={{notched:false}}
                                                      value={riskRange && riskRange.green}
                                                      onChange={(e) => {
                                                          setRiskRange({
                                                              ...riskRange,
                                                              ...{ green: e.target.value },
                                                          });
                                                      }}
                                           />
                                       </Grid>
                                       <Grid item xs={4}>
                                            <div style={{display:"flex" , alignItems:"baseline"}}>
                                                <div style={{background: "#FCB62B"}} className="Green circle"></div>
                                                <label style={{color:"#FCB62B"}}>Yellow Range</label>
                                            </div>
                                           <TextField style={{width:"100%"}} id="outlined-basic" placeholder="XX-XX"
                                                      variant="outlined" InputLabelProps={{shrink:false, style: {fontSize: 15}}}
                                                      inputProps={{style: {fontSize: 15}}}
                                                      InputProps={{notched:false}}
                                                      value={riskRange && riskRange.yellow}
                                                      onChange={(e) => {
                                                          setRiskRange({
                                                              ...riskRange,
                                                              ...{ yellow: e.target.value },
                                                          });
                                                      }}
                                           />
                                       </Grid>
                                       <Grid item xs={4}>
                                           <div style={{display:"flex", alignItems:"baseline" }}>
                                               <div style={{background: "red"}} className="Green circle"></div>
                                               <label style={{color:"red"}}> Red Range</label>
                                           </div>

                                           <TextField style={{width:"100%"}} id="outlined-basic" placeholder="XX-XX"
                                                      variant="outlined" InputLabelProps={{shrink:false, style: {fontSize: 15}}}
                                                      inputProps={{style: {fontSize: 15}}}
                                                      InputProps={{notched:false}}
                                                      value={riskRange && riskRange.red}
                                                      onChange={(e) => {
                                                          setRiskRange({
                                                              ...riskRange,
                                                              ...{ red: e.target.value },
                                                          });
                                                      }}
                                           />
                                       </Grid>
                                   </Grid>
                               </div>

                           </Grid>

                       </Grid>
                   </div>
                    <hr style={{border: "1px solid #E0E0E0" , marginTop:"30px"}} />
                    <div className="informationSection">
                        <h3>
                           Legend Information
                            <OverlayTrigger
                                trigger={['hover', 'focus']}
                                placement="bottom"
                                overlay={popoverHoverFocus("Lorem ipsum dolor sit amet, consectetur adipiscing elit.")}
                            >
                                <img src={Info} alt=""/>
                            </OverlayTrigger>
                        </h3>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <label className="title-text">Severity Potential</label>
                                <textarea
                                    className="create-asset-textarea"
                                    aria-label="empty textarea"
                                    placeholder="Empty"
                                    value={riskRange && riskRange.spp_legend}
                                    style={{ width: "100%", resize: "vertical", fontSize: 17 }}
                                    onChange={(e) => {
                                        setRiskRange({
                                            ...riskRange,
                                            ...{ spp_legend: e.target.value },
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <label>Probability</label>
                                <textarea
                                    className="create-asset-textarea"
                                    aria-label="empty textarea"
                                    placeholder="Empty"
                                    value={riskRange && riskRange.p_legend}
                                    style={{ width: "100%", resize: "vertical", fontSize: 17 }}
                                    onChange={(e) => {
                                        setRiskRange({
                                            ...riskRange,
                                            ...{ p_legend: e.target.value },
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <label>Frequency</label>
                                <textarea
                                    className="create-asset-textarea"
                                    aria-label="empty textarea"
                                    placeholder="Empty"
                                    value={riskRange && riskRange.f_legend}
                                    style={{ width: "100%", resize: "vertical", fontSize: 17 }}
                                    onChange={(e) => {
                                        setRiskRange({
                                            ...riskRange,
                                            ...{ f_legend: e.target.value },
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <label># of People</label>
                                <textarea
                                    className="create-asset-textarea"
                                    aria-label="empty textarea"
                                    placeholder="Empty"
                                    value={riskRange && riskRange.np_legend}
                                    style={{ width: "100%", resize: "vertical", fontSize: 17 }}
                                    onChange={(e) => {
                                        setRiskRange({
                                            ...riskRange,
                                            ...{ np_legend: e.target.value },
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <label>Total</label>
                                <textarea
                                    className="create-asset-textarea"
                                    aria-label="empty textarea"
                                    placeholder="Empty"
                                    value={riskRange && riskRange.total_legend}
                                    style={{ width: "100%", resize: "vertical", fontSize: 17 }}
                                    onChange={(e) => {
                                        setRiskRange({
                                            ...riskRange,
                                            ...{ total_legend: e.target.value },
                                        });
                                    }}
                                />
                            </Grid> 
                        </Grid>
                    </div> 
                </div>
            </div>
        </div>
    );
};

export default RiskRangeSettings;
