import React, { useState } from 'react';
import SubTabs from '../../../shared/components/subTabs';
import useFilterChange from '../../../shared/components/useFilterChange';
import { ButtonCreate } from '../../../shared/components/table';
import useFetch from '../../../shared/components/userFetch';
import CompanyGroupsList from './companyGroupList';
import CompanyGroupsArchive from './companyGroupArchive';
import CreateGroup from './createGroup';
import EditGroup from './editGroup';
import { connect } from 'react-redux';


const subTabs = {
    cg: [
        {
            label: 'Company Groups',
            value: 'comp_group'
        },
        {
            label: 'Archived',
            value: 'group_arch'
        }
    ]
}


const CompanyGroupWrapper = ({title, active, pageCount}) => {
    const initialState = {
        page: 1,
        limit: 20
    }
    const { data: baseData, isLoading: baseDataLoading, refetch: refetchBaseData } = useFetch(
        "/api/v2/entity_management/groups/base_data"
      );
    const [create, setCreate] = useState(false);
    const [edit, setEdit] = useState('');
    const { handleOnFilterChange, filterState, filterData, setFilterState, handleSort } = useFilterChange(initialState);
    const { handleOnFilterChange: handleOnFilterChangeArchive, filterState: filterStateArchive, filterData: filterDataArchive, setFilterState: setFilterStateArchive, handleSort: handleSortArchive } = useFilterChange(initialState);
    const {permissions: {allow_create} = {}} = baseData || {};
    const refetch = () => {
        setFilterState({...filterState});
    }
    return (
        <>
            <SubTabs
                tabList={subTabs[active] || []}
                withTitle
                title={title}
                pageCount={pageCount.cg}
                extras={[
                    <div style={{display: 'flex', gap: '10px'}}>
                        {allow_create && <ButtonCreate label="New Group" onClick={() => setCreate(true)} />}
                    </div>
                ]}>
                {(activeKey) => (
                    <>
                        {activeKey === "comp_group" && (
                            <>
                                <CompanyGroupsList
                                    handleOnFilterChange={handleOnFilterChange}
                                    filterData={filterData}
                                    filterState={filterState}
                                    setFilterState={setFilterState}
                                    handleSort={handleSort}
                                    initialState={initialState}
                                    setEdit={setEdit}
                                    baseData={baseData}
                                />
                            </>
                        )}
                        {activeKey === 'group_arch' && (
                            <CompanyGroupsArchive
                                handleOnFilterChange={handleOnFilterChangeArchive}
                                filterData={filterDataArchive}
                                filterState={filterStateArchive}
                                setFilterState={setFilterStateArchive}
                                handleSort={handleSortArchive}
                                initialState={initialState}
                            />
                        )}
                    </>
                )}
            </SubTabs>
            {create && <CreateGroup open={create} setOpen={setCreate} setFilterState={setFilterState} refetch={refetchBaseData} />}
            {!!edit && <EditGroup edit={edit} setEdit={setEdit} refetch={refetch} />}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        pageCount: state.CommonReducer.pageCount
    };
  };
  
  export default connect(mapStateToProps)(CompanyGroupWrapper);
