import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";

import { FileUploader } from "react-drag-drop-files";
import { Button, FormHelperText } from '@mui/material';

import axios from "@config/axiosConfig";
import File from "@assets/fonts/File-cover.svg";
import Upload from "@assets/images/Upload.svg";
import Application from "@config/ApplicationWrapper";

const initialValues = {
    name: "",
    parent_id: "",
};

const UploadFiles = ({ show, closePopup, details, parentId, companyId }) => {
    const label = { inputProps: { 'aria-label': 'Switch demo' } };
    const uploadInputRef = useRef(null);

    const [docs, setDocs] = useState([]);
    const [drag, setDrag] = useState(false);
    const [values, setValues] = useState(initialValues)
    const [onSubmit, setSubmit] = useState(false);
    const [validation, setValidation] = useState(true);

    const handleStatusClose = () => {
        setValues(initialValues);
        setDocs([])
        setSubmit(false)
        closePopup();
        setDrag(false)
    };

    const handleChange = (name, value) => {
        setValues({ ...values, [name]: value });
    }

    const create = async () => {
        setSubmit(true);
        if (docs.length === 0) {
            setValidation(false)
            return false;
        }
        const users = JSON.parse(localStorage.getItem('user'));
        const formData = new FormData();

        formData.append("company_identifier", companyId);
        formData.append("doc_detail[doc_type]", "file");
        formData.append("doc_detail[user_id]", users.id);
        parentId && formData.append("doc_detail[parent_id]", parentId);

        [...docs].forEach(image => {
            formData.append("docs[]", image);
        });

        let url = `${Application.api_url}/api/v2/company_documents/${companyId}/upload`;
        if (details) url = `${Application.api_url}/api/v2/company_documents/${details.slug}`;

        const result = await axios({
            method: "PATCH",
            url: url,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if (result.data && result.data.success === true) {
            toast.success(`${result.data.message}`)
            closePopup(result.data);
            setValues("");
            setDocs([]);
            setSubmit(false)
            setDrag(false)
        }
    }

    function Documents() {
        const handleFileChange = (file) => {
            setDocs([...docs, ...file]);
        };

        const handleDelete = (fileToDelete) => {
            setDocs(docs.filter(file => file !== fileToDelete));
        };

        if (docs.length === 0) {
            return (
                <>
                    {drag ? (
                        <FileUploader handleChange={handleFileChange} name="file" multiple={true} label="Upload or drop files here" className="drop-box"/>
                    ) : (
                        <div className="create-asset-upload">
                            <Button className="uploadButton" component="label">
                                <img src={Upload} alt="" /> Upload Files
                                <input className="internal-doc-uploader"
                                    hidden
                                    type="file"
                                    multiple
                                    ref={uploadInputRef}
                                    error={
                                        docs.length === 0 && onSubmit
                                    }
                                    helperText={
                                        docs.length === 0 && onSubmit ? "Image/File is required" : ""
                                    }
                                    onChange={(e) => {
                                        setDocs(Array.from(e.target.files));
                                    }}
                                />
                            </Button>
                        </div>
                    )}
                    <FormHelperText className="inputErrorMsg" error={!validation && onSubmit === true}>
                        {!validation && onSubmit === true
                            ? "Please upload your Files"
                            : ""}
                    </FormHelperText>
                </>
            )
        } else if (docs.length !== 0) {
            return (
                <div className="attachment-grid">
                    {docs && Array.isArray(docs) && docs.map((doc, index) => (
                        <div key={index} className="file-item">
                            <img className="file-type image-grid" alt="file" src={File}/>
                            <p className="file-name">{doc.name}</p> {/* */}
                            <button
                                className="delete-button grid-delete"
                                onClick={() => handleDelete(doc)}
                                aria-label={`Delete ${doc.name}`}
                            >
                                <img alt="Delete"
                                     src="/packs/media/assets/fonts/Delete-62b3f74c182772359479dc3681fbd490.svg"
                                />
                            </button>
                        </div>
                    ))}
                </div>
            )
        }
    }


    return (
        <Modal className="qr-modal-wrap add-task-modal pf-model docs-model internal-docs" show={show}
               onHide={(e) => { handleStatusClose(); }}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="pf-model-header">
                        <p className="pf-model-title">Upload Files</p>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="row">
                        <div className="col-md-12"></div>
                        <div className="col-md-12">
                            <label>
                                Image
                            </label>
                            <Documents />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="submit-btns">
                    <Button className="btn-cancel" onClick={(e) => { handleStatusClose() }}>Cancel</Button>
                    <Button variant="contained"
                            onClick={(event) => {
                                event.currentTarget.disabled = true;
                                create()
                            }}>
                        {details ? "Update" : "Upload"}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default UploadFiles;
