import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import ArrowUp from "../../../../../assets/fonts/ArrowRight.svg";
import EditImg from "../../../../../assets/images/Edit.svg";
import Searchbar from "../../../../../assets/images/searchbar.svg";
import PDF from "../../../../../assets/images/PDF.png";
import axios from "../../../../../config/axiosConfig";
// import Application from "../../../../../config/ApplicationWrapper";
import loader from "../../../../../assets/images/newloader.gif";
import Loader from "../../../../../components/MobileViewLoader";


const Application = {
    api_url: window.location.origin   //process.env.REACT_APP_API_URL // or any method you are using to set the URL
};

const CompletedFroms = ({ history }) => {
    let userDetails = localStorage.getItem("user");
    userDetails = JSON.parse(userDetails);

    const mounted = useRef(false);
    const [pageDetails, setPageDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [search, setSearch] = useState();
    const [pageCount, setPageCount] = useState(0);
    const [error, setError] = useState(null);

    useEffect(() => {
        getPageDetails(userDetails);
        setLoading(false);
    }, []);

    useEffect(() => {  
        const redirectTimer = setTimeout(() => { 
            mounted.current && getPageDetails(userDetails, 1);
            mounted.current = true;
        }, 2000);
    
        return () => clearTimeout(redirectTimer);
    }, [search]);

    const getPageDetails = async (details, page) => {
        try {
            window.scrollTo(0, 0);
            // debugger;
            console.log('API URL:', `${Application.api_url}/api/v2/internal_subs/${details.company_identifier}/internal_audits`);
            let resultObj = await axios.get(`${Application.api_url}/api/v2/internal_subs/${details.company_identifier}/internal_audits`, {
                params: {
                    page: page ? page : pageNumber,
                    limit: 20,
                    archived: false,
                    form_name: search,
                },
            });
            
            if (resultObj.data && Array.isArray(resultObj.data.audits)) {
                let details = resultObj.data; 
                if (!search) {
                    setPageDetails(prevItems => [...prevItems, ...details.audits]);
                    setPageNumber(pageNumber + 1);
                    setPageCount(details.metadata ? details.metadata.pages : 0);
                } else {
                    setPageDetails(details.audits);
                }
            } else {
                throw new Error('Invalid data format');
            }
        } catch (err) {
            console.log("error==>", err);
            setError('Failed to fetch data. Please try again later.');
        }
    };

    const goBack = () => {
        history.push('/internalsub_dashboard');
    }

    const viewPDF = async (data) => { 
        window.open(`${Application.api_url}/api${data}`, '_blank'); 
    }

    const startInspection = async (details) => { 
        window.location.href = `/audits/${details.audit_id}`; 
    }

    return (
        <>
            <Loader loading={loading} />
            {!loading &&
                <div className="internal-sub-forms">
                    <div className="title">
                        <img src={ArrowUp} alt="back-arrow" className="back-arrow" onClick={(e) => { goBack() }} /><span className="role">Forms</span>
                    </div>
                    <div className="search-bar">
                        <input className="search-input" placeholder="Search" onChange={(e) => { setSearch(e.target.value) }} />
                        <img src={Searchbar} alt="Searchbar" className="search-icon" />
                    </div>
                    <div className="forms-list">
                    {error && <div className="error-message">{error}</div>}
                    {!error && 
                    <InfiniteScroll
                            dataLength={pageDetails.length}
                            next={(e)=>{getPageDetails(userDetails)}}
                            hasMore={pageCount >= pageNumber } 
                            loader={
                                <div className="new-loader mobile-view" >
                                    <img src={loader} alt="" />
                                </div >
                            }
                        >
                        {
                            pageDetails.length > 0 &&
                            pageDetails.map((val, index) => (
                                <div className="new-forms" key={index}>
                                    <p className="form-name">{val.name}</p>
                                    <p className="form-cmpy">{val.company}</p>
                                    <div className="form-info">
                                        <div className="details">
                                            <label className="lable">Group:</label>
                                            <label className="info">{val.group_name}</label>
                                        </div>
                                        <div className="details">
                                            <label className="lable">Stared On:</label>
                                            <label className="info">{val.started}</label>
                                        </div>
                                        <div className="details">
                                            <label className="lable">Completed By:</label>
                                            <label className="info">{val.first_name} {val.last_name}</label>
                                        </div>
                                        <div className="details">
                                            <label className="lable">Completed On:</label>
                                            <label className="info">{val.completed}</label>
                                        </div>
                                    </div>
                                    <div className="form-btns">
                                        <div className="start-forms" onClick={(e)=>{startInspection(val)}}>
                                            <img src={EditImg} alt="back-arrow" className="back-arrow" />
                                            <span>Edit Form</span>
                                        </div>
                                        <div className="view-pdf" onClick={(e)=>{viewPDF(val.pdflink)}}>
                                            <img src={PDF} alt="back-arrow" className="back-arrow" />
                                            <span>View Pdf</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            </InfiniteScroll>}
                    </div>
                </div>
            }
        </>
    )
}

export default withRouter(CompletedFroms);