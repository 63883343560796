import React from "react"
import '../../assets/styles/themes.scss'

const phoneNumber = '1-833-444-9876'
const website = 'www.safetyloop.com'
const email = 'sales@safetyloop.com, support@safetyloop.com'

const PricingFooter = () => {
  return (
    <div className='footer'>
      <p className='description'>
        {`
          Whether you are a one person team or a company with thousand of users. We have a plan
          that’s right for you. Getting stared with SafetyLoop has never been easier.
          Simply call ${phoneNumber} to setup your free account and login to get started.
          As your needs grow, we are here to help you every step of the way.
        `}
      </p>
      <div className='eSeparator__footer--horizontal'></div>
      <div className='social_contact'>
        <div className='top'>
          Website: {website}
          <div className='eSeparator--vertical'></div>
          Contact: Head Office {phoneNumber}
        </div>

        <div className='bottom'>Email: {email}</div>
      </div>
    </div>
  )
}

export default PricingFooter
  