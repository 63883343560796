import React, {Fragment} from "react";
import Highcharts from 'highcharts'
import Chart from "../../components/Chart";

const ActionStatusChart = ({stats, query, onFilterSelect, error}) => {
    const actions = Object.keys(stats)
    const values = Object.values(stats)
    let chartOptions = {
        "chart": {
            "type": "column"
        },
        "title": {
            "text": "Current Actions Status"
        },
        "xAxis": {
            "categories": actions,
            "title": {
                "text": null
            },
            "gridLineWidth": 0,
            "enabled": false

        },
        "yAxis": {
            "min": 0,
            "title": {
                "text": "",
                "align": "high"
            },
            "labels" : {
                "enabled": false
            },
            "gridLineWidth": 0
        },
        "tooltip": {
            "valueSuffix": " "
        },
        "plotOptions": {
            "series": {
                "pointWidth": 140,
                "dataLabels": {
                    enabled: true,
                    format: '{point.y}'
                },
                "cursor": "pointer",
                "colorByPoint": true,
                "point": {
                    "events": {
                        click: function (e) {
                            let action_status = {1:0, 2:1, 3:2}
                            let location_origin = window.location.origin
                            if (e.point.index !== 0){
                                let status = action_status[e.point.index]
                                let url = `${location_origin}/mrc_dashboard?status=${status}&search=true`
                                window.open(url, '_blank')
                            }else{
                                window.open(`${location_origin}/mrc_dashboard`, '_blank')
                            }
                        }
                    }
                }
            },
            "bar": {
                "dataLabels": {
                    "enabled": false,
                    "crop": false,
                    "overflow": "none"

                }
            }
        },
        "colors": [
            "#639dcf",
            "#306a9c",
            "#18344E",
            "#132A3E"
        ],
        "legend": {
            "layout": "vertical",
            "align": "right",
            "verticalAlign": "top",
            "x": -40,
            "y": 80,
            "floating": true,
            "borderWidth": 1,
            "backgroundColor": "#FFFFFF",
            "shadow": true
        },
        "credits": {
            "enabled": false
        },
        "series": [{
            "showInLegend": false,
            "name": "No of Actions",
            "data": values
        }],
        "exporting": {
            "enabled": true
        }
    }

    return (
        <Fragment>
            <Chart options={chartOptions} type={'chart'} highcharts={Highcharts} />
        </Fragment>
    )
}

export default ActionStatusChart;
