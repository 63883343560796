import React from 'react'
import { Link } from "react-router-dom"
import '../../assets/styles/themes.scss'
import PricingHeaderLogo from './PricingHeaderLogo.svg'

const PricingHeader = () => {
  return (
    <div className="header">
      <a href="/">
        <img src={PricingHeaderLogo} alt='' />
      </a>
    </div>
  )
}

export default PricingHeader
