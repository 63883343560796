import React from 'react';

class PageSubNavBar extends React.Component
{
    constructor(props)
    {
        super(props);

    }
    render()
    {
        return (

            <div className="sub-navbar sub-navbar__header-breadcrumbs">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 sub-navbar-column">
                            <div className="sub-navbar-header">

                            </div>
                            <ol className="breadcrumb navbar-text navbar-right no-bg">
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            )
    }
}

export default PageSubNavBar;
