import React from "react";
import { Modal, Button } from "react-bootstrap";
import Downarrow from "../../../../assets/images/downarrow.svg";

export default function Status(props) {
  const { status, stausDetails, closepopup } = props;
  const onhandleStatusClose = () => {
    closepopup();
  };

  return (
    <Modal
      show={status}
      onHide={onhandleStatusClose}
      centered={true}
      className="status-modal"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title className="Rat-status-popup">
          <b>RAT Approval Status</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Name</th>
              <th>Company</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {stausDetails &&
              stausDetails.length > 0 &&
              stausDetails.map((val, index) => (
                <>
                  <tr>
                    <td>
                      <div class="d-flex child-group">
                        <img
                          class="downarrow"
                          src={Downarrow}
                          type="button"
                          data-toggle="collapse"
                          data-target={`#collapseExample${index}`}
                          aria-expanded="false"
                          aria-controls="collapseExample"
                        />
                        <div>
                          <span class="name">{val.display_name}</span>
                          <br />
                        </div>
                      </div>
                    </td>
                    <td>{val.company_name}</td>
                    <td>{val.status}</td>
                  </tr>
                  <tr class="collapse" id={`collapseExample${index}`}>
                    <td colspan="6" className="action_details">
                      <div>
                        <h4>Action Details</h4>
                        <div class="card">
                          <div class="card-body">
                            <div className="d-flex" style={{ margin: "10px 0" }}>
                              <div
                                className="col-md-4"
                                style={{ borderRight: "1px solid #D3D3D3" }}
                              >
                                <p>Name</p>
                                <h5 style={{ marginBottom: "27px" }}>
                                {val.display_name}
                                </h5>
                              </div>
                              <div
                                className="col-md-4"
                                style={{ borderRight: "1px solid #D3D3D3" }}
                              >
                                <p>Time</p>
                                <h5>{val.approval_date}</h5>
                              </div>
                              <div className="col-md-4">
                              <p>Signature</p>
                                { val.signature &&
                                  <p><img src={val.signature} alt="" style={{width: "148px"}}/></p>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </>
              ))}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-secondary" onClick={onhandleStatusClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
