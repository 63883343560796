import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SideNav from '../../components/SideNav';
import ProfileCard from '../../components/User/ProfileCard';
import AssignedTrainings from '../../components/User/AssignedTrainings';
import UserGroups from '../../components/User/UserGroups';
import { Tabs, Tab } from '@mui/material';
import '../../../assets/stylesheets/common.css';

const initialState = {
    profile_info: {
        username: '',
        email: '',
        profile_image: '',
        imagePreviewUrl: '',
        company: '',
        telephone_number: '',
        role: '',
        last_updated: '',
        first_name: '',
        last_name: '',
        address: ''
    }, trainings: [], groups: [], activities: []
}

const UserProfile = () => {
    const [user, setUser] = useState(initialState);
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        async function fetchData() {
            const result = await axios.get("user_management/profile_info", {
                params: { operational_data: true, trainings_by_topic: true },
            });
            setUser(result.data)
        }
        fetchData().then((r) => r);
    }, []);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <React.Fragment>
            <div className="msmcontainer dashboard-container">
                <div className="row d-flex">
                    <SideNav />
                    <div className="content-wrap col-sm-12 margin-top-style">
                        <div className="profile-header">
                            <div className="profile-back">
                                {/*<a href="#">← User Profile</a>*/}
                            </div>
                            <div className="profile-edit">
                                {/*<button>Edit Profile</button>*/}
                            </div>
                        </div>
                        <ProfileCard user={user.profile_info} />

                        <div className="tabs-container">
                            <Tabs value={activeTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
                                <Tab label="Record Of Training" />
                                <Tab label="Groups" />
                            </Tabs>
                        </div>

                        <div className="tab-content">
                            {activeTab === 0 && <AssignedTrainings trainings={user.trainings} />}
                            {activeTab === 1 && <UserGroups groups={user.groups} />}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default UserProfile;
