import React, { useEffect, useReducer, useState } from "react";
import axios from "../../../config/axiosConfig";
import Application from "../../../config/ApplicationWrapper";
import { User } from "../../HomePage/Login";
import Active from "./Active";
import Settings from "../../../assets/images/Settings-new.svg";
import AddTimeCard from "./popups/AddTimeCard";
import { withRouter } from 'react-router-dom';
import ExportTimecardsModal from "./popups/ExportTimecardsModal";


const initialTabStatus = {
    inventoryList: true,
    archived: false,
    orderRequest: false,
    backOrder:false,
    orderInProgress: false,
    orderHistory: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case "UPDATE":
            state[action.tab] = true;
            return { ...state };
        default:
            return state;
    }
};

const TimeTrackingList = ({ history, openUpdateModalHandler }) => {
    const [tabs, dispatch] = useReducer(reducer, initialTabStatus);
    const [activeTab, setActiveTab] = useState(1);
    const [pageDetails, setPageDetails] = useState();
    const [show, setShow] = useState(false);
    const [showExport, setShowExport] = useState(false);
    const [closeExport, setCloseExport] = useState(true);
    const [costCodes, setCostCodes] = useState([]);
    const [refresh, setRefresh] = useState();
    const [groupsList, setGroupsList] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [settings, setSettings] = useState(false);
    const [search, setSearch] = useState({
        name_cont: "",
        inventory_type_name_cont: "",
        status_eq: "",
        supplier_name_cont: "",
        serial_no_cont: "",
        low_stock_threshold_eq: "",
        unit_price_eq: "",
        inventory_location_name_cont: "",
        expiration_date_eq: "",
        notification_alert_eq: "",
        last_order_date_eq: "",
    });

    const [createTimeCard, setCreateTimeCard] = useState(false);
    const [tooltip, setTooltip] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        try {
            axios
                .get(`${Application.api_url}/api/v2/inventories/general_info`, {
                    params: {
                        ...search,
                    },
                })
                .then((res) => {
                    let details = res.data;
                    if (details && details.data) {
                        setPageDetails(details.data);
                    }
                })
                .catch((error) => {
                    console.log("err==>", error);
                });

            groupDetail()
            userDetail()
        } catch (err) {
            console.log("error==>", err);
        }
    }, [refresh, search]);

    useEffect(() => {
        getConfigurationDetails()
    }, []);

    const getConfigurationDetails = async () => {
        const configuration = {};

        try {
            const [
                classificationsResponse,
                costCodesResponse,
                timeTypesResponse,
                billingTosResponse,
                usersResponse
            ] = await Promise.all([
                axios.get(`${Application.api_url}/api/v2/time_trackers/classifications`),
                axios.get(`${Application.api_url}/api/v2/time_trackers/cost_codes`),
                axios.get(`${Application.api_url}/api/v2/time_trackers/time_types`),
                axios.get(`${Application.api_url}/api/v2/time_trackers/billing_tos`),
                axios.get("/users/check_for_user")
            ]);

            const setConfigValue = (response, key) => {
                if (response.status === 200 && response.data && response.data.data && response.data.data.length >= 1) {
                    if (key == 'cost_code') setCostCodes(response.data.data);
                    configuration[key] = true;
                }
            };

            setConfigValue(classificationsResponse, 'classification');
            setConfigValue(costCodesResponse, 'cost_code');
            setConfigValue(timeTypesResponse, 'time_type');
            setConfigValue(billingTosResponse, 'billing_to');

            if (usersResponse.status === 200 && usersResponse.data && usersResponse.data.user) {
                configuration.time_tracker = usersResponse.data.user.time_tracker_enabled;
            }

            const allConfigSet = ['classification', 'cost_code', 'time_type', 'billing_to', 'time_tracker'].every(key => configuration[key]);
            if (allConfigSet) {
                setCreateTimeCard(true);
                setIsDisabled(false);
            } else {
                const userRole = User.role;
                let tooltipMessage = '';

                if (!configuration.time_tracker) {
                    tooltipMessage = 'Please enable the Time Tracker';
                } else if (userRole === 'Admin') {
                    tooltipMessage = "Please create classification, cost code, time type and billing to's configurations";
                } else {
                    tooltipMessage = 'Please contact admin to setup configurations';
                }

                setTooltip(tooltipMessage);
            }

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleUpdateTab = (tab) => {
        dispatch({ type: "UPDATE", tab: tab });
    };

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };

    const handleShowExport = () => {
        setShowExport(true);
    }

    const handleCloseExport = () => {
        setShowExport(false);
        setCloseExport(true);
    }

    async function groupDetail() {

        const groupDetails = await axios.get(`${Application.api_url}/api/v2/common/groups`, {});


        if (groupDetails.status == 200 && groupDetails.data.success === true) {
            var details = groupDetails.data;
            setGroupsList(details.data);
        }
    }

    async function userDetail() {

        const userDetails = await axios.get(`${Application.api_url}/api/v2/common/users`, { params: { time_cards: true }});


        if (userDetails.status == 200 && userDetails.data.success === true) {
            var details = userDetails.data;
            setUsersList(details.data);
        }
    }

    const close = () => {
        setShow(false)
    };

    const openUpdatePlanModal = async () => {
        openUpdateModalHandler(true)
    }

    const searchFilter = (data) => {
        setSearch(data);
    };

    const handleCreateTimeCard = () => {
        if (createTimeCard) {
            setShow('true')
        }
    }

    return (
        <div className="inventory">
            <div className="inventory-pages">
                <div className="container-new">
                    <div className="header">
                        <div className="title-tabs">
                            <div className="title-create-btn-group">
                                <h4>
                                    Time Tracking
                                    &nbsp;
                                    <span className="badge badge-danger">{ User.currentInventoryPlan }</span>
                                    <a href="#" onClick={() => {openUpdatePlanModal()}}><span className="" style={{fontSize:'12px', marginLeft:'10px'}}>Upgrade now?</span></a>

                                </h4>
                                <div className="btn-col">
                                    <button className="create" style={{cursor:'pointer'}}
                                        onClick={(e) => {
                                            history.push("/time_tracking/configuration");
                                        }}
                                    >
                                        <div style={{fontSize:'14px'}}>
                                            <img src={Settings} alt="" />
                                            Configuration
                                        </div>

                                    </button>
                                    <button
                                        className="export-btn"
                                        id="export-btn"
                                        onClick={() => handleShowExport(true)}
                                    >
                                        Export
                                    </button>
                                    <div
                                        className="button-container2"
                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}
                                    >
                                        <button
                                            className="time-card-create-btn create"
                                            id="create"
                                            onClick={() => {
                                                handleCreateTimeCard()
                                            }}
                                            disabled={isDisabled}
                                            title={tooltip}
                                        >
                                            + Create Time Card
                                        </button>
                                        {isDisabled && tooltip && !createTimeCard && isHovered && (
                                            <div className="custom-tooltip">{tooltip}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`tabs-list ${settings ? "show" : ""}`}>
                        <div
                            className="tab-content"
                            style={{ display: activeTab === 1 ? "block" : "none" }}
                        >
                            {tabs.inventoryList && (
                                <Active
                                    refresh={refresh}
                                    searching={(data) => searchFilter(data)}
                                    showSettings={(data) => setSettings(data)}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {show &&
                <AddTimeCard show={show} close={close} groupsList={groupsList} usersList={usersList}
                             rightSideGroups={selectedGroups} rightSideUsers={selectedUsers} />
            }
            {showExport &&
                <ExportTimecardsModal show={showExport} handleClose={handleCloseExport} groups={groupsList} users={usersList} costCodes={costCodes} size="lg" centered />}
        </div>
    );
};

export default withRouter(TimeTrackingList);
