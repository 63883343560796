import React from 'react';
// This will be loading all the components for ROT pages..
import * as Components from '../../components';

let axios = require('axios');

const initialState = {
    filters: {employees: [], topics: [], training_status: []},
    summary: {total_employees: 0, buckets_info: { 30: {total_count: 0, trainings: []}, 60: {total_count: 0, trainings: []}, 90: {total_count: 0, trainings: []}, expired: {total_count: 0, trainings: []}}},
    trainings: [],
    archived_trainings: [],
    fields: { employee:'', topic:'', training_status: '', search: true, bucket: '' },
    filter_applied: false,
}

class ROT extends React.Component{
    state = initialState

    componentDidMount() {
        $(".loading").show()
        this.load_trainings_data({}).then((r) => $(".loading").hide());
    }

    load_trainings_data = async (params) => {
        
        const response = await axios.get("/record_of_trainings", {
            params: params,
        }).catch(err => {$('.loading').hide(); alert('Unable to fetch trainings at the moment')});
        let data = response.data
        
        console.log(data)
        let result = this.setResponseData(data, params)
        this.setState(result)
    };

    setResponseData = (data, params) => {
        let result = { filters: data.filters, summary: data.summary, trainings: data.trainings,
            filter_applied: data.filter_applied, archived_trainings: data.archived_trainings }


        if (Object.keys(params).length = 0){
            result['fields'] = { employee:'', topic:'', training_status: '', search: true, bucket: '' }
        }else{
            result['fields'] = params
        }
        return result;
    }

    onFilterSelect = (input, obj) => {
        if (input == ""){
            let fields = { employee:'', topic:'', training_status: '', search: '', bucket: '' }
            this.setState({fields: fields});
            this.load_trainings_data(fields).then((r) => console.log(r));
        }
        else{
            let fields = this.state.fields;
            fields[input] = obj.value ? obj.value: obj ;
            fields['search'] = true;
            this.setState({fields});
            this.load_trainings_data(fields).then((r) => console.log(r));
        }
    };

    render() {
        const { filters, summary, trainings, params, is_admin, filter_applied, archived_trainings } = this.state;
        const company_name= "NBL"

        return (
            <div className="msmcontainer">
                <div className="row d-flex">

                    <Components.ROTFilters  filters= {filters} is_admin= {is_admin} onFilterSelect={this.onFilterSelect}/>

                    <div className="col-sm-12 content-wrap">
                        <div className="content">

                            <div className="container-fluid">
                                <Components.ROTOverview
                                    summary={summary} params={params}
                                    filter_applied={filter_applied}
                                    onFilterSelect={this.onFilterSelect} 
                                    filters= {filters} />
                                <br/>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2>Employee Information</h2>
                                        <Components.ROTTrainingData data={trainings}/>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-12" style={{ marginBottom: "20px" }}>
                                        <h2>Archived Trainings</h2>
                                        <Components.ROTTrainingData data={archived_trainings}/>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default ROT;

// import React from 'react';
// // This will be loading all the components for ROT pages..
// import * as Components from '../../components';
//
// var axios = require('axios');
//
// const initialState = {
//     summary: {},
//     client: "",
//     result:[],
//     expired_employees:[],
//     audit_types:[],
//     owners:[],
//     projects: [],
//     fields: {template_id:'',project:'',auditor:''}
// };
//
// const initialState = {
//     filters: {},
//     summary: {},
//     trainings: [],
//
// }
//
// class ROT extends React.Component{
//     constructor(props)
//     {
//         super(props);
//         this.state = initialState
//         this.handleChange = this.handleChange.bind(this);
//         this.commonfilter = this.commonfilter.bind(this);
//         // this.resetfilter = this.resetfilter.bind(this);
//         this.onFilterChange = this.onFilterChange.bind(this);
//     }
//
//     componentDidMount() {
//         var that = this;
//         that.populateData()
//     }
//
//     componentDidUpdate(prevProps) {
//         window.scrollTo(0,0);
//     }
//     populateData(){
//         var that = this;
//         $(".loading").show()
//         axios.get('/dashboard/rot_overview',{
//         })
//         .then(function(response){
//             $(".loading").hide()
//             that.renderChartData(response.data, that)
//
//         })
//         .catch(function(error){
//             console.log(error);
//         })
//     }
//
//     onFilterChange(val, that) {
//         var data = that.state ? that.state.fields : that
//
//         var current = this
//         var filter_params = {
//             company : data['company'],
//             employee_id : data['employee_id'],
//             employee_name : data['employee_name'],
//             training_type : data['training_type'],
//             training_topic : data['training_topic'],
//             training_status : data['training_status'],
//             project : data['project'],
//             drill_down: data['drill_down'],
//             chart_type: data['chart_type'],
//             expiring_filter: data['expiring_filter']
//         }
//         $(".loading").show()
//         axios.get('/dashboard/rot_overview', {
//             params: filter_params
//         })
//         .then(function (response) {
//             console.log("Before updating the value of the data....")
//             console.log(response.data.summary)
//             $(".loading").hide()
//             current.renderChartData(response.data, current)
//
//         })
//         .catch(function (error) {
//             console.log(error);
//         });
//     }
//
//     commonfilter(){
//         var that = this;
//         var filter_params = {
//             template_id : that.state.fields['template_id'],
//             project : that.state.fields['project'],
//             auditor : that.state.fields['auditor'],
//             audit_type: 'Audit'
//         }
//         $(".loading").show()
//         axios.get('/dashboard/rot_overview', {
//             params: filter_params
//         })
//             .then(function (response) {
//                 console.log(response);
//                 $(".loading").hide()
//                 that.renderChartData(response.data, that)
//             })
//             .catch(function (error) {
//                 console.log(error);
//             });
//
//     }
//
//     handleChange(field, e){
//         let fields = this.state.fields;
//         fields[field] = e.target.value;
//         this.setState({fields});
//         this.commonfilter()
//     }
//
//     renderChartData(response, that){
//         that.setState({
//             expired_employees: response.expired_employees,
//             filters: response.filters,
//             summary: response.summary,
//             result: response.emp_training_data,
//             graph: {projects: response.projects_graph, trainings: response.emp_training_graph, element_trainings: response.element_training_graph},
//             employees_info: response.emp_training_data,
//             graph_title: response.drilldown_graph,
//             params: response.params,
//             is_admin: response.is_admin
//
//         });
//     }
//
//     updateChartData(params){
//         var that = this;
//         $(".loading").show()
//         axios.get('/dashboard/rot_overview', {
//             params: params
//         })
//             .then(function (response) {
//                 console.log(response);
//                 $(".loading").hide()
//                 that.renderChartData(response.data, that)
//             })
//             .catch(function (error) {
//                 console.log(error);
//             });
//     }
//
// render() {
//     const { filters, summary, graph, expired_employees, employees_info, graph_title, params, is_admin } = this.state;
//     const company_name= "NBL"
//
//     return (
//         <div className="msmcontainer">
//             <div className="row">
//
//                 <Components.ROTFilters  filters= {filters} is_admin= {is_admin} onFilterChange={this.onFilterChange}/>
//
//                 <div className="col-sm-10">
//                     <div className="content">
//
//                         <div className="container-fluid">
//                             <Components.ROTOverview
//                                 summary={summary} params={params} filters= {filters} expired_employees={expired_employees}
//                                 onFilterChange={this.onFilterChange} />
//                             <br/>
//
//
//                                 {/*<Components.ROTGraphs params={params} graph= {graph} onFilterChange={this.onFilterChange}/>*/}
//                                 {/*<br/>*/}
//                                 {/*<Components.TrainingGraph params={params}  graph= {graph} onFilterChange={this.onFilterChange} />*/}
//
//                                 <div className="row">
//                                     <div className="col-lg-12 side-nav">
//                                         <h2>Employee Information</h2>
//                                         <Components.ROTTrainingData data={this.state.result}/>
//                                     </div>
//                                 </div>
//
//                             </div>
//
//                      </div>
//                 </div>
//
//
//
//             </div>
//         </div>
//
//         )
//         }
//         }
//
// export default ROT;
