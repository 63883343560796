import React, { useEffect, useState,useRef } from "react";
import { NavLink } from "react-router-dom";
import Application from "../../../config/ApplicationWrapper";
import Cookies from "js-cookie";
import Pagination from "@mui/material/Pagination";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import Filter from "../../../assets/images/Filter.png";
import CreateAssetType from "./Popup/create";

const cookieDetails = Cookies.get("token");

export default function ActiveAsset({ updatedDate, totalCount, setUpdateDate }) {
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [pageCount, setPageCount] = React.useState(0);
  let [assetDetails, setAssetDetails] = React.useState([]);
  const [paginationStatus, setPaginationStatus] = React.useState(false);
  const [searchByName, setSearchByName] = React.useState("");
  const [createPopup, setCreatePopup] = useState(false);
  const [editData, setEditData] = useState();
  const [lastUpdate, setLastUpdate] = useState(0);
  const [sortDirection, setSortDirection] = useState("desc");
  const nameRef = useRef();

  useEffect(() => {
    const getPageDetails = async () => {
      try {
        window.scrollTo(0, 0);

        var url = new URL(
          `${Application.api_url
          }/api/v2/asset_types?archived_true=${false}&page=${page}&limit=${limit}&name_cont=${searchByName}&sort=name ${sortDirection}`
        );

        const res = await fetch(url, {
          method: "GET",
          headers: { Authorization: cookieDetails },
        });
        const resultObj = await res.json();

        setAssetDetails(resultObj && resultObj.data ? resultObj.data : []);
        setPageCount(
          resultObj && resultObj.metadata ? resultObj.metadata.pages : 0
        );
        totalCount(resultObj && resultObj.metadata ? resultObj.metadata.count : 0);
        setPaginationStatus(true);
      } catch (err) {
        console.log("error==>", err);
      }
    };

    getPageDetails();
  }, [page, limit, searchByName, updatedDate, lastUpdate, sortDirection]);

  const archiveAssetType = async (id) => {
    try {
      setPage(1);
      setPaginationStatus(false);
      await fetch(
        `${Application.api_url}/api/v2/asset_types/${id}/archive`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: cookieDetails,
          },
        }
      );
      toast.success("Successfully archived");
      setUpdateDate(Date.now())
    } catch (err) {
      console.log("error===>", err);
    }
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const openDocPopup = (data) => {
    setCreatePopup(true);
    if (data) {
      setEditData(data);
    }
  };

  const closePopup = (data) => {
    setCreatePopup(false);
    if (data) {
      toast.success("Updated successfully");
      setLastUpdate(Date.now());
    }
  };

  const sorting = () => {
    if (sortDirection == "desc") {
      setSortDirection("asc")
      nameRef.current.style.transform = `rotate(180deg)`;
    } else {
      setSortDirection("desc")
      nameRef.current.style.transform = `rotate(0deg)`;
    }
  }

  return (
    <div className="nestedTable">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col" className="risk-assessment-divider">
              <div
                className="d-flex justify-content-between"
                onClick={(e) => {
                  sorting()
                }}
              >
                Asset Type
                <img src={Filter} alt="" ref={nameRef}  />
              </div>
              <div class="input-group" style={{ width: "100%" }}>
                <input
                  type="text"
                  class="form-control assessment-name"
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  name="name"
                  onChange={(e) => {
                    setSearchByName(e.target.value);
                  }}
                />
                <div class="input-group-addon">
                  <span class="input-group-text" id="basic-addon1">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            </th>
            <th scope="col" style={{ textAlign: "center" }}>
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {assetDetails &&
            assetDetails.length > 0 &&
            assetDetails.map((val, index) => (
              <tr key={index}>
                <td style={{ textAlign: "left" }}>{val.name}</td>
                <td
                  className="template-options"
                  style={{ textAlign: "center" }}
                >
                  <a
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="btn dropdown-toggle"
                    data-toggle="dropdown"
                    id="dropdownMenuLink"
                    role="button"
                  >
                    <i class="fa fa-ellipsis-v"></i>
                  </a>
                  <ul aria-labelledby="dropdownMenuLink" class="dropdown-menu">
                    <li>
                      <a
                        class="archive_group_invites"
                        data-toggle="tooltip"
                        title="Edit RA"
                        onClick={() => {
                          openDocPopup(val);
                        }}
                      >
                        <div class="row" style={{ display: "flex" }}>
                          <div class="col-sm-3">
                            <i
                              class="fa fa-pencil-square-o"
                              style={{
                                paddingTop: "5px",
                                fontSize: "16px",
                              }}
                            ></i>
                          </div>
                          <div class="col-sm-9" style={{ paddingTop: "5px" }}>
                            Edit
                          </div>
                        </div>
                      </a>
                      <a
                        class="archive_group_invites"
                        data-toggle="tooltip"
                        title="Archive"
                        onClick={(e) => {
                          archiveAssetType(val.id);
                        }}
                      >
                        <div class="row" style={{ display: "flex" }}>
                          <div class="col-sm-3">
                            <i
                              class="fa fa-trash"
                              style={{
                                paddingTop: "5px",
                                fontSize: "16px",
                                color: "#FF3030",
                              }}
                            ></i>
                          </div>
                          <div
                            class="col-sm-9"
                            style={{
                              paddingTop: "5px",
                              color: "#FF3030",
                            }}
                          >
                            Archive
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div
        className="d-flex justify-content-between"
        style={{ alignItems: "center" }}
      >
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            value={limit}
            onChange={(e) => {
              setLimit(e.target.value);
            }}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={30}>30</MenuItem>
          </Select>
        </FormControl>
        {paginationStatus && (
          <Pagination
            count={pageCount}
            variant="outlined"
            shape="rounded"
            onChange={handleChangePage}
          />
        )}
      </div>
      <CreateAssetType
        show={createPopup}
        closepopup={closePopup}
        data={editData}
      />
    </div>
  );
}
