import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Button, TextField, Autocomplete } from "@mui/material";

import axios from "config/axiosConfig";
import Application from "config/ApplicationWrapper";
import { toast } from "react-toastify";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker, PickersPopper } from "@mui/x-date-pickers/DateTimePicker";
import { User } from "pages/HomePage/Login";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { MentionsInput, Mention } from 'react-mentions'
dayjs.extend(utc);
dayjs.extend(timezone);

export default function TodoForm(props) {
  const { show, closepopup, mode, selectedTodoId } = props;
  const [todo, setTodo] = useState({});
  const [mentionedUsers, setMentionedUsers] = useState([]); // [{id: 1, full_name: 'abc'}
  const [onSubmit, setSubmit] = useState(false);
  const [users, setUsers] = useState([]);
  const [forms, setForms] = useState([]);
  const [name, setName] = useState("");
  const [content, setContent] = useState("");
  const [dueDate, setDueDate] = useState(null);

  useEffect(() => {
    if (show) {
      getUsers();
      if (mode === "view" || mode === "edit") {
        getTodoDetails();
      } else {
        resetTodo();
      }
    }
  }, [show]);

  useEffect(() => {
    setTodo({
      ...todo,
      name,
      content,
      due_date: dueDate
        ? dueDate
        : null,
      mentioned_user_ids: mentionedUsers.map((user) => user.id),
    });
  }, [name, content, dueDate, mentionedUsers]);

  const resetTodo = () => {
    setName("");
    setContent("");
    setDueDate(null);
    setMentionedUsers([]);
  };

  const onhandleStatusClose = (data) => {
    resetTodo();
    closepopup(data);
  };

  const handleRequest = async (method, url, data) => {
    setSubmit(true);
    if (!data.name) {
      return false;
    }
    const result = await axios({
      method,
      url,
      data,
    });
    if (result.data && result.data.success === true) {
      toast.success(
        `${method === "POST" ? "Created" : "Updated"} Successfully`
      );
      setSubmit(false);
      onhandleStatusClose(result.data);
    }
  };

  const create = () => {
    handleRequest("POST", `${Application.api_url}/api/todos`, todo);
  };

  const update = () => {
    handleRequest(
      "PATCH",
      `${Application.api_url}/api/todos/${selectedTodoId}`,
      todo
    );
  };

  const getTodoDetails = () => {
    axios
      .get(`${Application.api_url}/api/todos/${selectedTodoId}`)
      .then((res) => {
        const todoItem = res.data.data;
        setName(todoItem.name);
        setContent(todoItem.content);
        setDueDate(todoItem.due_date ? new Date(todoItem.due_date).toJSON().slice(0, 10) : "");
        setMentionedUsers(mapUsers(todoItem.mentioned_users));
      });
  };

  const getUsers = () => {
    
    axios.get(`${Application.api_url}/api/todos/user_list`).then((res) => {
      setUsers(mapUsers(res.data.data));
    });

    axios.get(`${Application.api_url}/api/v2/audits/audit_details`).then((res) => {
      setForms(mapUsers(res.data.data));
    });
  };

  const mapUsers = (users) => {
    return users.map((user) => ({
      id: user.id,
      display: user.full_name || user.form_name,
    }));
  };

  const MyPopper = (props) => <PickersPopper {...props} />;


  return (
    <Modal
      show={show}
      onHide={(e) => {
        onhandleStatusClose();
      }}
      className="share-modal pf-model todo-view"
      style={{ zIndex: 1050 }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="pf-model-header">
            <p className="pf-model-title" style={{fontFamily:"Lato"}}>
              {" "}
              {mode === "view"
                ? "View"
                : mode === "edit"
                  ? "Edit"
                  : "Create"}{" "}
              TO DO
            </p>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="row create-form">
            <div className="col-md-12">
              <label className="form-lables">Title
                <span style={{ color: "red" }}>*</span>
              </label>
              <TextField
                fullWidth
                required
                value={name}
                error={!name && onSubmit === true ? true : false}
                helperText={
                  !name && onSubmit === true
                    ? "Title is required"
                    : ""
                }
                onChange={(e) => { setSubmit(false); setName(e.target.value) }}
                disabled={mode === "view"}
              />
            </div>
            <div className="col-md-12 mt-10">
              <label className="form-lables">Description
              </label>
              <MentionsInput value={content} onChange={(e) => setContent(e.target.value)} style={{
                control: {
                  backgroundColor: '#fff',
                  fontSize: 14,
                  fontWeight: 'normal',
                },

                '&multiLine': {
                  control: {
                    fontFamily: 'monospace',
                    minHeight: 63,
                  },
                  highlighter: {
                    padding: 9,
                    border: '1px solid transparent',
                  },
                  input: {
                    padding: 9,
                    border: '1px solid silver',
                  },
                },

                '&singleLine': {
                  display: 'inline-block',
                  width: 180,

                  highlighter: {
                    padding: 1,
                    border: '2px inset transparent',
                  },
                  input: {
                    padding: 1,
                    border: '2px inset',
                  },
                },

                suggestions: {
                  list: {
                    backgroundColor: 'white',
                    border: '1px solid rgba(0,0,0,0.15)',
                    fontSize: 14,
                    overflow: "auto",
                    maxHeight: "230px"
                  },
                  item: {
                    padding: '5px 15px',
                    borderBottom: '1px solid rgba(0,0,0,0.15)',
                    '&focused': {
                      backgroundColor: '#cee4e5',
                    },
                  },
                },
              }}>
                <Mention
                  trigger="@"
                  data={users}
                  displayTransform={(id, display) => `@${display}`}
                  markup={'@[__display__](__id__)'}
                  appendSpaceOnAdd
                  renderSuggestion={(
                    suggestion,
                    search,
                    highlightedDisplay,
                    index,
                    focused
                  ) => (
                    <div className="mention-list">
                      {highlightedDisplay}
                    </div>
                  )}
                  style={{
                    position: "relative",
                    'z-index': "1",
                    'color': "#1a8cff",
                    'text-shadow': "1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,-1px -1px 1px white",
                    "pointer-events": "none",
                  }}
                />
                <Mention
                  trigger="#"
                  data={forms}
                  displayTransform={(id, display) => `#${display}`}
                  markup={'#[__display__](__id__)'}
                  appendSpaceOnAdd
                  renderSuggestion={(
                    suggestion,
                    search,
                    highlightedDisplay,
                    index,
                    focused
                  ) => (
                    <div className="mention-list">
                      {highlightedDisplay}
                    </div>
                  )}
                  style={{
                    position: "relative",
                    'z-index': "1",
                    'color': "#bf8040",
                    'text-shadow': "1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,-1px -1px 1px white",
                    "pointer-events": "none",
                  }}
                />
              </MentionsInput>
              Use "@" to tag a user in the system, or use "#" to tag a form.
              <br />
            </div>
            <div className="col-md-12 mt-10">
              <label className="form-lables">Due Date
              </label>
              <input
                type="date"
                class="date-field"
                id="last_scheduled_at"
                placeholder="Search"
                aria-label="Username"
                aria-describedby="basic-addon1"
                name="last_scheduled_at"
                data-date="MM DD YYYY"
                data-date-format="MM DD YYYY"
                value={dueDate}
                disabled={mode === "view"}
                style={{
                  width: "100%",
                  height: "42px",
                  padding: "10px"
                }}
                onChange={(e) => {
                  setDueDate(e.target.value);
                }}
              />
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  ampm={false}
                  slotProps={{ textField: { fullWidth: true }, popper: MyPopper }}
                  value={dueDate}
                  timezone={User.companyInfo.timezone}
                  onChange={(date) => setDueDate(date)}
                  disabled={mode === "view"}
                />
              </LocalizationProvider> */}
            </div>
            {(User.role === "Admin" || User.role === "Manager") && (
              <div className="col-md-12 mt-10">
                <Autocomplete
                  multiple
                  id="tags-users"
                  options={users}
                  disablePortal={true}
                  value={mentionedUsers}
                  disabled={mode === "view"}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  disableCloseOnSelect={true}
                  onChange={(_event, value) => setMentionedUsers(value)}
                  getOptionLabel={(user) => user.display}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Mentioned Users"
                      placeholder="Select Users"
                      className={mode == "view" ? "disable-delect-icon" : ""}
                    />
                  )}
                />
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="submit-btns pr-0">
          <Button
            className="btn-cancel"
            onClick={(e) => {
              onhandleStatusClose();
            }}
          >
            Cancel
          </Button>
          {mode === "edit" || mode === "create" ? (
            <Button
              variant="contained"
              onClick={() => {
                mode === "edit" ? update() : create();
              }}
              disabled={onSubmit}
            >
              {mode === "edit" ? "Update" : "Create"}
            </Button>
          ) : null}
        </div>
      </Modal.Footer>
    </Modal>
  );
}
