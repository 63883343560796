import React, { Fragment } from "react";
import Highcharts from 'highcharts'
import Chart from "../../components/Chart";
import HighchartsReact from 'highcharts-react-official'
// import exporting from "highcharts/modules/exporting";
// exporting(Highcharts);

const PieChart = ({stats, query, onFilterSelect, error}) => {
    const data = Object.keys(stats)

    const handleChange = (event, data) => {
        let params = { ['template_id']: event.template_id, ['chart_type']: 'form_types',  ['hourly_chart']: '', ['date']: ''}
        onFilterSelect(Object.assign({}, query, params))
    };

    const options =
        {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            title: {
                text: 'Form Types'
            },
            tooltip: {
                pointFormat: "<span style='font-size:45px;color:green'><b>{point.form_types} / {point.percentage:.1f}%</span>"
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                series: {
                    cursor: "pointer",
                    point: {
                        events: {
                            click: function (e) {
                                handleChange(this, query)
                            }
                        }
                    }
                },
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    colors: [
                        "#639dcf",
                        "#306a9c",
                        "#18344E",
                        "#132A3E",
                        "#639dcf",
                        "#306a9c",
                        "#18344E",
                        "#132A3E",
                        "#639dcf",
                        "#306a9c",
                        "#18344E",
                        "#132A3E",
                        "#133e27"
                    ],
                    dataLabels: {
                        enabled: true,
                        format: "<b>{point.name}",
                    }
                }
            },
            series: [{
                name: '',
                colorByPoint: true,
                data: stats
            }],
        }

    return(
        <Fragment>
            <Chart options={options} type={'chart'} highcharts={Highcharts} />
        </Fragment>

    )
}

export default PieChart;
