import React from 'react';
import HeroSection from './CommonComponents/HeroSection';
import FeaturesSection from './CommonComponents/FeaturesSection';
import ParallaxSection from '../../pages/HomePage/Components/ParallaxSection';
import HomepageDemoSection from '../../pages/HomePage/Components/HomepageDemoSection';

export default function FlaggedUserManagement() {
    return (
        <>
            <HeroSection
                heroText={"Flagged User Management"}
                subText={"Manage flagged users to ensure compliance and safety across your organization."}
            />
            <FeaturesSection
                headerText={"Identify and Track Flagged Users"}
                subText={"Monitor and track flagged users to maintain safety and compliance."}
                featureHighlights={[
                    "Identify users flagged for non-compliance.",
                    "Monitor flagged user behavior and activity.",
                    "Set up alerts for flagged user actions.",
                    "Generate reports on flagged user status."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for Flagged User Management section here
            />
            <FeaturesSection
                headerText={"Automated Flagged User Alerts"}
                subText={"Receive real-time alerts and notifications for flagged user activities."}
                featureHighlights={[
                    "Set up alerts for flagged user behavior.",
                    "Monitor user actions and compliance status.",
                    "Receive automated alerts for non-compliance."
                ]}
                // Placeholder: Add image for Automated Alerts section here
            />
            <FeaturesSection
                headerText={"Flagged User Reporting"}
                subText={"Generate detailed reports on flagged users for audits and safety management."}
                featureHighlights={[
                    "Track flagged user status over time.",
                    "Generate compliance-ready reports.",
                    "Easily share flagged user status with stakeholders."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for Flagged User Reporting section here
            />
            <div className="Home-Page sl-home-wrapper" style={{ paddingTop: 0 }}>
                <ParallaxSection />
            </div>
            <div className="features-homepage-wrapper">
                <HomepageDemoSection mergeTop={false} />
            </div>
        </>
    );
}
