import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import configureStore from '../config/configureStore';
import { Provider } from 'react-redux'
import App from '../pages/App';

const store = configureStore();
const rootElement = document.getElementById('app')

const renderApp = Component => {
    ReactDOM.render(
        <Provider store={store}>
            <BrowserRouter>
                <Component />
            </BrowserRouter>
        </Provider>,
        rootElement
    );
};

renderApp(App);
