import React, { useEffect, useState } from "react";
import axios from "../../../../config/axiosConfig";
import { toast } from "react-toastify";
import { TableContainer, Paper, Pagination, MenuItem, FormControl, Select, Switch, Button } from '@mui/material';
import Application from "../../../../config/ApplicationWrapper";
import Loader from "../../../../components/NewLoader";
import EditIcon from "../../../../assets/fonts/edit.svg";
import AddCostCode from "../popup/AddCostCode";


const CostCode = ({ id }) => {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(30);
    const [pageCount, setPageCount] = useState(0);
    const [pageDetails, setPageDetails] = useState([]);
    const [costCode, setSearchByCostCode] = useState();
    const [show, setShow] = useState(false);
    const [showDetails, setShowDetails] = useState();
    const [loading, setLoading] = useState(true);
    const [searchable, setSearchable] = useState(false);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getPageDetails()
        }, 2000)

        return () => clearTimeout(delayDebounceFn)
    }, [costCode, limit])

    const getPageDetails = async () => {
        try {
            setLoading(true)
            let resultObj = await axios.get(`${Application.api_url}/api/v2/time_trackers/cost_codes`, {
                params: {
                    page: page,
                    limit: limit,
                    cost_code_cont: costCode,
                },
            });
            if (resultObj.status == 200) {
                let details = resultObj.data;
                setPageDetails(details ? details.data : [])
                setPageCount(
                    details && details.metadata ? details.metadata.pages : 0
                );
                setSearchable(details && details.data.length > 0 ? true : false)
                setLoading(false)
            }
        } catch (err) {
            console.log("error==>", err);
        }
    };

    const handleChangePage = (event, value) => {
        setPage(value);
        getPageDetails()
    };

    const handleLimitChange = (value) => {
        setLoading(true);
        setPage(1);
        setLimit(value);
    }

    const clearFilter = () => {
        setLoading(true);
        setSearchByCostCode("");
    }

    const open = (value) => {
        setShow(true)
        setShowDetails(value)
    }

    const close = (data) => {
        setShow(false)
        setShowDetails("")
        data && getPageDetails()
    }

    const archive = async (value) => {
        value.activated = value.archived
        var action = value.archived ? 'unarchive' : 'archive'
        await axios.patch(`${Application.api_url}/api/v2/time_trackers/cost_codes/${value.id}/${action}`, value).then((result) => {
            if (result.data && result.data.success === true) {
                toast.success(!value.archived ? `Deactivated Successfully` : "Activated Successfully")
                getPageDetails()
            }
        }).catch((error) => {
            console.log("error===>", error)
        })
    }

    return (
        <>
            <div class="card asset-tab-landing">
                <div class="card-body">
                    <div class="tab-content">
                        <div
                            id="active-risk-assessment"
                            class="tab-pane fade in active active-risk-assessment internal-sub-employee"
                        >
                            <div className="nestedTable pf-table">
                                <div className="fields-setting">
                                    <div className="asset-active-filters">
                                        Active Filters :
                                        <span className="active-filters">
                                            <span>Cost Codes :</span>
                                            <span className="selected">{costCode ? costCode : "All"}</span>
                                            <span className={costCode ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setSearchByCostCode("") }}>X</span>
                                        </span>
                                        <span className="active-filters-clear" onClick={(e) => clearFilter()}>
                                            Clear All
                                        </span>
                                    </div>
                                    <div className="bulk-action">
                                        <Button variant="contained" className="btn-create" onClick={(e) => { setShow(true) }}>+ Add Cost Code</Button>
                                    </div>
                                </div>
                                <TableContainer component={Paper}>
                                    <table class="table table-striped">
                                        <thead>
                                        <tr style={{ height: "100px" }}>
                                            <th scope="col" className="risk-assessment-divider">
                                                <div className="d-flex justify-content-between align-item-center">
                                                    Cost Code
                                                </div>
                                                {searchable &&
                                                    <div class="input-group">
                                                        <input
                                                            type="text"
                                                            class="form-control assessment-name"
                                                            placeholder="Search"
                                                            aria-label="Cost Code"
                                                            id="tt_cost_code"
                                                            aria-describedby="basic-addon1"
                                                            name="tt_cost_code"
                                                            onChange={(e) => {
                                                                setLoading(true);
                                                                setSearchByCostCode(e.target.value)
                                                            }}
                                                            value={costCode}
                                                        />
                                                        <div class="input-group-addon">
                                                                <span class="input-group-text" id="basic-addon1">
                                                                    <i class="fa fa-search" aria-hidden="true"></i>
                                                                </span>
                                                        </div>
                                                    </div>
                                                }
                                            </th>
                                            <th scope="col" className="table-action">Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {!loading && pageDetails &&
                                            pageDetails.length > 0 &&
                                            pageDetails.map((val, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            {val.cost_code}
                                                        </td>
                                                        <td className="template-options" style={{ textAlign: "center" }}>
                                                            <img src={EditIcon} alt="edit" onClick={() => {
                                                                setShowDetails(val);
                                                                setShow(true)
                                                            }}/>
                                                            <Switch
                                                                checked={!val.archived}
                                                                onClick={(event) => archive(val)}
                                                            />
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        <Loader pageDetails={pageDetails} loading={loading} btnName={"+ Add Cost Code"} open={open} />
                                        </tbody>
                                    </table>
                                </TableContainer>
                                <div className="page-limits">
                                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                        <Select
                                            labelId="demo-select-small"
                                            id="demo-select-small"
                                            value={limit}
                                            onChange={(e) => {
                                                handleLimitChange(e.target.value);
                                            }}
                                        >
                                            <MenuItem value={5}>5</MenuItem>
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={20}>20</MenuItem>
                                            <MenuItem value={30}>30</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {!loading && (
                                        <Pagination
                                            count={pageCount}
                                            page={page}
                                            variant="outlined"
                                            shape="rounded"
                                            onChange={handleChangePage}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {show &&
                <AddCostCode show={show} close={close} details={showDetails} />
            }
        </>
    );
}

export default CostCode;
