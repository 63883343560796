import React, { useEffect, useRef } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import {
    Grid,
    TextField,
    FormControl,
    Typography,
    FormControlLabel,
    Switch,
    Box,
    Select,
    MenuItem,
    Checkbox,
} from '@mui/material';
import DualListbox from '../../../shared/components/dualListBox';
import UploadFile from '../../../shared/components/uploadFile';
import TagInput from '../../../shared/components/tagInput';

export const NewGroupForm = ({ register, errors, control, options, logoDetails }) => {
    const isAddress = useWatch({control: control, name: 'address_enabled', defaultValue: false});
    const {adminUsers, timeZones, states, parentGroups} = options || {};
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <FormControl fullWidth>
                        <Box mb={1}>
                            <Typography variant="body1" className='f-label'>
                                Group Name <span style={{ color: 'red' }}>*</span>
                            </Typography>
                        </Box>
                        <TextField
                            placeholder="Enter Group Name"
                            size="small"
                            {...register('name', { required: 'Group Name is required' })}
                            error={!!errors.name}
                            helperText={errors.name ? errors.name.message : ''}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormControl fullWidth>
                        <Box mb={1}>
                            <Typography variant="body1" className='f-label'>
                                Description
                            </Typography>
                        </Box>
                        <TextField
                            placeholder="Enter Description"
                            size="small"
                            multiline
                            rows={4}
                            {...register('description')}
                            error={!!errors.description}
                            helperText={errors.description ? errors.description.message : ''}
                        />
                    </FormControl>
                </Grid>
               </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
                <UploadFile control={control} name="logo" existingFiles={logoDetails.file_name ? [logoDetails] : []} />
            </Grid>

            <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                    <Box mb={1}>
                        <Typography variant="body1" className='f-label'>Parent Group</Typography>
                    </Box>
                    <Controller
                        name="parent_id"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <Select size='small' {...field} displayEmpty>
                                <MenuItem value="">
                                    <em>Select Group</em>
                                </MenuItem>
                                {parentGroups.map(u => (
                                    <MenuItem value={u.value}>
                                        {u.key}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                    <Box mb={1}>
                        <Typography variant="body1" className='f-label'>Admin (Select one)</Typography>
                    </Box>
                    <Controller
                        name="admin_id"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <Select size='small' {...field} displayEmpty>
                                <MenuItem value="">
                                    <em>Select Admin</em>
                                </MenuItem>
                                {adminUsers.map(u => (
                                    <MenuItem value={u.value}>
                                        {u.key}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                    <Box mb={1}>
                        <Typography variant="body1" className='f-label'>
                            Time Zone
                        </Typography>
                    </Box>
                    <Controller
                        name="time_zone"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <Select size='small' {...field} displayEmpty>
                                <MenuItem value="">
                                    <em>Select Time Zone</em>
                                </MenuItem>
                                {timeZones.map(u => (
                                    <MenuItem value={u.value}>
                                        {u.key}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                    <Box mb={1}>
                        <Typography variant="body1" className='f-label'>
                            Job ID
                        </Typography>
                    </Box>
                    <TextField
                        size="small"
                        placeholder="Enter Job ID"
                        type="job_id"
                        {...register('job_id')}
                        error={!!errors.job_id}
                        helperText={errors.job_id ? errors.job_id.message : ''}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
                <div className='grey-box'>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <FormControl>
                                <Controller
                                    name="address_enabled"
                                    control={control}
                                    defaultValue={false} // Set default to false
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={value}
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    inputRef={ref}
                                                />
                                            }
                                            label="Address"
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        {!!isAddress && <><Grid item xs={12} sm={12}>
                            <FormControl fullWidth>
                                <Box mb={1}>
                                    <Typography variant="body1" className='f-label'>
                                        Address
                                    </Typography>
                                </Box>
                                <TextField
                                    size="small"
                                    placeholder="Enter Address"
                                    {...register('address')}
                                    error={!!errors.address}
                                    helperText={errors.address ? errors.address.message : ''}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <Box mb={1}>
                                    <Typography variant="body1" className='f-label'>
                                        City
                                    </Typography>
                                </Box>
                                <TextField
                                    size="small"
                                    placeholder="Enter City"
                                    {...register('city')}
                                    error={!!errors.city}
                                    helperText={errors.city ? errors.city.message : ''}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <Box mb={1}>
                                    <Typography variant="body1" className='f-label'>
                                        Province/State
                                    </Typography>
                                </Box>
                                <Controller
                                    name="province_or_state"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Select size='small' {...field} displayEmpty>
                                            <MenuItem value="">
                                                <em>Select State</em>
                                            </MenuItem>
                                            {states.map(u => (
                                                <MenuItem value={u.value}>
                                                    {u.key}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <Box mb={1}>
                                    <Typography variant="body1" className='f-label'>
                                        Postal Code
                                    </Typography>
                                </Box>
                                <TextField
                                    size="small"
                                    placeholder="Enter Postal Code"
                                    {...register('postal_code')}
                                    error={!!errors.postal_code}
                                    helperText={errors.postal_code ? errors.postal_code.message : ''}
                                />
                            </FormControl>
                        </Grid></>}
                    </Grid>
                </div>
            </Grid>
        </Grid>
    );
};

export const GroupAssignmentForm = ({ setValue, control, managerUsers, memberUsers, watch }) => {
    const elementRef = useRef(null);
    useEffect(() => {
        if (elementRef && elementRef.current) {
            elementRef.current.scrollIntoView();
        }
    }, [])
    return (
        <>
            <div ref={elementRef} />
            <Controller
                name="group_managers"
                control={control}
                render={({ field: { value = [] } }) => (
                    <DualListbox 
                        title="Managers"
                        control={control} 
                        setValue={setValue} 
                        name="group_managers" 
                        leftData={managerUsers.filter(m => value.every(val => val.value !== m.value))}
                        rightData={value}
                    />
                )}
            />
            <Controller
                name="group_members"
                control={control}
                render={({ field: { value } }) => (
                    <DualListbox 
                        title="Members"
                        control={control} 
                        setValue={setValue} 
                        name="group_members"
                        leftData={memberUsers.filter(m => value.every(val => val.value !== m.value))}
                        rightData={value}
                    />
                )}
            />
            <TagInput label="Email notification upon form completion" watch={watch} setValue={setValue} control={control} fieldName="group_notifies_field" stateName="group_notifies" />
        </>
    )
}

export const GlobalProjectForm = ({ setValue, control, companies, watch }) => {
    const elementRef = useRef(null);
    console.log(companies, 'companies');
    const isGlobalProject = useWatch({control: control, name: 'is_global_project', defaultValue: false});
    useEffect(() => {
        if (elementRef && elementRef.current) {
            elementRef.current.scrollIntoView();
        }
    }, [])
    return (
        <>
            <div ref={elementRef} />
            <div className='grey-box'>
                <FormControl>
                    <Controller
                        name="is_global_project"
                        control={control}
                        defaultValue={false} // Set default to false
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        inputRef={ref}
                                    />
                                }
                                label="Global Project"
                            />
                        )}
                    />
                </FormControl>
            </div>
            {!!isGlobalProject && 
            <>
                <Controller
                    name="group_companies"
                    control={control}
                    render={({ field: { value } }) => (
                        <DualListbox 
                            title="Companies"
                            control={control} 
                            setValue={setValue} 
                            name="group_companies"
                            leftData={companies.filter(c => value.every(val => val.value !== c.value))}
                            rightData={value}
                        />
                    )}
                />
                <TagInput label="Invites" watch={watch} setValue={setValue} control={control} fieldName="group_invites_field" stateName="group_invites" />
                <div className='grey-box'>
                    <Box display="flex" gap={2}>
                        <FormControl>
                        <Controller
                            name="allow_contractor_docs"
                            control={control}
                            render={({ field }) => (
                            <FormControlLabel
                                control={<Checkbox {...field} checked={field.value} />}
                                label="Allow sub-contractor docs upload"
                            />
                            )}
                        />
                        </FormControl>

                        <FormControl>
                        <Controller
                            name="share_company_docs"
                            control={control}
                            render={({ field }) => (
                            <FormControlLabel
                                control={<Checkbox {...field} checked={field.value} />}
                                label="Allow company docs to be shared"
                            />
                            )}
                        />
                        </FormControl>
                    </Box>
                </div>
            </>
            }
        </>
    )
}
