import React, {useEffect} from "react";
import { Modal, Button } from "react-bootstrap";
import Cookies from "js-cookie";
import Application from "../../../../config/ApplicationWrapper";
import { toast } from "react-toastify";
const cookieDetails = Cookies.get("token");

export default function Status(props) {
  const { show, closepopup, details } = props;
  const [data, setData] = React.useState();

  useEffect(()=>{
    setData(details.status)
  },[details])

  const onhandleStatusClose = () => {
    closepopup();
  };
  

  const update = async () => {
    try {
      await fetch(
        `${Application.api_url}/api/v2/asset_trackings/${details.identifier}/update_status`,
        {
          method: "PATCH",
          headers: {
            "content-type": "application/json",
            Authorization: cookieDetails,
          },
          body: JSON.stringify({
            status: data,
          }),
        }
      );
      toast.success("Updated successfully");
      closepopup(data);
    } catch (err) {
      console.log("error===>", err);
    }
  };

  return (
    <Modal
      show={show}
      centered={true}
      className="add-task-modal signature-popup periority-popup"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <b>Change Status </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="rat-details">
          <p className="asset-name">Asset Name</p>
          <p className="asset-detail">{details ? details.name : ""}</p>
        </div>
        <div className="periority-list">
          <select
            className="form-control risk-type"
            value={data}
            onChange={(e) => {
              setData(e.target.value);
            }}
          >
            <option value="Idle">Idle</option>
            <option value="Repair">Repair</option>
            <option value="In Use">In Use</option>
          </select>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn-secondary"
          onClick={(e) => {
            onhandleStatusClose();
          }}
        >
          Cancel
        </Button>
        <Button
          className="btn-primary"
          onClick={(e) => {
            update();
          }}
        >
          Change
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
