import React from 'react';
import useFetch from '../../../shared/components/userFetch';
import useModify from '../../../shared/components/userModify';
import NewJobs from './NewJobs';

const NewJobProfileEdit = ({edit, setEdit, setFilterState}) => {
    const {data, isLoading} = useFetch(`/api/v2/entity_management/job_profiles/${edit}`);
    const {mutate, isLoading: isMutating, error} = useModify('/api/v2/entity_management/job_profiles');
    if (isLoading) {
        return null;
    }
    return (
        <NewJobs mutate={mutate} open={!!edit} setOpen={setEdit} initialValues={data.data} setFilterState={setFilterState} isMutating={isMutating} />
    )
}

export default NewJobProfileEdit;
