import React from 'react';
import HeroSection from './CommonComponents/HeroSection';
import FeaturesSection from './CommonComponents/FeaturesSection';
import ParallaxSection from '../../pages/HomePage/Components/ParallaxSection';
import HomepageDemoSection from '../../pages/HomePage/Components/HomepageDemoSection';
import r1 from '../../../assets/images/rot1.png'
import r2 from '../../../assets/images/rot2.png'
import r3 from '../../../assets/images/rot3.png'

export default function TrainingCertificateManagement() {
  return (
    <>
      <HeroSection heroText={"Training Certificate Management"} subText={"Streamline training certificate tracking and compliance management with SafetyLoop. Ensure that your workforce stays up-to-date and certified with ease, enhancing your health and safety management system."} />
      <FeaturesSection
        headerText={"Effortless Records of Training"}
        subText={"SafetyLoop simplifies the way you manage training records, ensuring compliance and competence across your workforce."}
        featureHighlights={["Easily record and track training completion for all employees.", "Access comprehensive training histories at a glance.", "Receive automated reminders for upcoming training renewals.", "Generate reports for audits and compliance verification."]}
        sectionBackground='dark'
        sectionAlign="image-right"
        featureImageSrc={r1}
      />
      <FeaturesSection
        headerText={"Customizable Training Topics"}
        subText={"Tailor your training topics to address the specific needs of your organization with SafetyLoop. Ensure that your workforce receives the right knowledge and skills."}
        featureHighlights={["Create and customize training topics to match your industry and requirements.", "Assign relevant training topics to individuals or groups.", "Keep training materials up-to-date with version control.", "Monitor topic completion and competency development."]}
        featureImageSrc={r3}
      />
      <FeaturesSection
        headerText={"Streamlined Training Matrix"}
        subText={"SafetyLoop centralizes your training matrix, allowing you to plan, assign, and monitor training effortlessly."}
        featureHighlights={["Easily build and manage a comprehensive training matrix for your organization.", "Assign required training to job roles or departments.", "Track progress and compliance with a visual overview.", "Ensure that all employees are adequately trained and certified.", "Identify skill gaps and proactively address training needs."]}
        sectionBackground='dark'
        sectionAlign="image-right"
        featureImageSrc={r2}
      />
      <div className="Home-Page sl-home-wrapper" style={{ paddingTop: 0 }}>
        <ParallaxSection />
      </div>
      <div className="features-homepage-wrapper">
        <HomepageDemoSection mergeTop={false} />
      </div>
    </>
  )
}
