import React from "react";
import { Controller } from "react-hook-form";
import DualListbox from "../../../shared/components/dualListBox";
import { Grid, TextField, FormControl, Typography, Box } from "@mui/material";


export const JobProfileFrom = ({
  register,
  errors,
  setValue,
  control,
  jp_form_templates = [],
  jp_trainings = [],
  jp_users = [],
}) => {

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <Box mb={1}>
              <Typography variant="body1">
                Job Profile Name <span style={{ color: "red" }}>*</span>
              </Typography>
            </Box>
            <TextField
              size="small"
              {...register("name", {
                required: "Name is required",
              })}
              placeholder="Enter Job Profile"
              error={!!errors.name}
              helperText={errors.name ? errors.name.message : ""}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Controller
            name="jp_trainings"
            control={control}
            render={({ field: { value = [] } }) => (
              <DualListbox
                title={"Mandatory Training Topics"}
                control={control}
                setValue={setValue}
                name="jp_topics"
                leftData={jp_trainings.filter(tr => value.every(val => val.value !== tr.value))}
                rightData={value}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Controller
            name="jp_form_templates"
            control={control}
            render={({ field: { value = []} }) => (
              <DualListbox
                title={"Shared Forms"}
                control={control}
                setValue={setValue}
                name="jp_form_templates"
                leftData={jp_form_templates.filter(form => value.every(val => val.value !== form.value))}
                rightData={value}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Controller
            name="jp_users"
            control={control}
            render={({ field: { value = []} }) => (
              <DualListbox
                title={"Users"}
                control={control}
                setValue={setValue}
                name="jp_users"
                leftData={jp_users.filter(user => value.every(val => val.value !== user.value))}
                rightData={value}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};
