import React from 'react';
import * as Components from '../../components';

var axios = require('axios');

const initialState = {
    summary: {},
    client: "",
    result:[],
    audit_types:[],
    owners:[],
    provinces: [],
    fields: {company:'',province:''}
};

class COR extends React.Component{
    constructor(props)
    {
        super(props);
        this.state = initialState
        this.handleChange = this.handleChange.bind(this);
        this.commonfilter = this.commonfilter.bind(this);
        // this.resetfilter = this.resetfilter.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
    }

    componentDidMount() {
    var that = this;
    that.populateData()
    console.log(this.state)
}

    componentDidUpdate(prevProps) {
    window.scrollTo(0,0);
}
    populateData(){
    var that = this;
    $(".loading").show()
    axios.get('/dashboard/cor_overview',{
    })
        .then(function(response){
            $(".loading").hide()
            console.log(response.data)
            that.renderChartData(response.data, that)

        })
        .catch(function(error){
            console.log(error);
        })
}

    onFilterChange(val, that) {
    var data = that.state ? that.state.fields : that

    var current = this
    var filter_params = {
        company : data['company'],
        province : data['province'],
        chart_type: data['chart_type']
    }
    console.log(filter_params);
    $(".loading").show()
    axios.get('/dashboard/cor_overview', {
        params: filter_params
    })
        .then(function (response) {
            console.log("Before updating the value of the data....")
            console.log(response.data.summary)
            $(".loading").hide()
            current.renderChartData(response.data, current)

        })
        .catch(function (error) {
            console.log(error);
        });
}

    commonfilter(){
    var that = this;
    var filter_params = {
        template_id : that.state.fields['company'],
        project : that.state.fields['province'],
        auditor : that.state.fields['auditor'],
        audit_type: 'Audit'
    }
    $(".loading").show()
    axios.get('/dashboard/cor_overview', {
        params: filter_params
    })
        .then(function (response) {
            console.log(response);
            $(".loading").hide()
            that.renderChartData(response.data, that)
        })
        .catch(function (error) {
            console.log(error);
        });

}

    handleChange(field, e){
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({fields});
    this.commonfilter()
}

    renderChartData(response, that){
    that.setState({
        filters: response.filters,
        summary: response.summary,
        result: response.emp_training_data,
        graph: {element_trainings: response.element_graph},
        params: response.params

    });
}

    updateChartData(params){
    var that = this;
    $(".loading").show()
    axios.get('/dashboard/cor_overview', {
        params: params
    })
        .then(function (response) {
            console.log(response);
            $(".loading").hide()
            that.renderChartData(response.data, that)
        })
        .catch(function (error) {
            console.log(error);
        });
}

    render() {
    const { filters, summary, graph, employees_info, graph_title, params } = this.state;
    const company_name= "NBL"
    return (
        <div className="msmcontainer">
            <div className="row">
                <Components.CORFilters  filters= {filters} onFilterChange={this.onFilterChange}/>

                <div className="col-sm-10">
                    <div className="container-fluid">
                        <Components.COROverview summary={summary} />
                        <br/>
                        <Components.CORGraphs params={params} graph= {graph} onFilterChange={this.onFilterChange}/>
                    </div>
                </div>

            </div>
        </div>

        )
}
}

export default COR;
