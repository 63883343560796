import React from "react";
import { Nav } from 'react-bootstrap';
import Cookies from "js-cookie";
import Logout from "../../assets/fonts/Logout.svg";
import Application from "../../config/ApplicationWrapper";
import axios from "../../config/axiosConfig";
import { withRouter } from "react-router-dom";

const InternalSubHeader = ({history}) => {
    let companyDetails = localStorage.getItem('user');
    companyDetails = JSON.parse(companyDetails);

    const logout = async () => {
        localStorage.removeItem("user");
        Cookies.remove("token")
        await axios.post(`${Application.api_url}/api/logout`)
        window.location.href = "/home"
    }

    const goBack = () =>{
        history.push('/internalsub_dashboard');
    }

    return (
        <Nav className="internal-sub-header">
            <img alt="SafetyLoop Main" src={"/New-logo.svg"} className="logo" onClick={()=>{goBack()}}/>
            <div className="user-details">
                <p className="company">{companyDetails && companyDetails.company_name}</p>
                <p className="role">{companyDetails && companyDetails.first_name} {companyDetails && companyDetails.last_name}</p>
            </div>
            <div className="header-items"> 
                <div className="exit" onClick={(e) => { logout() }}> <img alt="Logout" src={Logout} className="exit-icon" /><span>Exit</span></div>
            </div>
        </Nav>
    )
}

export default withRouter(InternalSubHeader);