import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";
import React, { useEffect, useRef, useState, useReducer } from "react";
import axios from "../../../config/axiosConfig";
import Application from "../../../config/ApplicationWrapper";
import FreeIcon from "../../../assets/images/inventory-free.svg"
import StarterIcon from "../../../assets/images/inventory-starter.svg"
import PlusIcon from "../../../assets/images/inventory-plus.svg"
import PremierIcon from "../../../assets/images/inventory-premier.svg"
import FeatureTickIcon from "../../../assets/images/inventory-feature-tick.svg"
import NotIncluded from "../../../assets/images/not_included.svg"
import { User } from "../../HomePage/Login";
import { toast } from "react-toastify";

import { withRouter } from "react-router-dom";
import CreditCardForm from "./popups/CreditCardForm";

const Paywall = ({ history }) => {
    const [plans, setPlans] = useState([]);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [openPaidPlan, setOpenPaidPlan] = useState(false);
    const [onSubmit, setOnSubmit] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);
    // const [hasCreditCard, setHasCreditCard] = useState(false);

    const initiatePlans = () => {
        setPlans([
            {
                id: "tt-free-plan",
                name: "Free Plan",
                icon: FreeIcon,
                price: 0,
                time_trackers: "Time Cards",
                features: [
                    { name: "2 User Max", included: true },
                    // { name: "Mobile App", included: true },
                    // { name: "Work Entry", included: true },
                    // { name: "Bulk Work Entry", included: false },
                    // { name: "Bulk Import/Export", included: false },
                ],
                handleSelect: () => {
                    setOpenConfirmDialog(true);
                }
            },
            {
                id: "tt-starter-plan",
                name: "Starter",
                icon: StarterIcon,
                price: 59,
                time_trackers: "Time Cards",
                features: [
                    { name: "50 Users Monthly", included: true },
                    // { name: "Mobile App", included: true },
                    // { name: "Work Entry", included: true },
                    // { name: "Bulk Work Entry", included: false },
                    // { name: "Bulk Import/Export", included: false },
                ],
            },
            {
                id: "tt-plus-plan",
                name: "Plus",
                icon: PlusIcon,
                price: 99,
                time_trackers: "Time Cards",
                features: [
                    { name: "100 Users Monthly", included: true },
                    // { name: "Mobile App", included: true },
                    // { name: "Work Entry", included: true },
                    // { name: "Bulk Work Entry", included: true },
                    // { name: "Bulk Import/Export", included: true }
                ],
            },
            {
                id: "tt-premier-plan",
                name: "Premier",
                icon: PremierIcon,
                price: 299,
                time_trackers: "Time Cards",
                features: [
                    { name: "500 users Monthly", included: true }
                    // { name: "Work Entry", included: true },
                    // { name: "Bulk Work Entry", included: true },
                    // { name: "Bulk Import/Export", included: true }
                ],
            },
            {
                id: "tt-premier-plus-plan",
                name: "Premier+",
                icon: PremierIcon,
                price: 499,
                time_trackers: "Time Cards",
                features: [
                    { name: "Unlimited users Monthly", included: true },
                    // { name: "Mobile App", included: true },
                    // { name: "Work Entry", included: true },
                    // { name: "Bulk Work Entry", included: true },
                    // { name: "Bulk Import/Export", included: true }
                ],
            },
        ]);
    };


    const selectPlan = (plan) => {
        setSelectedPlan(plan);
        if (plan.handleSelect) {
            plan.handleSelect();
        }
        // else if (hasCreditCard) {
        //     setOpenConfirmDialog(true);
        // }
        else {
            setOpenPaidPlan(true);
        }
    };

    const handleCloseFreePlan = () => {
        setOpenConfirmDialog(false);
    }

    const handleConfirmFreePlan = () => {
        setOnSubmit(true);
        axios
            .post(
                `${Application.api_url}/api/v2/time_cards/subscribe_time_tracker_plan`,
                {
                    external_id: selectedPlan.id,
                }
            )
            .then((response) => {
                if (response.data.success) {
                    location.reload();
                }
            }).catch((error) => {
            const errors = error.response.data.message.credit_card_attributes || error.response.data.message;
            if (typeof errors === 'string') {
                toast.error(errors);
            } else {
                toast.error("Something went wrong. Please try again later.");
            }
        }).finally(() => {
            setOnSubmit(false);
        });
    }

    const handleModalClose = () => {
        setOnSubmit(false);
        setOpenPaidPlan(false);
    };

    // const fetchPaymentMethod = () => {
    //     axios
    //         .get(`${Application.api_url}/api/v2/payment_methods`)
    //         .then((response) => {
    //             response.data.data.length > 0 && setHasCreditCard(true);
    //         });
    // };

    useEffect(() => {
        initiatePlans();
        // fetchPaymentMethod();
    }, []);

    return (
        <div className="paywall pt-4">
            <div className="paywall__container mx-4">
                <div className="paywall__header">
                    <div className="paywall__header__introduction">
                        <h2 className="text-white">Welcome to Time Tracking!</h2>
                        <h1 className="text-white pl-0">Select Your Plan - Get our best intro pricing today!</h1>
                        <p className="text-white">Pick a plan below that best fits your needs, these prices won't be this way forever.  </p>
                    </div>
                </div>
                <div className="paywall__plans-container {plans.length > 0 ? 'd-flex' : 'd-none'}">
                    <div className="paywall__plans">
                        {plans.map((plan) => (
                            <div className="paywall__plans__card" style={{minWidth: '250px', padding: '20px'}}>
                                <div className="paywall__plans__card__header">
                                    <img src={plan.icon} alt={plan.name} className="paywall__plans__card__header__icon"/>
                                    <div className="d-flex justify-content-between paywall__plans__card__header__title mt-2">
                                        <h3 className="my-0">{plan.name}</h3>
                                        <div className="right">
                                            <h4 className="my-0">${plan.price}</h4>
                                            <span>/ mo</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="paywall__plans__card__features">
                                    {/* <h4 className="paywall__plans__card__features__inventories">{plan.time_trackers}</h4> */}
                                    <ul>
                                        {plan.features.map((feature, index) => (
                                            <li className="mb-2" key={index}>
                                                {feature.included ? (
                                                    <div>
                                                        <img src={FeatureTickIcon} alt="Feature" className="mr-2" />
                                                        <span>{feature.name}</span>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <img src={NotIncluded} alt="Not Included" className="mr-2 not-included" />
                                                        <span>{feature.name}</span>
                                                    </>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="paywall__plans__card__footer">
                                    <button
                                        className={"paywall__plans__card__footer__btn sticky-button" + ' ' + (User.currentTimeTrackerPlanId == plan.id ? ' disabled' : '')}
                                        onClick={() => selectPlan(plan)}
                                    >
                                        Select
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                    <footer style={{ fontSize: '11px', marginLeft: '95px', marginRight: '95px' }}>
                        <p>
                            All prices listed are in <u>Canadian dollars (CAD)</u>. Prices are subject to change with notice.
                            By accessing and using this website, you agree to our terms and conditions and privacy policy.
                            The content on this page is provided for informational purposes only and does not constitute a legally binding agreement.
                            <br />
                            This product is an add-on to your company's existing subscription to SafetyLoop.  We sincerely thank you for your business.
                        </p>
                    </footer>
                </div>
                <Dialog open={openConfirmDialog} onClose={() => setOpen(false)}>
                    <DialogTitle>Confirmation</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to select this plan?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseFreePlan} disabled={onSubmit}>Cancel</Button>
                        <Button onClick={handleConfirmFreePlan} disabled={onSubmit}>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
                {openPaidPlan && <CreditCardForm show={openPaidPlan} handleClose={handleModalClose} selectedPlan={selectedPlan} />}
            </div>

        </div>

    );
};

export default withRouter(Paywall);
