import React from 'react';
import { ButtonExport } from './table';
import axios from 'axios';

const ExportCSV = ({url, params = {}}) => {
    console.log(url, params, 'rerendering');
    const exportData = async () => {
        try {
          await axios
            .get(url, {
              responseType: "blob",
              params,
              headers: {
                'Content-Type': 'text/csv',
                'Accept': 'text/csv'
              }
            })
            .then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "users_group_export.csv"); //or any other extension
              document.body.appendChild(link);
              link.click();
            })
            .catch((error) => {
              console.log("err==>", error);
            });
        } catch (err) {
          console.log("error==>", err);
        }
      };
    return (
        <ButtonExport label="Export" onClick={exportData} />
    )
}

export default ExportCSV;
