import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'animate.css';
import ScrollAnimation from 'react-animate-on-scroll';

export default function FeatureListItem({
    featureURL = "/features",
    featureIcon,
    headerText,
    subText,
}) {
    return (
        <ScrollAnimation
            animateIn="animate__fadeIn"
            duration={0.75}
            animateOnce={true}
            delay={0}
            className='feature-list-item'>
            <a href={featureURL} className="feature-card">
                <div>
                    <FontAwesomeIcon icon={featureIcon} size="3x" style={{ marginBottom: 16, marginTop: 16 }} />
                    <h2 className="card-header">{headerText}</h2>
                </div>
                <p className="card-subtext">{subText}</p>
                <div className="feature-card-cta">Learn More</div>
            </a>
        </ScrollAnimation>
    )
}
