import React from 'react';
import UploadForm from './UploadForm';
import { withRouter } from "react-router-dom";
import usePatch from '../../../shared/components/usePatch';

const UploadDocs = ({open, setOpen, setFilterState, match}) => {
    const { id } = match.params; // Access the 'id' param from the URL
    const {mutate, isLoading: isMutating, error} = usePatch(`/api/v2/entity_management/groups/${id}/upload_docs`, true);
    return (
        <UploadForm open={open} mutate={mutate} setOpen={setOpen} setFilterState={setFilterState} />
    )
}

export default withRouter(UploadDocs);
