import React from "react";
import PlainTabs from "../shared/components/plainTabs";
import UsersListWrapper from "./components/UserList/usersListWrapper";
import JobProfileWrapper from "./components/JobProfiles/JobProfileWrapper";
import { createTheme, ThemeProvider } from "@mui/material";
import CompanyGroupWrapper from "./components/CompanyGroups/companyGroupWrapper";
import GlobalProjectWrapper from "./components/AssignedGlobalProjects/GlobalProjectWrapper";
import useFetch from "../shared/components/userFetch";
import Loader from "../shared/components/loader";

const theme = createTheme({
  typography: {
    fontFamily: "Inter, system-ui, -apple-system", // Change font family globally
  },
});

const UserGroup = () => {
  const {data: permissionData, isLoading} = useFetch('/api/v2/entity_management/users/feature_permission_setting');
  const {data} = permissionData || {};
  const {
    allow_view_company_groups,
    allow_view_company_job_profiles,
    allow_view_company_users,
    allow_view_global_projects
  } = data || {};
  if (isLoading) {
    return <Loader />
  }
  const tabList = [];

  if (allow_view_company_users) {
    tabList.push({
      label: "User List",
      value: "ulist",
    })
  }
  if (allow_view_company_job_profiles) {
    tabList.push({
      label: "Job Profiles",
      value: "jp",
    })
  }
  if (allow_view_company_groups) {
    tabList.push({
      label: "Company Groups",
      value: "cg",
    })
  }
  if (allow_view_global_projects) {
    tabList.push({
      label: "Assigned Global Projects",
      value: "agp",
    })
  }
  return (
    <>
      <ThemeProvider theme={theme}>
        <div style={{ paddingTop: 20 }}>
          <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '30px', paddingBottom: '10px'}}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              style={{ width: '25px'}}
            >
              <g clip-path="url(#clip0_9034_90868)">
                <path
                  d="M12 14.6667C14.9455 14.6667 17.3333 12.2789 17.3333 9.33333C17.3333 6.38781 14.9455 4 12 4C9.05444 4 6.66663 6.38781 6.66663 9.33333C6.66663 12.2789 9.05444 14.6667 12 14.6667Z"
                  stroke="#080808"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4 28V25.3333C4 23.9188 4.5619 22.5623 5.5621 21.5621C6.56229 20.5619 7.91885 20 9.33333 20H14.6667C16.0812 20 17.4377 20.5619 18.4379 21.5621C19.4381 22.5623 20 23.9188 20 25.3333V28"
                  stroke="#080808"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M21.3334 4.17334C22.4806 4.46707 23.4974 5.13427 24.2236 6.06975C24.9497 7.00523 25.3438 8.15578 25.3438 9.34001C25.3438 10.5242 24.9497 11.6748 24.2236 12.6103C23.4974 13.5457 22.4806 14.2129 21.3334 14.5067"
                  stroke="#080808"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M28 27.9999V25.3333C27.9932 24.1562 27.5972 23.0143 26.8737 22.0858C26.1502 21.1573 25.1398 20.4942 24 20.2"
                  stroke="#080808"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_9034_90868">
                  <rect width="32" height="32" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span style={{ fontSize: '24px', color: '#080808', fontWeight: '700', padding: '0 5px'}}>Users & Groups</span>
          </div>
          <PlainTabs tabList={tabList}>
            {(active) => {
              const title = tabList.find((e) => e.value === active).label;
              return (
                <>
                  <div style={{ padding: "20px 32px", background: "#F6F6F6" }}>
                    {active === "ulist" && (
                      <UsersListWrapper title={title} active={active} />
                    )}
                    {active === "jp" && (
                      <JobProfileWrapper title={title} active={active} />
                    )}
                    {active === "cg" && (
                      <CompanyGroupWrapper title={title} active={active} />
                    )}
                    {active === "agp" && (
                      <GlobalProjectWrapper title={title} active={active} />
                    )}
                  </div>
                </>
              );
            }}
          </PlainTabs>
        </div>
      </ThemeProvider>
    </>
  );
};

export default UserGroup;
