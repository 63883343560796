import React, { Fragment } from 'react'

const SharedFiles = ({files}) => {
    const renderFilesData = files.map((form, index) =>
    {
        return (
            <li>
                <a href={form.url}>
                    {form.name}
                </a>
            </li>
        )
    });

    const renderContent = (files) => {
        let content;
        if (files.length == 0){
            content = <h4 className='align-center'> No Files shared </h4>
        }else{
            content = <ul>
                        {renderFilesData}
                      </ul>
        }
        return content;
    }

    return (
        <div className="shared-files">
            <h3>
                <i className="fa fa-files-o"></i>
                shared files
            </h3>
            {renderContent(files)}
        </div>
    )
}

export default SharedFiles;
