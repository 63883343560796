import React from 'react';
import DocumentIcon from "../../../assets/feature-icons/Document.png";
import FileUploadIcon from "../../../assets/feature-icons/Upload.png";
import CloseIcon from "../../../assets/feature-icons/Close.png";
import Plus from "../../../assets/feature-icons/Plus.png";

import HeroBannerImage from "../../assets/images/TrainingRecordManagement.png";
const TrainingRecordManagement = () => {
    return (
        <div className="training-record-management-section">
            <img src={HeroBannerImage}  />
        </div>
    );
};

export default TrainingRecordManagement;
