import React, { useReducer, useState } from "react";
import Active from "./Active";
import Archive from "./Archive";
import { NavLink } from "react-router-dom";
import axios from "../../../config/axiosConfig";
import Application from "../../../config/ApplicationWrapper";

const initialTabStatus = {
    archive: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case "UPDATE":
            state[action.tab] = true;
            return { ...state };
        default:
            return state;
    }
};

const List = () => {
    let userDetails = localStorage.getItem("role");
    userDetails = JSON.parse(userDetails);
    const [active, setActive] = useState(0);
    const [archive, setArchive] = useState(0);
    const [updateActive, setUpdateActive] = useState();
    const [updateArchive, setUpdateArchive] = useState();
    const [tabs, dispatch] = useReducer(reducer, initialTabStatus);

    const updateActiveCount = (count) => {
        setActive(count)
    }

    const updateArchiveCount = (count) => {
        setArchive(count)
    }

    const handleUpdateTab = (tab) => {
        dispatch({ type: "UPDATE", tab: tab });
    };

    const exportData = async () => {
        try {
            axios
                .get(`${Application.api_url}/api/v2/internal_subs/export_internal_subs`, {
                })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "internal_subs.csv");
                    document.body.appendChild(link)
                    ;
                    link.click();
                })
                .catch((error) => {
                    console.log("err==>", error);
                });
        } catch (err) {
            console.log("error==>", err);
        }
    };

    return (
        <>
            <div class="card asset-tab-landing">
                <div class="card-body">
                    <div className="d-flex align-item-center justify-content-between header-badge">
                        <div className="d-flex align-item-center">
                            <h3>Managed Subs</h3>
                        </div>
                        <div className="btn-style">
                            {(userDetails && userDetails.name == "Admin" || userDetails && userDetails.name == "SuperAdmin") &&
                                <NavLink
                                    to={`/add-sub-contractors`}
                                    id=""
                                    role="button"
                                    className="rat-links"
                                >
                                    <button type="button" class="btn btn-primary">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                        Add Internal Subs
                                    </button>
                                </NavLink>
                            }

                            <button
                                className="export-btn btn btn-primary"
                                id="export-btn"
                                onClick={() => exportData()}>
                                <i className="fa fa-download" aria-hidden="true"></i>
                                Export All
                            </button>
                        </div>
                    </div>
                    <ul class="nav nav-tabs">
                        <li class="active">
                            <a data-toggle="tab" href="#internal-subs-list">
                                Internal Subs List
                                <span class="badge badge-light">
                                    {active}
                                </span>
                            </a>
                        </li>
                        <li onClick={(e) => { handleUpdateTab('archive') }}>
                            <a data-toggle="tab" href="#archived-internal-subs-list">
                                Archived
                                <span class="badge badge-light">
                                    {archive}
                                </span>
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div
                            id="internal-subs-list"
                            class="tab-pane fade in active active-risk-assessment"
                        >
                            <Active
                                updateActiveCount={updateActiveCount}
                                updateActiveRecord={updateActive}
                                userRole={true}
                                updateChanges={(e) => { setUpdateArchive(Date.now()) }}
                            />
                        </div>
                        <div
                            id="archived-internal-subs-list"
                            class="tab-pane fade in active-risk-assessment"
                        >
                            {tabs.archive && <Archive
                                updateActiveCount={updateArchiveCount}
                                updateArchiveRecord={updateArchive}
                                userRole={(userDetails && userDetails.name == "Admin" || userDetails && userDetails.name == "SuperAdmin") ? true : false}
                                updateChanges={(e) => { setUpdateActive(Date.now()) }}
                            />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default List;
