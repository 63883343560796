import React from 'react'
import HeroSection from './CommonComponents/HeroSection'
import FeaturesSection from './CommonComponents/FeaturesSection'
import ParallaxSection from '../../pages/HomePage/Components/ParallaxSection'
import HomepageDemoSection from '../../pages/HomePage/Components/HomepageDemoSection'
import r1 from '../../../assets/images/action1.png'
import r2 from '../../../assets/images/action2.png'
import r3 from '../../../assets/images/action3.png'

export default function Actions() {
  return (
    <>
      <HeroSection heroText={"Action Management Made Simple"} subText={"Streamline the tracking and execution of safety actions with SafetyLoop's user-friendly tools. Take immediate control of your health and safety management system."} />
      <FeaturesSection
        headerText={"Simplify Action Creation"}
        subText={"Empower your team to take immediate and effective action with SafetyLoop's streamlined action creation tools."}
        featureHighlights={["Create actions effortlessly, ensuring timely response to safety concerns.",
          "Attach relevant documentation and information to actions for clarity.",
          "Assign actions to responsible parties with ease, improving accountability.",
          "Monitor action progress and status in real-time.",
          "Enhance communication and collaboration for swift issue resolution."]}
        sectionBackground='dark'
        sectionAlign="image-right"
        featureImageSrc={r1}
      />
      <FeaturesSection
        headerText={"Customized Action Types"}
        subText={"Tailor your action types to match your organization's specific needs and safety protocols."}
        featureHighlights={["Define and categorize action types based on your safety requirements.",
          "Create action templates with predefined tasks and checklists.",
          "Standardize actions for consistent reporting and analysis.",
          "Easily identify the nature and urgency of each action type.",
          "Foster a proactive safety culture by classifying actions effectively."]}
        featureImageSrc={r2}
      />
      <FeaturesSection
        headerText={"Comprehensive Sub Category Types and Asset Actions"}
        subText={"Enhance your safety management system by utilizing sub category types and asset actions for detailed tracking and control."}
        featureHighlights={["Create sub category types to further classify actions within categories.",
          "Link actions directly to specific assets or equipment for targeted management.",
          "Improve asset maintenance and safety compliance through asset actions.",
          "Streamline asset-related tasks, such as inspections and maintenance schedules.",
          "Achieve a more granular view of safety data through sub categories and asset actions."]}
        sectionBackground='dark'
        sectionAlign="image-right"
        featureImageSrc={r3}
      />
      <div className="Home-Page sl-home-wrapper" style={{ paddingTop: 0 }}>
        <ParallaxSection />
      </div>
      <div className="features-homepage-wrapper">
        <HomepageDemoSection mergeTop={false} />
      </div>
    </>
  )
}
