import React from 'react';
// import Highcharts from 'highcharts'
var Highcharts = require('highcharts/highstock');
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);


import HighchartsReact from 'highcharts-react-official'
// import { Link} from "react-router-dom";
import Select from 'react-select';
import GaugeData from '../../config/gauge_data.json';
import GaugeOptions from '../../config/gauge_options.json';
import BarChartData from '../../config/bar_chart_data.json';
import PieData from '../../config/Pie_data.json';
import HourlyChart from '../../config/HourlyChart.json';
import BarChartDataSlider from '../../config/bar_chart_data_slider.json';
import PageSubNavBar from '../shared/PageSubNavBar';
import AnalyticsOverview from './AnalyticsOverview';
import DataOverview from './DataOverview';
// import Button from 'react-bootstrap/Button';
import { BrowserRouter as Router, Route, Link, withRouter,Redirect, NavLink} from "react-router-dom";
import Login, {User} from "../HomePage/Login";
import StatsOverview from './StatsOverview';
import SubContractorsDashboard from './SubContractorsDashboard';

let axios = require('axios');
let audits_frequency = { DAILY: { completed: 0, milestone: 0, percentage: 0},
    WEEKLY: { completed: 0, milestone: 0, percentage: 0},
    MONTHLY: { completed: 0, milestone: 0, percentage: 0},
    QUARTERLY: { completed: 0, milestone: 0, percentage: 0},
    ANNUAL: { completed: 0, milestone: 0, percentage: 0}}

const initialState = {
    summary: null,
    client: "",
    company: "",
    company_id: "",
    updated_date: "",
    result:[],
    audit_types:[],
    owners:[],
    groups: [],
    companies: [],
    fields: {template_id:'',group_id:'',auditor:'', company: ''},
    audits_stats: audits_frequency,
    admin_permission: '',
    manager_permission: '',
    manage_permission: '',
    view_only_permission: '',
    subcontractors: [],
    subcontractor_info: { base_info: {}, employees: [], assigned_projects: [], shared_documents: [] },
    project_shared_info: { shared_forms: [], files: []},
    employee_info: { shared_forms: [], files: [] },
    user_trainings_info: { user_info: [], trainings: [] },
    status: false
};

class UserDashboard extends React.Component{
    constructor(props)
    {
        super(props);
        this.state = initialState
        this.handleChange = this.handleChange.bind(this);
        this.commonfilter = this.commonfilter.bind(this);
        this.resetfilter = this.resetfilter.bind(this);

    }

    commonfilter(){
        var that = this;
        var filter_params = {
            template_id : that.state.fields['template_id'],
            group_id : that.state.fields['group_id'],
            auditor : that.state.fields['auditor'],
            company : that.state.fields['company'],
            audit_type: 'Audit'
        }
        axios.get('/dashboard/populate_data', {
            params: filter_params
        })
            .then(function (response) {
                $(".loading").hide()
                that.renderChartData(response.data, that)
            })
            .catch(function (error) {
                console.log(error);
            });

       

    }


    componentDidMount(){
      let that = this
      // debugger;
      axios.get('/users/check_for_user',{
      })
      .then(function(response){
          if(response.data.user && (response.data.user.email || response.data.user.username)){
              $("#app").data("user_data", response.data.user)
              $("#app").data("profile_url", response.data.user_profile_url)
              $("#app").data("user_role", response.data.user_role)
              debugger
              that.user_profile_data()
          }
      })
      .catch(function(error){
          console.log(error);
      })
    }

    resetfilter(){
        var that = this;
        $(".loading").show()
        that.setState({
            fields: {template_id:'',group_id:'',auditor:'', company: ''}
        });

        var filter_params = {template_id : "", group_id : "", auditor : "", company: "", filter_reset: true  }
        axios.get('/dashboard/populate_data', {
            params: filter_params
        })
            .then(function (response) {
                $(".loading").hide()
                that.renderChartData(response.data, that)
            })
            .catch(function (error) {
                console.log(error);
            });

        
    }

    handleChange(field, e){
        let fields = this.state.fields;
        fields[field] = e == null ? '' : e.value;
        this.setState({fields});
        if (e != null){
            $(".loading").show()
            this.commonfilter()
        }
    }

    populateData(){
        var that = this;
        $(".loading").show()

        axios.get('/dashboard/populate_data',{
        })
            .then(function(response){
                $(".loading").hide()
                that.renderChartData(response.data, that)

            })
            .catch(function(error){
                console.log(error);
            });
        
        axios.get('/users/check_for_user',{
        })
        .then(function(response){
            if(response.data.user && (response.data.user.email || response.data.user.username)){
                $("#app").data("user_data", response.data.user)
                $("#app").data("profile_url", response.data.user_profile_url)
                $("#app").data("user_role", response.data.user_role)
                that.user_profile_data()
            }
        })
        .catch(function(error){
            console.log(error);
        })
    }

    user_profile_data() {
      let that = this
      var user_data = $("#app").data("user_data")
      var profile_url = $("#app").data("profile_url")
      that.setState({
          email: user_data.email,
          first_name: user_data.first_name,
          last_name: user_data.last_name,
          username: user_data.username,
          profile_url: profile_url
  
      })
      // debugger;
  }

    renderChartData(response, that){
        var summary = response.summary
        $("#required_audits").text(summary.total_audits);
        $("#avg_audits").text(summary.average_daily_audits);
        $("#total_auditors").text(summary.total_auditor);
          var audit_data = response.audit_per_day
          var audit_type = response.filter_audit_types
          var audit_owner = response.filter_owner
          var last_updated_at = response.current_updated_date
          var fields = that.state.fields
          fields['company'] = response.company_id
          that.setState({
              client: response.client,
              company_id: response.company_id,
              companies: response.companies,
              groups: response.groups,
              audit_types: audit_type,
              result: audit_data,
              owners: audit_owner,
              updated_date: last_updated_at,
              fields: fields,
              admin_permission: response.admin_permission,
              manager_permission: response.manager_permission,
              manage_permission: response.manage_permission,
              view_only_permission: response.view_only_permission
          });
          var avg_score = summary.average_score ? summary.average_score : 0
        $("#average_score").text(avg_score+" %")
        GaugeOptions["yAxis"]["max"] = summary.total_audits
        GaugeOptions["series"][0]["data"] =[summary.completed_audits]
        if (response.chart_type != "Hourly"){
            var completed = response.completed_audits
            HourlyChart["xAxis"]["categories"] = completed.categories
            HourlyChart["series"][0]["data"] = completed.data
            Highcharts.chart('HourlyChart', HourlyChart);

        }

        
        if (response.chart_type != "Audit"){
            PieData.series[0]["data"] = response.audit_types;
            
            Highcharts.chart('PieData', PieData);
        }

        if (response.chart_type != "Daily"){
            var seriesOneData = [];
            var bar_data = response.audit_by_day.data

            bar_data.forEach(function(entry) {
//                seriesOneData.push([Date.parse(entry.name),entry.completed_audits]);
                seriesOneData.push({x: Date.parse(entry.name),y: entry.total_audits, total_audits: entry.total_audits, completed_audits: entry.completed_audits, date: entry.name});
            });

              if (BarChartDataSlider["series"] ==null)
              {
                BarChartDataSlider["series"] = [{
                                                  "name":"Total Audits",
                                                  "type":"column",
                                                  "data": seriesOneData,
                                                  "dataGrouping":{
                                                      "units":[
                                                          ["week",[1]],
                                                          ["month",[1,2,3,4,6]]
                                                      ]
                                                  }
                                              }]
                Highcharts.stockChart('BarChartData', BarChartDataSlider);
              }else{
                BarChartDataSlider["series"][0]["data"] = seriesOneData
                Highcharts.stockChart('BarChartData', BarChartDataSlider);
              }
          }
    }

    updateChartData(params){
        var that = this;
        $(".loading").show()
        axios.get('/dashboard/populate_data', {
            params: params
        })
            .then(function (response) {
                $(".loading").hide()
                that.renderChartData(response.data, that)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    load_company_stats_data = async () => {
        const response = await axios.get('/stats/audits_stats', { params: { }});
        this.setState({ audits_stats: response.data });
    }

    load_subcontractors_data = async () => {
        const response = await axios.get('/subcontractor_dashboard', { params: { }});
        this.setState({ subcontractors: response.data });
    }

    view_subcontractor_data = async (company_id) => {
        const response = await axios.get(`/subcontractor_dashboard/${company_id}`, { params: { }});
        this.setState({ subcontractor_info: response.data });
    }

    fetch_project_data = async (params) => {
        const response = await axios.get('/subcontractor_dashboard/project_info', { params: { group_id: params['group_id'], company: params['company'] }});
        this.setState({ project_shared_info: response.data });
    }

    fetch_employee_data = async (params) => {
        const response = await axios.get('/subcontractor_dashboard/user_info', { params: { user_id: params['user_id'] }});
        this.setState({ user_trainings_info: response.data, status: true });
    }

    onClientSelect = (client) => {
        this.view_subcontractor_data(client.id).then(r => console.log(r))
    }

    onProjectSelect = (params) => {
        this.fetch_project_data(params).then(r => console.log(r))
    }

    onEmployeeSelect = (params) => {
        this.fetch_employee_data(params).then(r => console.log(r))
    }

    onModalClose = () => {
        this.setState({ status: false });
    }

    componentDidMount() {
        let that = this;
        this.load_company_stats_data().then(r => console.log(r));
        this.load_subcontractors_data().then(r => console.log(r));
        that.populateData();
        PieData.plotOptions["series"]["point"]["events"]["click"] = function () {
            var params = {template_id: this.template_id, auditor: that.state.fields['auditor'],
                          group_id: that.state.fields['group_id'], company: that.state.company_id,
                          summary: {total_audits: this.total_audits,
                          completed_audits: this.completed_audits, average_score: this.average_score,
                          total_auditor: this.total_auditor}, chart_type: "Audit"}
            that.updateChartData(params)

        }

        BarChartDataSlider.plotOptions["series"]["point"]["events"]["click"] = function () {
            var birthday = new Date(this.x);
            var audit_date =  (birthday.getMonth() + 1) + '/' + birthday.getDate() + '/' +  birthday.getFullYear()
            var params = { date: audit_date, template_id: that.state.fields['template_id'],
                           auditor: that.state.fields['auditor'],
                           company: that.state.company_id,
                           group_id: that.state.fields['group_id'], chart_type: "Daily",
                           summary: {total_audits: this.total_audits,
                           completed_audits: this.completed_audits,
                           total_auditor: this.total_auditor,
                           average_score: this.average_score
                            } }
               that.updateChartData(params)
          }

        HourlyChart.plotOptions["series"]["point"]["events"]["click"] = function () {
            var params = {hourly_chart: this.category, template_id: that.state.fields['template_id'],
                          auditor: that.state.fields['auditor'],group_id: that.state.fields['group_id'],
                          company: that.state.company_id,chart_type: "Hourly" }
            that.updateChartData(params)
        }
}

    renderAuditTypeData() {
        return this.state.audit_types.map((types,key) => {
            return (
                <option key={key} value={types.template_id}>{types.name}</option>
                );
        })
    }

    renderProjectData() {
        return this.state.audit_types.map((record,key) => {
            return (
                <option key={key} value={record.template_id}>{record.name}</option>
                );
        })
    }

    renderAuditorData() {
        return this.state.owners.map((owner,key) => {
            return (
                <option key={key} value={owner}>{owner}</option>
                );
        })
    }


    renderFiltersData(results,value="") {
        let optionItems = results.map((record,key) => {
            return (
                <option key={key} value={record.ref_id}>{record.value}</option>
                );
        })
        if (!value){
            optionItems.unshift(<option key="" value="">Select</option>);
        }
        return optionItems;

    }

    

    renderCompanyFilter(companies){
        var that = this;
        this.state.filterProject = "";
        this.state.groups.forEach(function(x){
            if(x.value == that.state.fields["group_id"]) {
                console.log(x.value);
                that.state.filterProject = ""+ x.label;
            }
            
        });

      if ( User.role == "SuperAdmin" || User.company == "ITLE" || User.company == "WET"){
          
          return(
              <div>
                  <div className="col-sm-12">
                      <label className="control-label">Company</label>
                      <Select
                      name="form-field-name"
                      value={this.state.fields["company"]}
                      onChange={this.handleChange.bind(this,"company")}
                      options={companies}
                      id="company"
                      clearable={false}
                      />
                  </div>
              </div>
              )
      }
    }

    renderStatsLink(url, text){
        
        if ( this.state.admin_permission){
            if( text === "Stats Dashboard" ) {
                return (
                    <li role="presentation">
                        <i className="fa fa-tachometer" aria-hidden="true"></i><a  to={url} role="button" href={url}>{text}</a>
                    </li>
                );
            } else {
                return (
                    <li role="presentation">
                        <i className="fa fa-sliders" aria-hidden="true"></i><a  to={url} role="button" href={url}>{text}</a>
                    </li>
                );
            }
            
        }
    }

    renderTopAdmin = () => {
        if( this.state.manage_permission ) {
            return (
                
                <div className="row border-bottom">
                  <div className="col-sm-1">
                    <i className="fa fa-cogs" aria-hidden="true"></i>
                  </div>
                  <div className="col-sm-8">
                    <a href="/company_profile" to="/company" role="button" style={{color: '#fff'}}>
                        Company Profile
                    </a>
                  </div>
                  
                </div>
              
            );
        }
    }

    renderRat = () => {
        if( this.state.manage_permission ) {
          return (
              <li role="presentation">    
                <img src={"/main-images/rat-white.png"} style={{height:"26px",marginLeft:"-14px"}} /><a  to="/template-creator" role="button" href="/ctra">Risk Assessment Tool</a>
              </li> 
          );  
        }
    }


    renderCompanySettingsTop = () => {
        if(this.state.manage_permissions) {
            return (
                <li role="presentation">
                    <i className="fa fa-cog" aria-hidden="true"></i><a  to="/company_profile" role="button" href="/company_profile">Company Profile</a>
                </li>
            );
        }
    }

    renderTopAdminBar = () => {
        if(this.state.maange_permissions) {
            return (
                <hr style={{height: '1px', width: '180px', backgroundColor: '#fff', marginLeft: '0px', opacity: '.6'}} />
            );
        }
    }



    renderUsersListMenu = () => {
        if ( this.state.manage_permission || this.state.manager_permission){
            return (
                <li role="presentation">
                    <i className="fa fa-users" aria-hidden="true"></i><a  to="/company-users" role="button" href="/company-users">Company Users</a>
                </li>
            );
        }
    }

    renderNewUserMenu = () => {
        if ( this.state.manage_permission ){
            return (
                <li role="presentation">
                    <i className="fa fa-plus" aria-hidden="true"></i><a  to="/new-user" role="button" href="/new-user">Add User</a>
                </li>
            );
        }
    }

    renderUserManagementMenu = () => {
        if ( this.state.admin_permission ){
            return (
                <li role="presentation">
                    <i className="fa fa-cloud-upload" aria-hidden="true"></i><a  to="/user_management" role="button" href="/user_management">Import Users</a>
                </li>
            );
        }
    }

    renderRiskRangeInputMenu = () => {
        if ( this.state.manage_permission || this.state.manager_permission ){
            return (
                <li role="presentation">
                    <i className="fa fa-sliders" aria-hidden="true"></i><a  to="/risk-range" role="button" href="/riskrange">Risk Range Inputs</a>
                </li>
            );
        }
    }

    renderCompanyDocumentsLink = () => {
        if(this.state.admin_permission) {
            return (
                <li role="presentation">
                    <i className="fa fa-files-o" aria-hidden="true"></i><a  to="/company_documents" role="button" href="/company_documents">Company Documents</a>
                </li>
            );
        }
    }

    renderSubContractorDashboardLink = () => {
        // if(this.state.admin_permission) {
        //     return (
        //         <li role="presentation">
        //             <i className="fa fa-files-o" aria-hidden="true"></i><a  to="/sub-contractor" role="button" href="/sub-contractor">SubContractors Dashboard</a>
        //         </li>
        //     );
        // }
    }

    renderErpLink = () => {
        if(this.state.admin_permission) {
            return (
                <li role="presentation">
                    <i className="fa fa-files-o" aria-hidden="true"></i><a  to="/emergency_plans" role="button" href="/emergency_plans">Emergency Plans</a>
                </li>
            );
        }
    }

    renderQrCodeLink = () => {
        if(this.state.admin_permission || this.state.manager_permission) {
            return (
                <li role="presentation">
                    <i className="fa fa-files-o" aria-hidden="true"></i><a  to="/qr_codes" role="button" href="/qr_codes">QR Codes</a>
                </li>
            );
        }
    }

/******************** Begins Page render  **********************/

render() {
    const { summary, result, audit_types, groups, client, companies, owners, audits_stats, subcontractors, subcontractor_info, project_shared_info, user_trainings_info, status } = this.state;
    const hsmsLink = "https://www.mashey.com/clients/msm/iauditor/dropbox_poc?company_name="+client.toLowerCase();
    const hsmsDocLink = "/client?company_name="+client.toLowerCase();
    const superAdmin = User.role;
    const roles = ['Admin', 'SuperAdmin', 'Manager'];
    return (
        <div className="msmcontainer">
            <div className="row">
                <div className="col-sm-2 side-nav" >
                    <div className="inner-side-nav">

                        <div className="row border-bottom">
                            <div style={{fontSize:"18px", marginLeft:"15px", color:"#fff"}}>{client}</div> 
                            <div style={{fontSize:"12px", marginLeft:"15px"}} className="client_name whitey">{this.state.filterProject}</div>
                            <div style={{fontSize:"11px", marginLeft:"15px", color:"#fff"}}> (<b>Last Updated:</b> {this.state.updated_date} )</div>
                            {this.renderCompanyFilter(companies)}
                        </div>
                        {/* <hr style={{height: '1px', width: '180px', backgroundColor: '#fff', marginLeft: '0px', opacity: '.6'}} /> */}
                        <div className="row">

                            <div className="col-sm-12 hsms-link mb-ipad-1" style={{"paddingTop": "0px"}}>
                                
                              {  (roles.includes(superAdmin)) &&    
                                <div className="border-bottom">
                                  <a ariaExpanded="false" className="collapsed white" data-toggle="collapse" href="#admin">
                                    <div className="row">
                                        <div className="col-sm-1">
                                            <i className="fa fa-users"></i>
                                        </div>
                                        <div className="col-sm-8">
                                            <span className="left-shim">Users</span>
                                        </div>
                                        <div className="col-sm-2">
                                            <span className="caret"></span>
                                        </div>
                                    </div>
                                    
                                    
                                    
                                  </a>
                                  
                                  <div id="admin" className="collapse shim-top-gutter">
                                    <ul className="nav nav-collapse">
                                      <li>
                                        <span className="sub-item">
                                          <NavLink to="/company-users" id="" role="button">Users List</NavLink>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="sub-item">
                                          <NavLink id="new-user-link" to="/new-user" role="button">New User</NavLink>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="sub-item">
                                          <a id="stats-link"  to="/user_management" href="/user_management" role="button">Import Users</a>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="sub-item">
                                          <a id="stats-link"  to="/user_management" href="/groups" role="button">Groups</a>
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                              
                                </div>
                              }
                                
                                
                                
                                
                                
                                <div className="border-bottom">
                                  <a ariaExpanded="false" className="collapsed white" data-toggle="collapse" href="#dashboard">
                                    <div className="row">
                                        <div className="col-sm-1">
                                            <i className="fa fa-area-chart"></i>
                                        </div>
                                        <div className="col-sm-8">
                                            <span className="left-shim">Dashboard</span>
                                        </div>
                                        <div className="col-sm-2">
                                            <span className="caret"></span>
                                        </div>
                                    </div>
                                    
                                    
                                    
                                  </a>
                                  
                                  <div id="dashboard" className="collapse shim-top-gutter">
                                    <ul className="nav nav-collapse">
                                      <li>
                                        <span className="sub-item">
                                          <NavLink to="/" id="" role="button">Main Dashboard</NavLink>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="sub-item">
                                          <a  to="/stats" role="button" href="/stats">Stats Dashboard</a>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="sub-item">
                                          <a  to="/reports_dashboards" role="button" href="/reports_dashboards">Reporting Dashboard</a>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="sub-item">
                                            <a  to="/sub-contractor" role="button" href="/sub-contractor">Subcontractor Dashboard</a>
                                        </span>
                                      </li>
                                    </ul>
                                  </div>

                                </div>
                                

                                



                                <div className="border-bottom white">
                                    
                                  <a ariaExpanded="false" className="collapsed white" data-toggle="collapse" href="#training">
                                    <div className="row">
                                      <div className="col-sm-1">
                                        <i className="fa fa-graduation-cap white"></i>
                                      </div>
                                      <div className="col-sm-8">
                                        <span className="left-shim white">
                                          Training
                                        </span> 
                                      </div>
                                      <div className="col-sm-1">
                                        <span className="caret"></span>
                                      </div>
                                    </div>
                                    
                                  </a>

                                  <div id="training" className="collapse shim-top-gutter">
                                    <ul className="nav nav-collapse">
                                      <li>
                                        <span className="sub-item">
                                          <NavLink to="/rot" id="" role="button">Records</NavLink>
                                        </span>
                                      </li>
                                      {/* <li>
                                        <span className="sub-item">
                                          <a  to="/template-creator" role="button" href="https://training.msmecor.com" target="_blank">Education</a> <i className="fa fa-external-link whitey" aria-hidden="true"></i>
                                        </span>
                                      </li> */}
                                      
                                    </ul>
                                  </div>
                                </div>



                                <div className="border-bottom white">
                                  <a ariaExpanded="false" className="collapsed white" data-toggle="collapse" href="#forms">
                                    <div className="row"> 
                                      <div className="col-sm-1">
                                        <i className="fa fa-file white"></i>
                                      </div>
                                      <div className="col-sm-8">
                                        <span className="left-shim white">
                                          Forms
                                        </span>
                                      </div>
                                      <div className="col-sm-1">
                                        <span className="caret"></span>
                                      </div>
                                    </div>
                                  </a>

                                  <div id="forms" className="collapse shim-top-gutter">
                                    <ul className="nav nav-collapse">
                                      <li>
                                        <span className="sub-item">
                                          <a  to="/template-creator" role="button" href="/template">Form Creator</a>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="sub-item">
                                          <a  to="/groups" role="button" href="/groups">Groups</a>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="sub-item">
                                          <a  to="/audits" role="button" href="/audits">Inspections</a>
                                        </span>
                                      </li>
                                      { superAdmin == 'SuperAdmin' &&
                                        <li>
                                          <span className="sub-item">
                                            <a  to="/audits" role="button" href="/template/all_template">Form Duplication</a>
                                          </span>
                                        </li>
                                      } 
                                    </ul>
                                  </div>
                                </div>              

                                <div className="border-bottom white">
                                  <a ariaExpanded="false" className="collapsed white" data-toggle="collapse" href="#complyloop">
                                    <div className="row"> 
                                      <div className="col-sm-1">
                                        <i className="fa fa-check-square-o white"></i>
                                      </div>
                                      <div className="col-sm-8">
                                        <span className="left-shim white">
                                          ComplyLoop
                                        </span>
                                      </div>
                                      <div className="col-sm-1">
                                        <span className="caret"></span>
                                      </div>
                                    </div>
                                  </a>

                                  <div id="complyloop" className="collapse shim-top-gutter">
                                    <ul className="nav nav-collapse">
                                      <li>
                                        <span className="left-shim white">
                                          <Link to={{
                                                  pathname: '/client',
                                                  search: '?company_name='+client.toLowerCase(),
                                                  state: { company_name: client.toLowerCase() }
                                              }} id="hsms-documents-url" className="white" target="_blank">HSMS Documents</Link>

                                        </span>
                                      </li>
                                      <li>
                                        <span className="left-shim white">
                                            <a className="white" to="/company_documents" role="button" href="/company_documents">Company Documents</a>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="left-shim sub-item">
                                          <a  to="/sub-contractor" role="button" href="/sub-contractor">Subcontractors</a>
                                        </span>
                                      </li>
                                      { superAdmin == 'SuperAdmin' &&
                                        <li>
                                          <span className="sub-item">
                                            <a  to="/audits" role="button" href="/template/all_template">Form Duplication</a>
                                          </span>
                                        </li>
                                      } 
                                    </ul>
                                  </div>
                                </div> 

                                <div className="border-bottom white">

                                  <div className="row"> 
                                    <div className="col-sm-1">    
                                      <i className="fa fa-exclamation-triangle whitey"></i>
                                    </div>
                                    <div className="col-sm-8">
                                      <span className="left-shim white">
                                          <a className="white" to="/emergency_plans" role="button" href="/emergency_plans">Emergency Plans</a>
                                      </span>
                                    </div>
                                    

                                  </div>
                                </div>


                                <div className="border-bottom white">
                                  
                                  <div className="row"> 
                                    <div className="col-sm-1">  
                                      <img src={"/main-images/rat-white.png"} style={{height:"26px",marginLeft:"-5px"}} />
                                    </div>
                                    <div className="col-sm-8">
                                      <span className="left-shim white">
                                        <a className="white" to="/template-creator" role="button" href="/ctra">Risk Assessment Tool</a>
                                      </span>
                                    </div>
                                    
                                  </div>
                                </div>

                                <div className="border-bottom white">
                                  
                                  <div className="row"> 
                                    <div className="col-sm-1">  
                                      <img src={"/main-images/rat-white.png"} style={{height:"26px",marginLeft:"-5px"}} />
                                    </div>
                                    <div className="col-sm-8">
                                      <span className="left-shim white">
                                        <a className="white" to="/risk_types" role="button" href="/risk_types">RAT Risk Types</a>
                                      </span>
                                    </div>
                                    
                                  </div>
                                </div>

                                <div className="border-bottom white">
                                  
                                  <div className="row"> 
                                    <div className="col-sm-1">
                                      <i className="fa fa-check whitey" aria-hidden="true"></i>
                                    </div>
                                    <div className="col-sm-8">
                                      <span className="left-shim white">
                                        <a  to="/actions" role="button" className="white" href="/actions">Notices</a>
                                      </span>
                                    </div>
                                    
                                  </div>
                                </div>

                                <div className="border-bottom white">
                                  
                                  <div className="row"> 
                                    <div className="col-sm-1">  
                                      <i className="fa fa-sticky-note whitey" aria-hidden="true"></i>
                                    </div>
                                    <div className="col-sm-8">
                                      <span className="left-shim white">
                                        <a  to="/release_notes" className="white" role="button" href="/release_notes">Release Notes</a>
                                      </span>
                                    </div>
                                    
                                  </div>
                                </div>

                                {/* { superAdmin == 'SuperAdmin' &&
                                  <div className="border-bottom white">
                                    
                                    <div className="row"> 
                                      <div className="col-sm-1">  
                                        <i className="fa fa-clone whitey" aria-hidden="true"></i>
                                      </div>
                                      <div className="col-sm-8">
                                        <span className="left-shim white">
                                          <a  to="/template-creator" className="white" role="button" href="/ctra?company=duplicate">Ctra Duplication</a>
                                        </span>
                                      </div>
                                    </div>
                                      
                                    
                                    
                                  </div>
                                } */}
                                
                                
                                
                                    <div className="row  larger-white-row">
                                    </div>
                                <ul className="nav nav-pills nav-stacked">
                                    {this.renderTopAdmin()}
                                    
                                    <li role="presentation" >
                                      <div className="row"> 
                                        <div className="col-sm-1">  
                                          <i className="fa fa-user" aria-hidden="true"></i>
                                        </div>
                                        <div className="col-sm-8">
                                          <a  className="whitey" to="/user-profile" role="button" href="/user-profile">Profile</a>
                                        </div>
                                      </div>    
                                    </li>
                                    
                                </ul>



                                <span style={{color:"#fff", fontSize:"10px", marginTop:"200px"}}>
                                    v2.1.0
                                </span>
                                
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-sm-10">
                    <div className="content">
                        <div className="container-fluid" >
                            <div className="row">

                                <div className="col-sm-4">
                                    <label className="control-label">Group</label>
                                    <Select
                                    name="form-field-name"
                                    value={this.state.fields["group_id"]}
                                    onChange={this.handleChange.bind(this,"group_id")}
                                    options={groups}
                                    id="group_id"
                                    clearable={false}
                                    defaultValue="-"
                                    />
                                </div>

                                <div className="col-sm-4">
                                    <label className="control-label">Form Type</label>
                                    <Select
                                    name="form-field-name"
                                    value={this.state.fields["template_id"]}
                                    onChange={this.handleChange.bind(this,"template_id")}
                                    options={audit_types}
                                    id="role_id"
                                    clearable={false}
                                    />
                                </div>

                                <div className="col-sm-2">
                                    <label className="control-label">Auditor</label>
                                    <Select
                                    name="form-field-name"
                                    value={this.state.fields["auditor"]}
                                    onChange={this.handleChange.bind(this,"auditor")}
                                    options={owners}
                                    id="auditor"
                                    clearable={false}
                                    />
                                </div>

                                <div className="col-sm-2 filter_button" id="home-reset-filter" style={{paddingTop: "25px"}}>
                                    <button type="button" className="btn btn-primary" onClick={this.resetfilter.bind()}>Reset Filter</button>
                                </div>
                                {/* <div className="col-sm-2 filter_button" id="home-reset-filter" style={{paddingTop: "25px"}}>
                                    <button type="button" className="btn btn-primary" onClick={this.resetfilter.bind()}>Reset Filter</button>
                                </div> */}


                            </div>

                            <div>
                                <h2>Statistics</h2>
                            </div>
                            

                        


                            <StatsOverview stats={ audits_stats }/>
                            <br />
                            <br />
                            



                            <div className="row shim-top-gutter">
                                <div className="col-md-4 ">
                                    <div className="top-line-widget-container white-back col3-mid-data" style={{textAlign: 'center'}}>
                                        <h2>Average Daily Forms</h2>
                                        <div className="row shim-top-gutter-medium " >

                                            <div className="col-md-12 inner-widget">
                                                <span><i style={{display:'inline', marginRight:"15px"}} className="fa fa-calendar"></i></span>
                                                <span id="avg_audits" className="daily-audit"></span>
                                            </div>

                                        </div>
                                    </div>

                                </div>



                                <div className="col-md-4 ">
                                    <div className="top-line-widget-container white-back  col3-mid-data" style={{textAlign: 'center'}}>
                                        <h2>All forms</h2>
                                        <div className="row shim-top-gutter-medium " >
                                            <div className="col-md-12 inner-widget">
                                                <span><i style={{display:'inline', marginRight:"5px"}} className="fa fa-archive"></i> </span>
                                                <span   id="required_audits" className="daily-audit">{}</span>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-4 ">
                                    <div className="top-line-widget-container white-back  col3-mid-data" style={{textAlign: 'center'}}>
                                        <h2>Total Auditors</h2>
                                        <div className="row shim-top-gutter-medium ">
                                            <div className="col-md-12 inner-widget" >
                                                <span><i style={{display:'inline', marginRight:"15px"}} className="fa fa-address-card"></i></span>
                                                <span id="total_auditors" className="daily-audit"></span>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>


                            <AnalyticsOverview audit_types={audit_types}/>
                            <br/>
                            <DataOverview result={result} client={client}/>
                            <SubContractorsDashboard subcontractors={subcontractors}
                                                     subcontractor_info={subcontractor_info}
                                                     project_shared_info={project_shared_info}
                                                     user_trainings={user_trainings_info}
                                                     isOpen={status}
                                                     onClientSelect={this.onClientSelect}
                                                     onProjectSelect={this.onProjectSelect}
                                                     onEmployeeSelect={this.onEmployeeSelect}
                                                     onModalClose={this.onModalClose} />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
 }
}

export default UserDashboard;
