import React from 'react';
import PlaceholderImage from '../../../../assets/images/placeholder_image.png';
import 'animate.css';
import ScrollAnimation from 'react-animate-on-scroll';

export default function FeaturesSection({ headerText, subText, ctaLink = "https://www.go.safetyloop.com/contact-us#anchor", ctaButtonTitle = "Learn More", featureHighlights = [], featureImageSrc = PlaceholderImage, sectionAlign = "image-left", sectionBackground = "white" }) {

    const imageSection = <div className="section-image-container">
        <img src={featureImageSrc} alt="" />
    </div>

    const textSection = <div className="section-text-container">
        <h2><b>{headerText}</b></h2>
        <h4>{subText}</h4>
        <ul>
            {featureHighlights.map(x => (<li>{x}</li>))}
        </ul>
        <a href={ctaLink} target="_blank">
            <button type="button" className="section-cta-btn">{ctaButtonTitle}</button>
        </a>
    </div>

    const UIElements = [];

    if (sectionAlign == "image-left") {
        UIElements.push(imageSection);
        UIElements.push(textSection);
    } else {
        UIElements.push(textSection);
        UIElements.push(imageSection);
    }

    return (
        <div className='features-section' style={{ backgroundColor: "#e6e8e8" }}>
            <div className='section-container'>
                {UIElements.map((item, key) => {
                    return <ScrollAnimation
                        key={key}
                        animateIn="animate__fadeIn"
                        duration={0.75}
                        animateOnce={true}
                        delay={0}
                        style={{
                            width: "50%",
                            display: "flex",
                            justifyContent: "center"
                        }}
                        className='animator-container'
                        children={item} />
                })}
            </div>
        </div>
    )
}
