import React from 'react';
import HeroSection from './CommonComponents/HeroSection';
import FeaturesSection from './CommonComponents/FeaturesSection';
import ParallaxSection from '../../pages/HomePage/Components/ParallaxSection';
import HomepageDemoSection from '../../pages/HomePage/Components/HomepageDemoSection';

export default function Analytics() {
    return (
        <>
            <HeroSection
                heroText={"Analytics"}
                subText={"Generate insights with SafetyLoop's comprehensive analytics tools for better decision-making."}
            />
            <FeaturesSection
                headerText={"Comprehensive Analytics"}
                subText={"Monitor safety metrics, compliance, and performance trends with advanced analytics."}
                featureHighlights={[
                    "Track safety performance across multiple parameters.",
                    "Identify trends and areas for improvement.",
                    "Generate custom reports for stakeholders.",
                    "Gain actionable insights for decision-making."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for Analytics section here
            />
            <FeaturesSection
                headerText={"Custom Analytics Reports"}
                subText={"Create custom analytics reports to meet your organization's unique requirements."}
                featureHighlights={[
                    "Set up custom analytics dashboards.",
                    "Define report parameters based on your needs.",
                    "Easily share analytics reports with stakeholders."
                ]}
                // Placeholder: Add image for Custom Analytics section here
            />
            <FeaturesSection
                headerText={"Real-Time Data Insights"}
                subText={"Access real-time data insights to stay informed and responsive."}
                featureHighlights={[
                    "Monitor safety and compliance metrics in real-time.",
                    "Set up alerts for key performance indicators.",
                    "Make data-driven decisions based on real-time insights."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for Real-Time Insights section here
            />
            <div className="Home-Page sl-home-wrapper" style={{ paddingTop: 0 }}>
                <ParallaxSection />
            </div>
            <div className="features-homepage-wrapper">
                <HomepageDemoSection mergeTop={false} />
            </div>
        </>
    );
}
