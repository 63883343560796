
import React, { useEffect, useState } from "react";
import axios from "../../../../config/axiosConfig";
import { toast } from "react-toastify";
import Application from "../../../../config/ApplicationWrapper";
import Loader from "../../../../components/NewLoader";
// import EditIcon from "../../../../assets/fonts/edit.svg";
import { TableContainer, Paper, Pagination, MenuItem, FormControl, Select, Switch, Button, TextField } from '@mui/material';
// import { validateDateTime } from "@mui/x-date-pickers/internals";

const Geofence = (props) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(30);
  const [pageCount, setPageCount] = useState(0);
  const [pageDetails, setPageDetails] = useState([]);
  const [groupName, setSearchByGroupName] = useState();
  const [show, setShow] = useState(false);
  const [showDetails, setShowDetails] = useState();
  const [loading, setLoading] = useState(true);
  const [searchable, setSearchable] = useState(false);
  const [debouncedValue, setDebouncedValue] = useState(300);

  useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getPageDetails()
        }, 2000)

        return () => clearTimeout(delayDebounceFn)
  }, [groupName, limit])

  const handleChangePage = (event, value) => {
    setPage(value);
    getPageDetails()
  };

  const handleLimitChange = (value) => {
    setLoading(true);
    setPage(1);
    setLimit(value);
  }

  const getPageDetails = async () => {
    try {
      setLoading(true)
      let resultObj = await axios.get(`${Application.api_url}/api/v2/time_trackers/geofences`, {
        params: {
          page: page,
          limit: limit,
          name_cont: groupName
        }
      })

      if (resultObj.status == 200) {
        let details = resultObj.data;

        setPageDetails(details.data);
        setPageCount(details.metadata.pages);
        setSearchable(details.data.length > 0? true : false);
        setLoading(false);
      }
    }
    catch (err) {
      console.log("error==>", err);
    }
  }

  const clearFilter = () => {
    setLoading(true);
    setSearchByGroupName('');
  }

  const handleUpdate = async (identifier, key, val) => {
    const groupIndex = pageDetails.findIndex(el => el.identifier === identifier)
    pageDetails[groupIndex][key] = val;

    let params = {};
    params[key] = val;

    const updatedDetails = pageDetails.map(item => item.identifier === identifier ? ({ ...item, ...params }) : item)
    setPageDetails(updatedDetails);
    setTimeout(async () => {
      await axios.patch(`${Application.api_url}/api/v2/time_trackers/geofences/${identifier}`, params).then(result => {
        if (result.data && result.data.success === true) {
          toast.success('Updated successfully.');
        }
      }).catch(err => {
        toast.warning(err.response.data.message);
      })
    }, 200)
  }

  return (
    <>
            <div class="card asset-tab-landing">
                <div class="card-body">
                    <div class="tab-content">
                        <div
                            id="active-risk-assessment"
                            class="tab-pane fade in active active-risk-assessment internal-sub-employee"
                        >
                            <div className="nestedTable pf-table">
                                <div className="fields-setting">
                                    <div className="asset-active-filters">
                                        Active Filters :
                                        <span className="active-filters">
                                            <span>Group Name :</span>
                                            <span className="selected">{groupName ? groupName : "All"}</span>
                                            <span className={groupName ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setSearchByGroupName("") }}>X</span>
                                        </span>
                                        <span className="active-filters-clear" onClick={(e) => clearFilter()}>
                                            Clear All
                                        </span>
                                    </div>
                                </div>
                                <TableContainer component={Paper}>
                                    <table class="table table-striped">
                                        <thead>
                                        <tr style={{ height: "100px" }}>
                                            <th scope="col" className="risk-assessment-divider">
                                                <div className="d-flex justify-content-between align-item-center">
                                                    Name
                                                </div>
                                                {searchable &&
                                                    <div class="input-group">
                                                        <input
                                                            type="text"
                                                            class="form-control assessment-name"
                                                            placeholder="Search"
                                                            aria-label="Name"
                                                            id="tt_group_name"
                                                            aria-describedby="basic-addon1"
                                                            name="tt_group_name"
                                                            onChange={(e) => {
                                                                setLoading(true);
                                                                setSearchByGroupName(e.target.value)
                                                            }}
                                                            value={groupName}
                                                        />
                                                        <div class="input-group-addon">
                                                                <span class="input-group-text" id="basic-addon1">
                                                                    <i class="fa fa-search" aria-hidden="true"></i>
                                                                </span>
                                                        </div>
                                                    </div>
                                                }
                                            </th>
                                            <th scope="col" className="risk-assessment-divider">
                                                <div className="d-flex justify-content-between align-item-center">
                                                    Latitude
                                                </div>
                                            </th>
                                            <th scope="col" className="risk-assessment-divider">
                                                <div className="d-flex justify-content-between align-item-center">
                                                    Longitude
                                                </div>
                                            </th>
                                            <th scope="col" className="risk-assessment-divider">
                                                <div className="d-flex justify-content-between align-item-center">
                                                    Max Distance Allowed
                                                </div>
                                            </th>
                                            <th scope="col" className="risk-assessment-divider">
                                                <div className="d-flex justify-content-between align-item-center">
                                                    Geofence Required
                                                </div>
                                            </th>
                                            <th scope="col" className="risk-assessment-divider">
                                                <div className="d-flex justify-content-between align-item-center">
                                                    Turn on by group
                                                </div>
                                            </th>
                                            <th scope="col" className="table-action">Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {!loading && pageDetails &&
                                            pageDetails.length > 0 &&
                                            pageDetails.map((group, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            {group.name}
                                                        </td>
                                                        <td>{ group.lat }</td>
                                                        <td>{ group.lon }</td>
                                                        <td>
                                                      <TextField
                                                        type="number"
                                                        label=""
                                                        variant="outlined"
                                                        value={group.geofence_radius}
                                                        onChange={(e) => {
                                                          e.preventDefault();
                                                          const value = e.target.value;
                                                          handleUpdate(group.identifier, 'geofence_radius', value)
                                                        }}
                                                          />
                                                        </td>
                                                        <td className="template-options" style={{ textAlign: "center" }}>
                                                            <Switch
                                                              checked={group.geofence_required}
                                                              onClick={(e) => {
                                                                e.preventDefault()
                                                                setTimeout(() => {
                                                                  handleUpdate(group.identifier,
                                                                    'geofence_required', !group.geofence_required)
                                                                }, 100)
                                                              }}
                                                            />
                                                        </td>
                                                        <td className="template-options" style={{ textAlign: "center" }}>
                                                            <Switch
                                                        checked={group.turn_on_by_group}
                                                        onClick={(e) => {
                                                          e.preventDefault()
                                                          setTimeout(() => {
                                                            handleUpdate(group.identifier,
                                                              'turn_on_by_group', !group.turn_on_by_group)
                                                          }, 100)
                                                        }}
                                                            />
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                )
                                            })}
                                        <Loader pageDetails={pageDetails} loading={loading} btnName={"+ Add Group"} open={open} />
                                        </tbody>
                                    </table>
                                </TableContainer>
                                <div className="page-limits">
                                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                        <Select
                                            labelId="demo-select-small"
                                            id="demo-select-small"
                                            value={limit}
                                            onChange={(e) => {
                                                handleLimitChange(e.target.value);
                                            }}
                                        >
                                            <MenuItem value={5}>5</MenuItem>
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={20}>20</MenuItem>
                                            <MenuItem value={30}>30</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {!loading && (
                                        <Pagination
                                            count={pageCount}
                                            page={page}
                                            variant="outlined"
                                            shape="rounded"
                                            onChange={handleChangePage}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default Geofence;
