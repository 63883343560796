import React from "react"
import '../../assets/styles/themes.scss'
import CheckoutHeaderLogo from "./CheckoutHeaderLogo.svg"
import CheckoutCartIcon from "./CheckoutCartIcon"
import { Link } from "react-router-dom"

const CheckoutHeader = () => {
  return (
    <div className="header">
      <div className="eContainer header_content">
        <Link to="/">
          <img src={CheckoutHeaderLogo} alt="" />
        </Link>

        <div className="options"></div>

        {/* <div className="info">
          <div className="info__cart">
            <CheckoutCartIcon />
            <div className="cart__badge">1</div>
          </div>
          <div className="info__button">Login</div>
        </div> */}
      </div>
    </div>
  )
}

export default CheckoutHeader
