import React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import {Badge} from "react-bootstrap";

const AssignedTrainings = ({trainings}) => {

    const renderSizePerPageDropDown = (props) => {
        return (
            <SizePerPageDropDown
                open={props.open}
                className="my-size-per-page"
                btnContextual="btn-warning"
                variation="dropup"
                onClick={() => this.onToggleDropDown(props.toggleDropDown)}
            />
        );
    };

    const options = {
        sizePerPageDropDown: renderSizePerPageDropDown,
        sizePerPageList: [
            {
                text: "5",
                value: 5,
            },
            {
                text: "10",
                value: 10,
            },
            {
                text: "All",
                value: trainings.length,
            },
        ],
        prePage: "Prev", // Previous page button text
        nextPage: "Next", // Next page button text
        firstPage: "First", // First page button text
        lastPage: "Last", // Last page button text
        prePageTitle: "Go to previous", // Previous page button title
        nextPageTitle: "Go to next", // Next page button title
        firstPageTitle: "Go to first", // First page button title
        lastPageTitle: "Go to Last", // Last page button title
    };

    const trClassFormat = (rowData, rIndex) => {
        return rIndex % 2 === 0 ? "" : ""; //tr-function-example
    };

    const auditLink = (rowData, rIndex) => {
        return (
            <a href={rowData} target="_blank">
                View
            </a>
        );
    };

    const dateDiff = (expiry_date) => {
        let current_date = new Date()
        current_date = current_date.setHours(0,0,0,0)
        return (new Date(expiry_date) - current_date) / (1000 * 3600 * 24)
    }
    const auditStatus = (cell, row) => {
        return (
            <Badge style={{background: cell === 'Active' ? '#7fdf7980' : '#ff000021', color: cell === 'Active' ? 'green' : 'red'}}>{cell}</Badge>
        )
    }

    const auditStatusChild = (cell, row) => {
        let date_diff = row.expiry_date === '-' ? undefined : cell === 'Active' ? dateDiff(row.expiry_date) : 0
        date_diff = date_diff <= 30 ? 30 : date_diff <= 60 ? 60 : date_diff <= 90 ? 90 : date_diff
        let textColor = cell === 'Expired' ? 'red' : date_diff <= 30 ? '#ff6347' : date_diff <= 60 ? '#ffa500' : date_diff <= 90 ? '#09ff09' : 'green';
        let backgroundColor = cell === 'Expired' ? '#ff000021' : date_diff <= 30 ? '#ff634738' : date_diff <= 60 ? '#ffa5003b' : date_diff <= 90 ? '#09ff0926' : '#7fdf7980';
        return (
            <Badge style={{background: backgroundColor, color: textColor}}>
                {date_diff && cell === "Active"? date_diff +" Days " + cell : cell}
            </Badge>
        )
    }

    const expandColumnComponent = ({ isExpandableRow, isExpanded }) => {
        return <span className={isExpanded ? "fa fa-caret-up" : "fa fa-caret-down"}>
            </span>;
    };

    const BSTable = ({ data }) => {
        const recordOptions = {
            sizePerPageDropDown: renderSizePerPageDropDown,
            sizePerPageList: [
                {
                    text: "5",
                    value: 5,
                },
                {
                    text: "10",
                    value: 10,
                },
                {
                    text: "All",
                    value: data.length,
                },
            ],
            prePage: "Prev", // Previous page button text
            nextPage: "Next", // Next page button text
            firstPage: "First", // First page button text
            lastPage: "Last", // Last page button text
            prePageTitle: "Go to previous", // Previous page button title
            nextPageTitle: "Go to next", // Next page button title
            firstPageTitle: "Go to first", // First page button title
            lastPageTitle: "Go to Last", // Last page button title
        };

        return (
            <div className="record-table">
                <BootstrapTable data={data} options={recordOptions}>
                    <TableHeaderColumn width="30%" tdStyle={{borderLeft: '1px solid #ddd', borderRight: 0, borderTop: '1px solid #ddd', borderBottom: '1px solid #ddd'}}>
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        isKey={true}
                        dataField="completion_date"
                        tdStyle={{borderLeft: 0, borderRight: 0, borderTop: '1px solid #ddd', borderBottom: '1px solid #ddd'}}
                        width="18%"
                    ></TableHeaderColumn>
                    <TableHeaderColumn dataField="expiry_date" width="22%" tdStyle={{borderLeft: 0, borderRight: 0, borderTop: '1px solid #ddd', borderBottom: '1px solid #ddd'}}></TableHeaderColumn>
                    <TableHeaderColumn dataField="status" dataFormat={auditStatusChild} width="19%" tdStyle={{borderLeft: 0, borderRight: 0, borderTop: '1px solid #ddd', borderBottom: '1px solid #ddd'}}></TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="pdf_link"
                        width="11%"
                        dataFormat={auditLink}
                        tdStyle={{borderLeft: 0, borderTop: '1px solid #ddd', borderBottom: '1px solid #ddd', borderRight: '1px solid #ddd'}}
                        thStyle={{backgroundColor: 'white', borderBottom: '1px solid #ddd', borderLeft: 0}}
                    >
                        Preview
                    </TableHeaderColumn>
                </BootstrapTable>
            </div>
        );
    };

    const isExpandableRow = (row) => {
        if (row.id <= trainings.length) return true;
        else return false;
    };

    const expandComponent = (row) => {
        return <BSTable data={row.record} />;
    };

    return (
        <div className="mb-20">
            <div className="card">
                <div className="card-body content">
                    {/*<h5>Records Of Training</h5>*/}

                    <BootstrapTable
                        className="rot-trainings"
                        data={trainings}
                        trClassName={trClassFormat}
                        options={options}
                        pagination
                        expandableRow={isExpandableRow}
                        expandComponent={expandComponent}
                        expandColumnOptions={{
                            expandColumnVisible: true,
                            expandColumnComponent: expandColumnComponent,
                        }}
                        tableBodyClass="custom-table-spacing"
                        showExpandColumn="true"
                    >
                        <TableHeaderColumn dataField="topic" width="15%"
                                           isKey={true}
                                           tdStyle={{borderLeft: 0, borderRight: 0, borderTop: '1px solid #ddd', borderBottom: '1px solid #ddd'}}
                                           thStyle={{backgroundColor: 'white', borderBottom: '1px solid #ddd', borderLeft: 0, borderRight: 0}}>
                            Training Topic
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="completion_date" width="12%"
                                           thStyle={{backgroundColor: 'white', borderBottom: '1px solid #ddd', borderLeft: 0, borderRight: 0}}
                                           tdStyle={{borderLeft: 0, borderRight: 0, borderTop: '1px solid #ddd', borderBottom: '1px solid #ddd'}}
                        >
                            Completion Date
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="expiry_date" width="14%"
                                           tdStyle={{borderLeft: 0, borderRight: 0, borderTop: '1px solid #ddd', borderBottom: '1px solid #ddd'}}
                                           thStyle={{backgroundColor: 'white', borderBottom: '1px solid #ddd', borderLeft: 0, borderRight: 0}}>
                            Expiration Date
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="status" width="12%" dataFormat={auditStatus}
                                           tdStyle={{borderLeft: 0, borderRight: 0, borderTop: '1px solid #ddd', borderBottom: '1px solid #ddd'}}
                                           thStyle={{backgroundColor: 'white', borderBottom: '1px solid #ddd', borderRight: 0, borderLeft: 0}}>
                            Status
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="pdf_link"
                            width="8%"
                            dataFormat={auditLink}
                            tdStyle={{borderLeft: 0, borderTop: '1px solid #ddd', borderBottom: '1px solid #ddd', borderRight: '1px solid #ddd'}}
                            thStyle={{backgroundColor: 'white', borderBottom: '1px solid #ddd', borderLeft: 0}}
                        >
                            Preview
                        </TableHeaderColumn>
                    </BootstrapTable>
                </div>
            </div>
        </div>
    );
};

export default AssignedTrainings;