import React from "react";
import SubContractorsDashboard from "./SubContractorsDashboard";
import UserManagement from "../shared/UserManagement";

let axios = require("axios");

const initialState = {
    subcontractors: [],
    subcontractor_info: { base_info: {}, employees: [], shared_documents: [], assigned_projects: [] },
    project_shared_info: { shared_forms: [], files: [] },
    user_trainings_info: { user_info: [], trainings: [] },
    status: false,
};

class SubContractorsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    load_subcontractors_data = async () => {
        const response = await axios.get("/subcontractor_dashboard", {
            params: {},
        });
        this.setState({ subcontractors: response.data });
    };

    view_subcontractor_data = async (company_id) => {
        const response = await axios.get(`/subcontractor_dashboard/${company_id}`, {
            params: {},
        });
        this.setState({ subcontractor_info: response.data });
    };

    fetch_project_data = async (params) => {
        const response = await axios.get("/subcontractor_dashboard/project_info", {
            params: { group_id: params["group_id"], company: params["company"] },
        });
        this.setState({ project_shared_info: response.data });
    };

    fetch_employee_data = async (params) => {
        const response = await axios.get("/subcontractor_dashboard/user_info", {
            params: { user_id: params["user_id"] },
        });
        this.setState({ user_trainings_info: response.data, status: true });
    };

    onClientSelect = (client) => {
        this.view_subcontractor_data(client.id).then((r) => console.log(r));
    };

    onProjectSelect = (params) => {
        this.fetch_project_data(params).then((r) => console.log(r));
    };

    onEmployeeSelect = (params) => {
        this.fetch_employee_data(params).then((r) => console.log(r));
    };

    onModalClose = () => {
        this.setState({ status: false });
    };

    componentDidMount() {
        this.load_subcontractors_data().then((r) => console.log(r));
    }

    render() {
        const {
            subcontractors,
            subcontractor_info,
            project_shared_info,
            user_trainings_info,
            status,
        } = this.state;

        return (
            <React.Fragment>
                <div className="sub-contractor-wrap content">
                    <div className="sub-navbar sub-navbar__header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12 sub-navbar-column">
                                    <div className="sub-navbar-header"></div>
                                    <ol className="breadcrumb navbar-text navbar-right no-bg"></ol>
                                </div>
                                <div className="col-lg-12"></div>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid">
                        <div className="row">
                            <UserManagement source="stats-settings" />
                            <div className="col-lg-9 admin-content">
                                <SubContractorsDashboard
                                    subcontractors={subcontractors}
                                    subcontractor_info={subcontractor_info}
                                    project_shared_info={project_shared_info}
                                    user_trainings={user_trainings_info}
                                    isOpen={status}
                                    onClientSelect={this.onClientSelect}
                                    onProjectSelect={this.onProjectSelect}
                                    onEmployeeSelect={this.onEmployeeSelect}
                                    onModalClose={this.onModalClose}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default SubContractorsPage;