import React from "react";
export const UserGroupIcons = {
  QRCode: (
    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24"><g><rect fill="none" height="24" width="24" /></g><g><g><path d="M3,11h8V3H3V11z M5,5h4v4H5V5z" /><path d="M3,21h8v-8H3V21z M5,15h4v4H5V15z" /><path d="M13,3v8h8V3H13z M19,9h-4V5h4V9z" /><rect height="2" width="2" x="19" y="19" /><rect height="2" width="2" x="13" y="13" /><rect height="2" width="2" x="15" y="15" /><rect height="2" width="2" x="13" y="17" /><rect height="2" width="2" x="15" y="19" /><rect height="2" width="2" x="17" y="17" /><rect height="2" width="2" x="17" y="13" /><rect height="2" width="2" x="19" y="15" /></g></g></svg>
  ),
  view: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect width="24" height="24" fill="white" />
      <path
        d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
        stroke="#080808"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22 12C19.333 16.667 16 19 12 19C8 19 4.667 16.667 2 12C4.667 7.333 8 5 12 5C16 5 19.333 7.333 22 12Z"
        stroke="#080808"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  edit: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clip-path="url(#clip0_9550_16620)">
        <rect
          width="24"
          height="24"
          transform="translate(0 0.000976562)"
          fill="white"
        />
        <path
          d="M9 7.00098H6C5.46957 7.00098 4.96086 7.21169 4.58579 7.58676C4.21071 7.96184 4 8.47054 4 9.00098V18.001C4 18.5314 4.21071 19.0401 4.58579 19.4152C4.96086 19.7903 5.46957 20.001 6 20.001H15C15.5304 20.001 16.0391 19.7903 16.4142 19.4152C16.7893 19.0401 17 18.5314 17 18.001V15.001"
          stroke="#080808"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9 15.0012H12L20.5 6.5012C20.8978 6.10338 21.1213 5.56381 21.1213 5.0012C21.1213 4.43859 20.8978 3.89903 20.5 3.5012C20.1022 3.10338 19.5626 2.87988 19 2.87988C18.4374 2.87988 17.8978 3.10338 17.5 3.5012L9 12.0012V15.0012Z"
          stroke="#080808"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9550_16620">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.000976562)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  archive: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clip-path="url(#clip0_9550_7109)">
        <rect
          width="24"
          height="24"
          transform="translate(0 0.000976562)"
          fill="white"
        />
        <path
          d="M4 7.00098H20"
          stroke="#D92929"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10 11.001V17.001"
          stroke="#D92929"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14 11.001V17.001"
          stroke="#D92929"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5 7.00098L6 19.001C6 19.5314 6.21071 20.0401 6.58579 20.4152C6.96086 20.7903 7.46957 21.001 8 21.001H16C16.5304 21.001 17.0391 20.7903 17.4142 20.4152C17.7893 20.0401 18 19.5314 18 19.001L19 7.00098"
          stroke="#D92929"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9 7.00098V4.00098C9 3.73576 9.10536 3.48141 9.29289 3.29387C9.48043 3.10633 9.73478 3.00098 10 3.00098H14C14.2652 3.00098 14.5196 3.10633 14.7071 3.29387C14.8946 3.48141 15 3.73576 15 4.00098V7.00098"
          stroke="#D92929"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9550_7109">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.000976562)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  ArrowRight: (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" fill="white" />
      <path d="M7.08337 15.833L12.9167 9.99967L7.08337 4.16634" stroke="#080808" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  ),
  duplicate: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_9892_7005)">
        <rect width="24" height="24" transform="translate(0 0.000976562)" fill="white" />
        <path d="M18 8.00098H10C8.89543 8.00098 8 8.89641 8 10.001V18.001C8 19.1055 8.89543 20.001 10 20.001H18C19.1046 20.001 20 19.1055 20 18.001V10.001C20 8.89641 19.1046 8.00098 18 8.00098Z" stroke="#080808" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M16 8.00098V6.00098C16 5.47054 15.7893 4.96184 15.4142 4.58676C15.0391 4.21169 14.5304 4.00098 14 4.00098H6C5.46957 4.00098 4.96086 4.21169 4.58579 4.58676C4.21071 4.96184 4 5.47054 4 6.00098V14.001C4 14.5314 4.21071 15.0401 4.58579 15.4152C4.96086 15.7903 5.46957 16.001 6 16.001H8" stroke="#080808" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_9892_7005">
          <rect width="24" height="24" fill="white" transform="translate(0 0.000976562)" />
        </clipPath>
      </defs>
    </svg>
  ),
  requiredDocument: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_9892_3101)">
        <rect width="24" height="24" transform="translate(0 0.000976562)" fill="white" />
        <path d="M5 4.00098H9L12 7.00098H19C19.5304 7.00098 20.0391 7.21169 20.4142 7.58676C20.7893 7.96184 21 8.47054 21 9.00098V17.001C21 17.5314 20.7893 18.0401 20.4142 18.4152C20.0391 18.7903 19.5304 19.001 19 19.001H5C4.46957 19.001 3.96086 18.7903 3.58579 18.4152C3.21071 18.0401 3 17.5314 3 17.001V6.00098C3 5.47054 3.21071 4.96184 3.58579 4.58676C3.96086 4.21169 4.46957 4.00098 5 4.00098" stroke="#080808" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M12 10.001V16.001" stroke="#080808" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M9 13.001H15" stroke="#080808" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_9892_3101">
          <rect width="24" height="24" fill="white" transform="translate(0 0.000976562)" />
        </clipPath>
      </defs>
    </svg>
  ),
  clearInput: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12" aria-hidden="true">
      <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
    </svg>
  ),
  closeIcon: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
      <path d="M18 6L6 18M6 6l12 12" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
};
