import React from 'react';
import { Controller, useForm } from "react-hook-form";
import useFetch from "../../../shared/components/userFetch";
import FormWrapper from "../../../shared/components/formWrapper";
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import DualListbox from '../../../shared/components/dualListBox';
import { toast } from 'react-toastify';
import useUpdate from '../../../shared/components/useUpdate';

const BulkAssignJobProfile = ({selectedUsers = [], onCancel, refetch, setSelectedRows}) => {
    const url = '/api/v2/entity_management/users/load_bulk_assign_job_profiles'
    let params = '';
    selectedUsers.forEach((e, i) => {
        params += i === 0 ? `?user_ids[]=${e}` : `&user_ids[]=${e}`
    })
    const {data: baseData, isLoading} = useFetch(`${url}${params}`);
    const {mutate, isLoading: isMutating} = useUpdate();
    const {data: job_profiles = []} = baseData || {};
    const { control, register, handleSubmit, setValue, trigger, formState: { errors } } = useForm({
        defaultValues: {
            job_profile_ids: []
        }
    });
    const onSubmit = (data) => {
        if (!data.job_profile_ids.length) {
            return false;
        }
        let extraParams = ''
        data.job_profile_ids.forEach((e => {
            extraParams += `&job_profile_ids[]=${e.value}`
        }))
        mutate({},
            {onSuccess: () => {
                onCancel();
                toast('Successfully Assigned', {
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    theme: "light",
                    type: "success"
                });
                refetch();
                setSelectedRows([]);
            }},
            `/api/v2/entity_management/users/bulk_assign_job_profiles${params}${extraParams}`
        )
    }

    if (isLoading) {
        return null;
    }

    return (
        <FormWrapper>
            <Box className="f-modal">
                <Typography id="modal-title" sx={{padding: '16px'}} variant="h6" component="h2">
                    Assign Job Profile
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)} style={{height: 'calc(100% - 55px)', paddingInline: '24px'}}>
                    <Box sx={{ mt: 2 }} style={{ overflowY: 'auto', height: 'calc(100% - 100px)' }}>
                        <Controller
                            name="job_profile_ids"
                            control={control}
                            render={({ field: { value = [] } }) => (
                                <DualListbox 
                                    control={control} 
                                    setValue={setValue} 
                                    name="job_profile_ids" 
                                    leftData={job_profiles.filter(job => value.every(val => val.value !== job.value))}
                                    rightData={value}
                                    showTags
                                />
                            )}
                        />
                    </Box>
                    <Box mt={2} sx={{display: 'flex', justifyContent: 'flex-end', gap: '10px'}}>
                        <Button style={{textTransform: 'none', backgroundColor: '#ECECEC', color: '#5C5C5C', marginRight: '10px', outline: 'none'}} onClick={onCancel}>
                            Cancel
                        </Button>
                        <Button endIcon={isMutating ? <CircularProgress size={16} color="inherit" /> : null} style={{textTransform: 'none', backgroundColor: '#407797', color: '#fff', outline: 'none'}} disabled={isMutating} type="submit" variant="contained" color="primary">
                            Submit
                        </Button>
                    </Box>
                </form>
            </Box>
        </FormWrapper>
    )
}
export default BulkAssignJobProfile;
