import React from "react";
import { Redirect } from "react-router-dom";
import ActiveRiskAssessment from "./ActiveRiskAssessment";
import AchiveRiskAssessment from "./AchiveRiskAssessment";
import ApproveRiskAssessment from "./ApproveRiskAssessment";
import Cookies from "js-cookie";
const cookieDetails = Cookies.get("token");
import Application from "../../../config/ApplicationWrapper";
import { User } from "../../../pages/HomePage/Login";

class RiskAssessment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      create: false,
      ratId: "",
      activeRATCount: 0,
      archiveRATCount: 0,
      approveRATCount: 0,
      activeRATLastChange: 0,
      archiveRATLastChange: 0,
      approveRATLastChange: 0,
      noteCount: 0,
      tab: "Active",
      currentQuery: '',
    };
    this.exportData = this.exportData.bind(this);
    this.updateActiveRATCount = this.updateActiveRATCount.bind(this);
    this.updateArchiveRATCount = this.updateArchiveRATCount.bind(this);
    this.updateApproveRATCount = this.updateApproveRATCount.bind(this);
    this.updateTab = this.updateTab.bind(this);
    this.updateNoteCount = this.updateNoteCount.bind(this);
    this.updateTabLastChanges = this.updateTabLastChanges.bind(this);
    this.updateCurrentQuery = this.updateCurrentQuery.bind(this);
  }

  exportData = () => {
    let url
    
    switch (this.state.tab) {
      case "Active":
        url = new URL(`${Application.api_url}/ctra/download_csv.csv`);

        break
      case "Archived":
        url = new URL(`${Application.api_url}/ctra/download_csv.csv`);

        break
      case "Approval":
        url = new URL(`${Application.api_url}/ctra/mrc_download.csv`);

        break
    }

    if (this.state.currentQuery) {
      const query = this.state.currentQuery
      url.search = query.toString();
    }
    
    window.open(url.toString());
  };

  updateActiveRATCount(val) {
    this.setState({ activeRATCount: val });
  }

  updateArchiveRATCount = (val) => {
    this.setState({ archiveRATCount: val });
  };

  updateApproveRATCount = (val) => {
    this.setState({ approveRATCount: val });
  };

  updateNoteCount = (val) => {
    this.setState({ noteCount: val });
  };

  updateTab = (val) => {
    this.setState({ tab: val });
  };

  updateTabLastChanges = (tab) => {
    if (tab == "Active") {
      this.setState({ activeRATLastChange: Date.now() });
    } else if (tab == "Archived") {
      this.setState({ archiveRATLastChange: Date.now() });
    } else {
      this.setState({ approveRATLastChange: Date.now() });
    }
  };

  updateCurrentQuery = (newQuery) => {
    this.setState({ currentQuery: newQuery })
  }

  createRat = async () => {
    try {
      const createNewRat = await fetch(`${Application.api_url}/api/v2/rats`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: cookieDetails,
        },
        body: JSON.stringify({
          name: "",
          description: "",
          risk_type_id: "",
          company_id: "",
          approvers: "",
          tasks_attributes: [],
        }),
      });
      const details = await createNewRat.json();
      if (details.success === true) {
        await this.setState({
          create: true,
          ratId: details.rat.identifier,
        });
      }
    } catch (err) {
      console.log("error===>", err);
    }
  };

  render() {
    if (this.state.create) {
      return <Redirect to={`/create-risk-assessment/${this.state.ratId}`} />;
    }

    return (
      <>
        <div class="card">
          <div class="card-body">
            <div className="d-flex align-item-center justify-content-between header-badge">
              <div className="d-flex align-item-center">
                <h3>Risk Assessment </h3>
              </div>
              <div className="btn-style">
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  onClick={(e) => {
                    this.exportData();
                  }}
                >
                  <i class="fa fa-upload" aria-hidden="true"></i>
                  Export
                </button>
                {/* <Link
                to="/create-risk-assessment"
                id="create-risk-assessment-link"
              > */}
                {(User.role == "Admin" ||
                  User.role == "SuperAdmin") && 
                    <button
                      type="button"
                      class="btn btn-primary"
                      onClick={(e) => {
                        this.createRat();
                      }}
                    >
                      <i class="fa fa-plus" aria-hidden="true"></i>
                      Create
                    </button>
                  }
                {/* </Link> */}
              </div>
            </div>
            <ul class="nav nav-tabs">
              <li
                class="active"
                onClick={(e) => {
                  this.updateTab("Active");
                }}
              >
                <a data-toggle="tab" href="#active-risk-assessment">
                  Active Risk Assessment
                  <span class="badge badge-light">
                    {this.state.activeRATCount}
                  </span>
                </a>
              </li>
              <li
                onClick={(e) => {
                  this.updateTab("Archived");
                }}
              >
                <a data-toggle="tab" href="#archived-risk-assessment">
                  Archived Risk Assessment
                  <span class="badge badge-light">
                    {this.state.archiveRATCount}
                  </span>
                </a>
              </li>
              <li
                onClick={(e) => {
                  this.updateTab("Approval");
                }}
              >
                <a data-toggle="tab" href="#document-approvals">
                  Risk Assessment Approval
                  <span class="badge badge-light">
                    {this.state.approveRATCount}
                  </span>
                </a>
              </li>
            </ul>
            <div class="tab-content">
              <div
                id="active-risk-assessment"
                class="tab-pane fade in active active-risk-assessment"
              >
                <ActiveRiskAssessment
                  countUpdate={this.updateActiveRATCount}
                  noteCountUpdate={this.updateNoteCount}
                  updateLastChanges={this.updateTabLastChanges}
                  archiveRATLastChange={this.state.archiveRATLastChange}
                  approveRATLastChange={this.state.approveRATLastChange}
                  updateCurrentQuery={this.updateCurrentQuery}
                  currentTab={this.state.tab}
                />
              </div>
              <div
                id="archived-risk-assessment"
                class="tab-pane fade in active-risk-assessment"
              >
                <AchiveRiskAssessment
                  countUpdate={this.updateArchiveRATCount}
                  updateLastChanges={this.updateTabLastChanges}
                  activeRATLastChange={this.state.activeRATLastChange}
                  approveRATLastChange={this.state.approveRATLastChange}
                  updateCurrentQuery={this.updateCurrentQuery}
                  currentTab={this.state.tab}
                />
              </div>
              <div
                id="document-approvals"
                class="tab-pane fade in active-risk-assessment"
              >
                <ApproveRiskAssessment
                  countUpdate={this.updateApproveRATCount}
                  updateLastChanges={this.updateTabLastChanges}
                  archiveRATLastChange={this.state.archiveRATLastChange}
                  activeRATLastChange={this.state.activeRATLastChange}
                  updateCurrentQuery={this.updateCurrentQuery}
                  currentTab={this.state.tab}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default RiskAssessment;
