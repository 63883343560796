import React, { useEffect, useState, useRef } from "react";
import SideNav from "../../../components/SideNav";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import _ from "lodash"
import { Divider, TextField, Button } from '@mui/material';
import NotifyUsersModel from "../Popups/NotifyUsers";
import AssignedGroupsModel from "../Popups/AssignedGroups";
import Success from "../../../assets/fonts/Success.svg";
import IMG from "../../../assets/fonts/imgfile.png";
import PDFIMG from "../../../assets/fonts/pdf.png";
import Delete from "../../../assets/fonts/Delete.svg";
import EmptyTable from "../../../assets/images/empty-assets.svg";
import EmptyData from "../../../assets/images/asset-default.svg";
import Search from "../../../assets/fonts/Search.svg";
import ShowImagePopup from "../Popups/ShowImg";
import axios from "../../../config/axiosConfig";
import Application from "../../../config/ApplicationWrapper";
import FormHelperText from "@mui/material/FormHelperText";

const Create = (params) => {
    const uploadInputRef = useRef(null);
    const type = params.match.params.type;
    const [open, setOpen] = useState(false);
    const [groupPopup, setGroupPopup] = useState(false);
    const [pageDetails, setPageDetails] = useState();
    const [onSubmit, setSubmit] = useState(false);
    const [notifyUsers, setNotifyUsers] = useState([]);
    const [assignedGroups, setAssignedGroups] = useState([]);
    const [docs, setDocs] = useState([]);
    const [users, setUsers] = useState([]);
    const [userSearch, setUserSearch] = useState();
    const [groupSearch, setGroupSearch] = useState();
    const [showimg, setShowImg] = useState();
    const [deletedimg, setDeletedImg] = useState([]);
    const [groupsList, setGroupsList] = useState([]);
    const [values, setValues] = useState({
        name: "",
        description: "",
    });

    useEffect(() => {
        const getPageDetails = async () => {
            try {
                window.scrollTo(0, 0);
                if (params.match.params.id) {
                    let resultObj = await axios.get(`${Application.api_url}/api/v2/emergency_plans/${params.match.params.id}`);
                    if (resultObj.status == 200 && resultObj.data.success === true) {
                        var details = resultObj.data;
                        setPageDetails(details ? details.data : [])
                        setValues(details.data)
                        setNotifyUsers(details.data.assigned_users);
                        setAssignedGroups(details.data.assigned_groups);
                        setDocs(details.data.files);
                    }
                }

                const groupDetails = await axios.get(`${Application.api_url}/api/v2/common/groups`);
                if (groupDetails.status == 200 && groupDetails.data.success === true) {
                    var details = groupDetails.data;
                    setGroupsList(details.data);
                }

                const usersDetails = await axios.get(`${Application.api_url}/api/v2/common/users`);
                if (usersDetails.status == 200 && usersDetails.data.success === true) {
                    var details = usersDetails.data;
                    setUsers(details.data);
                }

            } catch (err) {
                console.log("error==>", err);
            }
        };
        getPageDetails()
    }, [])

    const saveInfo = async () => {
        try { 
            setSubmit(true);
            if (!values.name || assignedGroups.length == 0) {
                return true;
            }
            const formData = new FormData();
            formData.append("name", values.name);
            formData.append("description", values.description);

            [...notifyUsers].forEach(obj => {
                formData.append("notify_members[]", obj.id);
            });

            [...assignedGroups].forEach(obj => {
                formData.append("notify_groups[]", obj.id);
            });

            [...docs].forEach(image => {
                if (image.lastModified) {
                    formData.append("files[]", image);
                }
            });

            [...deletedimg].forEach(id => {
                formData.append("file_ids[]", id);
            });

            var apiUrl = `${Application.api_url}/api/v2/emergency_plans`;
            var method = 'POST'
            var message = "Created Successfully";

            if (params.match.params.id) {
                apiUrl = `${Application.api_url}/api/v2/emergency_plans/${params.match.params.id}`;
                method = 'PATCH';
                message = "Updated Successfully";
            }

            const result = await axios({
                method: method,
                url: apiUrl,
                data: formData,
            });

            if (result.status == 200 && result.data.success === true) {
                toast.success(message);
                setTimeout(() => {
                    window.location = '/emergency-plans';
                }, 3000)
            }

        } catch (err) {
            console.log("err===>", err);
        }
    };

    const addUsers = () => {
        setOpen(true)
    }

    const addGroups = () => {
        setGroupPopup(true)
    }

    const onhandleClose = (data, type) => {
        if (type === "users") {
            setOpen(false);
            if (data) {
                setNotifyUsers(data)
            }
        } else if (type === "group") {
            setGroupPopup(false);
            if (data) {
                setAssignedGroups(data)
                setSubmit(false);
            }
        } else {
            setShowImg("");
        }
    };

    const removeImg = (id, val) => {
        let img = docs.filter((item, i) => i !== id);
        if (params.match.params.id) {
            setDeletedImg([...deletedimg, ...[val.id]])
        }
        setDocs(img);
    }

    const viewImg = (img) => {
        setShowImg(img)
    }

    function Documents() {
        try {
            if (type != "view" && docs.length === 0) {
                return (
                    <div className="row attachment">
                        <div className="col-md-6 attachment-field">
                            <input
                                color="primary"
                                accept="image/*"
                                type="file"
                                id="icon-button-file"
                                ref={uploadInputRef}
                                multiple={true}
                                style={{ display: 'none', }}
                                onChange={(e) => {
                                    setDocs(Array.from(e.target.files));
                                }}
                            />
                            <Button onClick={() => uploadInputRef.current && uploadInputRef.current.click()} variant="outlined" type="file">Upload </Button>
                        </div>
                        <div className="col-md-6">
                            <div className="notes-field">
                                <span>To upload whatever will assist them in an emergency</span>
                                <ul>
                                    <li>Example : Escape Plan, Instruction Image,Safety Rules</li>
                                    <li>Attached the Safety Rules Chart</li>
                                    <li>Can be uploaded as a PDF or Image, File Size 5MB.</li>
                                </ul>
                            </div>
                        </div>
                    </div>)
            } else if (type != "view" && docs.length != 0) {
                return (
                    <div className="attachments">
                        {docs.map((val, i) => {
                            if ((val.size / 1000) <= 2000) {
                                return (
                                    <div className="list-attachments">
                                        <img src={Success} />
                                        <img src={val.name.split('.').pop().toLowerCase() == 'pdf' ? PDFIMG : IMG} className="file-type" />
                                        <div>
                                            <p>{val.name}</p>
                                            <span>{val.size / 1000}KB</span>
                                        </div>
                                        <img src={Delete} className="delete" onClick={(e) => { removeImg(i, val) }} />
                                    </div>
                                )
                            } else {
                                toast.info("Maximum allowed file size is 2 MB.")
                                removeImg(i)
                            }
                        })}
                    </div>
                )
            } else {
                return (
                    <div className="view-attachments">
                        {
                            docs.map((val, i) => {
                                let find = val.type.split("/");
                                let image = find[0] == "image" ? true : false;
                                return (
                                    <div className="view-list-attachments">
                                        <img className={image ? "show-img" : "show-img pdf"} src={!image ? PDFIMG : val.url} onClick={(e) => { image && viewImg(val.url) }} />
                                        <div className="view-docs">
                                            <p className="docs-name">{val.image}</p>
                                            <span className="updated-date">Uploaded On  {pageDetails.updated_at}</span>
                                            <span className="download-docs" onClick={(e) => { downloadImg(val) }}>Download</span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            }
        } catch (err) {
            console.log("error===>", err)
        }
    }

    const NoAssetsFound = () => (
        <div className="no-assets" style={{ textAlign: "center" }}>
            <img src={EmptyTable} />
            <p>
                <b>No Data</b>
            </p>
        </div>
    );

    const downloadImg = async (val) => {
        try {
            const result = await axios.post(`${Application.api_url}/api/v2/emergency_plans/${params.match.params.id}/convert_attachment_to_base64`, {
                file_id: val.id
            });
            if (result.status == 200 && result.data.success === true) {
                var a = document.createElement("a"); //Create <a>
                a.href = "data:image/png;base64," + result.data.data.base64; //Image Base64 Goes here
                a.download = val.name; //File name Here
                a.click();
            }
        } catch (err) {
            console.log("error===>", err)
        }
    }

    return (
        <>
            <div className="msmcontainer dashboard-container create-plans">
                <div className="row d-flex">
                    <SideNav />
                    <div className="content-wrap col-sm-12 create-risk-assessment">
                        <div className="card">
                            <div className="card-body">
                                <div class="risk-assessment-link">
                                    <Link to="/emergency-plans" id="create-risk-assessment-link">
                                        <i class="fa fa-arrow-left" aria-hidden="true"></i>
                                    </Link>
                                    <h3>
                                        {type === "view" ? "View Emergency Plan" : type === "edit" ? "Edit Emergency Plan" : "Create New Emergency Plan"} </h3>
                                </div>
                                <Divider light />
                                <div className="row create-form">
                                    <div className="col-md-6">
                                        <label className="form-lables">Name
                                            <span style={{ color: "red" }}>*</span>
                                        </label>
                                        <TextField
                                            required
                                            fullWidth
                                            className="text-field"
                                            id="outlined-required"
                                            error={!values.name && onSubmit === true ? true : false}
                                            helperText={
                                                !values.name && onSubmit === true
                                                    ? "Name is required"
                                                    : ""
                                            }
                                            value={values.name}
                                            onChange={(e) => {
                                                setValues({ ...values, ...{ "name": e.target.value } });
                                                setSubmit(false);
                                            }}
                                            disabled={type == "view" ? true : false}
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <label className="form-lables">Description
                                        </label>
                                        <textarea
                                            required
                                            fullWidth
                                            className={type == "view" ? "textarea disable-clr" : "textarea"}
                                            id="outlined-required"
                                            value={values.description}
                                            disabled={type == "view" ? true : false}
                                            onChange={(e) => {
                                                setValues({ ...values, ...{ "description": e.target.value } });
                                            }}
                                        />
                                    </div>
                                </div>
                                <label className="form-lables attachment-lable">Attachment
                                </label>
                                <Documents />
                                <div className="row create-form">
                                    <div className="col-md-6">
                                        <div className="notify-users">
                                            <div className="header">
                                                <label className="form-lables">Notified Users
                                                    <span class="badge badge-light">
                                                        {notifyUsers.length}
                                                    </span>
                                                </label>
                                                {type != "view" &&
                                                    <div className="search-modify">
                                                        <div className="search-input">
                                                            <input
                                                                type="text"
                                                                class="form-control usersearch"
                                                                placeholder="Search"
                                                                aria-label="usersearch"
                                                                id="user-search"
                                                                aria-describedby="basic-addon1"
                                                                name="usersearch"
                                                                onChange={(e) => {
                                                                    setUserSearch(e.target.value);
                                                                }} />
                                                            <img className="search-icon" src={Search} alt="" />
                                                        </div>
                                                        <div className="add-modify" onClick={(e) => { addUsers() }}>Add & Modify Users</div>
                                                    </div>
                                                }
                                            </div>
                                            <Divider light />
                                            {notifyUsers.length === 0 && type != "view" ? (
                                                <div className="notified-field">
                                                    <Button variant="contained" onClick={(e) => { addUsers() }}>+ Add Users</Button>
                                                </div>
                                            ) : (
                                                <div className="notified-user-data">
                                                    {
                                                        notifyUsers.filter((item) => new RegExp(userSearch, 'i').test(item.full_name))
                                                            .map((val, i) => (
                                                                <div className="notified-user-list d-flex">
                                                                    {val.full_name} <p className="user-employee-id"> - {val.employee_id}</p>
                                                                </div>
                                                            ))
                                                    }
                                                    {notifyUsers.length === 0 && type == "view" &&
                                                        <NoAssetsFound />
                                                    }
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="assigned-groups">
                                            <div className="header">
                                                <label className="form-lables">Assigned Groups <span style={{ color: "red" }}>*</span>
                                                    <span class="badge badge-light">
                                                        {assignedGroups.length}
                                                    </span>
                                                </label>
                                                {type != "view" &&
                                                    <div className="search-modify">
                                                        <div className="search-input">
                                                            <input
                                                                type="text"
                                                                class="form-control usersearch"
                                                                placeholder="Search"
                                                                aria-label="Username"
                                                                id="asset-name2"
                                                                aria-describedby="basic-addon1"
                                                                name="name"
                                                                onChange={(e) => {
                                                                    setGroupSearch(e.target.value);
                                                                }} />
                                                            <img className="search-icon" src={Search} alt="" />
                                                        </div>
                                                        <div className="add-modify" onClick={(e) => { addGroups() }}>Add & Modify Groups</div>
                                                    </div>
                                                }
                                            </div>
                                            <Divider light />
                                            {assignedGroups.length === 0 && type != "view" ? (
                                                <div className="assigned-group-field">
                                                    <Button variant="contained" onClick={(e) => { addGroups() }}>+ Add Groups</Button>
                                                </div>
                                            ) : (
                                                <div className="notified-user-data">
                                                    {
                                                        assignedGroups.filter((item) => new RegExp(groupSearch, 'i').test(item.name))
                                                            .map((val, i) => (
                                                                <div className="notified-user-list">
                                                                    {val.name}
                                                                </div>
                                                            ))
                                                    }
                                                    {assignedGroups.length === 0 && type == "view" &&
                                                        <NoAssetsFound />
                                                    }
                                                </div>
                                            )}
                                        </div>
                                        <FormHelperText
                                            className="inputErrorMsg"
                                            error={assignedGroups.length === 0 && onSubmit}
                                        >
                                            {assignedGroups.length === 0 && onSubmit === true ? "Group is required" : ""}
                                        </FormHelperText>
                                    </div>
                                </div>
                                {type != "view" &&
                                    <div className="submit-btns">
                                        <Link to="/emergency-plans"><Button disabled className="btn-cancel">Cancel</Button></Link>
                                        <Button variant="contained" disabled={onSubmit} onClick={(event) => {
                                            saveInfo() 
                                        }
                                        }>
                                            {type == "edit" ? "Update" : "Create"}
                                        </Button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {users && users.length > 0 &&
                <NotifyUsersModel
                    show={open}
                    closepopup={onhandleClose}
                    details={users}
                    rightSide={notifyUsers}
                />
            }
            {groupsList && groupsList.length > 0 &&
                <AssignedGroupsModel
                    show={groupPopup}
                    closepopup={onhandleClose}
                    details={groupsList}
                    rightSide={assignedGroups}
                />
            }
            <ShowImagePopup
                show={showimg ? true : false}
                closepopup={onhandleClose}
                details={showimg}
            />
        </>
    );
};

export default Create;
