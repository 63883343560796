import React, {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import { withRouter, useHistory } from "react-router-dom";
import SearchIcon from "../../../../assets/fonts/Search.svg";
import _ from "lodash";
import {Button, TextField} from "@mui/material";
import axios from "../../../../config/axiosConfig";
import Application from "../../../../config/ApplicationWrapper";
import {toast} from "react-toastify";


const AddTimeCard = ({ history, show, close, groupsList, usersList, rightSideGroups, rightSideUsers }) => {
    const [onSubmit, setSubmit] = useState(false);
    const [loading, setLoading] = useState(true);
    const [checkedGroups, setCheckedGroups] = useState([]);
    const [checkedUsers, setCheckedUsers] = useState([]);
    const [rightGroups, setRightGroups] = useState([]);
    const [rightUsers, setRightUsers] = useState([]);
    const [groupData, setGroupData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [searchGroups, setSearchGroups] = useState("");
    const [searchSelectedGroups, setSearchSelectedGroups] = useState("");
    const [searchUsers, setSearchUsers] = useState("");
    const [searchSelectedUsers, setSearchSelectedUsers] = useState("");
    const [selectedDate, setSelectedDate] = useState(null);

    useEffect(() => {
        let selectedGroups = intersection(groupsList, rightSideGroups);
        let selectedUsers = intersection(usersList, rightSideUsers);
        setRightGroups(selectedGroups);
        setRightUsers(selectedUsers);
        groupsList && setGroupData(not(groupsList, rightSideGroups));
        usersList && setUserData(not(usersList, rightSideUsers));
    }, [show]);

    const onhandleStatusClose = () => {
        close("", "group");
        setRightGroups([]);
        setRightUsers([]);
        setGroupData(groupsList);
        setUserData(usersList);
        setSelectedDate(null);
    };

    function not(a, b) {
        return a.filter((value) => b.indexOf(value.id) === -1);
    }

    function intersection(a, b) {
        return a.filter((value) => b.indexOf(value.id) !== -1);
    }

    const handleToggleAll = (value, isChecked, type) => {
        if (type === "group") {
            if (isChecked) {
                let newValues = _.map(value, "id");
                setCheckedGroups([...newValues]);
            } else {
                setCheckedGroups([]);
            }
        } else if (type === "user") {
            if (isChecked) {
                let newValues = _.map(value, "id");
                setCheckedUsers([...newValues]);
            } else {
                setCheckedUsers([]);
            }
        }
    };

    const handleToggle = (value, type) => () => {
        if (type === "group") {
            const currentIndex = checkedGroups.indexOf(value);
            const newChecked = [...checkedGroups];

            if (currentIndex === -1) {
                newChecked.push(value);
            } else {
                newChecked.splice(currentIndex, 1);
            }
            setCheckedGroups(newChecked);
        } else if (type === "user") {
            const currentIndex = checkedUsers.indexOf(value);
            const newChecked = [...checkedUsers];

            if (currentIndex === -1) {
                newChecked.push(value);
            } else {
                newChecked.splice(currentIndex, 1);
            }
            setCheckedUsers(newChecked);
        }
    };

    const handleCheckedRight = (type) => {
        if (type === "group") {
            let newChecked = intersection(groupData, checkedGroups);
            setRightGroups([...rightGroups, ...newChecked]);
            setGroupData(not(groupData, checkedGroups));
            setCheckedGroups([]);
        } else if (type === "user") {
            let newChecked = intersection(userData, checkedUsers);
            setRightUsers([...rightUsers, ...newChecked]);
            setUserData(not(userData, checkedUsers));
            setCheckedUsers([]);
        }
    };

    const handleCheckedLeft = (type) => {
        if (type === "group") {
            let newChecked = intersection(rightGroups, checkedGroups);
            setGroupData([...groupData, ...newChecked]);
            setRightGroups(not(rightGroups, checkedGroups));
            setCheckedGroups([]);
        } else if (type === "user") {
            let newChecked = intersection(rightUsers, checkedUsers);
            setUserData([...userData, ...newChecked]);
            setRightUsers(not(rightUsers, checkedUsers));
            setCheckedUsers([]);
        }
    };

    const updateDetails = () => {
        close({selectedDate, rightGroups, rightUsers});
        setRightGroups([]);
        setRightUsers([]);
        setSelectedDate(null);
    };

    const create = async () => {
        try {
            setLoading(true);
            setSubmit(true);
            if(!selectedDate) {
                return false;
            }

            if(rightUsers.length == 0 || rightGroups.length == 0) {
                toast.error('Please select users and groups')
                return false;
            }

            var user_ids = rightUsers.map(obj => obj.id.toString())
            await axios
                .post(`${Application.api_url}/api/v2/time_cards`, {
                   date: selectedDate,
                   user_ids: user_ids,
                   group_ids: rightGroups.map(obj => obj.id.toString())
                })
                .then((response) => {
                    response.data.success === true &&
                    history.push(`/time_tracking/${response.data.data.id}/create-work-entries`);
                })
                .catch((error) => {
                    error.response.data.message.map((msg) => {
                        toast.error(msg)
                    });
                });
        } catch (err) {
            console.log("err===>", err);
        }
    }

    return (
            <Modal
                bsSize="lg"
                show={show}
                onHide={(e) => {
                    onhandleStatusClose();
                }}
                className="qr-modal-wrap add-task-modal pf-model Activate multi-select"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>Create Time Card</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <label>
                                Create Date <text style={{color: "red"}}>*</text>
                            </label>
                            <div className="input-group col-md-4">
                                <input
                                    type="date"
                                    className="form-control assessment-name"
                                    placeholder="Search"
                                    aria-label="Username"
                                    id="completionDate"
                                    aria-describedby="basic-addon1"
                                    name="date"
                                    onChange={(e) => {
                                        setSelectedDate(e.target.value)
                                    }}
                                    value={selectedDate}
                                />
                            </div>
                            {onSubmit && !selectedDate && (
                                <p className="required-fields mt-1">
                                    Please select the Date
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="transfer-list">
                            <div className="unselected-users">
                                <h4>Unselected Groups</h4>
                                <span className="badge badge-light">{groupData.length}</span>
                                <input
                                    type="text"
                                    className="form-control assessment-name"
                                    placeholder="Search Groups"
                                    aria-label="Group Search"
                                    onChange={(e) => setSearchGroups(e.target.value)}
                                    value={searchGroups}
                                />
                                <img className="search-icon" src={SearchIcon} alt="Search"/>
                            </div>
                            <div className="users-list">
                                <ul>
                                    {groupData.length !== 0 &&
                                        <div className="select-all">
                                            <input type="checkbox"
                                                   checked={groupData.length === checkedGroups.length && checkedGroups.length !== 0}
                                                   onClick={(e) => handleToggleAll(groupData, e.target.checked, "group")}
                                            />
                                            Select All
                                        </div>
                                    }
                                    <div className="tt-users-data">
                                        {groupData.filter((item) => new RegExp(searchGroups, 'i').test(item.name))
                                            .map((val, i) => (
                                                <div className="users" key={val.id}>
                                                    <input type="checkbox"
                                                           onClick={handleToggle(val.id, "group")}
                                                           checked={checkedGroups.indexOf(val.id) !== -1}
                                                    />
                                                    {val.name}
                                                </div>
                                            ))}
                                    </div>
                                </ul>
                            </div>
                        </div>
                        <div className="move-btn">
                            <div className="selected-btns mb-2">
                                <button type="button" onClick={() => handleCheckedRight("group")}>&gt;</button>
                            </div>
                            <div className="unselected-btn">
                                <button type="button" onClick={() => handleCheckedLeft("group")}>&lt;</button>
                            </div>
                        </div>
                        <div className="transfer-list">
                            <div className="selected-users">
                                <h4>Selected Groups</h4>
                                <span className="badge badge-light">{rightGroups.length}</span>
                                <input
                                    type="text"
                                    className="form-control assessment-name"
                                    placeholder="Search Groups"
                                    aria-label="Group Search"
                                    onChange={(e) => setSearchSelectedGroups(e.target.value)}
                                    value={searchSelectedGroups}
                                />
                                <img className="search-icon" src={SearchIcon} alt="Search"/>
                            </div>
                            <div className="users-list">
                                <ul>
                                    {rightGroups.length !== 0 &&
                                        <div className="select-all">
                                            <input type="checkbox"
                                                   checked={rightGroups.length === checkedGroups.length && checkedGroups.length !== 0}
                                                   onClick={(e) => handleToggleAll(rightGroups, e.target.checked, "group")}
                                            />
                                            Select All
                                        </div>
                                    }
                                    <div className="tt-users-data">
                                        {rightGroups.filter((item) => new RegExp(searchSelectedGroups, 'i').test(item.name))
                                            .map((val, i) => (
                                                <div className="users" key={val.id}>
                                                    <input type="checkbox"
                                                           onClick={handleToggle(val.id, "group")}
                                                           checked={checkedGroups.indexOf(val.id) !== -1}
                                                    />
                                                    {val.name}
                                                </div>
                                            ))}
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="transfer-list">
                            <div className="unselected-users">
                                <h4>Unselected Users</h4>
                                <span className="badge badge-light">{userData.length}</span>
                                <input
                                    type="text"
                                    className="form-control assessment-name"
                                    placeholder="Search Users"
                                    aria-label="User Search"
                                    onChange={(e) => setSearchUsers(e.target.value)}
                                    value={searchUsers}
                                />
                                <img className="search-icon" src={SearchIcon} alt="Search"/>
                            </div>
                            <div className="users-list">
                                <ul>
                                    {userData.length !== 0 &&
                                        <div className="select-all">
                                            <input type="checkbox"
                                                   checked={userData.length === checkedUsers.length && checkedUsers.length !== 0}
                                                   onClick={(e) => handleToggleAll(userData, e.target.checked, "user")}
                                            />
                                            Select All
                                        </div>
                                    }
                                    <div className="tt-users-data">
                                        {userData.filter((item) => new RegExp(searchUsers, 'i').test(item.full_name))
                                            .map((val, i) => (
                                                <div className="users" key={val.id}>
                                                    <input type="checkbox"
                                                           onClick={handleToggle(val.id, "user")}
                                                           checked={checkedUsers.indexOf(val.id) !== -1}
                                                    />
                                                    {val.full_name}
                                                </div>
                                            ))}
                                    </div>
                                </ul>
                            </div>
                        </div>
                        <div className="move-btn">
                            <div className="selected-btns mb-2">
                                <button type="button" onClick={() => handleCheckedRight("user")}>&gt;</button>
                            </div>
                            <div className="unselected-btn">
                                <button type="button" onClick={() => handleCheckedLeft("user")}>&lt;</button>
                            </div>
                        </div>
                        <div className="transfer-list">
                            <div className="selected-users">
                                <h4>Selected Users</h4>
                                <span className="badge badge-light">{rightUsers.length}</span>
                                <input
                                    type="text"
                                    className="form-control assessment-name"
                                    placeholder="Search Users"
                                    aria-label="User Search"
                                    onChange={(e) => setSearchSelectedUsers(e.target.value)}
                                    value={searchSelectedUsers}
                                />
                                <img className="search-icon" src={SearchIcon} alt="Search"/>
                            </div>
                            <div className="users-list">
                                <ul>
                                    {rightUsers.length !== 0 &&
                                        <div className="select-all">
                                            <input type="checkbox"
                                                   checked={rightUsers.length === checkedUsers.length && checkedUsers.length !== 0}
                                                   onClick={(e) => handleToggleAll(rightUsers, e.target.checked, "user")}
                                            />
                                            Select All
                                        </div>
                                    }
                                    <div className="tt-users-data">
                                        {rightUsers.filter((item) => new RegExp(searchSelectedUsers, 'i').test(item.full_name))
                                            .map((val, i) => (
                                                <div className="users" key={val.id}>
                                                    <input type="checkbox"
                                                           onClick={handleToggle(val.id, "user")}
                                                           checked={checkedUsers.indexOf(val.id) !== -1}
                                                    />
                                                    {val.full_name}
                                                </div>
                                            ))}
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="submit-btns">
                        <Button className="btn-cancel" onClick={(e) => {
                            close()
                        }}>
                            Cancel
                        </Button>
                        <Button variant="contained" className="btn-update" onClick={(e) => {
                           create()
                        }}>
                            Proceed
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
    );
};
export default withRouter(AddTimeCard)