import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { Pagination, MenuItem, FormControl, Select, Button, Breadcrumbs, Link } from '@mui/material';

import Folder from "@assets/fonts/Folder.svg";
import axios from "@config/axiosConfig";
import Application from "@config/ApplicationWrapper";

import ListView from "./list";
import CreateFolder from "./popups/create";
import UploadFiles from "./popups/upload";
import BulkUploadModal from "./popups/BulkUpload";

const initialBreadcrumb = [{
    name: "Home",
    id: "",
    is_asset_doc: false,
    int_id: ""
}];

const limitOptions = [5, 10, 20, 30];

const useDebounce = (callback, delay, dependencies) => {
    useEffect(() => {
        const timeoutId = setTimeout(callback, delay);
        return () => clearTimeout(timeoutId);
    }, dependencies);
};

const Docs = ({ id }) => {
    const [breadcrumb, setBreadcrumb] = useState(initialBreadcrumb);
    const [loading, setLoading] = useState(true);

    // Pagination
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(30);
    const [pageCount, setPageCount] = useState(0);
    const [pageDetails, setPageDetails] = useState([]);

    // search & sort
    const [name, setSearchByName] = useState();
    const [searchByType, setSearchByType] = useState();
    const [searchByCreatedBy, setSearchByCreatedBy] = useState();
    const [searchByUpdatedAt, setSearchByUpdatedAt] = useState();
    const [sorting, setSorting] = useState(false);
    const [sortedBy, setSortedBy] = useState("file_name");

    // popups
    const [openFolder, setOpenFolder] = useState(false);
    const [openUploadFile, setOpenUploadFile] = useState(false);
    const [bulkUpload, setBulkUpload] = useState(false);

    // data
    const [show, setShow] = useState(false);
    const [formsList, setFormsList] = useState([]);

    // others
    const [selectedForms, setSelectedForms] = useState([]);
    const [selectedData, setSelectedData] = useState();
    const [isAsset, setIsAsset] = useState(false);
    const [parentId, setParentId] = useState();
    const firstUpdate = useRef(false);
    const [lastUpdate, setLastUpdate] = useState(0);

    useDebounce(() => {
        getPageDetails(breadcrumb[breadcrumb.length - 1].id);
    }, 2000, [page, limit, lastUpdate]);

    const getPageDetails = async (internalSubId) => {
        try {
            window.scrollTo(0, 0);
            setLoading(true)

            let url = `${Application.api_url}/api/v2/company_documents`;
            let params = {
                page: page,
                limit: limit,
                audit_template_name_cont: name
            };

            if (internalSubId) {
                url += `/${internalSubId}`;
            } else {
                params.company_identifier = id;
            }

            let resultObj = await axios.get(url, { params });

            if (resultObj.status === 200) {
                let details = resultObj.data;
                let finalObj = internalSubId ? (details && details.data && details.data.children) : (details && details.data) || [];

                setPageDetails(finalObj);
                setPageCount(details && details.metadata ? details.metadata.pages : 0);
                setLoading(false)
            }
        } catch (err) {
            console.log("error==>", err);
        }
    };

    const handleChangePage = (event, value) => { setPage(value); };

    const handleUploadSuccess = () => {
        toast.success('Files uploaded successfully');
        getPageDetails(breadcrumb[breadcrumb.length - 1].id);
      };      

    const deleteFolder = async (id) => {
        try {
            const resultObj = await axios.delete(`${Application.api_url}/api/v2/company_documents/${id}`);
            if (resultObj.data.success) {
                toast.success("Deleted Successfully");
                getPageDetails(breadcrumb[breadcrumb.length - 1].id);
            }
        } catch (err) {
            console.log("error==>", err);
        }
    }

    const changeRoot = async (data) => {
        let updateBreadCrumb = {
            name: data.name,
            id: data.slug,
            is_asset_doc: data.is_asset_doc,
            int_id: data.int_id
        }
        setLoading(false)
        setParentId(data.int_id)
        setPage(1)
        setBreadcrumb([...breadcrumb, updateBreadCrumb])
        getPageDetails(data.slug)
    }

    const handleClick = (id, is_asset_doc, int_id) => {
        if (id) {
            const index = breadcrumb.findIndex((item) => item.id == id) + 1;
            const filteredValues = breadcrumb.slice(0, index);
            setBreadcrumb(filteredValues)
            getPageDetails(id);
            setParentId(int_id)
        } else {
            setParentId("")
            setBreadcrumb(initialBreadcrumb)
            getPageDetails();
        }
        setIsAsset(is_asset_doc)
    }

    const editRecord = (data) => {
        setSelectedData(data);
        setOpenFolder(true);
    }

    const handleShorting = (sort, field) => {
        setSorting(sort);
        setSortedBy(field)
    }

    const handleSearch = (name, value) => {
        const setters = {
            "name": setSearchByName,
            "type": setSearchByType,
            "created_by": setSearchByCreatedBy,
            "updated_at": setSearchByUpdatedAt
        };

        const setter = setters[name];
        if (setter) {
            setter(value);
        }
    }

    const clearFilter = () => {
        setSearchByName("");
        setSearchByType("");
        setSearchByCreatedBy("");
        setSearchByUpdatedAt("");
    }

    const handleClosePopup = (data) => {
        setOpenFolder(false);
        setOpenUploadFile(false);
        setBulkUpload(false)
        data && getPageDetails(breadcrumb[breadcrumb.length - 1].id);
    }

    const findAsset = (data) => {
        setIsAsset(data)
    }

    return (
        <>
            <div className="card asset-tab-landing internal-docs">
                <div className="card-body">
                    <div className="tab-content">
                        <div
                            id="active-risk-assessment"
                            className="tab-pane fade in active active-risk-assessment internal-sub-employee"
                        >
                            <div className="nestedTable pf-table">
                                <div className="fields-setting">
                                    <div className="asset-active-filters">
                                        <Breadcrumbs separator={">"} aria-label="breadcrumb">
                                            {breadcrumb.map((val, index) => (
                                                <Link underline="hover" key="index" color="inherit"
                                                      className="breadcrumbs-link"
                                                      onClick={(e) => {
                                                          handleClick(val.id, val.is_asset_doc, val.int_id)
                                                      }}>
                                                    <img src={Folder} alt="" className="folder-icon" />{val.name}
                                                </Link>
                                            ))}
                                        </Breadcrumbs>
                                    </div>
                                    <div className="bulk-action">
                                        <Button variant="outlined" onClick={(e) => { setSelectedData(); setOpenFolder(true) }}>+ Create Folder</Button>
                                        <Button variant="contained" className="btn-create bulk-upload-action" onClick={(e) => { setOpenUploadFile(true) }}>Upload</Button>
                                        <Button variant="contained" className="btn-create bulk-upload-action" onClick={(e) => { setBulkUpload(true) }} >Bulk Upload</Button>
                                    </div>
                                </div>
                                <ListView
                                    pageDetails={pageDetails}
                                    deleteFolder={deleteFolder}
                                    changeDirectory={changeRoot}
                                    edit={editRecord}
                                    changeSorting={handleShorting}
                                    searching={handleSearch}
                                    searchByName={name}
                                    searchByType={searchByType}
                                    searchByCreatedBy={searchByCreatedBy}
                                    searchByUpdatedAt={searchByUpdatedAt}
                                    breadcrumb={breadcrumb}
                                    findAsset={findAsset}
                                    update={setLastUpdate}
                                    loading={loading}
                                    companyId={id}
                                />
                                <div className="page-limits">
                                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                        <Select
                                            labelId="demo-select-small"
                                            id="demo-select-small"
                                            value={limit}
                                            onChange={(e) => {
                                                setLimit(e.target.value);
                                            }}
                                        >
                                            {limitOptions.map((option) => (
                                                <MenuItem key={option} value={option}>{option}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {!loading && (
                                        <Pagination
                                            count={pageCount}
                                            page={page}
                                            variant="outlined"
                                            shape="rounded"
                                            onChange={handleChangePage}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {openFolder &&
                    <CreateFolder
                        show={openFolder}
                        details={selectedData}
                        parentId={parentId}
                        closePopup={handleClosePopup}
                        companyId={id}
                    />
                }
                <UploadFiles show={openUploadFile} parentId={parentId} closePopup={handleClosePopup} onUploadSuccess={handleUploadSuccess} companyId={id} />
                <BulkUploadModal show={bulkUpload} parentId={parentId} closePopup={handleClosePopup} onUploadSuccess={handleUploadSuccess} companyId={id} />
            </div>
        </>
    );
}

export default Docs;
