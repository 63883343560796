import React from 'react';
import HeroSection from './CommonComponents/HeroSection';
import FeaturesSection from './CommonComponents/FeaturesSection';
import ParallaxSection from '../../pages/HomePage/Components/ParallaxSection';
import HomepageDemoSection from '../../pages/HomePage/Components/HomepageDemoSection';

export default function QRCodeManagement() {
    return (
        <>
            <HeroSection
                heroText={"QR Code Management"}
                subText={"Streamline access and management with QR codes for quick and secure tracking."}
            />
            <FeaturesSection
                headerText={"Efficient QR Code Management"}
                subText={"Create, track, and manage QR codes for various assets and locations."}
                featureHighlights={[
                    "Generate QR codes for easy asset tracking.",
                    "Assign QR codes to specific assets and users.",
                    "Monitor access and usage through QR codes.",
                    "Maintain a secure record of QR code activity."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for QR Code Management section here
            />
            <FeaturesSection
                headerText={"Customizable QR Code Properties"}
                subText={"Define custom properties and roles for each QR code."}
                featureHighlights={[
                    "Create QR codes with specific access permissions.",
                    "Assign roles to QR codes for restricted access.",
                    "Easily update QR code details from a single platform."
                ]}
                // Placeholder: Add image for Customizable Properties section here
            />
            <FeaturesSection
                headerText={"Detailed QR Code Reports"}
                subText={"Track and analyze QR code usage and generate detailed reports."}
                featureHighlights={[
                    "Monitor QR code usage and access patterns.",
                    "Identify unauthorized access attempts.",
                    "Generate QR code activity reports for compliance."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for QR Code Reports section here
            />
            <div className="Home-Page sl-home-wrapper" style={{ paddingTop: 0 }}>
                <ParallaxSection />
            </div>
            <div className="features-homepage-wrapper">
                <HomepageDemoSection mergeTop={false} />
            </div>
        </>
    );
}
