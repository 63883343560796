import React, { useEffect, useState, useContext } from "react";
import Pagination from "@mui/material/Pagination";
import Cookies from "js-cookie";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { AssetDetailsContext } from "./index";
import Application from "../../../../config/ApplicationWrapper";
import EmptyData from "../../../../assets/images/empty-assets.svg";

const MaintenanceSchedule = () => {
  const [maintenanceData, setMaintenanceData] = useState([]);
  const { pageData } = useContext(AssetDetailsContext);

  const cookieDetails = Cookies.get("token");

  // Pagination
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [pagination, setPagination] = useState(true);
  const [pageCounts, setPageCounts] = useState(0);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const updateResults = (resultObj) => {
    let records = resultObj.data;
    setMaintenanceData([...records]);
    // records.length > 0 ? setAssetAction(true) : null
    pagination === false ? setPagination(true) : null;
    setPageCounts(resultObj.metadata ? resultObj.metadata.pages : 0);
  };

  const urlParams = () => {
    return new URLSearchParams({
      page: page,
      pagination: true,
      limit: limit,
    }).toString();
  };

  const getMaintenanceScheduleDetails = async (asstId) => {
    try {
      let url = new URL(
        `${Application.api_url}/api/v2/asset_trackings/${asstId}/maintenance_schedules`
      );
      url.search = urlParams();
      fetch(url, {
        method: "GET",
        headers: { Authorization: cookieDetails },
      }).then(async (response) => {
        let resultObj = await response.json();
        if (response.status === 200) {
          updateResults(resultObj);
        }
      });
    } catch (err) {
      console.log("error===>", err);
    }
  };

  useEffect(() => {
    if (pageData.id) {
      getMaintenanceScheduleDetails(pageData.id);
    }
  }, [limit, page, pageData]);

  const NoAssetsFound = () => (
    <div className="no-assets">
      <img src={EmptyData} />
      <p>
        <b>No Data</b>
      </p>
    </div>
  );

  return (
    <div>
      <div className="table-template">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">
                <div className="d-flex justify-content-between">
                  Maintenance Name
                </div>
              </th>
              <th scope="col">
                <div className="d-flex justify-content-between">
                  Maintenance Service Intervals
                </div>
              </th>
              <th scope="col">
                <div className="d-flex justify-content-between">
                  Location of Maintenance
                </div>
              </th>
              <th scope="col">
                <div className="d-flex justify-content-between">
                  Responsible Party
                </div>
              </th>
              {/* <th scope="col">
                <div className="d-flex justify-content-between">
                  Maintenance Status
                </div>
              </th> */}
              <th scope="col">
                <div className="d-flex justify-content-between">
                  Approved Maintenance
                </div>
              </th>
              {/* <th scope="col" style={{textAlign: "left"}}>Action</th> */}
            </tr>
          </thead>
          <tbody>
            {maintenanceData &&
              maintenanceData.length > 0 &&
              maintenanceData.map((maintenance, index) => (
                <tr key={index}>
                  <td>{maintenance.name}</td>
                  <td>
                    {maintenance.service_intervals} {maintenance.unit}
                  </td>
                  <td>{maintenance.location}</td>
                  <td>
                    <div className="responsible_parties">
                      {maintenance.responsible_parties &&
                        maintenance.responsible_parties.map((obj, index) => (
                          <span
                            className={`badge badge-light responsible-party`}
                          >
                            {obj.full_name}
                          </span>
                        ))}
                    </div>
                  </td>
                  {/* <td>{maintenance.status}</td> */}
                  <td>
                    {maintenance.approved_maintenance
                      ? maintenance.approved_maintenance
                      : ""}
                  </td>
                  {/* <td>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        style={{display: "flex"}}
                                    >
                                        <img src={Activate} style={{marginRight: "5px"}} />
                                        Activate Maintenance
                                    </button>
                                </td> */}
                </tr>
              ))}

            {maintenanceData.length === 0 && (
              <tr>
                <td colSpan={6}>
                  <NoAssetsFound />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {maintenanceData && maintenanceData.length > 0 && (
        <div
          className="d-flex justify-content-between"
          style={{ alignItems: "center" }}
        >
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={limit}
              onChange={(e) => {
                setLimit(e.target.value);
                setPage(1);
                setPagination(false);
              }}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
            </Select>
          </FormControl>
          {pagination ? (
            <Pagination
              count={pageCounts}
              variant="outlined"
              shape="rounded"
              onChange={handleChangePage}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};

export default MaintenanceSchedule;
