import React, { Fragment } from 'react'
import AuditDetailData from './AuditDetailData';
// import ROTTrainingData from '../ROT/ROTTrainingData';
import * as Components from '../../components';

class DataOverview extends React.Component
{
    constructor(props)
    {
        console.log(props);
        super(props);
        this.state = {
            audit_data: []
        };

        this.onChangePage = this.onChangePage.bind(this);
    }


    onChangePage(audit_data) {
        // update state with new page of items
        // debugger;
        this.setState({ audit_data: audit_data });
    }


render()
{
    return (

        <Fragment>


            <div className="row">
                <div className="col-lg-6 muruga" id="HourlyChart">

                </div>

                <div className="col-lg-6 muruga pie-chart-1" id="PieData">

                </div>
            </div>


            <div className="row">
                <div className="col-lg-12 muruga" id="BarChartData" style={{marginTop: "20px"}}>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12 muruga" id="ddd">
                    <h2>Form Details</h2>

                    <AuditDetailData data={this.props.result}/>
                    
                </div>
            </div>

            

            {/* <div className="row">
                <div className="col-lg-12 side-nav">
                    <h2>Records of Training Information</h2>
                    <Components.ROTTrainingData data={this.state.result}/>
                </div>
            </div> */}


            <br/>
        </Fragment>

        )
}
}

export default DataOverview;
