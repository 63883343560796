import React, { useEffect, useState, useLayoutEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import Cookies from "js-cookie";
import Info from "../../../../assets/images/Info.svg";
import Application from "../../../../config/ApplicationWrapper";
const cookieDetails = Cookies.get("token");

export default function Task(props) {
  const { show, closepopup, ratDetails, taskDetails, hazardDetails, type } = props;
  const [taskName, setTaksName] = useState("");
  const [hazardList, setHazardList] = useState([]);
  const [riskRanges, setRiskRanges] = useState();
  const [hazardSaved, setHazardSaved] = useState()
  const [startValue, setStartValue] = React.useState(1);

  let initialHazard = {
    descriptions: "",
    name: "",
    post_f: startValue,
    post_np: startValue,
    post_p: startValue,
    post_sp: startValue,
    pre_f: startValue,
    pre_np: startValue,
    pre_p: startValue,
    pre_sp: startValue,
    priority: startValue,
    task_id: "",
  };
  const [hazard, setHazard] = React.useState(initialHazard);
  const [riskRangesArray, setRiskRangesArray] = React.useState([]);

  useEffect(() => {
    const getPageDetails = async () => {
      try {
        let hazardData = await fetch(
          `${Application.api_url}/api/v2/risk_assesments/hazards`,
          {
            method: "GET",
            headers: { Authorization: cookieDetails },
          }
        );
        hazardData = await hazardData.json();
        setHazardList(
          hazardData && hazardData.hazards ? hazardData.hazards : []
        );
      } catch (err) {
        console.log("error==>", err);
      }
    };
    getPageDetails();
  }, [hazardSaved]);

  useEffect(() => {
    const getRiskDetails = async () => {
      try {
        let riskRangesData = await fetch(
          `${Application.api_url}/api/v2/risk_assesments/risk_ranges`,
          {
            method: "GET",
            headers: { Authorization: cookieDetails },
          }
        );
        riskRangesData = await riskRangesData.json();
        setRiskRanges(
          riskRangesData && riskRangesData.risk_range
            ? riskRangesData.risk_range
            : {}
        );
        let min = riskRangesData.risk_range && riskRangesData.risk_range.min ? riskRangesData.risk_range.min : 1;
        let max = riskRangesData.risk_range && riskRangesData.risk_range.max ? riskRangesData.risk_range.max : 15;
        let newarr = [...Array((max - min) / 1 + 1)].map(
          (_, i) => min + 1 * i
        )
        setStartValue(min);
        setRiskRangesArray(newarr);
        if (type == "add") {
          setHazard({
            descriptions: "",
            name: "",
            post_f: min,
            post_np: min,
            post_p: min,
            post_sp: min,
            pre_f: min,
            pre_np: min,
            pre_p: min,
            pre_sp: min,
            priority: min,
            task_id: "",
          })
        }
      } catch (err) {
        console.log("error==>", err);
      }
    };
    getRiskDetails();
  }, [show]);

  useEffect(() => {
    setTaksName(taskDetails && taskDetails.name ? taskDetails.name : "");
  }, [taskDetails, show]);

  useEffect(() => {
    if (hazardDetails && hazardDetails.task_id && !type) {
      let newData = {
        descriptions: hazardDetails.descriptions
          ? hazardDetails.descriptions
          : "",
        name: hazardDetails.name ? hazardDetails.name : "",
        post_f: hazardDetails.post_f,
        post_np: hazardDetails.post_np,
        post_p: hazardDetails.post_p,
        post_sp: hazardDetails.post_sp,
        pre_f: hazardDetails.pre_f,
        pre_np: hazardDetails.pre_np,
        pre_p: hazardDetails.pre_p,
        pre_sp: hazardDetails.pre_sp,
        priority: hazardDetails.priority,
        task_id: hazardDetails.task_id,
      };
      setHazard(newData);
    }
  }, [show, hazardDetails]);

  const onhandleShareClose = () => {
    setHazard(initialHazard);
    setTaksName("");
    closepopup();
  };

  const save = async () => {
    if (!taskDetails && !taskDetails.id) {
      let craeteTask = await fetch(
        `${Application.api_url}/api/v2/risk_assesments/tasks`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: cookieDetails,
          },
          body: JSON.stringify({
            task: {
              name: taskName,
              ctra_id: ratDetails.id,
            },
          }),
        }
      );
      craeteTask = await craeteTask.json();
      hazard.task_id = craeteTask.task.id;
    } else {
      hazard.task_id = taskDetails.id;
    }

    if (hazard.task_id) {
      let task = await fetch(
        `${Application.api_url}/api/v2/risk_assesments/hazards`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: cookieDetails,
          },
          body: JSON.stringify({
            hazard: hazard,
          }),
        }
      );
      task = await task.json();
      setHazardSaved(hazard);
      setHazard(initialHazard);
      setTaksName("");
      closepopup(task);
    }
  };

  const changeHazard = (value) => {
    let data = hazardList.find((val) => {
      if (val.id == value) {
        return val;
      }
    });

    let newData = {
      descriptions: data.descriptions,
      name: data.name,
      post_f: data.post_f,
      post_np: data.post_np,
      post_p: data.post_p,
      post_sp: data.post_sp,
      pre_f: data.pre_f,
      pre_np: data.pre_np,
      pre_p: data.pre_p,
      pre_sp: data.pre_sp,
      priority: data.priority,
      task_id: "",
    };
    setHazard(newData);
  };

  const updateHazard = async () => {
    hazard.task_id = taskDetails.id;
    let update = await fetch(
      `${Application.api_url}/api/v2/risk_assesments/hazards/${hazardDetails.id}`,
      {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          Authorization: cookieDetails,
        },
        body: JSON.stringify({
          hazard: hazard,
        }),
      }
    );
    update = await update.json();
    setHazardSaved(hazard);
    setHazard(initialHazard);
    setTaksName("");
    closepopup(update);
  };

  const CustomComponent = ({ obj, type }) => {
    let green = riskRanges.green.split("-");
    let yellow = riskRanges.yellow.split("-");
    let total = 1;
    let colour;
    if (riskRanges && riskRanges.risk_calc != "Multiplication") {
      if (type == "pre") {
        total =
          Number(obj.pre_f < startValue ? startValue : obj.pre_f) +
          Number(obj.pre_np < startValue ? startValue : obj.pre_np) +
          Number(obj.pre_p < startValue ? startValue : obj.pre_p) +
          Number(obj.pre_sp < startValue ? startValue : obj.pre_sp);
      } else {
        total =
          Number(obj.post_f < startValue ? startValue : obj.pre_f) +
          Number(obj.post_np < startValue ? startValue : obj.post_np) +
          Number(obj.post_p < startValue ? startValue : obj.post_p) +
          Number(obj.post_sp < startValue ? startValue : obj.post_sp);
      }
    } else {
      if (type == "pre") {
        total =
          Number(obj.pre_f < startValue ? startValue : obj.pre_f) *
          Number(obj.pre_np < startValue ? startValue : obj.pre_np) *
          Number(obj.pre_p < startValue ? startValue : obj.pre_p) *
          Number(obj.pre_sp < startValue ? startValue : obj.pre_sp);
      } else {
        total =
          Number(obj.post_f < startValue ? startValue : obj.post_f) *
          Number(obj.post_np < startValue ? startValue : obj.post_np) *
          Number(obj.post_p < startValue ? startValue : obj.post_p) *
          Number(obj.post_sp < startValue ? startValue : obj.post_sp);
      }
    }

    if (total >= green[0] && total <= green[1]) {
      colour = "green";
    } else if (total >= yellow[0] && total <= yellow[1]) {
      colour = "yellow";
    } else {
      colour = "red";
    }

    return (
      <p
        style={{
          background: colour,
          color: "black",
          margin: 0,
          padding: "5px",
          textAlign: "center",
        }}
      >
        {total}
      </p>
    );
  };

  return (
    <Modal show={show} centered className="add-task-modal" size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <i class="fa fa-plus" aria-hidden="true"></i>
          <b> Create New Step / Task</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <label>Task Name *</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Task Name"
            name="taskname"
            value={taskName}
            readOnly={taskDetails ? true : false}
            onChange={(e) => {
              setTaksName(e.target.value);
            }}
          />
          <div className="card">
            <div
              className="card-header d-flex"
              style={{ alignItems: "center", padding: "0 12px" }}
            >
              Add New Hazard
            </div>
            <div class="card-body">
              <div className="row d-flex align-item-center">
                <div className="col-md-6">
                  <label>Hazard Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Hazard Name"
                    name="hazardname"
                    value={hazard.name}
                    onChange={(e) => {
                      setHazard({ ...hazard, ...{ name: e.target.value } });
                    }}
                  />
                </div>
                <div className="col-md-1 d-flex option">or</div>
                <div className="col-md-6">
                  <label>Use Existing Hazard</label>
                  <select
                    className="form-control select"
                    onChange={(e) => changeHazard(e.target.value)}
                  >
                    <option value="" disabled selected>
                      Select Hazard Name
                    </option>
                    {hazardList.map((val, index) => (
                      <option value={val.id} key={index}>
                        {val.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div>
                <h2>Hazard Name</h2>
              </div>
              <table className="popup-table">
                <thead>
                  <tr>
                    <th>Stage</th>
                    <th>
                      Severity Potential
                      <img src={Info} alt="" className="info-img" />
                    </th>
                    <th>
                      Probability
                      <img src={Info} alt="" className="info-img" />
                    </th>
                    <th>
                      Frequency
                      <img src={Info} alt="" className="info-img" />
                    </th>
                    <th>
                      # Of People
                      <img src={Info} alt="" className="info-img" />
                    </th>
                    <th>
                      Total
                      <img src={Info} alt="" className="info-img" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>PRE</td>
                    <td>
                      <select
                        name="pre_sp"
                        value={hazard.pre_sp}
                        onChange={(e) => {
                          setHazard({
                            ...hazard,
                            ...{ pre_sp: e.target.value },
                          });
                        }}
                      >
                        {riskRangesArray.map((x, i) => (
                          <option value={x} key={i}>
                            {x}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <select
                        name="pre_p"
                        value={hazard.pre_p}
                        onChange={(e) => {
                          setHazard({
                            ...hazard,
                            ...{ pre_p: e.target.value },
                          });
                        }}
                      >
                        {riskRangesArray.map((x, i) => (
                          <option value={x} key={i}>
                            {x}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <select
                        name="pre_f"
                        value={hazard.pre_f}
                        onChange={(e) => {
                          setHazard({
                            ...hazard,
                            ...{ pre_f: e.target.value },
                          });
                        }}
                      >
                        {riskRangesArray.map((x, i) => (
                          <option value={x} key={i}>
                            {x}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <select
                        name="pre_np"
                        value={hazard.pre_np}
                        onChange={(e) => {
                          setHazard({
                            ...hazard,
                            ...{ pre_np: e.target.value },
                          });
                        }}
                      >
                        {riskRangesArray.map((x, i) => (
                          <option value={x} key={i}>
                            {x}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <CustomComponent obj={hazard} type={"pre"} />
                    </td>
                  </tr>
                  <tr>
                    <td>POST</td>
                    <td>
                      <select
                        name="post_sp"
                        value={hazard.post_sp}
                        onChange={(e) => {
                          setHazard({
                            ...hazard,
                            ...{ post_sp: e.target.value },
                          });
                        }}
                      >
                        {riskRangesArray.map((x, i) => (
                          <option value={x} key={i}>
                            {x}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <select
                        name="post_p"
                        value={hazard.post_p}
                        onChange={(e) => {
                          setHazard({
                            ...hazard,
                            ...{ post_p: e.target.value },
                          });
                        }}
                      >
                        {riskRangesArray.map((x, i) => (
                          <option value={x} key={i}>
                            {x}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <select
                        name="post_f"
                        value={hazard.post_f}
                        onChange={(e) => {
                          setHazard({
                            ...hazard,
                            ...{ post_f: e.target.value },
                          });
                        }}
                      >
                        {riskRangesArray.map((x, i) => (
                          <option value={x} key={i}>
                            {x}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <select
                        name="post_np"
                        value={hazard.post_np}
                        onChange={(e) => {
                          setHazard({
                            ...hazard,
                            ...{ post_np: e.target.value },
                          });
                        }}
                      >
                        {riskRangesArray.map((x, i) => (
                          <option value={x} key={i}>
                            {x}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <CustomComponent obj={hazard} type={"post"} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn-secondary"
          onClick={(e) => {
            onhandleShareClose();
          }}
        >
          Cancel
        </Button>
        {hazardDetails && taskName.trim() && (
          <Button
            className="btn-primary"
            onClick={(e) => {
              updateHazard();
            }}
          >
            Update
          </Button>
        )}
        {!hazardDetails && taskName.trim() && (
          <Button
            className="btn-primary"
            onClick={(event) => {
              save();
              event.currentTarget.disabled = true;
            }}
          >
            Create
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
