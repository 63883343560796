import { useEffect, useState } from 'react';
import axios from 'axios';
import {setUserCookieData} from "../utils/util";

const useFetchUserData = () => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        async function fetchData() {
            const result = await axios.get(
                'analytics_dashboard/user_info', { params: {} }
            );
            setUser(result.data.user_name);
            setUserCookieData(result.data);
        }
        fetchData().then(r => r);
    }, []);

    return {
        user
    }
}

export default useFetchUserData;