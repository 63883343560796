import React from "react";
import { BrowserRouter as Router, Route, Link, NavLink, withRouter,Redirect} from "react-router-dom";
import Login from './Login';
import SignUp from './Signup';
import Main from '../Main';

class Layout extends React.Component {

render() {

  return (
      <div>
          <ul className="nav nav-pills">
              <li role="presentation" >
                  <NavLink to="/" role="button">Home</NavLink>
              </li>
              <li role="presentation" className="">
                  <NavLink to="/about-us" role="button">About Us</NavLink>
              </li>
              <li role="presentation" className="">
                  <NavLink to="/contact-us" role="button">Contact Us</NavLink>
              </li>
              <li role="presentation" className="">
                  <NavLink to="/login" role="button">Login</NavLink>
              </li>
              <li role="presentation" className="">
                  <NavLink to="/register" role="button">SignUp</NavLink>
              </li>
          </ul>
      </div>

    )

  }
}

export default Layout;
