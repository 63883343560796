export const handleClickExpand = (expanded, id, setExpanded) => {
  const selectedIndex = expanded.indexOf(id);
  let newSelected = [];

  if (selectedIndex === -1) {
    newSelected = newSelected.concat(expanded, id);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(expanded.slice(1));
  } else if (selectedIndex === expanded.length - 1) {
    newSelected = newSelected.concat(expanded.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
        expanded.slice(0, selectedIndex),
        expanded.slice(selectedIndex + 1)
    );
  }

  setExpanded(newSelected);
};
