import React, { useEffect, useState } from "react";
import OrderRequestItemTable from './components/OrderRequestItemTable'
import axios from "../../../config/axiosConfig";
import Application from "../../../config/ApplicationWrapper";
import Loader from "../../../components/NewLoader";
import SideNav from "../../../components/SideNav";
import '../../../assets/styles/themes.scss'
import './orderDetails.scss';
import OrderSummary from "./components/OrderSummary";
import { fetchUserDropdown } from "./utils";

const OrderDetails = ({match, history}) => {
  const orderId = match.params.orderId
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([])

  useEffect(() => {
    const fetchUsers = async () => {
      const users = await fetchUserDropdown();

      setUsers(users)
    }

    fetchUsers();
  }, [fetchUserDropdown])

  const fetchOrderDetails = async () => {
    try {
      const res = await axios.get(`${Application.api_url}/api/v2/order_requests/${orderId}`);
      setOrderDetails(res.data.data);
      setLoading(false);
    } catch (err) {
      console.log("Error fetching order details:", err);
      setLoading(false);
    }
  }

  useEffect(() => {
    const retrieveOrderDetails = async () => {
      await fetchOrderDetails()
    };

    retrieveOrderDetails();
  }, [orderId]);

  return (
    <React.Fragment>
      <div className="msmcontainer dashboard-container">
        <div className="row d-flex">
          <SideNav />
          <div className="order-details-container risk-assessment">
            <div className="d-flex" style={{justifyContent: 'space-between'}}>
              <h2 className="order-title">
                <span className="" style={{ alignSelf: 'center', height: '30px', paddingTop: '5px', marginRight:'10px', fontWeight: 'bold', cursor:'pointer' }} onClick={() => history.push('/inventory?tab=order-management')}>
                  <i class="fa fa-chevron-left" aria-hidden="true"></i>
                </span>
                View Order
              </h2>
              
            </div>
            {
              (loading && <Loader />) ||
              (!orderDetails && <div>No details available for this order.</div>) ||
              orderDetails && (<React.Fragment>
                <OrderSummary order={orderDetails} />

                <h3 className="item-list-title">Item List</h3>
                <OrderRequestItemTable orderItems={orderDetails.order_request_items} pickers={users} fetchDetails={fetchOrderDetails} />
              </React.Fragment>)
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OrderDetails;
