import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "../../../config/axiosConfig";
import Application from "../../../config/ApplicationWrapper";

export default function ViewPopup(props) {
    const { show, closepopup, details } = props;
    const [pageDetails, setPageDetails] = useState("");

    const onhandleStatusClose = () => {
        closepopup();
    };

    useEffect(() => {
        getPageDetails(details)
    }, [])


    const getPageDetails = async (details) => {
        try {
            let resultObj = await axios.get(`${Application.api_url}/api/v2/internal_subs/${details}`);
            if (resultObj.status == 200) {
                let details = resultObj.data;
                setPageDetails(details && details.data)
            }
        } catch (err) {
            console.log("error==>", err);
        }
    }



    return (
        <Modal
            bsSize="lg"
            show={show}
            onHide={(e) => {
                onhandleStatusClose();
            }}
            className="qr-modal-wrap add-task-modal pf-model internal-sub-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="pf-model-header">
                        <p className="pf-model-title"> View Company Info </p>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {pageDetails &&
                    <div>
                        <div className="company-info-header">
                            <img src={pageDetails.profile_picture_url || '/main-images/default-org-image.png'} alt="logo" className="profile_picture_url" />
                            <div className="company-infos">
                                <p className="name">{pageDetails.name}</p>
                                <p className="desc">{pageDetails.company_info}</p>
                            </div>
                            {pageDetails.qr_url &&
                                <div className="slow-qa">
                                    <img src={pageDetails.qr_url} alt="/" />
                                </div>
                            }
                        </div>
                        <div className="company-details">
                            <div className="general-dtls">
                                <p className="title">General Details</p>
                                <ul className="list">
                                    <li className="list-dls"><span>Contact Number</span><span>{pageDetails.phonenumber}</span></li>
                                    <li className="list-dls"><span>Company Email </span><span> {pageDetails.email}</span></li>
                                    <li className="list-dls"><span>Industry</span><span>{pageDetails.industry}</span></li>
                                    <li className="list-dls"><span>Company Time Zone</span><span>{pageDetails.timezone}</span></li>
                                    <li className="list-dls"><span>License Number</span><span>{pageDetails.license_number}</span></li>
                                    <li className="list-dls"><span>Fax</span><span>{pageDetails.fax}</span></li>
                                    <li className="list-dls"><span>Website</span><span>{pageDetails.website}</span></li>
                                </ul>
                            </div>
                            <div className="admin-details">
                                <p>Company Admin Details</p>
                                <ul className="list">
                                    <li className="list-dls"><span>Name</span><span>{pageDetails.admin && pageDetails.admin.full_name}</span></li>
                                    <li className="list-dls"><span>Email </span><span>{pageDetails.admin && pageDetails.admin.email}</span></li>
                                </ul>
                                <p className="location-details">Location</p>
                                <ul className="list">
                                    <li className="list-dls"><span>Address</span><span>{pageDetails.address}</span></li>
                                    <li className="list-dls"><span>City </span><span>{pageDetails.city}</span></li>
                                    <li className="list-dls"><span>State</span><span>{pageDetails.state}</span></li>
                                    <li className="list-dls"><span>Country</span><span>{pageDetails.country}</span></li>
                                    <li className="list-dls"><span>Zip Code</span><span>{pageDetails.zipcode}</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                }
            </Modal.Body>
        </Modal>
    );
}
