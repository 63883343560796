import React, { Fragment } from 'react'
import { PanelGroup} from 'react-bootstrap';
import SubContractorInfo from '../../components/SubContractor/SubContractorInfo';

const SubContractorsDashboard = ({subcontractors, subcontractor_info, project_shared_info, onClientSelect, onProjectSelect, onEmployeeSelect,onModalClose, isOpen, user_trainings }) => {
    const renderClientPanel = subcontractors.map((item, index) => {
        return(
            <SubContractorInfo item={item}
                               key={index}
                               subcontractor={subcontractor_info}
                               project_shared_info={project_shared_info}
                               index={index}
                               onClientSelect={onClientSelect}
                               onEmployeeSelect={onEmployeeSelect}
                               onModalClose={onModalClose}
                               isOpen={isOpen}
                               user_trainings={user_trainings}
                               onProjectSelect={onProjectSelect}/>
        )

    });

    const renderContent = (subcontractors) => {
        if (subcontractors.length == 0){
            // return <h2 className='align-center'> No SubContractors Found</h2>
            return <h2 className='align-center'> </h2>
        }
        return renderClientPanel;
    }

    return (
        <React.Fragment>
            {/* <div className="accordion-wrap">
                <h2>Subcontractors Dashboard</h2>
                <PanelGroup accordion id="accordion-main" defaultActiveKey="">
                    {renderContent(subcontractors)}
                </PanelGroup>
            </div> */}

        </React.Fragment>
    )
}

export default SubContractorsDashboard;
