import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Typography from "@mui/material/Typography";
import { useReactToPrint } from 'react-to-print';
import { ComponentToPrint } from './ComponentToPrint';
import Application from "../../../config/ApplicationWrapper";
import axios from "../../../config/axiosConfig";

export default function QrPopup(props) {
  const { show, closepopup, details } = props;
  const [qrDetails, setQrDetails] = useState("")

  const onhandleStatusClose = () => {
    closepopup();
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    getPageDetails()
  }, [show])


  const getPageDetails = async () => {
    try {
      let resultObj = await axios.get(`${Application.api_url}/api/v2/internal_subs/${details}`);
      if (resultObj.status == 200) {
        let details = resultObj.data; 
        setQrDetails(details && details.data)
      }
    } catch (err) {
      console.log("error==>", err);
    }
  }

  return (
    <Modal
      show={show}
      onHide={(e) => {
        onhandleStatusClose();
      }}
      className="qr-modal-wrap add-task-modal signature-popup"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <i
            class="fa fa-qrcode"
            style={{
              fontSize: "19px",
              marginRight: "5px",
            }}
          ></i>
          <b>QR Code</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="qr-info-item">
          <div className="qr-asset-info">
            <Typography variant="body2" gutterBottom className="qr-info-title">
              QR Information
            </Typography>
            <div className="qr-details">
              <Typography gutterBottom variant="subtitle1" component="div" className="qr-created-by">
                Company Name:

                <label className="status-label">
                  {qrDetails.name}
                </label>
              </Typography>
              <Typography gutterBottom variant="subtitle1" component="div" className="qr-created-by">
                Email:

                <label className="status-label">
                  {qrDetails && qrDetails.admin ? qrDetails.admin.email : ""}
                </label>
              </Typography>
            </div>
          </div>
        </div>
        <div id="qr-scan-img">
          <div style={{ textAlign: "center" }}>
            <img src={qrDetails.qr_url} alt="qr" />
          </div>
          <div style={{textAlign:"center"}}>
            <p style={{color:"black", fontSize:"16px", textAlign:"center"}}>QR Link : {qrDetails.invite_link}</p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* <button
          className="btn btn-secondary"
          onClick={() => window.open(`company/${qrDetails.company_id}/qr_code/${qrDetails.id}`, "_blank")}
        >
          <img style={{ width: "auto", padding: 0 }} />
          <span className="btn-text">QR Code Link</span>
        </button> */}

        <button
          className="btn btn-secondary"
          onClick={() => { handlePrint() }}
        >
          <img style={{ width: "auto", padding: 0 }} />
          <span className="btn-text">Print</span>
        </button>
      </Modal.Footer>
      {qrDetails && qrDetails.qr_url &&
        <ComponentToPrint ref={componentRef} pageData={qrDetails} />
      }
    </Modal>
  );
}
