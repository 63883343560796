import React, { useState } from 'react';
import axios from "../../../../config/axiosConfig";
import { Modal, Button, Form } from 'react-bootstrap';
import Application from '../../../../config/ApplicationWrapper';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormControl from 'react-bootstrap/lib/FormControl';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import { toast } from 'react-toastify';
import Select from 'react-select';

const ExportTimecardsModal = ({ show, handleClose, groups, users, costCodes }) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [groupId, setGroupId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [costCodeId, setCostCodeId] = useState(null);
  const [exportType, setExportType] = useState('csv');

  const handleMultipleSelect = (value, callback) => {
    const data = value.map(option => parseInt(option.value, 10))
    callback(data)
  }

  const handleExport = () => {
    try {
        axios
            .get(`${Application.api_url}/api/v2/time_cards/export`, {
              responseType: "blob",
              params: {
                start_date: startDate,
                end_date: endDate,
                cost_code_id: costCodeId,
                user_id: userId,
                group_id: groupId
              }
            })
            .then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              const timestamp = new Date().toISOString().replace(/[-:.]/g, '');
              link.href = url;
              link.setAttribute("download", `time-cards-${timestamp}.csv`); //or any other extension
              document.body.appendChild(link);
              link.click();
              toast.success('Export successfully!');
            })
            .catch((error) => {
              console.log("err==>", error);
              toast.warning('Export failed!');
            });
    } catch (err) {
        console.log("error==>", err);
    }
  }

  return (
    <Modal show={show} onHide={handleClose} style={{top: '100px'}}>
      <Modal.Header closeButton>
        <Modal.Title>Export Timecards</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <FormGroup>
            <ControlLabel>Start Date</ControlLabel>
            <FormControl type="date" onChange={(e) => setStartDate(e.target.value)} />
          </FormGroup>

          <FormGroup>
            <ControlLabel>End Date</ControlLabel>
            <FormControl type="date" onChange={e => setEndDate(e.target.value)} />
          </FormGroup>

          <FormGroup controlId="formControlsSelect">
            <Select options={groups.map((group) => ({ label: group.name, value: group.id }))} isMulti onChange={value => handleMultipleSelect(value, setGroupId)} />
          </FormGroup>

          <FormGroup controlId="formControlsSelect">
            <ControlLabel>Workers</ControlLabel>
            <Select options={users.map((user) => ({ label: user.full_name, value: user.id }))} isMulti onChange={value => handleMultipleSelect(value, setUserId)} />
          </FormGroup>

          <FormGroup controlId="formControlsSelect">
            <ControlLabel>Cost Codes</ControlLabel>
            <Select options={costCodes.map((costCode) => ({ label: costCode.cost_code, value: costCode.id }))} isMulti onChange={value => handleMultipleSelect(value, setCostCodeId)} />
          </FormGroup>

          <FormGroup controlId="formControlsSelect">
            <ControlLabel>Export Types</ControlLabel>
            <FormControl componentClass="select" placeholder="select">
              <option value="csv">csv</option>
            </FormControl>
          </FormGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="btn-secondary" onClick={handleClose}>
          Close
        </Button>
        <Button className="btn-primary" variant="primary" onClick={handleExport}>
          Export
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExportTimecardsModal;
