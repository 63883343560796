import React from 'react';
import Select from 'react-select';
import { BrowserRouter as Router, Route, Link, withRouter,Redirect, NavLink} from "react-router-dom";
import Login, {User} from "../../pages/HomePage/Login";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
var axios = require('axios');

const initialState = {
    summary: null,
    client: [],
    result:[],
    audit_types:[],
    owners:[],
    projects: [],
    emp_ids: [],
    company_name: '',
    last_updated: '',
    fields: { employee: '', company: '', topic: '', training_status: '' }
};


    
  

class ROTFilters extends React.Component {
    constructor(props) {

        super(props);
        this.state = initialState
        this.handleChange = this.handleChange.bind(this);
        this.resetfilter = this.resetfilter.bind(this);
        this.company = ""
        async function fetchData() {
            const result = await axios.get(
                'analytics_dashboard/user_info', { params: {} }
            );
            // debugger;
            initialState["company_name"] = result.data['company']
            initialState["last_updated"] = result.data["last_updated"]
        }
        fetchData().then(r => r);
    }

    handleChange(field, e){
        let fields = this.state.fields;
        fields[field] = e == null ? '' : e.value;
        this.setState({fields});
        if (e != null){
            this.props.onFilterSelect(field, e);
        }
    }

    resetfilter(){
        var that = this;
        let fields = this.state.fields;
        $.each(fields, function (i, value) {fields[i] = ""});
        this.setState({fields});
        this.props.onFilterSelect("", this);
   }

    renderProjectFilter(results,value="") {
        if (results != undefined){
            let optionItems = results.map((record,key) => {
                return ( <option key={key} value={record.ref_id}>{record.value} </option> );
            });
            if (value == ""){
                optionItems.unshift(<option key="" value="">Select</option>);
            }
            return optionItems;
        }
    }

    renderFiltersData(results,value="") {
        if (results != undefined){
            let optionItems =  results.map((value, i) => {
            return (
                <option key={i} value={value}>{value}</option>
                );
        })

        if (!value){
            optionItems.unshift(<option key="" value="">Select</option>);
        }
        return optionItems;
    }
    }

    renderCompanyName() {
      
      return(
        <div className="whitey"
            style={{
              fontSize: "18px",
              marginLeft: "5px",
              paddingLeft:"5px",
              color: "#fff",
              marginTop: "-10px",
              paddingTop: "0px",
              marginBottom: "20px"

          }}
        >
          <span
              style={{
                  fontSize: "18px",
                  marginLeft: "0px",
                  paddingLeft: "0px",
                  color: "#fff"
                  
              }}>
              {initialState["company_name"]}
          </span>
          <div
            style={{
              fontSize: "11px",
              marginLeft: "0px",
              color: "#fff",
            }}
          >
            (<b>Last Updated:</b> {initialState["last_updated"]} )
          </div>
          
          
        </div>
      )
      
    }


    renderCompanyFilter(filters){
        
        
        if ( User.role == "SuperAdmin"){
            return(
                <div className="col-sm-12">
                    <label className="control-label">Company</label>
                    <Select
                        name="form-field-name"
                        value={this.state.fields["company"]}
                        onChange={this.handleChange.bind(this,"company")}
                        options={filters ? filters.company : filters}
                        id="company"
                        clearable={false}
                    />
                </div>
            )
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.filter_applied == true){
            return false;
        }else{
            return true;
        }
    }

    render() {
        const { filters, is_admin } = this.props;

        return (
            <div className="col-sm-2 side-nav" style={{minWidth:"300px"}}>

                {/*<div className="inner-side-nav" style={{fontSize:"16px"}}>*/}
                    
                    <div className="row" style={{minWidth:"300px"}}>   

                        

                        
                

                
                <div className="col-sm-12 hsms-link mb-ipad-1" style={{minWidth:"300px"}}>
                  {this.renderCompanyName()}
                  {(User.role == "Admin" || User.role == 'SuperAdmin') &&
                          <div className="border-bottom">
                              <a ariaExpanded="false" className="collapsed white" data-toggle="collapse" href="#admin">
                                  <div className="row">
                                      <div className="col-sm-1">
                                          <i style={{paddingInlineStart: "10px"}} className="fa fa-users"></i>
                                      </div>
                                      <div className="col-sm-8">
                                          <span className="left-shim">Users</span>
                                      </div>
                                      <div className="col-sm-2">
                                          <span className="caret"></span>
                                      </div>
                                  </div>
                              </a>

                              <div id="admin" className="collapse">
                                  <ul className="nav nav-collapse" style={{ paddingInlineStart: "65px", fontSize:"14px"}}>
                                      <li>
                                              <span className="sub-item">
                                                  <NavLink to="/company-users" id="" role="button">Users List</NavLink>
                                              </span>
                                      </li>
                                      <li>
                                              <span className="sub-item">
                                                  <NavLink id="new-user-link" to="/new-user" role="button">New User</NavLink>
                                              </span>
                                      </li>
                                      <li>
                                              <span className="sub-item">
                                                  <a id="stats-link" to="/user_management" href="/user_management" role="button">Import Users</a>
                                              </span>
                                      </li>
                                      <li>
                                              <span className="sub-item">
                                                  <a id="stats-link" to="/user_management" href="/groups" role="button">Groups</a>
                                              </span>
                                      </li>
                                  </ul>
                              </div>

                          </div>
                    }
                    {/* <div className="inner-side-nav" style={{height:"400px",width:"100%"}}> */}
                        <div className="border-bottom">
                                  <a ariaExpanded="false" className="collapsed white" data-toggle="collapse" href="#dashboard">
                                    <div className="row">
                                        <div className="col-sm-1">
                                            <i style={{paddingInlineStart: "10px"}} className="fa fa-area-chart"></i>
                                        </div>
                                        <div className="col-sm-8">
                                            <span className="left-shim">Dashboard</span>
                                        </div>
                                        <div className="col-sm-2">
                                            <span className="caret"></span>
                                        </div>
                                    </div>
                                    
                                    
                                    
                                  </a>
                                  
                                  <div id="dashboard" className="collapse">
                                    <ul className="nav nav-collapse" style={{ paddingInlineStart: "65px", fontSize:"14px"}}>
                                      <li>
                                        <span className="sub-item">
                                          <NavLink to="/" id="" role="button">Main Dashboard</NavLink>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="sub-item">
                                          <a  to="/stats" role="button" href="/stats">Stats Dashboard</a>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="sub-item">
                                          <a  to="/reports_dashboards" role="button" href="/reports_dashboards">Reporting Dashboard</a>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="sub-item">
                                            <a  to="/sub-contractor" role="button" href="/sub-contractor">Subcontractor Dashboard</a>
                                        </span>
                                      </li>
                                    </ul>
                                  </div>

                                </div>

                                <div className="border-bottom white">
                                    
                                    <a ariaExpanded="false" className="collapsed white" data-toggle="collapse" href="#training">
                                      <div className="row">
                                        <div className="col-sm-1">
                                          <i style={{paddingInlineStart: "10px"}} className="fa fa-graduation-cap white"></i>
                                        </div>
                                        <div className="col-sm-8">
                                          <span className="left-shim white">
                                            Training
                                          </span> 
                                        </div>
                                        <div className="col-sm-1">
                                          <span className="caret"></span>
                                        </div>
                                      </div>
                                      
                                    </a>
  
                                    <div id="training" className="collapse">
                                      <ul className="nav nav-collapse" style={{ paddingInlineStart: "65px", fontSize:"14px"}}>
                                        <li>
                                          <span className="sub-item">
                                            <a href="/rot_dashboard">Records of Training</a>
                                          </span>
                                        </li>
                                        <li>
                                          <span className="sub-item">
                                            <a href="/trainings/employees_trainings">Training Matrix</a>
                                            <span style={{border:"1px #3fb950 solid",borderRadius:"10px 10px 10px 10px", color:"#3fb950", padding: "1px 10px 1px 10px", marginLeft:"5px", fontSize:"11px" }}>Beta</span>
                                          </span>
                                        </li>
                                        <li>
                                          <span className="sub-item">
                                            <a  to="/template-creator" role="button" href="https://training.msmecor.com" target="_blank">Education</a> <i className="fa fa-external-link whitey" style={{paddingInlineStart:"5px"}} aria-hidden="true"></i>
                                          </span>
                                        </li>
                                   


                                        
                                      </ul>
                                    </div>
                                  </div>
  
  
  
                                  <div className="border-bottom white">
                                    <a ariaExpanded="false" className="collapsed white" data-toggle="collapse" href="#forms">
                                      <div className="row"> 
                                        <div className="col-sm-1">
                                          <i style={{paddingInlineStart: "10px"}} className="fa fa-file white"></i>
                                        </div>
                                        <div className="col-sm-8">
                                          <span className="left-shim white">
                                            Forms
                                          </span>
                                        </div>
                                        <div className="col-sm-1">
                                          <span className="caret"></span>
                                        </div>
                                      </div>
                                    </a>
  
                                    <div id="forms" className="collapse">
                                      <ul className="nav nav-collapse" style={{ paddingInlineStart: "65px", fontSize:"14px"}}>
                                        <li>
                                          <span className="sub-item">
                                            <a  to="/template-creator" role="button" href="/template">Start new form</a>
                                          </span>
                                        </li>
                                        <li>
                                          <span className="sub-item">
                                            <a  to="/template-creator" role="button" href="/template">Template Creator</a>
                                          </span>
                                        </li>
                                        {(User.role == "Admin") &&
                                          <li>
                                            <span className="sub-item">
                                              <a  to="/groups" role="button" href="/groups">Groups</a>
                                            </span>
                                          </li>
                                        }
                                        <li>
                                          <span className="sub-item">
                                            <a  to="/audits" role="button" href="/audits">All Forms</a>
                                          </span>
                                        </li>
                                        
                                      </ul>
                                    </div>
                                  </div>              
  
                                  <div className="border-bottom white">
                                    <a ariaExpanded="false" className="collapsed white" data-toggle="collapse" href="#complyloop">
                                      <div className="row"> 
                                        <div className="col-sm-1">
                                          <i style={{paddingInlineStart: "10px"}} className="fa fa-check-square-o white"></i>
                                        </div>
                                        <div className="col-sm-8">
                                          <span className="left-shim white">
                                            ComplyLoop
                                          </span>
                                        </div>
                                        <div className="col-sm-1">
                                          <span className="caret"></span>
                                        </div>
                                      </div>
                                    </a>
  
                                    <div id="complyloop" className="collapse">
                                      <ul className="nav nav-collapse" style={{ paddingInlineStart: "60px", fontSize:"14px"}}>
                                        {/* <li>
                                          <span className="left-shim white">
                                            <Link to={{
                                                    pathname: '/client',
                                                    search: '?company_name='+client.toLowerCase(),
                                                    state: { company_name: client.toLowerCase() }
                                                }} id="hsms-documents-url" className="white" target="_blank">HSMS Documents</Link>
  
                                          </span>
                                        </li> */}
                                        <li>
                                          <span className="left-shim white">
                                              <a className="white" to="/company_documents" role="button" href="/company_documents">Company Documents</a>
                                          </span>
                                        </li>
                                        <li>
                                          <span className="left-shim sub-item">
                                            <a  to="/sub-contractor" role="button" href="/sub-contractor">Subcontractors</a>
                                          </span>
                                        </li>
                                        
                                      </ul>
                                    </div>
                                  </div> 


                        {(User.role == "Admin") &&
                          <div className="border-bottom white">
                            <a
                                ariaExpanded="false"
                                className="collapsed white"
                                data-toggle="collapse"
                                href="#RAT" >
                                <div className="row">
                                    <div className="col-sm-1">
                                        <i style={{paddingInlineStart: "10px"}} className="fa fa-file white"></i>
                                    </div>
                                    <div className="col-sm-8">
                                        <span className="left-shim white">Risk Assessment</span>
                                    </div>
                                    <div className="col-sm-1">
                                        <span className="caret"></span>
                                    </div>
                                </div>
                            </a>

                            <div id="RAT" className="collapse">
                                <ul className="nav nav-collapse" style={{ paddingInlineStart: "65px", fontSize:"14px"}}>
                                    <li>
                                        <span className="sub-item">
                                            <a to="/groups" role="button" href="/ctra/create">
                                                Create Risk Assessment
                                            </a>
                                        </span>
                                    </li>
                                    <li>
                                        <span className="sub-item">
                                            <a to="/template-creator" role="button" href="/ctra">
                                                Risk Assessments
                                            </a>
                                        </span>
                                    </li>
                                    
                                    <li>
                                        <span className="sub-item">
                                            <a to="/groups" role="button" href="/risk_types">
                                                Risk Types
                                            </a>
                                        </span>
                                    </li>
                                    <li>
                                        <span className="sub-item">
                                            <a to="/riskrange" role="button" href="/riskrange">
                                                Risk Range Settings
                                            </a>
                                        </span>
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                      }


                                  {(User.role == "Admin") &&
                                    <div className="border-bottom white">
    
                                      <div className="row"> 
                                        <div className="col-sm-1">    
                                          <i style={{paddingInlineStart: "10px"}} className="fa fa-exclamation-triangle whitey"></i>
                                        </div>
                                        <div className="col-sm-8">
                                          <span className="left-shim white">
                                              <a className="white" to="/emergency_plans" role="button" href="/emergency_plans">Emergency Plans</a>
                                          </span>
                                        </div>
                                        
    
                                      </div>
                                    </div>
                                  }  
                                  {(User.role == "Admin") &&
                                  <div className="border-bottom white">
                                      <a
                                          ariaExpanded="false"
                                          className="collapsed white"
                                          data-toggle="collapse"
                                          href="#QRCodes" >
                                          <div className="row">
                                              <div className="col-sm-1">
                                                  <i  style={{paddingInlineStart: "10px"}} className="fa fa-qrcode whitey"></i>
                                              </div>
                                              <div className="col-sm-8">
                                                  <span className="left-shim white">QR Codes</span>
                                              </div>
                                              <div className="col-sm-1">
                                                  <span className="caret"></span>
                                              </div>
                                          </div>
                                      </a>

                                      <div id="QRCodes" className="collapse shim-top-gutter">
                                          <ul className="nav nav-collapse" style={{ paddingInlineStart: "65px", fontSize:"14px"}}>
                                              <li>
                                            <span className="sub-item">
                                                <a to="/qr_codes" role="button" href="/qr_codes">
                                                    Group QR Codes
                                                </a>
                                            </span>
                                              </li>
                                              <li>
                                            <span className="sub-item">
                                                <a to="/templates_qr_codes" role="button" href="/templates_qr_codes">
                                                    Form Templates QR Codes
                                                </a>
                                            </span>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                                  }
  
                                  
  
                                  <div className="border-bottom white">
                                    
                                    <div className="row"> 
                                      <div className="col-sm-1">
                                        <i style={{paddingInlineStart: "10px"}} className="fa fa-check whitey" aria-hidden="true"></i>
                                      </div>
                                      <div className="col-sm-8">
                                        <span className="left-shim white">
                                          <a  to="/mrc_dashboard" role="button" className="white" href="/mrc_dashboard">Notices</a>
                                        </span>
                                      </div>
                                      
                                    </div>
                                  </div>

                                  <div className="border-bottom white">
                                    
                                    <div className="row"> 
                                      <div className="col-sm-1">
                                        <i style={{paddingInlineStart: "10px"}} className="fa fa-tasks whitey" aria-hidden="true"></i>
                                      </div>
                                      <div className="col-sm-8">
                                        <span className="left-shim white">
                                          <a  to="/actions" role="button" className="white" href="/actions">Actions</a>
                                        </span>
                                      </div>
                                      
                                    </div>
                                  </div>
  

                                  {(User.role == "Admin") &&
                                    <div className="border-bottom white">
                                      
                                      <div className="row"> 
                                        <div className="col-sm-1">  
                                          <i style={{paddingInlineStart: "10px"}} className="fa fa-sticky-note whitey" aria-hidden="true"></i>
                                        </div>
                                        <div className="col-sm-8">
                                          <span className="left-shim white">
                                            <a  to="/release_notes" className="white" role="button" href="/release_notes">Release Notes</a>
                                          </span>
                                        </div>
                                        
                                      </div>
                                    </div>
                                  }
                                  
                                  </div>

                                      

                                    <div className="row larger-white-row"></div>
                        
                        <ul style={{marginTop:"200px"}} className="nav nav-pills nav-stacked">
                           {(User.role == "Admin" || User.role == 'SuperAdmin') &&
                                <li role="presentation">
                                    <div className="row">
                                        <div className="col-sm-1">
                                            <i style={{paddingInlineStart: "15px"}} className="fa fa-cogs" aria-hidden="true"></i>
                                        </div>
                                        <div className="col-sm-8">
                                            <a href="/company-profile" to="/company" role="button" style={{color: '#fff', fontSize:"16px"}}>
                                                Settings
                                            </a>
                                        </div>
                                    </div>
                                </li>
                                
                           }    
                           { User.role == "Admin" &&
                            <li role="presentation">
                                <div className="row">
                                  <div className="col-sm-1">
                                    <i className="fa fa-sliders" style={{paddingInlineStart: "15px"}} aria-hidden="true"></i>
                                  </div>
                                  <div className="col-sm-8">
                                    <NavLink style={{fontSize:'16px'}} className="whitey" id="stats-link"
                                                                                            to="/stats-settings"
                                                                                            role="button">Stats Settings</NavLink>
                                  </div>
                                
                                </div>                              
                            </li>
                            }
                            <li role="presentation">
                                <div className="row">
                                    <div className="col-sm-1">
                                        <i style={{paddingInlineStart: "15px"}} className="fa fa-user" aria-hidden="true"></i>
                                    </div>
                                    <div className="col-sm-8">
                                        <a
                                            style={{fontSize:"16px"}}
                                            className="whitey"
                                            to="/user-profile"
                                            role="button"
                                            href="/user-profile" >
                                            Profile
                                        </a>
                                    </div>
                                </div>
                            </li>
                        </ul>

                        <span
                            style={{
                                color: "#fff",
                                fontSize: "10px",
                                marginTop: "200px",
                                marginLeft: "25px"
                            }}>
                            {User.version}
                        </span>
                    {/* </div> */}
                </div>
            </div>
            );
    }
}

export default ROTFilters;
