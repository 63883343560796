import React, { useEffect, useState } from "react";
import axios from "../../../../config/axiosConfig";
import { toast } from "react-toastify";
import { TableContainer, Paper, Pagination, MenuItem, FormControl, Select, Switch, Button } from '@mui/material';
import Application from "../../../../config/ApplicationWrapper";
import Loader from "../../../../components/NewLoader";

const TTUserManagement = ({ id }) => {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(15);
    const [pageCount, setPageCount] = useState(0);
    const [pageDetails, setPageDetails] = useState([]);
    const [name, setSearchByName] = useState();
    const [refresh, setRefresh] = useState();
    const [loading, setLoading] = useState(true);
    const [searchable, setSearchable] = useState(false);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getPageDetails()
        }, 2000)

        return () => clearTimeout(delayDebounceFn)
    }, [name, limit, refresh])

    const getPageDetails = async () => {
        try {
            window.scrollTo(0, 0);
            setLoading(true)
            let resultObj = await axios.get(`${Application.api_url}/api/v2/users/user_list`, {
                params: {
                    page: page,
                    limit: limit,
                    full_name_cont: name,
                    view: 'tt_list'
                },
            });
            if (resultObj.status == 200) {
                let details = resultObj.data;
                setPageDetails(details ? details.data : [])
                setPageCount(
                    details && details.metadata ? details.metadata.pages : 0
                );
                setSearchable(details && details.data.length > 0 ? true : false)
                setLoading(false)
            }
        } catch (err) {
            console.log("error==>", err);
        }
    };

    const handleChangePage = (event, value) => {
        setPage(value);
        getPageDetails()
    };

    const handleLimitChange = (value) => {
        setLoading(true);
        setPage(1);
        setLimit(value);
    }

    const clearFilter = () => {
        setLoading(true);
        setSearchByName("");
    }

    const activate = async (value) => {
        var params = { id: value.id, time_tracker_enabled: !value.time_tracker_enabled }
        await axios.post(`${Application.api_url}/api/v2/time_cards/update_time_tracker_access`, params).then((result) => {
            if (result.data && result.data.success === true) {
                toast.success(!value.time_tracker_enabled ? "Enabled Successfully" : "Disabled Successfully")
                value.time_tracker_enabled = !value.time_tracker_enabled
                setRefresh(Date.now());
            } else {
                toast.error(result.data.message);
            }
        }).catch((error) => {
            toast.error(error.response.data.message)
            console.log("error===>", error)
        })
    }

    return (
        <>
            <div class="card asset-tab-landing">
                <div class="card-body">
                    <div class="tab-content">
                        <div
                            id="active-risk-assessment"
                            class="tab-pane fade in active active-risk-assessment internal-sub-employee"
                        >
                            <div className="nestedTable pf-table">
                                <div className="fields-setting">
                                    <div className="asset-active-filters">
                                        Active Filters :
                                        <span className="active-filters">
                                            <span>Users :</span>
                                            <span className="selected">{name ? name : "All"}</span>
                                            <span className={name ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setSearchByName("") }}>X</span>
                                        </span>
                                        <span className="active-filters-clear" onClick={(e) => clearFilter()}>
                                            Clear All
                                        </span>
                                    </div>
                                </div>
                                <TableContainer component={Paper}>
                                    <table class="table table-striped">
                                        <thead>
                                        <tr style={{ height: "100px" }}>
                                            <th scope="col" className="risk-assessment-divider">
                                                <div className="d-flex justify-content-between align-item-center">
                                                    User
                                                </div>
                                                {searchable &&
                                                    <div class="input-group">
                                                        <input
                                                            type="text"
                                                            class="form-control assessment-name"
                                                            placeholder="Search"
                                                            aria-label="Company Name"
                                                            id="full_name"
                                                            aria-describedby="basic-addon1"
                                                            name="full_name"
                                                            onChange={(e) => {
                                                                setLoading(true);
                                                                setSearchByName(e.target.value)
                                                            }}
                                                            value={name}
                                                        />
                                                        <div class="input-group-addon">
                                                                <span class="input-group-text" id="basic-addon1">
                                                                    <i class="fa fa-search" aria-hidden="true"></i>
                                                                </span>
                                                        </div>
                                                    </div>
                                                }
                                            </th>
                                            <th scope="col" className="table-action">Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {!loading && pageDetails &&
                                            pageDetails.length > 0 &&
                                            pageDetails.map((val, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            {val.full_name}
                                                        </td>
                                                        <td className="template-options" style={{ textAlign: "center" }}>
                                                            <Switch
                                                                checked={val.time_tracker_enabled}
                                                                onClick={(event) => activate(val)}
                                                            />
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                        <Loader pageDetails={pageDetails} loading={loading} btnName={"+ Add BillingTo"} open={open} />
                                    </table>
                                </TableContainer>
                                <div className="page-limits">
                                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                        <Select
                                            labelId="demo-select-small"
                                            id="demo-select-small"
                                            value={limit}
                                            onChange={(e) => {
                                                handleLimitChange(e.target.value);
                                            }}
                                        >
                                            <MenuItem value={5}>5</MenuItem>
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={20}>20</MenuItem>
                                            <MenuItem value={30}>30</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {!loading && (
                                        <Pagination
                                            count={pageCount}
                                            page={page}
                                            variant="outlined"
                                            shape="rounded"
                                            onChange={handleChangePage}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TTUserManagement;
