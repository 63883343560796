import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import NewForm from "../../../../../assets/images/Internal-sub/NewForm.svg";
import Forms from "../../../../../assets/images/Internal-sub/Forms.svg";
import Documents from "../../../../../assets/images/Internal-sub/Documents.svg";
import Training from "../../../../../assets/images/Internal-sub/Training.svg";
import Loader from "../../../../../components/MobileViewLoader";


const Dashboard = () => {
    const [loading, setLoading] = useState(true)

    setTimeout((() => {
        setLoading(false)
    }), [1000])
    return (
        <>
            <Loader loading={loading} />
            {!loading &&
                <div className="internal-sub-mobile-sidebar">
                    <h4><b>Internal Sub Apps</b></h4>
                    <div className="row">
                        <div className="col-xs-4 my-3">
                            <NavLink
                                to={`/inter_sub_m_new_forms`}
                                id=""
                                role="button"
                                className="rat-links"
                            >
                                <div className="item-details">
                                    <img src={NewForm} alt="NewForm" />
                                    <p>New Form</p>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-xs-4 my-3">
                            <NavLink
                                to={`/inter_sub_m_forms`}
                                id=""
                                role="button"
                                className="rat-links"
                            >
                                <div className="item-details">
                                    <img src={Forms} alt="Forms" />
                                    <p>Forms</p>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-xs-4 my-3">
                            <NavLink
                                to={`/inter_sub_m_docs`}
                                id=""
                                role="button"
                                className="rat-links"
                            >
                                <div className="item-details">
                                    <img src={Documents} alt="Documents" />
                                    <p>Documents</p>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-xs-4 my-3">
                            <NavLink
                                to={`/inter_sub_m_trainings`}
                                id=""
                                role="button"
                                className="rat-links"
                            >
                                <div className="item-details">
                                    <img src={Training} alt="Training" />
                                    <p>Training</p>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default Dashboard;