import React, { useRef, useEffect } from "react";
import HighchartsReact from "highcharts-react-official";

const Chart = ({ options, type, highcharts }) => {
    const chartComponent = useRef(null);

    useEffect(() => {
        const chart = chartComponent.current.chart;
        console.log(chart);

        setTimeout(() => {
            chart.reflow();
        }, 2000);
    }, []);

    return (
        <HighchartsReact
            highcharts={highcharts}
            constructorType={type}
            options={options}
            ref={chartComponent}
        />
    );
};

export default Chart;
