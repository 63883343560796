import React, { useEffect, useContext, useState } from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "../../../../assets/images/searchbar.svg";
import Application from "../../../../config/ApplicationWrapper";
import Paper from "@mui/material/Paper";
import Map from "./Map";
import { AssetDetailsContext } from "./index";
import Cookies from "js-cookie";

const mapsKey = "AIzaSyBFtyJFE7Y6FhOOX8R7z5XnYOLyk6Gfirk";
const mapURL = `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${mapsKey}`;

export default function GeoLocationView() {
  const { pageData } = useContext(AssetDetailsContext);
  const cookieDetails = Cookies.get("token");
  const [pageDetails, setPageDetails] = useState([]);
  const [mapPoints, setMapPoints] = useState();
  const [search, setSearch] = useState([]);

  useEffect(() => {
    try {
      async function getPageDetails() {
        if (pageData && pageData.id) {
          const groupDetails = await fetch(
            `${Application.api_url}/api/v2/asset_trackings/${
              pageData.id
            }/geo_location_trackings?limit=${10}&page=${1}&address_cont=${search}&sort=created_at+desc`,
            {
              method: "GET",
              headers: { Authorization: cookieDetails },
            }
          );
          const details = await groupDetails.json();
          setPageDetails(details && details.data ? details.data : []);
          if (details && details.data) {
            let newpoint = [];
            details.data.find((res) => {
              let points = {};
              points.lat = parseFloat(res.lat);
              points.lng = parseFloat(res.lon);
              newpoint.push(points);
            });
            setMapPoints({ ...{}, newpoint });
          }
        }
      }
      getPageDetails();
    } catch (err) {
      console.log("err===>", err);
    }
  }, [pageData, search]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4.8} xl={4}>
        <div className="location-log">
          <h4 className="geo-title">geo location log</h4>
          <div className="location-search">
            <Paper
              component="form"
              sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search Location"
                inputProps={{ "aria-label": "search google maps" }}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <img src={SearchIcon} />
              </IconButton>
            </Paper>
          </div>
          <div className="location-list">
            <List>
              {pageDetails &&
                pageDetails.map((obj, index) => (
                  <ListItem>
                    <div className="log-info-wrap">
                      <div className="count-wrap">
                        <span className="location-count">{pageDetails.length - (index)}</span>
                      </div>
                      <div className="log-info">
                        <h3>{obj.address}</h3>
                        <p>
                          ({obj.lat}, {obj.lon})
                        </p>
                        <span>{obj.created_at}</span>
                      </div>
                      <label className="status-label">{obj.added_type}</label>
                    </div>
                  </ListItem>
                ))}
            </List>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={7.2} xl={8}>
        <div className="location-map">
          <h4 className="geo-title">geo location map</h4>

          <Map
            googleMapURL={mapURL}
            containerElement={<div style={{ flexGrow: 1, height:500 }} />}
            mapElement={<div style={{ height: "100%" }} />}
            loadingElement={<p>Cargando</p>}
            mapPoints={mapPoints}
          />
        </div>
      </Grid>
    </Grid>
  );
}
