import React, { useEffect, useState } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import axios from "axios";
import ErrorFallback from "../../components/ErrorFallback";

const AuditDetails = ({ query }) => {
  const [auditDetails, setAuditDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    async function fetchData() {
      //setLoading(true);
      try {
        let search_query = Object.entries(query).reduce(
          (a, [k, v]) => (v ? ((a[k] = v), a) : a),
          {}
        );

        const result = await axios.get("analytics_dashboard/forms_data", {
          params: search_query,
        });
        setAuditDetails(result.data);
        // debugger;
        setLoading(false);
      } catch (e) {
        setError(true);
      }
    }
    fetchData().then((r) => {
      console.log("render success");
    });
  }, [query]);

  const renderSizePerPageDropDown = (props) => {
    return (
      <SizePerPageDropDown
        open={props.open}
        className="my-size-per-page"
        btnContextual="btn-warning"
        variation="dropup"
        onClick={ () => this.onToggleDropDown(props.toggleDropDown) }
      />
    );
  };

  const renderShowsTotal = (start, to, total) => {
    return (
        <span className="pagination-total">
        {start} -&nbsp;{to} of&nbsp;{total} &nbsp; Records
      </span>
    );
  };

  const options = {
    sizePerPageDropDown: renderSizePerPageDropDown,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: auditDetails.length,
      },
    ],
    prePage: "Prev", // Previous page button text
    nextPage: "Next", // Next page button text
    firstPage: "First", // First page button text
    lastPage: "Last", // Last page button text
    prePageTitle: "Go to previous", // Previous page button title
    nextPageTitle: "Go to next", // Next page button title
    firstPageTitle: "Go to first", // First page button title
    lastPageTitle: "Go to Last", // Last page button title
    paginationShowsTotal: renderShowsTotal,
  };

  const sortDates = (rowData, rIndex) => {
    return rIndex.started;
  };

  const trClassFormat = (rowData, rIndex) => {
    return rIndex % 2 === 0 ? "" : ""; //tr-function-example
  };

  const sortCompletedDates = (rowData, rIndex) => {
    return rIndex.completed;
  };

  const auditLink = (rowData, rIndex) => {
    return (
      <a href={rowData} target="_blank">
        PDF Link
      </a>
    );
  };

  return (
    <React.Fragment>
      <div className={loading ? "card loader" : "card"}>
        {!error && (
          <BootstrapTable
            data={auditDetails}
            trClassName={trClassFormat}
            options={options}
            pagination
            exportCSV
          >
            <TableHeaderColumn isKey={true} dataField="id" hidden>
              ID
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="sort_started"
              width="10%"
              dataSort={true}
              dataFormat={sortDates}
            >
              Started
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="sort_completed"
              width="8%"
              dataSort={true}
              dataFormat={sortCompletedDates}
            >
              Completed
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="owner"
              width="12%"
              filter={{ type: "TextFilter" }}
            >
              Username
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="company"
              width="14%"
              filter={{ type: "TextFilter" }}
            >
              Company
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="owner_name"
              width="12%"
              filter={{ type: "TextFilter" }}
            >
              Owner
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="group_name"
              width="20%"
              
            >
              Group
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="pdf_link"
              width="6%"
              dataFormat={auditLink}
            >
              PDF Link
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="name"
              width="30%"
              filter={{ type: "TextFilter" }}
            >
              Name
            </TableHeaderColumn>
          </BootstrapTable>
        )}
        {error && (<ErrorFallback/>)}
      </div>
    </React.Fragment>
  );
};

export default AuditDetails;
