import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
import { Button, TextField, Checkbox, FormGroup, FormControlLabel, FormControl, Autocomplete, FormHelperText, Switch } from '@mui/material';
import axios from "../../../../config/axiosConfig";
import Application from "../../../../config/ApplicationWrapper";
import File from "../../../../assets/fonts/File-cover.svg";
import Upload from "../../../../assets/images/Upload.svg";
import DeleteIcon from "../../../../assets/images/delete-icon.svg";
import { toast } from "react-toastify";

export default function UploadFiles(props) {
    const { show, closepopup, details, parentId } = props;
    const label = { inputProps: { 'aria-label': 'Switch demo' } };
    const uploadInputRef = useRef(null);
    const [group, setGroup] = useState([]);
    const [onSubmit, setSubmit] = useState(false);
    const [drag, setDrag] = useState(false);
    const [validation, setValidation] = useState(true);
    const [groupsList, setGroupsList] = useState([]);
    const [docs, setDocs] = useState([]);
    const [values, setValues] = useState({
        name: "",
        description: "",
        parent_id: "",
        expiry_date: "",
        is_public: false,
        is_global_project: false,
        is_hsms_doc: false,
        group: []
    })

    useEffect(() => {
        async function fetchGroup() {
            const groupDetails = await axios.get(`${Application.api_url}/api/v2/common/groups?type=PublicGroup`);
            if (groupDetails.status == 200 && groupDetails.data.success === true) {
                var details = groupDetails.data;
                setGroupsList(details.data);
            }
        }
        fetchGroup()
    }, [])


    useEffect(() => {
        setValues({ ...values, ...details });
        if (details && details.global_project_ids) {
            let groupDetails = groupsList.filter(person => details.global_project_ids.includes(person.id));
            setGroup(groupDetails)
        }
    }, [details])

    const onhandleStatusClose = () => {
        setValues({
            name: "",
            description: "",
            parent_id: "",
            expiry_date: "",
            is_public: false,
            is_global_project: false,
            is_hsms_doc: false,
            group: []
        });
        setGroup([]);
        setDocs([])
        setSubmit(false)
        closepopup();
        setDrag(false)
    };

    const handleChange = (name, value) => {
        setValues({ ...values, [name]: value });
    }

    const create = async () => {
        setSubmit(true);
        if (docs.length == 0) {
            setValidation(false)
            return false;
        } 
        var users = localStorage.getItem('user');
        users = JSON.parse(users);
        const formData = new FormData();
        formData.append("doc_detail[doc_type]", "file");
        formData.append("doc_detail[user_id]", users.id);
        formData.append("doc_detail[company_id]", users.company_id);
        //formData.append("doc_detail[folder_name]", values.name);
        formData.append("doc_detail[description]", values ? values.description : "");
        parentId && formData.append("doc_detail[parent_id]", parentId);
        formData.append("doc_detail[expiry_date]", values ? values.expiry_date : "");
        formData.append("doc_detail[is_global_project]", values ? values.is_global_project : false);
        formData.append("doc_detail[is_public]", values ? values.is_public : false);
        formData.append("doc_detail[is_hsms_doc]", values ? values.is_hsms_doc : false);
        if (values.is_global_project) {
            [...group].forEach(obj => {
                formData.append("global_project_ids[]", obj.id);
            });
        }

        [...docs].forEach(image => { 
            formData.append("docs[]", image);
        });

        let url = `${Application.api_url}/api/v2/company_documents/${users.company_id}/upload`;

        if (details) {
            url = `${Application.api_url}/api/v2/company_documents/${details.slug}`;
        }

        const result = await axios({
            method: "PATCH",
            url: url,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if (result.data && result.data.success === true) {
            toast.success(`${result.data.message}`)
            closepopup(result.data);
            setValues("");
            setGroup([]);
            setDocs([]);
            setSubmit(false)
            setDrag(false)
        }
    }

    function Documents() {
        const handleFileChange = (file) => {
            setDocs([...docs, ...file]);
        };
    
        const handleDelete = (fileToDelete) => {
            setDocs(docs.filter(file => file !== fileToDelete));
        };
    
        try {
            if (docs.length === 0) {
                return (
                    <>
                        {drag ? (
                            <FileUploader handleChange={handleFileChange} name="file" multiple={true} label="Upload or drop files here" className="drop-box"/>
                        ) : (
                            <div className="create-asset-upload">
                                <Button className="uploadButton" component="label">
                                    <img src={Upload} alt="" /> Upload Files
                                    <input
                                        style={{ display: "none" }}
                                        hidden
                                        type="file"
                                        multiple
                                        ref={uploadInputRef}
                                        error={docs.length == 0 && onSubmit}
                                        helperText={
                                            docs.length == 0 && onSubmit ? "Image/File is required" : ""
                                        }
                                        onChange={(e) => {
                                            setDocs(Array.from(e.target.files));
                                        }}
                                    />
                                </Button>
                            </div>
                        )}
                        <FormHelperText
                            className="inputErrorMsg"
                            error={!validation && onSubmit === true ? true : false}
                        >
                            {!validation && onSubmit === true
                                ? "Please upload your Files"
                                : ""}
                        </FormHelperText>
                    </>
                )
            } else if (docs.length != 0) {
                return (
                    <div className="attachment-grid">
                        {docs && Array.isArray(docs) && docs.map((doc, index) => (
                            <div key={index} className="file-item">
                                <img 
                            src={File} 
                            className="file-type" 
                            alt="file" 
                            style={{ width: '80px', height: 'auto' }} 
                        />
                                <p className="file-name">{doc.name}</p> {/* */}
                                <button 
                                    className="delete-button" 
                                    onClick={() => handleDelete(doc)}
                                    aria-label={`Delete ${doc.name}`}
                                    style={{
                                        position: 'absolute',
                                        top: '0',
                                        right: '0',
                                        transform: 'translate(-165%, -50%)',
                                        backgroundColor: 'transparent',
                                        border: 'none',
                                        cursor: 'pointer',
                                        padding: '0'
                                    }}
                                >
                                    <img 
                                        src={DeleteIcon} 
                                        alt="Delete" 
                                        style={{
                                            width: '24px',
                                            height: '24px'
                                        }}
                                    />
                                </button>
                            </div>
                        ))}
                    </div>
                )
            }
        } catch (err) {
            console.log("error===>", err)
        }
    }
    

    return (
        <Modal
            show={show}
            onHide={(e) => {
                onhandleStatusClose();
            }}
            className="qr-modal-wrap add-task-modal pf-model docs-model"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="pf-model-header">
                        <p className="pf-model-title">Upload Files</p>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="drag-drop-option">
                                <label className="info-text">
                                    <span style={{color:"red"}}>Note : </span>For uploading folders or larger files, please utilize the bulk upload
                                </label>
                                {/* <Switch {...label} checked={drag} onChange={(e) => { setDocs([]); setDrag(!drag) }} /> */}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <label>
                                Image
                            </label>
                            <Documents />
                        </div>
                        <div className="col-md-12">
                            <label>Description</label>
                            <textarea
                                className="create-asset-textarea"
                                aria-label="empty textarea"
                                value={values.description}
                                onChange={(e) => {
                                    handleChange("description", e.target.value);
                                }}
                            />
                        </div>
                        <div className="col-md-12">
                            <label>
                                Expiry Date
                            </label>
                            <input
                                type="date"
                                min={new Date().toJSON().split("T")[0]}
                                class="date-field"
                                id="expiry_date"
                                placeholder="Search"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                name="expiry_date"
                                data-date="MM DD YYYY"
                                data-date-format="MM DD YYYY"
                                value={values.expiry_date}
                                onChange={(e) => {
                                    handleChange("expiry_date", e.target.value);
                                }}
                            />
                        </div>
                        <div className="col-md-12">
                            <FormControl component="fieldset">
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        value="start"
                                        control={<Checkbox checked={values.is_public} onChange={(event) => { handleChange("is_public", event.target.checked) }} name="is_public" />}
                                        label="Visible in ComplyLoop"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="start"
                                        control={<Checkbox checked={values.is_hsms_doc} onChange={(event) => { handleChange("is_hsms_doc", event.target.checked) }} name="is_hsms_doc" />}
                                        label="Visible to HSMS"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="start"
                                        control={<Checkbox checked={values.is_global_project} onChange={(event) => { handleChange("is_global_project", event.target.checked) }} name="is_global_project" />}
                                        label="Visible ONLY to selected Global Project"
                                        labelPlacement="end"
                                    />
                                </FormGroup>
                            </FormControl>
                        </div>
                        {values.is_global_project &&
                            <div className="col-md-12">
                                <label>
                                    Groups <text style={{ color: "red", fontSize: "16px" }}>*</text>
                                </label>
                                <Autocomplete
                                    multiple={true}
                                    limitTags={2}
                                    disablePortal={true}
                                    id="multiple-limit-tags"
                                    disableCloseOnSelect
                                    options={groupsList}
                                    getOptionLabel={(options) => options.name}
                                    defaultValue={group}
                                    onChange={(event, value) => setGroup(value)}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderOption={(props, option) => <li {...props} key={option.id}>{option.name}</li>}
                                    renderInput={(params) => (
                                        <TextField {...params} />
                                    )}
                                />
                                <FormHelperText
                                    className="inputErrorMsg"
                                    error={group.length === 0 && onSubmit}
                                >
                                    {group.length === 0 && onSubmit === true ? "Group is required" : ""}
                                </FormHelperText>
                            </div>
                        }
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="submit-btns">
                    <Button className="btn-cancel" onClick={(e) => { onhandleStatusClose() }}>Cancel</Button>
                    <Button variant="contained" onClick={(event) => {
                        event.currentTarget.disabled = true;
                        create()
                    }}>
                        {details ? "Update" : "Upload"}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

