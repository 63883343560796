import React, { useState, useReducer } from "react";
import { Button } from '@mui/material';
import { NavLink } from "react-router-dom";
import SideNav from "../../../components/SideNav";
import ArrowUp from "../../../assets/fonts/ArrowRight.svg";
import Classification from "./Classification";
import CostCode from "./CostCode";
import TimeType from "./TimeType";
import BillingTo from "./BillingTo";
import TTUserManagement from "./TTUserManagement";
import Geofence from "./Geofence";

const initialTabStatus = {
    tt_classifications: true,
    tt_cost_code: false,
    tt_time_types: false,
    tt_billing_tos: false,
    tt_usr_mgmt: false,
    docs: false,
    geofence: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case "UPDATE":
            state[action.tab] = true;
            return { ...state};
        default:
            return state;
    }
};

const index = (params) => {
    let userDetails = localStorage.getItem("role");
    userDetails = JSON.parse(userDetails);
    const id = params.match.params.id;
    const [qrModel, setQrModel] = useState(false);
    const [tabs, dispatch] = useReducer(reducer, initialTabStatus);

    const close = () => {
        setQrModel(false)
    }

    const handleUpdateTab = (tab) => {
        dispatch({ type: "UPDATE", tab: tab });
    };

    return (
        <React.Fragment>
            <div className="msmcontainer dashboard-container">
                <div className="row d-flex">
                    <SideNav />
                    <div className="content-wrap asset-tracking-wrap risk-assessment Internal-sub-employee">
                        <div className="title-tab-wrap">
                            <div className="title-card">
                                <div className="title-content">
                                    <NavLink
                                        to={'/time-tracking'}
                                        id=""
                                        role="button"
                                        className="rat-links"
                                    >
                                        <div>
                                            <img src={ArrowUp} alt="back-arrow" className="back-arrow" />
                                        </div>
                                    </NavLink>
                                    <h4>
                                        <b>Configuration</b>
                                    </h4>
                                </div>
                            </div>
                            <ul className="nav nav-tabs">
                                <li className="active">
                                    <a data-toggle="tab" href="#tt_classifications">
                                        Classification
                                    </a>
                                </li>
                                {(userDetails && userDetails.name == "Manager" || userDetails.name == "Admin" || userDetails && userDetails.name == "SuperAdmin") &&
                                    <li onClick={(e) => {
                                        handleUpdateTab('tt_cost_code')
                                    }}>
                                        <a data-toggle="tab" href="#tt_cost_code">
                                            Cost Code
                                        </a>
                                    </li>
                                }
                                <li onClick={(e) => { handleUpdateTab('tt_time_types') }}>
                                    <a data-toggle="tab" href="#tt_time_types">
                                        Time Type
                                    </a>
                                </li>
                                <li onClick={(e) => { handleUpdateTab('tt_billing_tos') }}>
                                    <a data-toggle="tab" href="#tt_billing_tos">
                                        Billing To
                                    </a>
                                </li>
                                <li onClick={(e) => { handleUpdateTab('tt_usr_mgmt') }}>
                                    <a data-toggle="tab" href="#tt_usr_mgmt">
                                        Time Tracking User Management
                                    </a>
                                </li>
                                <li onClick={(e) => { handleUpdateTab('geofence') }}>
                                    <a data-toggle="tab" href="#geofence">
                                        Geofence Management
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="tab-content">
                            <div id="tt_classifications" className="tab-pane fade in active">
                                {tabs.tt_classifications && <Classification id={id} />}
                            </div>
                            <div id="tt_cost_code" className="tab-pane fade in ">
                                {tabs.tt_cost_code && <CostCode id={id} />}
                            </div>
                            <div id="tt_time_types" className="tab-pane fade in ">
                                {tabs.tt_time_types && <TimeType id={id} />}
                            </div>
                            <div id="tt_billing_tos" className="tab-pane fade in ">
                                {tabs.tt_billing_tos && <BillingTo id={id} />}
                            </div>
                            <div id="tt_usr_mgmt" className="tab-pane fade in ">
                                {tabs.tt_usr_mgmt && <TTUserManagement id={id} />}
                            </div>
                            <div id="geofence" className="tab-pane fade in ">
                                {tabs.geofence &&
                                <Geofence id={id} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {qrModel &&
                <ViewPopup show={qrModel} closepopup={close} details={id} />
            }
        </React.Fragment>
    );
};

export default index;
