import React from 'react'
import HeroSection from './CommonComponents/HeroSection'
import FeaturesSection from './CommonComponents/FeaturesSection'
import ParallaxSection from '../../pages/HomePage/Components/ParallaxSection'
import HomepageDemoSection from '../../pages/HomePage/Components/HomepageDemoSection'
import r1 from '../../../assets/images/risk1.png'
import r2 from '../../../assets/images/risk2.png'
import r3 from '../../../assets/images/risk3.png'

export default function RiskAssessmentManagement() {
  return (
    <>
      <HeroSection heroText={"Comprehensive Risk Assessment Management"} subText={"Mitigate risks effectively with SafetyLoop's advanced management tools. Streamline risk assessment, analysis, and mitigation for a more robust safety management system."} />
      <FeaturesSection
        headerText={"Simplified Risk Assessment Creation"}
        subText={"Effortlessly identify and manage risks with SafetyLoop's intuitive risk assessment tools. Empower your team to proactively address safety concerns."}
        featureHighlights={["Create custom risk assessments tailored to your industry and specific needs.",
          "Easily input and evaluate potential hazards and risks.",
          "Collaborate with stakeholders to gather valuable insights and input.",
          "Streamline the process of prioritizing and mitigating risks.",
          "Generate comprehensive reports for informed decision-making."]}
        sectionBackground='dark'
        sectionAlign="image-right"
        featureImageSrc={r1}
      />
      <FeaturesSection
        headerText={"Flexible Risk Types"}
        subText={"Adapt to your industry's unique risk landscape with SafetyLoop's versatile risk types capabilities. Classify risks for targeted mitigation."}
        featureHighlights={["Define and categorize risk types specific to your organization.",
          "Customize risk types to align with industry standards and regulations.",
          "Efficiently track and manage risks by type for improved oversight.",
          "Enhance risk assessment precision with tailored risk type templates."]}
        featureImageSrc={r2}
      />
      <FeaturesSection
        headerText={"Fine-Tuned Risk Range Settings"}
        subText={"Take control of your risk tolerance levels with SafetyLoop's comprehensive risk range settings. Define acceptable risk thresholds for informed decision-making."}
        featureHighlights={["Configure risk range settings to match your organization's risk appetite.",
          "Set clear boundaries for low, moderate, and high-risk scenarios.",
          "Receive real-time alerts when risks approach or exceed predefined thresholds.",
          "Make data-driven decisions by analyzing risks within established ranges.",
          "Foster a proactive safety culture by proactively addressing potential issues."]}
        sectionBackground='dark'
        sectionAlign="image-right"
        featureImageSrc={r3}
      />
      <div className="Home-Page sl-home-wrapper" style={{ paddingTop: 0 }}>
        <ParallaxSection />
      </div>
      <div className="features-homepage-wrapper">
        <HomepageDemoSection mergeTop={false} />
      </div>
    </>
  )
}
