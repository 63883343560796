import React from 'react';
import useFetch from '../../../shared/components/userFetch';
import SharedForms from './SharedForm';
import { withRouter } from "react-router-dom";
import usePatch from '../../../shared/components/usePatch';

const SharedFormEdit = ({edit, setEdit, setFilterState, match}) => {
    const { id } = match.params; // Access the 'id' param from the URL
    const {data, isLoading} = useFetch(`/api/v2/entity_management/groups/${id}/load_group_shared_form/${edit.id}`);
    const {mutate, isLoading: isMutating, error} = usePatch(`/api/v2/entity_management/global_projects/${id}/save_group_template_users`);
    if (isLoading) {
        return null;
    }
    return (
        <SharedForms mutate={mutate} open={!!edit} setOpen={setEdit} initialValues={data.data} setFilterState={setFilterState} />
    )
}

export default withRouter(SharedFormEdit);
