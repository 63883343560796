import { useEffect, useState } from 'react';
import axios from 'axios';

const useFetchFiltersData = () => {
    const [filters, setFilters] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const result = await axios.get("analytics_dashboard/filters_data", {
                params: { form_type: true },
            });
            setFilters(result.data);
        }
        fetchData().then((r) => r);
    }, []);

    return {
        filters
    }
}

export default useFetchFiltersData;