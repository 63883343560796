import React from "react";
import Highcharts from "highcharts";
import exporting from "highcharts/modules/exporting";
import HourlyChart from "./HourlyChart";
import PieChart from "./PieChart";
import FormsPerDayChart from "./FormsPerDayChart";
exporting(Highcharts);
import useFetchChartData from "../../hooks/useFetchChartData";
import ErrorFallback from "../../components/ErrorFallback";
import ActionStatusChart from "./ActionsStatusChart";

const FormsChart = ({ query, onFilterSelect }) => {
  const { stats, loading, error } = useFetchChartData(query);

  return (
      <div className="chart-wrap">
        <div className={loading ? "card loader" : "card"}>
          {loading && (
            <div className="loading-block">
              <h4>Forms Per Day</h4>
              <img src="/main-images/newloader.gif" style={{width:"175px"}}/>
            </div>
          )}
          {!error && (
            <FormsPerDayChart
              stats={stats.forms_per_day}
              query={query}
              onFilterSelect={onFilterSelect}
              error={error}
            />
          )}
            {error && (<ErrorFallback/>)}
        </div>
      </div>
  );
};

export default FormsChart;
