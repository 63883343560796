import React, { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { AssetDetailsContext } from "./index";
import { Button } from "@mui/material";
import Upload from "../../../../assets/images/Upload.svg";
import Application from "../../../../config/ApplicationWrapper";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { FormGroup, ControlLabel, FormControl as Input } from "react-bootstrap";
import Cookies from "js-cookie";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import axios from "axios";
import loader from "../../../../assets/images/newloader.gif";

export default function AssetInfoView() {
  const { pageData, type } = useContext(AssetDetailsContext);
  const [responsibleParties, setResponsibleParties] = useState([]);
  const [party, setParty] = useState(pageData.managers);
  const [imageFile, setImageFile] = useState("");
  const [imageChanged, setImageChanged] = useState(false);
  const [groupsList, setGroupsList] = useState([]);
  const [onSubmit, setSubmit] = useState(false);
  const cookieDetails = Cookies.get("token");
  const [assetTypes, setAssetTypes] = useState([]);
  const [loading, setLoading] = useState(false);

  const [trackedAmount, setTrackedAmount] = useState(0);
  const [trackerType, setTrackerType] = useState('');
  const [unitLabel, setUnitLabel] = useState('Select');

  let units = ['Hours', 'Mileage', 'Kilometers'];
  let permissionDetails = localStorage.getItem('role');
  permissionDetails = JSON.parse(permissionDetails);

  const unitLabelToKeyMap = {
    "Hours": "hours_tracker_value",
    "Mileage": "mileage_tracker_value",
    "Kilometers": "kilometers_tracker_value",
  };

  const [values, setValues] = useState({
    name: "",
    status: "",
    description: "",
    notes: "",
    company: "",
    group: "",
    model: "",
    serial: "",
    managers: "",
    image: "",
    mileage: "",
    hours: "",
    days: "",
    kilometers: "",
    project: "",
    manufacturer: "",
    maintenanceDate: "",
    maintenanceMilage: "",
    maintenanceHours: "",
    approvedMaintenance: "",
    asset_type_id: "",
    unitValue: "",
  });

  useEffect(() => {
    try {
      async function getPageDetails() {
        let data = {
          name: pageData.name,
          status: pageData.status,
          description: pageData.description,
          notes: pageData.notes && pageData.notes != "null" ? pageData.notes : "",
          company: pageData.company_name,
          model: pageData.model,
          serial: pageData.serial,
          managers: pageData.managers,
          image: pageData.image_url,
          manufacturer: pageData.manufacturer,
          mileage:
            pageData.tracker_type && pageData.tracker_type == "Mileage"
              ? pageData.tracked_amount
              : "",
          hours:
            pageData.tracker_type && pageData.tracker_type == "Hours"
              ? pageData.tracked_amount
              : "",
          days:
            pageData.tracker_type &&
              pageData.tracker_type == "Days"
              ? pageData.tracked_amount
              : "",
          kilometers:
            pageData.tracker_type &&
              pageData.tracker_type == "Kilometers"
              ? pageData.tracked_amount
              : "",
          group:
            pageData.asset_groups && pageData.asset_groups.length > 0
              ? pageData.asset_groups[0].group.id
              : "",
          groupName: pageData.asset_groups && pageData.asset_groups.length > 0 ? pageData.asset_groups[0].group.name : '',
          maintenanceMilage:
            pageData.last_tracker_type &&
              pageData.last_tracker_type == "Mileage"
              ? pageData.last_tracked_amount
              : "",
          maintenanceHours:
            pageData.last_tracker_type && pageData.last_tracker_type == "Hours"
              ? pageData.last_tracked_amount
              : "",
          maintenanceDate: pageData.last_maintenance_date
            ? pageData.last_maintenance_date
            : "",
          asset_type_id: pageData.asset_type ? pageData.asset_type.id : "",
          unitValue: pageData.tracked_amount
        };
        setParty(pageData.managers);
        setValues(data);
        setLoading(true);
        pageData.tracker_type ? setUnitLabel(pageData.tracker_type) : null

        const groupDetails = await fetch(`${Application.api_url}/api/groups`, {
          method: "GET",
          headers: { Authorization: cookieDetails },
        });
        const details = await groupDetails.json();
        setGroupsList(details && details ? details : []);

        const asset_types = await fetch(
          `${Application.api_url}/api/v2/asset_types?archived_true=${false}`,
          {
            method: "GET",
            headers: { Authorization: cookieDetails },
          }
        );
        const assetTypeDetails = await asset_types.json();
        setAssetTypes(assetTypeDetails && assetTypeDetails.data ? assetTypeDetails.data : []);

        const responsiblePartiesDetails = await fetch(
          `${Application.api_url}/api/v2/common/responsible_parties`,
          {
            method: "GET",
            headers: { Authorization: cookieDetails },
          }
        );
        const result = await responsiblePartiesDetails.json();
        setResponsibleParties(result && result.data ? result.data : []);

        // setTimeout(function () {
        //   setLoading(true);
        // }, 2000)
      }
      getPageDetails();
    } catch (err) {
      console.log("err===>", err);
    }
  }, []);


  useEffect(() => {
    if(permissionDetails.name !== 'Admin') {
      const t = setTimeout(() => {
        previousAssetTracker("unitValue", values.unitValue)
      }, 900);
      return () => {
        clearTimeout(t)
      }
    }
  }, [values.unitValue])

  const handleChange = (name, event) => {
    switch (name) {
      case "mileage":
        values.mileage = event.target.value;
        values.hours = "";
        values.days = "";
        values.kilometers = "";

        setValues({ ...values });
        setTrackedAmount(event.target.value);
        setTrackerType('Mileage');
        break;
      case "hours":
        values.mileage = "";
        values.hours = event.target.value;
        values.days = "";
        values.kilometers = "";

        setTrackedAmount(event.target.value);
        setTrackerType('Hours');
        setValues({ ...values });
        break;
      case "days":
        values.mileage = "";
        values.hours = "";
        values.days = event.target.value;
        values.kilometers = "";

        setTrackedAmount(event.target.value);
        setTrackerType('Days');
        setValues({ ...values });
        break;
      case "kilometers":
        values.mileage = "";
        values.hours = "";
        values.days = "";
        values.kilometers = event.target.value;

        setTrackedAmount(event.target.value);
        setTrackerType('Kilometers');
        setValues({ ...values });
        break;
      case "maintenanceMilage":
        values.maintenanceMilage = event.target.value;
        values.maintenanceHours = "";
        setValues({ ...values });
        break;
      case "maintenanceHours":
        values.maintenanceHours = event.target.value;
        values.maintenanceMilage = "";
        setValues({ ...values });
        break;
      default:
        setValues({ ...values, [name]: event.target.value });
    }
  };

  const previousAssetTracker = (name, value) => {
    const key = unitLabelToKeyMap[unitLabel];

    let previous_asset_tracker_type_values = pageData.tracker_type === unitLabel ? pageData.tracked_amount :
      pageData.previous_asset_tracker_type_values[key];

    if (value < previous_asset_tracker_type_values) {
      toast.info(`Please enter current ${unitLabel} greater than ${previous_asset_tracker_type_values} ( previous maintenance value )`);
      setValues({ ...values, [name]: previous_asset_tracker_type_values });
      return false;
    } else {
      setValues({ ...values, [name]: value });
      return true;
    }
  };

  const imageUpload = (value) => {
    setImageFile(value ? URL.createObjectURL(value) : "");
    setValues({ ...values, ["image"]: value });
    setImageChanged(true);
  };

  const updateAssetInfo = async () => {
    setSubmit(true);

    if (pageData.group_archived) {
      toast.warning("Please change the group");
      return false;
    }

    if (
      !values.name ||
      !values.status ||
      !values.group ||
      !values.model ||
      !values.serial ||
      party.length === 0 ||
      !values.unitValue
    ) {
      return false;
    }
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("description", values.description);
    formData.append("notes", values.notes);
    formData.append("status", values.status);
    formData.append("model", values.model);
    formData.append("serial", values.serial);
    formData.append("manufacturer", values.manufacturer);
    formData.append("tracked_amount", values.unitValue);
    formData.append("tracker_type", unitLabel);
    formData.append("asset_group_ids", values.group);
    formData.append("last_maintenance_date", values.maintenanceDate);
    formData.append("asset_type_id", values.asset_type_id);
    formData.append(
      "last_tracked_amount",
      values.maintenanceMilage
        ? parseFloat(values.maintenanceMilage)
        : parseFloat(values.maintenanceHours)
    );
    formData.append(
      "last_tracker_type",
      values.maintenanceMilage ? "Mileage" : "Hours"
    );
    formData.append("manager_ids", party.map((obj) => obj.id).join(","));
    if (imageChanged === true) {
      formData.append("image", values.image);
    }

    axios.defaults.headers.common["Authorization"] = cookieDetails;

    const result = await axios({
      method: "PUT",
      url: `${Application.api_url}/api/v2/asset_trackings/${pageData.identifier}`,
      data: formData,
    });

    if (result.status == 200) {
      toast.success("Updated Successfully");
      setImageChanged(false);
    }
  };

  return (
    <div className="AssetInfoEdit">
      <h4 className="header">Asset Information</h4>
      {loading === false ? (
        <div className="new-loader">
          <img src={loader} alt="" />
        </div>
      ) : (
        <div className="body">
          <div className="row">
            <div className="col-md-7">
              <div className="row">
                <div className="col-md-12">
                  <label className="Asset-view-lable">
                    Asset Name <text style={{ color: "red" }}>*</text>
                  </label>
                  <TextField
                    fullWidth
                    required
                    inputProps={{ className: "asset-view-text-field" }}
                    id="outlined-required"
                    value={values.name}
                    error={!values.name && onSubmit === true ? true : false}
                    helperText={
                      !values.name && onSubmit === true
                        ? "Asset Name is required"
                        : ""
                    }
                    onChange={(e) => {
                      handleChange("name", e);
                    }}
                    name="name"
                  />
                </div>
                <div className="col-md-12">
                  <label className="Asset-view-lable">
                    Status <text style={{ color: "red" }}>*</text>
                  </label>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    inputProps={{ className: "asset-view-text-field" }}
                    className="status-field"
                    defaultValue={values.status}
                    fullWidth
                    name="status"
                    onChange={(e) => {
                      handleChange("status", e);
                    }}
                  >
                    <FormControlLabel
                      checked={values.status == "In Use" ? true : false}
                      className="status-field"
                      value="In Use"
                      style={{ fontSize: "14px" }}
                      control={<Radio />}
                      label="In Use"
                    />
                    <FormControlLabel
                      value="Repair"
                      checked={values.status == "Repair" ? true : false}
                      control={<Radio />}
                      label="Repair"
                    />
                    <FormControlLabel
                      value="Idle"
                      checked={values.status == "Idle" ? true : false}
                      control={<Radio />}
                      label="Idle"
                    />
                  </RadioGroup>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="Asset-view-lable">Asset Type</label>
                  <FormControl
                    controlId="formControlsSelectMultiple"
                    required
                    fullWidth
                  >
                    <Select
                      labelId="demo-simple-select-error-label"
                      id="demo-simple-select-error"
                      value={values.asset_type_id}
                      fullWidth
                      input={<OutlinedInput sx={{ fontSize: "14px" }} />}
                      onChange={(e) => {
                        handleChange("asset_type_id", e);
                      }}
                    >
                      {assetTypes.map((val) => (
                        <MenuItem
                          value={val.id}
                          style={{ fontSize: 14 }}
                          key={val.id}
                        >
                          {val.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-md-6">
                  <label className="Asset-view-lable">
                    Model # <text style={{ color: "red" }}>*</text>
                  </label>
                  <TextField
                    required
                    fullWidth
                    inputProps={{ className: "asset-view-text-field" }}
                    id="outlined-required"
                    value={values.model}
                    name="model"
                    error={!values.model && onSubmit === true ? true : false}
                    helperText={
                      !values.model && onSubmit === true
                        ? "Model is required"
                        : ""
                    }
                    onChange={(e) => {
                      handleChange("model", e);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="Asset-view-lable">
                    Serial # <text style={{ color: "red" }}>*</text>
                  </label>
                  <TextField
                    required
                    fullWidth
                    inputProps={{ className: "asset-view-text-field" }}
                    id="outlined-required"
                    value={values.serial}
                    error={!values.serial && onSubmit === true ? true : false}
                    helperText={
                      !values.serial && onSubmit === true
                        ? "Serial is required"
                        : ""
                    }
                    name="serial"
                    onChange={(e) => {
                      handleChange("serial", e);
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <label className="Asset-view-lable">Manufacturer Field</label>
                  <TextField
                    required
                    fullWidth
                    inputProps={{ className: "asset-view-text-field" }}
                    id="outlined-required"
                    value={values.manufacturer}
                    onChange={(e) => {
                      handleChange("manufacturer", e);
                    }}
                    // error={!values.manufacturer && onSubmit === true ? true : false}
                    // helperText={
                    //   !values.manufacturer && onSubmit === true
                    //     ? "Manufacturer is required"
                    //     : ""
                    // }
                    name="manufacturer"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <label className="Asset-view-lable">
                    Current Mileage/Hours or Kilometers
                    <text style={{ color: "red" }}> *</text>
                  </label>
                  <div className="mileage-hours-field">
                    <div className="input-group">
                      <div className="input-group-btn">
                        <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown"
                          aria-haspopup="true" aria-expanded="false">
                          {unitLabel}
                          <span className="caret"></span>
                        </button>
                        <ul className="dropdown-menu">
                          {
                            units.map((unitName) => {
                              return <li onClick={() => {
                                const key = unitLabelToKeyMap[unitName];
                                let previous_asset_tracker_type_values = pageData.tracker_type === unitName ? pageData.tracked_amount :
                                  pageData.previous_asset_tracker_type_values[key];
                                setValues({ ...values, ['unitValue']: previous_asset_tracker_type_values });
                                setUnitLabel(unitName)
                              }}>
                                <span>{unitName}</span>
                              </li>
                            })
                          }
                        </ul>
                      </div>
                      <input type="number" className="form-control mileage-hours-input" aria-label="..."
                        placeholder={unitLabel !== 'Select' ? unitLabel : 'Choose Unit'} value={values.unitValue}
                        onChange={(e) => {
                          handleChange('unitValue', e)
                          unitLabel === 'Select' ? setUnitLabel('Mileage') : null
                        }}
                      />
                    </div>
                    {/*<div className="fields">*/}
                    {/*  <label>Mileage</label>*/}
                    {/*  <span>*/}
                    {/*    <TextField*/}
                    {/*      required*/}
                    {/*      className="text-field"*/}
                    {/*      id="outlined-required"*/}
                    {/*      value={values.mileage}*/}
                    {/*      name="mileage"*/}
                    {/*      onChange={(e) => {*/}
                    {/*        handleChange("mileage", e);*/}
                    {/*      }}*/}
                    {/*    />*/}
                    {/*  </span>*/}
                    {/*</div>*/}
                    {/*<div className="or-lable">*/}
                    {/*  <span>Or</span>*/}
                    {/*</div>*/}
                    {/*<div className="fields">*/}
                    {/*  <label>Hours</label>*/}
                    {/*  <span>*/}
                    {/*    <TextField*/}
                    {/*      required*/}
                    {/*      className="text-field"*/}
                    {/*      id="outlined-required"*/}
                    {/*      value={values.hours}*/}
                    {/*      name="hours"*/}
                    {/*      onChange={(e) => {*/}
                    {/*        handleChange("hours", e);*/}
                    {/*      }}*/}
                    {/*    />*/}
                    {/*  </span>*/}
                    {/*</div>*/}
                    {/*<div className="or-lable">*/}
                    {/*  <span>Or</span>*/}
                    {/*</div>*/}
                    {/*<div className="fields">*/}
                    {/*  <label>Days</label>*/}
                    {/*  <span>*/}
                    {/*    <TextField*/}
                    {/*      className="text-field"*/}
                    {/*      id="outlined-required"*/}
                    {/*      value={values.days}*/}
                    {/*      name="days"*/}
                    {/*      onChange={(e) => {*/}
                    {/*        handleChange("days", e);*/}
                    {/*      }}*/}
                    {/*    />*/}
                    {/*  </span>*/}
                    {/*</div>*/}
                    {/*<div className="or-lable">*/}
                    {/*  <span>Or</span>*/}
                    {/*</div>*/}
                    {/*<div className="fields">*/}
                    {/*  <label>Kilometers</label>*/}
                    {/*  <span>*/}
                    {/*    <TextField*/}
                    {/*      className="text-field"*/}
                    {/*      id="outlined-required"*/}
                    {/*      value={values.kilometers}*/}
                    {/*      name="kilometers"*/}
                    {/*      onChange={(e) => {*/}
                    {/*        handleChange("kilometers", e);*/}
                    {/*      }}*/}
                    {/*    />*/}
                    {/*  </span>*/}
                    {/*</div>*/}
                  </div>
                  <FormHelperText
                    className="inputErrorMsg"
                    error={!values.unitValue && onSubmit}
                  >
                    {!values.unitValue && onSubmit
                      ? unitLabel === 'Select' ? 'Unit is required' : `${unitLabel} is required`
                      : ""}
                  </FormHelperText>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <label className="Asset-view-lable">Company</label>
                  <TextField
                    required
                    fullWidth
                    inputProps={{ className: "asset-view-text-field" }}
                    id="outlined-required"
                    name="company"
                    disabled={true}
                    value={localStorage.getItem("token")}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <FormControl
                    controlId="formControlsSelectMultiple"
                    required
                    fullWidth
                  >
                    <ControlLabel className="Asset-view-lable">
                      Group <text style={{ color: "red" }}>*</text>
                    </ControlLabel>
                    <div className="asset-group-field">
                      <Select
                        labelId="demo-simple-select-error-label"
                        id="demo-simple-select-error"
                        className="assetCreateInfoGroup"
                        fullWidth
                        input={
                          <OutlinedInput
                            sx={{ fontSize: "14px", borderColor: "red" }}
                          />
                        }
                        value={values.group}
                        onChange={(e) => {
                          handleChange("group", e);
                          pageData.group_archived = false;
                        }}
                      >
                        {pageData.group_archived &&
                          <MenuItem
                             value={values.group}
                            style={{ fontSize: 14 }}
                            key={values.group}
                          >
                            {values.groupName} - <span className="badge badge-light ArchivedStatus">Archived</span>
                          </MenuItem>
                        }
                        {groupsList.map((val) => (
                          <MenuItem
                            value={val.id}
                            style={{ fontSize: 14 }}
                            key={val.id}
                          >
                            {val.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </FormControl>
                  <FormHelperText
                    className="inputErrorMsg"
                    error={!values.group && onSubmit === true ? true : false}
                  >
                    {!values.group && onSubmit === true
                      ? "Group is required"
                      : ""}
                  </FormHelperText>
                </div>
                <div className="col-md-12">
                  <label className="Asset-view-lable">
                    Responsible Party <text style={{ color: "red" }}>*</text>
                  </label>
                  <FormGroup controlId="formControlsSelectMultiple">
                    <Autocomplete
                      multiple={true}
                      limitTags={2}
                      id="multiple-limit-tags"
                      options={responsibleParties}
                      disableCloseOnSelect
                      disablePortal={true}
                      getOptionLabel={(options) =>
                        `${options.last_name},${options.first_name} (${options.employee_id})`
                      }
                      renderOption={(props, option) => (
                        <li {...props} key={option.id} style={{ fontSize: 14 }}>
                          {option.last_name},{option.first_name} ({option.employee_id})
                        </li>
                      )}
                      defaultValue={party}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, value) => setParty(value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          placeholder=""
                          className="mui-new-autocomplete"
                          error={party.length == 0 && onSubmit}
                          helperText={
                            party.length == 0 && onSubmit
                              ? "Responsible Party is required"
                              : ""
                          }
                        />
                      )}
                      sx={{ width: "100%" }}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="row">
                <div className="col-md-12">
                  <div className="create-information-text-area">
                    <label className="Asset-view-lable">Description</label>
                    <textarea
                      className="create-asset-textarea"
                      aria-label="empty textarea"
                      name="description"
                      value={values.description}
                      onChange={(e) => {
                        handleChange("description", e);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="create-information-text-area">
                    <label className="Asset-view-lable">Notes</label>
                    <textarea
                      className="create-asset-textarea"
                      aria-label="empty textarea"
                      name="notes"
                      value={values.notes}
                      onChange={(e) => {
                        handleChange("notes", e);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <label className="Asset-view-lable">Upload Image</label>
                  <div className="create-asset-upload">
                    <div>
                      <Button className="uploadButton" component="label">
                        <img src={Upload} alt="" /> Upload Image
                        <input
                          style={{ display: "none" }}
                          hidden
                          accept="image/*"
                          type="file"
                          onChange={(e) => {
                            imageUpload(e.target.files[0]);
                          }}
                        />
                      </Button>
                    </div>
                  </div>
                  {values.image && (
                    <div className="uploadedImage">
                      <img
                        src={imageFile ? imageFile : values.image}
                        alt="signature"
                        className="assetimage"
                      />
                      <i
                        className="fa fa-trash delete-icon"
                        onClick={(e) => {
                          imageUpload("");
                          setImageChanged(true);
                        }}
                      ></i>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="button-section">
            <Link to="/asset-tracking">
              <button className="btn asset-cancel-btn">Cancel</button>
            </Link>
            <button
              className="btn btn-primary asset-next-btn"
              onClick={(e) => {
                updateAssetInfo();
              }}
            >
              Update
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
