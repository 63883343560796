import React, { Fragment } from 'react'

const CompanyInfo = ({client}) => {
    return (
        <div className="company-info">
            <h3>
                <i className="fa fa-building-o" aria-hidden="true"></i>
                company information
            </h3>

            <ul>
                <li>
                    Industry - {client.industry}
                </li>
                <li>
                    state - {client.state}
                </li>
                <li>
                    city - {client.city}
                </li>
                <li>
                    address - {client.address}
                </li>
            </ul>
        </div>
    )
}

export default CompanyInfo;
