import React from 'react';
import NewUserCreate from './newUser';
import useFetch from '../../../shared/components/userFetch';
import useModify from '../../../shared/components/userModify';

const NewUserEdit = ({edit, setEdit, refetch}) => {
    const {data, isLoading} = useFetch(`/api/v2/entity_management/users/${edit}`);
    const {mutate, isLoading: isMutating, error} = useModify('/api/v2/entity_management/users');
    if (isLoading) {
        return null;
    }
    return (
        <NewUserCreate mutate={mutate} open={!!edit} setOpen={setEdit} initialValues={data.data} refetch={refetch} isMutating={isMutating} />
    )
}

export default NewUserEdit;
