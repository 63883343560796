import RelatedAsset from "./RelatedAsset";
import React, {createContext, useState, useContext} from "react";
import RelatedAssetModal from "../Popups/RelatedAssetModal";
export const RelatedAssetContext = createContext();
import { AssetCreateContext } from "./index";

export const RelatedAssetInfo = () => {
    const [refresh, setRefresh] = useState();
    const [assetAction, setAssetAction] = useState(false);
    const [relatedAssetPopup, setRelatedAssetPopup] = useState(false);
    const { setTabKey } = useContext(AssetCreateContext);


    const getAssetInfo = () => {
        let assetInfo =  localStorage.getItem("assetInfo");
        return JSON.parse(assetInfo);
    }

    const setAssetInfo = (key) => {
        let {asstId } = getAssetInfo();
        localStorage.setItem(
            "assetInfo",
            JSON.stringify({ asstId: asstId, step: key })
        );
        setTabKey(key)
    }

    return (
        <div className="relatedAssets">
            <div className="header d-flex">
                <h3 className="title"><b>Add Related Assets</b></h3>
                { assetAction &&
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                            setRelatedAssetPopup(true)
                        }}
                    >
                        <i className="fa fa-plus" aria-hidden="true"></i>
                        Add Related Assets
                    </button>
                }
            </div>
            <div className="body">
                <RelatedAssetContext.Provider
                    value={{relatedAssetPopup, setRelatedAssetPopup, setAssetAction, setRefresh, refresh}}
                >
                    <RelatedAsset />
                    <RelatedAssetModal />
                </RelatedAssetContext.Provider>

            </div>
            <div className="d-flex footer">
                <button className="btn button" onClick={() => { setAssetInfo("3")} }>
                    Skip
                </button>
                <div>
                    <button className="btn button" onClick={() => { setAssetInfo("1")} }>
                        Previous
                    </button>
                    <button className="btn btn-primary" onClick={() => { setAssetInfo("3")} }>
                        Next
                    </button>
                </div>
            </div>
        </div>
    )
}