import React, { useEffect, useState } from 'react';
import axios from 'config/axiosConfig';
import Application from '../../config/ApplicationWrapper';

const NotificationCenter = ({ isOpenNotiDropdown }) => {
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(null);
  const [activeDropdownIndex, setActiveDropdownIndex] = useState(null);
  const notificationMenuClassName = `dropdown-menu nav-user-info__notification-menu dropdown-user animated ${isOpenNotiDropdown ? 'show' : ''}`;
  const entityTypeIcons = {
    "Training": "fa-graduation-cap",
    "Emergency Call": "fa-exclamation-triangle",
    "Audit Response": "fa-check-double",
    "Audit Pdf": "fa-file-pdf",
    "Action": "fa-tasks",
    "Audit Action": "fa-clipboard-check",
    "Asset Action": "fa-cogs",
  };
  const statusDisplays = {
    "to_do": "To Do",
    "in_progress": "In Progress",
  }

  const initialParams = {
    page: 1,
    limit: 30,
    archived_eq: false,
    sort: ["created_at desc"],
  };

  const [params, setParams] = useState(initialParams);
  useEffect(() => {
    if (!isOpenNotiDropdown || isLoading || (totalPage && params.page > totalPage)) return;

    setIsLoading(true);
    axios.get("/api/notifications", { params: {...params} }).then((response) => {
      const uniqueNotifications = Array.from(new Set([...notifications, ...response.data.data]));
      setNotifications(uniqueNotifications);
      setIsLoading(false);
      if(response.data.metadata.pages !== totalPage) {
        setTotalPage(response.data.metadata.pages);
      }
    });
  }, [isOpenNotiDropdown, params]);

  useEffect(() => {
    if (isOpenNotiDropdown) return;

    setNotifications([]);
    setParams(initialParams);
  }, [isOpenNotiDropdown]);

  const handleDropdownClick = (notificationId) => {
    setActiveDropdownIndex(notificationId === activeDropdownIndex ? null : notificationId);
  };

  const updateNotification = (notification, params) => {
    axios.put(`/api/notifications/${notification.id}`, params).then((response) => {
      const updatedNotification = response.data.data;
      setNotifications(notifications.map((notification) => notification.id === updatedNotification.id ? updatedNotification : notification));
      setActiveDropdownIndex(null);
    });
  }

  const openPdfLink = (notification) => {
    window.open(`/audits/${notification.other.audit_id}/report.pdf`, '_blank');
  }

  const onNotificationClick = (notification) => {
    if(notification.read === false) updateNotification(notification, { read: true });

    switch(notification.entity_type) {
      case 'Training':
        window.open("/rot_dashboard", '_blank');
        break;
      case 'Emergency Call':
        window.open(`/view-plan/view/${notification.other.plan_id}`, '_blank');
        break;
      case 'Audit Pdf':
      case 'Audit Response':
        openPdfLink(notification);
        break;
      case 'Action':
      case 'Audit Action':
        window.open(`/approver/audit_action_note/${notification.other.audit_action.audit_action_id}`, '_blank');
        break;
      case 'Asset Action':
        window.open(`/approver/asset_action_note/${notification.other.asset_action.id}`, '_blank');
        break;
      case 'Inventory Import':
        download(notification)
        break;
    }
  }


  const handleScroll = (e) => {
    const buffer = 10;
    const bottom = e.target.scrollHeight - e.target.scrollTop - buffer < e.target.clientHeight;
    if (bottom && params.page < totalPage && !isLoading) {
      setParams((params) => ({ ...params, page: params.page + 1 }));
    }
  };


  const download = async (notification) => { 
    try { 
      axios
        .get(
            `${Application.api_url}/api/v2/inventories/imported_report?id=${notification.other.import_log_id}`,
            {
              responseType: "blob",
            }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(
              new Blob([response.data])
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "imported_report.csv"); //or any other extension
          document.body.appendChild(link)
          ;
          link.click();
        })
        .catch((error) => {
          console.log("err==>", error);
        });
          
    } catch (err) {
      console.log("error==>", err);
    }
  };

  return (
    <ul id="notificationMenu" className={notificationMenuClassName} onScroll={handleScroll}>
        <div className="scroll-wrapper dropdown-user-scroll scrollbar-outer" style={{ position: "relative" }}>
            <p className='nav-user-info__notification-menu-title'>NOTIFICATIONS</p>
            <div className="dropdown-user-scroll scrollbar-outer scroll-content" style={{ height: "auto", marginBottom: "0px", marginRight: "0px" }}>
                {notifications.map((notification) => (
                  <li key={notification.id} className="li-row" onClick={() => onNotificationClick(notification)}>
                    <div className="nav-user-info__notification-menu__header">
                      <div className="nav-user-info__notification-menu__entity">
                        <i className={`nav-user-info__notification-menu__entity-icon fas ${entityTypeIcons[notification.entity_type]}`}></i>
                        {notification.entity_type}
                      </div>
                      <div className="nav-user-info__notification-menu__actions">
                        <i className="fas fa-ellipsis-v open-dropdown-menu" onClick={(event) => { event.stopPropagation(); handleDropdownClick(notification.id); }}></i>
                        <ul className={`dropdown-menu dropdown-user animated action-dropdown ${activeDropdownIndex === notification.id ? 'show' : ''}`} >
                          <li>
                            <a href="#" onClick={(event) => { event.stopPropagation(); updateNotification(notification, { read: !notification.read });}} >Mark as {notification.read ? 'Unread' : 'Read'}</a>
                          </li>
                          <li>
                            <a href="#" onClick={(event) => { event.stopPropagation(); updateNotification(notification, { archived: !notification.archived }); }}>Move to {notification.archived ? 'Unarchive' : 'Archive'}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="nav-user-info__notification-menu__title">
                      <span>{notification.title}</span>
                    </div>
                    <div className="nav-user-info__notification-menu__message">
                      <span>{notification.message}</span>
                    </div>
                    { notification.entity_type === 'Emergency Call' &&
                      <div className={`nav-user-info__notification-menu__emergency-call ${notification.severity}`}>
                        <i className={`fas fa-arrow-${notification.severity == 'major' ? 'up' : 'down'}`}></i>
                        <span>{notification.severity}</span>
                      </div>
                    }
                    { notification.entity_type === 'Asset Action' &&
                      <div className="nav-user-info__notification-menu__asset-action">
                        <span className={`nav-user-info__notification-menu__asset-action-status-${notification.status}`}>{statusDisplays[notification.status] || notification.status}</span>
                        <span className={`nav-user-info__notification-menu__asset-action-severity-${notification.severity}`}>
                          <i className="fas fa-exclamation-triangle" />
                          {notification.severity}
                        </span>
                      </div>
                    }
                    <div className="nav-user-info__notification-menu__created_at">
                      <span>{notification.created_at}</span>
                    </div>
                    <div className="nav-user-info__notification-menu__time-in-words">
                      <div>
                        <span>{notification.time_ago_in_words} ago</span>
                        <i className={`nav-user-info__notification-menu__read-status fas fa-circle ${notification.read ? '' : 'text-success'}`}></i>
                      </div>
                      { notification.archived ? <div className="nav-user-info__notification-menu__archived">Archived</div> :
                        notification.read ? '' : <div className="nav-user-info__notification-menu__unread">Unread</div>
                      }
                    </div>
                  </li>
                ))}
            </div>
        </div>
    </ul>
  );
};
export default NotificationCenter;
