import React,{useEffect} from "react";
import { Button } from "react-bootstrap";
import SearchIcon from "../../assets/images/searchbar.svg";

export default function HelpUs() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="HelpPage">
      <div className="help-us-search">
        <div className="home-container">
          <h1>
            <b>How Can We Help You?</b>
          </h1>
          <div className="d-flex  helpus-searchbar">
            <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 helpus-searchbar-content">
              <div className="form-group">
                <input
                  className="form-control"
                  id="email"
                  placeholder="Search"
                />
                <img src={SearchIcon} className="searchIcon" alt="" />
              </div>
            </div>
            <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1">
              <Button className="search-btn">Search</Button>
            </div>
          </div>
        </div>
      </div>
      <div className="help-us-card">
        <div className="home-container">
          <div className="d-flex ">
            <div className="col-xs-12 col-md-4 col-lg-4">
              <div className="helpus-card-content">
                <div className="card-title">
                  <h4>Getting Started</h4>
                  <p>
                    New to Safetyloop? No problem. Use these resources to start
                    your onboarding process.
                  </p>
                </div>
                <ul>
                  <li>
                    + <a> Getting Started with Safetyloop</a>
                  </li>
                  <li>
                    + <a> Subcontractor Basics</a>
                  </li>
                  <li>
                    + <a> Live Training Webinar</a>
                  </li>
                  <li>
                    + <a> Learning Paths</a>
                  </li>
                  <li>
                    + <a> User management</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xs-12 col-md-4 col-lg-4">
              <div className="helpus-card-content">
                <div className="card-title">
                  {" "}
                  <h4>What's New?</h4>
                  <p>
                    Learn about the newest features to the Safetyloop platform
                    and get a sneak peek about what's coming soon.
                  </p>
                </div>
                <ul>
                  <li>
                    + <a> What’s New in Safetyloop?</a>
                  </li>
                  <li>
                    + <a> Product Releases</a>
                  </li>
                  <li>
                    + <a>Advanced Training</a>
                  </li>
                  <li>
                    + <a> Foam Template</a>
                  </li>
                  <li>
                    + <a> Auditing</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xs-12 col-md-4 col-lg-4">
              <div className="helpus-card-content">
                <div className="card-title">
                  <h4>Resource Center</h4>
                  <p>
                    Use the links below to access our most popular resources.
                  </p>
                </div>
                <ul>
                  <li>
                    + <a> FAQs</a>
                  </li>
                  <li>
                    + <a> Client Sloutions</a>
                  </li>
                  <li>
                    + <a> Covid-19: Best Practices</a>
                  </li>
                  <li>
                    + <a> Directory</a>
                  </li>
                  <li>
                    + <a> App Marketplace</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
