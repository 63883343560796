import React, { useState } from "react";
import { TableContainer, Paper } from '@mui/material';
import { toast } from "react-toastify";
import axios from "@config/axiosConfig";
import Loader from "@components/NewLoader";
import Application from "@config/ApplicationWrapper";

import Filter from "@assets/images/Filter.png";
import URLCopy from "@assets/fonts/URLCopy.svg";
import EditBtn from "@assets/fonts/edit.svg";
import DeleteBtn from '@assets/fonts/Delete.svg'
import EmptyTable from "@assets/images/empty-assets.svg";

import Folder from "@assets/fonts/Folder.svg";
import FolderExpired from "@assets/fonts/FolderExpired.svg";

import PNGIcon from "@assets/fonts/png.png";
import JPGIcon from "@assets/fonts/jpg.png";
import MP4Icon from "@assets/fonts/mp4.png";
import MP3Icon from "@assets/fonts/mp3.png";
import ZIPIcon from "@assets/fonts/zip.png";
import PPTIcon from "@assets/fonts/ppt.png";
import XLSIcon from "@assets/fonts/xls.png";
import PDFIcon from "@assets/fonts/pdf.png";
import DOCSIcon from "@assets/fonts/docs.png";
import XLSXIcon from "@assets/fonts/xlsx1.png";

const ListView = ({
                      pageDetails,
                      deleteFolder,
                      changeDirectory: changeDirectory,
                      edit,
                      changeSorting,
                      stared,
                      searching,
                      searchByName,
                      searchByType,
                      searchByCreatedBy,
                      searchByUpdatedAt,
                      update,
                      findAsset,
                      loading,
                      companyId
                  }) => {
    const [sorting, setSorting] = useState(false);
    const [sortedBy, setSortedBy] = useState("file_name");

    const handleClick = async (val) => {
        findAsset(val.is_asset_doc)
        if (val.is_directory) {
            changeDirectory(val)
        } else {
            try {
                const response = await axios.get(`${Application.api_url}/api/v2/company_documents/${val.slug}`);
                if (response.status === 200 && response.data.success === true) {
                    const values = response.data.data;
                    if (values.file && values.file.url) {
                        window.open(values.file.url, "_blank")
                    }
                }
            } catch (error) {
                console.error("Error fetching document:", error);
            }
        }
    }

    const fileIcons = {
        Folder: Folder,
        FolderExpired: FolderExpired,
        png: PNGIcon,
        jpg: JPGIcon,
        jpeg: JPGIcon,
        pdf: PDFIcon,
        doc: DOCSIcon,
        docx: DOCSIcon,
        xlsx: XLSXIcon,
        xls: XLSIcon,
        csv: XLSIcon,
        mp4: MP4Icon,
        mp3: MP3Icon,
        zip: ZIPIcon,
        ppt: PPTIcon,
        pptx: PPTIcon,
    };

    const FileType = ({ item }) => {
        let fileExtension = item.is_directory ? 'Folder' : item.name.split('.').pop().toLowerCase();
        let fileIcon = fileIcons[fileExtension] || DOCSIcon;
        if(item.is_directory && item.expired) fileIcon = fileIcons['FolderExpired']

        return (
            <img src={fileIcon} alt="image" className="file-icon"/>
        );
    }

    const onDragStart = (event, id) => {
        event.dataTransfer.setData('id', id);
    };

    const onDragOver = (event) => {
        event.preventDefault();
    };

    const onDrop = async (event, targetId) => {
        const draggedId = event.dataTransfer.getData('id');
        const draggedItem = pageDetails.find(item => item.int_id == draggedId);
        const targetItem = pageDetails.find(item => item.int_id == targetId);

        if (canMoveDocument(targetId, draggedId, draggedItem, targetItem)) {
            try {
                const response = await moveDocument(draggedItem, targetItem);
                if (response && response.status === 200) {
                    toast.success("Moved Successfully");
                    update(Date.now());
                }
            } catch (error) {
                console.error("Error moving document:", error);
            }
        }
    };

    const canMoveDocument = (targetId, draggedId, draggedItem, targetItem) => {
        return (
            targetId &&
            draggedId &&
            targetItem.is_directory &&
            !draggedItem.is_asset_doc &&
            !targetItem.is_asset_doc
        );
    };

    const moveDocument = async (draggedItem, targetItem) => {
        const formData = new FormData();
        formData.append("doc_detail[parent_id]", targetItem.int_id);
        formData.append("company_identifier", companyId);

        return await axios({
            method: "PATCH",
            url: `${Application.api_url}/api/v2/company_documents/${draggedItem.slug}`,
            data: formData,
        });
    };

    return (
        <TableContainer component={Paper}>
            <table className="table table-striped internal-docs">
                <thead>
                <tr className="docs-header risk-assessment-divider">
                    <th scope="col" className="risk-assessment-divider">
                        Name
                    </th>
                    <th scope="col" className="risk-assessment-divider">
                        Type
                    </th>
                    <th scope="col" className="risk-assessment-divider">
                        Created By
                    </th>
                    <th scope="col" className="risk-assessment-divider">
                        Updated At
                    </th>
                    <th scope="col" className="table-action">
                        Action
                    </th>
                </tr>
                </thead>
                <tbody>
                { pageDetails && pageDetails.length > 0 && pageDetails.map((val, index) => (
                        <tr key={index} onDragOver={onDragOver} onDrop={(e) => onDrop(e, val.int_id)}>
                            <td className="alerts-name drag drag-docs" draggable="true"
                                onDragStart={(e) => onDragStart(e, val.int_id)}>
                                <p onClick={(e) => { handleClick(val) }} className={`pf-name-list ${val.expired ? 'folder-expired' : ''}`}>
                                    <FileType item={val} />{val.name}
                                </p>
                            </td>
                            <td className="ui-droppable">
                                {val.is_directory ? "Folder" : "File"}
                            </td>
                            <td className="ui-droppable">
                                <p className="action-by">{val.created_by}</p>
                                <p className="action-time">{val.created_at}</p>
                            </td>
                            <td className="ui-droppable">
                                {val.updated_at}
                            </td>
                            <td className="table-action ui-droppable">
                                {(
                                    <>
                                        <img src={DeleteBtn} alt="" className="cursor-pointer" onClick={() => deleteFolder(val.slug)} />
                                        <img src={EditBtn} alt="" className="cursor-pointer" onClick={() => edit(val)} />
                                        <img src={URLCopy} alt="" className="cursor-pointer" onClick={() => navigator.clipboard.writeText(`${window.location.origin}/company_documents/${val.slug}`)} />
                                    </>
                                )}
                            </td>
                        </tr>
                    )
                )}
                <Loader pageDetails={pageDetails} loading={loading} />
                </tbody>
            </table>
        </TableContainer>
    )
}

export default ListView;
