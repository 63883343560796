import React, {useState} from 'react'
import {Form, Modal, FormGroup, FormControl, Button} from "react-bootstrap";
import { rejectAsAdmin } from "../utils";

const RejectModal = ({show, close, itemId, fetchDetails}) => {
  const [rejectReason, setRejectReason] = useState('');

  const handleReject = async () => {
    await rejectAsAdmin(itemId, rejectReason);
    fetchDetails();
    close();
  }

  return (
    <Modal
      show={show}
      onHide={(_) => close()}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Reject Reason
        </Modal.Title>
        <Modal.Body>
          <Form>
            <FormGroup>
              <FormControl type="text" name="reject_reason" onChange={(e) => setRejectReason(e.target.value)}></FormControl>
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="btn-secondary" onClick={close}>
            Close
          </Button>

          <Button variant="primary" className="btn-primary" onClick={handleReject}>
            Reject
          </Button>
        </Modal.Footer>
      </Modal.Header>
    </Modal>
  )
}

export default RejectModal;