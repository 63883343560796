import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { Button, TextField, OutlinedInput, MenuItem, Select, FormControl, FormHelperText } from '@mui/material';
import SideNav from "../../../components/SideNav";
import ArrowUp from "../../../assets/fonts/ArrowRight.svg";
import axios from "../../../config/axiosConfig";
import Application from "../../../config/ApplicationWrapper";

const CreateCompany = ({ history, match }) => {
    const [values, setValues] = useState({
        name: "",
        industry: "",
        state: "",
        city: "",
        address: "",
        timezone: "US/Eastern",
        phone_number: "",
        first_name: "",
        last_name: "",
        role_id: 1,
        email: "",
        company_info: "",
        license_number: "",
        website: "",
        fax: "",
        phonenumber: "",
        zipcode: "",
        country: "",
        company_email: "",
    });

    const [onSubmit, setSubmit] = useState(false);
    const [states, setStates] = useState([]);
    const [timezones, setTimezones] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [docs, setDocs] = useState();
    const [imageFile, setImageFile] = useState("");

    const id = match.params.id;
    const fileTypes = ["JPG", "PNG", "JPEG"];

    useEffect(() => {
        stateList()
        timezoneList()
        industriesList()
        id && getPageDetails(id)
    }, [])

    const getPageDetails = async (id) => {
        try {

            window.scrollTo(0, 0);
            let resultObj = await axios.get(`${Application.api_url}/api/v2/internal_subs/${id}`);
            if (resultObj.status == 200) {
                let details = resultObj.data.data;
                details.first_name = details.admin && details.admin.first_name;
                details.last_name = details.admin && details.admin.last_name;
                details.phone_number = details.admin && details.admin.phone_number;
                details.company_email = details.email;
                details.email = details.admin && details.admin.email;
                setImageFile(details.profile_picture_url)
                setValues(details)
            }
        } catch (err) {
            console.log("error==>", err);
        }
    };

    const stateList = async () => {
        let list = await axios.get(`${Application.api_url}/api/v2/common/state_list`);
        if (list.data && list.data.success == true) {
            setStates(list.data.data)
        }
    }

    const timezoneList = async () => {
        let list = await axios.get(`${Application.api_url}/api/v2/company_profiles/timezone_list`);
        if (list.data && list.data.success == true) {
            setTimezones(list.data.data)
        }
    }

    const industriesList = async () => {
        let list = await axios.get(`${Application.api_url}/api/v2/common/industries_list`);
        if (list.data && list.data.success == true) {
            setIndustries(list.data.data)
        }
    }

    const handleChange = (name, event) => {
        setValues({ ...values, [name]: event.target.value });
    }

    const saveInfo = async () => {
        try {
            
            setSubmit(true);

            if (!values.name || !values.industry || !values.timezone || !values.first_name || !values.last_name || !values.email || !values.address || !values.city || !values.state) {
                toast.success('Please fill all the required fields')
                return false;
            }

            $('.btn-create').prop('disabled', true).css('background-color', 'grey').css('color', 'white');
            const formData = new FormData();
            formData.append("company[name]", values.name);
            formData.append("company[industry]", values.industry);
            formData.append("company[state]", values.state);
            formData.append("company[city]", values.city);
            formData.append("company[address]", values.address);
            formData.append("company[zipcode]", values.zipcode);
            formData.append("company[country]", values.country);
            values.company_email && formData.append("company[email]", values.company_email);
            formData.append("company[phonenumber]", values.phonenumber);
            formData.append("company[timezone]", values.timezone);
            formData.append("company[company_info]", values.company_info);
            formData.append("company[license_number]", values.license_number);
            formData.append("company[fax]", values.fax);
            formData.append("company[website]", values.website);
            formData.append("company[country]", values.country);
            docs && formData.append("company[logo]", docs);
            formData.append("user[first_name]", values.first_name);
            formData.append("user[last_name]", values.last_name);
            formData.append("user[role_id]", values.role_id);
            formData.append("user[email]", values.email);
            formData.append("user[phone_number]", values.phone_number);

            
            let method = "POST";
            let apiUrl = `${Application.api_url}/api/v2/internal_subs`;

            if (id) {
                method = "PATCH";
                apiUrl = `${Application.api_url}/api/v2/internal_subs/${id}`;

                if (!docs && !imageFile) {
                    formData.append("company[logo]", "")
                }
            }

            await axios({
                method: method,
                url: apiUrl,
                data: formData,
            }).then((result) => {
                if (result.data && result.data.success === true) {
                    toast.success(id ? 'Updated Successfully' : `Created Successfully`)
                    goBack();
                }
            }).catch((error) => {
                error.response.data.message.map((msg) => {
                    toast.error(msg)
                });
            });


        } catch (err) {
            console.log("err===>", err);
        }
    };


    const goBack = () => {
        history.push("/subcontractor/managesubs");
    }

    const handleFileChange = (file) => {
        // var allowedTypes = ['image/jpeg', 'image/png', 'image/jpg']; 
        // if (!allowedTypes.includes(file.type)) {
        //     toast.info('Only JPG, PNG, and PDF files are allowed.');
        //     return false;
        // }

        setDocs(file);
        setImageFile(URL.createObjectURL(file))
    };

    const handleDelete = () => {
        setDocs("");
        setImageFile("")
    };

    return (
        <React.Fragment>
            <div className="msmcontainer dashboard-container">
                <div className="row d-flex">
                    <SideNav />
                    <div className="content-wrap add-internal-subs">
                        <div className="title-card">
                            <div className="title-content">
                                <div>
                                    <img src={ArrowUp} alt="back-arrow" className="back-arrow" onClick={(e) => { goBack() }} />
                                </div>
                                <h4>
                                    <i
                                        style={{ paddingInlineStart: "5px", paddingRight: "5px" }}
                                        className="fa fa-plus"
                                    ></i>
                                    <b>{id ? "Update" : "Add"} Subcontractor</b>
                                </h4>
                            </div>
                            <div>
                                <Button className="btn-cancel" onClick={(e) => { goBack() }}>Cancel</Button>
                                <Button className="btn-create" variant="contained" onClick={(e) => { saveInfo() }}>
                                    {id ? "Update" : "Create Subcontractor & Send information"}
                                </Button>
                            </div>
                        </div>
                        <div className="body-card">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label className="field-lable">
                                                Company Name <text style={{ color: "red" }}>*</text>
                                            </label>
                                            <TextField
                                                fullWidth
                                                required
                                                className="text-field"
                                                id="outlined-required"
                                                value={values.name}
                                                error={!values.name && onSubmit === true ? true : false}
                                                helperText={
                                                    !values.name && onSubmit === true
                                                        ? "Company Name is required"
                                                        : ""
                                                }
                                                onChange={(e) => {
                                                    handleChange("name", e);
                                                }}
                                                name="name"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="field-lable">
                                                Contact Number
                                            </label>
                                            <TextField
                                                fullWidth
                                                className="text-field"
                                                id="outlined-required"
                                                value={values.phonenumber == "null" ? "" : values.phonenumber}
                                                onChange={(e) => {
                                                    handleChange("phonenumber", e);
                                                }}
                                                name="phonenumber"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="field-lable">
                                                Company Email Address
                                            </label>
                                            <TextField
                                                fullWidth
                                                className="text-field"
                                                id="outlined-required"
                                                value={values.company_email == "null" ? "" : values.company_email}
                                                onChange={(e) => {
                                                    handleChange("company_email", e);
                                                }}
                                                name="company_email"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <FormControl
                                                controlId="formControlsSelectMultiple"
                                                required
                                                fullWidth
                                            >

                                                <label className="field-lable">
                                                    Industry <text style={{ color: "red" }}>*</text>
                                                </label>
                                                <Select
                                                    labelId="demo-simple-select-error-label"
                                                    id="outlined-required"
                                                    className="text-field"
                                                    fullWidth
                                                    input={
                                                        <OutlinedInput
                                                            sx={{ fontSize: "14px", borderColor: "red" }}
                                                        />
                                                    }
                                                    value={values.industry}
                                                    onChange={(e) => {
                                                        handleChange("industry", e);
                                                    }}
                                                >
                                                    {industries.map((val) => (
                                                        <MenuItem
                                                            value={val.value}
                                                            style={{ fontSize: 14 }}
                                                            key={val.value}
                                                        >
                                                            {val.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <FormHelperText
                                                className="inputErrorMsg"
                                                error={!values.industry && onSubmit === true ? true : false}
                                            >
                                                {!values.industry && onSubmit === true ? "Industry is required" : ""}
                                            </FormHelperText>
                                        </div>
                                        <div className="col-md-6">
                                            <FormControl
                                                controlId="formControlsSelectMultiple"
                                                required
                                                fullWidth
                                            >
                                                <label className="field-lable">
                                                    Company Time Zone <text style={{ color: "red" }}>*</text>
                                                </label>
                                                <Select
                                                    labelId="demo-simple-select-error-label"
                                                    id="outlined-required"
                                                    className="text-field"
                                                    fullWidth
                                                    input={
                                                        <OutlinedInput
                                                            sx={{ fontSize: "14px", borderColor: "red" }}
                                                        />
                                                    }
                                                    value={values.timezone}
                                                    onChange={(e) => {
                                                        handleChange("timezone", e);
                                                    }}
                                                >
                                                    {timezones.map((val) => (
                                                        <MenuItem
                                                            value={val.value}
                                                            style={{ fontSize: 14 }}
                                                            key={val.value}
                                                        >
                                                            {val.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <FormHelperText
                                                className="inputErrorMsg"
                                                error={!values.timezone && onSubmit === true ? true : false}
                                            >
                                                {!values.timezone && onSubmit === true ? "Company Time Zone is required" : ""}
                                            </FormHelperText>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="field-lable">
                                                License Number
                                            </label>
                                            <TextField
                                                fullWidth
                                                required
                                                className="text-field"
                                                id="outlined-required"
                                                value={values.license_number == "null" ? "" : values.license_number}
                                                onChange={(e) => {
                                                    handleChange("license_number", e);
                                                }}
                                                name="license_number"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="field-lable">
                                                Fax
                                            </label>
                                            <TextField
                                                fullWidth
                                                required
                                                className="text-field"
                                                id="outlined-required"
                                                value={values.fax == "null" ? "" : values.fax}
                                                onChange={(e) => {
                                                    handleChange("fax", e);
                                                }}
                                                name="fax"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="field-lable">
                                                Website
                                            </label>
                                            <TextField
                                                fullWidth
                                                required
                                                className="text-field"
                                                id="outlined-required"
                                                value={values.website == "null" ? "" : values.website}
                                                onChange={(e) => {
                                                    handleChange("website", e);
                                                }}
                                                name="website"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <p>Company Admin User Details</p>
                                    </div>
                                    <div className="col-md-12 user-details">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label className="field-lable">
                                                    First Name <text style={{ color: "red" }}>*</text>
                                                </label>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    className="text-field"
                                                    id="outlined-required"
                                                    value={values.first_name}
                                                    error={!values.first_name && onSubmit === true ? true : false}
                                                    helperText={
                                                        !values.first_name && onSubmit === true
                                                            ? "First Name is required"
                                                            : ""
                                                    }
                                                    onChange={(e) => {
                                                        handleChange("first_name", e);
                                                    }}
                                                    name="first_name"
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label className="field-lable">
                                                    Last Name <text style={{ color: "red" }}>*</text>
                                                </label>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    className="text-field"
                                                    id="outlined-required"
                                                    value={values.last_name}
                                                    error={!values.last_name && onSubmit === true ? true : false}
                                                    helperText={
                                                        !values.last_name && onSubmit === true
                                                            ? "Last Name is required"
                                                            : ""
                                                    }
                                                    onChange={(e) => {
                                                        handleChange("last_name", e);
                                                    }}
                                                    name="last_name"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label className="field-lable">
                                                    Email <text style={{ color: "red" }}>*</text>
                                                </label>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    className="text-field"
                                                    id="outlined-required"
                                                    value={values.email}
                                                    error={!values.email && onSubmit === true ? true : false}
                                                    helperText={
                                                        !values.email && onSubmit === true
                                                            ? "Email is required"
                                                            : ""
                                                    }
                                                    onChange={(e) => {
                                                        handleChange("email", e);
                                                    }}
                                                    name="email"
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label className="field-lable">
                                                    Phone Number
                                                </label>
                                                <TextField
                                                    fullWidth
                                                    className="text-field"
                                                    id="outlined-required"
                                                    value={values.phone_number == "null" ? "" : values.phone_number}
                                                    onChange={(e) => {
                                                        handleChange("phone_number", e);
                                                    }}
                                                    name="phone_number"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <p>Location</p>
                                    </div>
                                    <div className="col-md-12 user-details">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label className="field-lable">
                                                    Address <text style={{ color: "red" }}>*</text>
                                                </label>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    className="text-field"
                                                    id="outlined-required"
                                                    value={values.address}
                                                    error={!values.address && onSubmit === true ? true : false}
                                                    helperText={
                                                        !values.address && onSubmit === true
                                                            ? "Address is required"
                                                            : ""
                                                    }
                                                    onChange={(e) => {
                                                        handleChange("address", e);
                                                    }}
                                                    name="address"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label className="field-lable">
                                                    City <text style={{ color: "red" }}>*</text>
                                                </label>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    className="text-field"
                                                    id="outlined-required"
                                                    value={values.city}
                                                    error={!values.city && onSubmit === true ? true : false}
                                                    helperText={
                                                        !values.city && onSubmit === true
                                                            ? "City is required"
                                                            : ""
                                                    }
                                                    onChange={(e) => {
                                                        handleChange("city", e);
                                                    }}
                                                    name="city"
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <FormControl
                                                    controlId="formControlsSelectMultiple"
                                                    required
                                                    fullWidth
                                                >
                                                    <label className="field-lable">
                                                        Province / State <text style={{ color: "red" }}>*</text>
                                                    </label>
                                                    <Select
                                                        labelId="demo-simple-select-error-label"
                                                        id="outlined-required"
                                                        className="outlined-required"
                                                        fullWidth
                                                        input={
                                                            <OutlinedInput
                                                                sx={{ fontSize: "14px", borderColor: "red" }}
                                                            />
                                                        }
                                                        value={values.state}
                                                        onChange={(e) => {
                                                            handleChange("state", e);
                                                        }}
                                                    >
                                                        {states.map((val) => (
                                                            <MenuItem
                                                                value={val.value}
                                                                style={{ fontSize: 14 }}
                                                                key={val.value}
                                                            >
                                                                {val.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <FormHelperText
                                                    className="inputErrorMsg"
                                                    error={!values.state && onSubmit === true ? true : false}
                                                >
                                                    {!values.state && onSubmit === true ? "State is required" : ""}
                                                </FormHelperText>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label className="field-lable">
                                                    Country
                                                </label>
                                                <TextField
                                                    fullWidth
                                                    className="text-field"
                                                    id="outlined-required"
                                                    value={values.country == "null" ? "" : values.country}
                                                    onChange={(e) => {
                                                        handleChange("country", e);
                                                    }}
                                                    name="country"
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label className="field-lable">
                                                    Zip Code
                                                </label>
                                                <TextField
                                                    className="text-field"
                                                    id="outlined-required"
                                                    value={values.zipcode == "null" ? "" : values.zipcode}
                                                    onChange={(e) => {
                                                        handleChange("zipcode", e);
                                                    }}
                                                    name="zipcode"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div>
                                        <div className="create-information-text-area">
                                            <label className="field-lable">Company Info</label>
                                            <textarea
                                                className="create-asset-textarea"
                                                aria-label="empty textarea"
                                                name="company_info"
                                                value={values.company_info == "null" ? "" : values.company_info}
                                                onChange={(e) => {
                                                    handleChange("company_info", e);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12 logo-upload">
                                        <label className="field-lable">Logo</label>
                                        <FileUploader handleChange={handleFileChange} name="file" label="Upload or drop files here" className="drop-box" types={fileTypes}/>
                                        {imageFile &&
                                            <div className="attachment-grid">
                                                <div className="file-item">
                                                    <img
                                                        src={imageFile}
                                                        className="file-type"
                                                        alt="file"
                                                        style={{ width: '80px', height: 'auto' }}
                                                    />
                                                    <button
                                                        className="delete-button"
                                                        onClick={() => handleDelete()}
                                                        style={{
                                                            position: 'absolute',
                                                            top: '0',
                                                            right: '0',
                                                            transform: 'translate(-145%, 5%)',
                                                            backgroundColor: 'transparent',
                                                            border: 'none',
                                                            cursor: 'pointer',
                                                            padding: '0'
                                                        }}
                                                    >
                                                        <img
                                                            src="/packs/media/assets/fonts/Delete-62b3f74c182772359479dc3681fbd490.svg"
                                                            alt="Delete"
                                                            style={{
                                                                width: '24px',
                                                                height: '24px'
                                                            }}
                                                        />
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
};

export default withRouter(CreateCompany);
