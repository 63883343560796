import React, { useEffect, useCallback, useState } from "react";
import update from "immutability-helper";
import { Card } from "./card.js";
import AnalyticsData from "./AnalyticsData.js";
import ChartData from "./ChartData.js";
import AuditSchedulesOverview from "./AuditsSchedulesOverview.js";
import FormsChart from "./FormsChart.js";
import FormsGroupByAnalytics from "./FormsGroupByAnalytics.js";
import AuditDetails from "./AuditDetails.js";

export default function DNDLayout({query, onFilterSelect}) {
  {
    let componentList = {
      scheduledForms: <AuditSchedulesOverview />,
      formsAuditorsCounts: <AnalyticsData query={query} />,
      fromTypes: <ChartData query={query} />,
      formsPerDay: <FormsChart query={query} onFilterSelect={onFilterSelect}/>,
      formsGroups: <FormsGroupByAnalytics />,
      latestFormDetails: <AuditDetails query={query} />,
    }

    let getUser = localStorage.getItem("user");
    getUser = JSON.parse(getUser)

    const [cards, setCards] = useState(getUser.dashboard_positions && getUser.dashboard_positions.columns ? getUser.dashboard_positions.columns : [
      {
        id: 1,
        header: "Scheduled Forms",
        name: "scheduledForms"
      },
      {
        id: 2,
        header: "Forms & Auditors Counts",
        name: "formsAuditorsCounts"
      },
      {
        id: 3,
        header: "Current Actions Status & Form Types",
        name: "fromTypes"
      },
      {
        id: 4,
        header: "Forms Per Day",
        name: "formsPerDay"
      },
      {
        id: 5,
        header: "Forms Group By Data",
        name: "formsGroups"
      },
      {
        id: 6,
        header: "Latest Form Details",
        name: "latestFormDetails"
      }
    ]);

    const [postion, setPostion] = useState(getUser.dashboard_positions && getUser.dashboard_positions.titles ? getUser.dashboard_positions.titles :{
      scheduledForms: true,
      formsAuditorsCounts: true,
      fromTypes: false,
      formsPerDay: false,
      formsGroups: false,
      latestFormDetails: false,
    })

    const moveCard = useCallback((dragIndex, hoverIndex) => {
      setCards((prevCards) =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex]]
          ]
        })
      );
    }, []);

    const changePostion = (objName) => {
      setPostion({ ...postion, ...{ [objName]: !postion[objName] } })
    }

    useEffect(() => {
      let details = {
        titles: postion,
        columns: cards,
        banner: getUser.dashboard_positions && getUser.dashboard_positions.banner ? getUser.dashboard_positions.banner : false
      }

      let userDetails = {
        user: {
          dashboard_positions: details,
        },
    };
      $.ajax({
          type: "PATCH",
          url: "/entity_management/update_dashboard_positions",
          dataType: "json",
          data: userDetails,
          error: function (error) {
            console.log("error")
          },
          success: function (res) {
              console.log("updated successfully")
          },
      });

      localStorage.setItem("user", JSON.stringify({ ...getUser, ...{ dashboard_positions: details } }))
    }, [cards, postion])

    return (
      <>
        <div>
          {cards.map((card, index) => (
            <>
              <div className="dashboard-header-section" onClick={() => changePostion(card.name)} style={{cursor: "pointer"}}>
                <h2 className="dashboard-col-header">{card.header}</h2>
                <span className={postion[card.name] ? "caret" : "caret up-arrow"} ></span>
              </div>
              {postion[card.name] &&
                <Card
                  key={card.id}
                  index={index}
                  id={card.id}
                  text={componentList[card.name]}
                  moveCard={moveCard}
                />
              }
            </>
          ))}
        </div>
      </>
    );
  }
};
