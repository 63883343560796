import React from 'react';
import HeroSection from './CommonComponents/HeroSection';
import FeaturesSection from './CommonComponents/FeaturesSection';
import ParallaxSection from '../../pages/HomePage/Components/ParallaxSection';
import HomepageDemoSection from '../../pages/HomePage/Components/HomepageDemoSection';

export default function OnboardingOrientation() {
    return (
        <>
            <HeroSection
                heroText={"Onboarding Orientation"}
                subText={"Streamline the onboarding process for new employees with SafetyLoop's orientation tools."}
            />
            <FeaturesSection
                headerText={"Seamless Employee Onboarding"}
                subText={"Guide new hires through a structured onboarding process to ensure compliance and safety."}
                featureHighlights={[
                    "Create onboarding checklists and schedules.",
                    "Track onboarding progress for new employees.",
                    "Ensure compliance with orientation requirements.",
                    "Generate onboarding completion reports."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for Employee Onboarding section here
            />
            <FeaturesSection
                headerText={"Custom Orientation Programs"}
                subText={"Tailor orientation programs to specific roles and departments."}
                featureHighlights={[
                    "Create role-specific orientation modules.",
                    "Assign orientation programs based on job roles.",
                    "Monitor progress and completion for each program."
                ]}
                // Placeholder: Add image for Orientation Programs section here
            />
            <FeaturesSection
                headerText={"Orientation Compliance"}
                subText={"Track and report on compliance with orientation and safety training requirements."}
                featureHighlights={[
                    "Generate compliance reports for onboarding.",
                    "Set up alerts for incomplete orientation tasks.",
                    "Maintain a centralized repository for orientation records."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for Orientation Compliance section here
            />
            <div className="Home-Page sl-home-wrapper" style={{ paddingTop: 0 }}>
                <ParallaxSection />
            </div>
            <div className="features-homepage-wrapper">
                <HomepageDemoSection mergeTop={false} />
            </div>
        </>
    );
}
