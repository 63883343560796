import { FormControl, Select, MenuItem, Pagination } from "@mui/material";
import React, { useEffect, useReducer, useRef, useState} from "react";
import SearchIcon from "../../../assets/images/searchbar.svg";
import Settings from "../../../assets/images/Settings-new.svg";
import Unstar from "../../../assets/fonts/unstar.svg";
import Stared from "../../../assets/fonts/stared.svg";
import InventoryLevelOneIcon from "../../../assets/fonts/InventoryLevelOne.svg";
import InventoryLevelThreeIcon from "../../../assets/fonts/InventoryLevelThree.svg";
import InventoryLevelSixIcon from "../../../assets/fonts/InventoryLevelSix.svg";
import Sorting from "../../../assets/images/Filter.png";
import DefaultInventory from "../../../assets/fonts/DefaultInventory.svg";
import axios from "../../../config/axiosConfig";
import Application from "../../../config/ApplicationWrapper";
import Loader from "../../../components/NewLoader";
import ViewQrCode from "./popup/ViewQrCode";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";

const initialState = {
  name_cont: "",
  inventory_type_name_cont: "",
  status_eq: "",
  supplier_name_cont: "",
  serial_no_cont: "",
  low_stock_threshold_eq: "",
  unit_price_eq: "",
  inventory_location_name_cont: "",
  expiration_date_eq: "",
  notification_alert_eq: "",
  last_order_date_eq: "",
  current_qty_eq: "",
};

const initialSettings = {
  name: true,
  inventory_type: true,
  status: true,
  supplier_id: true,
  serial_no: true,
  current_qty: true,
  low_stock_threshold: true,
  unit_price: true,
  inventory_location: true,
  last_order_date: true,
  quantity_unit: true,
  expiration_date: true,
  notes: true,
  reorder_status: true,
  notification_alert: true,
  notification_enabled: false,
  cost_per_day: true,
  rental_end_date: true,
  rental_start_date: true,
  total_inventory: true,
};

const searchReducer = (state, action) => {
  switch (action.type) {
    case "HANDLE INPUT CHANGE":
      return { ...state, [action.field]: action.payload };
    case "HANDLE INPUT REMOVE":
      return { ...state, [action.field]: "" };
    case "UPDATE":
      return { ...state, ...action.payload };
    case "RESET":
      return initialState;
    default:
      return state;
  }
};

const settingsReducer = (state, action) => {
  switch (action.type) {
    case "HANDLE INPUT CHANGE":
      return { ...state, [action.field]: action.payload };
    case "UPDATE":
      return { ...state, ...action.payload };
    case "RESET":
      return initialState;
    default:
      return state;
  }
};



const Active = ({ history, refresh, searching, showSettings }) => {
  const mounted = useRef(false);
  const [search, dispatch] = useReducer(searchReducer, initialState);
  const [settings, dispatchSettings] = useReducer(
    settingsReducer,
    initialSettings
  );
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(30);
  const [pageCount, setPageCount] = useState(0);
  const [pageDetails, setPageDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState([]);
  const [sortedBy, setSortedBy] = useState();
  const [sort, setSort] = useState("desc");
  const [showColumnSettings, setShowColumnSettings] = useState(false);
  const [showQr, setShowQr] = useState(false);
  const [showDetails, setShowDetails] = useState();

  useEffect(() => {
    getPageDetails();
    let inventory_settings = JSON.parse(
      localStorage.getItem("inventory_settings")
    );
    if (inventory_settings) {
      dispatchSettings({
        type: "UPDATE",
        payload: inventory_settings,
      });
    }
  }, [page, limit, refresh]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (mounted.current) {
        getPageDetails();
        searching(search);
      }
      mounted.current = true;
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
  }, [search, sortedBy, sort]);

  const getPageDetails = async () => {
    try {
      setLoading(true);
      await axios
        .get(`${Application.api_url}/api/v2/inventories`, {
          params: {
            page: page,
            limit: limit,
            archived_eq: false,
            sort: sortedBy + " " + sort,
            ...search,
          },
        })
        .then((res) => {
          let details = res.data;
          setPageDetails(details ? details.data : []);
          setPageCount(
            details && details.metadata ? details.metadata.pages : 0
          );
          setLoading(false);
        })
        .catch((err) => console.log("err=>", err));
    } catch (err) {
      console.log("err===>", err);
    }
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleTextChanges = (e) => {
    dispatch({
      type: "HANDLE INPUT CHANGE",
      field: e.target.name,
      payload: e.target.value,
    });
  };

  const handleTextRemove = (name) => {
    dispatch({
      type: "HANDLE INPUT REMOVE",
      field: name,
      payload: "",
    });
  };

  const handleSettingsUpdate = (e) => {
    dispatchSettings({
      type: "HANDLE INPUT CHANGE",
      field: e.target.name,
      payload: e.target.checked,
    });
    settings[e.target.name] = e.target.checked;
    let newSettings = settings;
    localStorage.setItem("inventory_settings", JSON.stringify(newSettings));
  };

  const close = () => { 
    setShowQr(false); 
    setShowDetails("")
  }; 

  const archive = async (data) => {
    try {
      await axios
        .patch(
          `${Application.api_url}/api/v2/inventories/${data.identifier}/archive`
        )
        .then((result) => {
          if (result.data && result.data.success === true) {
            toast.success("Archived Successfully");
            getPageDetails();
          }
        })
        .catch((err) => console.log("err=>", err));
    } catch (err) {
      console.log("err==>", err);
    }
  };

  const goTo = async (path) => {
    history.push(path);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const BulkArchive = async () => {
    try {
      await axios
        .patch(`${Application.api_url}/api/v2/inventories/bulk_archive`, {
          selected_ids: selected.join(","),
        })
        .then((result) => {
          if (result.data && result.data.success === true) {
            toast.success("Archived Successfully");
            getPageDetails();
            setSelected([]);
          }
        })
        .catch((err) => console.log("err=>", err));
    } catch (err) {
      console.log("err==>", err);
    }
  };

  const handleStar = async (identifier, star) => {
    try {
      await axios
        .patch(
          `${Application.api_url}/api/v2/inventories/${identifier}/${
            star ? "star" : "unstar"
          }`
        )
        .then((result) => {
          if (result.data && result.data.success === true) {
            toast.success(
              `${
                star
                  ? "Successfully added to favorites"
                  : "Removed from favorites Successfully"
              }`
            );
            getPageDetails();
          }
        })
        .catch((err) => console.log("err=>", err));
    } catch (err) {
      console.log("err==>", err);
    }
  };

  const handleSort = (name) => {
    if (sortedBy == name) {
      setSort(sort == "asc" ? "desc" : "asc");
    } else {
      setSortedBy(name);
      setSort("asc");
    }
  };

  return (
    <div className="inventory-type">
      <div className="fields-setting">
        <div className="asset-active-filters">
          Active Filters :
          <span className="active-filters">
            <span>Inventory Name :</span>
            <span className="selected">
              {search.name_cont ? search.name_cont : "All"}
            </span>
            <span
              className={
                search.name_cont ? "clr-selected" : "clr-selected hidden"
              }
              onClick={(e) => {
                handleTextRemove("name_cont");
              }}
            >
              X
            </span>
          </span>
          <span className="active-filters">
            <span>Inventory Type :</span>
            <span className="selected">
              {search.inventory_type_name_cont
                ? search.inventory_type_name_cont
                : "All"}
            </span>
            <span
              className={
                search.inventory_type_name_cont
                  ? "clr-selected"
                  : "clr-selected hidden"
              }
              onClick={(e) => {
                handleTextRemove("inventory_type_name_cont");
              }}
            >
              X
            </span>
          </span>
          <span className="active-filters">
            <span>Rented/Owned :</span>
            <span className="selected">
              {search.status_eq ? search.status_eq : "All"}
            </span>
            <span
              className={
                search.status_eq ? "clr-selected" : "clr-selected hidden"
              }
              onClick={(e) => {
                handleTextRemove("status_eq");
              }}
            >
              X
            </span>
          </span>
          <span className="active-filters">
            <span>Supplier :</span>
            <span className="selected">
              {search.supplier_name_cont ? search.supplier_name_cont : "All"}
            </span>
            <span
              className={
                search.supplier_name_cont
                  ? "clr-selected"
                  : "clr-selected hidden"
              }
              onClick={(e) => {
                handleTextRemove("supplier_name_cont");
              }}
            >
              X
            </span>
          </span>
          <span className="active-filters">
            <span>Barcode/Serial No :</span>
            <span className="selected">
              {search.serial_no_cont ? search.serial_no_cont : "All"}
            </span>
            <span
              className={
                search.serial_no_cont ? "clr-selected" : "clr-selected hidden"
              }
              onClick={(e) => {
                handleTextRemove("serial_no_cont");
              }}
            >
              X
            </span>
          </span>
          <span className="active-filters">
            <span>Total Quantity :</span>
            <span className="selected">
              {search.current_qty_eq ? search.current_qty_eq : "All"}
            </span>
            <span
              className={
                search.current_qty_eq ? "clr-selected" : "clr-selected hidden"
              }
              onClick={(e) => {
                handleTextRemove("current_qty_eq");
              }}
            >
              X
            </span>
          </span>
          <span className="active-filters">
            <span>Low Stock Threshold :</span>
            <span className="selected">
              {search.low_stock_threshold_eq
                ? search.low_stock_threshold_eq
                : "All"}
            </span>
            <span
              className={
                search.low_stock_threshold_eq
                  ? "clr-selected"
                  : "clr-selected hidden"
              }
              onClick={(e) => {
                handleTextRemove("low_stock_threshold_eq");
              }}
            >
              X
            </span>
          </span>
          <span className="active-filters">
            <span>Unit Price :</span>
            <span className="selected">
              {search.unit_price_eq ? search.unit_price_eq : "All"}
            </span>
            <span
              className={
                search.unit_price_eq ? "clr-selected" : "clr-selected hidden"
              }
              onClick={(e) => {
                handleTextRemove("unit_price_eq");
              }}
            >
              X
            </span>
          </span>
          <span className="active-filters">
            <span>Location :</span>
            <span className="selected">
              {search.inventory_location_id_eq
                ? search.inventory_location_id_eq
                : "All"}
            </span>
            <span
              className={
                search.inventory_location_id_eq
                  ? "clr-selected"
                  : "clr-selected hidden"
              }
              onClick={(e) => {
                handleTextRemove("name_cont");
              }}
            >
              X
            </span>
          </span>
          <span className="active-filters">
            <span>Last Order Date :</span>
            <span className="selected">
              {search.last_order_date_eq ? search.last_order_date_eq : "All"}
            </span>
            <span
              className={
                search.last_order_date_eq
                  ? "clr-selected"
                  : "clr-selected hidden"
              }
              onClick={(e) => {
                handleTextRemove("last_order_date_eq");
              }}
            >
              X
            </span>
          </span>
          <span className="active-filters">
            <span>Expiration Date :</span>
            <span className="selected">
              {search.expiration_date_eq ? search.expiration_date_eq : "All"}
            </span>
            <span
              className={
                search.expiration_date_eq
                  ? "clr-selected"
                  : "clr-selected hidden"
              }
              onClick={(e) => {
                handleTextRemove("expiration_date_eq");
              }}
            >
              X
            </span>
          </span>
          <span className="active-filters">
            <span>Notification Alert :</span>
            <span className="selected">
              {search.notification_alert_eq
                ? search.notification_alert_eq
                : "All"}
            </span>
            <span
              className={
                search.notification_alert_eq
                  ? "clr-selected"
                  : "clr-selected hidden"
              }
              onClick={(e) => {
                handleTextRemove("notification_alert_eq");
              }}
            >
              X
            </span>
          </span>
          <span
            className="active-filters-clear"
            onClick={(e) => {
              dispatch({
                type: "RESET",
              });
            }}
          >
            Clear All
          </span>
        </div>
        <div className="config-settings">
          <div
            onClick={() => {
              setShowColumnSettings(!showColumnSettings);
              showSettings(!showColumnSettings);
            }}
          >
            <img src={Settings} alt="icon" />
            <span>Column Settings</span>
          </div>
          <div
            className={`${
              showColumnSettings
                ? "col-settings-list show"
                : "col-settings-list"
            }`}
          >
            <div className="columns">
              <input
                type="checkbox"
                id="inventory_type"
                name="inventory_type"
                className="check-box"
                checked={settings.inventory_type}
                onChange={(e) => {
                  handleSettingsUpdate(e);
                }}
              />
              <span>Inventory Type</span>
            </div>
            <div className="columns">
              <input
                type="checkbox"
                id="status"
                name="status"
                className="check-box"
                checked={settings.status}
                onChange={(e) => {
                  handleSettingsUpdate(e);
                }}
              />
              <span>Rented/Owned</span>
            </div>
            <div className="columns">
              <input
                type="checkbox"
                id="supplier_id"
                name="supplier_id"
                className="check-box"
                checked={settings.supplier_id}
                onChange={(e) => {
                  handleSettingsUpdate(e);
                }}
              />
              <span>Supplier</span>
            </div>
            <div className="columns">
              <input
                type="checkbox"
                id="serial_no"
                name="serial_no"
                className="check-box"
                checked={settings.serial_no}
                onChange={(e) => {
                  handleSettingsUpdate(e);
                }}
              />
              <span>Barcode/Serial No</span>
            </div>
            <div className="columns">
              <input
                type="checkbox"
                id="current_qty"
                name="current_qty"
                className="check-box"
                checked={settings.current_qty}
                onChange={(e) => {
                  handleSettingsUpdate(e);
                }}
              />
              <span>Total Quantity</span>
            </div>
            <div className="columns">
              <input
                type="checkbox"
                id="low_stock_threshold"
                name="low_stock_threshold"
                className="check-box"
                checked={settings.low_stock_threshold}
                onChange={(e) => {
                  handleSettingsUpdate(e);
                }}
              />
              <span>Low Stock Threshold</span>
            </div>
            <div className="columns">
              <input
                type="checkbox"
                id="unit_price"
                name="unit_price"
                className="check-box"
                checked={settings.unit_price}
                onChange={(e) => {
                  handleSettingsUpdate(e);
                }}
              />
              <span>Unit Price</span>
            </div>
            <div className="columns">
              <input
                type="checkbox"
                id="total_inventory"
                name="total_inventory"
                className="check-box"
                checked={settings.total_inventory}
                onChange={(e) => {
                  handleSettingsUpdate(e);
                }}
              />
              <span>Total Inventory Value</span>
            </div>
            <div className="columns">
              <input
                type="checkbox"
                id="inventory_location"
                name="inventory_location"
                className="check-box"
                checked={settings.inventory_location}
                onChange={(e) => {
                  handleSettingsUpdate(e);
                }}
              />
              <span>Location</span>
            </div>
            <div className="columns">
              <input
                type="checkbox"
                id="last_order_date"
                name="last_order_date"
                className="check-box"
                checked={settings.last_order_date}
                onChange={(e) => {
                  handleSettingsUpdate(e);
                }}
              />
              <span>Last Order Date</span>
            </div>
            <div className="columns">
              <input
                type="checkbox"
                id="expiration_date"
                name="expiration_date"
                className="check-box"
                checked={settings.expiration_date}
                onChange={(e) => {
                  handleSettingsUpdate(e);
                }}
              />
              <span>Expiration Date</span>
            </div>
            <div className="columns">
              <input
                type="checkbox"
                id="notification_alert"
                name="notification_alert"
                className="check-box"
                checked={settings.notification_alert}
                onChange={(e) => {
                  handleSettingsUpdate(e);
                }}
              />
              <span>Notification Alert</span>
            </div>
            <div className="columns">
              <input
                type="checkbox"
                id="reorder_status"
                name="reorder_status"
                className="check-box"
                checked={settings.reorder_status}
                onChange={(e) => {
                  handleSettingsUpdate(e);
                }}
              />
              <span>Reorder Status</span>
            </div>
          </div>
        </div>
      </div>
      {selected.length > 0 && (
        <div className="multi-select">
          <div className="selected-users">
            <span>Selected Items :</span>
            <span className="count">{selected.length}</span>
          </div>
          <div className="bulk-archive" onClick={() => BulkArchive()}>
            <span>Archive</span>
          </div>
        </div>
      )}
      <div className="page-content">
        <table>
          <thead>
            <tr>
              <th className="divider star-col"></th>
              <th className="divider inventory-name-col">
                <span className="col-name sorting-col">
                  Inventory Name
                  <img
                    src={Sorting}
                    alt=""
                    className={`${
                      sortedBy == "name" && sort == "asc" ? "order-desc" : ""
                    }`}
                    onClick={() => {
                      handleSort("name");
                    }}
                  />
                </span>
                {pageDetails.length > 0 && (
                  <div className="search-col inventory-name">
                    <input
                      type="text"
                      name="name_cont"
                      id="name_cont"
                      className="search-btn"
                      value={search.name_cont}
                      placeholder="Search"
                      onChange={(e) => {
                        handleTextChanges(e);
                      }}
                    />
                    <img
                      src={SearchIcon}
                      alt="Search Icon"
                      className="search-icon"
                    />
                  </div>
                )}
              </th>
              {settings.inventory_type && (
                <th className="divider">
                  <span className="col-name sorting-col">
                    Inventory Type
                    <img
                      src={Sorting}
                      alt=""
                      className={`${
                        sortedBy == "inventory_type_name" && sort == "asc"
                          ? "order-desc"
                          : ""
                      }`}
                      onClick={() => {
                        handleSort("inventory_type_name");
                      }}
                    />
                  </span>
                  {pageDetails.length > 0 && (
                    <div className="search-col">
                      <input
                        type="text"
                        name="inventory_type_name_cont"
                        id="inventory_type_name_cont"
                        className="search-btn"
                        value={search.inventory_type_name_cont}
                        placeholder="Search"
                        onChange={(e) => {
                          handleTextChanges(e);
                        }}
                      />
                      <img
                        src={SearchIcon}
                        alt="Search Icon"
                        className="search-icon"
                      />
                    </div>
                  )}
                </th>
              )}
              {settings.status && (
                <th className="divider">
                  <span className="col-name sorting-col">
                    Rented/Owned
                    <img
                      src={Sorting}
                      alt=""
                      className={`${
                        sortedBy == "status" && sort == "asc"
                          ? "order-desc"
                          : ""
                      }`}
                      onClick={() => {
                        handleSort("status");
                      }}
                    />
                  </span>
                  {pageDetails.length > 0 && (
                    <div className="search-col">
                      <select
                        type="date"
                        name="status_eq"
                        id="status_eq"
                        value={search.status_eq}
                        className="search-btn"
                        placeholder="All"
                        onChange={(e) => {
                          handleTextChanges(e);
                        }}
                      >
                        <option value={""}>All</option>
                        <option value={"rented"}>Rented</option>
                        <option value={"owned"}>Owned</option>
                      </select>
                    </div>
                  )}
                </th>
              )}
              {settings.supplier_id && (
                <th className="divider">
                  <span className="col-name sorting-col">
                    Supplier
                    <img
                      src={Sorting}
                      alt=""
                      className={`${
                        sortedBy == "supplier_name" && sort == "asc"
                          ? "order-desc"
                          : ""
                      }`}
                      onClick={() => {
                        handleSort("supplier_name");
                      }}
                    />
                  </span>
                  {pageDetails.length > 0 && (
                    <div className="search-col">
                      <input
                        type="text"
                        name="supplier_name_cont"
                        id="supplier_name_cont"
                        className="search-btn"
                        value={search.supplier_name_cont}
                        placeholder="Search"
                        onChange={(e) => {
                          handleTextChanges(e);
                        }}
                      />
                      <img
                        src={SearchIcon}
                        alt="Search Icon"
                        className="search-icon"
                      />
                    </div>
                  )}
                </th>
              )}
              {settings.serial_no && (
                <th className="divider">
                  <span className="col-name sorting-col">
                    Barcode/Serial No
                    <img
                      src={Sorting}
                      alt=""
                      className={`${
                        sortedBy == "serial_no" && sort == "asc"
                          ? "order-desc"
                          : ""
                      }`}
                      onClick={() => {
                        handleSort("serial_no");
                      }}
                    />
                  </span>
                  {pageDetails.length > 0 && (
                    <div className="search-col">
                      <input
                        type="text"
                        name="serial_no_cont"
                        id="serial_no_cont"
                        className="search-btn"
                        value={search.serial_no_cont}
                        placeholder="Search"
                        onChange={(e) => {
                          handleTextChanges(e);
                        }}
                      />
                      <img
                        src={SearchIcon}
                        alt="Search Icon"
                        className="search-icon"
                      />
                    </div>
                  )}
                </th>
              )}
              {settings.current_qty && (
                <th className="divider">
                  <span className="col-name sorting-col">
                    Total Quantity
                    <img
                      src={Sorting}
                      alt=""
                      className={`${
                        sortedBy == "current_qty" && sort == "asc"
                          ? "order-desc"
                          : ""
                      }`}
                      onClick={() => {
                        handleSort("current_qty");
                      }}
                    />
                  </span>
                  {pageDetails.length > 0 && (
                    <div className="search-col">
                      <input
                        type="text"
                        name="current_qty_eq"
                        id="current_qty_eq"
                        className="search-btn"
                        value={search.current_qty_eq}
                        placeholder="Search"
                        onChange={(e) => {
                          handleTextChanges(e);
                        }}
                      />
                      <img
                        src={SearchIcon}
                        alt="Search Icon"
                        className="search-icon"
                      />
                    </div>
                  )}
                </th>
              )}
              {settings.low_stock_threshold && (
                <th className="divider">
                  <span className="col-name sorting-col">
                    Low Stock Threshold
                    <img
                      src={Sorting}
                      alt=""
                      className={`${
                        sortedBy == "low_stock_threshold" && sort == "asc"
                          ? "order-desc"
                          : ""
                      }`}
                      onClick={() => {
                        handleSort("low_stock_threshold");
                      }}
                    />
                  </span>
                  {pageDetails.length > 0 && (
                    <div className="search-col">
                      <input
                        type="text"
                        name="low_stock_threshold_eq"
                        id="low_stock_threshold_eq"
                        className="search-btn"
                        value={search.low_stock_threshold_eq}
                        placeholder="Search"
                        onChange={(e) => {
                          handleTextChanges(e);
                        }}
                      />
                      <img
                        src={SearchIcon}
                        alt="Search Icon"
                        className="search-icon"
                      />
                    </div>
                  )}
                </th>
              )}
              {settings.unit_price && (
                <th className="divider">
                  <span className="col-name sorting-col">
                    Unit Price
                    <img
                      src={Sorting}
                      alt=""
                      className={`${
                        sortedBy == "unit_price" && sort == "asc"
                          ? "order-desc"
                          : ""
                      }`}
                      onClick={() => {
                        handleSort("unit_price");
                      }}
                    />
                  </span>
                  {pageDetails.length > 0 && (
                    <div className="search-col">
                      <input
                        type="text"
                        name="unit_price_eq"
                        id="unit_price_eq"
                        className="search-btn"
                        value={search.unit_price_eq}
                        placeholder="Search"
                        onChange={(e) => {
                          handleTextChanges(e);
                        }}
                      />
                      <img
                        src={SearchIcon}
                        alt="Search Icon"
                        className="search-icon"
                      />
                    </div>
                  )}
                </th>
              )}
              {settings.total_inventory && (
                <th className="divider">
                  <span className="col-name sorting-col">
                    Total Inventory Value
                    <img
                      src={Sorting}
                      alt=""
                      className={`${
                        sortedBy == "inventory_location_id" && sort == "asc"
                          ? "order-desc"
                          : ""
                      }`}
                      onClick={() => {
                        handleSort("inventory_location_id");
                      }}
                    />
                  </span>
                  {pageDetails.length > 0 && (
                    <div className="search-col">
                      <input
                        type="text"
                        name="inventory_location_id_eq"
                        id="inventory_location_id_eq"
                        className="search-btn"
                        value={search.inventory_location_id_eq}
                        placeholder="Search"
                        onChange={(e) => {
                          handleTextChanges(e);
                        }}
                      />
                      <img
                        src={SearchIcon}
                        alt="Search Icon"
                        className="search-icon"
                      />
                    </div>
                  )}
                </th>
              )}
              {settings.inventory_location && (
                <th className="divider">
                  <span className="col-name sorting-col">
                    Location
                    <img
                      src={Sorting}
                      alt=""
                      className={`${
                        sortedBy == "inventory_location_name" && sort == "asc"
                          ? "order-desc"
                          : ""
                      }`}
                      onClick={() => {
                        handleSort("inventory_location_name");
                      }}
                    />
                  </span>
                  {pageDetails.length > 0 && (
                    <div className="search-col">
                      <input
                        type="text"
                        name="inventory_location_name_cont"
                        id="inventory_location_name_cont"
                        className="search-btn"
                        value={search.inventory_location_name_cont}
                        placeholder="Search"
                        onChange={(e) => {
                          handleTextChanges(e);
                        }}
                      />
                      <img
                        src={SearchIcon}
                        alt="Search Icon"
                        className="search-icon"
                      />
                    </div>
                  )}
                </th>
              )}
              {settings.last_order_date && (
                <th className="divider">
                  <span className="col-name sorting-col">
                    Last Order Date
                    <img
                      src={Sorting}
                      alt=""
                      className={`${
                        sortedBy == "last_order_date" && sort == "asc"
                          ? "order-desc"
                          : ""
                      }`}
                      onClick={() => {
                        handleSort("last_order_date");
                      }}
                    />
                  </span>
                  {pageDetails.length > 0 && (
                    <div className="search-col">
                      <input
                        type="date"
                        name="last_order_date_eq"
                        id="last_order_date_eq"
                        className="search-btn"
                        value={search.last_order_date_eq}
                        placeholder="Search"
                        onChange={(e) => {
                          handleTextChanges(e);
                        }}
                      />
                    </div>
                  )}
                </th>
              )}
              {settings.expiration_date && (
                <th className="divider">
                  <span className="col-name sorting-col">
                    Expiration Date
                    <img
                      src={Sorting}
                      alt=""
                      className={`${
                        sortedBy == "expiration_date" && sort == "asc"
                          ? "order-desc"
                          : ""
                      }`}
                      onClick={() => {
                        handleSort("expiration_date");
                      }}
                    />
                  </span>
                  {pageDetails.length > 0 && (
                    <div className="search-col">
                      <input
                        type="date"
                        name="expiration_date_eq"
                        id="expiration_date_eq"
                        className="search-btn"
                        value={search.expiration_date_eq}
                        placeholder="Search"
                        onChange={(e) => {
                          handleTextChanges(e);
                        }}
                      />
                    </div>
                  )}
                </th>
              )}
              {settings.notification_alert && (
                <th className="divider">
                  <span className="col-name sorting-col">
                    Notification Alert
                    <img
                      src={Sorting}
                      alt=""
                      className={`${
                        sortedBy == "notification_alert" && sort == "asc"
                          ? "order-desc"
                          : ""
                      }`}
                      onClick={() => {
                        handleSort("notification_alert");
                      }}
                    />
                  </span>
                  {pageDetails.length > 0 && (
                    <div className="search-col">
                      <select
                        type="date"
                        name="notification_alert_eq"
                        id="notification_alert_eq"
                        className="search-btn"
                        placeholder="All"
                        value={search.notification_alert_eq}
                        onChange={(e) => {
                          handleTextChanges(e);
                        }}
                      >
                        <option value="">All</option>
                        <option value="instantly">Instantly</option>
                        <option value="daily">Daily</option>
                        <option value="weekly">Weekly</option>
                      </select>
                    </div>
                  )}
                </th>
              )}
              {settings.reorder_status && (
                <th>
                  <span className="col-name">Reorder Status</span>
                </th>
              )}
              <th className="action-sticky-col">Action</th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              pageDetails.length > 0 &&
              pageDetails.map((val, index) => {
                const isItemSelected = isSelected(val.identifier);
                return (
                  <tr >
                    <td className="sticky-col" >
                      <div className="star-unstar">
                        <input
                          type="checkbox"
                          id="vehicle1"
                          name="vehicle1"
                          value="Bike"
                          className="check-box"
                          checked={isItemSelected}
                          onChange={(event) =>
                            handleClick(event, val.identifier)
                          }
                        />
                        <img
                          src={val.star ? Stared : Unstar}
                          alt=""
                          className="cursor-pointer"
                          onClick={(e) => {
                            handleStar(val.identifier, !val.star);
                          }}
                        />
                      </div>
                    </td>
                    <td className="sticky-col-2">
                      <img
                        src={val.image_url ? val.image_url : DefaultInventory}
                        alt="image url"
                        style={{
                          width: "54px",
                          height: "54px",
                          marginRight: "5px",
                          objectFit: "cover",
                        }}
                      />
                      <span
                        onClick={(e) => {
                          goTo(`/view-inventory/${val.identifier}`);
                        }}
                      >
                        {val.name}
                      </span>
                    </td>
                    {settings.inventory_type && (
                      <td>{val.inventory_type_name}</td>
                    )}
                    {settings.status && (
                      <td>
                        <span className="payment-types">{val.status}</span>
                      </td>
                    )}
                    {settings.supplier_id && <td>{val.supplier_name}</td>}
                    {settings.serial_no && <td>{val.serial_no}</td>}
                    {settings.current_qty && (
                      <td className="current_qty_col">
                        <img
                          src={
                            val.current_qty <= 0
                              ? InventoryLevelSixIcon
                              : val.current_qty >= val.low_stock_threshold
                              ? InventoryLevelOneIcon
                              : InventoryLevelThreeIcon
                          }
                          alt="image url"
                        />
                        <span>{val.current_qty}</span>
                      </td>
                    )}
                    {settings.low_stock_threshold && (
                      <td>{val.low_stock_threshold}</td>
                    )}
                    {settings.unit_price && <td>${val.unit_price}</td>}
                    {settings.total_inventory && (
                      <td>${val.total_inventory_value}</td>
                    )}
                    {settings.inventory_location && (
                      <td>{val.inventory_location_name}</td>
                    )}
                    {settings.last_order_date && <td>{val.last_order_date}</td>}
                    {settings.expiration_date && <td>{val.expiration_date}</td>}
                    {settings.notification_alert && (
                      <td>{val.notification_alert}</td>
                    )}
                    {settings.reorder_status && (
                      <td>{val.reorder_status ? "Reorder" : "-"}</td>
                    )}
                    <td className="sticky-col-3 template-options">
                      <a
                        aria-expanded="false"
                        aria-haspopup="true"
                        className="btn dropdown-toggle"
                        data-toggle="dropdown"
                        id="dropdownMenuLink"
                        role="button"
                      >
                        <i className="fa fa-ellipsis-v"></i>
                      </a>
                      <ul
                        aria-labelledby="dropdownMenuLink"
                        className="dropdown-menu"
                      >
                        <li>
                          <a
                            className="archive_group_invites"
                            data-toggle="tooltip"
                            title="View"
                            onClick={(e) => {
                              goTo(`/view-inventory/${val.identifier}`);
                            }}
                          >
                            <div className="row d-flex">
                              <div className="col-sm-3">
                                <i
                                  className="fa fa-eye"
                                  style={{
                                    paddingTop: "5px",
                                    fontSize: "16px",
                                    cursor: "pointer",
                                  }}
                                ></i>
                              </div>
                              <div
                                className="col-sm-9"
                                style={{
                                  paddingTop: "5px",
                                  cursor: "pointer",
                                }}
                              >
                                View
                              </div>
                            </div>
                          </a>
                          <a
                            className="archive_group_invites"
                            data-toggle="tooltip"
                            title="Edit"
                            onClick={(e) => {
                              goTo(`/edit-inventory/${val.identifier}`);
                            }}
                          >
                            <div className="row d-flex">
                              <div className="col-sm-3">
                                <i
                                  className="fa fa-pencil-square-o"
                                  style={{
                                    paddingTop: "5px",
                                    fontSize: "16px",
                                    cursor: "pointer",
                                  }}
                                ></i>
                              </div>
                              <div
                                className="col-sm-9"
                                style={{
                                  paddingTop: "5px",
                                  cursor: "pointer",
                                }}
                              >
                                Edit
                              </div>
                            </div>
                          </a>
                          <a
                            className="archive_group_invites"
                            data-toggle="tooltip"
                            title="View History"
                            onClick={(e) => {
                              goTo(
                                `/view-history/${val.identifier}/stock-history`
                              );
                            }}
                          >
                            <div className="row d-flex">
                              <div className="col-sm-3">
                                <i
                                  className="fa fa-history"
                                  style={{
                                    paddingTop: "5px",
                                    fontSize: "16px",
                                    cursor: "pointer",
                                  }}
                                ></i>
                              </div>
                              <div
                                className="col-sm-9"
                                style={{
                                  paddingTop: "5px",
                                  cursor: "pointer",
                                }}
                              >
                                View History
                              </div>
                            </div>
                          </a>
                          <a
                            class="archive_group_invites"
                            data-toggle="tooltip"
                            title="View QR"
                            onClick={(e) => {
                              setShowDetails(val.qr_code);
                              setShowQr(true);
                            }}
                          >
                            <div class="row d-flex">
                              <div class="col-sm-3">
                                <i
                                  class="fa fa-qrcode"
                                  style={{
                                    paddingTop: "5px",
                                    fontSize: "16px",
                                    cursor: "pointer",
                                  }}
                                ></i>
                              </div>
                              <div
                                class="col-sm-9"
                                style={{
                                  paddingTop: "5px",
                                  cursor: "pointer",
                                }}
                              >
                                View QR
                              </div>
                            </div>
                          </a>
                          <a
                            className="archive_group_invites"
                            data-toggle="tooltip"
                            title="Archive"
                            onClick={(e) => {
                              archive(val);
                            }}
                          >
                            <div className="row d-flex">
                              <div className="col-sm-3">
                                <i
                                  className="fa fa-trash"
                                  style={{
                                    paddingTop: "5px",
                                    fontSize: "16px",
                                    cursor: "pointer",
                                    color: "#FF3030",
                                  }}
                                ></i>
                              </div>
                              <div
                                className="col-sm-9"
                                style={{
                                  paddingTop: "5px",
                                  cursor: "pointer",
                                  color: "#FF3030",
                                }}
                              >
                                Archive
                              </div>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>
                );
              })}
            <Loader pageDetails={pageDetails} loading={loading} colSpan={14} />
          </tbody>
        </table>
      </div>
      <div className="page-limits">
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            value={limit}
            onChange={(e) => {
              setPage(1);
              setLoading(true);
              setLimit(e.target.value);
            }}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={30}>30</MenuItem>
          </Select>
        </FormControl>
        <Pagination
          count={pageCount || 1}
          variant="outlined"
          shape="rounded"
          onChange={handleChangePage}
        />
      </div>
      {showQr &&
                <ViewQrCode show={showQr} closeup={close} details={showDetails}/>
            }
    </div>
  );
};

export default withRouter(Active);
