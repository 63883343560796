import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetch = (url, initalState = {}) => {
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isFetching, setIsFetcing] = useState(false);

  useEffect(() => {
    setIsFetcing(true);
    const fetchData = async () => {
      const {
        filters = {},
        pagination = {},
        sort = {}
      } = initalState;
      const params = {
        ...filters,
        ...pagination
      }
      if (sort.name) {
        params.sort = `${sort.name || ''} ${sort.value || ''}`;
      }
      try {
        const response = await axios.get(url, {params});
        setData(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
        setIsFetcing(false);
      }
    };

    fetchData();
  }, [url]);

  const refetch = async ({filters = {}, pagination = {}, sort = {}} = {}) => {
    setIsFetcing(true);
    try {
        const params = {
          ...filters,
          ...pagination
        }
        if (sort.name) {
          params.sort = `${sort.name || ''} ${sort.value || ''}`;
        }
        const response = await axios.get(url, {params});
        setData(response.data);
    } catch (err) {
        setError(err);
    } finally {
        setIsFetcing(false);
    }
  }

  return { data, isLoading, error, refetch, isFetching, loading: isLoading };
};

export default useFetch;