import React from "react";


export const ComponentToPrint = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} style={{ textAlign: "center" }} className="print-qr-body">
      <p
        style={{ fontWeight: "500", fontSize: "37px", color: "#080808" }}
      >
        {props.pageData.inventory_name}
      </p>
      <img alt="" src={props.pageData ? props.pageData.url : ""} style={{ width: "100%" }} />  
    </div>
  );
});