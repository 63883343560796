import { useEffect, useState} from 'react';
import axios from 'axios';

const initialState = {
    forms_grouping_data: { categories: [], series: [], users: [] }
}

const useFormsGroupByData = (filters) => {
    const [stats, setStats] = useState(initialState);
    const [loading, setLoading] = useState(false);
    const [error, SetError] = useState(false);

    const updateResponseData = (response, stats) => {
        return response.forms_grouping_data
    }

    useEffect(() => {
        const fetchGroupByData = async () => {
            setLoading(true);
            try {
                let search_query = Object.entries(filters).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {})
                const { data: response } = await axios.get('analytics_dashboard/forms_group_by_data', { params: search_query });
                let result = updateResponseData(response, stats);
                setStats(result);
            } catch (error) {
                SetError(true);
                console.error(error);
            }
        };

        fetchGroupByData().then(r => {
            setLoading(false);
            console.log('render success');
        });
    }, [filters]);

    return {
        stats,
        loading,
        error
    };
};

export default useFormsGroupByData;