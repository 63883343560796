import { Box, Button, CircularProgress, FormControl, Grid, Modal, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import FormWrapper from '../../../shared/components/formWrapper';
import { UserGroupIcons } from '../../../shared/components/userGroupIcons';
import useUpdate from '../../../shared/components/useUpdate';
import useFetch from '../../../shared/components/userFetch';
import useModify from '../../../shared/components/userModify';
import { useForm } from 'react-hook-form';
import useDelete from '../../../shared/components/useDelete';
import { showToast } from '../../../shared/components/showNotification';

const style = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: '85%',
    backgroundColor: '#fff',
    boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.30)',
    borderRadius: '12px',
    p: 4
};

const RequiredDocumentsForm = ({initialValues = null, recordId, refetch, setClose}) => {
    const isEdit = !!initialValues;
    const defaultValues = isEdit ? initialValues : {}
    const {mutate: createNew, isLoading: loadingNew} = useUpdate(`/api/v2/entity_management/groups/${recordId}/create_required_document`);
    const {mutate, isLoading: isMutating} = useModify(`/api/v2/entity_management/groups/${recordId}/update_required_document`);
    const { register, handleSubmit, formState: { errors } } = useForm({defaultValues});
    const onSubmit = (data) => {
        const payload = {
            required_document: {
                ...data
            }
        }
        if (isEdit) {
            mutate(payload, {
                onSuccess: () => {
                    refetch({});
                    setClose();
                    showToast({message: 'Updated Successfully'});
                }
            }, `/api/v2/entity_management/groups/${recordId}/update_required_document/${initialValues.id}`)
        } else {
            createNew(payload, {
                onSuccess: () => {
                    refetch({});
                    showToast({message: 'Created Successfully'});
                    setClose();
                }
            })
        }
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                        <TextField
                            placeholder="Enter Name"
                            size="small"
                            {...register('name', { required: 'Name is required' })}
                            error={!!errors.name}
                            helperText={errors.name ? errors.name.message : ''}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                        <TextField
                            placeholder="Enter Description"
                            size="small"
                            {...register('description')}
                            error={!!errors.description}
                            helperText={errors.description ? errors.description.message : ''}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <FormControl fullWidth>
                        <TextField
                            placeholder="Enter Due Date"
                            size="small"
                            {...register('expiry_day', {
                                pattern: {
                                    value: /^[0-9]+$/,
                                    message: 'invalid'
                                }
                            })}
                            error={!!errors.expiry_day}
                            helperText={errors.expiry_day ? errors.expiry_day.message : ''}
                            inputMode="numeric"
                            type="tel"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={2} style={{display: 'flex', alignItems: 'center', gap: '15px'}}>
                    <Button endIcon={(isMutating || loadingNew) ? <CircularProgress style={{color: '#fff'}} size={16} color="inherit" /> : null} style={{textTransform: 'none', backgroundColor: '#407797', color: '#fff', outline: 'none'}} disabled={isMutating || loadingNew} type="submit" variant="contained" color="primary">
                        {isEdit ? 'Update' : 'Save'}
                    </Button>
                    <div onClick={() => setClose(false)} style={{cursor: 'pointer'}}>{UserGroupIcons.archive}</div>
                </Grid>
            </Grid>
        </form>
    )
}

const CreateRequiredDocuments = ({setClose, recordId, refetch}) => {
    return <RequiredDocumentsForm refetch={refetch} setClose={setClose} recordId={recordId} />
}

const EditRequiredDocuments = ({setClose, refetch, recordId, initialValues}) => {
    return <RequiredDocumentsForm setClose={setClose} refetch={refetch} recordId={recordId} initialValues={initialValues} />
}

const RequiredDocuments = ({open, handleClose, data}) => {
    const {data: record, isLoading, refetch} = useFetch(`/api/v2/entity_management/groups/${data.id}/required_documents`);
    const { mutate: deleteItem, isLoading: isDeleting } = useDelete(`/api/v2/entity_management/groups/${data.id}/required_documents`);
    const [isNew, setNew] = useState(false);
    const [edit, setEdit] = useState({});
    const recordData = record ? record.data : [];
    const {
        id,
        logo,
        name = '',
        description = ''
    } = data || {};
    const handleDelete = (id) => {
        if (isDeleting) {
            return false;
        }
        const payload = {
            id
        }
        deleteItem(payload, {
            onSuccess: () => {
                refetch();
                showToast({message: 'Deleted Successfully'});
            }
        });
    }
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <FormWrapper>
                <Box sx={style}>
                    <Box style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div>
                            <Typography id="modal-title" variant="h6" component="h2">
                                + Required Documents
                            </Typography>
                            <p>
                                All documents entered will be visible in ComplyLoop
                            </p>
                        </div>
                        <div onClick={handleClose} style={{cursor: 'pointer'}}>
                            {UserGroupIcons.closeIcon}
                        </div>
                    </Box>
                    <Box style={{border: '1px solid #D3D3D3', padding: 16, borderRadius: 5, marginBottom: 16}}>
                        <Grid container spacing={2} style={{ gap: 10, margin: 0, padding: 0 }}>
                            <Grid item style={{padding: 0}}>
                                {logo ? <img height="80px" width="80px" style={{objectFit: 'contain'}} src={logo} /> : (
                                    <Box style={{height: 80, width: 80, background: '#65B5E3', color: '#fff', fontSize: 32, display: 'flex', alignItems:'center', justifyContent: 'center'}}>{name.charAt()}</Box>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={8} style={{flex: 1, padding: 0}}>
                                <Box style={{display: 'flex', flexDirection: 'column'}}>
                                    <Typography variant="h6" component="h3">
                                        {name}
                                    </Typography>
                                    <p>
                                        {description}
                                    </p>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box style={{border: '1px solid #D0D5DD', borderRadius: 5}}>
                        <Grid container style={{padding: 10, background: '#E0EDF4', margin: 0, height: 56, alignItems: 'center'}}>
                            <Grid item sm={4}>
                                Required Documents
                            </Grid>
                            <Grid item sm={4}>
                                Description
                            </Grid>
                            <Grid item sm={2}>
                                Due Date
                            </Grid>
                            <Grid item sm={2}>
                                Actions
                            </Grid>
                        </Grid>
                        <Box style={{display: 'flex', flexDirection: 'column', gap: 10, padding: 10}}>
                            <Button style={{width: 'fit-content', backgroundColor: '#F5FCFF', color: '#65B5E3', border: '1px solid #65B5E3', textTransform: 'none'}} onClick={() => {setNew(true); setEdit({});}}>+ Add New Required Document</Button>
                            {isNew && (
                                <CreateRequiredDocuments refetch={refetch} setClose={() => setNew(false)} recordId={id} />
                            )}
                            {!!edit.id && (
                                <EditRequiredDocuments initialValues={edit} refetch={refetch} setClose={() => setEdit({})} recordId={id} />
                            )}
                            <Box style={{overflowY: 'auto', height: 220}}>
                                {recordData.map((d, i) => (
                                    <Grid container style={{padding: 10, background: ((i+1) % 2 === 0) ? '#fff' : '#E0EDF4', margin: 0, height: 56, alignItems: 'center'}}>
                                        <Grid item sm={4}>
                                            {d.name}
                                        </Grid>
                                        <Grid item sm={4}>
                                            {d.description}
                                        </Grid>
                                        <Grid item sm={2}>
                                            {d.expiry_day ? `${d.expiry_day} Days` : '0 Days'}
                                        </Grid>
                                        <Grid item sm={2} style={{display: 'flex', gap: '15px', alignItems: 'center'}}>
                                            <div onClick={() => {setEdit(d); setNew(false);}} style={{cursor: 'pointer'}}>{UserGroupIcons.edit}</div>
                                            <div onClick={() => handleDelete(d.id)} style={{cursor: 'pointer'}}>{UserGroupIcons.archive}</div>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </FormWrapper>
        </Modal>
    )
};

export default RequiredDocuments;
