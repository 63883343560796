import React, { useEffect, useState } from "react";
import axios from "../../../config/axiosConfig";
import Application from "../../../config/ApplicationWrapper";
import loader from "../../../assets/images/newloader.gif";

const List = () => {
    const [pageDetails, setPageDetails] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getPageDetails()
    }, [])

    const getPageDetails = async () => {
        try {
            window.scrollTo(0, 0);
            let resultObj = await axios.get(`${Application.api_url}/api/subcontractors`);
            if (resultObj.status == 200) {
                let details = resultObj.data; 
                setPageDetails(details ? details : [])
                setLoading(false)
            }
        } catch (err) {
            console.log("error==>", err);
        }
    };

    return (
        <>
            <div class="card asset-tab-landing">
                <div class="card-body">
                    <div class="tab-content">
                        <div
                            id="active-risk-assessment"
                            class="tab-pane fade in active active-risk-assessment subcontractors"
                        >
                            <div className="nestedTable pf-table">
                                <div className="fields-setting">
                                    <div className="asset-active-filters">
                                        <p>Subcontractors</p>
                                    </div>
                                    <div className="bulk-action">

                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div>
                                <div className="container-new">
                                    <div className="row">
                                        {pageDetails.map((val) => (
                                            <div className="col-md-3 mb-4">
                                                <div className="subcontractors-list">
                                                    <div className="subcontractors-info">
                                                        <div className="subcontractors-details">
                                                            <img src={ val.profile_picture_url != " " ? val.profile_picture_url : '/main-images/default-org-image.png'} alt="subcontractors-img" />
                                                            <p onClick={(e) => { window.location = `${Application.api_url}/subcontractor_dashboard/subcontractor_info?subcontractor_id=${val.identifier}` }}>{val.company}</p>
                                                        </div>
                                                        <p className="location">
                                                            <img src={"/main-images/location.svg"} alt="location-img" />{val.location}
                                                        </p>
                                                    </div>
                                                    <div className="subcontractors-count">
                                                        <div className="details">
                                                            <img src="/main-images/sub-projects.svg" alt="Project" /> Project
                                                            <p>0</p>
                                                        </div>
                                                        <div className="divider"></div>
                                                        <div className="details">
                                                            <img src="/main-images/sub-employees.svg" alt="Employees" /> Employees
                                                            <p>{val.employees}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {loading &&
                                            <div className="new-loader">
                                                <img src={loader} alt="" />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default List;
