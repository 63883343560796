import React, {useEffect, useRef, useState, useContext, createContext} from "react";
import Pagination from "@mui/material/Pagination";
import Cookies from "js-cookie";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { AssetDetailsContext } from "./index";
import Application from "../../../../config/ApplicationWrapper";
import {toast} from "react-toastify";
import AssetDefault from "../../../../assets/images/asset-default.svg";
import RelatedAssetModal from "./RelatedAssetModal";
import EmptyData from "../../../../assets/images/empty-assets.svg";
export const RelatedAssetContext = createContext();
// import RelatedAssetModal from "./RelatedAssetModal";

const RelatedAssetDetails = () => {
    const [relatedAssets, setRelatedAssets] = useState([]);
    const [assetLables, setAssetLables] = useState([]);
    const { pageData } = useContext(AssetDetailsContext);
    const [relatedAssetPopup, setRelatedAssetPopup] = useState(false);
    const [refresh, setRefresh] = useState();
    const [relatedAssetId, setRelatedAssetId] = useState();
    const [selectedValues, setSelectedValues] = useState([]);
    const lableSize = 4;


    const cookieDetails = Cookies.get("token");

    // Pagination
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [pagination, setPagination] = useState(true);
    const [pageCounts, setPageCounts] = useState(0);

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const handleSelectedAssetList = (id) => {
        let selectedIds = selectedValues || [];
        const findIndex = selectedIds.findIndex(value => value === id)

        if(findIndex === -1) {
            selectedIds.push(id)
        }
        else {
            selectedIds.splice(findIndex, 1)
        }
        setSelectedValues([...selectedIds])
    }

    const updateResults = (resultObj) => {
        let records = resultObj.data;
        setRelatedAssets([...records])
        // records.length > 0 ? setAssetAction(true) : null
        pagination === false ? setPagination(true) : null;
        setPageCounts(resultObj.metadata ? resultObj.metadata.pages : 0);
    }

    const urlParams = () => {
        return new URLSearchParams({
            page: page,
            pagination: true,
            limit: limit,
        }).toString();
    }

    const removeRelatedAssets = async (id, individual) => {
        try {
            let url = new URL(`${Application.api_url}/api/v2/asset_trackings/${pageData.id}/related_assets/${id}`);

            fetch(
                url,
                {
                    method: "DELETE",
                    headers: { Authorization: cookieDetails },
                }
            ).then(async response => {
                if(response.status === 200) {
                    setRefresh(Math.random())
                    if(individual) {
                        toast.success('Related asset disconnected successfully');
                    }
                }
            })
        } catch (err) {
            console.log("error===>", err);
        }
    }

    const bulkAssetDelete = () => {
        selectedValues.map(id => removeRelatedAssets(id, false))
        setSelectedValues([]);
        toast.success('Deleted successfully');
    }

    const getRelatedAssetDetails = async (asstId) => {
        try {
            let url = new URL(`${Application.api_url}/api/v2/asset_trackings/${asstId}/related_assets`);
            url.search = urlParams()
            fetch(
                url,
                {
                    method: "GET",
                    headers: { Authorization: cookieDetails },
                }
            ).then(async response => {
                let resultObj = await response.json()
                if(response.status === 200) {
                    updateResults(resultObj)
                }
            })
        } catch (err) {
            console.log("error===>", err);
        }
    }

    const ManagerList = (asset, manager, moreItems, index) => (
        <span>
            <span key={asset.id} className={`asset-manager-details ${index > lableSize && !assetLables.includes(asset.id) ? `${asset.id}_managers_hide` : ''}`}>
                {manager.first_name} {manager.last_name}
            </span>
            {
                index === lableSize ? <MoreItems count={(asset.managers.length - 1) - lableSize } asset_id={asset.id}/> : null
            }
        </span>
    );

    const MoreItems = ({count, asset_id}) => (
        <span key={asset_id} className={`asset-manager-details ${assetLables.includes(asset_id) ? `${asset_id}_managers_hide` : '' }`}
              onClick={() => {
                  let labels = assetLables;
                  labels.push(asset_id);
                  setAssetLables([...labels]);
              }}
        >
            + {count} More
        </span>
    );

    const NoAssetsFound = () => (
        <div className="no-assets">
            <img src={EmptyData} />
            <p>
                <b>No Data</b>
            </p>
            <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                    setRelatedAssetPopup(true);
                }}
            >
                <i className="fa fa-plus" aria-hidden="true"></i>
                Add Related Assets
            </button>
        </div>
    )

    useEffect(() => {
        getRelatedAssetDetails(pageData.identifier)
    }, [limit, page, pageData, refresh]);

    return (
        <div>
            <div className="header d-flex">
                <h3 className="title"><b>Related Assets</b></h3>
                {
                    relatedAssets.length > 0 ?
                        <div className="title-action">
                            { selectedValues.length > 0 ? (
                            <button
                                type="button"
                                className="btn bulk-delete"
                                onClick={() => {
                                    bulkAssetDelete();
                                }}
                            >
                                <i className="fa fa-trash" aria-hidden="true"></i>
                                Delete Selected
                            </button>
                            ) : null }
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => {
                                    setRelatedAssetPopup(true)
                                }}
                            >
                                <i className="fa fa-plus" aria-hidden="true"></i>
                                Add Related Assets
                            </button>
                        </div> : null
                }
            </div>
            {
                relatedAssets.length > 0 ?
                    <div className="table-template">
                        <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th scope="col">
                                        <div className="d-flex justify-content-between">

                                        </div>
                                    </th>
                                    <th scope="col">
                                        <div className="d-flex justify-content-between">
                                           Image
                                        </div>
                                    </th>
                                    <th scope="col">
                                        <div className="d-flex justify-content-between">
                                            Asset Name
                                        </div>
                                    </th>
                                    <th scope="col">
                                        <div className="d-flex justify-content-between">
                                            Company
                                        </div>
                                    </th>
                                    <th scope="col">
                                        <div className="d-flex justify-content-between">
                                            Group
                                        </div>
                                    </th>
                                    <th scope="col">
                                        <div className="d-flex justify-content-between">
                                            Model #
                                        </div>
                                    </th>
                                    <th scope="col">
                                        <div className="d-flex justify-content-between">
                                            Serial No#
                                        </div>
                                    </th>
                                    <th scope="col">
                                        <div className="d-flex justify-content-between">
                                            Created By
                                        </div>
                                    </th>
                                    <th scope="col">
                                        <div className="d-flex justify-content-between">
                                            Responsible Party
                                        </div>
                                    </th>
                                    <th scope="col">
                                        <div className="d-flex justify-content-between">
                                           Status
                                        </div>
                                    </th>
                                    <th scope="col" style={{textAlign: "left"}}>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {relatedAssets.map((asset, index) => (
                                    <tr key={index}>
                                        <td style={{paddingLeft: "30px"}}>
                                            <input
                                                type="checkbox"
                                                checked={selectedValues.includes(asset.id)}
                                                style={{ marginTop: "10px", accentColor: "#407797" }}
                                                onClick={(e) => {
                                                    handleSelectedAssetList(asset.id)
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <div>
                                                <img src={asset.image_url || AssetDefault} alt=''
                                                     className="asset-image"/>
                                            </div>
                                        </td>
                                        <td>{asset.name}</td>
                                        <td>{asset.company_name}</td>
                                        <td>{asset.groups[0] && asset.groups[0].name}</td>
                                        <td>{asset.model}</td>
                                        <td>{asset.serial}</td>
                                        <td>{asset.creator && asset.creator.full_name}</td>
                                        <td>
                                            <div className="manager-containers">
                                                {
                                                    asset.managers && asset.managers.map((manager, index) =>
                                                        ManagerList(asset, manager, true, index)  )
                                                }
                                            </div>
                                        </td>
                                        <td className="related-status">
                                            <span className={`badge badge-light ${asset.status}`}>{asset.status}</span>
                                        </td>
                                        <td className="template-options">
                                            <div className="col-sm-3" title="Delink" onClick={() => {
                                                removeRelatedAssets(asset.id, true)
                                            }}>
                                                <i
                                                    className="fa fa-chain-broken"
                                                    aria-hidden="true"
                                                    style={{
                                                        paddingTop: "5px",
                                                        fontSize: "16px",
                                                        color: "#FF3030",
                                                    }}
                                                ></i>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                                }
                                </tbody>
                            </table>
                    </div>
                    :
                    <NoAssetsFound />
            }
            <div
                className="d-flex justify-content-between"
                style={{alignItems: "center"}}
            >
                { relatedAssets.length > 0 ?
                    <FormControl sx={{m: 1, minWidth: 120}} size="small">
                        <Select labelId="demo-select-small" id="demo-select-small" value={limit}
                                onChange={(e) => {
                                    setLimit(e.target.value)
                                    setPage(1)
                                    setPagination(false)
                                }}
                        >
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={30}>30</MenuItem>
                        </Select>
                    </FormControl> : null
                }
                {
                    pagination && relatedAssets.length > 0 ?
                        <Pagination
                            count={pageCounts}
                            variant="outlined"
                            shape="rounded"
                            onChange={handleChangePage}
                        /> : null
                }
            </div>
            <RelatedAssetContext.Provider
                value={{relatedAssetPopup, setRelatedAssetPopup, setRefresh}}
            >
            <RelatedAssetModal assetId={pageData.id} relatedAssetId={relatedAssetId} />
            </RelatedAssetContext.Provider>
        </div>
    );
};

export default RelatedAssetDetails;
