import React from 'react';
import UserManagement from '../shared/UserManagement';
import { Route, Router, NavLink } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap'

import axios, { put } from 'axios';
const logo = require('../../assets/images/company_logo.png');

class CompanyList extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            result:[],
            fields: {name:'',industry:'',state:'',city:'',province:'',address:''},
            errors: {},
            company_id: '',
            file: [],
            imagePreviewUrl: '',
            company_url: ''
        }

        this.closeForm = this.closeForm.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);

    }

    handleImageChange(e) {
        e.preventDefault();
        this.setState({file:e.target.files[0]})

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            this.setState({
                imagePreviewUrl: reader.result
            });
        }

        reader.readAsDataURL(file)

    }

    handleSubmit(e) {
        e.preventDefault();
        if(this.handleValidation()){
            var file = this.state.file
            const url = '/companies/'+this.state.company_id;
            const formData = new FormData();
            formData.append('logo',file)
            formData.append('id',this.state.company_id)
            formData.append('name',this.state.fields['name'])
            formData.append('industry',this.state.fields['industry'])
            formData.append('state',this.state.fields['state'])
            formData.append('city',this.state.fields['city'])
            formData.append('province',this.state.fields['province'])
            formData.append('address',this.state.fields['address'])
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            let that = this
            put(url, formData, config)
                .then(function(response) {
                    $('#new_user').append('<span class="new_user_message" style="padding-left: 111px; font-size: 19px;">Company has been successfully updated.</span>')
                    $('.new_user_message').fadeOut(15000);
                    that.populateData()
                })
                .catch(function(error) {
                    console.log(error);
                });
        }else{
            console.log("Form has errors.")
        }
    }


    handleChange(field, e){
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({fields});
    }

    handleValidation(){
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if(!fields["name"]){
            formIsValid = false;
            errors["name"] = "Required";
        }

        if(!fields["industry"]){
            formIsValid = false;
            errors["industry"] = "Required";
        }

        if(!fields["state"]){
            formIsValid = false;
            errors["state"] = "Required";
        }

        if(!fields["city"]){
            formIsValid = false;
            errors["city"] = "Required";
        }

        // if(!fields["province"]){
        //     formIsValid = false;
        //     errors["province"] = "Province cannot be empty";
        // }

        if(!fields["address"]){
            formIsValid = false;
            errors["address"] = "Required";
        }


        this.setState({errors: errors});
        return formIsValid;
    }

    populateData(){
    var that = this;

    axios.get('get_companies',{
    })
        .then(function(response){
            var companies = response.data.companies
            that.setState({
                result: companies
            });
        })
        .catch(function(error){
            console.log(error);
        })
}

    componentDidMount() {
        var that = this;
        that.populateData()
    }

    closeForm() {
        var that = this
        that.setState({ show: false });
    }

    handleEdit(company_id){

        this.setState({ show: true, company_id: company_id });

        var that = this;
        const url = '/companies/'+company_id;

        axios.get(url,{
            headers: {'X-Requested-With': 'XMLHttpRequest'}
        })
            .then(function(response) {
                let company = response.data.company
                let fields = that.state.fields
                let logo_url = response.data.company_logo_url
                fields['name'] = company.name
                fields['industry'] = company.industry
                fields['state'] = company.state
                fields['city'] = company.city
                fields['province'] = company.province
                fields['address'] = company.address
                if(company){
                    that.setState({
                        fields: fields,
                        imagePreviewUrl: logo_url
                    });
                }
            })
            .catch(function(error) {
                console.log(error)

            });

    }

    renderTableData() {
        return this.state.result.map((company,key) => {
            return (
                <tr key={key}>
                    <td>{company.name}</td>
                    <td>{company.industry}</td>
                    <td>{company.state}</td>
                    <td>{company.city}</td>
                    <td>{company.address}</td>
                    <td><img class="img" src={company.logo_url} style={{maxWidth: "117px", maxHheight: "81px"}} alt=""/></td>
                    <td>
                        <p data-toggle="tooltip" title="Edit">
                            <button id={"edit-company-btn-"+company.id} className="btn btn-primary btn-xs" style={{fontSize: "13px"}}
                                onClick={() => { this.handleEdit(company.id) }}>Edit
                            </button>
                            </p>
                        </td>
                </tr>
                );
        })
    }

render(){
    let {imagePreviewUrl} = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
        $imagePreview = (<img src={imagePreviewUrl} />);
    }
    return (

        <div className="content">
            <div className="sub-navbar sub-navbar__header-breadcrumbs">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 sub-navbar-column">
                            <div className="sub-navbar-header">
                            </div>
                            <ol className="breadcrumb navbar-text navbar-right no-bg">
                            </ol>
                        </div>
                        <div className="col-lg-12">
                        </div>
                        {/* <div className="col-lg-12 sub-navbar-column">
                            <ol className="breadcrumb navbar-text navbar-left no-bg">
                                <li className="current-parent">
                                    <NavLink id="home-link-icon" to="/" className="current-parent" role="button"><i className="fa fa-fw fa-home"></i></NavLink>
                                </li>
                                <li className="active">
                                    <NavLink id="dashboard-link" to="/" className="current-parent" role="button">Dashboard</NavLink>
                                </li>
                                <li className="active">Companies</li>
                            </ol>
                        </div> */}
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <UserManagement source= "company-list" />
                    <div className="col-lg-2">

                    </div>
                    <div className="col-lg-10">
                        <div className="panel panel-default   b-a-2 no-bg b-gray-dark">
                            <div className="panel-heading">
                                <h4 className="panel-title">Company list</h4>
                            </div>

                            <div className="panel-body">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="col-lg-12 table-responsive" >
                                            <h2>Company Details</h2>
                                            <div className="row">
                                                <div className="col-lg-4 ctile-border">
                                                    <h4>Total Companies</h4>
                                                    <hr />
                                                    {this.state.result.length}        
                                                </div>
                                                <div className="col-lg-4">

                                                </div>
                                            </div>
                                            
                                            <table className="table table-bordered table-striped table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>Company Name</th>
                                                        <th>Industry</th>
                                                        <th>State/Province</th>
                                                        <th>City</th>
                                                        <th>Address</th>
                                                        <th>Logo</th>
                                                        <th>Edit</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                  {this.renderTableData()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal show={this.state.show} onHide={this.closeForm}>
                        <Modal.Header>
                            <Modal.Title>Edit Company</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div id="new_user"/>
                            <form className="form-horizontal">
                                <div className="form-group">
                                    <label className="col-sm-3 control-label">Name <span className="text-danger">*</span></label>
                                    <div className="col-sm-6">
                                        <input className="form-control" ref="name" id="name" maxLength="50" placeholder="" onChange={this.handleChange.bind(this, "name")} value={this.state.fields["name"] || ''}/>
                                        <span style={{color: "red"}}>{this.state.errors["name"]}</span>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="col-sm-3 control-label">Industry <span className="text-danger">*</span></label>
                                    <div className="col-sm-6">
                                        <input className="form-control" ref="industry" id="industry" maxLength="50" placeholder="" onChange={this.handleChange.bind(this, "industry")} value={this.state.fields["industry"] || ''}/>
                                        <span style={{color: "red"}}>{this.state.errors["industry"]}</span>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="col-sm-3 control-label">State/Province <span className="text-danger">*</span></label>
                                    <div className="col-sm-6">
                                        <select className="form-control" id="state" value={this.state.fields["state"]} onChange={this.handleChange.bind(this,"state")}>
                                            <option value=''>Please Select State/Province</option>
                                            <option value="AB">Alberta</option>
                                            <option value="BC">British Columbia</option>
                                            <option value="MB">Manitoba</option>
                                            <option value="NB">New Brunswick</option>
                                            <option value="NL">Newfoundland and Labrador</option>
                                            <option value="NS">Nova Scotia</option>
                                            <option value="ON">Ontario</option>
                                            <option value="PE">Prince Edward Island</option>
                                            <option value="QC">Quebec</option>
                                            <option value="SK">Saskatchewan</option>
                                            <option value="NT">Northwest Territories</option>
                                            <option value="NU">Nunavut</option>
                                            <option value="YT">Yukon</option>
                                            <option value="AL">AL</option>
                                            <option value="AK">AK</option>
                                            <option value="AR">AR</option>	
                                            <option value="AZ">AZ</option>
                                            <option value="CA">CA</option>
                                            <option value="CO">CO</option>
                                            <option value="CT">CT</option>
                                            <option value="DC">DC</option>
                                            <option value="DE">DE</option>
                                            <option value="FL">FL</option>
                                            <option value="GA">GA</option>
                                            <option value="HI">HI</option>
                                            <option value="IA">IA</option>	
                                            <option value="ID">ID</option>
                                            <option value="IL">IL</option>
                                            <option value="IN">IN</option>
                                            <option value="KS">KS</option>
                                            <option value="KY">KY</option>
                                            <option value="LA">LA</option>
                                            <option value="MA">MA</option>
                                            <option value="MD">MD</option>
                                            <option value="ME">ME</option>
                                            <option value="MI">MI</option>
                                            <option value="MN">MN</option>
                                            <option value="MO">MO</option>	
                                            <option value="MS">MS</option>
                                            <option value="MT">MT</option>
                                            <option value="NC">NC</option>	
                                            <option value="NE">NE</option>
                                            <option value="NH">NH</option>
                                            <option value="NJ">NJ</option>
                                            <option value="NM">NM</option>			
                                            <option value="NV">NV</option>
                                            <option value="NY">NY</option>
                                            <option value="ND">ND</option>
                                            <option value="OH">OH</option>
                                            <option value="OK">OK</option>
                                            <option value="OR">OR</option>
                                            <option value="PA">PA</option>
                                            <option value="RI">RI</option>
                                            <option value="SC">SC</option>
                                            <option value="SD">SD</option>
                                            <option value="TN">TN</option>
                                            <option value="TX">TX</option>
                                            <option value="UT">UT</option>
                                            <option value="VT">VT</option>
                                            <option value="VA">VA</option>
                                            <option value="WA">WA</option>
                                            <option value="WI">WI</option>	
                                            <option value="WV">WV</option>
                                            <option value="WY">WY</option>
                                        </select>
                                        <span style={{color: "red"}}>{this.state.errors["state"]}</span>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="col-sm-3 control-label">City<span className="text-danger">*</span></label>
                                    <div className="col-sm-6">
                                        <input className="form-control" ref="city" id="city" maxLength="60" placeholder="" onChange={this.handleChange.bind(this, "city")} value={this.state.fields["city"]}/>
                                        {/* <select className="form-control" id="city" value={this.state.fields["city"]} onChange={this.handleChange.bind(this,"city")}>
                                            <option value=''>Please Select City</option>
                                            <option>Montgomery</option>
                                            <option>New York</option>
                                            <option>California</option>
                                            <option>Texas</option>
                                            <option>Arizona</option>
                                        </select> */}
                                        <span style={{color: "red"}}>{this.state.errors["city"]}</span>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="profile-edit-profile" className="col-sm-3 control-label">Address<span className="text-danger">*</span></label>
                                    <div className="col-sm-6">
                                        <textarea className="form-control" ref="address" id="address" maxLength="300" placeholder="" onChange={this.handleChange.bind(this, "address")} value={this.state.fields["address"] || ''}/>
                                        <span style={{color: "red"}}>{this.state.errors["address"]}</span>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="col-sm-3 control-label">Company Logo</label>
                                    <div className="col-sm-6">
                                        <input id="edit-company-logo" type="file" onChange={this.handleImageChange} />
                                    </div>
                                </div>

                                <div className="imgPreview">
                                    {$imagePreview}
                                </div>


                            </form>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button bsStyle="primary" id="edit-company-close" onClick={this.closeForm}>Close</Button>
                            <Button bsStyle="primary" id="edit-company-save" onClick={this.handleSubmit}>Save changes</Button>
                        </Modal.Footer>
                    </Modal>



                </div>
            </div>
        </div>
        )}
}

export default CompanyList;