import React from "react";
import SideNav from "../../components/SideNav";
import CompanyDoc from "./CompanyDocs";
import StaredDoc from "./StaredDocs";
import TrainingDoc from "./TrainingDocs";
import DeletedDoc from "./DeletedDocs"; // Import the new component

const Docs = () => {
    return (
        <React.Fragment>
            <div className="msmcontainer dashboard-container">
                <div className="row d-flex">
                    <SideNav />
                    <div className="content-wrap asset-tracking-wrap risk-assessment">
                        <div className="title-tab-wrap">
                            <h1 style={{fontWeight:'600', fontSize:"32px"}}>
                                {/* <img src={CloseButton} alt="" className="header-closer-img" /> */}
                                <img src="/main-images/hp/copy.png" alt="Service Icon" style={{width:"30px", marginRight:"5px", marginTop:"-3px"}} />
                                Document Management
                            </h1>
                            <ul className="nav nav-tabs">
                                <li className="active">
                                    <a data-toggle="tab" href="#company-docs">
                                        Company Documents
                                    </a>
                                </li>
                                <li>
                                    <a data-toggle="tab" href="#stared-docs">
                                        Starred Documents
                                    </a>
                                </li>
                                <li>
                                    <a data-toggle="tab" href="#training-docs">
                                        Training Documents
                                    </a>
                                </li>
                                <li>
                                    <a data-toggle="tab" href="#deleted-docs">
                                        Deleted Documents
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="tab-content">
                            <div id="company-docs" className="tab-pane fade in active">
                                <CompanyDoc />
                            </div>
                            <div id="stared-docs" className="tab-pane fade in">
                                <StaredDoc />
                            </div>
                            <div id="training-docs" className="tab-pane fade in">
                                <TrainingDoc />
                            </div>
                            <div id="deleted-docs" className="tab-pane fade in">
                                <DeletedDoc />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Docs;
