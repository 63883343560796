import React, { useState } from "react";
import { Button, Modal, Typography, Box, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import { JobProfileFrom } from "./NewJobFrom";
import useFetch from "../../../shared/components/userFetch";
import FormWrapper from "../../../shared/components/formWrapper";
import { ShowNotification, showToast } from "../../../shared/components/showNotification";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: '70%',
  height: '80%',
  backgroundColor: "#fff",
  boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.30)",
  borderRadius: "12px",
  p: 4,
};

const NewJobs = ({
  open,
  setOpen,
  initialValues = null,
  mutate,
  setFilterState,
  isMutating
}) => {
  const isEdit = !!initialValues;
  const [notification, setNotification] = useState({
    open: false,
    message: 'test',
    severity: 'success'
  });
  //activated_eq=true create_job_profile=true
  const { data: baseData, isLoading: baseDataLoading } = useFetch(
    "/api/v2/entity_management/job_profiles/base_data?activated_eq=true&create_job_profile=true"
  );
  const {
    company_trainings = [],
    company_templates = [],
    company_users = []
  } = baseData || {};
  const defaultValues = isEdit
    ? { ...initialValues, jp_trainings: initialValues.jp_topics || [], jp_users: initialValues.jp_users.map(v => ({
      ...v, 
      key: v.name, 
      value: v.id
    }))}
    : {
        name: "",
        jp_users: [],
        jp_form_templates: [],
        jp_topics: [],
      };
  const {
    control,
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues,
  });
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSubmit = (data) => {
    const payload = {
      job_profile: {
        jp_users: data.jp_users.map((item) => item.value),
        jp_topics: data.jp_topics.map((item) => item.value),
        audit_template_ids: data.jp_form_templates.map((item) => item.value),
        name: data.name,
      },
    };

    if (isEdit) {
      payload.job_profile.id = initialValues.id;
      payload.id = initialValues.id;
    }
    mutate(payload, {
      onSuccess: () => {
        showToast({ message: `Job Profile ${ isEdit ? 'Updated' : 'Created'} Successfully!` });        
        setTimeout(() => {
          setFilterState({ page: 1, limit: 20});
          handleClose(); // Close the modal after submission
        }, 0);
      },
      onError: (e) => {
        showToast({ type: 'error', message: typeof e === 'string' ? e : e.errors.toString() });        
      }
    });
  };

  if (baseDataLoading) {
    return null;
  }

  const handleCloseNotification = () => {
    setNotification(prev => ({
      ...prev,
      open: false
    })); // Hide notification, retain other state values
  };

  return (
    <>
      <Button variant="contained" color="primary" className="popup-title" onClick={handleOpen}>
        New Job Profile
      </Button>
      <ShowNotification
        open={notification.open}
        onClose={handleCloseNotification}
        severity={notification.severity}
        message={notification.message}
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <FormWrapper>
          <Box sx={style}>
            <Typography className="popup-title" id="modal-title" variant="h6" component="h2">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
              <g clip-path="url(#clip0_9101_84804)">
                <path d="M23.8738 16.0001L8.12616 16.0001M16 8.12622L16 23.8739L16 8.12622Z" stroke="#080808" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_9101_84804">
                  <rect width="32" height="32" fill="white"/>
                </clipPath>
              </defs>
            </svg> {isEdit ? 'Update' : 'New'} Job Profile
            </Typography>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{ height: "calc(100% - 50px)" }}
            >
              <Box
                sx={{ mt: 2 }}
                style={{ overflowY: "scroll", height: "calc(100% - 40px)" }}
              >
                <JobProfileFrom
                  control={control}
                  errors={errors}
                  register={register}
                  setValue={setValue}
                  jp_form_templates={company_templates}
                  jp_trainings={company_trainings}
                  jp_users={company_users}
                />
              </Box>

              <Box
                mt={2}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <div />
                <div>
                  <Button
                    style={{
                      textTransform: "none",
                      backgroundColor: "#ECECEC",
                      color: "#5C5C5C",
                      marginRight: "10px",
                      outline: "none",
                    }}
                    onClick={() => setOpen(false)}
                    sx={{ mr: 1 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{
                      textTransform: "none",
                      backgroundColor: "#407797",
                      color: "#fff",
                      outline: "none",
                    }}
                    variant="contained"
                    htmlType="button"
                    type="submit"
                    endIcon={isMutating ? <CircularProgress size={16} color="inherit" /> : null} 
                    color="primary"
                  >
                    {isEdit ? 'Update' : 'Create' }
                  </Button>
                </div>
              </Box>
            </form>
          </Box>
        </FormWrapper>
      </Modal>
    </>
  );
};

export default NewJobs;
