import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import ArrowUp from "../../../../../assets/fonts/ArrowRight.svg";
import Searchbar from "../../../../../assets/images/searchbar.svg";
import axios from "../../../../../config/axiosConfig";
import Application from "../../../../../config/ApplicationWrapper";
import loader from "../../../../../assets/images/newloader.gif";
import Loader from "../../../../../components/MobileViewLoader";


const SelectGroup = ({ history, match }) => {
    const template_id = match.params.id;
    let userDetails = localStorage.getItem("user");
    userDetails = JSON.parse(userDetails);

    const mounted = useRef(false);
    const [pageDetails, setPageDetails] = useState([]);
    const [group, setGroup] = useState();
    const [search, setSearch] = useState();
    const [pageNumber, setPageNumber] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getPageDetails(userDetails)
    }, [])


    useEffect(() => {
        const redirectTimer = setTimeout(() => {
            mounted.current && getPageDetails(userDetails, 1)
            mounted.current = true;
        }, 2000);

        return () => clearTimeout(redirectTimer);
    }, [search]);

    const getPageDetails = async (details, page) => {
        try {
            window.scrollTo(0, 0);
            let resultObj = await axios.get(`${Application.api_url}/api/v2/internal_subs/${details.company_identifier}/internal_groups`, {
                params: {
                    page: page ? page : pageNumber,
                    limit: 20,
                    group_name_cont: search,
                },
            });
            if (resultObj.data && resultObj.data.success == true) {
                let details = resultObj.data;
                if (!search) {
                    setPageDetails(prevItems => [...prevItems, ...details.data])
                    setPageNumber(pageNumber + 1);
                    setPageCount(
                        details && details.metadata ? details.metadata.pages : 0
                    );
                } else {
                    setPageDetails(details.data)
                }
                setLoading(false)
            }
        } catch (err) {
            console.log("error==>", err);
        }
    };

    const startInspection = async () => {
        try {
            setLoading(true)
            let resultObj = await axios.post(`${Application.api_url}/api/v2/internal_subs/${userDetails.company_identifier}/internal_templates/${template_id}/start_inspection`, {
                group_id: group
            });
            if (resultObj.data && resultObj.data.success == true) {
                console.log(resultObj.data)
                let details = resultObj.data;
                window.location.href = `/audits/${details.data.audit_id}`
            }
        } catch (err) {
            console.log("error==>", err);
        }
    }

    const goBack = () => {
        history.push('/inter_sub_m_new_forms');
    }

    return (
        <>
            <Loader loading={loading} />
            {!loading &&
                <div className="internal-sub-new-forms-group">
                    <div className="title">
                        <div><img src={ArrowUp} alt="back-arrow" className="back-arrow" onClick={(e) => { goBack() }} /></div>
                        <div>
                            <span className="role">Assign Inspection to Group</span>
                            <p>Please Select a group, this will create the from under the assigned group.</p>
                        </div>
                    </div>
                    <div className="select-groups">
                        <div className="search-bar">
                            <input className="search-input" placeholder="Search" onChange={(e) => { setSearch(e.target.value) }} />
                            <img src={Searchbar} alt="Searchbar" className="search-icon" />
                        </div>
                        <div className="groups-list">
                            <InfiniteScroll
                                dataLength={pageDetails.length}
                                next={(e) => { getPageDetails(userDetails) }}
                                hasMore={pageCount >= pageNumber}
                                loader={
                                    <div className="new-loader mobile-view" >
                                        <img src={loader} alt="" />
                                    </div >
                                }
                            >
                                {
                                    pageDetails.length > 0 &&
                                    pageDetails.map((val, index) => (
                                        <div className="groups-details">
                                            <label className="form-check-label one_year" htmlFor="one_year">{val.group && val.group.name}</label>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="inlineRadioOptions"
                                                id="one_year"
                                                value={val.identifier}
                                                onChange={(e) => {
                                                    setGroup(e.target.value)
                                                }}
                                            />
                                        </div>

                                    ))}
                            </InfiniteScroll>
                        </div>
                        <div className="start-insp">
                            <button onClick={(e) => { startInspection() }}>Start </button>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default withRouter(SelectGroup);