import React from "react";
import { Modal, Button } from "react-bootstrap";
import Cookies from "js-cookie";
import Application from "../../../../config/ApplicationWrapper";
const cookieDetails = Cookies.get("token");

export default function Notes(props) {
  const { show, closepopup, ratId } = props;
  const [note, setNote] = React.useState("");
  const onhandleStatusClose = () => {
    setNote("")
    closepopup();
  };

  const saveNotes = async () => {
    try {
      let ratsData = await fetch(
        `${Application.api_url}/api/v2/rats/${ratId}/create_rat_note`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: cookieDetails,
          },
          body: JSON.stringify({
            note: note,
          }),
        }
      );
      ratsData = await ratsData.json();
      setNote("")
      closepopup(ratsData);
    } catch (err) {
      console.log("error==>", err);
    }
  };

  return (
    <Modal
      show={show}
      onHide={(e) => {
        onhandleStatusClose();
      }}
      centered={true}
      className="add-task-modal signature-popup"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <b>Notes </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <textarea
          id="w3review"
          name="w3review"
          rows="4"
          cols="50"
          onChange={(e) => {
            setNote(e.target.value);
          }}
        >
          {note}
        </textarea>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn-secondary"
          onClick={(e) => {
            onhandleStatusClose();
          }}
        >
          Cancel
        </Button>
        <Button
          className="btn-primary"
          onClick={(event) => {
            saveNotes();
            event.currentTarget.disabled = true;
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
