import React from 'react';
import CorrectiveIcon from "../../../assets/feature-icons/Corrective-Actions.png";
import Plus from "../../../assets/feature-icons/plus_icon.png";
import HeroBannerImage from "../../assets/images/actions_mgmt.png";
const CorrectiveActions = () => {
    return (
        <div className="corrective-actions-section">
            <img src={HeroBannerImage}  />
        </div>
    );
};

export default CorrectiveActions;