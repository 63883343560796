import React, {useEffect} from 'react'

const OrderActionMenu = ({ history, itemId, isOpen, onToggle }) => {
  const viewOrderDetails = () => {
    history.push(`/orders/${itemId}`);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.action-menu')) {
        onToggle(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className="action-menu">
      <button className="ellipsis-btn" onClick={(e) => {
        e.stopPropagation();
        onToggle(itemId);
      }}>⋮</button>
      {isOpen && <div className="action-menu--dropdown">
        <button className="action" onClick={viewOrderDetails}>
          <i
            className="fa fa-eye"
            style={{
              fontSize: "16px",
              cursor: "pointer",
            }}
          ></i>
          <span className={"ml-2"}>View / Edit</span>
        </button>
      </div>}
    </div>
  )
}

export default OrderActionMenu;