import React, { useEffect, useReducer, useState } from "react";
import InventoryType from "./InventoryType";
import SupplierList from "./SupplierList";
import Location from "./Location";
import PickerList from "./PickerList";
import UserList from './Users';
import { withRouter } from "react-router-dom";

const initialTabStatus = {
    inventoryType: true,
    supplierList: false,
    location: false,
    picker: false,
    user: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case "UPDATE":
            state[action.tab] = true;
            return { ...state };
        default:
            return state;
    }
};

const index = ({location}) => {

    const [tabs, dispatch] = useReducer(reducer, initialTabStatus);
    const [activeTab, setActiveTab] = useState(1);
    const [queryParams, setQueryParams] = useState("");

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const paramValue = searchParams.get('page');
        setQueryParams(paramValue); 
        if(paramValue == "supplier"){
            handleTabClick(2)
        }
    }, [])

    const handleUpdateTab = (tab) => {
        dispatch({ type: "UPDATE", tab: tab });
    };

    const handleTabClick = (tabIndex) => { 
        setActiveTab(tabIndex);
    };

    return (
        <div className="config-pg">
            <div className="container-new">
                <div className="header">
                    <h4>Configuration</h4>
                    <div className="tabs-container">
                        <ul className="tab">
                            <li onClick={() => { handleUpdateTab("inventoryType"); handleTabClick(1) }} className={activeTab === 1 ? 'active' : ''}>Inventory Type</li>
                            <li onClick={() => { handleUpdateTab("supplierList"); handleTabClick(2) }} className={activeTab === 2 ? 'active' : ''}>Supplier List</li>
                            <li onClick={() => { handleUpdateTab("location"); handleTabClick(3) }} className={activeTab === 3 ? 'active' : ''}>Location</li>
                            <li onClick={() => { handleUpdateTab("picker"); handleTabClick(4) }} className={activeTab === 4 ? 'active' : ''}>Picker List</li>
                            <li onClick={() => {
                                handleUpdateTab("user");
                                handleTabClick(5)
                            }} className={activeTab === 5 ? 'active' : ''}>Users
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="tabs-list">
                    <div className="tab-content" style={{display: activeTab === 1 ? 'block' : 'none'}}>
                        {tabs.inventoryType && <InventoryType/>}
                    </div>

                    <div className="tab-content" style={{display: activeTab === 2 ? 'block' : 'none'}}>
                        {tabs.supplierList || queryParams == "supplier" ? <SupplierList/> : ""}
                    </div>

                    <div className="tab-content" style={{display: activeTab === 3 ? 'block' : 'none'}}>
                        {tabs.location && <Location/>}
                    </div>

                    <div className="tab-content" style={{display: activeTab === 4 ? 'block' : 'none'}}>
                        {tabs.picker || queryParams == "picker" ? <PickerList/> : ""}
                    </div>

                    <div className="tab-content" style={{display: activeTab === 5 ? 'block' : 'none'}}>
                        {tabs.user || queryParams == "user" ? <UserList/> : ""}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(index)