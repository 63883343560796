import React, { useEffect, useReducer, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  FormControl,
  Pagination,
  MenuItem,
  Select,
} from "@mui/material";
import SideNav from "../../../../components/SideNav";
import ArrowUp from "../../../../assets/fonts/ArrowRight.svg";
import Supplier from "../../../../assets/fonts/Supplier.svg";
import SearchIcon from "../../../../assets/images/searchbar.svg";
import ExternalIcon from "../../../../assets/fonts/External.svg";
import Show from "./show";

import axios from "../../../../config/axiosConfig";
import Application from "../../../../config/ApplicationWrapper";
import Loader from "../../../../components/NewLoader";

const initialState = {
  name_cont: "",
  inventory_type_name_cont: "",
  status_eq: "",
  creator_full_name_cont: ""
};

const searchReducer = (state, action) => {
  switch (action.type) {
    case "HANDLE INPUT CHANGE":
      return { ...state, [action.field]: action.payload };
    case "HANDLE INPUT REMOVE":
      return { ...state, [action.field]: "" };
    case "UPDATE":
      return { ...state, ...action.payload };
    case "RESET":
      return initialState;
    default:
      return state;
  }
};

const ViewSupplier = ({ history, match }) => {
  const id = match.params.id;
  const mounted = useRef(false);
  const [search, dispatch] = useReducer(searchReducer, initialState);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(30);
  const [pageCount, setPageCount] = useState(0);
  const [show, setShow] = useState(false);
  const [pageDetails, setPageDetails] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getPageDetails()
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      mounted.current && getPageDetails();
      mounted.current = true;
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);
  
  const getPageDetails = async() => {
    try{
        axios
        .get(`${Application.api_url}/api/v2/inventories`, {
          params: {
            supplier_id_eq: id,
            ...search
          },
        })
        .then((res) => {
          setPageDetails(res.data.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log("err===>", err);
        });
    }catch(err){
        console.lof("err==>", err)
    }
  }

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleTextChanges = (e) => {
    dispatch({
      type: "HANDLE INPUT CHANGE",
      field: e.target.name,
      payload: e.target.value,
    });
  };

  const goBack = (page) => {
    if (page && page == "config") {
      history.push(`/inventory?model=${page}`);
    } else if (page && page == "supplier") {
      history.push(`/inventory?model=config&page=${page}`);
    } else {
      history.push("/inventory");
    }
  };

  const close = () => {
    setShow(false);
  };
  return (
    <div className="msmcontainer dashboard-container">
      <div className="row d-flex">
        <SideNav />
        <div className="content-wrap add-supplier">
          <div className="title-card">
            <div className="title-content">
              <div>
                <img
                  src={ArrowUp}
                  alt="back-arrow"
                  className="back-arrow"
                  onClick={(e) => {
                    goBack();
                  }}
                />
              </div>
              <div className="title">
                <div className="supplier">
                  <div className="supplier-dtls">
                    <img src={Supplier} alt="supplier-icon" />
                    <h4>
                      <b>{match.params.name}</b>
                    </h4>
                  </div>
                  <div className="breadcrumbs">
                    <span onClick={() => goBack("config")}>
                      Configuration &gt;
                    </span>
                    <span onClick={() => goBack("supplier")}>
                      Supplier List &gt;
                    </span>
                    <span className="current-pg">View Supplier</span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Button
                className="btn-cancel"
                onClick={() => {
                  setShow(true);
                }}
              >
                View Supplier Info
              </Button>
            </div>
          </div>
          <div className="view-supplier-pg">
            <div className="view-supplier">
              <div className="title">
                <h4>Inventory List</h4>
              </div>
              <div className="page-content">
                <table>
                  <thead>
                    <tr>
                      <th className="divider">
                        Inventory Name
                        <div className="search-col">
                          <input
                            type="text"
                            name="name_cont"
                            id="name_cont"
                            value={search.name_cont}
                            className="search-btn"
                            placeholder="Search"
                            onChange={(e) => {
                              handleTextChanges(e);
                            }}
                          />
                          <img
                            src={SearchIcon}
                            alt="Search Icon"
                            className="search-icon"
                          />
                        </div>
                      </th>
                      <th className="divider">
                        Inventory Type
                        <div className="search-col">
                          <input
                            type="text"
                            name="inventory_type_name_cont"
                            id="inventory_type_name_cont"
                            value={search.inventory_type_name_cont}
                            className="search-btn"
                            placeholder="Search"
                            onChange={(e) => {
                              handleTextChanges(e);
                            }}
                          />
                        </div>
                      </th>
                      <th className="divider">
                        Rented/Owned
                        <div className="search-col">
                          <select
                            type="date"
                            name="status_eq"
                            id="status_eq"
                            value={search.status_eq}
                            className="search-btn"
                            placeholder="All"
                            onChange={(e) => {
                              handleTextChanges(e);
                            }}
                          >
                            <option value={""}>All</option>
                            <option value={"rented"}>Rented</option>
                            <option value={"owned"}>Owned</option>
                          </select>
                        </div>
                      </th>
                      <th className="divider">
                        Created By
                        <div className="search-col">
                          <input
                            type="text"
                            name="creator_full_name_cont"
                            id="creator_full_name_cont"
                            className="search-btn"
                            placeholder="Search"
                            value={search.creator_full_name_cont}
                            onChange={(e) => {
                              handleTextChanges(e);
                            }}
                          />
                          <img
                            src={SearchIcon}
                            alt="Search Icon"
                            className="search-icon"
                          />
                        </div>
                      </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading &&
                      pageDetails.length > 0 &&
                      pageDetails.map((val, index) => {
                        return (
                          <tr>
                            <td>{val.name}</td>
                            <td>{val.inventory_type_name}</td>
                            <td>{val.status}</td>
                            <td>{val.creator_full_name}</td>
                            <td>
                              <img
                                src={ExternalIcon}
                                alt="edit"
                                onClick={() => {
                                  setShowDetails(val);
                                  setShow(true);
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    <Loader
                      pageDetails={pageDetails}
                      loading={loading}
                    />
                  </tbody>
                </table>
              </div>
            </div>
            <div className="page-limits">
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={limit}
                  onChange={(e) => {
                    setPage(1);
                    setLoading(true);
                    setLimit(e.target.value);
                  }}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                </Select>
              </FormControl>
              <Pagination
                count={pageCount || 1}
                variant="outlined"
                shape="rounded"
                onChange={handleChangePage}
              />
            </div>
          </div>
        </div>
      </div>
      {show && <Show show={show} close={close} details={id} />}
    </div>
  );
};

export default withRouter(ViewSupplier);
