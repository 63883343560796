import React, { useEffect, useState } from "react";
import axios from "../../../config/axiosConfig";
import { NavLink } from "react-router-dom";
import { TableContainer, Paper, Pagination, MenuItem, FormControl, Select, Button } from '@mui/material';
import loader from "../../../assets/images/newloader.gif";
import EmptyTable from "../../../assets/images/empty-assets.svg";
import Application from "../../../config/ApplicationWrapper";
import TemplateQrPopup from "../Popups/TemplateQrCode";

const Template = ({templateId}) => {

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(30);
    const [pageCount, setPageCount] = useState(0);
    const [pageDetails, setPageDetails] = useState([]);
    const [paginationStatus, setPaginationStatus] = useState(false);
    const [name, setSearchByName] = useState();
    const [user, setSearchByUser] = useState();
    const [group, setSearchByGroup] = useState();
    const [qrModel, setQrModel] = useState(false);
    const [qrDetails, setQrDetails] = useState();

    useEffect(() => {
        getPageDetails()
    }, [page, limit])


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getPageDetails()
        }, 2000)

        return () => clearTimeout(delayDebounceFn)
    }, [name, user, group])

    const getPageDetails = async () => {
        try {
            
            window.scrollTo(0, 0);
            let resultObj = await axios.get(`${Application.api_url}/api/template_qr_codes`, {
                params: {
                    page: page,
                    limit: limit,
                    audit_template_name_cont: name,
                    creator_full_name_cont: user,
                    group_name_cont: group,
                    template_id: templateId
                },
            });
            if (resultObj.status == 200) {
                let details = resultObj.data;
                setPageDetails(details ? details.data : [])
                setPageCount(
                    details && details.metadata ? details.metadata.pages : 0
                );
                setPaginationStatus(true)
            }
        } catch (err) {
            console.log("error==>", err);
        }
    };

    const handleChangePage = (event, value) => {
        setPage(value);
    };


    function ChipDesign({ data, type }) {
        let finalData = data.filter(Boolean);
        if (data && data.length >= 3) {
            finalData = finalData.slice(0, 2);
        }
        let objName = type === "user" ? "full_name" : "name";

        return (
            <div>
                {finalData &&
                    finalData.map((obj, index) => (
                        <span className={`badge badge-light responsible-party`}>
                            {obj && obj[objName]}
                        </span>
                    ))}
                {data && data.length >= 3 && (
                    <span className={`badge badge-light responsible-party more-groups`}>
                        + {data.length - 2} More Groups
                    </span>
                )}
            </div>
        );
    }

    const clearFilter = () => {
        setSearchByGroup("");
        setSearchByName("");
        setSearchByUser("");
    }

    const NoAssetsFound = () => (
        <div className="no-assets" style={{ textAlign: "center" }}>
            <img src={EmptyTable} />
            <p>
                <b>No Data</b>
            </p>
        </div>
    );

    const viewQR = async (val) => {
        setQrDetails(val)
        setQrModel(!qrModel)
    }

    return (
        <>
            <div class="card asset-tab-landing">
                <div class="card-body">
                    <div class="tab-content">
                        <div
                            id="active-risk-assessment"
                            class="tab-pane fade in active active-risk-assessment"
                        >
                            <div className="nestedTable pf-table">
                                <div className="fields-setting">
                                    <div className="asset-active-filters">
                                        Active Filters :
                                        <span className="active-filters">
                                            <span>Template :</span>
                                            <span className="selected">{name ? name : "All"}</span>
                                            <span className={name ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setSearchByName("") }}>X</span>
                                        </span>
                                        <span className="active-filters">
                                            <span>Group :</span>
                                            <span className="selected">{group ? group : "All"}</span>
                                            <span className={group ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setSearchByGroup("") }}>X</span>
                                        </span>
                                        <span className="active-filters">
                                            <span>Created By	 :</span>
                                            <span className="selected">{user ? user : "All"}</span>
                                            <span className={user ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setSearchByUser("") }}>X</span>
                                        </span>
                                        <span className="active-filters-clear" onClick={(e) => clearFilter()}>
                                            Clear All
                                        </span>
                                    </div>
                                    <div className="bulk-action">
                                        <Button variant="contained" onClick={(e) => (window.location = "/template")}>Form Templates</Button>
                                    </div>
                                </div>
                                <TableContainer component={Paper}>
                                    <table class="table table-striped">
                                        <thead>
                                            <tr style={{ height: "100px" }}>
                                                <th scope="col" className="risk-assessment-divider">
                                                    <div className="d-flex justify-content-between align-item-center">
                                                        Template
                                                    </div>
                                                    <div class="input-group">
                                                        <input
                                                            type="text"
                                                            class="form-control assessment-name"
                                                            placeholder="Search"
                                                            aria-label="Username"
                                                            id="name"
                                                            aria-describedby="basic-addon1"
                                                            name="name"
                                                            onChange={(e) => {
                                                                setSearchByName(e.target.value)
                                                            }}
                                                            value={name}
                                                        />
                                                        <div class="input-group-addon">
                                                            <span class="input-group-text" id="basic-addon1">
                                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th scope="col" className="risk-assessment-divider">
                                                    Groups
                                                    <div class="input-group">
                                                        <input
                                                            type="text"
                                                            class="form-control assessment-name"
                                                            placeholder="Search"
                                                            aria-label="Username"
                                                            id="group"
                                                            aria-describedby="basic-addon1"
                                                            name="group"
                                                            onChange={(e) => {
                                                                setSearchByGroup(e.target.value)
                                                            }}
                                                            value={group}
                                                        />
                                                        <div class="input-group-addon">
                                                            <span class="input-group-text" id="basic-addon1">
                                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th scope="col" className="risk-assessment-divider">
                                                    Max Forms
                                                </th>
                                                <th scope="col" className="risk-assessment-divider">
                                                    Forms Created
                                                </th>
                                                <th scope="col" className="risk-assessment-divider">
                                                    Created By
                                                    <div class="input-group">
                                                        <input
                                                            type="text"
                                                            class="form-control assessment-name"
                                                            placeholder="Search"
                                                            aria-label="Username"
                                                            id="user"
                                                            aria-describedby="basic-addon1"
                                                            name="user"
                                                            onChange={(e) => {
                                                                setSearchByUser(e.target.value)
                                                            }}
                                                            value={user}
                                                        />
                                                        <div class="input-group-addon">
                                                            <span class="input-group-text" id="basic-addon1">
                                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th scope="col" className="risk-assessment-divider">
                                                    Created At
                                                </th>
                                                <th scope="col" className="table-action">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pageDetails &&
                                                pageDetails.length > 0 &&
                                                pageDetails.map((val, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="alerts-name">
                                                                {val.external_form_template_datum && val.external_form_template_datum.audit_template_name}
                                                            </td>
                                                            <td>
                                                                {val.external_form_template_datum && val.external_form_template_datum.group_name}
                                                            </td>
                                                            <td className="text-center">
                                                                {val.external_form_template_datum && val.external_form_template_datum.max_forms_limit_by_qr_code}
                                                            </td>
                                                            <td className="text-center">
                                                                <a href={`/audits?is_external_form=true&qr_code_id=${val.id}&search=true`}>{val.forms_created}</a>
                                                            </td>
                                                            <td>
                                                                {val.created_by && val.created_by.full_name}
                                                            </td>
                                                            <td>
                                                                {val.created_at}
                                                            </td>
                                                            <td
                                                                className="template-options"
                                                                style={{ textAlign: "center" }}
                                                            >   <i class="fa fa-pencil-square-o" aria-hidden="true" onClick={(e) => { viewQR(val) }} ></i>
                                                                <NavLink
                                                                    to={`/qrcode/${val.id}`}
                                                                    id=""
                                                                    role="button"
                                                                    className="rat-links"
                                                                >
                                                                    <i class="fa fa-eye" aria-hidden="true" style={{ marginLeft: "10px" }}></i>
                                                                </NavLink>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            {pageDetails && pageDetails.length == 0 &&
                                                <tr>
                                                    <td colspan={6}>
                                                        <NoAssetsFound />
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </TableContainer>
                                <div className="page-limits">
                                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                        <Select
                                            labelId="demo-select-small"
                                            id="demo-select-small"
                                            value={limit}
                                            onChange={(e) => {
                                                setPage(1);
                                                setPaginationStatus(false);
                                                setLimit(e.target.value);
                                            }}
                                        >
                                            <MenuItem value={5}>5</MenuItem>
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={20}>20</MenuItem>
                                            <MenuItem value={30}>30</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {paginationStatus && (
                                        <Pagination
                                            count={pageCount}
                                            variant="outlined"
                                            shape="rounded"
                                            onChange={handleChangePage}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {qrDetails && qrDetails.external_form_template_datum && 
                <TemplateQrPopup show={qrModel} qrDetails={qrDetails} closepopup={(e) => { setQrModel(false); getPageDetails() }} />
            }
        </>
    );
}

export default Template;
