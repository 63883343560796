import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Collapse } from 'react-bootstrap';

class SideBar extends Component {

  state = {};

  render() {
    let {
      location,
      backgroundColor,
      enableBackgroundImage,
      backgroundImage
    } = this.props;

    return (
        <aside className="navbar-default sidebar">
            <div className="sidebar-overlay-head">
                <img src="" alt="Logo" />
                <a href="javascript:void(0)" className="sidebar-switch action-sidebar-close">
                    <i className="fa fa-times"></i>
                </a>
            </div>
            <div className="sidebar-logo">
                <img className="logo-default" src="" alt="Logo" width="53"/>
                    <img className="logo-slim" src="" alt="Logo"/>
                    </div>
                    <div className="sidebar-content">

                    </div>
                </aside>

    )
  }
}

const mapStateToProps = state => ({
  enableBackgroundImage: state.ThemeOptions.enableBackgroundImage,
  backgroundColor: state.ThemeOptions.backgroundColor,
});

export default withRouter(
  connect(mapStateToProps)(SideBar)
);