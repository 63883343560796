import React, { Component } from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';


function trClassFormat(rowData, rIndex) {
    return rIndex % 2 === 0 ? '' : '';   //tr-function-example
}

function auditLink(rowData, rIndex) {
    // debugger;
    return <a href={rowData} target="_blank">PDF Link</a>;
}

function sortDates(rowData, rIndex) {
    return rIndex.started
}

function sortCompletedDates(rowData, rIndex) {
    return rIndex.completed
}


class AuditDetailData extends Component {
    onToggleDropDown = (toggleDropDown) => {
    // do your stuff here
    console.log('toggle dropdown');
    toggleDropDown();
}


    renderSizePerPageDropDown = (props) => {
        return (
            <SizePerPageDropDown
            open={ props.open }
            className='my-size-per-page'
            btnContextual='btn-warning'
            variation='dropup'
            onClick={ () => this.onToggleDropDown(props.toggleDropDown) }/>
        );
    }

    renderShowsTotal(start, to, total) {
        return ( <span className="pagination-total">{start} -&nbsp;{to} of&nbsp;{total} &nbsp; Records</span>);
    }

    render() {
    const options = {
        sizePerPageDropDown: this.renderSizePerPageDropDown,
        sizePerPageList: [ {
            text: '5', value: 5
        }, {
            text: '10', value: 10
        }, {
            text: 'All', value: this.props.data.length
        } ],
        prePage: 'Prev', // Previous page button text
        nextPage: 'Next', // Next page button text
        firstPage: 'First', // First page button text
        lastPage: 'Last', // Last page button text
        prePageTitle: 'Go to previous', // Previous page button title
        nextPageTitle: 'Go to next', // Next page button title
        firstPageTitle: 'Go to first', // First page button title
        lastPageTitle: 'Go to Last', // Last page button title
        paginationShowsTotal: this.renderShowsTotal
    };

    // debugger;
    return (
        <BootstrapTable data={this.props.data} trClassName={ trClassFormat }  options={ options } pagination exportCSV>
            <TableHeaderColumn isKey={ true } dataField='id' hidden>ID </TableHeaderColumn>
            <TableHeaderColumn dataField='sort_started' width='10%'  dataSort={ true } dataFormat = {sortDates}>Started</TableHeaderColumn>
            <TableHeaderColumn dataField='sort_completed' width='10%' dataSort={ true } dataFormat = {sortCompletedDates}>Completed</TableHeaderColumn>
            <TableHeaderColumn dataField='owner' width='20%' filter={ { type: 'TextFilter'} }>Username</TableHeaderColumn>
            <TableHeaderColumn dataField='owner_name' width='20%' filter={ { type: 'TextFilter'} }>Owner</TableHeaderColumn>
            <TableHeaderColumn dataField='group_name' width='20%' dataFormat={group_name}>Group</TableHeaderColumn>
            <TableHeaderColumn dataField='pdflink' width='20%' dataFormat={auditLink}>PDF Link</TableHeaderColumn>
            <TableHeaderColumn dataField='name' width='20%' filter={ { type: 'TextFilter'} }>Name</TableHeaderColumn>
        </BootstrapTable>
        );
}
}

export default AuditDetailData;