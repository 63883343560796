import React, { useState } from "react";
import { Button, Modal, Typography, Box, Grid } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import useFetch from "../../../shared/components/userFetch";
import FormWrapper from "../../../shared/components/formWrapper";
import { ShowNotification, showToast } from "../../../shared/components/showNotification";
import DualListbox from "../../../shared/components/dualListBox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  height: "80%",
  backgroundColor: "#fff",
  boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.30)",
  borderRadius: "12px",
  p: 4,
};

const SharedForms = ({
  open,
  setOpen,
  initialValues = null,
  mutate,
  setFilterState,
}) => {
  const isEdit = !!initialValues;
  const { group_members_managers = []} = initialValues;

  const defaultValues = isEdit
    ? {
        group_members: initialValues.assignee_members
      }
    : {
        group_members: []
      };
  const {
    control,
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues,
  });
  const handleClose = () => setOpen(false);

  const onSubmit = (data) => {
    const payload = {
      group: {
        group_members: [...new Set(data.group_members.map((item) => item.value))],
      },
    };    

    if (isEdit) {
      payload.id = initialValues.id;
    }
    mutate(payload, {
      onSuccess: () => {
        showToast({ message: `Shared Forms ${
            isEdit ? "Updated" : "Created"
          } Successfully!` });

        setTimeout(() => {
          setFilterState({ page: 1, limit: 20 });
          handleClose(); // Close the modal after submission
        }, 0);
      },
    });
  };

  return (
    <>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <FormWrapper>
          <Box sx={style}>
            <Typography id="modal-title" variant="h6" component="h2">
              {isEdit ? "Update" : "Create"} Assignee
            </Typography>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{ height: "calc(100% - 50px)" }}
            >
              <Box
                sx={{ mt: 2 }}
                style={{ overflowY: "scroll", height: "calc(100% - 40px)" }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Controller
                      name="group_members"
                      control={control}
                      render={({ field: { value = [] } }) => (
                        <DualListbox
                          title={"Member(s)"}
                          control={control}
                          setValue={setValue}
                          name="group_members"
                          leftData={group_members_managers.filter((tr) =>
                            value.every((val) => val.value !== tr.value)
                          )}
                          rightData={value}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box
                mt={2}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <div />
                <div>
                  <Button
                    style={{
                      textTransform: "none",
                      backgroundColor: "#ECECEC",
                      color: "#5C5C5C",
                      marginRight: "10px",
                      outline: "none",
                    }}
                    onClick={() => setOpen(false)}
                    sx={{ mr: 1 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{
                      textTransform: "none",
                      backgroundColor: "#407797",
                      color: "#fff",
                      outline: "none",
                    }}
                    variant="contained"
                    htmlType="button"
                    type="submit"
                    color="primary"
                  >
                    Submit
                  </Button>
                </div>
              </Box>
            </form>
          </Box>
        </FormWrapper>
      </Modal>
    </>
  );
};

export default SharedForms;
