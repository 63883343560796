import React,{useEffect} from "react";
import { Button } from "react-bootstrap";
import Help from "../../assets/images/Help.svg";
import Inquiry from "../../assets/images/Inquiry.svg";
import Demo from "../../assets/images/Demo.svg";
import { Link } from "react-router-dom";



export default function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="contactUs-page">
      <div className="banner-section">
        <div className="banner-content">
          <div className="banner-inside-content">
            <h1>
              <b>Contact Us</b>
            </h1>
            <p>
              Let us show you how the SafetyLoop health and safety software
              platform can let you control your health and safety program. You
              will have a LIVE view of your program. Try our System today
              risk-free today with our 30 day trial.
            </p>
          </div>
        </div>
      </div>
      <div className="contact-us-card">
        <div className="home-container">
          <div className="d-flex">
            {/* <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <div className="contact-card-content">
                <img src={Help} alt="..." />
                <h3>
                  <b>Need Help ?</b>
                </h3>
                <p>
                  Learn how to use Safetyloop and contact our support team if
                  needed.
                </p>

                <Link to="/help">
                <Button className="card-btn">
                  Go To Help Center
                </Button>
                </Link>
              </div>
            </div> */}
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-md-6">
              <div className="contact-card-content">
                <img src={Demo} alt="..." />
                <h3>
                  <b>Get a personal demo</b>
                </h3>
                <p>
                  See Safetyloop in action. Request a demo and we'll be in touch
                  shortly.
                </p>
              <a href="https://cxtzl04.na1.hs-sales-engage.com/Ctc/LY+23284/cXtZl04/Jl22-6qcW7lCdLW6lZ3p3V8Lpkb3WF7Z2W8_8h3c31qxyfN60bD2lc6pVRW67RVDV8NXr0YW1s1m2n3DbFP_N95Q7FVxndjdW2x4LG87jftWRW5tF2rT7rJM5sW1L3k1c3bzWm1VwTQ138Lw5qjW4scXLF3vP7clW3PRdwc5cyRLJW8CcJ484VV_d9W8tdyVf5gF5k-W3Zvk8c8FncpkW5QKM2q7vpk79W8tCY3R6dwxsjW8NyZkZ3JJznsN3vnLnRTJ89sV2cqLF5hChJ3W5Wz_cK4tgyq_N8FxPjY_5765W73hhnF5Hbg-gVTm4Q-6r1kXLf1mVlDb04" target="_blank">
                <Button bsStyle="warning" className="demo-btn" >Request a Demo</Button>
              </a>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-md-6">
              <div className="contact-card-content">
                <img src={Inquiry} alt="..." />
                <h3>
                  <b>Make a sales inquiry</b>
                </h3>
                <p>
                  Get to know how to upgrade or customize Safetyloop for your
                  needs.
                </p>
              <a href="https://cxtzl04.na1.hs-sales-engage.com/Ctc/LY+23284/cXtZl04/Jl22-6qcW7lCdLW6lZ3p3V8Lpkb3WF7Z2W8_8h3c31qxyfN60bD2lc6pVRW67RVDV8NXr0YW1s1m2n3DbFP_N95Q7FVxndjdW2x4LG87jftWRW5tF2rT7rJM5sW1L3k1c3bzWm1VwTQ138Lw5qjW4scXLF3vP7clW3PRdwc5cyRLJW8CcJ484VV_d9W8tdyVf5gF5k-W3Zvk8c8FncpkW5QKM2q7vpk79W8tCY3R6dwxsjW8NyZkZ3JJznsN3vnLnRTJ89sV2cqLF5hChJ3W5Wz_cK4tgyq_N8FxPjY_5765W73hhnF5Hbg-gVTm4Q-6r1kXLf1mVlDb04" target="_blank">
                <Button bsStyle="warning" className="demo-btn" >Contact Sales</Button>
              </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-map-section">
        <div className="home-container">
          <div className="d-flex">
            <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8">
              <div className="contant-us-map">
                <iframe
                  
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2923.4031627167883!2d-79.25265442246808!3d42.88543997114898!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d331215aa92483%3A0x29f9a8bb8ff207c0!2s216%20West%20St%2C%20Port%20Colborne%2C%20ON%20L3K%204E3%2C%20Canada!5e0!3m2!1sen!2sus!4v1719403244476!5m2!1sen!2sus"
                  width="100%"
                  height="400"
                ></iframe>
              </div>
              <div className="d-flex contact-map-location">
                <div className="col-md-4 col-sm-4">
                  <h4 style={{ color: "#407797" }}>
                    <b>Headquarters</b>
                  </h4>
                  <address>
                    216 West St, Port Colborne, ON L3K 4E3, Canada
                  </address>
                </div>
                <div className="col-md-4 col-sm-4">
                  <h4 style={{ color: "#407797" }}>
                    <b>Phone</b>
                  </h4>
                  <address>833-444-9876</address>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 contact-us-form-section">
              <div className="contact-us-form">
                <form className="m-t-3" method="get" action="/send-hubspot-create">
                  <h3>
                    <b>Get in contact with us today!</b>
                  </h3>
                  <div className="form-group">
                    <label>Company Name</label>
                    <input
                      className="form-control"
                      id="email"
                      name="company_name"
                      maxLength="50"
                      placeholder="Company Name"
                    />
                  </div>
                  <div className="form-group">
                    <label>First Name</label>
                    <input
                      className="form-control"
                      id="email"
                      name="fname"
                      maxLength="50"
                      placeholder="First Name"
                    />
                  </div>
                  <div className="form-group">
                    <label>Last Name</label>
                    <input
                      className="form-control"
                      id="email"
                      name="lname"
                      maxLength="50"
                      placeholder="Last Name"
                    />
                  </div>
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      name="email"
                      className="form-control"
                      id="email"
                      maxLength="50"
                      placeholder="Email"
                    />
                  </div>
                  <div className="form-group">
                    
                  </div>
                    <button className="btn m-b-2 btn-primary" id="user-login">
                      Submit
                    </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
