import React, { useEffect, useState } from "react";
import axios from "../../../config/axiosConfig";
import { TableContainer, Paper, Pagination, MenuItem, FormControl, Select, Button, Checkbox } from '@mui/material';
import { toast } from "react-toastify";
import loader from "../../../assets/images/newloader.gif";
import EmptyTable from "../../../assets/images/empty-assets.svg";
import Application from "../../../config/ApplicationWrapper";
import CreateQr from "../Popups/CreateQr";
import ViewQr from "../Popups/GroupQrCode";

const Group = () => {

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [pageDetails, setPageDetails] = useState([]);
    const [paginationStatus, setPaginationStatus] = useState(false);
    const [name, setSearchByName] = useState();
    const [group, setSearchByGroup] = useState();
    const [selected, setSelected] = useState([]);
    const [createModel, setCreateModel] = useState(false);
    const [qrModel, setQrModel] = useState(false);
    const [qrDetails, setQrDetails] = useState();

    useEffect(() => {
        getPageDetails()
    }, [page, limit])


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getPageDetails()
        }, 2000)

        return () => clearTimeout(delayDebounceFn)
    }, [name, group])

    const getPageDetails = async () => {
        try {
            window.scrollTo(0, 0);
            let resultObj = await axios.get(`${Application.api_url}/api/group_qr_codes`, {
                params: {
                    page: page,
                    limit: limit,
                    creator_full_name_cont: name,
                    groups_name_cont: group,
                },
            });
            if (resultObj.status == 200) {
                let details = resultObj.data;
                setPageDetails(details ? details.data : [])
                setPageCount(
                    details && details.metadata ? details.metadata.pages : 0
                );
                setPaginationStatus(true)
            }
        } catch (err) {
            console.log("error==>", err);
        }
    };

    const handleChangePage = (event, value) => {
        setPage(value);
    };


    function ChipDesign({ data, type }) {
        let finalData = data.filter(Boolean);
        if (data && data.length >= 3) {
            finalData = finalData.slice(0, 2);
        }

        return (
            <div>
                {finalData &&
                    finalData.map((obj, index) => (
                        <span className={`badge badge-light responsible-party`}>
                            {obj.name}
                        </span>
                    ))}
                {data && data.length >= 3 && (
                    <span className={`badge badge-light responsible-party more-groups`}>
                        + {data.length - 2} More Groups
                    </span>
                )}
            </div>
        );
    }

    const clearFilter = () => {
        setSearchByGroup("");
        setSearchByName("");
        setPage(1);
    }

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const create = async () => {
        setCreateModel(!createModel)
    }

    const viewQR = async (val) => {
        setQrDetails(val)
        setQrModel(!qrModel)
    }

    const deleteQR = async () => {
        const formData = new FormData(); 
        [...selected].forEach(obj => {
            formData.append("ids[]", obj);
        });

        const result = await axios({
            method: "DELETE",
            url: `${Application.api_url}/api/group_qr_codes/bulk_remove_qr_codes`,
            data: formData,
        });

        if (result.status == 200 && result.data.success === true) {
            toast.success("Archive Successfully")
            setSelected([])
            setPage(1);
            getPageDetails()
        }
    }

    const NoAssetsFound = () => (
        <div className="no-assets" style={{ textAlign: "center" }}>
            <img src={EmptyTable} />
            <p>
                <b>No Data</b>
            </p>
        </div>
    );

    return (
        <>
            <div class="card asset-tab-landing">
                <div class="card-body">
                    <div class="tab-content">
                        <div
                            id="active-risk-assessment"
                            class="tab-pane fade in active active-risk-assessment"
                        >
                            <div className="nestedTable pf-table">
                                <div className="fields-setting">
                                    <div className="asset-active-filters">
                                        Active Filters :
                                        <span className="active-filters">
                                            <span>Assigned Groups	 :</span>
                                            <span className="selected">{group ? group : "All"}</span>
                                            <span className={group ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setPage(1); setSearchByGroup("") }}>X</span>
                                        </span>
                                        <span className="active-filters">
                                            <span>Created By :</span>
                                            <span className="selected">{name ? name : "All"}</span>
                                            <span className={name ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setPage(1); setSearchByName("") }}>X</span>
                                        </span>
                                        <span className="active-filters-clear" onClick={(e) => clearFilter()}>
                                            Clear All
                                        </span>
                                    </div>
                                    <div className="bulk-action">
                                        <Button variant="contained" onClick={(e) => { create() }}>New QR Code</Button>
                                        {selected.length > 0 &&
                                            <Button variant="contained" color="error" className="qr-delete-btn" onClick={(e) => { deleteQR(selected) }}>Delete</Button>
                                        }
                                    </div>
                                </div>
                                <TableContainer component={Paper}>
                                    <table class="table table-striped">
                                        <thead>
                                            <tr style={{ height: "100px" }}>
                                                <th scope="col" className="risk-assessment-divider">

                                                </th>
                                                <th scope="col" className="risk-assessment-divider">
                                                    <div
                                                        className="d-flex justify-content-between align-item-center"
                                                    >
                                                        Assigned Groups
                                                    </div>
                                                    <div class="input-group">
                                                        <input
                                                            type="text"
                                                            class="form-control assessment-name"
                                                            placeholder="Search"
                                                            aria-label="Username"
                                                            id="group"
                                                            aria-describedby="basic-addon1"
                                                            name="group"
                                                            onChange={(e) => {
                                                                setPage(1);
                                                                setSearchByGroup(e.target.value)
                                                            }}
                                                            value={group}
                                                        />
                                                        <div class="input-group-addon">
                                                            <span class="input-group-text" id="basic-addon1">
                                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th scope="col" className="risk-assessment-divider">
                                                    Created On
                                                </th>
                                                <th scope="col" className="risk-assessment-divider">
                                                    <div
                                                        className="d-flex justify-content-between align-item-center"
                                                    >
                                                        Created By
                                                    </div>
                                                    <div class="input-group">
                                                        <input
                                                            type="text"
                                                            class="form-control assessment-name"
                                                            placeholder="Search"
                                                            aria-label="Username"
                                                            id="name"
                                                            aria-describedby="basic-addon1"
                                                            name="name"
                                                            onChange={(e) => {
                                                                setPage(1);
                                                                setSearchByName(e.target.value)
                                                            }}
                                                            value={name}
                                                        />
                                                        <div class="input-group-addon">
                                                            <span class="input-group-text" id="basic-addon1">
                                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th scope="col" className="table-action">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paginationStatus && pageDetails &&
                                                pageDetails.length > 0 &&
                                                pageDetails.map((val, index) => {
                                                    const isItemSelected = isSelected(val.id);
                                                    const labelId = `enhanced-table-checkbox-${index}`;
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={isItemSelected}
                                                                    onClick={(event) => handleClick(event, val.id)}
                                                                    inputProps={{
                                                                        'aria-labelledby': labelId,
                                                                    }}
                                                                />
                                                            </td>
                                                            <td className="alerts-name">
                                                                <ChipDesign data={val.groups} type="user" />
                                                            </td>
                                                            <td>
                                                                {val.created_at}
                                                            </td>
                                                            <td>
                                                                <p className="action-by">{val.created_by ? val.created_by.last_name + " " + val.created_by.first_name : "-"}</p>
                                                            </td>
                                                            <td
                                                                className="template-options"
                                                                style={{ textAlign: "center" }}
                                                                onClick={(e) => { viewQR(val) }}
                                                            >
                                                                <i class="fa fa-eye" aria-hidden="true"></i>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            {pageDetails && pageDetails.length == 0 &&
                                                <tr>
                                                    <td colspan={6}>
                                                        {
                                                            paginationStatus === false ? (
                                                                <div className="new-loader">
                                                                    <img src={loader} alt="" />
                                                                </div>
                                                            ) : (
                                                                <NoAssetsFound />
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </TableContainer>
                                <div className="page-limits">
                                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                        <Select
                                            labelId="demo-select-small"
                                            id="demo-select-small"
                                            value={limit}
                                            onChange={(e) => {
                                                setPage(1);
                                                setPaginationStatus(false);
                                                setLimit(e.target.value);
                                            }}
                                        >
                                            <MenuItem value={5}>5</MenuItem>
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={20}>20</MenuItem>
                                            <MenuItem value={30}>30</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {paginationStatus && (
                                        <Pagination
                                            count={pageCount}
                                            variant="outlined"
                                            shape="rounded"
                                            onChange={handleChangePage}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CreateQr show={createModel} closepopup={(e) => { setCreateModel(false);  getPageDetails() }} />
            {qrDetails &&
                <ViewQr show={qrModel} qrDetails={qrDetails} closepopup={(e) => { setQrModel(false) }} />
            }
        </>
    );
}

export default Group;
