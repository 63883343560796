import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Typography from "@mui/material/Typography";
import { useReactToPrint } from 'react-to-print';
import { ComponentToPrint } from './ComponentToPrint';
import Application from "../../../../config/ApplicationWrapper";
import axios from "../../../../config/axiosConfig";

export default function QrPopup(props) {
  const { show, closepopup, details, parentId } = props;
  const [qrDetails, setQrDetails] = useState(null); 
  const [isLoading, setIsLoading] = useState(false); 

  const onhandleStatusClose = () => {
    closepopup();
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (show) {
      setIsLoading(true); 
      getPageDetails();
    } else {
      setQrDetails(null); 
    }
  }, [show])

  const getPageDetails = async () => {
    try {
      let resultObj = await axios.get(`${Application.api_url}/api/v2/internal_subs/${parentId}/internal_employees/${details}`);
      if (resultObj.status === 200) {
        let data = resultObj.data;
        setQrDetails(data && data.data);
      }
    } catch (err) {
      console.log("error==>", err);
      setQrDetails({}); 
    } finally {
      setIsLoading(false);
    }
  }

  const modalContent = () => {
    if (isLoading) {
      return (
        <Typography>Loading...</Typography>
      );
    } else if (qrDetails && qrDetails.qr_url) {
      return (
        <>
          <div className="qr-info-item">
            <div className="qr-asset-info">
              <Typography variant="body2" gutterBottom className="qr-info-title">
                QR Information
              </Typography>
              <div className="qr-details">
                <Typography gutterBottom variant="subtitle1" component="div" className="qr-created-by">
                  Name:
                  <label className="status-label">
                    {`${qrDetails.first_name} ${qrDetails.last_name}`}
                  </label>
                </Typography>
                <Typography gutterBottom variant="subtitle1" component="div" className="qr-created-by">
                  Email:
                  <label className="status-label">
                    {qrDetails.email}
                  </label>
                </Typography>
              </div>
            </div>
          </div>
          <div id="qr-scan-img">
            <div style={{ textAlign: "center" }}>
              <img src={qrDetails.qr_url} alt="QR Code" />
              <p style={{ color: "black", fontSize: "16px", textAlign: "center" }}>
                QR Link: {qrDetails.invite_link}
              </p>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <Typography variant="subtitle1" style={{ textAlign: "center", marginTop: "20px" }}>
          Please reactivate the user to view the valid QR Code.
        </Typography>
      );
    }
  };

  return (
    <Modal
      show={show}
      onHide={onhandleStatusClose}
      className="qr-modal-wrap add-task-modal signature-popup"
      size={(isLoading || !qrDetails) ? 'sm' : undefined}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <i className="fa fa-qrcode" style={{ fontSize: "19px", marginRight: "5px" }}></i>
          <b>QR Code</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {modalContent()}
      </Modal.Body>
      <Modal.Footer>
        {isLoading || !qrDetails ? null : qrDetails.qr_url ? (
          <button className="btn btn-secondary" onClick={handlePrint}>
            <span className="btn-text">Print</span>
          </button>
        ) : (
          <button className="btn btn-secondary" onClick={onhandleStatusClose}>
            <span className="btn-text">Close</span>
          </button>
        )}
      </Modal.Footer>
      {!isLoading && qrDetails && qrDetails.qr_url && (
        <ComponentToPrint ref={componentRef} pageData={qrDetails} />
      )}
    </Modal>
  );
}
