import React from "react";
import { NavLink } from "react-router-dom"; 


const InteralSubSidebar = () => {
    return (
        <div className="wrapper internal-sub-sidebar">
            <div className="sidebar">
                <div className="sb-item-list">
                    <a href="/inter_sub_new_forms">
                        <div className="sb-item">
                            <i className="sb-icon fa fa-file-text"></i>
                            <span className="sb-text">New Forms</span>
                        </div>
                    </a>
                    <a href="/inter_sub_forms">
                        <div className="sb-item">
                            <i className="sb-icon fa fa-file-text"></i>
                            <span className="sb-text">Forms</span>
                        </div>
                    </a>
                    {/* <a href="/inter_sub_docs">
                        <div className="sb-item">
                            <i className="sb-icon fa fa-check-square-o"></i>
                            <span className="sb-text">Documents</span>
                        </div>
                    </a> */}
                    <a href="/inter_sub_trainings">
                        <div className="sb-item">
                            <i className="sb-icon fa fa-graduation-cap"></i>
                            <span className="sb-text">Trainings</span>
                        </div>
                    </a>
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className="btn-toggle-sidebar sb-item">
                        <i className="sb-icon fa fa-angle-double-left"></i>
                        <span className="sb-text"></span>
                        <i className="sb-icon fa fa-angle-double-right"></i>
                    </div>

                </div>
            </div>
            <div className="main"></div>
        </div>
    );
}

export default InteralSubSidebar;