import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { connect } from 'react-redux';
import { setValue } from "../../reducers/common";
import Cookies from "js-cookie";

const HeaderLogo = ({setValue, pageData}) => {
  const [pageDetails, setPageDetails] = useState("");
  const currentPageDetails = JSON.parse(Cookies.get("pageData") || '{}');

  useEffect(() => {
    async function fetchData() {
      const result = await axios.get("/users/check_for_user");
      if (result && result.data) {
        setPageDetails(result.data);
      }
    }
    fetchData().then((r) => r);
  }, []);

  const handleToggle = () => {
    setValue({isToggleEnable: !pageData.isToggleEnable }); // Dispatch the setValue action
    document.cookie = `collapse=${!pageData.isToggleEnable}`;
    // document.querySelectorAll('.sb-item.active').forEach((el) => {
    //   el.classList.remove('active'); // Remove the 'active' class from each
    // });
  };

  return (
    <>
      <div
        className="navbar-header"
        style={{ display: "block", padding: "14px 0px" }}
      >
        {/* <Link to="/analytics-dashboard"><img alt="SafetyLoop Main" style={{ position: "absolute", width: "80px" }} src={"/New-logo.svg"} /></Link> */}
        <div className="d-flex align-items-center">
          <div className="d-flex back-arrow" role="button" style={{ background: '#ECECEC', borderRadius: '4px', padding: '5px 10px', marginRight: '5px' }} onClick={handleToggle}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.04718 4.58333L14.1667 4.58333"
                stroke="#3B3B3C"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.04718 15.4166L14.1667 15.4166"
                stroke="#3B3B3C"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.33337 10L10.8334 10"
                stroke="#3B3B3C"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.5 13.3333L14.1667 10.0005L17.5 6.66659"
                stroke="#3B3B3C"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          {/* <div className="select-menu d-flex align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g clip-path="url(#clip0_9256_39526)">
                <path
                  d="M12 15C13.1046 15 14 14.1046 14 13C14 11.8954 13.1046 11 12 11C10.8954 11 10 11.8954 10 13C10 14.1046 10.8954 15 12 15Z"
                  stroke="#5C5C5C"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.45 11.55L15.5 9.5"
                  stroke="#5C5C5C"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.4 20C4.93815 18.8381 3.87391 17.2503 3.35478 15.4565C2.83564 13.6627 2.88732 11.7519 3.50265 9.98884C4.11797 8.22576 5.26647 6.69774 6.78899 5.61653C8.3115 4.53532 10.1326 3.95447 12 3.95447C13.8674 3.95447 15.6885 4.53532 17.211 5.61653C18.7335 6.69774 19.882 8.22576 20.4974 9.98884C21.1127 11.7519 21.1644 13.6627 20.6452 15.4565C20.1261 17.2503 19.0619 18.8381 17.6 20H6.4Z"
                  stroke="#5C5C5C"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_9256_39526">
                  <rect width="24" height="24" rx="4" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <b className="px-1" style={{ fontSize: '20px', color: '#5c5c5c' }}>{ currentPageDetails.parent ? currentPageDetails.parent : ''}</b>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clip-path="url(#clip0_9256_39529)">
              <path
                d="M9 18L15 12L9 6"
                stroke="#5C5C5C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_9256_39529">
                <rect width="24" height="24" rx="4" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <b style={{ fontSize: '20px', color: '#080808', fontWeight: 600 }} className="px-1">{currentPageDetails.child  ? currentPageDetails.child :  ''}</b> */}
        </div>
        <div className="offline-status">
          <span>
            <i
              aria-hidden="true"
              className="fa fa-wifi"
              style={{ marginRight: "15px" }}
            ></i>
            You are currently offline
            <a href="." style={{ marginLeft: "10px" }}>
              Refresh
            </a>
          </span>
        </div>
      </div>
      {/* <div className='nav-company'>
        <img src={pageDetails && pageDetails.company_url ? pageDetails.company_url : '/Company.svg'} alt="company" />
        <p>{pageDetails && pageDetails.company && pageDetails.company.name}</p>
      </div> */}
      <div className="nav-select-arrow">
        <img alt="arrow" src={"ArrowDown.svg"} />
      </div>
      <div className="nav-group">
        <img src="Nav-Group.svg" alt="group" />
        <p className="nav-group-option">
          <select name="test" id="test" className="nav-group-select">
            <option value="">Select</option>
          </select>
        </p>
      </div>
    </>
  );
};

// export default HeaderLogo;
const mapStateToProps = (state) => {
  return {
    pageData: state.CommonReducer.pageData
  };
};

// Map dispatch to props
const mapDispatchToProps = (dispatch) => ({
  setValue: (value) => dispatch(setValue(value)) // Dispatch the setValue action
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLogo);
