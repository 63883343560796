import React, { Fragment } from 'react'

const SharedDocuments = ({documents, clientid, item}) => {
    const renderDocsData = documents.map((form, index) =>
    {
        return (
            <li>
                <a href={form.url} target="_blank">
                    {form.name}
                </a>
            </li>
        )
    });

    const renderContent = (documents) => {
        console.log(documents);
        let content;
        if (documents.length == 0){
            content = <h4 className='align-center'> No Docs shared </h4>
        }else{
            content = <ul>
                {renderDocsData}
            </ul>
        }
        return content;
    }

    const renderPublicDocuments = (clientId, item) => {
        let content;

        if (item.id == clientId){
            content = <iframe src={`${window.location.origin}/company_documents/${clientid}/frame`} style={{width:"100%", height:"600px"}} frameBorder="0" data-clientid={clientid}/>

        }else{
            content = <iframe src='' style={{width:"100%", height:"600px"}} frameBorder="0" data-clientid={clientid}/>

        }
        return content;
    }

    return (
        <div className="public-documents shared-files" clientId={clientid} item={item}>
            <h3>
                <i className="fa fa-file-pdf-o"></i>
                Public Documents
            </h3>
            {renderPublicDocuments(clientid, item)}
            {/*<iframe src={`https://www.safetyloop.com/company_documents/${clientid}/frame`} style={{width:"100%", height:"600px"}} frameBorder="0" data-clientid={clientid}/>*/}
            {/* {renderContent(documents)} */}
        </div>
    )
}

export default SharedDocuments;
