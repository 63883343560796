import React from 'react';

const OrderSummary = ({order}) => {
  return (
    <div className="order-summary" style={{flexDirection: 'column'}}>
      <div className="order-info-item">
        <strong>Order Id:</strong> #{order.id}
      </div>
      <div className="row">
        <div className="col col-md-6">
          <div className="order-info-item">
            <strong>Order Date:</strong> {order.created_at}
          </div>
          <div className="order-info-item">
            <strong>Requested
              By:</strong> {order.created_by && order.created_by.full_name || "N/A"}
          </div>
        </div>
        <div className="col col-md-6">
          <div className="order-info-item">
            <strong>Comments: </strong>{order.note_content || "N/A"}
          </div>
          <div className="order-info-item">
            <strong>Attachment: </strong>
            {order.attachment && order.attachment.length > 0 ? (
              order.attachment.map((file, index) => (
                <a key={index} href={file.url}>Attachment {index + 1}</a>
              ))
            ) : (
              "No attachments"
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderSummary;