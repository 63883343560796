import React, {useState} from 'react'
import {Form, Modal, FormGroup, FormControl, Button} from "react-bootstrap";
import {requestForBackorder} from "../utils";

const BackorderModal = ({show, close, item, fetchDetails}) => {
  const [qtyApproved, setQtyApproved] = useState(item.place_order_quantity);
  const [reason, setReason] = useState('');

  const handleApprove = async () => {
    await requestForBackorder(item.identifier, {
      place_order_quantity: qtyApproved,
      notes_attributes: [{
        content: `Backorder: ${reason}`
      }]
    })
    await fetchDetails();
    close();
  }

  return (
    <Modal
      show={show}
      onHide={(_) => close()}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Backorder
        </Modal.Title>
        <Modal.Body>
          <Form>
            <div className="row">
              <div className={"col-md-4"}>Order Item Name:</div>
              <div className={"col-md-8"}>{item.inventory_item.name}</div>
            </div>

            <div className="row">
              <div className={"col-md-4"}>QTY Requested:</div>
              <div className={"col-md-8"}>{item.quantity}</div>
            </div>

            <FormGroup>
              <div className="row">
                <div className={"col-md-4"}>QTY Approved:</div>
                <div className={"col-md-8"}>
                  <FormControl
                    type="number"
                    name="qty_approved"
                    onChange={(e) => setQtyApproved(e.target.value)}
                    style={{width: '20%'}}
                    max={item.quantity}
                    min={1}
                    required
                  ></FormControl>
                </div>

              </div>
            </FormGroup>

            <FormGroup>
              <label htmlFor="reason">Reason:</label>
              <FormControl
                componentClass="textarea"
                name="reason"
                onChange={(e) => setReason(e.target.value)}
                required
              ></FormControl>
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="btn-secondary" onClick={close}>
            Cancel
          </Button>

          <Button variant="primary" className="btn-primary" onClick={handleApprove}>
            Approve
          </Button>
        </Modal.Footer>
      </Modal.Header>
    </Modal>
  )
}

export default BackorderModal;