import React from 'react';
import { Route, Router } from 'react-router-dom';
import { connect } from 'react-redux';
import cx from 'classnames';
import { setMobileNavVisibility } from '../../reducers/Layout';
import { withRouter } from 'react-router-dom';
import '../../assets/styles/themes.scss';
import PropTypes from 'prop-types';


const contentStyle = {
    backgroundColor: "#282828"
};



class Profile extends React.Component {
    constructor(props) {
    super(props);
    var user_data = $("#app").data("user_data")
    this.state = {
        loginUnsuccessful: false,
        email: user_data.email,
        first_name: user_data.first_name,
        last_name: user_data.last_name
    }

    // This binding is necessary to make `this` work in the callback
    this.handleChange = this.handleChange.bind(this);
    this.handleProfileUpdate = this.handleProfileUpdate.bind(this);
    this.updateLoginError = this.updateLoginError.bind(this);
    this.changePage = this.changePage.bind(this);
}

    handleChange = (event) => {
        var state = {};
        state[event.target.name] =  $.trim(event.target.value);
        this.setState(state);
    }


    changePage(newPage) {
    this.setState({
        page: newPage
    });
}

    updateLoginError() {
    this.setState({
        loginUnsuccessful: true
    });
}

    handleProfileUpdate(e) {
    e.preventDefault();
    var that = this
    var userInfo = {
        user: {
            email: this.state.email,
            first_name: this.state.first_name,
            last_name: this.state.last_name
        }
    }
    $.ajaxSetup({
        headers:
        { "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr('content') }
    });
    $.ajax({
        type: "GET",
        url: "/users/profile_update",
        dataType: "json",
        data: userInfo,
        error: function (error) {
            that.updateEdit("false");
        },
        success: function (res) {
            alert("profile successfully updated")
            that.updateEdit("true");
        },
    });
}

    updateEdit(string) {
    this.setState({
        editSuccessful: string
    });
}

    updateDeleteError() {
    this.setState({
        deleteUnsuccessful: true
    });
}

    render() {
    const {email, first_name, last_name} = this.state
    return (
        <div className="content">
            <div className="sub-navbar sub-navbar__header">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="page-header m-t-0">
                                <h3 className="m-t-0">Profile Edit</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sub-navbar sub-navbar__header-breadcrumbs">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 sub-navbar-column">
                            <div className="sub-navbar-header">
                                <h3>Profile Edit</h3>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">

                <div className="media m-b-3">
                    <div className="media-left media-middle p-r-2">
                        <div className="avatar">
                            <img className="img-circle m-t-1 m-b-2" src="https://s3.amazonaws.com/uifaces/faces/twitter/joaoedumedeiros/128.jpg" alt="Avatar"/>
                        </div>
                    </div>
                    <div className="media-body">
                        <h3 className="f-w-300 m-b-0 m-t-1"><a href="profile-details.html"><span>{first_name}</span></a> <span className="text-muted">
                            <span className="m-r-1 m-l-1">/</span></span> Profile Edit</h3>

                    </div>
                </div>

                <div className="row">

                    <div className="col-lg-12">

                        <div className="panel panel-default   b-a-2 no-bg b-gray-dark" style={contentStyle}>
                            <div className="panel-heading">
                                <h4 className="panel-title">Edit Profile</h4>
                            </div>

                            <div className="panel-body">


                                <form className="form-horizontal">

                                    <div className="form-group">
                                        <label className="col-sm-3 control-label"><span className="text-danger">*</span> First Name</label>
                                        <div className="col-sm-6">
                                            <input type="text" name="first_name" className="form-control" id="first-name"  placeholder="First Name..." value={this.state.first_name} onChange={this.handleChange.bind(this)}/>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label className="col-sm-3 control-label"> Last Name</label>
                                        <div className="col-sm-6">
                                            <input type="text" name="last_name" className="form-control" id="first-name"  placeholder="Last Name..." value={this.state.last_name} onChange={this.handleChange.bind(this)}/>
                                        </div>
                                    </div>
                                </form>
                                <button type="button" className="btn btn-primary pull-right" onClick={this.handleProfileUpdate}>Update Profile</button>
                            </div>




                        </div>

                    </div>
                </div>



            </div>
        </div>

        )
}



}

export default Profile;