import React, { useEffect, useReducer } from "react";
import SideNav from "../../components/SideNav";
import Subcontractors from "./Subcontractors";
import InternalSubs from "./InternalSubs";
import Topics from "./Topics";
import FlaggedUsers from "./Flaggedusers";

const initialTabStatus = {
    subcontractors: true,
    managesubs: false,
    flaggedusers: false,
    configuration: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case "UPDATE":
            state[action.tab] = true;
            return { ...state };
        default:
            return state;
    }
};

const Home = (params) => {
    let userDetails = localStorage.getItem("role");
    userDetails = JSON.parse(userDetails);
    const name = params.match.params.name || "subcontractors";
    const [tabs, dispatch] = useReducer(reducer, initialTabStatus);

    const handleUpdateTab = (tab) => {
        dispatch({ type: "UPDATE", tab: tab });
    };

    useEffect(() => {
        if (name) {
            handleUpdateTab(name)
        }
    }, [])
    return (
        <React.Fragment>
            <div className="msmcontainer dashboard-container">
                <div className="row d-flex">
                    <SideNav />
                    <div className="content-wrap asset-tracking-wrap risk-assessment">
                        <div className="title-tab-wrap">
                            <h1>
                                <i
                                    style={{ paddingInlineStart: "5px", paddingRight: "5px" }}
                                    className="fa fa-address-card"
                                ></i>
                                <b>Subcontractors</b>
                            </h1>
                            <ul className="nav nav-tabs">
                                <li className={`${name == "subcontractors" ? 'active' : ""}`}>
                                    <a data-toggle="tab" href="#subcontractors_list">
                                        External Subs
                                    </a>
                                </li>
                                {/*{(userDetails && userDetails.name == "Admin" || userDetails && userDetails.name == "SuperAdmin") &&*/}
                                    <li className={`${name == "managesubs" ? 'active' : ""}`} onClick={(e) => { handleUpdateTab('managesubs') }}>
                                        <a data-toggle="tab" href="#manage_subs">
                                            Managed Subs
                                            <span style={{border:"1px #3fb950 solid",borderRadius:"10px 10px 10px 10px", color:"#3fb950", padding: "1px 10px 1px 10px", marginLeft:"5px", fontSize:"11px" }}>New</span>
                                        </a>
                                        
                                    </li>
                                {/*}*/}
                                <li className={`${name == "flaggedusers" ? 'active' : ""}`} onClick={(e) => { handleUpdateTab('flaggedusers') }}>
                                    <a data-toggle="tab" href="#flagged_users">
                                        Flagged Users
                                    </a>
                                </li>
                                {(userDetails && userDetails.name == "Admin" || userDetails && userDetails.name == "SuperAdmin") &&
                                    <li className={`${name == "configuration" ? 'active' : ""}`} onClick={(e) => { handleUpdateTab('configuration') }}>
                                        <a data-toggle="tab" href="#configuration">
                                            Configuration
                                        </a>
                                    </li>
                                }
                            </ul>
                        </div>

                        <div className="tab-content">
                            <div id="subcontractors_list" className={`tab-pane fade in ${name == "subcontractors" ? 'active' : ""}`}>
                                <Subcontractors />
                            </div>
                            {/*{(userDetails && userDetails.name == "Admin" || userDetails && userDetails.name == "SuperAdmin") &&*/}
                                <div id="manage_subs" className={`tab-pane fade in ${name == "managesubs" ? 'active' : ""}`}>
                                    {tabs.managesubs && <InternalSubs />}
                                </div>
                            {/*}*/}
                            <div id="flagged_users" className={`tab-pane fade in`}>
                                {tabs.flaggedusers && <FlaggedUsers />}
                            </div>
                            {(userDetails && userDetails.name == "Admin" || userDetails && userDetails.name == "SuperAdmin") &&
                                <div id="configuration" className={`tab-pane fade in ${name == "configuration" ? 'active' : ""}`}>
                                    {tabs.configuration && <Topics />}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Home;