import React from 'react';
import HeroSection from './CommonComponents/HeroSection';
import FeaturesSection from './CommonComponents/FeaturesSection';
import ParallaxSection from '../../pages/HomePage/Components/ParallaxSection';
import HomepageDemoSection from '../../pages/HomePage/Components/HomepageDemoSection';

export default function AutoNotifications() {
    return (
        <>
            <HeroSection
                heroText={"Auto Notifications"}
                subText={"Set up automated reminders and alerts to keep your team informed and on track."}
            />
            <FeaturesSection
                headerText={"Automated Alerts"}
                subText={"Set up alerts and notifications for critical safety and compliance updates."}
                featureHighlights={[
                    "Create automated alerts for compliance tasks.",
                    "Monitor alert delivery and completion.",
                    "Receive real-time notifications for safety updates.",
                    "Set reminders for upcoming tasks and deadlines."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for Automated Alerts section here
            />
            <FeaturesSection
                headerText={"Custom Notification Settings"}
                subText={"Tailor notification settings based on your organization’s unique needs."}
                featureHighlights={[
                    "Set up custom notification rules for various events.",
                    "Monitor notification delivery status.",
                    "Track responses and engagement with notifications."
                ]}
                // Placeholder: Add image for Custom Notification Settings section here
            />
            <FeaturesSection
                headerText={"Notification Reporting"}
                subText={"Generate reports on notifications and alerts to ensure compliance and efficiency."}
                featureHighlights={[
                    "Track notification delivery and completion.",
                    "Analyze notification response rates.",
                    "Optimize alerts based on data insights."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for Notification Reporting section here
            />
            <div className="Home-Page sl-home-wrapper" style={{ paddingTop: 0 }}>
                <ParallaxSection />
            </div>
            <div className="features-homepage-wrapper">
                <HomepageDemoSection mergeTop={false} />
            </div>
        </>
    );
}
