import React, { useEffect, useState } from "react";
import axios from "../../../config/axiosConfig";
import { toast } from "react-toastify";
import { TableContainer, Paper, Pagination, MenuItem, FormControl, Select, Button } from '@mui/material';
import Application from "../../../config/ApplicationWrapper";
import ViewPopup from "../Popups/View";
import Loader from "../../../components/NewLoader";

const Archive = ({ updateActiveCount, updateChanges, updateArchiveRecord, userRole }) => {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(30);
    const [pageCount, setPageCount] = useState(0);
    const [pageDetails, setPageDetails] = useState([]);
    const [name, setSearchByName] = useState();
    const [user, setSearchByUser] = useState();
    const [group, setSearchByGroup] = useState();
    const [show, setShow] = useState(false);
    const [showDetails, setShowDetails] = useState();
    const [loading, setLoading] = useState(true);
    const [searchable, setSearchable] = useState(false);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getPageDetails()
        }, 2000)

        return () => clearTimeout(delayDebounceFn)
    }, [name, user, group, page, limit, updateArchiveRecord])

    const getPageDetails = async () => {
        try {

            window.scrollTo(0, 0);
            let resultObj = await axios.get(`${Application.api_url}/api/v2/internal_subs`, {
                params: {
                    page: page,
                    limit: limit,
                    archived_eq: true,
                    name_cont: name,
                    internal_group_companies_group_name_cont: group
                },
            });
            if (resultObj.status == 200) {
                console.log("checkk")
                let details = resultObj.data;
                setPageDetails(details ? details.data : [])
                setPageCount(
                    details && details.metadata ? details.metadata.pages : 0
                );
                updateActiveCount(details && details.metadata ? details.metadata.count : 0
                )
                setSearchable(details && details.data.length > 0 ? true : false)
                setLoading(false)
            }
        } catch (err) {
            console.log("error==>", err);
        }
    };

    const handleChangePage = (event, value) => {
        setPage(value);
    };


    function ChipDesign({ data, type }) {
        let finalData = data.filter(Boolean);
        if (data && data.length >= 3) {
            finalData = finalData.slice(0, 2);
        }
        let objName = type === "user" ? "full_name" : "name";

        return (
            <div>
                {finalData &&
                    finalData.map((obj, index) => (
                        <span className={`badge badge-light responsible-party`}>
                            {obj && obj[objName]}
                        </span>
                    ))}
                {data && data.length >= 3 && (
                    <span className={`badge badge-light responsible-party more-groups`}>
                        + {data.length - 2} More Groups
                    </span>
                )}
            </div>
        );
    }

    const clearFilter = () => {
        setSearchByGroup("");
        setSearchByName("");
        setSearchByUser("");
    }

    const unarchive = async (val) => {
        await axios.patch(`${Application.api_url}/api/v2/internal_subs/${val}/unarchive`).then((result) => {
            if (result.data && result.data.success === true) {
                toast.success(`Unarchived Successfully`)
                getPageDetails()
                updateChanges()
            }
        }).catch((err) => {
            console.log("err===>", err)
        })
    }

    const open = (id) => {
        setShow(true)
        setShowDetails(id)
    }

    const close = () => {
        setShow(false)
    }

    return (
        <>
            <div class="card asset-tab-landing">
                <div class="card-body">
                    <div class="tab-content">
                        <div
                            id="active-risk-assessment"
                            class="tab-pane fade in active active-risk-assessment active-manage-sub"
                        >
                            <div className="nestedTable pf-table">
                                <div className="fields-setting">
                                    <div className="asset-active-filters">
                                        Active Filters :
                                        <span className="active-filters">
                                            <span>Company Name :</span>
                                            <span className="selected">{name ? name : "All"}</span>
                                            <span className={name ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setSearchByName("") }}>X</span>
                                        </span>
                                        <span className="active-filters">
                                            <span>Shared Groups :</span>
                                            <span className="selected">{group ? group : "All"}</span>
                                            <span className={group ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setSearchByGroup("") }}>X</span>
                                        </span>
                                        <span className="active-filters-clear" onClick={(e) => clearFilter()}>
                                            Clear All
                                        </span>
                                    </div>
                                </div>
                                <TableContainer component={Paper}>
                                    <table class="table table-striped">
                                        <thead>
                                            <tr style={{ height: "100px" }}>
                                                <th scope="col" className="risk-assessment-divider">
                                                    <div className="d-flex justify-content-between align-item-center">
                                                        Company Name
                                                    </div>
                                                    {searchable &&
                                                        <div class="input-group">
                                                            <input
                                                                type="text"
                                                                class="form-control assessment-name"
                                                                placeholder="Search"
                                                                aria-label="Username"
                                                                id="name"
                                                                aria-describedby="basic-addon1"
                                                                name="name"
                                                                onChange={(e) => {
                                                                    setSearchByName(e.target.value)
                                                                }}
                                                                value={name}
                                                            />
                                                            <div class="input-group-addon">
                                                                <span class="input-group-text" id="basic-addon1">
                                                                    <i class="fa fa-search" aria-hidden="true"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    }
                                                </th>
                                                <th scope="col" className="risk-assessment-divider">
                                                    Industry
                                                </th>
                                                <th scope="col" className="risk-assessment-divider">
                                                    Shared Groups
                                                    {searchable &&
                                                        <div class="input-group">
                                                            <input
                                                                type="text"
                                                                class="form-control assessment-name"
                                                                placeholder="Search"
                                                                aria-label="Username"
                                                                id="user"
                                                                aria-describedby="basic-addon1"
                                                                name="user"
                                                                onChange={(e) => {
                                                                    setSearchByGroup(e.target.value)
                                                                }}
                                                                value={group}
                                                            />
                                                            <div class="input-group-addon">
                                                                <span class="input-group-text" id="basic-addon1">
                                                                    <i class="fa fa-search" aria-hidden="true"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    }
                                                </th>
                                                <th scope="col" className="risk-assessment-divider">
                                                    No. Of
                                                    Employees
                                                </th>
                                                <th scope="col" className="risk-assessment-divider">
                                                    Company Admin Details
                                                </th>
                                                {userRole &&
                                                    <th scope="col" className="table-action">Action</th>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!loading &&
                                                pageDetails.length > 0 &&
                                                pageDetails.map((val, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td onClick={(e) => { open(val.identifier) }}>
                                                                <img src={val.profile_picture_url || '/main-images/default-org-image.png'} alt="profile picture" className="profile_picture_url" />
                                                                <span className="alerts-name" >{val.name}</span>
                                                            </td>
                                                            <td>
                                                                {val.industry}
                                                            </td>
                                                            <td>
                                                                <ChipDesign data={val.internal_groups} type="users" />
                                                            </td>
                                                            <td className="text-center">
                                                                {val.no_of_employees}
                                                            </td>
                                                            <td>
                                                                <p className="action-by">{val.admin && val.admin.full_name}</p>
                                                                <p className="action-time">{val.admin && val.admin.email}</p>
                                                            </td>
                                                            {userRole &&
                                                                <td
                                                                    className="template-options"
                                                                    style={{ textAlign: "center" }}
                                                                >
                                                                    <Button variant="contained" color="success" onClick={(e) => { unarchive(val.identifier) }}>Active</Button>
                                                                </td>
                                                            }
                                                        </tr>
                                                    )
                                                })}
                                            <Loader pageDetails={pageDetails} loading={loading} />
                                        </tbody>
                                    </table>
                                </TableContainer>
                                <div className="page-limits">
                                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                        <Select
                                            labelId="demo-select-small"
                                            id="demo-select-small"
                                            value={limit}
                                            onChange={(e) => {
                                                setPage(1);
                                                setLimit(e.target.value);
                                            }}
                                        >
                                            <MenuItem value={5}>5</MenuItem>
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={20}>20</MenuItem>
                                            <MenuItem value={30}>30</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {!loading && (
                                        <Pagination
                                            count={pageCount}
                                            page={page}
                                            variant="outlined"
                                            shape="rounded"
                                            onChange={handleChangePage}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {show &&
                <ViewPopup show={show} closepopup={close} details={showDetails} />
            }
        </>
    );
}

export default Archive;
