import React from 'react';
import UserManagement from '../shared/UserManagement';
import { NavLink } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap'
import Select from 'react-select';

import axios, { put } from 'axios';

var firstCall = 0

class UsersArchivedList extends React.Component
{
    constructor(props)
    {
        super(props);
        const initialState = {
            result:[],
            roles_list: [],
            approver_list: [],
            errors: {},
            show: false,
            company_selected: '',
            group_selected: [],
            trainings: [],
            training_selected: [],
            role_selected: '',
            approver_selected: '',
            company_list: [],
            groups: [],
            allow_group_update: false,
            user_id: '',
            user_role: '',
            fields: {email:'',first_name:'',last_name:'',username:'', employee_id: '', approver:'', activated:''}
        };

        this.state = initialState

        this.closeForm = this.closeForm.bind(this);
        this.handleCompanySelect = this.handleCompanySelect.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleUserSelect = (role_selected) => {
        this.setState({ role_selected , allow_group_update: true});
    }

    handleApproverSelect = (approver_selected) => {
        this.setState({ approver_selected });
    }

    handleValidation(){
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if(!fields["first_name"]){
            formIsValid = false;
            errors["first_name"] = "First Name cannot be empty";
        }

        if(!fields["last_name"]){
            formIsValid = false;
            errors["last_name"] = "Last Name cannot be empty";
        }

        if(!fields["username"]){
            formIsValid = false;
            errors["username"] = "UserName cannot be empty";
        }

        if(!fields["employee_id"]){
            formIsValid = false;
            errors["employee_id"] = "Employee Id cannot be empty";
        }

        if(!this.state.role_selected){
            formIsValid = false;
            errors["role_id"] = "UserRole cannot be empty";
        }

        if(!this.state.company_selected){
            formIsValid = false;
            errors["user_company_id"] = "Company Name cannot be empty";
        }

        this.setState({errors: errors});
        return formIsValid;
    }

    handleChange(field, e){
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({fields});
    }

    getSelectedValues = (selected_values) => {
        if (selected_values.length > 0 && typeof (selected_values[0]) == "object"){
            selected_values = selected_values.map(a => a.value);
        }
        return selected_values
    }

    handleSubmit(e) {
        let approver_id;
        e.preventDefault();
        let errors = {};
        var that = this;

        if(this.handleValidation()){

            if (typeof(this.state.approver_selected) == "string"){
                 approver_id = this.state.approver_selected
            }else{
                approver_id = this.state.approver_selected.value
            }

            const url = '/users/edit_user';
            var user = {
                company_id: this.state.company_selected,
                groups: this.getSelectedValues(this.state.group_selected),
                trainings: this.getSelectedValues(this.state.training_selected),
                allow_group_update: this.state.allow_group_update,
                id: this.state.user_id,
                email: this.state.fields['email'],
                first_name: this.state.fields['first_name'],
                last_name: this.state.fields['last_name'],
                username: this.state.fields['username'],
                password: this.state.fields['password'],
                employee_id: this.state.fields['employee_id'],
                role_id: this.state.role_selected.value,
                approver_id: approver_id
                
            }
            put(url, user)
                .then(function(response) {
                    $('#new_user').append('<span class="new_user_message" style="padding-left: 111px; font-size: 19px;">User has been successfully updated.</span>')
                    $('.new_user_message').fadeOut(15000);
                    that.populateData()
                })
                .catch(function(error) {
                    errors["invalid_user"] = error.response.data.error;
                    that.setState({errors: errors});
                });
        }else{
            console.log("Form has errors.")
        }
    }

    // Populate Users List.
    populateData(){
        var that = this;
        // debugger;
        axios.get('/companies',{
        })
            .then(function(response){
                var users = response.data.users

                that.setState({
                    result: users,
                    company_list: response.data.user_companies,
                    company_selected: response.data.company_id,
                    user_role: response.data.user_role
                });
            })
            .catch(function(error){
                console.log(error);
            })
    }

    handleCompanySelect = (company_selected) => {
        this.setState({ company_selected: company_selected.value });
    }

    handleGroupSelect = (group_selected) => {
        let selected_values = group_selected.map(a => a.value);
        this.setState({ group_selected: selected_values, allow_group_update: true });
    }

    handleTrainingSelect = (training_selected) => {
        let selected_values = training_selected.map(a => a.value);
        this.setState({ training_selected: selected_values });
    }

    componentDidMount() {
        var that = this;
        that.populateData()
        this.loadGroupsData()
    }

    loadGroupsData = async () => {
        const response = await axios.get('/users/groups_data', { params: {  } });
        let data = response.data
        this.setState({ groups: data });
    }

    loadUserGroupsData = async (userId) => {
        const response = await axios.get('/users/get_groups', { params: { id: userId } });
        let data = response.data
        this.setState({ groups: data.groups, group_selected: data.assigned_groups, trainings: data.trainings, training_selected: data.assigned_trainings });
    }


    // Destroy user simply deactivates the user.  
    handleDelete(user_id){
      $(".loading").show()
      var that = this;
      var user = {
          id: user_id
      }
      axios.get('/users/destroy_user', {
          params: user
      })
          .then(function(response) {
              var users = response.data.users
              $(".loading").hide()
              that.setState({
                  result: users
              });
          })
          .catch(function(error) {
              console.log(error)

          });
    }

    /******************************************/
    // UNDestroy user simply activates the user.  
    unDelete(user_id){
      $(".loading").show()
      var that = this;
      var user = {
          id: user_id
      }
      axios.get('/users/undestroy_user', {
          params: user
      })
          .then(function(response) {
              var users = response.data.users
              $(".loading").hide()
              that.setState({
                  result: users
              });
          })
          .catch(function(error) {
              console.log(error)

          });
    }

    handleEdit(user_id){

        let merged_state = {...this.state, ...{ show: true, user_id: user_id }}
        this.setState(merged_state);
        var that = this;
        var user = {
            id: user_id
        }
        this.loadUserGroupsData(user_id);
        
        axios.get('/fetch_user_info', {
            params: user
        })
            .then(function(response) {
                let data = response.data
                let user = response.data.user_data
                let role_id = response.data.role_id
                let approver_id = response.data.approver_id
                let fields = that.state.fields

                fields['email'] = user.email
                fields['first_name'] = user.first_name
                fields['last_name'] = user.last_name
                fields['username'] = user.username
                fields['employee_id'] = user.employee_id
                fields['role_id'] = role_id
                fields['approver'] = approver_id
                fields['activated'] = user.activated
                that.setState({
                    roles_list: data.user_roles,
                    approver_list: [{value:"Yes",label:"Yes"}, {value:"No", label:"No"}],
                    fields: fields,
                    role_selected: role_id,
                    approver_selected: approver_id
                });
            })
            .catch(function(error) {
                console.log(error)

            });

    }

    closeForm() {
        var that = this
        that.setState({ show: false, errors: {} });
    }

    copyInviteLink() {
        var copyText = document.getElementById("url");
        if(firstCall == 0) {
            copyText.value = copyText.value + copyText.dataset['company'];
            firstCall = 1;
        }
        copyText.select();
        copyText.setSelectionRange(0, 99999)
        document.execCommand("copy");
        var copiedAlert = document.getElementById("copied");

        copiedAlert.style.visibility = "visible";

        setTimeout(function(){
            copiedAlert.style.visibility = "hidden";
        },3000);
    }


    roleMap(role_id) {
        if(role_id==1) {
            return "Admin"
        } else if(role_id==2) {
            return "Manager"
        } else if(role_id==3) {
            return "Employee"
        } else {
            return "-"
        }
    }

    renderEditAction(user_role, users){
        if (user_role != "Observer")
        {
            return <td>
                <p data-toggle="tooltip" title="Edit">
                    <button id={"edit-user-btn-"+users.id}  disabled={!users.activated} className="btn btn-primary btn-xs" style={{fontSize: "13px"}}
                            onClick={() => { this.handleEdit(users.id) }}>Edit
                    </button>
                </p>
            </td>
        }
    }

    renderSearchBox() {
      return (
        <div className="panel-heading extra">
            <span class="user-search-title" >Search</span>
            <br/>
            <input type="text" onKeyUp={() => { this.searchAllRows(event.target.value) }} id="auto-complete-search" name="autousersearch" placeholder="Enter User..." />
        </div>
      )
    }

    searchAllRows(keyword) {
      console.log(keyword);
      // Search all rows in table and hide rows not matching
      $(".user-row").each(function(){
        // debugger;
        if(keyword=="") {
          $(this).show();
        } else {

          
          try {
            if($(this)[0].dataset["name"].includes(keyword.trim()) || $(this)[0].dataset["user"].includes(keyword.trim()) || $(this)[0].dataset["fname"].includes(keyword.trim())) {
              $(this).show();
            } else {
              $(this).hide();
            }
          } catch(e) {

          }
        }
      });
      
    }





    renderActionButton(user_role, users){
        if( users.activated == false )
        {
            return <td>
                <p data-toggle="tooltip" title="Delete">
                    
                    <button id={"delete-user-btn-"+users.id} className="btn btn-primary btn-xxs users-button" style={{fontSize: "13px"}}
                            onClick={() => { if (window.confirm('Are you sure you wish to activate this user?')) this.unDelete(users.id) } }>Activate

                    </button>
                    
                </p>
            </td>
        }
        if (user_role != "Observer")
        {
            return <td>
                <p data-toggle="tooltip" title="Delete">
                    
                    <button id={"delete-user-btn-"+users.id} className="btn btn-secondary btn-xxs users-button deactive" style={{fontSize: "13px"}}
                            onClick={() => { if (window.confirm('Are you sure you wish to deactivate this user?')) this.handleDelete(users.id) } }>Deactivate

                    </button>
                    
                </p>
            </td>
        }
    }


    

    renderDeleteAction(user_role, users){
        if( users.activated == false )
        {
            return <td>
                <p data-toggle="tooltip" title="Delete">
                    Deactive
                    
                    
                </p>
            </td>
        }
        if (user_role != "Observer")
        {
            return <td>
                <p data-toggle="tooltip" title="Delete">
                    Active
                    
                    
                </p>
            </td>
        }
    }

    renderInviteLink(user_role) {
        if (user_role != "Observer"){
            return <input type="button" className="btn btn-secondary" onClick={this.copyInviteLink} value="Create Invite Link" />

        }
    }

    renderArchived(user_role) {
        if (user_role != "Observer"){
            return( 
                <a href="/company-archived-users">
                    <input type="button" className="btn btn-secondary" style={{marginLeft:"5px"}}  value="Archived" />
                </a>
            );

        }
    }

    renderActive(user_role) {
        if (user_role != "Observer"){

            return( 
                <a href="/company-users">
                    <input type="button" className="btn btn-secondary" style={{marginLeft:"5px"}}  value="Active" />
                </a>
            );

        }
    }

    renderTableData() {
        // debugger;
        return this.state.result.map((users,key) => {
            console.log(users)
            if(!users.activated) {
                return (
                    <tr key={key} className="user-row" data-name={users.last_name} data-fname={users.first_name} data-user={users.username}>
                        <td>{users.first_name}</td>
                        <td>{users.last_name}</td>
                        <td>{users.username}</td>
                        <td>{users.email}</td>
                        <td>{this.roleMap(users.role)}</td>
                        <td>{users.approver}</td>
                        {this.renderEditAction(this.state.user_role, users)}
                        {this.renderDeleteAction(this.state.user_role, users)}
                        {this.renderActionButton(this.state.user_role, users)}
                    </tr>
                );
            }
        })
    }

    render(){

        const { selectedOption, company_selected, group_selected, training_selected, trainings, projects, company_list, role_selected, approver_selected, roles_list, approver_list, groups, user_role } = this.state;
    return (

        <div className="content">
            {/* <div className="sub-navbar sub-navbar__header-breadcrumbs">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 sub-navbar-column">
                            <div className="sub-navbar-header">
                            </div>
                            <ol className="breadcrumb navbar-text navbar-right no-bg">
                            </ol>
                        </div>
                        <div className="col-lg-12">
                        </div>
                        <div className="col-lg-2"></div>
                        <div className="col-lg-10 sub-navbar-column">
                            <ol className="breadcrumb navbar-text navbar-left no-bg">
                                <li className="current-parent">
                                    <NavLink to="/" className="current-parent" role="button"><i className="fa fa-fw fa-home"></i></NavLink>
                                </li>
                                <li className="active">
                                    <NavLink to="/" className="current-parent" role="button">Dashboard</NavLink>
                                </li>
                                <li className="active">Users</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="container" style={{marginTop:"100px", width:"1200px", marginLeft:"300px"}}>
                <div className="row">
                    <UserManagement source= "users" />

                    <div className="col-lg-12">
                        <div className="panel no-bg">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="panel-heading">
                                        <h2 className="panel-title" style={{fontSize:"28px", fontWeight:"700"}}>Company Archived Users List</h2>
                                    </div>
                                </div>
                                <div className="col-md-4" style={{paddingTop: "0px"}}>
                                    <div id="copied" style={{visibility:"hidden"}} >
                                        Copied!
                                    </div>
                                    {this.renderInviteLink(this.state.user_role)}
                                    {this.renderArchived(this.state.user_role)}
                                    {this.renderActive(this.state.user_role)}
                                    {/*<input type="button" onClick={this.copyInviteLink} value="Create Invite Link" />*/}
                                    <input type="text" id="url" value="https://www.msmecor.com/users/register-new/" data-company={this.state.company_selected} style={{height: "0px", opacity: "0.0"}} />
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    {this.renderSearchBox()}
                                </div>                            
                            </div>

                            <div className="panel-body">
                                <div className="row">
                                    <div className="col-lg-12 muruga" id="ddd">
                                        <div className="col-lg-12">
                                            
                                            <table className="table table-bordered table-striped table-hover">

                                                <thead>
                                                    <tr>
                                                        <th style={{backgroundColor:"#2e6ab1", color:"#fff"}}>First Name</th>
                                                        <th style={{backgroundColor:"#2e6ab1", color:"#fff"}}>Last Name</th>
                                                        <th style={{backgroundColor:"#2e6ab1", color:"#fff"}}>UserName</th>
                                                        <th style={{backgroundColor:"#2e6ab1", color:"#fff"}}>Email</th>
                                                        <th style={{backgroundColor:"#2e6ab1", color:"#fff"}}>Role</th>
                                                        <th style={{backgroundColor:"#2e6ab1", color:"#fff"}}>Approver</th>
                                                        { user_role != "Observer" ?
                                                            <th style={{backgroundColor:"#2e6ab1", color:"#fff"}}>Edit</th>
                                                            : ''
                                                        }
                                                        
                                                        <th style={{backgroundColor:"#2e6ab1", color:"#fff"}}> Status</th>
                                                        
                                                        
                                                        { user_role != "Observer" ?
                                                            <th style={{backgroundColor:"#2e6ab1", color:"#fff"}}>Actions</th>
                                                            : ''
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.renderTableData()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal show={this.state.show} onHide={this.closeForm}>
                        <Modal.Header>
                            <Modal.Title>Edit User</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div id="new_user"/>
                            <span style={{color: "red"}}>{this.state.errors["invalid_user"]}</span>
                            <form className="form-horizontal">
                                <div className="form-group">
                                    <label className="col-sm-4 control-label">First Name<span className="text-danger">*</span></label>
                                    <div className="col-sm-6">
                                        <input className="form-control" ref="first_name" id="first_name" maxLength="50" placeholder="Enter your First Name" onChange={this.handleChange.bind(this, "first_name")} value={this.state.fields["first_name"] || ''}/>
                                        <span style={{color: "red"}}>{this.state.errors["first_name"]}</span>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="col-sm-4 control-label"> Last Name<span className="text-danger">*</span></label>
                                    <div className="col-sm-6">
                                        <input className="form-control" ref="last_name" id="last_name" maxLength="50" placeholder="Enter your Last Name" onChange={this.handleChange.bind(this, "last_name")} value={this.state.fields["last_name"] || ''}/>
                                        <span style={{color: "red"}}>{this.state.errors["last_name"]}</span>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="col-sm-4 control-label">User Name<span className="text-danger">*</span></label>
                                    <div className="col-sm-6">
                                        <input className="form-control" ref="username" id="username" maxLength="50" placeholder="Enter your User Name" onChange={this.handleChange.bind(this, "username")} value={this.state.fields["username"] || ''}/>
                                        <span style={{color: "red"}}>{this.state.errors["username"]}</span>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="col-sm-4 control-label">Email<span className="text-danger"></span></label>
                                    <div className="col-sm-6">
                                        <input className="form-control"  ref="email" id="email" maxLength="50" placeholder="Enter your Email" onChange={this.handleChange.bind(this, "email")} value={this.state.fields["email"] || ''}/>
                                        {/* <span style={{color: "red"}}>{this.state.errors["email"]}</span> */}
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="col-sm-4 control-label">Password Override<span className="text-danger">*</span></label>
                                    <div className="col-sm-6">
                                        <input className="form-control" type="password"  ref="password" id="password" maxLength="50" placeholder="Enter a password" onChange={this.handleChange.bind(this, "password")} value={this.state.fields["password"] || ''}/>
                                        <span style={{color: "red"}}>{this.state.errors["password"]}</span>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="col-sm-4 control-label">Employee ID<span className="text-danger">*</span></label>
                                    <div className="col-sm-6">
                                        <input className="form-control" ref="employee" id="employee_id" maxLength="50" placeholder="Enter your Employee Id" onChange={this.handleChange.bind(this, "employee_id")} value={this.state.fields["employee_id"] || ''}/>
                                        <span style={{color: "red"}}>{this.state.errors["employee_id"]}</span>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="col-sm-4 control-label">User Role<span className="text-danger">*</span></label>
                                    <div className="col-sm-6">
                                        <span style={{color: "red"}}>{this.state.errors["role_id"]}</span>
                                            <Select
                                            name="form-field-name"
                                            value={role_selected}
                                            onChange={this.handleUserSelect}
                                            options={roles_list}
                                            id="role_id"
                                            />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="col-sm-4 control-label">Company Name<span className="text-danger">*</span></label>
                                    <div className="col-sm-6">
                                        <span style={{color: "red"}}>{this.state.errors["user_company_id"]}</span>
                                            <Select
                                            name="form-field-name"
                                            value={company_selected}
                                            onChange={this.handleCompanySelect}
                                            options={company_list}
                                            id="user_company_id"
                                            clearable={false}
                                            />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="col-sm-4 control-label">Approver</label>
                                    <div className="col-sm-6">
                                        <Select
                                        name="form-field-name"
                                        value={approver_selected}
                                        onChange={this.handleApproverSelect}
                                        options={approver_list}
                                        id="approver_id"
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="col-sm-4 control-label">Assign Groups</label>
                                    <div className="col-sm-6">
                                        <Select
                                            name="form-field-name"
                                            value={group_selected}
                                            onChange={this.handleGroupSelect}
                                            options={groups}
                                            multi={true}
                                            id="user_groups"
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="col-sm-4 control-label">Assign Trainings</label>
                                    <div className="col-sm-6">
                                        <Select
                                            name="form-field-name"
                                            value={training_selected}
                                            onChange={this.handleTrainingSelect}
                                            options={trainings}
                                            multi={true}
                                            id="user_trainings"
                                        />
                                    </div>
                                </div>
                                
                            </form>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button bsStyle="primary" onClick={this.closeForm}>Close</Button>
                            <Button bsStyle="primary" onClick={this.handleSubmit}>Save</Button>
                        </Modal.Footer>
                    </Modal>


                </div>
            </div>
        </div>
    )}
    }

    export default UsersArchivedList;