import React, { useEffect, useRef } from "react";
import Filter from "../../../assets/images/Filter.png";
import Pagination from "@mui/material/Pagination";
import Cookies from "js-cookie";
import { Button } from "react-bootstrap";
import Application from "../../../config/ApplicationWrapper";
import Signature from "./Popups/Signature";
import { NavLink } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormIcon from "../../../assets/images/form.svg";

const ApproveRiskAssessment = (props) => {
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [sort, setSort] = React.useState("created_at");
  const [pageCount, setPageCount] = React.useState(0);
  const [filterName, setFilterName] = React.useState("");
  const [sortingDirection, setSortingDirection] = React.useState({
    name: "desc",
    created_at: "desc",
  });

  const [rats, setRats] = React.useState([]);
  const [signaturePopup, setSignaturePopup] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [ratId, setRatId] = React.useState("");
  const [created, setCreated] = React.useState("");
  const [approvalStatus, setApprovalStatus] = React.useState("");
  const [creator, setCreator] = React.useState("");
  const [dataChange, setDataChange] = React.useState("");
  const [currentQuery, setCurrentQuery] = React.useState('');

  const nameImageRef = useRef();
  const createdImageRef = useRef();
  const cookieDetails = Cookies.get("token");

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const changeFilter = (event) => {
    setFilterName(event.target.value);
  };

  const changeStatus = async (status, id) => {
    setStatus(status);
    setRatId(id);
    setSignaturePopup(true);
  };

  const closeStatus = (data) => {
    setDataChange(data);
    setSignaturePopup(false);
    props.updateLastChanges();
  };

  const sorting = (name) => {
    let direction;
    if (sortingDirection[name] == "desc") {
      if (name == "name") {
        nameImageRef.current.style.transform = `rotate(180deg)`;
        direction = { ...sortingDirection, ...{ name: "asc" } };
      } else {
        createdImageRef.current.style.transform = `rotate(180deg)`;
        direction = { ...sortingDirection, ...{ created_at: "asc" } };
      }
    } else {
      if (name == "name") {
        nameImageRef.current.style.transform = `rotate(0deg)`;
        direction = { ...sortingDirection, ...{ name: "desc" } };
      } else {
        createdImageRef.current.style.transform = `rotate(0deg)`;
        direction = { ...sortingDirection, ...{ created_at: "desc" } };
      }
    }
    setSortingDirection(direction);
    setSort(name);
  };

  useEffect(() => {
    const getPageDetails = async () => {
      try {
        var url = new URL(`${Application.api_url}/api/v2/mrc/rats`);
        const query = new URLSearchParams({
          page: page,
          "query[name_cont]": filterName,
          "query[created_on]": created,
          "query[approval_status_eq]": approvalStatus,
          "query[creator_fullname_cont]": creator,
          limit: limit,
          direction: sort ? sortingDirection[sort] : "",
          "query[archived_eq]": false,
          sort: sort,
        }) 
        url.search = query.toString();
        
        setCurrentQuery(query)

        const res = await fetch(url, {
          method: "GET",
          headers: { Authorization: cookieDetails },
        });
        const resultObj = await res.json();
        setRats(resultObj && resultObj.rats ? resultObj.rats : []);
        setPageCount(resultObj && resultObj.meta ? resultObj.meta.pages : 0);
        props.countUpdate(
          resultObj && resultObj.meta ? resultObj.meta.count : 0
        );
      } catch (err) {
        console.log("error==>", err);
      }
    };

    getPageDetails();
  }, [
    page,
    filterName,
    sortingDirection,
    dataChange,
    limit,
    created,
    approvalStatus,
    creator,
    props.archiveRATLastChange,
    props.activeRATLastChange,
  ]);

  useEffect(() => {
    if (props.currentTab === 'Approval' && currentQuery) {
      props.updateCurrentQuery(currentQuery)
    }
  }, [props.currentTab, currentQuery])

  const CustomComponent = ({ value, status, id }) => {
    if (value) {
      const user_id = localStorage.getItem("user_id");
      let checkUser = value.filter((val) => {
        if (val.user_id == user_id) {
          return true;
        }
      });
      if (checkUser && checkUser.length > 0) {
        switch (status) {
          case "Approved":
            return (
              <>
                <NavLink to={`/view-risk-assessment/${id}`} id="" role="button">
                  <Button
                    style={{
                      margin: "0 5px",
                      backgroundColor: "#5BA3CC",
                      borderColor: "#5BA3CC",
                    }}
                    bsStyle="warning"
                  >
                    View RAT
                  </Button>
                </NavLink>
              </>
            );
          case "Declined":
            return (
              <>
                <NavLink to={`/view-risk-assessment/${id}`} id="" role="button">
                  <Button
                    style={{
                      margin: "0 5px",
                      backgroundColor: "#5BA3CC",
                      borderColor: "#5BA3CC",
                    }}
                    bsStyle="warning"
                  >
                    View RAT
                  </Button>
                </NavLink>
                <Button
                  style={{ margin: "0 5px", backgroundColor: "#009E1F" }}
                  bsStyle="success"
                  onClick={(e) => {
                    changeStatus("approve", id);
                  }}
                >
                  Approve
                </Button>
              </>
            );
          default:
            return (
              <>
                <NavLink to={`/view-risk-assessment/${id}`} id="" role="button">
                  <Button
                    style={{
                      margin: "0 5px",
                      backgroundColor: "#5BA3CC",
                      borderColor: "#5BA3CC",
                    }}
                    bsStyle="warning"
                  >
                    View RAT
                  </Button>
                </NavLink>
                <Button
                  style={{ margin: "0 5px", backgroundColor: "#009E1F" }}
                  bsStyle="success"
                  onClick={(e) => {
                    changeStatus("approve", id);
                  }}
                >
                  Approve
                </Button>
                <Button
                  style={{ margin: "0 5px", backgroundColor: "#D92929" }}
                  bsStyle="danger"
                  onClick={(e) => {
                    changeStatus("declined", id);
                  }}
                >
                  Decline
                </Button>
              </>
            );
        }
      } else {
        return (
          <>
            <NavLink to={`/view-risk-assessment/${id}`} id="" role="button">
              <Button
                style={{
                  margin: "0 5px",
                  backgroundColor: "#5BA3CC",
                  borderColor: "#5BA3CC",
                }}
                bsStyle="warning"
                onClick={(e) => {
                  changeStatus("approve", id);
                }}
              >
                View RAT
              </Button>
            </NavLink>
          </>
        );
      }
    } else {
      return (
        <>
          <NavLink to={`/view-risk-assessment/${id}`} id="" role="button">
            <Button
              style={{
                margin: "0 5px",
                backgroundColor: "#5BA3CC",
                borderColor: "#5BA3CC",
              }}
              bsStyle="warning"
            >
              View RAT
            </Button>
          </NavLink>
        </>
      );
    }
  };

  return (
    <>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col" className="risk-assessment-divider">
              <div
                className="d-flex justify-content-between"
                onClick={(e) => {
                  sorting("name");
                }}
              >
                RAT Name
                <img src={Filter} alt="" ref={nameImageRef} />
              </div>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control assessment-name"
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  onChange={changeFilter}
                />
                <div class="input-group-addon">
                  <span class="input-group-text" id="basic-addon1">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            </th>
            <th scope="col" className="risk-assessment-divider">
              <div
                className="d-flex justify-content-between"
                onClick={(e) => {
                  sorting("created_at");
                }}
              >
                Created On
                <img src={Filter} alt="" ref={createdImageRef} />
              </div>
              <input
                type="date"
                class="form-control assessment-name"
                placeholder="All Dates"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={created}
                onChange={(e) => {
                  setCreated(e.target.value);
                }}
              />
            </th>
            <th scope="col" className="risk-assessment-divider">
              <div className="d-flex justify-content-between">
                Created By
                <img src={Filter} alt="" ref={createdImageRef} />
              </div>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control assessment-name"
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={creator}
                  onChange={(e) => {
                    setCreator(e.target.value);
                  }}
                />
                <div class="input-group-addon">
                  <span class="input-group-text" id="basic-addon1">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            </th>
            <th scope="col" className="risk-assessment-divider">
              Approval Status
              <img
                src={Filter}
                alt=""
                ref={createdImageRef}
                value={approvalStatus}
              />
              <select
                class="form-control risk-type"
                onChange={(e) => {
                  setApprovalStatus(e.target.value);
                }}
              >
                <option value="">All</option>
                <option value="Approved">Approved</option>
                <option value="Declined">Declined</option>
                <option value="Submitted">Submitted</option>
                <option value="Pending_Approval">Pending</option>
              </select>
            </th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {rats &&
            rats.length > 0 &&
            rats.map((val, index) => (
              <tr key={index}>
                <td>
                  {val.name}
                  {val.note_count && val.note_count > 0 ? (
                    <span className="file">
                      <img src={FormIcon} alt="" />
                      <span>{val.note_count}</span>
                    </span>
                  ):""}
                </td>
                <td>{val.created_at}</td>
                <td>
                  {val.creator && val.creator.first_name
                    ? val.creator.first_name
                    : ""}{" "}
                  {val.creator && val.creator.last_name
                    ? val.creator.last_name
                    : ""}
                </td>
                <td style={{ textAlign: "center" }}>
                  <span className={`badge badge-light ${val.approval_status}`}>
                  {val.approval_status === "Pending_Approval" ? "Pending Approval" : val.approval_status}
                  </span>
                </td>
                <td className="template-options">
                  <td
                    className="template-options"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <CustomComponent
                      value={val.ctra_approved_users}
                      status={val.approval_status}
                      id={val.identifier}
                    />
                  </td>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div
        className="d-flex justify-content-between"
        style={{ alignItems: "center" }}
      >
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            value={limit}
            onChange={(e) => {
              setLimit(e.target.value);
            }}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={30}>30</MenuItem>
          </Select>
        </FormControl>
        <Pagination
          count={pageCount}
          variant="outlined"
          shape="rounded"
          onChange={handleChangePage}
        />
      </div>
      <Signature
        signature={signaturePopup}
        closepopup={closeStatus}
        status={status}
        ratId={ratId}
      />
    </>
  );
};

export default ApproveRiskAssessment;
