import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
} from "react-google-maps";

const Map = (props) => {
  const [mapPosition, setMapPosition] = useState([]);

  const containerStyle = {
    width: '100%',
    height: '100%'
  };

  useEffect(() => {
    if (props && props.mapPoints && props.mapPoints.newpoint) {
      setMapPosition(props.mapPoints.newpoint);
    }
  }, [props.mapPoints]);

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      zoom={1}
      center={{
        lat: -3.745,
        lng: -38.523
      }}
    >
      {mapPosition.map((marker) => (
        <Marker
          position={{ lat: marker.lat, lng: marker.lng }}
          key={marker.lat}
        />
      ))}
      <Marker position={mapPosition} />
    </GoogleMap>
  );
};

export default withScriptjs(withGoogleMap(Map));
