import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";
import React, { useEffect, useRef, useState, useReducer } from "react";
import axios from "../../../config/axiosConfig";
import Application from "../../../config/ApplicationWrapper";
import Loader from "../../../components/NewLoader";
import InventoryFreeIcon from "../../../assets/images/inventory-free.svg"
import InventoryStarterIcon from "../../../assets/images/inventory-starter.svg"
import InventoryPlusIcon from "../../../assets/images/inventory-plus.svg"
import InventoryPremierIcon from "../../../assets/images/inventory-premier.svg"
import InventoryFeatureTickIcon from "../../../assets/images/inventory-feature-tick.svg"
import NotIncluded from "../../../assets/images/not_included.svg"
import CreditCardForm from "./popup/CreditCardForm";
import { User } from "../../HomePage/Login";
import { toast } from "react-toastify";

import { withRouter } from "react-router-dom";

const Paywall = ({ history }) => {
  const [plans, setPlans] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openPaidPlan, setOpenPaidPlan] = useState(false);
  const [onSubmit, setOnSubmit] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [hasCreditCard, setHasCreditCard] = useState(false);

  // const initiatePlans = () => {
  //   setPlans([
  //     {
  //       id: "inventory-free",
  //       name: "Free Plan",
  //       icon: InventoryFreeIcon,
  //       price: 0,
  //       inventories: "50 Inventory Items",
  //       features: [
  //         "50 Order Requests monthly",
  //         "5 Suppliers",
  //         "5 Picker Lists",
  //         "Set Notifications",
  //         "",
  //         "",
  //         "",
  //         "",
  //         ""
  //       ],
  //       handleSelect: () => {
  //         setOpenConfirmDialog(true);
  //       }
  //     },
  //     {
  //       id: "inventory-starter",
  //       name: "Starter",
  //       icon: InventoryStarterIcon,
  //       price: 99,
  //       inventories: "5,000 Inventory Items",
  //       features: [
  //         "500 Order Requests monthly",
  //         "Unlimited Suppliers",
  //         "500 Notifications monthly",
  //         "30 day historical logs",
  //         "",
  //         "",
  //         "",
  //         "",
  //         ""
  //       ],
  //     },
  //     {
  //       id: "inventory-plus",
  //       name: "Plus",
  //       icon: InventoryPlusIcon,
  //       price: 199,
  //       inventories: "20,000 Inventory",
  //       features: [
  //         "1000 Order Requests Monthly",
  //         "Unlimited Suppliers",
  //         "Unlimited Pickers",
  //         "Unlimited Recievers / Restocking",
  //         "Bulk Import/Export",
  //         "Unlimited Notification",
  //         "Set Reorder Status",
  //         "60 day historical logs",
  //         ""
  //       ],
  //     },
  //     {
  //       id: "inventory-premier",
  //       name: "Premier",
  //       icon: InventoryPremierIcon,
  //       price: 399,
  //       inventories: "Unlimited Inventory",
  //       features: [
  //         "Unlimited Order Requests Monthly",
  //         "Unlimited Suppliers",
  //         "Bulk Import/Export",
  //         "Unlimited Picker List",
  //         "Unlimited Notification",
  //         "Unlimited Recievers / Restocking",
  //         "Set Reorder Status",
  //         "90 day historical logs",
  //         "Access to inventory support"
  //       ],
  //     },
  //   ]);
  // }

  const initiatePlans = () => {
    setPlans([
      {
        id: "inventory-free",
        name: "Free Plan",
        icon: InventoryFreeIcon,
        price: 0,
        inventories: "30 Inventory Items",
        features: [
          { name: "10 Order Requests Monthly", included: true },
          { name: "Mobile App", included: true },
          { name: "3 Suppliers", included: true },
          { name: "Bulk Import/Export", included: false },
          { name: "Unlimited Picker List", included: false },
          { name: "Unlimited Notification", included: false },
          { name: "Unlimited Recievers / Restocking", included: false },
          { name: "Set Reorder Status", included: false },
          { name: "Historical logs", included: false },
          { name: "Access to inventory support", included: false }
        ],
        handleSelect: () => {
          setOpenConfirmDialog(true);
        }
      },
      {
        id: "inventory-starter",
        name: "Starter",
        icon: InventoryStarterIcon,
        price: 49,
        inventories: "2,500 Inventory Items",
        features: [
          { name: "100 Order Requests Monthly", included: true },
          { name: "Mobile App", included: true },
          { name: "10 Suppliers", included: true },
          { name: "Unlimited Picker List", included: true },
          { name: "Unlimited Notification", included: true },
          { name: "Bulk Import/Export", included: false },
          { name: "Unlimited Recievers / Restocking", included: false },
          { name: "Set Reorder Status", included: false },
          { name: "Historical logs", included: false },
          { name: "Access to inventory support", included: false }
        ],
      },
      {
        id: "inventory-plus",
        name: "Plus",
        icon: InventoryPlusIcon,
        price: 199,
        inventories: "20,000 Inventory Items",
        features: [
          { name: "500 Order Requests Monthly", included: true },
          { name: "Mobile App", included: true },
          { name: "Unlimited Suppliers", included: true },
          { name: "Unlimited Picker List", included: true },
          { name: "Unlimited Notification", included: true },
          { name: "Bulk Import/Export", included: true },
          { name: "Unlimited Recievers / Restocking", included: true },
          { name: "Set Reorder Status", included: true },
          { name: "Historical logs", included: true },
          { name: "Access to inventory support", included: false }
        ],
      },
      {
        id: "inventory-premier",
        name: "Premier",
        icon: InventoryPremierIcon,
        price: 399,
        inventories: "Unlimited Inventory",
        features: [
          { name: "Unlimited Order Requests Monthly", included: true },
          { name: "Mobile App", included: true },
          { name: "Unlimited Suppliers", included: true },
          { name: "Unlimited Picker List", included: true },
          { name: "Unlimited Notification", included: true },
          { name: "Bulk Import/Export", included: true },
          { name: "Unlimited Recievers / Restocking", included: true },
          { name: "Set Reorder Status", included: true },
          { name: "Historical logs", included: true },
          { name: "Access to inventory support", included: true }
        ],
      },
    ]);
  };


  const selectPlan = (plan) => {
    setSelectedPlan(plan);
    if (plan.handleSelect) {
      plan.handleSelect();
    } else if (hasCreditCard) {
      setOpenConfirmDialog(true);
    } else {
      setOpenPaidPlan(true);
    }
  };

  const handleCloseFreePlan = () => {
    setOpenConfirmDialog(false);
  }

  const handleConfirmFreePlan = () => {
    setOnSubmit(true);
    axios
        .post(
          `${Application.api_url}/api/v2/inventories/subscribe_inventory_plan`,
          {
            external_id: selectedPlan.id,
          }
        )
        .then((response) => {
          if (response.data.success) {
            location.reload();
          }
        }).catch((error) => {
          const errors = error.response.data.message.credit_card_attributes || error.response.data.message;
          if (typeof errors === 'string') {
            toast.error(errors);
          } else {
            toast.error("Something went wrong. Please try again later.");
          }
        }).finally(() => {
          setOnSubmit(false);
        });
  }

  const handleModalClose = (isSuccess) => {
    if (isSuccess) {
      location.reload();
    } else {
      setOnSubmit(false);
      setOpenPaidPlan(false);
    }
  };

  const fetchPaymentMethod = () => {
    axios
      .get(`${Application.api_url}/api/v2/payment_methods`)
      .then((response) => {
        response.data.data.length > 0 && setHasCreditCard(true);
      });
  };

  useEffect(() => {
    initiatePlans();
    fetchPaymentMethod();
  }, []);

  return (
    <div class="paywall pt-4">
      <div className="paywall__container mx-5">
        <div className="paywall__header">
          <div className="paywall__header__introduction">
            <h2 className="text-white">Welcome to Inventory!</h2>
            <h1 className="text-white pl-0">Select Your Plan</h1>
            <p className="text-white">Pick a plan below that best fits your needs</p>
          </div>
        </div>
        <div className="paywall__plans-container {plans.length > 0 ? 'd-flex' : 'd-none'}">
          <div className="paywall__plans">
            {plans.map((plan) => (
              <div className="paywall__plans__card">
                <div className="paywall__plans__card__header">
                  <img src={plan.icon} alt={plan.name} className="paywall__plans__card__header__icon"/>
                  <div className="d-flex justify-content-between paywall__plans__card__header__title mt-2">
                    <h3 className="my-0">{plan.name}</h3>
                    <div className="right">
                      <h4 className="my-0">${plan.price}</h4>
                      <span>/ mo</span>
                    </div>
                  </div>
                </div>
                <div className="paywall__plans__card__features">
                  <h4 className="paywall__plans__card__features__inventories">{plan.inventories}</h4>
                  <ul>
                  {plan.features.map((feature, index) => (
                      <li className="mb-2" key={index}>
                        {feature.included ? (
                          <div>
                            <img src={InventoryFeatureTickIcon} alt="Feature" className="mr-2" />
                            <span>{feature.name}</span>
                          </div>
                        ) : (
                          <>
                            <img src={NotIncluded} alt="Not Included" className="mr-2 not-included" />
                            <span>{feature.name}</span>
                          </>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="paywall__plans__card__footer">
                  <button
                    className={"paywall__plans__card__footer__btn sticky-button" + ' ' + (User.currentInventoryPlanId == plan.id ? ' disabled' : '')}
                    onClick={() => selectPlan(plan)}
                  >
                    Select
                  </button>
                </div>
              </div>
            ))}
          </div>
          <footer style={{ fontSize: '11px', marginLeft: '95px', marginRight: '95px' }}>
          <p>
            All prices listed are in <u>Canadian dollars (CAD)</u>. Prices are subject to change with notice.
            By accessing and using this website, you agree to our terms and conditions and privacy policy.
            The content on this page is provided for informational purposes only and does not constitute a legally binding agreement.
            Inventory availability is not guaranteed and is subject to prior sale. Additional charges may apply for shipping and handling.
            Please consult with a sales representative for the most current and accurate information.
            <br />
            This product is an add-on to your company's existing subscription to SafetyLoop.  We sincerely thank you for your business.
          </p>
        </footer>
        </div>
        <Dialog open={openConfirmDialog} onClose={() => setOpen(false)}>
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to select this plan?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseFreePlan} disabled={onSubmit}>Cancel</Button>
            <Button onClick={handleConfirmFreePlan} disabled={onSubmit}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        {openPaidPlan && <CreditCardForm show={openPaidPlan} handleClose={handleModalClose} selectedPlan={selectedPlan} />}

      </div>

    </div>

  );
};

export default withRouter(Paywall);
