import React, { useEffect } from "react";

const Disclaimer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    
    
      <div className="main-wrap common-container privacy-policy">
        <div className="content">
          <div className="container-fluid">
            <div name="termly-embed" data-id="fa91b6a5-6830-4556-af5a-27a3d3021419" data-type="iframe"></div>
            
          </div>
        </div>
      </div>
    
  );
};




(function(d, s, id) {
    var js, tjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s); js.id = id;
    js.src = "https://app.termly.io/embed-policy.min.js";
    tjs.parentNode.insertBefore(js, tjs);
  }(document, 'script', 'termly-jssdk'));


export default Disclaimer;