import React, { useEffect, useMemo, useState } from "react";
import CustomTable, {
  ActionMenu,
  ButtonCreate,
  CustomBotton,
  CustomPagination,
  HeaderItem,
  TagRender,
} from "../../../shared/components/table";
import {
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import useFetch from "../../../shared/components/userFetch";
import usePatch from "../../../shared/components/usePatch";
import { showToast } from "../../../shared/components/showNotification";
import { connect } from "react-redux";
import { setPageCount } from "../../../../reducers/common";

const InvitesRequestList = ({
  handleOnFilterChange,
  filterData,
  filterState,
  setFilterState,
  handleSort,
  pageCount
}) => {

  const {
    data: responseData,
    loading,
    error,
    refetch,
  } = useFetch("/api/v2/entity_management/group_join_invites", {
    filters: filterData,
    pagination: { page: filterState.page, limit: filterState.limit },
    sort: filterState.sort,
  });
  const { mutate, isLoading: isRestoring } = usePatch(
    `/api/v2/entity_management/group_join_invites/:id/archive`
  );

  const { data = [], metadata = {} } = responseData || {};

  useEffect(() => {
    metadata.count && setPageCount({agp: {
      assigned_global_project: pageCount.agp.assigned_global_project,
      invite_pending_accept: pageCount.agp.invite_pending_accept,
      invite_request: metadata.count || pageCount.agp.invite_request
    }})
  }, [metadata.count]);

  const callRefetch = () => {
    refetch({
      filters: filterData,
      pagination: { page: filterState.page, limit: filterState.limit },
      sort: filterState.sort,
    });
  };
  useEffect(() => {
    if (!loading) {
      callRefetch()
    }
  }, [filterState]);


  const handleOk = (ele) => {
    mutate(
      { id: ele.id },
      {
        onSuccess: () => {
          showToast({ message:  "This Invite has been archieved!" });
          callRefetch();
        },
      },
      `/api/v2/entity_management/group_join_invites/${ele.id }/archive`
    );
  };

  const headers = [
    <TableCell>
      <HeaderItem
        title="Name"
        isSort
        handleSort={() => handleSort("name")}
      />
    </TableCell>,
    <TableCell>
      <HeaderItem
        title="Status"
        isSort
        handleSort={() => handleSort("status")}
      />
    </TableCell>,
    <TableCell className="right" style={{ minWidth: "100px" }}>
      <HeaderItem title="Actions" />
    </TableCell>,
  ];

  const rows = useMemo(
    () =>
      data.map((ele, index) => {
        const {
          group_name,
          status,
        } = ele || {};
        return (
          <TableRow key={index} className={index % 2 === 0 ? "even-row" : "odd-row"}>
            <TableCell>
              {group_name}
            </TableCell>
            <TableCell>
              {status}
            </TableCell>
            <TableCell className="right" style={{ minWidth: "100px" }}>
              <CustomBotton
                style={{background: '#ECECEC', color: '#5C5C5C'}}
                type="button"
                variant="contained"
                size="small"
                className='filled'
                onClick={() => handleOk(ele)}
            >
                Archive
            </CustomBotton>
            </TableCell>
          </TableRow>
        );
      }),
    [data]
  );

  return (
    <>
      <CustomTable
        headers={headers}
        rows={rows}
        isSliderScroll={false}
        // renderActiveFilter={
        //   <ActiveFilters filterData={filterState} update={setFilterState} />
        // }
      />
      {!!metadata.count && (
        <CustomPagination
          totalPage={metadata.last}
          lastPage={metadata.last}
          currentPage={filterState.page}
          handlePageChange={(_, nextPage) => {
            setFilterState((prev) => ({ ...prev, page: nextPage }));
          }}
          rowOptions={[20, 50, 100]}
          rowPerPage={filterState.limit}
          handleRowPerPageChange={(targetValue) => {
            setFilterState((prev) => ({
              ...prev,
              limit: parseInt(targetValue, 10),
              page: 1,
            }));
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
      pageCount: state.CommonReducer.pageCount
  };
};
const mapDispatchToProps = (dispatch) => ({
  setPageCount: (value) => dispatch(setPageCount(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(InvitesRequestList);