import React from "react"
import '../../assets/styles/themes.scss'
import { Controller } from "react-hook-form"

const CheckoutEInput = (props) => {
  const { control, style, name, title, type = "text", ...restProps } = props;
  return (
    <Controller
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <div className="eInput">
          <div className="label">{title}</div>
          <input
            onChange={onChange}
            value={value}
            type={type}
            {...restProps}
            style={style}
          />
          <p className="error">{error && error.message}</p>
        </div>
      )}
      name={name}
    />
  )
}

export default CheckoutEInput
