import { Box, List, ListItem, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

const ViewListWithFilter = ({title, items = [], width = '100%', style={}}) => {
    const [searchTerm, setSearchTerm] = useState('');

    const filteredItems = items.filter(item =>
        item.key.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return <Box style={{ border: '1px solid #ECECEC', borderRadius: '10px', width, ...style }}>
        <Box style={{marginBottom: 0, background: '#F6F6F6', padding: '5px'}} display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h6" style={{fontSize: '14px', fontWeight: 700, color: '#5C5C5C'}}>
                {title} ({filteredItems.length})
            </Typography>
            <TextField
                variant="outlined"
                size="small"
                sx={{width: '170px', background: '#fff'}}
                placeholder="Search..."
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
            />
        </Box>
        <List style={{minHeight: '100px', maxHeight: '400px', overflowY: 'auto', paddingTop: 0, paddingBottom: 0}}>
            {filteredItems.map((item, index) => (
                <ListItem key={`${item.key}-${index}`} style={{fontSize: '14px', borderBottom: '1px solid #ECECEC'}}>
                    {item.key}{item.type ? ' - ' : ''}{item.type ? <span style={{color: '#909090'}}>&nbsp;{item.type}</span> : ''}
                </ListItem>
            ))}
        </List>
    </Box>
};

export default ViewListWithFilter;
