import React, { useEffect, useRef, useState, useContext } from "react";
import Pagination from "@mui/material/Pagination";
import Cookies from "js-cookie";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { AssetDetailsContext } from "./index";
import Application from "../../../../config/ApplicationWrapper";
import { toast } from "react-toastify";
import Activate from "../../../../assets/images/activate-black.svg";
import CreateMaintenance from "../Popups/Maintenance";
import EmptyData from "../../../../assets/images/empty-assets.svg";

const MaintenanceSchedule = ({ maintanceChangeTime }) => {
  const [maintenanceData, setMaintenanceData] = useState([]);
  const { pageData } = useContext(AssetDetailsContext);

  const cookieDetails = Cookies.get("token");

  // Pagination
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [pagination, setPagination] = useState(true);
  const [pageCounts, setPageCounts] = useState(0);

  const [lastUpdate, setLastUpdate] = useState(0);
  const [maintenancePopup, setMaintenancePopup] = useState(false);
  const [editData, setEditData] = useState(false);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const updateResults = (resultObj) => {
    let records = resultObj.data;
    setMaintenanceData([...records]);
    // records.length > 0 ? setAssetAction(true) : null
    pagination === false ? setPagination(true) : null;
    setPageCounts(resultObj.metadata ? resultObj.metadata.pages : 0);
  };

  const urlParams = () => {
    return new URLSearchParams({
      page: page,
      pagination: true,
      limit: limit,
    }).toString();
  };

  const getMaintenanceScheduleDetails = async (asstId) => {
    try {
      let url = new URL(
        `${Application.api_url}/api/v2/asset_trackings/${asstId}/maintenance_schedules`
      );
      url.search = urlParams();
      fetch(url, {
        method: "GET",
        headers: { Authorization: cookieDetails },
      }).then(async (response) => {
        let resultObj = await response.json();
        if (response.status === 200) {
          updateResults(resultObj);
        }
      });
    } catch (err) {
      console.log("error===>", err);
    }
  };

  useEffect(() => {
    getMaintenanceScheduleDetails(pageData.id);
  }, [limit, page, pageData, lastUpdate, maintanceChangeTime]);

  const openMaintenancePopup = (data) => {
    setEditData(data);
    setTimeout(function () {
      setMaintenancePopup(true);
    },2000)
  };

  const closeMaintenancepopup = (data) => {
    setMaintenancePopup(false);
    if (data) {
      toast.success("Updated Successfully");
      setLastUpdate(Date.now());
    }
    setEditData(false)
  };

  const removeAssetsMaintance = async (assetId) => {
    const groupDetails = await fetch(
      `${Application.api_url}/api/v2/asset_trackings/${pageData.id}/maintenance_schedules/${assetId}`,
      {
        method: "DELETE",
        headers: { Authorization: cookieDetails },
      }
    );
    setLastUpdate(Date.now());
    toast.success("Deleted Successfully");
  };

  const updateStatus = async (assetId) => {
    const groupDetails = await fetch(
      `${Application.api_url}/api/v2/asset_trackings/${pageData.id}/maintenance_schedules/${assetId}/update_status`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: cookieDetails,
        },
        body: JSON.stringify({ status: "Activated" }),
      }
    );
    setLastUpdate(Date.now());
    toast.success("Updated Successfully");
  };

  const NoAssetsFound = () => (
    <div className="no-assets">
      <div className="d-flex">
        <div className="container">
          <img src={EmptyData} />
          <p>
            <b>No Data</b>
          </p>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <div className="table-template">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">
                <div className="d-flex justify-content-between">
                  Maintenance Name
                </div>
              </th>
              <th scope="col">
                <div className="d-flex justify-content-between">
                  Maintenance Service Intervals
                </div>
              </th>
              <th scope="col">
                <div className="d-flex justify-content-between">
                  Location of Maintenance
                </div>
              </th>
              <th scope="col">
                <div className="d-flex justify-content-between">
                  Responsible Party
                </div>
              </th>
              {/* <th scope="col">
                <div className="d-flex justify-content-between">
                  Maintenance Status
                </div>
              </th> */}
              <th scope="col" style={{ textAlign: "center" }}>
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {maintenanceData &&
              maintenanceData.length > 0 &&
              maintenanceData.map((maintenance, index) => (
                <tr key={index}>
                  <td>{maintenance.name}</td>
                  <td>{maintenance.service_intervals} {maintenance.unit}</td>
                  <td>{maintenance.location}</td>
                  <td>
                    <div className="responsible_parties">
                      {maintenance.responsible_parties &&
                        maintenance.responsible_parties.map((obj, index) => (
                          <span
                            className={`badge badge-light responsible-party`}
                          >
                            {obj.full_name}
                          </span>
                        ))}
                    </div>
                  </td>
                  {/* <td>{maintenance.status}</td> */}
                  <td
                    className="template-options"
                    style={{ textAlign: "center" }}
                  >
                    <a
                      aria-expanded="false"
                      aria-haspopup="true"
                      className="btn dropdown-toggle"
                      data-toggle="dropdown"
                      href="#"
                      id="dropdownMenuLink"
                      role="button"
                    >
                      <i className="fa fa-ellipsis-v"></i>
                    </a>
                    <ul
                      aria-labelledby="dropdownMenuLink"
                      className="dropdown-menu"
                    >
                      <li>
                        {/* <a
                          className="archive_group_invites"
                          data-toggle="tooltip"
                          onClick={(e) => {
                            updateStatus(maintenance.id);
                          }}
                          title="Activate-Maintenance"
                        >
                          <div className="row" style={{ display: "flex" }}>
                            <div className="col-sm-3">
                              <img src={Activate} />
                            </div>
                            <div className="col-sm-9">Activate Maintenance</div>
                          </div>
                        </a> */}
                        <a
                          className="archive_group_invites clickable"
                          data-toggle="tooltip"
                          onClick={(e) => {
                            openMaintenancePopup(maintenance);
                          }}
                          title="Edit"
                        >
                          <div className="row" style={{ display: "flex" }}>
                            <div className="col-sm-3">
                              <i
                                className="fa fa-pencil-square-o"
                                style={{
                                  paddingTop: "5px",
                                  fontSize: "16px",
                                }}
                              ></i>
                            </div>
                            <div
                              className="col-sm-9"
                              style={{ paddingTop: "5px" }}
                            >
                              Edit
                            </div>
                          </div>
                        </a>
                        <a
                          className="archive_group_invites"
                          data-toggle="tooltip"
                          onClick={() => removeAssetsMaintance(maintenance.id)}
                          title="Delete"
                        >
                          <div className="row" style={{ display: "flex" }}>
                            <div className="col-sm-3">
                              <i
                                className="fa fa-trash"
                                style={{
                                  paddingTop: "5px",
                                  fontSize: "16px",
                                  color: "#FF3030",
                                }}
                              ></i>
                            </div>
                            <div
                              className="col-sm-9"
                              style={{
                                paddingTop: "5px",
                                color: "#FF3030",
                              }}
                            >
                              Delete
                            </div>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>
              ))}
              {maintenanceData.length === 0 && (
              <tr>
                <td colSpan={6}>
                  <NoAssetsFound />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {maintenanceData && maintenanceData.length > 0 && (
        <div
          className="d-flex justify-content-between"
          style={{ alignItems: "center" }}
        >
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={limit}
              onChange={(e) => {
                setLimit(e.target.value);
                setPage(1);
                setPagination(false);
              }}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
            </Select>
          </FormControl>
          {pagination ? (
            <Pagination
              count={pageCounts}
              variant="outlined"
              shape="rounded"
              onChange={handleChangePage}
            />
          ) : null}
        </div>
      )}
      {editData && (
        <CreateMaintenance
          show={maintenancePopup}
          closepopup={closeMaintenancepopup}
          data={editData}
          parentId={pageData.id}
        />
      )}
    </div>
  );
};

export default MaintenanceSchedule;
