import { Box, Grid, List, ListItem, ListItemText, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

const LisContainer = ({ data = [], title = '' }) => {
    const [searchItem, setSearchItem] = useState('')
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setFilteredData(data);
  }, [data])

  const handleInputChange = (e) => { 
    const searchTerm = e.target.value;
    setSearchItem(searchTerm)

    const filteredItems = data.filter((item) =>
        item.key.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredData(filteredItems);
  }
  return (
    <>
      <Grid item xs={4} md={4}>
        <Box display="flex" justifyContent="space-between">
          <Box
            width="100%"
            style={{
              border: "1px solid #F6F6F6",
              borderRadius: "5px",
              background: "#ECECEC",
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              className="searchBoxContainer"
            >
              <div className="searchBox">
                <span className="box-title">{title}</span>{" "}
                <span className="count">{filteredData.length}</span>
              </div>
              <TextField
                placeholder="Search"
                value={searchItem}
                onChange={(e) => handleInputChange(e)}
                fullWidth
                size="small"
              />
            </Box>
            <Box style={{ background: "#ECECEC",  minHeight: "600px" }}>
              <List
                style={{
                  maxHeight: "600px",
                  minHeight: "600px",
                  overflow: "auto",
                  background: "#FFF",
                }}
              >
                {filteredData.map((item) => (
                  <ListItem key={item.value} dense>
                    <ListItemText className="list-item" primary={item.key} />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default LisContainer;
