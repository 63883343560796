import React from 'react';
import Select from 'react-select';
import { BrowserRouter as Router, Route, Link, withRouter,Redirect, NavLink} from "react-router-dom";
import Login, {User} from "../../pages/HomePage/Login";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
var axios = require('axios');

const initialState = {
    summary: null,
    client: [],
    result:[],
    provinces: [],
    fields: {company: '', province:'', chart_type: ""}
};

class CORFilters extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialState
        this.handleChange = this.handleChange.bind(this);
        this.resetfilter = this.resetfilter.bind(this);
    }

    handleChange(field, e){
        console.log(this.props)
        let fields = this.state.fields;
        fields[field] = e == null ? '' : e.value;
        this.setState({fields});
        if (e != null){
            this.props.onFilterChange(e.value, this);
        }

    }

    resetfilter(){
        var that = this;
        let fields = this.state.fields;
        $.each(fields, function (i, value) {fields[i] = ""});
        this.setState({fields});
        this.props.onFilterChange("", this);
    }


    renderCompanyFilter(filters){
        if ( User.role == "Admin"){
            return(
                <div>
                    <div className="col-sm-12">
                        <label className="control-label">Company</label>
                        <Select
                        name="form-field-name"
                        value={this.state.fields["company"]}
                        onChange={this.handleChange.bind(this,"company")}
                        options={filters ? filters.company : filters}
                        id="company"
                        clearable={false}
                        />
                    </div>
                </div>
                )
        }
    }

render() {
    const { filters } = this.props;

    return (
        <div className="col-sm-2 side-nav">

            <div className="inner-side-nav">

                <div className="row">
                    <div className="rot_admin_view" style={{paddingBottom: "33px", paddingLeft: "15px"}}>COR Audit Scoring</div>
                 </div>

                <div className="row">

                    {this.renderCompanyFilter(filters)}

                    <div className="col-sm-12">
                        <label className="control-label">Province</label>
                        <Select
                        name="form-field-name"
                        value={this.state.fields["province"]}
                        onChange={this.handleChange.bind(this,"province")}
                        options={filters ? filters.province : filters}
                        id="province"
                        clearable={false}
                        />
                    </div>

                    <div className="col-sm-12 filter_button">
                        <button type="button" className="btn btn-primary" onClick={this.resetfilter.bind()}>Reset Filter</button>
                    </div>
                </div>
            </div>
        </div>
        );
}
}

export default CORFilters;