export const Time_zones_list = [
  { "id": 1, "label": "(GMT-12:00) International Date Line West", "value": "Etc/GMT+12" },
  { "id": 2, "label": "(GMT-11:00) Midway Island, Samoa", "value": "Pacific/Midway" },
  { "id": 3, "label": "(GMT-10:00) Hawaii", "value": "Pacific/Honolulu" },
  { "id": 4, "label": "(GMT-09:00) Alaska", "value": "US/Alaska" },
  { "id": 5, "label": "(GMT-08:00) Pacific Time (US & Canada)", "value": "America/Los_Angeles" },
  { "id": 6, "label": "(GMT-08:00) Tijuana, Baja California", "value": "America/Tijuana" },
  { "id": 7, "label": "(GMT-07:00) Arizona", "value": "US/Arizona" },
  { "id": 8, "label": "(GMT-07:00) Chihuahua, La Paz, Mazatlan", "value": "America/Chihuahua" },
  { "id": 9, "label": "(GMT-07:00) Mountain Time (US & Canada)", "value": "US/Mountain" },
  { "id": 10, "label": "(GMT-06:00) Central America", "value": "America/Managua" },
  { "id": 11, "label": "(GMT-06:00) Central Time (US & Canada)", "value": "US/Central" },
  { "id": 12, "label": "(GMT-06:00) Guadalajara, Mexico City, Monterrey", "value": "America/Mexico_City" },
  { "id": 13, "label": "(GMT-06:00) Saskatchewan", "value": "Canada/Saskatchewan" },
  { "id": 14, "label": "(GMT-05:00) Bogota, Lima, Quito, Rio Branco", "value": "America/Bogota" },
  { "id": 15, "label": "(GMT-05:00) Eastern Time (US & Canada)", "value": "US/Eastern" },
  { "id": 16, "label": "(GMT-05:00) Indiana (East)", "value": "US/East-Indiana" },
  { "id": 17, "label": "(GMT-04:00) Atlantic Time (Canada)", "value": "Canada/Atlantic" },
  { "id": 18, "label": "(GMT-04:00) Caracas, La Paz", "value": "America/Caracas" },
  { "id": 19, "label": "(GMT-04:00) Manaus", "value": "America/Manaus" },
  { "id": 20, "label": "(GMT-04:00) Santiago", "value": "America/Santiago" },
  { "id": 21, "label": "(GMT-03:30) Newfoundland", "value": "Canada/Newfoundland" },
  { "id": 22, "label": "(GMT-03:00) Brasilia", "value": "America/Sao_Paulo" },
  { "id": 23, "label": "(GMT-03:00) Buenos Aires, Georgetown", "value": "America/Argentina/Buenos_Aires" },
  { "id": 24, "label": "(GMT-03:00) Greenland", "value": "America/Godthab" },
  { "id": 25, "label": "(GMT-03:00) Montevideo", "value": "America/Montevideo" },
  { "id": 26, "label": "(GMT-02:00) Mid-Atlantic", "value": "America/Noronha" },
  { "id": 27, "label": "(GMT-01:00) Cape Verde Is.", "value": "Atlantic/Cape_Verde" },
  { "id": 28, "label": "(GMT-01:00) Azores", "value": "Atlantic/Azores" },
  { "id": 29, "label": "(GMT+00:00) Casablanca, Monrovia, Reykjavik", "value": "Africa/Casablanca" },
  { "id": 30, "label": "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London", "value": "Etc/Greenwich" },
  { "id": 31, "label": "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna", "value": "Europe/Amsterdam" },
  { "id": 32, "label": "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague", "value": "Europe/Belgrade" },
  { "id": 33, "label": "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris", "value": "Europe/Brussels" },
  { "id": 34, "label": "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb", "value": "Europe/Sarajevo" },
  { "id": 35, "label": "(GMT+01:00) West Central Africa", "value": "Africa/Lagos" },
  { "id": 36, "label": "(GMT+02:00) Amman", "value": "Asia/Amman" },
  { "id": 37, "label": "(GMT+02:00) Athens, Bucharest, Istanbul", "value": "Europe/Athens" },
  { "id": 38, "label": "(GMT+02:00) Beirut", "value": "Asia/Beirut" },
  { "id": 39, "label": "(GMT+02:00) Cairo", "value": "Africa/Cairo" },
  { "id": 40, "label": "(GMT+02:00) Harare, Pretoria", "value": "Africa/Harare" },
  { "id": 41, "label": "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius", "value": "Europe/Helsinki" },
  { "id": 42, "label": "(GMT+02:00) Jerusalem", "value": "Asia/Jerusalem" },
  { "id": 43, "label": "(GMT+02:00) Minsk", "value": "Europe/Minsk" },
  { "id": 44, "label": "(GMT+02:00) Windhoek", "value": "Africa/Windhoek" },
  { "id": 45, "label": "(GMT+03:00) Kuwait, Riyadh, Baghdad", "value": "Asia/Kuwait" },
  { "id": 46, "label": "(GMT+03:00) Moscow, St. Petersburg, Volgograd", "value": "Europe/Moscow" },
  { "id": 47, "label": "(GMT+03:00) Nairobi", "value": "Africa/Nairobi" },
  { "id": 48, "label": "(GMT+03:00) Tbilisi", "value": "Asia/Tbilisi" },
  { "id": 49, "label": "(GMT+03:30) Tehran", "value": "Asia/Tehran" },
  { "id": 50, "label": "(GMT+04:00) Abu Dhabi, Muscat", "value": "Asia/Muscat" },
  { "id": 51, "label": "(GMT+04:00) Baku", "value": "Asia/Baku" },
  { "id": 52, "label": "(GMT+04:00) Yerevan", "value": "Asia/Yerevan" },
  { "id": 53, "label": "(GMT+04:30) Kabul", "value": "Asia/Kabul" },
  { "id": 54, "label": "(GMT+05:00) Yekaterinburg", "value": "Asia/Yekaterinburg" },
  { "id": 55, "label": "(GMT+05:00) Islamabad, Karachi, Tashkent", "value": "Asia/Karachi" },
  { "id": 56, "label": "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi", "value": "Asia/Calcutta" },
  { "id": 57, "label": "(GMT+05:30) Sri Jayawardenapura", "value": "Asia/Calcutta" },
  { "id": 58, "label": "(GMT+05:45) Kathmandu", "value": "Asia/Katmandu" },
  { "id": 59, "label": "(GMT+06:00) Almaty, Novosibirsk", "value": "Asia/Almaty" },
  { "id": 60, "label": "(GMT+06:00) Astana, Dhaka", "value": "Asia/Dhaka" },
  { "id": 61, "label": "(GMT+06:30) Yangon (Rangoon)", "value": "Asia/Rangoon" },
  { "id": 62, "label": "(GMT+07:00) Bangkok, Hanoi, Jakarta", "value": "Asia/Bangkok" },
  { "id": 63, "label": "(GMT+07:00) Krasnoyarsk", "value": "Asia/Krasnoyarsk" },
  { "id": 64, "label": "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi", "value": "Asia/Hong_Kong" },
  { "id": 65, "label": "(GMT+08:00) Kuala Lumpur, Singapore", "value": "Asia/Kuala_Lumpur" },
  { "id": 66, "label": "(GMT+08:00) Irkutsk, Ulaan Bataar", "value": "Asia/Irkutsk" },
  { "id": 67, "label": "(GMT+08:00) Perth", "value": "Australia/Perth" },
  { "id": 68, "label": "(GMT+08:00) Taipei", "value": "Asia/Taipei" },
  { "id": 69, "label": "(GMT+09:00) Osaka, Sapporo, Tokyo", "value": "Asia/Tokyo" },
  { "id": 70, "label": "(GMT+09:00) Seoul", "value": "Asia/Seoul" },
  { "id": 71, "label": "(GMT+09:00) Yakutsk", "value": "Asia/Yakutsk" },
  { "id": 72, "label": "(GMT+09:30) Adelaide", "value": "Australia/Adelaide" },
  { "id": 73, "label": "(GMT+09:30) Darwin", "value": "Australia/Darwin" },
  { "id": 74, "label": "(GMT+10:00) Brisbane", "value": "Australia/Brisbane" },
  { "id": 75, "label": "(GMT+10:00) Canberra, Melbourne, Sydney", "value": "Australia/Canberra" },
  { "id": 76, "label": "(GMT+10:00) Hobart", "value": "Australia/Hobart" },
  { "id": 77, "label": "(GMT+10:00) Guam, Port Moresby", "value": "Pacific/Guam" },
  { "id": 78, "label": "(GMT+10:00) Vladivostok", "value": "Asia/Vladivostok" },
  { "id": 79, "label": "(GMT+11:00) Magadan, Solomon Is., New Caledonia", "value": "Asia/Magadan" },
  { "id": 80, "label": "(GMT+12:00) Auckland, Wellington", "value": "Pacific/Auckland" },
  { "id": 81, "label": "(GMT+12:00) Fiji, Kamchatka, Marshall Is.", "value": "Pacific/Fiji" },
  { "id": 82, "label": "(GMT+13:00) Nuku'alofa", "value": "Pacific/Tongatapu" }
]
