import { User } from '../pages/HomePage/Login';
import { subscribeToUploadStatus } from '../channels/upload_status_channel';
import { toast } from "react-toastify";

function initializeUploadStatus(totalFiles) {
  let user = localStorage.getItem('user');
  user = JSON.parse(user);
  let formData = new FormData();

  formData.append('total_files', totalFiles);
  formData.append('user_id', user.id);

  return new Promise((resolve, reject) => {
    $.ajax({
      url: 'upload_statuses',
      type: 'POST',
      data: formData,
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      processData: false,
      contentType: false,
      success: function (response) {
        User.currentBulkUploadId = response.data.upload_status_id;
        // console.log('Upload status id:', response.data.upload_status_id);
        subscribeToUploadStatus(user.id, response.data.upload_status_id);
        resolve(response.data.upload_status_id);
      },
      error: function (error) {
        reject(error);
      }
    })
  })
}

export function uploadFiles(dataTransferItems, parentId, onClose, onProgressUpdate) {
  let files = [];
  let entriesPromises = [];
  let metadata = { count: 0 }
  for (let it of dataTransferItems) {
    entriesPromises.push(
        traverseFileTreePromise(it.webkitGetAsEntry(), '', files, metadata)
    );
  }
  let user = localStorage.getItem('user');
  user = JSON.parse(user);

  Promise.all(entriesPromises).then(() => {
    const totalFiles = metadata.count;
    initializeUploadStatus(totalFiles).then(uploadStatusId => {
      handleFileAndFolderUploads(files, parentId, uploadStatusId, totalFiles, [] , onProgressUpdate, onClose)
          .then(() => {
            // Trigger success notification once all uploads are done
            // toast.success("All files uploaded successfully!");
            // setTimeout(() => {
            //   onClose(true)
            // }, 500)
          })
          .catch(error => {
            console.error("Error during upload:", error);
            toast.error("Some files failed to upload. Please try again.");
          });
    });
  }).then(() => {
    // toast.success("Upload is in progress!");
  })
}

function handleFileAndFolderUploads(entries, parentId, uploadStatusId, totalFiles, completedFiles, onProgressUpdate, onCloseModal) {
  const uploadPromises = entries.map(entry => {
    if (entry.folder_name) {
      return createFolder(entry, parentId, uploadStatusId).then(response => {
        return handleFileAndFolderUploads(entry.sub_folder, response.parent_id, uploadStatusId, totalFiles, completedFiles, onProgressUpdate, onCloseModal);
      })
    } else if (entry.file_buffer) {
      const file = new File([entry.file_buffer], entry.file_name);

      return uploadFile(file, entry.relative_path, parentId, uploadStatusId, totalFiles, completedFiles, onProgressUpdate, onCloseModal)
    }
    // setUploadProgress(Math.round((completedFiles / totalFiles) * 100));
  })

  return Promise.all(uploadPromises);
}

function createFolder(entry, parentId, uploadStatusId) {
  return new Promise((resolve, reject) => {
    let user = localStorage.getItem('user');
    user = JSON.parse(user);
    const newParentId = parentId == 0 ? null : parentId;

    const folderFormData = new FormData();
    folderFormData.append('folder_name', entry.folder_name);
    folderFormData.append('relative_path', entry.relative_path);
    folderFormData.append('parent_id', newParentId);
    folderFormData.append('is_drag_folder', true);
    folderFormData.append('user_id', user.id)
    folderFormData.append('upload_status_id', uploadStatusId)

    $.ajax({
      url: `/company_documents/${user.company_id}/upload`,
      type: 'PATCH',
      data: folderFormData,
      processData: false,
      contentType: false,
      success: function(response) {
        resolve(response);
      },
      error: function (error) {
        console.error('Error creating folder:', error);
        reject(error);
      }
    });
  })
}

function uploadFile(file, relativePath, parentId, uploadStatusId, totalFiles, completedFiles, onProgressUpdate, onCloseModal) {
  const totalSize = file.size;
  const chunkSize = 1024 * 1024; // 1MB chunk size
  const concurrentUploads = 3; // Number of concurrent uploads
  const chunks = [];

  // Split the file into chunks
  for (let chunkStart = 0; chunkStart < totalSize; chunkStart += chunkSize) {
    chunks.push({
      chunkStart,
      chunk: file.slice(chunkStart, Math.min(chunkStart + chunkSize, totalSize)),
    });
  }

  // Create a queue for sequential processing
  const queue = Array.from(chunks);
  const activePromises = new Set();

  function processChunk() {
    if (queue.length === 0 && activePromises.size === 0) {
      const completedFilesCount = completedFiles.push(file.name);
      console.log(completedFilesCount, totalFiles)
      if (completedFilesCount === totalFiles)
        setTimeout(() => {
          onCloseModal(true)
        }, 500)

      onProgressUpdate({ count: Math.round((completedFilesCount / totalFiles) * 100), name: file.name});
      // toast.success(`${file.name} Upload complete!`);
      return;
    }

    while (queue.length > 0 && activePromises.size < concurrentUploads) {
      const { chunkStart, chunk } = queue.shift();
      const uploadPromise = uploadChunk(file, chunk, chunkStart, chunkSize, totalSize, file.name, relativePath, parentId, uploadStatusId, totalFiles)
          .then(() => {
            // console.log(`Successfully uploaded chunk at ${chunkStart}`);
          })
          .catch((error) => {
            console.error(`Error uploading chunk at ${chunkStart}:`, error);
            // Optionally re-add the chunk to the queue for retry
            queue.push({ chunkStart, chunk });
          })
          .finally(() => {
            activePromises.delete(uploadPromise);
            processChunk(); // Start the next chunk
          });

      activePromises.add(uploadPromise);
    }
  }

  processChunk(); // Start processing the chunks
}

function uploadChunk(file, chunk, chunkStart, chunkSize, totalSize, fileName, relativePath, parentId, uploadStatusId, totalFiles) {
  let user = JSON.parse(localStorage.getItem('user'));

  const formData = new FormData();
  formData.append('chunk', chunk, fileName);
  formData.append('chunkStart', chunkStart);
  formData.append('totalSize', totalSize);
  formData.append('is_drag_folder', true);
  formData.append('relativePath', relativePath);
  formData.append('parent_id', parentId);
  formData.append('upload_status_id', uploadStatusId);
  formData.append('total_files', totalFiles);
  formData.append('user_id', user.id);

  return new Promise((resolve, reject) => {
    $.ajax({
      url: `/company_documents/${user.company_id}/upload`,
      type: 'PATCH',
      data: formData,
      processData: false,
      contentType: false,
      dataType: 'json',
      success: (data, status) => {
        if (status === "success") {
          resolve();
        } else {
          reject(new Error("Chunk upload failed"));
        }
      },
      error: function (xhr, status, error) {
        reject(error);
      },
    });
  });
}

function updateUploadStatus(uploadStatusId, status = 'failed') {
  $.ajax({
    url: `/upload_statuses/${uploadStatusId}`,
    method: 'PATCH',
    data: formData,
    processData: false,
    contentType: false,
    dataType: 'json',
    success: function (data, status) {
      toast.warning('Recent upload failed...');
      $('.recent-upload-container').fadeOut();
    },
  })
}

function traverseFileTreePromise(item, path = '', folder, metadata = {}) {
  return new Promise(resolve => {
    if (item.isFile) {
      item.file(file => {
        // console.log(`Traverse - ${metadata.count} - ${file.name}`)
        let reader = new FileReader();
        reader.onloadend = () => {
            if (file.name.startsWith('.')) {
              resolve();
            } else {
              if (file.size > 0){
                folder.push({ file_buffer: reader.result, file_name: file.name, relative_path: path + file.name });
                metadata.count += 1;
              }
              resolve();
            }
        }
        reader.onerror = () => {
          console.error(`Failed to read file: ${file.name}`)
          metadata.count -= 1;
          resolve();
        }
        reader.readAsArrayBuffer(file)
      });
    } else if (item.isDirectory) {
      let dirReader = item.createReader();
      let entriesPromises = [];
      let sub_folder = [];
      folder.push({ folder_name: item.name, relative_path: path + item.name + '/', sub_folder: sub_folder });

      function readAllEntries() {
        dirReader.readEntries(entries => {
          if (entries.length > 0) {
            for (const entry of entries) {
              entriesPromises.push(traverseFileTreePromise(entry, path + item.name + '/', sub_folder, metadata));
            }
            readAllEntries();
          } else {
            Promise.all(entriesPromises).then(() => {
              resolve()
            });
          }
        })
      }

      readAllEntries();
    }
  })
}

