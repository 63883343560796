import React from 'react';
import HeroSection from './CommonComponents/HeroSection';
import FeaturesSection from './CommonComponents/FeaturesSection';
import ParallaxSection from '../../pages/HomePage/Components/ParallaxSection';
import HomepageDemoSection from '../../pages/HomePage/Components/HomepageDemoSection';
import HeroBannerImage from "../../assets/images/inventory_management.png";

export default function InventoryManagement() {
    return (
        <div className="corrective-actions-section">
            <img src={HeroBannerImage}  />
        </div>
    );
}
