import React from 'react';

const TrashIcon = ({ onDragOver, onDrop }) => (
    <div
        className="trash-icon"
        onDragOver={onDragOver}
        onDrop={onDrop}
        style={{
            width: '24px',
            height: '24px',
            textAlign: 'center',
            lineHeight: '24px',
        }}
    >
        <svg
            width="24"
            height="24"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_8286_51852)">
                <path
                    d="M3.3335 5.83398H16.6668"
                    stroke="#FF3030"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.3335 9.16699V14.167"
                    stroke="#FF3030"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.6665 9.16699V14.167"
                    stroke="#FF3030"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.1665 5.83398L4.99984 15.834C4.99984 16.276 5.17543 16.6999 5.48799 17.0125C5.80055 17.3251 6.22448 17.5007 6.6665 17.5007H13.3332C13.7752 17.5007 14.1991 17.3251 14.5117 17.0125C14.8242 16.6999 14.9998 16.276 14.9998 15.834L15.8332 5.83398"
                    stroke="#FF3030"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.5 5.83382V3.33382C7.5 3.11281 7.5878 2.90085 7.74408 2.74457C7.90036 2.58829 8.11232 2.50049 8.33333 2.50049H11.6667C11.8877 2.50049 12.0996 2.58829 12.2559 2.74457C12.4122 2.90085 12.5 3.11281 12.5 3.33382V5.83382"
                    stroke="#FF3030"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_8286_51852">
                    <rect width="20" height="20" fill="white" transform="translate(0 0.000488281)"/>
                </clipPath>
            </defs>
        </svg>
    </div>
);

export default TrashIcon;
