import React from 'react';
import HeroBannerImage from "../../assets/images/contractor_management.png";


const ContractorManagement = () => {
    return (
        <div className="contractor-management-section">
            
            <img src={HeroBannerImage}  />
            
        </div>
    );
};

export default ContractorManagement;
