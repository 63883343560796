import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Button, TextField, Autocomplete, FormHelperText } from '@mui/material';
import axios from "../../../config/axiosConfig";
import Application from "../../../config/ApplicationWrapper";
import { toast } from "react-toastify";

export default function CreateQR(props) {
    const { show, closepopup, details } = props;
    const [group, setGroup] = useState([]);
    const [groupsList, setGroupsList] = useState([]);
    const [onSubmit, setSubmit] = useState(false);

    useEffect(() => {
        async function fetchGroup() {
            const groupDetails = await axios.get(`${Application.api_url}/api/v2/common/groups`);
            if (groupDetails.status == 200 && groupDetails.data.success === true) {
                var details = groupDetails.data;
                setGroupsList(details.data);
            }
        }
        fetchGroup()
    }, [])

    const onhandleStatusClose = () => {
        closepopup();
    };

    const create = async () => {
        setSubmit(true);
        const formData = new FormData(); 
        [...group].forEach(obj => {
            formData.append("qr_code_groups[]", obj.id);
        });

        const result = await axios({
            method: "POST",
            url: `${Application.api_url}/api/group_qr_codes`,
            data: formData,
        });

        if (result.data && result.data.success === true) {
            toast.success("Created Successfully")
            closepopup();
        }
    }

    return (
        <Modal
            show={show}
            onHide={(e) => {
                onhandleStatusClose();
            }}
            className="qr-modal-wrap add-task-modal pf-model"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="pf-model-header">
                        <p className="pf-model-title"> New QR Code </p>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="row">
                        <div className="col-md-12">
                            <label>
                                Groups <text style={{ color: "red", fontSize:"16px" }}>*</text>
                            </label>
                            <Autocomplete
                                multiple={true}
                                limitTags={2}
                                id="multiple-limit-tags"
                                disableCloseOnSelect
                                options={groupsList}
                                getOptionLabel={(options) => options.name}
                                defaultValue={[]}
                                onChange={(event, value) => setGroup(value)}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderOption={(props, option) => <li {...props} key={option.id}>{option.name}</li>}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                            />
                            <FormHelperText
                                className="inputErrorMsg"
                                error={group.length === 0 && onSubmit}
                            >
                                {group.length === 0 && onSubmit === true ? "Group is required" : ""}
                            </FormHelperText>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="submit-btns">
                    <Button className="btn-cancel" onClick={(e) => { onhandleStatusClose() }}>Cancel</Button>
                    <Button variant="contained" onClick={(e) => { create() }}>
                        Generate QR Code
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}
