import React, {useState} from 'react'
import {Form, Modal, FormGroup, FormControl, Button} from "react-bootstrap";
import {orderItemSingleUpdate} from "../utils";
import {User} from "../../../HomePage/Login";

const AddCommentModal = ({show, close, itemId}) => {
  const [content, setContent] = useState('')

  const saveComment = async () => {
    await orderItemSingleUpdate(itemId, {
      notes_attributes: [{
        content
      }]
    })
    close()
  }

  return (
    <Modal
      style={{marginTop:'100px'}}
      show={show}
      onHide={(_) => close()}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Add Comment
        </Modal.Title>
        <Modal.Body>
          <Form>
            <FormGroup>
              <FormControl type="text" name="content" onChange={(e) => setContent(e.target.value)} required></FormControl>
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="btn-secondary" onClick={close}>
            Close
          </Button>

          <Button variant="primary" className="btn-primary" onClick={saveComment}>
            Save
          </Button>
        </Modal.Footer>
      </Modal.Header>
    </Modal>
  )
}

export default AddCommentModal