import React, { useEffect, useRef, useState, useReducer } from "react";
import { Tabs, Tab } from 'react-bootstrap'
import { withRouter } from "react-router-dom";
import ActiveOrders from "./components/ActiveOrders";
import Backorders from "./components/Backorders";

const OrderManagement = () => {
  const [activeNestedTab, setActiveNestedTab] = useState('active');

  const handleNestedTabClick = (tab) => {
    setActiveNestedTab(tab);
  }

  return (
    <div>
      <Tabs
        id="nested-tabs"
        activeKey={activeNestedTab}
        onSelect={(k) => setActiveNestedTab(k)}
        className="mb-3"
      >
        <Tab
          eventKey="active"
          title="Active"
        >
          <ActiveOrders />
        </Tab>
        <Tab
          eventKey="backorder"
          title="Backorder"
        >
          <Backorders />
        </Tab>
      </Tabs>
    </div>
  )
};

export default withRouter(OrderManagement);
