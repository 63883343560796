import React, { Fragment } from "react";
import Highcharts from 'highcharts/highstock';
import Chart from "../../components/Chart";

const FormsPerDayChart = ({stats, query, onFilterSelect, error}) => {
    const handleChange = (event, data) => {
        let date = new Date(event.x);
        let audit_date =  (date.getMonth() + 1) + '/' + date.getDate() + '/' +  date.getFullYear()

        let params = { ['chart_type']: 'daily',  ['hourly_chart']: '', date: audit_date}
        if (query['chart_type'] == 'form_types'){
            params['template_id'] = ''
        }
        onFilterSelect(Object.assign({}, query, params))
    };

    const options = {
        chart: {
            alignTicks: false
        },
        rangeSelector: {
            selected: 0
        },
        title: {
            text: "Forms Per Day"
        },

        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                turboThreshold: 0,
                cursor: "pointer",
                point: {
                    events: {
                        click: function (e) {
                            handleChange(this, query)
                        }
                    }
                }
            }
        },
        credits: {
            enabled: false
        },
        series: [{
            name: "Total Audits",
            type: "column",
            // data: stats.forms_per_day,
            data: stats,
            dataGrouping: {
                units: [
                    ["week", [1]],
                    ["month", [1, 2, 3, 4, 6]]
                ]
            }
        }],
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: [
                        "viewFullscreen", "printChart", "separator", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"
                    ]
                },
            },
            enabled: true,
        },
    }

    return(
        <Fragment>
            <Chart options={options} type={'stockChart'} highcharts={Highcharts} />
        </Fragment>
    )
}

export default FormsPerDayChart;
