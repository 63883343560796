import React from 'react'
import HeroSection from './CommonComponents/HeroSection'
import FeaturesSection from './CommonComponents/FeaturesSection'
import f1 from '../../../assets/images/form_schedule1.png'
import f2 from '../../../assets/images/form_schedule2.png'
import f3 from '../../../assets/images/form_schedule3.png'
import ParallaxSection from '../../pages/HomePage/Components/ParallaxSection'
import HomepageDemoSection from '../../pages/HomePage/Components/HomepageDemoSection'


export default function FormScheduling() {
  return (
    <>
      <HeroSection heroText={"Scheduled Form Management"} subText={"Efficiently schedule and automate form submissions with SafetyLoop's powerful tools. Enhance your health and safety management system by ensuring timely data collection and compliance."} />
      <FeaturesSection
        headerText={"Effortless Form Scheduling"}
        subText={"SafetyLoop makes it easy to schedule forms for data collection and compliance tracking. Customize schedules to fit your workflow."}
        featureHighlights={["Set up recurring schedules for routine form submissions.",
          "Define deadlines and frequencies for data collection.",
          "Automate form distribution to relevant users or teams.",
          "Ensure timely and consistent data submission.",
          "Flexibility to adjust schedules as your needs evolve."]}
        sectionBackground='dark'
        sectionAlign="image-right"
        featureImageSrc={f1}
      />
      <FeaturesSection
        headerText={"Comprehensive Form Visibility"}
        subText={"Gain real-time insights into form submissions and progress. Monitor compliance and track data collection effortlessly."}
        featureHighlights={["Access a centralized dashboard to view all submitted forms.",
          "Filter and search options for specific forms, users, or time periods.",
          "Monitor compliance rates and identify trends.",
          "Receive notifications for overdue or incomplete forms.",
          "Streamline auditing and reporting processes."]}
        featureImageSrc={f2}
      />
      <FeaturesSection
        headerText={"Data-Driven Insights"}
        subText={"Unlock the power of data with SafetyLoop's robust statistics feature. Make informed decisions and enhance safety measures."}
        featureHighlights={["Generate detailed statistics and analytics reports.",
          "Track trends and patterns in form submissions.",
          "Identify areas for improvement and proactive safety measures.",
          "Monitor compliance across teams, projects, or locations.",
          "Leverage data to optimize safety protocols and workflows."]}
        sectionBackground='dark'
        sectionAlign="image-right"
        featureImageSrc={f3}
      />
      <div className="Home-Page sl-home-wrapper" style={{ paddingTop: 0 }}>
        <ParallaxSection />
      </div>
      <div className="features-homepage-wrapper">
        <HomepageDemoSection mergeTop={false} />
      </div>
    </>
  )
}
