import React from 'react';
import HeroSection from './CommonComponents/HeroSection';
import FeaturesSection from './CommonComponents/FeaturesSection';
import ParallaxSection from '../../pages/HomePage/Components/ParallaxSection';
import HomepageDemoSection from '../../pages/HomePage/Components/HomepageDemoSection';

export default function WearableIntegrations() {
    return (
        <>
            <HeroSection
                heroText={"Wearable Integrations"}
                subText={"Integrate data from wearables to enhance safety and track employee wellness."}
            />
            <FeaturesSection
                headerText={"Seamless Wearable Integration"}
                subText={"Connect wearables to monitor employee health and safety in real-time."}
                featureHighlights={[
                    "Integrate data from various wearable devices.",
                    "Monitor employee health metrics and safety.",
                    "Set up alerts for safety thresholds and updates.",
                    "Generate reports on wearable data."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for Wearable Integration section here
            />
            <FeaturesSection
                headerText={"Custom Wearable Alerts"}
                subText={"Receive real-time alerts and notifications based on wearable data."}
                featureHighlights={[
                    "Set up alerts for health and safety thresholds.",
                    "Receive notifications for abnormal health metrics.",
                    "Monitor wearable status and connectivity."
                ]}
                // Placeholder: Add image for Wearable Alerts section here
            />
            <FeaturesSection
                headerText={"Wearable Data Analytics"}
                subText={"Analyze data from wearables to gain insights into employee health and safety."}
                featureHighlights={[
                    "Generate reports on health and safety metrics.",
                    "Identify trends in employee wellness.",
                    "Use data insights to improve safety protocols."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for Wearable Analytics section here
            />
            <div className="Home-Page sl-home-wrapper" style={{ paddingTop: 0 }}>
                <ParallaxSection />
            </div>
            <div className="features-homepage-wrapper">
                <HomepageDemoSection mergeTop={false} />
            </div>
        </>
    );
}
