import Cookies from 'js-cookie'

export const colorsList = [
    "#40E0D0",
    "#0080ff",
    "#132A3E",
    "#ff1a1a",
    "#808080",
    "#ff4d79",
    "#06bcf9",
    "#ff668c",
    "#ffa64d",
    "#adad85",
    "#ff751a",
    "#d279a6",
    "#73264d",
    "#ff471a",
    "#4d94ff",
    "#c65353",
    "#df9f9f",
    "#132A3E",
    "#f6557e",
    "#18344E",
    "#3498DB",
    "#AED6F1",
    "#E74C3C",
    "#ff8080",
    "#639dcf",
    "#ffb84d",
    "#cc7a00",
    "#ff3333",
    "#7575a3",
    "#306a9c",
    "#18344E"
    ]

export const currentYear = new Date().getFullYear();

export const monthsList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

export const defaultFilters = { year: currentYear, frequency: 'month', month: '', user: '', form_type_id: '', search: false }

export const defaultWidgets = [defaultFilters]

export const monthsSelectOptions = { "Jan": "January", "Feb": "February", "Mar": "March",
     "Apr": "April", "May": "May", "Jun": "June", "Jul": "July", "Aug": "August",
     "Sep": "September", "Oct": "October", "Nov": "November", "Dec": "December" }

export const frequencyOptions = [{ value: "month", label: "Monthly" },
    { value: "week", label: "Weekly" }]

const monthFilterOptions = [{ value: "Jan", label: "Jan" },
    { value: "Feb", label: "Feb" }, { value: "Mar", label: "March" },
    { value: "Apr", label: "April" }, { value: "May", label: "May" },
    { value: "Jun", label: "June" }, { value: "Jul", label: "July" },
    { value: "Aug", label: "Aug" }, { value: "Sep", label: "Sep" },
    { value: "Oct", label: "Oct" }, { value: "Nov", label: "Nov" },
    { value: "Dec", label: "Dec" }]

export function generateArrayOfYears() {
    let max = new Date().getFullYear()
    let min = max - 9
    let years = []

    for (let i = max; i >= min; i--) {
        years.push(i)
    }
    return years
}

export function generateYearOptions(){
    let years = generateArrayOfYears()
    let options = []

    for (let year of years){
        options.push({ value: year, label: year })
    }
    return options
}

export function generateMonthOptions(){
    let options = []

    for (let year of monthsList){
        options.push({ value: year, label: year })
    }
    return options
}

export function generateMonthOptionsData(){
    let options = []
    Object.entries(monthsSelectOptions).map(item => {
            options.push({ value: item[0], label: item[1] })
    })
    return options
}

export function dataNotFound(obj){
    return obj === undefined || obj == null || obj.length === 0
}

export function generateUsersListsOptions(users){
    let options = []
    for (let user of users){
        options.push({ value: user, label: user })
    }
    return options
}

export function findOrCreateCookieData(name){
    let widgets = [];
    let cookie_name = name;
    let data = getCookieData(cookie_name)

    if (data === undefined){
        widgets = Cookies.set(cookie_name, JSON.stringify([defaultFilters]));
        widgets = JSON.parse(data)
    }
    else {
        widgets = JSON.parse(getCookieData(cookie_name))
    }
    setCookieData('username', cookie_name)

    return widgets;
}

export function setUserCookieData(user_data){
    if (user_data){
        Cookies.set('username', `${user_data.user_name}`)
    }
}

export function getSavedWidgets(user_name){
    let widgets = [];
    let cookie_name = `${user_name}_widgets`

    if (getCookieData('username') != undefined ){
        if (getCookieData(cookie_name) === undefined){
            setCookieData(cookie_name, JSON.stringify([defaultFilters]))
            widgets = JSON.parse(getCookieData(cookie_name))
        }else{
            widgets = getWidgetsFromCookies(cookie_name)
        }
    }else{

    }
    return widgets
}

export function getCookieData(key){
    return Cookies.get(key)
}

export function setCookieData(key, data){
    Cookies.set(key, data)
}

export function getWidgetsFromCookies(cookie_name){
    let options = []
    let result = getCookieData(cookie_name)

    if (result !== undefined){
        let widgets = JSON.parse(result)
        for (let widget of widgets){
            options.push(widget)
        }
    }

    return options;
}

export function getCurrentUserData(){
    let cookie_user = getCookieData('username')
    let user = $("#app").attr("user_data")

    if (user){
        let data = JSON.parse(user)
        if (cookie_user != data.username){
            Cookies.get('username', data.username)
        }
    }
    return getCookieData('username')
}

export function saveWidgets(filters){
    let widgets = [];
    let user_cookie = getCookieData('username')

    if (user_cookie !== undefined ){
        let cookie_name = `${user_cookie}_widgets`
        let data = getCookieData(cookie_name)

        if (data != undefined){
            widgets = JSON.parse(data)
            widgets.push(filters)
            setCookieData(cookie_name, JSON.stringify(widgets))
        }
    }
    return widgets;
}