import React, { useEffect, useState, useRef } from "react";
import SideNav from "../../../components/SideNav";
import { Link } from "react-router-dom";
import { Divider, TextField, Button } from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import axios from "../../../config/axiosConfig";
import Application from "../../../config/ApplicationWrapper";
import loader from "../../../assets/images/newloader.gif";
import { TemplateQRPrint } from '../Popups/TemplateQRPrint';

const View = (params) => {
    const type = params.match.params.type;;
    const [pageDetails, setPageDetails] = useState();
    const componentRef = useRef();

    useEffect(() => {
        const getPageDetails = async () => {
            try {
                window.scrollTo(0, 0);
                if (params.match.params.id) {
                    let resultObj = await axios.get(`${Application.api_url}/api/template_qr_codes/${params.match.params.id}`);
                    if (resultObj.status == 200 && resultObj.data.success === true) {
                        var details = resultObj.data;
                        
                        setPageDetails(details ? details.data : [])
                        // debugger;
                    }
                }

            } catch (err) {
                console.log("error==>", err);
            }
        };
        getPageDetails()
    }, [])

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const url = "https://www.safetyloop.com/external_forms/enter_access_token?access_token=";

    return (
        <>
            <div className="msmcontainer dashboard-container create-plans">
                <div className="row d-flex">
                    <SideNav />
                    <div className="content-wrap col-sm-12 create-risk-assessment">
                        <div className="card">
                            <div className="card-body">
                                <div class="risk-assessment-link">
                                    <Link to="/qrcodes" id="create-risk-assessment-link">
                                        <i class="fa fa-arrow-left" aria-hidden="true"></i>
                                    </Link>
                                    <h3>View Template QR</h3>
                                    <Button variant="contained" size="large" onClick={() => { handlePrint() }} style={{ marginLeft: "auto", fontSize: "16px" }}>Print QR</Button>
                                </div>
                                <Divider light />
                                {pageDetails ? (
                                    <div className="row create-form">
                                        <div className="col-md-6">
                                            <label className="form-lables">Template
                                            </label>
                                            <TextField
                                                required
                                                fullWidth
                                                className="text-field"
                                                id="outlined-required"
                                                disabled={true}
                                                value={pageDetails.external_form_template_datum && pageDetails.external_form_template_datum.audit_template_name}
                                            />
                                            <label className="form-lables">Group
                                            </label>
                                            <TextField
                                                required
                                                fullWidth
                                                className="text-field"
                                                id="outlined-required"
                                                disabled={true}
                                                value={pageDetails.external_form_template_datum && pageDetails.external_form_template_datum.group_name}
                                            />
                                            <label className="form-lables">Created By
                                            </label>
                                            <TextField
                                                required
                                                fullWidth
                                                className="text-field"
                                                id="outlined-required"
                                                disabled={true}
                                                value={pageDetails.created_by && pageDetails.created_by.full_name}
                                            /><label className="form-lables">Max Forms Limit
                                            </label>
                                            <TextField
                                                required
                                                fullWidth
                                                className="text-field"
                                                id="outlined-required"
                                                disabled={true}
                                                value={pageDetails.external_form_template_datum && pageDetails.external_form_template_datum.max_forms_limit_by_qr_code}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <div className="text-center">
                                                <h2>Access Token:  {pageDetails.external_form_template_datum && pageDetails.external_form_template_datum.access_token}</h2>
                                                
                                                <img src={pageDetails.qr_url} alt="" />
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3"></div>
                                                <div className="col-md-8" style={{fontSize:"11px"}}>
                                                    Having trouble using the QR code? Use this link.
                                                    <br /><br />
                                                    {url.toLowerCase() + pageDetails.external_form_template_datum.access_token}
                                                </div>
                                                <div className="col-md-1"></div>
                                                
                                                
                                            </div>
                                        </div>
                                    </div>
                                ) : <div className="new-loader">
                                    <img src={loader} alt="" />
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {pageDetails &&
                <TemplateQRPrint ref={componentRef} pageData={pageDetails} />
            }
        </>
    );
};

export default View;
