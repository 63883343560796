import React from 'react';

class ExpiredTrial extends React.Component {
    render() {
        return (
            <div style={{ color: "rgb(255,0,0)", backgroundColor: "rgba(255,0,0,0.1)", display: "flex", alignItems: "center", padding: "11px 8px", float: "left", marginRight: "16px" }}>
                <span style={{marginRight: "8px"}}>Trial has expired.</span>
                {User.role === 'Admin' &&
                    (<a style={{ color: "red", fontWeight: "600", textDecoration: "underline", lineHeight: "41px !important" }} href="/company_profile/billing_page" className="waves-effect waves-light">Upgrade Now!</a>)}
            </div>
        );
    }
}

export default ExpiredTrial
