import { Switch, FormControl, Select, MenuItem, Pagination } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import EditIcon from "../../../../assets/fonts/edit.svg";
import SearchIcon from "../../../../assets/images/searchbar.svg";
import AddType from "./add";
import axios from "../../../../config/axiosConfig";
import Application from "../../../../config/ApplicationWrapper";
import Loader from "../../../../components/NewLoader";
import { toast } from "react-toastify";


const InventoryType = () => {
    const mounted = useRef(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(30);
    const [pageCount, setPageCount] = useState(0);
    const [count, setCount] = useState(0);
    const [pageDetails, setPageDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [showDetails, setShowDetails] = useState();
    const [name, setSearchByName] = useState();

    useEffect(() => {
        getPageDetails()
    }, [page, limit])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            mounted.current && getPageDetails()
            mounted.current = true;
        }, 2000)

        return () => clearTimeout(delayDebounceFn)
    }, [name])


    const getPageDetails = async () => {
        try {
            await axios.get(`${Application.api_url}/api/v2/inventory_types`, {
                params: {
                    page: page,
                    limit: limit,
                    name_cont: name,
                },
            }).then((res) => {
                let details = res.data;
                setPageDetails(details ? details.data : [])
                setPageCount(
                    details && details.metadata ? details.metadata.pages : 0
                );
                setCount(
                    details && details.metadata ? details.metadata.count : 0
                );
                setLoading(false)
            }).catch((err) => console.log("err=>", err))
        } catch (err) {
            console.log("err===>", err)
        }
    }

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const close = (data) => {
        setShow(false)
        setShowDetails("")
        data && getPageDetails()
    }

    const archive = async (data) => {
        try {
            await axios.patch(`${Application.api_url}/api/v2/inventory_types/${data.identifier}`, { is_active: !data.is_active }).then((result) => {
                if (result.data && result.data.success === true) {
                    toast.success(data.is_active ? 'Deactivated Successfully' : 'Activated Successfully')
                    close(result.data)
                }
            }).catch((err) => console.log("err=>", err))
        } catch (err) {
            console.log("err==>", err)
        }
    }

    const clearFilter = () => {
        setSearchByName(""); 
    }

    return (
        <div className="inventory-type">
            <div className="title">
                <h4>Inventory Type <span>{count}</span></h4>
                <div className="add-new-btn">
                    <button onClick={() => { setShow(true) }}>+ Add Inventory Type</button>
                </div>
            </div>
            <div className="fields-setting">
                <div className="asset-active-filters">
                    Active Filters :
                    <span className="active-filters">
                        <span>Inventory Type :</span>
                        <span className="selected">{name ? name : "All"}</span>
                        <span className={name ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setSearchByName("") }}>X</span>
                    </span> 
                    <span className="active-filters-clear" onClick={(e) => clearFilter()}>
                        Clear All
                    </span>
                </div>
            </div>
            <div className="page-content">
                <table>
                    <thead>
                        <tr>
                            <th className="divider">
                                Inventory Type
                                {pageDetails.length > 0 &&
                                    <div className="search-col">
                                        <input type="text" name="search" id="search" className="search-btn" value={name} placeholder="Search" onChange={(e) => { setSearchByName(e.target.value) }}/>
                                        <img src={SearchIcon} alt="Search Icon" className="search-icon" />
                                    </div>
                                }
                            </th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!loading &&
                            pageDetails.length > 0 &&
                            pageDetails.map((val, index) => {
                                return (
                                    <tr>
                                        <td>{val.name}</td>
                                        <td>
                                            <img src={EditIcon} alt="edit" onClick={() => { setShowDetails(val); setShow(true) }} />
                                            <Switch
                                                checked={val.is_active}
                                                onClick={(event) => archive(val)}
                                            />
                                        </td>
                                    </tr>)
                            })}
                        <Loader pageDetails={pageDetails} loading={loading} btnName={"+ Add Inventory Type"} open={() => { setShow(true) }} />
                    </tbody>
                </table>
            </div>
            <div className="page-limits">
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={limit}
                        onChange={(e) => {
                            setPage(1);
                            setLoading(true);
                            setLimit(e.target.value);
                        }}
                    >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                    </Select>
                </FormControl>
                <Pagination
                    count={pageCount || 1}
                    variant="outlined"
                    shape="rounded"
                    onChange={handleChangePage}
                />
            </div>
            {show &&
                <AddType show={show} close={close} details={showDetails} />
            }
        </div>
    )

}

export default InventoryType;