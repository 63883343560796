import React, {useEffect, useState} from 'react'
import {Form, Modal, FormGroup, FormControl, Button} from "react-bootstrap";

const OrderItemCommentsModal = ({show, close, notes }) => {
  return (
  <Modal
    style={{marginTop:'100px'}}
    show={show}
    onHide={(_) => close()}
  >
    <Modal.Header closeButton>
      <Modal.Title>
        View Comments
      </Modal.Title>
      <Modal.Body>
        {
          notes && (
            <div style={{
              height: "300px",
              overflowY: "auto",
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              backgroundColor: "#f9f9f9"
            }}>
              {notes && notes.map((note) => (
                <div key={note.id} style={{marginBottom: "8px", borderBottom: "1px solid #ccc", padding: '5px'}} className={"d-flex"}>
                  <div className={"d-flex"} style={{flexDirection: 'column'}}>
                    <div style={{fontSize: '18px'}}>{note.created_by.full_name}:</div>
                    <div style={{fontSize: '10px'}}>({note.created_at})</div>
                  </div>
                  <div className={"ml-2"} style={{wordBreak: 'break-all', border: '1px solid black', borderRadius: '5px', padding: '10px', width: '300px'}}>{note.content}</div>
                </div>
              ))}
            </div>
          )
        }

        {
          !notes && (
            <div style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              backgroundColor: "#f9f9f9"
            }}>
              No messages yet.
            </div>
          )
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="btn-secondary" onClick={close}>
          Close
        </Button>
      </Modal.Footer>
    </Modal.Header>
  </Modal>)
}

export default OrderItemCommentsModal;