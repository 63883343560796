import React, { useEffect, useState } from "react";
import {
  Modal,
  FormControl as Input,
} from "react-bootstrap";
import { toast } from "react-toastify";
import Application from "../../../../config/ApplicationWrapper";
import axios from "../../../../config/axiosConfig";


export default function Add(props) {
  const { show, close, details } = props;
  const [onSubmit, setSubmit] = useState(false);
  const [disabled, setDisable] = useState(false);
  const [name, setName] = useState("");

  useEffect(() => {
    details && setName(details.name)
  }, [show])

  const createOrUpdate = async () => {
    try {
      setSubmit(true);
      setDisable(true);

      if (!name) {
        return false
      }

      if (details) {
        await axios.patch(`${Application.api_url}/api/v2/inventory_types/${details.identifier}`, { name: name }).then((result) => {
          if (result.data && result.data.success === true) {
            toast.success(`Updated Successfully`)
            close(result.data)
          }
        }).catch((error) => toast.error(error.response.data.message))
      } else {
        await axios.post(`${Application.api_url}/api/v2/inventory_types`, { name: name }).then((result) => {
          if (result.data && result.data.success === true) {
            toast.success(`Created Successfully`)
            close(result.data)
          }
        }).catch((error) => {
          toast.error(error.response.data.message)
        })
      }

    } catch (err) {
      console.log("err===>", err);
    }
  };

  return (
    <Modal
      show={show}
      onHide={(e) => {
        close();
      }}
      className="new-model-popup"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {!details && <i className="fa fa-plus" aria-hidden="true"></i>}
          <b> {details ? "Update Inventory Type" : "Add Inventory Type"} </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="new-form">
          <div className="row">
            <div className="col-md-12">
              <label>
                Inventory Type<text style={{ color: "red" }}>*</text>
              </label>
              <input name="name" id="name" className="input-new" value={name} required onChange={(e) => { setName(e.target.value); setDisable(false) }} />
              {onSubmit && !name && <p className="required-fields">Please enter the name</p>}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn button cancel-btn"
          onClick={() => {
            close()
          }}
        >
          Cancel
        </button>
        <button
          className="btn btn-primary"
          disabled={disabled}
          onClick={(event) => {
            createOrUpdate();
          }}
        >
          {details ? "Update" : "Create"}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
