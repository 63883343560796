import React, { useEffect, useState } from "react";
import Application from "../../../../config/ApplicationWrapper";
import axios from "../../../../config/axiosConfig";
import ExpirationDate from "../../../../assets/fonts/ExpirationDate.png";
import UpdateStock from "../../../../assets/fonts/UpdateStock.png";
import UnityPriceUpdate from "../../../../assets/fonts/UnityPriceUpdate.png";
import ReorderStatus from "../../../../assets/fonts/ReorderStatus.png";
import EmptyTable from "../../../../assets/images/empty-assets.svg";
import loader from "../../../../assets/images/newloader.gif";

const HistoricalLogs = ({ id }) => {
  const [pageDetails, setPageDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState();
  const [date, setDate] = useState();

  let labels = {
    name: "Name",
    status: "Status",
    serial_no: "Serial No Action Type",
    current_qty: "Current Quantity",
    low_stock_threshold: "Low Stock Threshold",
    unit_price: "Unit Price",
    total_inventory_value: "Total Inventory Value",
    inventory_location_id: "Inventory Location",
    last_order_date: "Last Order Date",
    expiration_date: "Expiration Date",
    notification_alert: "Notification Alert",
    reorder_status: "Reorder Status",
    cost_per_day: "Cost Per Day",
    rental_start_date: "Rental Start Date",
    rental_end_date: "Rental End Date",
    quantity_unit: "Quantity Unit",
    notification_enabled: "Notification Enabled",
  };

  const fieldToImageMap = {
    name: UpdateStock,
    status: UpdateStock,
    serial_no: UpdateStock,
    current_qty: UpdateStock,
    low_stock_threshold: UpdateStock,
    unit_price: UnityPriceUpdate,
    total_inventory_value: UnityPriceUpdate,
    inventory_location_id: UpdateStock,
    last_order_date: ExpirationDate,
    expiration_date: ExpirationDate,
    notification_alert: ReorderStatus,
    reorder_status: ReorderStatus,
    cost_per_day: UnityPriceUpdate,
    rental_start_date: ExpirationDate,
    rental_end_date: ExpirationDate,
    quantity_unit: UpdateStock,
    notification_enabled: ReorderStatus,
  };

  useEffect(() => {
    (async () => {
      try {
        axios
          .get(
            `${Application.api_url}/api/v2/inventories/${id}/inventory_historical_logs`,
            {
              params: {
                field_eq: search,
                created_at_eq: date,
              },
            }
          )
          .then((res) => {
            setPageDetails(res.data.data);
            setLoading(false);
          })
          .catch((err) => {
            console.log("err===>", err);
          });
      } catch (err) {
        console.lof("err==>", err);
      }
    })();
  }, [search, date]);

  return (
    <div className="historical-logs-pg">
      <div className="title">
        <h2>Historical Logs</h2>
        <div className="filters">
          <span>Action Type</span>
          <select
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          >
            <option value="">Select Action Type</option>
            <option value="name">Name</option>
            <option value="status">Status</option>
            <option value="serial_no">Serial No</option>
            <option value="current_qty">Current Quantity</option>
            <option value="low_stock_threshold">Low Stock Threshold</option>
            <option value="unit_price">Unit Price</option>
            <option value="total_inventory_value">Total Inventory Value</option>
            <option value="inventory_location_id">Inventory Location</option>
            <option value="last_order_date">Last Order Date</option>
            <option value="expiration_date">Expiration Date</option>
            <option value="notification_alert">Notification Alert</option>
            <option value="reorder_status">Reorder Status</option>
            <option value="cost_per_day">Cost Per Day</option>
            <option value="rental_start_date">Rental Start Date</option>
            <option value="rental_end_date">Rental End Date</option>
            <option value="quantity_unit">Quantity Unit</option>
            <option value="notification_enabled">Notification Enabled</option>
          </select>
          <span>Date log</span>
          <input
            type="date"
            className="date-filter"
            onChange={(e) => {
              setDate(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="log-details">
        {!loading &&
          pageDetails.length > 0 &&
          pageDetails.map((val, index) => (
            <div className="logs">
              <span>{labels[val.field] ? labels[val.field] : val.field}</span>
              <div className="details">
                <img
                  src={fieldToImageMap[val.field] || UpdateStock}
                  alt="images"
                  style={{ height: "45px" }}
                />
                <div className="info">
                  <span className="text">
                    <span className="important-text">
                      {val.updated_by && val.updated_by.full_name}
                    </span>
                    {`has Updated ${
                      labels[val.field] ? labels[val.field] : val.field
                    }`}
                  </span>
                  <div className="date-time">
                    <span className="date">
                      From <span className="highlight">{val.old_value}</span> To{" "}
                      <span className="highlight">{val.new_value}</span>
                      {` on ${val.created_at}`}
                    </span>
                  </div>
                </div>
              </div>
              <span className="divide-line"></span>
            </div>
          ))}
        {loading ? (
          <div className="new-loader"  style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection:"column", width:"100%", height:"100%" }}>
            <img src={loader} alt="" />
          </div>
        ) : (
          pageDetails &&
          pageDetails.length == 0 && (
            <div
              className="no-assets"
              style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection:"column", width:"100%", height:"100%" }}
            >
              <img src={EmptyTable} />
              <p>
                <b>No Data</b>
              </p>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default HistoricalLogs;
