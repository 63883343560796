import React, { useEffect, useState } from "react";
import axios from "../../../../config/axiosConfig";
import {TableContainer, Paper, Pagination, MenuItem, FormControl, Select, Button} from '@mui/material';
import Application from "../../../../config/ApplicationWrapper";
import Loader from "../../../../components/NewLoader";

const CompletedFormList = ({ id }) => {
    let userDetails = localStorage.getItem("user");
    userDetails = JSON.parse(userDetails);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(30);
    const [pageCount, setPageCount] = useState(0);
    const [pageDetails, setPageDetails] = useState([]);
    const [name, setSearchByName] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getPageDetails()
        }, 2000)

        return () => clearTimeout(delayDebounceFn)
    }, [limit, name, page])

    const clearFilter = () => {
        setSearchByName("");
    }

    const getPageDetails = async () => {
        try {
            window.scrollTo(0, 0);
            setLoading(true)
            let resultObj = await axios.get(`${Application.api_url}/api/v2/internal_subs/${id}/internal_audits`, {
                params: {
                    page: page,
                    limit: limit,
                    archived: false,
                    form_name: name,
                },
            });
            if (resultObj.status == 200) {
                let details = resultObj.data;
                setPageDetails(details ? details.audits : [])
                setPageCount(
                    details && details.metadata ? details.metadata.pages : 0
                );
                setLoading(false)
            }
        } catch (err) {
            console.log("error==>", err);
        }
    };

    const handleChangePage = (event, value) => {
        setPage(value);
        getPageDetails()
    };

    const hanldeLimitChange = (value) => {
        setPage(1);
        setLimit(value);
    }

    const viewPDF = async (link, appendix= true) => {
        debugger
        window.open(`${link}?appendix=${appendix}`, '_blank');
    }

    return (
        <>
            <div className="card asset-tab-landing">
                <div className="card-body">
                    <div className="tab-content">
                        <div
                            id="active-risk-assessment"
                            className="tab-pane fade in active active-risk-assessment internal-sub-employee"
                        >
            <div className="nestedTable pf-table internal_sub_web_table">
                <div className="fields-setting">
                    <div className="asset-active-filters">
                        Active Filters :
                        <span className="active-filters">
                                            <span>Form Name :</span>
                                            <span className="selected">{name ? name : "All"}</span>
                                            <span className={name ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setSearchByName("") }}>X</span>
                                        </span>
                        <span className="active-filters-clear" onClick={(e) => clearFilter()}>
                                            Clear All
                                        </span>
                    </div>
                </div>
                <TableContainer component={Paper}>
                    <table class="table table-striped">
                        <thead>
                        <tr style={{ height: "100px" }}>
                            <th scope="col" className="risk-assessment-divider">
                                <div className="d-flex justify-content-between align-item-center">
                                    Forms Name
                                </div>
                                <div class="input-group">
                                    <input
                                        type="text"
                                        class="form-control assessment-name"
                                        placeholder="Search"
                                        aria-label="Username"
                                        id="name"
                                        aria-describedby="basic-addon1"
                                        name="name"
                                        onChange={(e) => {
                                            setSearchByName(e.target.value)
                                        }}
                                        value={name}
                                    />
                                    <div class="input-group-addon">
                                            <span class="input-group-text" id="basic-addon1">
                                                <i class="fa fa-search" aria-hidden="true"></i>
                                            </span>
                                    </div>
                                </div>
                            </th>
                            <th scope="col" className="risk-assessment-divider">
                                Company
                            </th>
                            <th scope="col" className="risk-assessment-divider">
                                Group
                            </th>
                            <th scope="col" className="risk-assessment-divider">
                                Stared On
                            </th>
                            <th scope="col" className="risk-assessment-divider">
                                Completed On
                            </th>
                            <th scope="col" className="table-action">
                                Action
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {pageDetails &&
                            pageDetails.length > 0 &&
                            pageDetails.map((val, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <i aria-hidden="true" className="fa fa-file-text-o" style={{fontSize: "16px", margin: "0 10px", color: "#407797"}}></i>
                                            {val.name}
                                        </td>
                                        <td>
                                            {val.company}
                                        </td>
                                        <td>
                                            {val.group_name}
                                        </td>
                                        <td>
                                            {val.started}
                                        </td>
                                        <td>
                                            {val.completed}
                                        </td>
                                        <td className="template-options" style={{textAlign: "center"}}>
                                            <a
                                                aria-expanded="false"
                                                aria-haspopup="true"
                                                className="btn dropdown-toggle"
                                                data-toggle="dropdown"
                                                href="#"
                                                id="dropdownMenuLink"
                                                role="button"
                                            >
                                                <i className="fa fa-ellipsis-v"></i>
                                            </a>
                                            <ul aria-labelledby="dropdownMenuLink" className="dropdown-menu">
                                                <li>
                                                    <a
                                                        className="archive_group_invites"
                                                        data-toggle="tooltip"
                                                        title="View Appendix"
                                                        onClick={(e) => {
                                                            viewPDF(val.report_link)
                                                        }}
                                                    >
                                                        <div className="row d-flex">
                                                            <div className="col-sm-3">
                                                                <i
                                                                    className="fa fa-eye"
                                                                    style={{
                                                                        paddingTop: "5px",
                                                                        fontSize: "16px",
                                                                        cursor: "pointer"
                                                                    }}
                                                                ></i>
                                                            </div>
                                                            <div
                                                                className="col-sm-9"
                                                                style={{
                                                                    paddingTop: "5px",
                                                                    cursor: "pointer"
                                                                }}
                                                            >
                                                                View - Appendix
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <a
                                                        className="archive_group_invites"
                                                        data-toggle="tooltip"
                                                        title="View "
                                                        onClick={(e) => {
                                                            viewPDF(val.report_link, false)
                                                        }}
                                                    >
                                                        <div className="row d-flex">
                                                            <div className="col-sm-3">
                                                                <i
                                                                    className="fa fa-eye-slash"
                                                                    style={{
                                                                        paddingTop: "5px",
                                                                        fontSize: "16px",
                                                                        cursor: "pointer"
                                                                    }}
                                                                ></i>
                                                            </div>
                                                            <div
                                                                className="col-sm-9"
                                                                style={{
                                                                    paddingTop: "5px",
                                                                    cursor: "pointer"
                                                                }}
                                                            >
                                                                View - No Appendix
                                                            </div>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                )
                            })}
                        <Loader pageDetails={pageDetails} loading={loading} />
                        </tbody>
                    </table>
                </TableContainer>
                <div className="page-limits">
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={limit}
                            onChange={(e) => {
                                hanldeLimitChange(e.target.value);
                            }}
                        >
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={30}>30</MenuItem>
                        </Select>
                    </FormControl>
                    {!loading && (
                        <Pagination
                            count={pageCount}
                            page={page}
                            variant="outlined"
                            shape="rounded"
                            onChange={handleChangePage}
                        />
                    )}
                </div>
            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CompletedFormList;
