import React, { Fragment } from 'react'
import StatsCard from '../../components/StatsCard';

const StatsOverview = ({ stats }) => {
    delete stats.unscheduled
    const renderedCard = Object.keys(stats).map(data => {
        let info = stats[data]
        return (
            <StatsCard
                key={data}
                label={data}
                stats={info}
                />
            
        );
    });

    return <div className="stats-list">{renderedCard}</div>;
}

export default StatsOverview;
