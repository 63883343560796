
var Application = {
    role_admin: "Admin",
    role_manager: "Manager",
    role_employee: "Employee",
    admin: [],
    manager: ["/company_profile"],
    user: [],
    role_configuration: {   "Admin": ["/company_profile","new-company", "/stats-settings", "/stats"],
                            "Manager":["/company_profile", "/new-user", "/company-users","/stats-settings", "/stats"],
                            "Employee": []
    },
    api_url: window.location.origin,
    // api_url: 'http://staging.safetyloop.com'
    // api_url: 'https://www.safetyloop.com'

}


module.exports = Application;

