import React, { useEffect, useState } from 'react'
import axios from "axios"
import Application from "../../config/ApplicationWrapper"
import PricingOurPlan from '../../components/Pricing/PricingOurPlan.svg'
import PricingCard from "../../components/Pricing/PricingCard"
import '../../assets/styles/themes.scss'

const sortByPriceAscWithNullLast = (arr) => {
  return arr.sort((a, b) => {
    if (a.price === null) {
      return 1;
    }

    if (b.price === null) {
      return -1;
    }

    if (a.price === b.price) {
      return 0;
    }

    return a.price < b.price ? -1 : 1;
  })
}

const PricingPage = () => {
  const [subscriptionPlans, setSubscriptionPlans] = useState([])

  useEffect(() => {
    const fetchSubscriptionPlans = async () => {
      try {
        const result = await axios.get(`${Application.api_url}/api/v2/subscription_plans`)

        if (result && result.data && result.data.data) {
          setSubscriptionPlans(sortByPriceAscWithNullLast(result.data.data))
        } else {
          console.error('Can not fetch subscription plans')
        }
      } catch (err) {
        console.error(err)
      }
    }

    fetchSubscriptionPlans()
  }, [])

  return (
    <div className='home'>
      <div className='section__intro'>
        {/* <img src={PricingOurPlan} alt='' style={{width:"200px"}}/> */}
        {/* <p className='title' style={{textAlign:"center", fontSize:"24px"}}>

          A plan for EVERY company.
          <br />
          Access all SafetyLoop features <span>FREE</span> for 30 days
          <br/>
          No credit card, no commitment, and cancel anytime.
        </p> */}
      </div>

      <div style={{textAlign:'center', fontSize:'14px'}}>
        All prices in Canadian Dollars*
      </div>

      <div className='section__cards mx-auto'>
        {subscriptionPlans.map((subscriptionPlan)=>{

            return (
              (subscriptionPlan.show_on_pricing_page)==true && (
                <div key={subscriptionPlan.id} className='section__card'>
                  <PricingCard
                    id={subscriptionPlan.id}
                    name={subscriptionPlan.name}
                    description={subscriptionPlan.description}
                    price={subscriptionPlan.price}
                    currency={subscriptionPlan.currency}
                    interval={subscriptionPlan.interval}
                    intervalUnit={subscriptionPlan.interval_unit}
                    options={subscriptionPlan.options}
                  />
                </div>
              )
            )

        })}
      </div>

      <p className='home__description'>{`At SafetyLoop, Our goal is to help you simplify your occupational health & safety management system
        by using our ecosystem, custom forms & tools, and monitor in real-time today.`}
      </p>
    </div>
  )
}

export default PricingPage
