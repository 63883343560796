import React, { useEffect, useState } from "react";
import axios from "../../../config/axiosConfig";
import { toast } from "react-toastify";
import { TableContainer, Paper, Pagination, MenuItem, FormControl, Select, Switch } from '@mui/material';
import Application from "../../../config/ApplicationWrapper";
import New from "./New";
import Loader from "../../../components/NewLoader"; 

const Topics = ({ updateActiveCount }) => {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(30);
    const [pageCount, setPageCount] = useState(0);
    const [pageDetails, setPageDetails] = useState([]);
    const [name, setSearchByName] = useState();
    const [user, setSearchByUser] = useState();
    const [expiration, setSearchByExpiration] = useState();
    const [show, setShow] = useState(false);
    const [editData, setEditData] = useState();
    const [loading, setLoading] = useState(true);
    const [searchable, setSearchable] = useState(false);
    const [cloneStatus, setCloneStatus] = useState(true);

    let expirationLable = {
        "no_expiration": "No Expiration",
        "five_year": "5 Year",
        "four_year": "4 Year",
        "three_year": "3 Year",
        "two_year": "2 Year",
        "one_year": "1 Year"

    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getPageDetails()
        }, 2000)

        return () => clearTimeout(delayDebounceFn)
    }, [name, user, expiration, page, limit])

    const getPageDetails = async () => {
        try {

            window.scrollTo(0, 0);
            let resultObj = await axios.get(`${Application.api_url}/api/v2/internal_training_topics`, {
                params: {
                    page: page,
                    limit: limit,
                    archived_eq: [true, false],
                    title_cont: name,
                    expiry_value_eq: expiration,
                    sort: "created_at desc",
                },
            });
            if (resultObj.status == 200) {
                let details = resultObj.data;
                setPageDetails(details ? details.data : [])
                setPageCount(
                    details && details.metadata ? details.metadata.pages : 0
                );
                updateActiveCount(details && details.metadata ? details.metadata.count : 0)
                setSearchable(details && details.data.length > 0 ? true : false)
                setCloneStatus(details ? details.custom_data.clone_status : "")
                setLoading(false) 
            }
        } catch (err) {
            console.log("error==>", err);
        }
    };

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const clearFilter = () => {
        setSearchByName("");
        setSearchByExpiration("");
    }

    const archive = async (value) => {
        value.archived = !value.archived
        await axios.patch(`${Application.api_url}/api/v2/internal_training_topics/${value.identifier}`, value).then((result) => {
            if (result.data && result.data.success === true) {
                toast.success(value.archived ? 'Deactivated Successfully' : 'Activated Successfully')
                getPageDetails()
            }
        }).catch((err) => {
            console.log("err===>", err)
        })
    }

    const open = (data) => {
        setShow(true)
        setEditData(data)
    }

    const close = (data) => {
        setShow(false)
        setEditData("")
        data && getPageDetails()
    }

    const cloneTopics = async () => {
        try {
            await axios.post(`${Application.api_url}/api/v2/trainings/clone_training_topics`).then((result) => {
                if (result.data && result.data.success === true) {
                    toast.success('Cloned Successfully')
                    getPageDetails()
                }
            }).catch((err) => {
                console.log("err===>", err)
            })
        } catch (err) {
            console.log("err===>", err)
        }
    }

    return (
        <>
            <div class="card asset-tab-landing">
                <div class="card-body">
                    <div class="tab-content">
                        <div
                            id="active-risk-assessment"
                            class="tab-pane fade in active active-risk-assessment active-manage-sub"
                        >
                            <div className="nestedTable pf-table">
                                <div className="fields-setting">
                                    <div className="asset-active-filters">
                                        Active Filters :
                                        <span className="active-filters">
                                            <span>Title :</span>
                                            <span className="selected">{name ? name : "All"}</span>
                                            <span className={name ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setSearchByName("") }}>X</span>
                                        </span>
                                        <span className="active-filters">
                                            <span>Expiration Date :</span>
                                            <span className="selected">{expiration ? expirationLable[expiration] : "All"}</span>
                                            <span className={expiration ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setSearchByExpiration("") }}>X</span>
                                        </span>
                                        <span className="active-filters-clear" onClick={(e) => clearFilter()}>
                                            Clear All
                                        </span>
                                    </div>
                                    <div className="btn-style">
                                        {cloneStatus != "completed" &&
                                            <button type="button" class="btn btn-primary" onClick={(e) => { cloneTopics(true) }} disabled={loading}>
                                                <span className="ml-2">Clone Topics</span>
                                            </button>
                                        }
                                        <button type="button" class="btn btn-primary" onClick={(e) => { setShow(true) }}>
                                            <i class="fa fa-plus" aria-hidden="true"></i>
                                            <span className="ml-2">Add Topics</span>
                                        </button>
                                    </div>
                                </div>
                                <TableContainer component={Paper}>
                                    <table class="table table-striped">
                                        <thead>
                                            <tr style={{ height: "100px" }}>
                                                <th scope="col" className="risk-assessment-divider">
                                                    <div className="d-flex justify-content-between align-item-center">
                                                        Title
                                                    </div>
                                                    {searchable &&
                                                        <div class="input-group">
                                                            <input
                                                                type="text"
                                                                class="form-control assessment-name"
                                                                placeholder="Search"
                                                                aria-label="Username"
                                                                id="name"
                                                                aria-describedby="basic-addon1"
                                                                name="name"
                                                                onChange={(e) => {
                                                                    setSearchByName(e.target.value)
                                                                }}
                                                                value={name}
                                                            />
                                                            <div class="input-group-addon">
                                                                <span class="input-group-text" id="basic-addon1">
                                                                    <i class="fa fa-search" aria-hidden="true"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    }
                                                </th>
                                                <th scope="col" className="risk-assessment-divider">
                                                    Description
                                                </th>
                                                <th scope="col" className="risk-assessment-divider">
                                                    Expiration Date
                                                    {searchable &&
                                                        <div class="input-group">
                                                            <select
                                                                class="form-control risk-type"
                                                                onChange={(e) => {
                                                                    setSearchByExpiration(e.target.value);
                                                                }}
                                                                value={expiration}
                                                            >
                                                                <option value="">All</option>
                                                                <option value="no_expiration">No Expiration</option>
                                                                <option value="five_year">5 Year</option>
                                                                <option value="four_year">4 Year</option>
                                                                <option value="three_year">3 Year</option>
                                                                <option value="two_year">2 Year</option>
                                                                <option value="one_year">1 Year</option>
                                                            </select>
                                                        </div>
                                                    }
                                                </th>
                                                <th scope="col" className="table-action">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pageDetails &&
                                                pageDetails.length > 0 &&
                                                pageDetails.map((val, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td onClick={(e) => { open(val) }}>
                                                                <span className="alerts-name" >{val.title}</span>
                                                            </td>
                                                            <td>
                                                                {val.description}
                                                            </td>
                                                            <td>
                                                                <p className={`expiration ${val.expiry_value}`}>
                                                                    {expirationLable[val.expiry_value]}
                                                                </p>
                                                            </td>
                                                            <td
                                                                className="template-options"
                                                                style={{ textAlign: "center" }}
                                                            >
                                                                <a
                                                                    aria-expanded="false"
                                                                    aria-haspopup="true"
                                                                    class="btn dropdown-toggle"
                                                                    data-toggle="dropdown"
                                                                    href="#"
                                                                    id="dropdownMenuLink"
                                                                    role="button"
                                                                >
                                                                    <i class="fa fa-ellipsis-v"></i>
                                                                </a>
                                                                <ul aria-labelledby="dropdownMenuLink" class="dropdown-menu">
                                                                    <li>
                                                                        <a
                                                                            class="archive_group_invites"
                                                                            data-toggle="tooltip"
                                                                            title="Edit"
                                                                            onClick={(e) => { open(val) }}
                                                                        >
                                                                            <div class="row d-flex">
                                                                                <div class="col-sm-3">
                                                                                    <i
                                                                                        class="fa fa-pencil-square-o"
                                                                                        style={{
                                                                                            paddingTop: "5px",
                                                                                            fontSize: "16px",
                                                                                            cursor: "pointer"
                                                                                        }}
                                                                                    ></i>
                                                                                </div>
                                                                                <div
                                                                                    class="col-sm-9"
                                                                                    style={{
                                                                                        paddingTop: "5px",
                                                                                        cursor: "pointer"
                                                                                    }}
                                                                                >
                                                                                    Edit
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                                <Switch
                                                                    checked={!val.archived}
                                                                    onClick={(event) => archive(val)}
                                                                />
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            <Loader pageDetails={pageDetails} loading={loading} btnName={"+ Add Topics"} open={(e) => { setShow(true) }} />
                                        </tbody>
                                    </table>
                                </TableContainer>
                                <div className="page-limits">
                                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                        <Select
                                            labelId="demo-select-small"
                                            id="demo-select-small"
                                            value={limit}
                                            onChange={(e) => {
                                                setPage(1);
                                                setLimit(e.target.value);
                                            }}
                                        >
                                            <MenuItem value={5}>5</MenuItem>
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={20}>20</MenuItem>
                                            <MenuItem value={30}>30</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {!loading && (
                                        <Pagination
                                            count={pageCount}
                                            page={page}
                                            variant="outlined"
                                            shape="rounded"
                                            onChange={handleChangePage}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {show &&
                <New show={show} closepopup={close} details={editData} />
            }
        </>
    );
}

export default Topics;
