import React from "react"

const CheckoutArrowLeftIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_5974_100983)">
        <path
          d="M9.16669 22H34.8333"
          stroke="#080808"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.16669 22L16.5 29.3333"
          stroke="#080808"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.16669 22L16.5 14.6667"
          stroke="#080808"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5974_100983">
          <rect width="44" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CheckoutArrowLeftIcon
