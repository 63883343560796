import React from 'react';
import { BrowserRouter as Router, Route, Link, withRouter,Redirect, NavLink} from "react-router-dom";
import Login, {User} from "../../pages/HomePage/Login";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BarChartData from '../../config/bar_chart_data.json';
import TrainingChart from '../../config/drilldown_chart.json';
import PageSubNavBar from '../../pages/shared/PageSubNavBar';

const initialState = {
    summary: null,
    client: [],
    result:[],
    audit_types:[],
    owners:[],
    projects: [],
    emp_ids: [],
    fields: {company: '', project:'', employee_id: '', employee_name: '', training_type: '', training_topic: '', drill_down: "", chart_type: ""}
};

class TrainingGraph extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialState
    }

    renderGraph(results, type){
        var that = this; var is_changed = "false"
        let chart_data = that.props.graph
        let fields = that.state.fields;
        // var display = this.props.graph_title ? "true" : (fields["drill_down"] == "" ? "false" : "true" )
        var params = that.props.params
        var display = fields["drill_down"] == "" ? "false" : "true"

        if (params && params.drill_down != "" && params.chart_type == "child"){
            display = "true"
        }

        //Training and certification graph
    if (chart_data){
        // if (display ==  "false" || params.drill_down == ""|| params.drill_down == "element_training"){
        //     TrainingChart["title"]["text"] = "Element Trainings"
        //     TrainingChart["chart"]["type"] = "column"
        //     TrainingChart["subtitle"]["text"] = ""
        //     TrainingChart["series"][0]["data"] = chart_data.element_trainings.types
        //     TrainingChart["drilldown"]["series"] = chart_data.element_trainings.topics
        //     TrainingChart["plotOptions"]["series"]["events"]["click"]=  function(event){
        //         $.each(fields, function(key,value){
        //             if (that.props.params.hasOwnProperty(key)){
        //                 fields[key] = that.props.params[key];
        //             }
        //         });

        //         if (event.point.type != undefined){
        //             fields["training_type"] = event.point.name
        //             fields["training_topic"] = ""
        //             fields["chart_type"] = "parent"

        //         }else{
        //             fields["training_topic"] = event.point.name
        //             fields["chart_type"] = "child"
        //         }
        //         is_changed = "true";fields["drill_down"] = "self_training"

        //         if (fields["training_type"] == params.training_type){
        //             is_changed = "false"
        //         }

        //         if (fields["training_topic"] != params.training_topic){
        //             is_changed = "true"
        //         }

        //         if(is_changed == "true"){
        //             // that.setState({fields});
        //             // that.props.onFilterChange(this.project_id, that);
        //             that.props.onFilterChange(this.project_id, fields);
        //         }
        //     }
        //     TrainingChart["chart"]["events"]["drillup"]=  function(event){
        //         fields["training_type"] = ""
        //         fields["training_topic"] = ""
        //         fields["chart_type"] = ""
        //         fields["drill_down"] = ""
        //         that.props.onFilterChange(this.project_id, fields);

        //     }
        //     Highcharts.chart('training_graph', TrainingChart,
        //     function (chart) { // on complete
        //         if (TrainingChart.series[0].data.length < 1) {
        //             chart.renderer.text('No Data Available', 140, 140)
        //                 .css({
        //                     color: '#4572A7',
        //                     fontSize: '20px'
        //                 })
        //                 .add();
        //         }

        //     });
        // }
    }
    }


    render() {
        const { graph, params } = this.props;
        return (

            <div className="row">
                {this.renderGraph(graph)}

                <div className="col-lg-12"  id="training_graph">
                </div>

            </div>

            );
    }
}

export default TrainingGraph;
