import React from "react";
import SideNav from "../../components/SideNav";
import TodoList from "./TodoList";

const TodoManagement = () => {
    return (
        <React.Fragment>
            <div className="msmcontainer dashboard-container">
                <div className="row d-flex">
                    <SideNav />
                    <div className="content-wrap asset-tracking-wrap risk-assessment">
                        <div className="title-tab-wrap">
                            <h1>
                                {/* <img src={CloseButton} alt="" className="header-closer-img" /> */}
                                <i
                                    style={{ paddingInlineStart: "5px", paddingRight: "5px" }}
                                    className="fa fa-list-alt"
                                ></i>
                                <b>TO DO list</b>
                            </h1>
                            <ul class="nav nav-tabs">
                                <li class="active">
                                    <a data-toggle="tab" href="#asset-tracking">
                                    TO DO list
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div class="tab-content">
                            <div id="asset-tracking" class="tab-pane fade in active">
                                <TodoList />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default TodoManagement;