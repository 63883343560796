import React from "react";
import axios from "axios";
import {
    BrowserRouter as Router,
    Route,
    Link,
    withRouter,
    Redirect,
} from "react-router-dom";

import { connect } from "react-redux";
import cx from "classnames";
import { setMobileNavVisibility } from "../../reducers/Layout";
import "../../assets/styles/themes.scss";
// const logo = require('../../assets/images/Logo.svg');
// PropTypes is a separate package now:
import PropTypes from "prop-types";

import Main from "../Main";
import HomePage from "../HomePage";

import UserDashboard from "../UserDashboard";
// import Footer from '../../components/Footer';
import HomeFooter from "../../pages/HomePage/Footer";
const logo = require("../../assets/images/logo_safety.png");
import Recaptcha from "react-recaptcha";
import login from "../../../javascript/assets/images/login.svg";
// import UpdatePassword from "./UpdatePassword";
import LoginFooter from "./LoginFooter";
import { Row } from "react-bootstrap";

const site_key = "6Ldqdk0UAAAAAEPyCg3XwNbIrBuFWY_J6XvwEolS";

const panelFooterStyle = {
    backgroundColor: "#282828",
};

const panelStyle = {
    backgroundColor: "#1A1A1A",
    marginTop: "100px",
};

// replace React.createClass with a class:
class UpdatePassword extends React.Component {
    constructor(props) {
        super(props);
        // this.state = this.state.
        this.state = {
            fields: { username: localStorage.email, new_password: "", confirm_password: "" },
            errors: {},
            updateSuccessful: false,
            after_login_path: '/analytics-dashboard'
        };

        // This binding is necessary to make `this` work in the callback
        // this.handleLogin = this.handleLogin.bind(this);
        this.handlePasswordUpdate = this.handlePasswordUpdate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        // this.updateLoginError = this.updateLoginError.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
    }

    componentDidMount() {
        var once = false;
        const toggleNewPassword = document.querySelector("#toggleNewPassword");
        const toggleConfirmPassword = document.querySelector("#toggleConfirmPassword");
        const newPassword = document.querySelector("#new_password");
        const confirmPassword = document.querySelector("#confirm_password");
        try {
            if (!once) {
                toggleNewPassword.addEventListener("click", function (e) {
                    // toggle the type attribute
                    // debugger;
                    const type =
                        newPassword.getAttribute("type") === "password" ? "text" : "password";
                    newPassword.setAttribute("type", type);
                    // console.log("Got here")
                    // toggle the eye slash icon
                    this.classList.toggle("fa-eye-slash");
                });
                toggleConfirmPassword.addEventListener("click", function (e) {
                    // toggle the type attribute
                    // debugger;
                    const type =
                        confirmPassword.getAttribute("type") === "password" ? "text" : "password";
                    confirmPassword.setAttribute("type", type);
                    // console.log("Got here")
                    // toggle the eye slash icon
                    this.classList.toggle("fa-eye-slash");
                });
                once = true;
            }
        } catch (e) { }
    }

    callback = function () {
        console.log("Done!!!!");
    };

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //Email
        // if (!fields["email"]) {
        //     formIsValid = false;
        //     errors["email"] = "Email cannot be empty";
        // }

        //    if(fields["email"] !== ''){
        //        let lastAtPos = fields["email"].lastIndexOf('@');
        //        let lastDotPos = fields["email"].lastIndexOf('.');
        //
        //        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
        //            formIsValid = false;
        //            errors["email"] = "Email is not valid";
        //        }
        //    }
        //password
        if (!fields["new_password"]) {
            formIsValid = false;
            errors["new_password"] = "Password is required";
        }
        if (!fields["confirm_password"]) {
            formIsValid = false;
            errors["confirm_password"] = "Confirm Password is required";
        } else if (fields['new_password'] !== fields["confirm_password"]) {
            formIsValid = false;
            errors["confirm_password"] = "Password mismatch";
        } else if (fields['new_password'].length < 6) {
            formIsValid = false;
            errors["new_password"] = "Password is too short (minimum is 6 characters)"
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    fetchOneSignalId() {
        return window.OneSignal.getUserId(function (userId) {
            // debugger;
            return userId;
        });
    }

    handleLogout(e) {
        e.preventDefault();
        const { history } = this.props;
        var that = this;
        let oneSignalId;

        try {
            oneSignalId = this.fetchOneSignalId();
        } catch (error) {
            oneSignalId = '';
        }

        $.ajax({
            type: "GET",
            url: `/users/sign_out?push_token=${oneSignalId}`,
            dataType: "json",
            error: function (error) {
                console.log(error);
            },
            success: function (res) {
                window.location.href = '/';
            },
        });
    }


    handlePasswordUpdate(e) {
        e.preventDefault();
        let errors = {};

        //check hanglkers
        if (this.handleValidation()) {
            let fields = this.state.fields;
            let that = this;
            let userDetails = {
                user: {
                    password: fields["new_password"],
                },
            };
            $(".loading").show();
            $.ajax({
                type: "PATCH",
                url: "/entity_management/update_password",
                dataType: "json",
                data: userDetails,
                error: function (error) {
                    // debugger;
                    errors["invalid_user"] = "Invalid Details";
                    that.setState({ errors: errors });
                    $(".loading").hide();
                },
                success: function (res) {
                    localStorage.username = ""
                    that.setState({
                        updateSuccessful: true
                    });

                    //window.pathname = "/";
                }
            });
        }
        else {
            console.log("Form has errors.");
            $(".loading").hide();
        }
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        if (field == "remember_me") {
            fields[field] = e.target.checked;
        } else {
            fields[field] = e.target.value;
        }
        // debugger;
        this.setState({ fields });
    }

    updateLoginError() {
        this.setState({
            loginUnsuccessful: true,
        });
    }

    updateCurrentUser(email) {
        this.setState({
            currentUser: email,
        });
    }

    render() {
        if (this.state.updateSuccessful) {
            $(".loading").hide();
            $('.update-user-password').text('Password Updated Successfully').css('display', 'block').fadeOut(2000)
            setTimeout(() => {
                window.location.href = "/analytics-dashboard";
            }, 2000)

        }

        return (
            <div className="main-wrap user-login-page">
                <div className="row new-login-page">
                    <div className="home-container login-page-revamp">
                        <div className="login-image col-md-8 col-sm-12">
                            <div className="login">
                                <img src={login} />
                            </div>
                        </div>
                        <div className="login-form col-md-4 col-sm-12">
                            <div className="login-details">
                                <h2 className="text-left login-header"> Update your password</h2>
                                <p>You need to update your password because this is the first time you are signing in,
                                    or because your password has expired</p>
                                <span style={{ color: "red" }}>
                                    {this.state.errors["invalid_user"]}
                                </span>
                                <div className="update-user-password span success_message alert alert-success strong" style={{ display: 'none' }}>
                                </div>
                                <form className="m-t-3">
                                    <div className="form-group">
                                        <label>User Name</label>
                                        <input
                                            className="form-control"
                                            ref="email"
                                            id="email"
                                            maxLength="50"
                                            placeholder="Enter your username"
                                            onChange={this.handleChange.bind(this, "email")}
                                            readOnly={true}
                                            value={this.state.fields["username"]}
                                        />
                                        <span style={{ color: "red" }}>
                                            {this.state.errors["email"]}
                                        </span>
                                    </div>
                                    <div className="form-group">
                                        <Row>
                                            <div className="col-md-6">
                                                <label>New Password</label>
                                                <input
                                                    className="form-control"
                                                    ref="new_password"
                                                    type="password"
                                                    maxLength="50"
                                                    id="new_password"
                                                    placeholder="New password"
                                                    onChange={this.handleChange.bind(this, "new_password")}
                                                    value={this.state.fields["new_password"]}
                                                />
                                                <i
                                                    className="fa fa-eye fa-eye-slash"
                                                    id="toggleNewPassword"
                                                    style={{
                                                        marginBottom: "10px",
                                                        marginTop: "-22px",
                                                        float: "right",
                                                        paddingRight: "10px",
                                                        cursor: "pointer",
                                                        display: "block",
                                                    }}
                                                ></i>
                                                <span style={{ color: "red", paddingTop: "10px" }}>
                                                    {this.state.errors["new_password"]}
                                                </span>
                                            </div>
                                            <div className="col-md-6">
                                                <label>Confirm Password</label>
                                                <input
                                                    className="form-control"
                                                    ref="confirm_password"
                                                    type="password"
                                                    maxLength="50"
                                                    id="confirm_password"
                                                    placeholder="Confirm password"
                                                    onChange={this.handleChange.bind(this, "confirm_password")}
                                                    value={this.state.fields["confirm_password"]}
                                                />
                                                <i
                                                    className="fa fa-eye fa-eye-slash"
                                                    id="toggleConfirmPassword"
                                                    style={{
                                                        marginBottom: "10px",
                                                        marginTop: "-22px",
                                                        float: "right",
                                                        paddingRight: "10px",
                                                        cursor: "pointer",
                                                        display: "block",
                                                    }}
                                                ></i>
                                                <span style={{ color: "red", paddingTop: "10px" }}>
                                                    {this.state.errors["confirm_password"]}
                                                </span>
                                            </div>
                                        </Row>
                                    </div>
                                    <button
                                        className="btn btn-block m-b-2 btn-primary revamp-login-btn"
                                        id="user-login"
                                        onClick={this.handlePasswordUpdate}
                                    >
                                        Update
                                    </button>
                                </form>
                                <div className="back-to-home">
                                    <Link to="/home" style={{ color: "#264153" }} onClick={this.handleLogout}>Back to Login</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div style={{ marginTop: "5px" }}>
                    <LoginFooter />
                </div> */}
            </div>
        );
    }
}
export default UpdatePassword;
