import React from 'react';
import useFetch from '../../../shared/components/userFetch';
import useModify from '../../../shared/components/userModify';
import NewGlobalProject from './NewGlobalProject';

const EditGlobalProject = ({edit, setEdit}) => {
    const {data, isLoading} = useFetch(`/api/v2/entity_management/global_projects/${edit}?view_type=group_info`);
    const {mutate, isLoading: isMutating, error} = useModify('/api/v2/entity_management/global_projects', true);
    if (isLoading) {
        return null;
    }
    return (
        <NewGlobalProject mutate={mutate} open={!!edit} setOpen={setEdit} initialValues={data.data} />
    )
}

export default EditGlobalProject;
