const ReactDataGrid = require('react-data-grid');
const { Toolbar, Data: { Selectors } } = require('react-data-grid-addons');
//const exampleWrapper = require('../components/exampleWrapper');
const React = require('react');

class ROTEmployeeData extends React.Component {
    constructor(props, context) {
    super(props, context);
    this._columns = [
        // {
        //     key: 'id',
        //     name: 'ID',
        //     width: 80
        // },
        // {
        //     key: 'first_name',
        //     name: 'First Name',
        //     filterable: true,
        //     sortable: true
        // },
        // {
        //     key: 'last_name',
        //     name: 'Last Name',
        //     filterable: true
        // },
        // {
        //     key: 'employee_id',
        //     name: 'Employee Id',
        //     filterable: true
        // },
        {
            key: 'full_name',
            name: 'Full Name',
            filterable: true,
            sortable: true
        },
        {
            key: 'project',
            name: 'Project',
            filterable: true
        },
        {
            key: 'training_type',
            name: 'Training Type',
            filterable: true
        },
        {
            key: 'topic',
            name: 'Training Topic',
            filterable: true
        },
        {
            key: 'completion_date',
            name: 'Completed Date',
            filterable: true
        },
        {
            key: 'audit_pdf',
            name: 'PDF',
            filterable: true,
            sortable: true
        },
        {
            key: 'status',
            name: 'Active or Expired',
            filterable: true,
            sortable: true
        }
    ];
    this.state = { rows: props.employees.data, filters: {}, sortColumn: null, sortDirection: null };
    }

    componentWillReceiveProps(nextProps){
        this.setState({ rows: nextProps.employees.data});
    }

    getRandomDate = (start, end) => {
        return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime())).toLocaleDateString();
    };

        createRows = (numberOfRows) => {

        let rows = [];
        for (let i = 1; i < numberOfRows; i++) {

            rows.push({
                id: i,
                Employee_First_Name: 'Vel ' + i,
                Employee_Last_Name: 'murugan ' + i,
                TrainingType: ['Bug', 'Improvement', 'Epic', 'Story'][Math.floor((Math.random() * 3) + 1)],
                TrainingTopic: ['Bug', 'Improvement', 'Epic', 'Story'][Math.floor((Math.random() * 3) + 1)],
                CompletionDate: this.getRandomDate(new Date(2015, 3, 1), new Date()),
                Active_or_Expired: ['Active', 'Expired'][Math.floor((Math.random() * 1) + 1)]
            });
        }
        return rows;
    };

        getRows = () => {
        // this.state = { rows: this.props.employees.data, filters: {}, sortColumn: null, sortDirection: null };
        return Selectors.getRows(this.state);
    };

        getSize = () => {
        return this.getRows().length;
    };

        rowGetter = (rowIdx) => {
        const rows = this.getRows();
        return rows[rowIdx];
    };

        handleGridSort = (sortColumn, sortDirection) => {
        this.setState({ sortColumn: sortColumn, sortDirection: sortDirection });
    };

        handleFilterChange = (filter) => {
        let newFilters = Object.assign({}, this.state.filters);
        if (filter.filterTerm) {
            newFilters[filter.column.key] = filter;
        } else {
            delete newFilters[filter.column.key];
        }

        this.setState({ filters: newFilters });
    };

        onClearFilters = () => {
        this.setState({ filters: {} });
    };

    render() {
    return  (
        <div>
              <ReactDataGrid
                    onGridSort={this.handleGridSort}
                    enableCellSelect={true}
                    columns={this._columns}
                    rowGetter={this.rowGetter}
                    rowsCount={this.getSize()}
                    minHeight={400}
                    toolbar={<Toolbar enableFilter={true}/>}
                onAddFilter={this.handleFilterChange}
                onClearFilters={this.onClearFilters} />
                </div>

        );
}
}

export default ROTEmployeeData;
