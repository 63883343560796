import React from 'react';

const Footer = () => (
  <footer>
    <div className="container-fluid">
        <p className="text-gray-dark">
            <strong className="m-r-1">SafetyLoop</strong>
            <span className="text-gray-dark">&#xA9; 2022
            </span>
        </p>
    </div>
</footer>
);

export default Footer;
