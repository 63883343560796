import React from 'react';
import useUpdate from '../../../shared/components/useUpdate';
import NewJobs from "./NewJobs";

const NewJobProfileCreate = ({open, setOpen, setFilterState}) => {
    const {mutate, isLoading: isMutating, error} = useUpdate('/api/v2/entity_management/job_profiles');
    return (
        <NewJobs open={open} mutate={mutate} setOpen={setOpen} setFilterState={setFilterState} isMutating={isMutating} />
    )
}

export default NewJobProfileCreate;
