import React, { useEffect, useMemo, useState } from "react";
import axios from "../../../../config/axiosConfig";
import { toast } from "react-toastify";
import { TableContainer, Paper, Pagination, MenuItem, FormControl, Select, Button } from '@mui/material';
import Application from "../../../../config/ApplicationWrapper";
import MulitiSelect from "../../../../components/MultiSelect";
import Loader from "../../../../components/NewLoader";

const FormsList = ({ id }) => {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(30);
    const [pageCount, setPageCount] = useState(0);
    const [pageDetails, setPageDetails] = useState([]);
    const [name, setSearchByName] = useState();
    const [show, setShow] = useState(false);
    const [formsList, setFormsList] = useState([]);
    const [selecetedForms, setSelecetedForms] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchable, setSearchable] = useState(false);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getPageDetails()
        }, 2000)

        return () => clearTimeout(delayDebounceFn)
    }, [name, limit])

    useMemo(() => {
        formDetails()
    }, [])

    const getPageDetails = async () => {
        try {
            window.scrollTo(0, 0);
            setLoading(true)
            let resultObj = await axios.get(`${Application.api_url}/api/v2/internal_subs/${id}/internal_templates`, {
                params: {
                    page: page,
                    limit: limit,
                    audit_template_name_cont: name,
                },
            });
            if (resultObj.status == 200) {
                let details = resultObj.data;
                setPageDetails(details ? details.data : [])
                setPageCount(
                    details && details.metadata ? details.metadata.pages : 0
                );
                setSelecetedForms(details && details.custom_data ? details.custom_data.template_ids : [])
                setSearchable(details && details.data.length > 0 ? true : false)
                setLoading(false)
            }
        } catch (err) {
            console.log("error==>", err);
        }
    };

    async function formDetails() {
        const formDetails = await axios.get(`${Application.api_url}/api/v2/common/templates`, {
            params: {
                identifier: true
            }
        });
        if (formDetails.status == 200 && formDetails.data.success === true) {
            var details = formDetails.data;
            setFormsList(details.data);
        }
    }

    const handleChangePage = (event, value) => {
        setPage(value);
        getPageDetails()
    };

    const hanldeLimitChange = (value) => {
        setPage(1);
        setLimit(value);
    }

    const clearFilter = () => {
        setSearchByName("");
    }

    const close = async (data) => {
        if (data) {
            const formData = new FormData();
            data.map((obj) => {
                formData.append("template_ids[]", obj.identifier);
            })

            if (data.length === 0) {
                formData.append("template_ids[]", []);
            }

            await axios.post(`${Application.api_url}/api/v2/internal_subs/${id}/internal_templates/share`, formData).then((result) => {
                if (result.data && result.data.success === true) {
                    toast.success(`Updated Successfully`)
                    setShow(false)
                    getPageDetails()
                }
            }).catch((error) => {
                error.response.data.message.map((msg) => {
                    toast.error(msg)
                });
            })
        } else {
            setShow(false)
        }
    }

    return (
        <>
            <div class="card asset-tab-landing">
                <div class="card-body">
                    <div class="tab-content">
                        <div
                            id="active-risk-assessment"
                            class="tab-pane fade in active active-risk-assessment internal-sub-employee"
                        >
                            <div className="nestedTable pf-table">
                                <div className="fields-setting">
                                    <div className="asset-active-filters">
                                        Active Filters :
                                        <span className="active-filters">
                                            <span>Forms Name :</span>
                                            <span className="selected">{name ? name : "All"}</span>
                                            <span className={name ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setSearchByName("") }}>X</span>
                                        </span>
                                        <span className="active-filters-clear" onClick={(e) => clearFilter()}>
                                            Clear All
                                        </span>
                                    </div>
                                    <div className="bulk-action">
                                        <Button variant="contained" className="btn-create" onClick={(e) => { setShow(true) }}>+ Add & Modify Shared Forms</Button>
                                    </div>
                                </div>
                                <TableContainer component={Paper}>
                                    <table class="table table-striped">
                                        <thead>
                                            <tr style={{ height: "100px" }}>
                                                <th scope="col" className="risk-assessment-divider">
                                                    <div className="d-flex justify-content-between align-item-center">
                                                        Forms Name
                                                    </div>
                                                    {searchable &&
                                                        <div class="input-group">
                                                            <input
                                                                type="text"
                                                                class="form-control assessment-name"
                                                                placeholder="Search"
                                                                aria-label="Username"
                                                                id="name"
                                                                aria-describedby="basic-addon1"
                                                                name="name"
                                                                onChange={(e) => {
                                                                    setSearchByName(e.target.value)
                                                                }}
                                                                value={name}
                                                            />
                                                            <div class="input-group-addon">
                                                                <span class="input-group-text" id="basic-addon1">
                                                                    <i class="fa fa-search" aria-hidden="true"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    }
                                                </th>
                                                <th scope="col" className="risk-assessment-divider">
                                                    Source Company
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pageDetails &&
                                                pageDetails.length > 0 &&
                                                pageDetails.map((val, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                {val.audit_template && val.audit_template.name}
                                                            </td>
                                                            <td>
                                                                {val.parent_company && val.parent_company.name}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            <Loader pageDetails={pageDetails} loading={loading} btnName={"+ Add & Modify Shared Forms"} open={(e) => { setShow(true) }} />
                                        </tbody>
                                    </table>
                                </TableContainer>
                                <div className="page-limits">
                                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                        <Select
                                            labelId="demo-select-small"
                                            id="demo-select-small"
                                            value={limit}
                                            onChange={(e) => {
                                                hanldeLimitChange(e.target.value);
                                            }}
                                        >
                                            <MenuItem value={5}>5</MenuItem>
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={20}>20</MenuItem>
                                            <MenuItem value={30}>30</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {!loading && (
                                        <Pagination
                                            count={pageCount}
                                            page={page}
                                            variant="outlined"
                                            shape="rounded"
                                            onChange={handleChangePage}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {show &&
                <MulitiSelect show={show} closepopup={close} header={"Shared Forms"} details={formsList} rightSide={selecetedForms} />
            }
        </>
    );
}

export default FormsList;
