import consumer from './consumer';
import { toast } from "react-toastify";
import { User } from '../pages/HomePage/Login';

export function subscribeToUploadStatus(userId, uploadStatusId) {
  return consumer.subscriptions.create({
    channel: 'UploadStatusChannel',
    user_id: userId,
    upload_status_id: uploadStatusId
  }, {
    connected() {
      console.log(`Subscribed to upload status channel upload_status_${userId}_${uploadStatusId}`);
    },

    disconnected() {
      console.log('Disconnected from upload status channel');
    },

    received(data) {
      if (data.success) {
        console.log("Data received: " + (data.uploaded_files + data.failed_files) + '/' + data.total_files + ':' + data.message);
        console.log('Data: ', data);
        updateProgressBar(data)
      } else {
        toast.warning(`Failed to upload file: ${data.filepath} so the upload stopped!`);
        User.currentBulkUploadId = null;
        User.currentBulkUploadPercentage = 0;
        $('.recent-upload-container').fadeOut();
      }
    },
    rejected() {
      console.error('Subscription was rejected.');
    },

    error(error) {
      console.error('WebSocket error:', error);
    }
  });
}

function updateProgressBar(data) {
  const progressBar = document.getElementById('progress-bar');
  const progressBarPercentage = document.getElementById('progress-bar-percentage');

  if (progressBar && progressBarPercentage) {
    const progress = Math.min(...[100.0, ((data.uploaded_files + data.failed_files) / data.total_files * 100)]);
    progressBar.style.width = `${progress}%`;
    progressBar.setAttribute('aria-valuenow', progress);
    progressBarPercentage.innerText = `${progress.toFixed(2)}%`;

    if (progress === 100) {
      console.log('Upload DONE!!!');
      toast.success("Recent upload completed!");
      User.currentBulkUploadId = null;
      User.currentBulkUploadPercentage = 0;
      $('.recent-upload-container').fadeOut();
    }
  }
}
