import React, { useEffect, useState } from "react";
import {
    Modal,
    FormControl as Input,
} from "react-bootstrap";
import { toast } from "react-toastify";
import Application from "../../../../config/ApplicationWrapper";
import axios from "../../../../config/axiosConfig";


export default function AddTimeType(props) {
    const { show, close, details } = props;
    const [onSubmit, setSubmit] = useState(false);
    const [disabled, setDisable] = useState(false);
    const [timeTypes, setTimeTypes] = useState([{name: ''}]);

    const handleInputChange = (index, event) => {
        const values = [...timeTypes];
        values[index][event.target.name] = event.target.value;
        setTimeTypes(values);
        setDisable(false)
    };

    const handleAddClick = () => {
        setTimeTypes([...timeTypes, { name: ''}]);
    };

    const handleRemoveClick = (index) => {
        const values = [...timeTypes];
        values.splice(index, 1);
        setTimeTypes(values);
    };

    useEffect(() => {
        details && setTimeTypes([details])
    }, [show])

    const create = async () => {
        try {
            setSubmit(true);
            setDisable(true);

            const hasBlankName = timeTypes.some(item => item.name === '');
            if (hasBlankName) {
                return true;
            }

            let time_types = timeTypes

            await axios.post(`${Application.api_url}/api/v2/time_trackers/time_types/bulk_create`, {time_types}).then((result) => {
                if (result.data && result.data.success === true) {
                    toast.success(`Created Successfully`)
                    close(result.data)
                }
            }).catch((error) => {
                toast.error(error.response.data.message.join(', '));
            })


        } catch (err) {
            console.log("err===>", err);
        }
    };

    const update = async () => {
        try {
            setSubmit(true);
            setDisable(true);

            if (!timeTypes[0].name) {
                return true;
            }

            let time_types = timeTypes[0]

            await axios.patch(`${Application.api_url}/api/v2/time_trackers/time_types/${details.id}`, time_types).then((result) => {
                if (result.data && result.data.success === true) {
                    toast.success(`Updated Successfully`)
                    close(result.data)
                }
            }).catch((error) => {
                toast.error(error.response.data.message.join(', '));
            })
        } catch (err) {
            console.log("err===>", err);
        }
    };

    return (
        <Modal
            show={show}
            onHide={(e) => {
                close();
            }}
            className="new-model-popup"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {!details && <i className="fa fa-plus" aria-hidden="true"></i>}
                    <b>{!details ? "Create" : "Update"} Time Type</b>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="new-form">
                    <div className="row">
                        {timeTypes && timeTypes.map((timeType, index) => (
                            <div className="col-md-12" style={{padding: 0, "padding-right": '10px'}}>
                                <div key={index} style={{display: 'flex', marginBottom: '5px'}}>
                                    <div className="col-md-8" style={{"padding-right": '15px'}}>
                                        <input
                                            type="text"
                                            name="name"
                                            id="name" className="input-new"
                                            value={timeType.name} required
                                            onChange={(event) => handleInputChange(index, event)}
                                            placeholder="Name"
                                        />
                                        {onSubmit && !timeType.name &&
                                            <p className="required-fields">Please enter the name</p>}
                                    </div>
                                    {index !== 0 && (
                                        <div className="col-md-1">
                                            <i className="fa fa-minus-circle"
                                               onClick={ () => handleRemoveClick(index)}
                                               style={{margin: "0px 5px", color: "red"}}>
                                            </i>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                        { !details &&
                            <div className="col-md-4" style={{
                                "text-decoration": 'underline',
                                color: '#407797',
                                padding: 0,
                                "padding-right": '10px'
                            }} onClick={handleAddClick}>
                                <i className="fa fa-plus"
                                   aria-hidden="true"
                                   style={{margin: "0px 10px 0px 15px"}}>
                                </i>
                                Add Value
                            </div>
                        }
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn button cancel-btn"
                    onClick={() => {
                        close()
                    }}
                >
                    Cancel
                </button>
                <button
                    className="btn btn-primary"
                    style={{marginLeft: "15px"}}
                    disabled={disabled}
                    onClick={(event) => {
                        {!details ? create() : update()}
                    }}
                >
                    {!details ? "Create" : "Update"}
                </button>
            </Modal.Footer>
        </Modal>
    );
}
