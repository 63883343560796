import React from 'react';
// import ComparisonHeader from './ComparisonHeader';


const ComparisonPage = () => {
  // Define an array of features
  const features = [
    "Real-time Alerts", "Incident Reporting", "Compliance Tracking",
    "Safety Training", "Risk Assessment", "Document Management",
    "Audit Management", "Equipment Tracking", "Work Permit Management",
    "Analytics and Reporting", "Emergency Notifications", "Task Management",
    "Employee Certifications", "Safety Meeting Management", "Hazard Identification",
    "Corrective Actions", "Mobile Access", "Offline Access", "Custom Forms",
    "Digital Signatures", "Integrations with Other Systems", "User Permissions",
    "Data Security Features", "Performance Dashboards", "Incident Investigation",
    "Occupational Health Management", "Environmental Management", "Sustainability Management",
    "Energy Management", "Quality Management"
  ];

  // Define an array of software, including a mock function to determine feature availability
  const softwareList = ["SafetyLoop", "eCompliance", "SiteDocs", "SalusPro", "CorFix"];

  // Placeholder function to randomly assign feature availability
  const isFeatureAvailable = (software, feature) => {
    if (software === "SafetyLoop") {
      return true;
    }
    return Math.random() > 0.5;
  };

  return (
    <div className="comparison-page" style={{ paddingTop: '100px' }}>
      <ComparisonHeader />
      <ComparisonTable features={features} softwareList={softwareList} isFeatureAvailable={isFeatureAvailable} />
      
    </div>
  );
};

// Other components (Header, HeroSection, Footer) remain unchanged

const ComparisonTable = ({ features, softwareList, isFeatureAvailable }) => {
  return (
    <table className="comparison-table">
      <thead>
        <tr>
          <th>Feature</th>
          {softwareList.map((software, index) => (
            <th key={index}>{software}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {features.map((feature, index) => (
          <tr key={index}>
            <td>{feature}</td>
            {softwareList.map((software, softwareIndex) => (
              <td key={softwareIndex}>
                {isFeatureAvailable(software, feature) ? (
                  <span className="checkmark">✓</span>
                ) : (
                  <span className="cross">X</span>
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const ComparisonHeader = () => {
    return (
      <header className="comparison-header">
        <h1>Safety Software Comparison: SafetyLoop and Competitors</h1>
        <p>SafetyLoop is committed to transparency and excellence in improving workplace safety standards.</p>
        <div className="comparison-subtext">
          We encourage you to see how we compare with others because we believe strongly in our product's ability to make a difference. Our confidence is reflected in our transparency and the comprehensive features we offer.
        </div>
      </header>
    );
  };

export default ComparisonPage;
