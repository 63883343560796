import React from 'react';
import HeroSection from './CommonComponents/HeroSection';
import FeaturesSection from './CommonComponents/FeaturesSection';
import ParallaxSection from '../../pages/HomePage/Components/ParallaxSection';
import HomepageDemoSection from '../../pages/HomePage/Components/HomepageDemoSection';

export default function ComplianceManagement() {
    return (
        <>
            <HeroSection
                heroText={"Compliance Management"}
                subText={"Ensure adherence to legal standards and regulatory requirements with SafetyLoop's compliance tools."}
            />
            <FeaturesSection
                headerText={"Comprehensive Compliance Management"}
                subText={"Manage compliance requirements and ensure adherence to safety regulations."}
                featureHighlights={[
                    "Track compliance across all safety standards.",
                    "Monitor regulatory changes and updates.",
                    "Generate compliance-ready reports for audits.",
                    "Ensure compliance with automated alerts."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for Compliance Management section here
            />
            <FeaturesSection
                headerText={"Automated Compliance Alerts"}
                subText={"Set up automated alerts to stay ahead of compliance deadlines and renewals."}
                featureHighlights={[
                    "Receive alerts for compliance renewals and deadlines.",
                    "Set up automated reminders for regulatory updates.",
                    "Ensure compliance with real-time tracking."
                ]}
                // Placeholder: Add image for Automated Alerts section here
            />
            <FeaturesSection
                headerText={"Compliance Reporting"}
                subText={"Generate detailed compliance reports for audits and safety management."}
                featureHighlights={[
                    "Create compliance-ready reports for audits.",
                    "Track compliance performance over time.",
                    "Easily share compliance status with stakeholders."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for Compliance Reporting section here
            />
            <div className="Home-Page sl-home-wrapper" style={{ paddingTop: 0 }}>
                <ParallaxSection />
            </div>
            <div className="features-homepage-wrapper">
                <HomepageDemoSection mergeTop={false} />
            </div>
        </>
    );
}
