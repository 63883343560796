import Axios from "axios";
import { useState } from "react";

const useModify = (url, formDataEnable = false) => {
    const [isLoading, setLoading] = useState(false);
    const mutate = async (payload, {onSuccess, onError}, overrideUrl = '') => {
        try {
            setLoading(true);
            const {data} = formDataEnable ? await Axios.put(overrideUrl || `${url}/${payload.id}`, payload, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }) : await Axios.put(overrideUrl || `${url}/${payload.id}`, payload);
            if(onSuccess) {
                onSuccess(data);
            }
        } catch (error) {
            const errorData = error.response ? error.response.data : error.message;
            if (onError) {
                onError(errorData);
            }
        } finally {
            setLoading(false);
        }
    }
    return {mutate, isLoading};
};

export default useModify;
