import React, { useEffect, useMemo, useState } from "react";
import CustomTable, {
  ActionMenu,
  CustomBotton,
  CustomPagination,
  HeaderItem,
  TagRender,
} from "../../../shared/components/table";
import {
  Autocomplete,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import useFetch from "../../../shared/components/userFetch";
import { ShowNotification } from "../../../shared/components/showNotification";
import useFilterChange from "../../../shared/components/useFilterChange";
import SearchIcon from "../../../../assets/fonts/Search.svg";
import { withRouter } from "react-router-dom";
import SharedFormEdit from "./SharedFormEdit";
import UploadDocs from "./UploadDoc";

const CompanyDocs = ({ group = false, match }) => {
  const { id } = match.params; // Access the 'id' param from the URL
  const [uploadView, setUploadView] = useState(false);
  const initialState = {
    page: 1,
    limit: 20,
  };
  const {
    handleOnFilterChange,
    filterState,
    filterData,
    setFilterState,
    handleSort,
  } = useFilterChange(initialState);
  const [notification, setNotification] = useState({
    open: false,
    message: "test",
    severity: "success",
  });
  const [editItem, setEditItem] = useState("");

  const {
    data: responseData,
    loading,
    error,
    refetch,
  } = useFetch(`/api/v2/entity_management/groups/${id}/group_docs`, {
    filters: filterData,
    pagination: { page: filterState.page, limit: filterState.limit },
    sort: filterState.sort,
  });

  const { data = [], metadata = {} } = responseData || {};

  const callRefetch = () => {
    refetch({
      filters: filterData,
      pagination: { page: filterState.page, limit: filterState.limit },
      sort: filterState.sort,
    });
  };
  useEffect(() => {
    if (!loading) {
      callRefetch();
    }
  }, [filterState]);

  const handleCloseNotification = () => {
    setNotification((prev) => ({
      ...prev,
      open: false,
    })); // Hide notification, retain other state values
  };

  const headers = [
    <TableCell>
      <HeaderItem
        title="File Name"
        isSort
        handleSort={() => handleSort("doc_name_cont")}
        filter={
          <TextField
            style={{ background: '#fff', color: '##080808', width: '80%' }}
            label="Search"
            value={filterData["doc_name_cont"]}
            onChange={(_, v) =>
              handleOnFilterChange(_.target.value, "doc_name_cont")
            }
          />
        }
      />
    </TableCell>,
    <TableCell>
      <HeaderItem
        title="Upload Date and Time"
        isSort
        handleSort={() => handleSort("uploaded_at")}
      />
    </TableCell>,
  ];

  const rows = useMemo(
    () =>
      data.map((ele, index) => {
        const { file_name, uploaded_at } = ele || {};
        return (
          <TableRow key={index} className={index % 2 === 0 ? "even-row" : "odd-row"}>
            <TableCell>{file_name}</TableCell>
            <TableCell>{uploaded_at}</TableCell>
          </TableRow>
        );
      }),
    [data]
  );

  const handleClickUpload = () => {
    setUploadView(!uploadView);
  };

  return (
    <>
      <ShowNotification
        open={notification.open}
        onClose={handleCloseNotification}
        severity={notification.severity}
        message={notification.message}
      />
      {editItem.id && (
        <SharedFormEdit
          edit={editItem}
          setEdit={setEditItem}
          setFilterState={setFilterState}
        />
      )}
      <div style={{ display: "flex", flexDirection: "row-reverse" }}>
        <CustomBotton
          style={{ background: "#407797", color: "#fff" }}
          type="button"
          variant="contained"
          size="small"
          startIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M2.66699 11.3335V12.6668C2.66699 13.0205 2.80747 13.3596 3.05752 13.6096C3.30756 13.8597 3.6467 14.0002 4.00033 14.0002H12.0003C12.3539 14.0002 12.6931 13.8597 12.9431 13.6096C13.1932 13.3596 13.3337 13.0205 13.3337 12.6668V11.3335"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4.66699 5.99984L8.00032 2.6665L11.3337 5.99984"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 2.6665V10.6665"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          }
          className="filled"
          onClick={() => handleClickUpload()}
        >
          Upload
        </CustomBotton>
      </div>
      <CustomTable
        headers={headers}
        rows={rows}
        isSliderScroll={false}
        renderActiveFilter={false}
      />
      {!!metadata.count && (
        <CustomPagination
          totalPage={metadata.count}
          lastPage={metadata.last}
          currentPage={filterState.page}
          handlePageChange={(_, nextPage) => {
            setFilterState((prev) => ({ ...prev, page: nextPage }));
          }}
          rowOptions={[20, 50, 100]}
          rowPerPage={filterState.limit}
          handleRowPerPageChange={(targetValue) => {
            setFilterState((prev) => ({
              ...prev,
              limit: parseInt(targetValue, 10),
              page: 1,
            }));
          }}
        />
      )}
      {uploadView && (
        <UploadDocs
          open={uploadView}
          setOpen={setUploadView}
          setFilterState={setFilterState}
        />
      )}
    </>
  );
};

export default withRouter(CompanyDocs);
