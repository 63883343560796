import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
  Redirect,
} from "react-router-dom";
import { connect } from "react-redux";
import cx from "classnames";
import { setMobileNavVisibility } from "../../reducers/Layout";
import login from "../../../javascript/assets/images/login.svg";
import success from "../../../javascript/assets/images/Success.png";
import LoginFooter from "./LoginFooter";
const logo = require("../../assets/images/Logo.svg");
import "../../assets/styles/themes.scss";
import { Modal, Button } from "react-bootstrap";

// PropTypes is a separate package now:
import PropTypes from "prop-types";
const panelFooterStyle = {
  backgroundColor: "#282828",
};

// replace React.createClass with a class:
class Forget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      forgetUnsuccessful: false,
      redirectReferPage: false,
      fields: { user_email: "" },
      errors: {},
      show: false,
    };

    // This binding is necessary to make `this` work in the callback
    this.handleLogin = this.handleLogin.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateLoginError = this.updateLoginError.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.handleHide = this.handleHide.bind(this);
  }

  handleChange(field, e) {
    // debugger;
    let fields = this.state.fields;
    fields[field] = e.target.value;
    console.log("asdsadasd");
    this.setState({ fields });
  }

  stopEnter(event) {
    if (event.which == 13 || event.keyCode == 13) {
      var link = document.getElementById("user-forget-password");
      link.click();
      return false;
    }
    return true;
  }

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //Email
    if (!fields["user_email"]) {
      formIsValid = false;
      errors["user_email"] = "Email cannot be empty";
    }

    if (fields["user_email"] !== "") {
      let lastAtPos = fields["user_email"].lastIndexOf("@");
      let lastDotPos = fields["user_email"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["user_email"].indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          fields["user_email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["user_email"] = "Email is not valid";
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleHide() {
    this.setState({
      redirectReferPage: true,
      show: false,
    });
  }

  handleLogin(e) {
    e.preventDefault();
    let errors = {};

    if (this.handleValidation()) {
      let fields = this.state.fields;
      var that = this;
      var userInfo = {
        user: {
          email: fields["user_email"],
        },
      };
      $.ajax({
        type: "POST",
        url: "/users/password",
        dataType: "json",
        data: userInfo,
        error: function (error) {
          that.updateLoginError();
        },
        success: function (res) {
          that.setState({
            show: true,
          });
        },
      });
    } else {
      console.log("Form has errors.");
    }
  }

  updateLoginError() {
    let errors = {};
    errors["user_email"] = "Email not found";
    this.setState({
      forgetUnsuccessful: true,
      errors: errors,
    });
  }

  render() {
    if (this.state.redirectReferPage) {
      // return <HomePage/>
      return <Redirect to="/" />;
    }

    return (
      <div className="main-wrap user-login-page">
        <div className="row new-login-page">
          <div className="home-container login-page-revamp">
            <div className="login-image col-md-8 col-sm-12">
              <div className="login">
                {/* <img src={login} /> */}
              </div>
            </div>
            <div className="login-form col-md-4 col-sm-12">
              <div className="login-details" style={{ maxWidth: "400px" }}>
                <h2 className="text-center login-header forget-psw">
                  Forget Password
                </h2>
                <span style={{ color: "red" }}>
                  {this.state.errors["invalid_user"]}
                </span>
                <form className="m-t-3">
                  <div className="form-group">
                    <input
                      className="form-control"
                      ref="user_email"
                      id="user_email"
                      maxLength="50"
                      placeholder="Enter your email"
                      onKeyDown={this.stopEnter.bind(this)}
                      onChange={this.handleChange.bind(this, "user_email")}
                      value={this.state.fields["user_email"]}
                    />
                    <span style={{ color: "red" }}>
                      {this.state.errors["user_email"]}
                    </span>
                  </div>
                  <button
                    className="btn btn-block m-b-2 btn-primary revamp-login-btn"
                    id="user-login"
                    onClick={this.handleLogin}
                  >
                    Reset Your Password
                  </button>
                </form>
                <div className="back-to-home">
                  <Link to="/login" style={{ color: "#264153" }}>Back to Login</Link>
                </div>
                {/* <span style={{ float: "right", fontSize: "10px" }}>
                    v{User.version}
                  </span> */}
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
        <div className="static-modal">
          <Modal
            show={this.state.show}
            onHide={this.handleHide}
            container={this}
            aria-labelledby="contained-modal-title"
            closeButton={true}
            className="forget-popup"
          >
            <Modal.Body>
              <div>
                <img src={success} alt="" />
                <h1>
                  <b>Success !</b>
                </h1>
                <p>Password reset request was sent successfully.</p>
                <p>Please check your email to reset your password.</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button bsStyle="primary" className="forget-popup-btn" onClick={this.handleHide}>Okay</Button>
            </Modal.Footer>
          </Modal>
        </div>
        {/* <div style={{ marginTop: "5px" }}>
          <LoginFooter />
        </div> */}
      </div>
    );
  }
}

export default Forget;
