import React from 'react';
import { BrowserRouter as Router, Route, Link, withRouter,Redirect, NavLink} from "react-router-dom";
import Login, {User} from "../../pages/HomePage/Login";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BarChartData from '../../config/bar_chart_data.json';
import CorBarChartData from '../../config/cor_bar_chart_data.json';
import PageSubNavBar from '../../pages/shared/PageSubNavBar';

const initialState = {
    summary: null,
    client: [],
    result:[],
    audit_types:[],
    owners:[],
    projects: [],
    emp_ids: [],
    fields: {company: '', project:'', employee_id: '', name: '', training_type: '', training_topic: '', training_status: '', drill_down: "", chart_type: ""}
};

class CORGraphs extends React.Component {

    constructor(props) {
    super(props);
    this.state = initialState
}

    renderGraph(results, type){
    var that = this; var is_changed = "false"
    let chart_data = this.props.graph
    let fields = that.state.fields;
    var params = that.props.params

    if (chart_data){
        CorBarChartData["series"][0]["data"] = chart_data.element_trainings.data
        CorBarChartData.plotOptions["series"]["point"]["events"]["click"] = function (event) {
          window.open(event.point.pdflink, '_blank');
        }
        Highcharts.chart('element_training_graph', CorBarChartData);
    }
}

    render() {
    const { graph, params } = this.props;
    return (
            <div className="row">

                  {this.renderGraph(graph)}
                <div className="col-lg-12" id="element_training_graph">
                </div>

            </div>
        );
}
}

export default CORGraphs;
