import React, {useEffect, useState} from 'react';
import AddCommentModal from "../popups/AddCommentModal";
import OrderItemCommentsModal from "../popups/OrderItemCommentsModal";
import DeliveryStatusUpdateModal from "../popups/DeliveryStatusUpdateModal";

const OrderItemActionMenu = ({ itemId, isOpen, onToggle, item, fetchDetails }) => {
  const [showAddCommentModal, setShowAddCommentModal] = useState(false);
  const [showViewCommentsModal, setShowViewCommentsModal] = useState(false);
  const [showDeliveryStatusUpdateModal, setShowDeliveryStatusUpdateModal] = useState(false);

  const openAddCommentModal = () => {
    setShowAddCommentModal(true);
  }

  const closeAddCommentModal = () => {
    setShowAddCommentModal(false);
  }

  const openViewCommentsModal = () => {
    setShowViewCommentsModal(true);
  }

  const closeViewCommentsModal = () => {
    setShowViewCommentsModal(false);
  }

  const openDeliveryStatusUpdateModal = () => {
    setShowDeliveryStatusUpdateModal(true);
  }

  const closeDeliveryStatusUpdateModal = () => {
    setShowDeliveryStatusUpdateModal(false);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.action-menu')) {
        onToggle(false)
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className="action-menu">
      <button className="ellipsis-btn" onClick={(e) => {
        e.stopPropagation();
        onToggle(itemId);
      }}>⋮</button>
      {isOpen && <div className="action-menu--dropdown">
        <button className="action" onClick={openAddCommentModal}>
          <i
            className="fa fa-comment"
            style={{
              fontSize: "16px",
              cursor: "pointer",
            }}
          ></i>
          <span className={"ml-2"}>Add Comment</span>
        </button>

        <button className="action" onClick={openViewCommentsModal}>
          <i
            className="fa fa-comment"
            style={{
              fontSize: "16px",
              cursor: "pointer",
            }}
          ></i>
          <span className={"ml-2"}>View Comments</span>
        </button>

        <button
          className={`action ${!item.delivery_request_item ? 'disabled' : ''}`}
          onClick={openDeliveryStatusUpdateModal}
          disabled={!item.delivery_request_item}
        >
          <i
            className="fa fa-pencil"
            style={{
              fontSize: "16px",
              cursor: "pointer",
            }}
          ></i>
          <span className={"ml-2"}>Delivery Status</span>
        </button>
      </div>}

      {showAddCommentModal &&
        <AddCommentModal itemId={itemId} show={showAddCommentModal} close={closeAddCommentModal}/>}
      {showViewCommentsModal &&
        <OrderItemCommentsModal show={showViewCommentsModal} close={closeViewCommentsModal} notes={item.notes}/>}
      {showDeliveryStatusUpdateModal &&
        <DeliveryStatusUpdateModal show={showDeliveryStatusUpdateModal} close={closeDeliveryStatusUpdateModal} item={item.delivery_request_item} fetchDetails={fetchDetails} />}
    </div>
  )
}

export default OrderItemActionMenu;