import React from 'react';
import { BrowserRouter as Router, Route, Link, withRouter,Redirect, NavLink} from "react-router-dom";
import Login, {User} from "../../pages/HomePage/Login";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Components from '../../components';

class EmployeeInformation extends React.Component {

    constructor(props) {
        super(props);
    }

    get_data_date(data){
        return (data.getDate()  + "/" + (data.getMonth()+1) + "/" + data.getFullYear());
    }

    render() {
        const { employees, count } = this.props;
        const table = employees ? (<Components.ROTEmployeeData employees={employees} count={5}/>)
                 :
               (" <div className='col-lg-7 side-nav'> Data is Loading.. <div>")
               console.log(table)
        return (
            <div className="col-lg-12">
                <h2>Employee Information</h2>
                {table}
            </div>
           
            );
    }
}

export default EmployeeInformation;