import React from 'react';
import { BrowserRouter as Router, Route, Link, withRouter,Redirect} from "react-router-dom";
import { connect } from 'react-redux';
import cx from 'classnames';
import { setMobileNavVisibility } from '../../reducers/Layout';
const logo = require('../../assets/images/Logo.svg');
import '../../assets/styles/themes.scss';

// PropTypes is a separate package now:
import PropTypes from 'prop-types';


const panelStyle = {
    backgroundColor: "#1A1A1A"
};


// replace React.createClass with a class:
class Signup extends React.Component {

    constructor(props) {
    super(props);
    this.state = {
        signupUnsuccessful: false,
        signupsuccessful: false,
        fields: {email:'',password:'',password_confirmation:''},
        errors: {}
    }

    // This binding is necessary to make `this` work in the callback
    this.handleSignup = this.handleSignup.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateLoginError = this.updateSignupError.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    }

    handleValidation(){
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;


        //Email
        if(!fields["email"]){
            formIsValid = false;
            errors["email"] = "Email Cannot be empty";
        }

        if(typeof fields["email"] !== "undefined"){
            let lastAtPos = fields["email"].lastIndexOf('@');
            let lastDotPos = fields["email"].lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
                formIsValid = false;
                errors["email"] = "Email is not valid";
            }
        }

        if(!fields["password"]){
            formIsValid = false;
            errors["password"] = "Password Cannot be empty";
        }

        if(!fields["password_confirmation"]){
            formIsValid = false;
            errors["password_confirmation"] = "Confirm Password Cannot be empty";
        }

        if(!(fields["password_confirmation"]==fields["password"])){
            formIsValid = false;
            errors["password_confirmation"] = "Confirm password does not match";
        }

        this.setState({errors: errors});
        return formIsValid;
    }

    handleChange(field, e){
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({fields});
    }

    handleSignup(e) {
        e.preventDefault();
        let errors = {};
        if(this.handleValidation()){
            var that = this;
            var userInfo = {
                user: {
                    email: document.getElementById("email").value,
                    password: document.getElementById("password").value,
                    password_confirmation: document.getElementById("password_confirmation").value
                }
            }
            $.ajax({
                type: "POST",
                url: "/users",
                dataType: "json",
                data: userInfo,
                error: function (error) {
                    that.updateSignupError()
                },
                success: function (res) {
                    that.setState({
                        signupsuccessful: true
                    })
                }
            });
        }else{
            console.log("Form has errors.")
        }




    }

    updateSignupError() {
        this.setState({
            signupUnsuccessful: true
        });
    }

    render() {

    if (this.state.signupsuccessful) {
        // return <Main />
        return <Redirect to="/"/>
    }

    return (
        <div className="main-wrap">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4 col-md-offset-4">
                            <div className="panel panel-default b-a-2 no-bg b-gray-dark" style={panelStyle}>
                                <div className="panel-heading text-center">
                                    <a href="../index.html"> <img src={logo} alt="Logo" className="m-t-3 m-b-3 h-20"/></a>
                                </div>
                                <div className="panel-body">
                                    <h2 className="text-center f-w-300 m-b-0">Register</h2>
                                    <p className="text-center m-b-3">We can help you create user account.</p>
                                    <form>
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input className="form-control" id="email" ref="email" maxLength="50" placeholder="Enter your Email" onChange={this.handleChange.bind(this, "email")} value={this.state.fields["email"]}/>
                                            <span style={{color: "red"}}>{this.state.errors["email"]}</span>
                                        </div>
                                        <div className="form-group">
                                          <label>Password</label>
                                          <input className="form-control" id="password" ref="password" maxLength="50" placeholder="Enter your Password" onChange={this.handleChange.bind(this, "password")} value={this.state.fields["password"]}/>
                                            <span style={{color: "red"}}>{this.state.errors["password"]}</span>
                                        </div>
                                        <div className="form-group">
                                            <label>Repeat Password</label>
                                            <input className="form-control" id="password_confirmation" maxLength="50" ref="password_confirmation" placeholder="Enter your ConfirmPassword" onChange={this.handleChange.bind(this, "password_confirmation")} value={this.state.fields["password_confirmation"]}/>
                                            <span style={{color: "red"}}>{this.state.errors["password_confirmation"]}</span>
                                        </div>
                                            {/*<div className="checkbox">
                                            <label>
                                                <input type="checkbox"/> Accept Terms &amp; Privacy Policy
                                            </label>
                                        </div>*/}
                                            <a onClick={this.handleSignup} role="button" className="btn m-b-2 btn-block btn-primary">Register</a>
                                    </form>
                                </div>
                                <div className="panel-footer b-a-0 b-r-a-0">
                                    <Link to="/login">Login</Link>
                                </div>
                            </div>
                            <p className="text-gray-light text-center"><strong>MASHEY Dashboard </strong> <span className="text-gray-light">© 2018 - 2019. Made by <i className="fa fa-fw fa-heart text-danger"></i> </span></p>
                        </div>
                    </div>
                </div>

            </div>



        </div>
        );
}
}

export default Signup;
