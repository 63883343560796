import React from "react";

const ErrorFallback = () => {
    return (
        <div className="error-block">
            <h4>Failed to load data. <a onClick={() => { window.location.reload() }}>Reload</a></h4>
        </div>
    )
}

export default ErrorFallback