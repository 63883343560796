import React, { useState } from "react";
import { Button, Modal, Typography, Box, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";
import FormWrapper from "../../../shared/components/formWrapper";
import { ShowNotification, showToast } from "../../../shared/components/showNotification";
import UploadFile from "../../../shared/components/uploadFile";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  height: "75%",
  backgroundColor: "#fff",
  boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.30)",
  borderRadius: "12px",
  p: 4,
};

const UploadForm = ({
  open,
  setOpen,
  initialValues = null,
  mutate,
  setFilterState,
  match
}) => {
    const { id } = match.params; // Access the 'id' param from the URL
  const isEdit = !!initialValues;

  const defaultValues = isEdit
    ? { ...initialValues}
    : {};
  const {
    control,
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues,
  });
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSubmit = (data) => {
    console.log(data);
    const formData = new FormData();

    // Append array data by iterating through each array
    Object.values(data.docs).forEach((file) => {
        formData.append('group[docs][]', file);
    });

    if (isEdit) {
      payload.id = initialValues.id;
    }
    mutate(formData, {
      onSuccess: () => {
        showToast({ message: `Uploaded Successfully!` });

        setTimeout(() => {
          setFilterState({ page: 1, limit: 20 });
          handleClose(); // Close the modal after submission
        }, 0);
      },
    }, `/api/v2/entity_management/groups/${id}/upload_docs`);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <FormWrapper>
          <Box sx={style}>
            <Typography className="popup-title" id="modal-title" variant="h6" component="h2">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
              <path d="M5.33301 22.6665V25.3332C5.33301 26.0404 5.61396 26.7187 6.11406 27.2188C6.61415 27.7189 7.29243 27.9998 7.99967 27.9998H23.9997C24.7069 27.9998 25.3852 27.7189 25.8853 27.2188C26.3854 26.7187 26.6663 26.0404 26.6663 25.3332V22.6665" stroke="#080808" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M9.33301 12.0002L15.9997 5.3335L22.6663 12.0002" stroke="#080808" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M16 5.3335V21.3335" stroke="#080808" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            </svg> Upload Docs
            </Typography>
            <form
              onSubmit={handleSubmit(onSubmit)}
            >
              <Box
                sx={{ mt: 2 }}
              >
                <Grid item xs={12} sm={6}>
                  <UploadFile control={control} name="docs" multiple />
                </Grid>
              </Box>

              <Box
                mt={2}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <div />
                <div>
                  <Button
                    style={{
                      textTransform: "none",
                      backgroundColor: "#ECECEC",
                      color: "#5C5C5C",
                      marginRight: "10px",
                      outline: "none",
                    }}
                    onClick={() => setOpen(false)}
                    sx={{ mr: 1 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{
                      textTransform: "none",
                      backgroundColor: "#407797",
                      color: "#fff",
                      outline: "none",
                    }}
                    variant="contained"
                    htmlType="button"
                    type="submit"
                    color="primary"
                  >
                    Submit
                  </Button>
                </div>
              </Box>
            </form>
          </Box>
        </FormWrapper>
      </Modal>
    </>
  );
};

export default withRouter(UploadForm);
