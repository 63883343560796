import React, { useState } from "react";
import Select from "react-select";
import {
    currentYear,
    frequencyOptions,
    generateYearOptions,
    generateMonthOptionsData,
} from "../../utils/util.js";

const initialState = {
    year: currentYear,
    frequency: "month",
    month: "",
    user: "",
    form_type_id: ""
};

const FormsGroupByFilters = ({
                                 formFilters,
                                 filters,
                                 onFilterSelect,
                                 onFilterReset,
                             }) => {
    const [fields, setFields] = useState(filters);

    const onFilterChange = (event, field) => {
        // Immediately update the state for 'frequency'
        setFields((prevFields) => {
            const updatedFields = { ...prevFields, [field]: event };

            // Handle query params generation based on updated fields
            let params = generateQueryParams(event, field, updatedFields);

            // If 'frequency' is 'week' but no 'month' is selected

            if (params.frequency === "week" && !params.month) {
                console.warn("Month is required when filtering by week.");
            } else {
                onFilterSelect(params); // Call the filter select function with updated params
            }


            return updatedFields; // Return updated state
        });
    };

    const onResetFilter = () => {
        setFields(initialState); // Reset fields to initial state
        onFilterReset(initialState); // Call reset callback
    };

    const generateQueryParams = (event, field, updatedFields) => {
        let params = {};
        Object.keys(updatedFields).forEach(function(key) {
            // Check if the field exists and access its value or fallback to an empty string
            params[key] = updatedFields[key] && updatedFields[key].value ? updatedFields[key].value : updatedFields[key] || "";
        });
        return params;
    };

    const renderMonthFilter = () => {
        // 'Month' filter should only be visible if frequency is 'week'
        return fields.frequency.value === "week" ? (
            <div>
                <label className="control-label">Month</label>
                <Select
                    name="month"
                    value={fields.month}
                    onChange={(e) => onFilterChange(e, "month")}
                    options={generateMonthOptionsData()}
                    id="month"
                    isClearable={false}
                />
            </div>
        ) : null;
    };

    return (
        <div className="forms-group-filter">
            <div className="row">
                <div className="col-xs-3 col-md-3 mt-4">
                    <label className="control-label">Form Type</label>
                    <Select
                        name="form_type_id"
                        value={fields.form_type_id}
                        onChange={(e) => onFilterChange(e, "form_type_id")}
                        options={formFilters.form_types}
                        id="form_type_id"
                        isClearable={false}
                    />
                </div>

                <div className="col-xs-3 col-md-3 mt-4">
                    <label className="control-label">Group By</label>
                    <Select
                        name="frequency"
                        value={fields.frequency}
                        onChange={(e) => onFilterChange(e, "frequency")}
                        options={frequencyOptions}
                        id="frequency"
                        isClearable={false}
                    />
                </div>

                <div className="col-xs-3 col-md-3 mt-4">
                    <label className="control-label">Year</label>
                    <Select
                        name="year"
                        value={fields.year}
                        onChange={(e) => onFilterChange(e, "year")}
                        options={generateYearOptions()}
                        id="year"
                        isClearable={false}
                    />
                </div>

                {/* Month filter that shows up only if 'frequency' is set to 'week' */}
                <div className="col-xs-3 col-md-3 mt-4">
                    {renderMonthFilter()}
                </div>

                <div
                    className="col-xs-3 col-md-3 d-flex align-item-center justify-content-between"
                    style={{ marginTop: "20px" }}
                >
                    <div className="filter-button" id="home-reset-filter">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={onResetFilter}
                        >
                            Reset Filter
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormsGroupByFilters;
