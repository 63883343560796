import React, {useEffect, useState } from "react"
import CheckoutArrowLeftIcon from "../../components/Checkout/CheckoutArrowLeftIcon"
import CheckoutAccountInfo from "../../components/Checkout/CheckoutAccountInfo"
// import CheckoutBillingInfo from "../../components/Checkout/CheckoutBillingInfo"
// import CheckoutReviewOrder from "../../components/Checkout/CheckoutReviewOrder"
import CheckoutOrderSummary from "../../components/Checkout/CheckoutOrderSummary"
import Application from "../../config/ApplicationWrapper"
import axios from "axios"
import { Link } from "react-router-dom"

export const STEPS = {
  ACCOUNT_INFO: 'ACCOUNT_INFO',
  // BILLING_INFO: 'BILLING_INFO',
  // REVIEW_ORDER: 'REVIEW_ORDER'
}

export const stepOrder = [
  STEPS.ACCOUNT_INFO,
  // STEPS.BILLING_INFO,
  // STEPS.REVIEW_ORDER
]

const CheckoutPage = () => {
  const [checkoutStep, setCheckoutStep] = useState(0)

  const [subscriptionPlan, setSubscriptionPlan] = useState()

  useEffect(() => {
    const fetchSubscriptionPlan = async () => {
      try {
        const result = await axios.get(`${Application.api_url}/api/v2/subscription_plans`)

        if (result && result.data && result.data.data) {
          const subscriptionPlans = result.data.data
          const subscriptionPlanId = parseInt(new URLSearchParams(window.location.search).get("subscription_plan_id"))
          const foundPlan = subscriptionPlans.find((plan) => plan.id === subscriptionPlanId)
          if (foundPlan) {
            setSubscriptionPlan(foundPlan)
          } else {
            console.error('Invalid subscription_plan_id') // TODO: handle subscription_plan_id error in a better way
          }
        } else {
          console.error('Can not fetch subscription plans')
        }
      } catch (err) {
        console.error(err)
      }
    }

    fetchSubscriptionPlan()
  }, [])

  if (!subscriptionPlan) {
    return null
  }

  // const checkoutNextStep = () => {
  //   setCheckoutStep((prev) => prev + 1)
  // }

  const changeCheckoutStep = (step) => {
    setCheckoutStep(step)
  }

  return (
    <div className="eContainer">
      <Link to="/pricing" style={{ color: 'inherit', textDecoration: 'inherit'}}>
        <div className="checkoutPage__back">
          {/* <CheckoutArrowLeftIcon /> */}
          <p className="mb-0">
            Create your account
            {
              subscriptionPlan.name === 'Safety Starter' ?
                <p></p>
              :
                <p className="mb-2" style={{fontSize:'14px'}}>No credit card, no commitment, and cancel anytime. Our totally free trial period is 30 days, plenty of time to get set up and try things out.</p>
            }
          </p>
          
          

        </div>
      </Link>

      <div className="checkoutPage__content">
        <div className="checkoutPage__steps">
          <CheckoutAccountInfo
            openAccountInfo={() => changeCheckoutStep(STEPS.ACCOUNT_INFO)}
            isDone={checkoutStep > 0}
            isOpen={stepOrder[checkoutStep] === STEPS.ACCOUNT_INFO}
            // checkoutNextStep={checkoutNextStep}
          />
          {/*<CheckoutBillingInfo*/}
          {/*  openBillingInfo={() => changeCheckoutStep(STEPS.BILLING_INFO)}*/}
          {/*  isDone={checkoutStep > 1}*/}
          {/*  isOpen={stepOrder[checkoutStep] === STEPS.BILLING_INFO}*/}
          {/*  checkoutNextStep={checkoutNextStep}*/}
          {/*/>*/}
          {/*<CheckoutReviewOrder*/}
          {/*  address={undefined}*/}
          {/*  openReviewOrder={() => changeCheckoutStep(STEPS.REVIEW_ORDER)}*/}
          {/*  cardNumber="1234 5678 9012 3456"*/}
          {/*  isOpen={stepOrder[checkoutStep] === STEPS.REVIEW_ORDER}*/}
          {/*/>*/}
        </div>

        <div className="checkoutPage__summary">
          <CheckoutOrderSummary />
        </div>
      </div>
    </div>
  )
}

export default CheckoutPage
