import React, { useEffect, useState } from "react";
import axios from "../../config/axiosConfig";
import { NavLink } from "react-router-dom";
import { Button, TableContainer, Paper, Pagination, MenuItem, FormControl, Select } from '@mui/material';
import Filter from "../../assets/images/Filter.png";
import { toast } from "react-toastify";
import loader from "../../assets/images/newloader.gif";
import EmptyTable from "../../assets/images/empty-assets.svg";
import Application from "../../config/ApplicationWrapper";
import TodoForm from "./Popups/TodoForm";

export default function Active({ updateActiveCount, updateChanges, updateActiveRecord }) {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [pageDetails, setPageDetails] = useState([]);
    const [paginationStatus, setPaginationStatus] = useState(false);
    const [name, setSearchByName] = useState();
    const [content, setSearchByContent] = useState();
    const [selectedDate, setSearchByDate] = useState();
    const [sorting, setSorting] = useState(false);
    const [isShowTodoForm, setShowTodoForm] = useState(false);
    const [formMode, setFormMode] = useState("create");
    const [selectedTodoId, setSelectedTodoId] = useState(null);

    useEffect(() => {
        getPageDetails()
    }, [page, limit, sorting, updateActiveRecord])


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getPageDetails()
        }, 2000)

        return () => clearTimeout(delayDebounceFn)
    }, [name, content, selectedDate])

    const getPageDetails = async () => {
        try {
            window.scrollTo(0, 0);
            let resultObj = await axios.get(`${Application.api_url}/api/todos`, {
                params: {
                    page: page,
                    limit: limit,
                    archived_eq: false,
                    name_cont: name,
                    content_cont: content,
                    due_date_eq: selectedDate,
                    sort: sorting ? "name asc" : "name desc",
                },
            });
            if (resultObj.status == 200 && resultObj.data.success === true) {
                let details = resultObj.data;
                setPageDetails(details ? details.data : [])
                setPageCount(
                    details && details.metadata ? details.metadata.pages : 0
                );
                updateActiveCount(details && details.metadata ? details.metadata.count : 0
                )
                setPaginationStatus(true)
            }
        } catch (err) {
            console.log("error==>", err);
        }
    };

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const clearFilter = () => {
        setSearchByDate("");
        setSearchByName("");
        setSearchByContent("");
    }

    const archive = async (id) => {
        let result = await axios.post(`${Application.api_url}/api/todos/${id}/archive`);
        if (result.status == 200 && result.data.success === true) {
            if (page == 1) {
                getPageDetails();
              } else {
                setPage(1);
              }
              updateChanges()
              toast.success("Archive Successfully")
        }
    }

    const NoAssetsFound = () => (
        <div className="no-assets" style={{ textAlign: "center" }}>
            <img src={EmptyTable} />
            <p>
                <b>No Data</b>
            </p>
        </div>
    );

    const openForm = (mode) => {
        setFormMode(mode);
        setShowTodoForm(true);
    };

    const handleClose = (data) => {
        setShowTodoForm(false);
        data && getPageDetails();
    }

    return (
        <>
            {paginationStatus === true && (
                <div className="nestedTable pf-table">
                    <div className="fields-setting">
                        <div className="asset-active-filters">
                            Active Filters :
                            <span className="active-filters">
                                <span>Title :</span>
                                <span className="selected">{name ? name : "All"}</span>
                                <span className={name ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setSearchByName("") }}>X</span>
                            </span> 
                            <span className="active-filters">
                                <span>Due Date :</span>
                                <span className="selected">{selectedDate ? selectedDate : "All"}</span>
                                <span className={selectedDate ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setSearchByDate("") }}>X</span>
                            </span>
                            <span className="active-filters-clear" onClick={(e) => clearFilter()}>
                                Clear All
                            </span>
                        </div>
                    </div>
                    <TableContainer component={Paper}>
                        <table class="table table-striped">
                            <thead>
                                <tr style={{ height: "100px" }}>
                                    <th scope="col" className="risk-assessment-divider">
                                        <div
                                            className="d-flex justify-content-between align-item-center"
                                        >
                                            Title
                                            <img src={Filter} alt="" className={sorting ? "up-arrow" : ""} onClick={(e) => {
                                                setSorting(!sorting);
                                            }}/>
                                        </div>
                                        <div class="input-group">
                                            <input
                                                type="text"
                                                class="form-control assessment-name"
                                                placeholder="Search"
                                                aria-label="Username"
                                                id="name"
                                                aria-describedby="basic-addon1"
                                                name="name"
                                                onChange={(e) => {
                                                    setSearchByName(e.target.value)
                                                }}
                                                value={name}
                                            />
                                            <div class="input-group-addon">
                                                <span class="input-group-text" id="basic-addon1">
                                                    <i class="fa fa-search" aria-hidden="true"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </th> 
                                    <th scope="col" className="risk-assessment-divider">
                                        Due Date
                                        <div class="input-group">
                                            <input
                                                type="date"
                                                class="form-control"
                                                placeholder="Search"
                                                value={selectedDate}
                                                onChange={(e) => {
                                                    setSearchByDate(e.target.value);
                                                }}
                                            />
                                            <div class="input-group-addon">
                                                <span class="input-group-text" id="basic-addon1">
                                                    <i class="fa fa-search" aria-hidden="true"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </th>
                                    <th scope="col" className="table-action">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pageDetails &&
                                    pageDetails.length > 0 &&
                                    pageDetails.map((val, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    {val.name}
                                                </td> 
                                                <td>
                                                    {val.original_formatted_due_date}
                                                </td>
                                                <td
                                                    className="template-options"
                                                    style={{ textAlign: "center" }}
                                                >
                                                    <a
                                                        aria-expanded="false"
                                                        aria-haspopup="true"
                                                        class="btn dropdown-toggle"
                                                        data-toggle="dropdown"
                                                        href="#"
                                                        id="dropdownMenuLink"
                                                        role="button"
                                                    >
                                                        <i class="fa fa-ellipsis-v"></i>
                                                    </a>
                                                    <ul aria-labelledby="dropdownMenuLink" class="dropdown-menu">
                                                        <li>
                                                            <a
                                                                class="archive_group_invites"
                                                                data-toggle="tooltip"
                                                                role="button"
                                                                title="View"
                                                                onClick={(e) => {
                                                                    openForm("view");
                                                                    setSelectedTodoId(val.id);
                                                                }}
                                                            >
                                                                <div class="row">
                                                                    <div class="col-sm-3">
                                                                        <i
                                                                            class="fa fa-eye"
                                                                            style={{
                                                                                paddingTop: "5px",
                                                                                fontSize: "16px",
                                                                            }}
                                                                        ></i>
                                                                    </div>
                                                                    <div class="col-sm-9" style={{ paddingTop: "5px" }}>
                                                                        View Info
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            <a
                                                                class="archive_group_invites"
                                                                data-toggle="tooltip"
                                                                title="Edit"
                                                                onClick={(e) => {
                                                                    openForm("edit");
                                                                    setSelectedTodoId(val.id);
                                                                }}
                                                            >
                                                                <div class="row">
                                                                    <div class="col-sm-3">
                                                                        <i
                                                                            class="fa fa-pencil-square-o"
                                                                            style={{
                                                                                paddingTop: "5px",
                                                                                fontSize: "16px",
                                                                                cursor: "pointer"
                                                                            }}
                                                                        ></i>
                                                                    </div>
                                                                    <div
                                                                        class="col-sm-9"
                                                                        style={{ paddingTop: "5px" }}
                                                                    >
                                                                        Edit
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            <a
                                                                class="archive_group_invites"
                                                                data-toggle="tooltip"
                                                                title="Archive"
                                                                onClick={(e) => {
                                                                    archive([val.id]);
                                                                }}
                                                            >
                                                                <div class="row">
                                                                    <div class="col-sm-3">
                                                                        <i
                                                                            class="fa fa-trash"
                                                                            style={{
                                                                                paddingTop: "5px",
                                                                                fontSize: "16px",
                                                                                color: "#FF3030",
                                                                                cursor: "pointer"
                                                                            }}
                                                                        ></i>
                                                                    </div>
                                                                    <div
                                                                        class="col-sm-9"
                                                                        style={{
                                                                            paddingTop: "5px",
                                                                            color: "#FF3030",
                                                                        }}
                                                                    >
                                                                        Archive
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                {pageDetails && pageDetails.length == 0 &&
                                    <tr>
                                        <td colspan={6}>
                                            <NoAssetsFound />
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </TableContainer>
                    <div className="page-limits">
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={limit}
                                onChange={(e) => {
                                    setPage(1);
                                    setPaginationStatus(false);
                                    setLimit(e.target.value);
                                }}
                            >
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={30}>30</MenuItem>
                            </Select>
                        </FormControl>
                        {paginationStatus && (
                            <Pagination
                                count={pageCount}
                                variant="outlined"
                                shape="rounded"
                                onChange={handleChangePage}
                            />
                        )}
                    </div>
                </div>
            )
            }
            {
                paginationStatus === false &&
                <div className="new-loader">
                    <img src={loader} alt="" />
                </div>
            }

            <TodoForm
                show={isShowTodoForm}
                mode={formMode}
                selectedTodoId={selectedTodoId}
                closepopup={handleClose}
            />
        </>
    );
}
