import React from "react"

const CheckoutArrowRightLineIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      {...props}
    >
      <path
        d="M6.16663 12.6666L10.8333 7.99996L6.16663 3.33329"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CheckoutArrowRightLineIcon
