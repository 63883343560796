import React, { useEffect } from "react";

const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    
      
      <div className="main-wrap common-container terms">
        <div className="content">
          <div className="container-fluid">
            <div name="termly-embed" data-id="109946fe-3168-4916-9de6-0b3dd6835dd1" data-type="iframe"></div>

          </div>
        </div>
      </div>        
    
  );
};

(function(d, s, id) {
  var js, tjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) return;
  js = d.createElement(s); js.id = id;
  js.src = "https://app.termly.io/embed-policy.min.js";
  tjs.parentNode.insertBefore(js, tjs);
}(document, 'script', 'termly-jssdk'));

export default Terms;
