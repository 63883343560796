import React from 'react'
import HeroSection from './CommonComponents/HeroSection'
import BookDemoSection from './CommonComponents/BookDemoSection'

export default function ProjectSiteManagement() {
  return (
    <>
      <HeroSection heroText={"Streamlined Project Site Management"} subText={"Efficiently oversee and coordinate your project sites with SafetyLoop's intuitive tools. Enhance control over safety protocols, documentation, and more, all in one place."} />
      <BookDemoSection/>
    </>
  )
}
