import React, { useEffect, useState, useContext } from "react";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { FormGroup, ControlLabel, FormControl as Input } from "react-bootstrap";
import { Button } from "@mui/material";
import Cookies from "js-cookie";
import Upload from "../../../../assets/images/Upload.svg";
import Application from "../../../../config/ApplicationWrapper";
import FormHelperText from "@mui/material/FormHelperText";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import { toast } from "react-toastify";
import { AssetCreateContext } from "./index";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";

export default function CreateAssetInfo() {
  const [party, setParty] = useState([]);
  const [onSubmit, setSubmit] = useState(false);
  const [groupsList, setGroupsList] = React.useState([]);
  const [responsibleParties, setResponsibleParties] = React.useState([]);
  const [imageFile, setImageFile] = useState("");
  const [imageChanged, setImageChanged] = useState(false);
  const [assetTypes, setAssetTypes] = useState([]);

  const [trackedAmount, setTrackedAmount] = useState(0);
  const [trackerType, setTrackerType] = useState('');
  const [unitLabel, setUnitLabel] = useState('Select');
  const [updateValue, setUpdateValue] = useState(false);
  const [disable, setDisable] = React.useState(false);

  let units = ['Hours', 'Mileage', 'Kilometers']

  const cookieDetails = Cookies.get("token");
  const { setTabKey } = useContext(AssetCreateContext);

  const [values, setValues] = useState({
    name: "",
    status: "In Use",
    description: "",
    notes: "",
    company: "",
    group: "",
    model: "",
    serial: "",
    managers: "",
    image: "",
    mileage: "",
    hours: "",
    days: "",
    kilometers: "",
    project: "",
    manufacturer: "",
    maintenanceDate: "",
    maintenanceMilage: "",
    maintenanceHours: "",
    approvedMaintenance: "",
    asset_type_id: "",
    unitValue: "",
  });

  let getAssetId = localStorage.getItem("assetInfo");

  useEffect(() => {
    try {
      async function getPageDetails() {
        const groupDetails = await fetch(
          `${Application.api_url}/api/v2/common/groups`,
          {
            method: "GET",
            headers: { Authorization: cookieDetails },
          }
        );
        const details = await groupDetails.json();
        setGroupsList(details && details.data ? details.data : []);


        const asset_types = await fetch(
          `${Application.api_url}/api/v2/asset_types?archived_true=${false}`,
          {
            method: "GET",
            headers: { Authorization: cookieDetails },
          }
        );
        const assetTypeDetails = await asset_types.json();
        setAssetTypes(assetTypeDetails && assetTypeDetails.data ? assetTypeDetails.data : []);

        const responsiblePartiesDetails = await fetch(

          `${Application.api_url}/api/v2/common/responsible_parties`,
          {
            method: "GET",
            headers: { Authorization: cookieDetails },
          }
        );
        const result = await responsiblePartiesDetails.json();

        setResponsibleParties(result && result.data ? result.data : []);

        if (getAssetId) {
          let assetInfo = JSON.parse(getAssetId);
          const assetInfoObj = await fetch(
            `${Application.api_url}/api/v2/asset_trackings/${assetInfo.asstId}`,
            {
              method: "GET",
              headers: { Authorization: cookieDetails },
            }
          );

          if (assetInfoObj.status == 200) {
            let assetDetails = await assetInfoObj.json();
            assetDetails = assetDetails.data;
            let data = {
              name: assetDetails.name,
              status: assetDetails.status,
              description: assetDetails.description,
              notes: assetDetails.notes,
              company: assetDetails.company_name,
              model: assetDetails.model,
              serial: assetDetails.serial,
              managers: assetDetails.managers,
              image: assetDetails.image_url,
              manufacturer: assetDetails.manufacturer,
              mileage:
                assetDetails.tracker_type &&
                assetDetails.tracker_type == "Mileage"
                  ? assetDetails.tracked_amount
                  : "",
              hours:
                assetDetails.tracker_type &&
                assetDetails.tracker_type == "Hours"
                  ? assetDetails.tracked_amount
                  : "",
              days:
                assetDetails.tracker_type &&
                assetDetails.tracker_type == "Days"
                  ? assetDetails.tracked_amount
                  : "",
              kilometers:
                assetDetails.tracker_type &&
                assetDetails.tracker_type == "Kilometers"
                  ? assetDetails.tracked_amount
                  : "",
              group:
                assetDetails.asset_groups &&
                assetDetails.asset_groups.length > 0
                  ? assetDetails.asset_groups[0].group.id
                  : "",
              maintenanceMilage:
                assetDetails.last_tracker_type &&
                assetDetails.last_tracker_type == "Mileage"
                  ? assetDetails.last_tracked_amount
                  : "",
              maintenanceHours:
                assetDetails.last_tracker_type &&
                assetDetails.last_tracker_type == "Hours"
                  ? assetDetails.last_tracked_amount
                  : "",
              maintenanceDate: assetDetails.last_maintenance_date
                ? assetDetails.last_maintenance_date
                : "",
              asset_type_id: assetDetails.asset_type ? assetDetails.asset_type.id : "",
              unitValue: assetDetails.tracked_amount
            };
            setParty(assetDetails.managers);
            setValues(data);
            assetDetails.tracker_type ? setUnitLabel(assetDetails.tracker_type) : null
          }
        }
      }
      getPageDetails();
    } catch (err) {
      console.log("err===>", err);
    }
  }, []);

  const handleChange = (name, event) => {
    switch (name) {
      case "mileage":
        values.mileage = event.target.value;
        values.hours = "";
        values.days = "";
        values.kilometers = "";

        setValues({ ...values });
        setTrackedAmount(event.target.value);
        setTrackerType('Mileage');
        break;
      case "hours":
        values.mileage = "";
        values.hours = event.target.value;
        values.days = "";
        values.kilometers = "";

        setTrackedAmount(event.target.value);
        setTrackerType('Hours');
        setValues({ ...values });
        break;
      case "days":
        values.mileage = "";
        values.hours = "";
        values.days = event.target.value;
        values.kilometers = "";

        setTrackedAmount(event.target.value);
        setTrackerType('Days');
        setValues({ ...values });
        break;
      case "kilometers":
        values.mileage = "";
        values.hours = "";
        values.days = "";
        values.kilometers = event.target.value;

        setTrackedAmount(event.target.value);
        setTrackerType('Kilometers');
        setValues({ ...values });
        break;
      case "maintenanceMilage":
        values.maintenanceMilage = event.target.value;
        values.maintenanceHours = "";
        setValues({ ...values });
        break;
      case "maintenanceHours":
        values.maintenanceHours = event.target.value;
        values.maintenanceMilage = "";
        setValues({ ...values });
        break;
      default:
        setValues({ ...values, [name]: event.target.value });
    }

    setUpdateValue(true);
  };

  const imageUpload = (value) => {
    setImageFile(value ? URL.createObjectURL(value) : "");
    setValues({ ...values, ["image"]: value });
    setImageChanged(true);
  };

  const saveInfo = async () => {
    try {
      let method;
      let apiUrl;
      setSubmit(true);
      setDisable(true)

      if (
        !values.name.toString() ||
        !values.status ||
        !values.group ||
        !values.model ||
        !values.serial ||
        party.length === 0 ||
        !values.unitValue
      ) {
        setDisable(false)
        return false;
      }

      if (getAssetId) {
        let assetInfo = JSON.parse(getAssetId);
        method = "PUT";
        apiUrl = `${Application.api_url}/api/v2/asset_trackings/${assetInfo.asstId}`;
      } else {
        method = "POST";
        apiUrl = `${Application.api_url}/api/v2/asset_trackings`;
      }

      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("description", values.description);
      formData.append("notes", values.notes);
      formData.append("status", values.status);
      formData.append("model", values.model);
      formData.append("serial", values.serial);
      formData.append("manufacturer", values.manufacturer);
      formData.append("asset_type_id", values.asset_type_id);
      formData.append("tracked_amount", values.unitValue);
      formData.append("tracker_type", unitLabel);
      formData.append("asset_group_ids", values.group);
      // formData.append("last_maintenance_date", values.maintenanceDate);
      // formData.append("last_tracked_amount", values.maintenanceMilage ? values.maintenanceMilage : values.maintenanceHours);
      // formData.append("last_tracker_type", values.maintenanceMilage ? "Mileage" : "Hours");
      formData.append("manager_ids", party.map((obj) => obj.id).join(","));
      if (imageChanged === true) {
        formData.append("image", values.image);
      }

      axios.defaults.headers.common["Authorization"] = cookieDetails;
      const result = await axios({
        method: method,
        url: apiUrl,
        data: formData,
      });

      if (result.status == 200) {
        localStorage.setItem(
          "assetInfo",
          JSON.stringify({ asstId: result.data.data.id, step: "3" })
        );
        if(updateValue === true){
          toast.success("Created Successfully");
          setUpdateValue(false)
        }
        setTabKey("3");
        setDisable(false)
      }
    } catch (err) {
      console.log("err===>", err);
    }
  };

  return (
    <div className="createAssetment">
      <h3>
        <b>Asset information</b>
      </h3>
      <div className="row">
        <div className="col-md-7">
          <div className="row">
            <div className="col-md-12">
              <label>
                Asset Name <text style={{ color: "red" }}>*</text>
              </label>
              <TextField
                required
                fullWidth
                className="text-field"
                id="outlined-required"
                error={!values.name && onSubmit === true ? true : false}
                helperText={
                  !values.name && onSubmit === true
                    ? "Asset Name is required"
                    : ""
                }
                value={values.name}
                onChange={(e) => {
                  handleChange("name", e);
                }}
              />
            </div>
            <div className="col-md-12">
              <FormControl>
                <label>
                  Status <text style={{ color: "red" }}>*</text>
                </label>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="status"
                  fullWidth
                  defaultValue={values.status}
                  onChange={(e) => {
                    handleChange("status", e);
                  }}
                >
                  <FormControlLabel
                    checked={values.status == "In Use" ? true : false}
                    value="In Use"
                    control={<Radio />}
                    label="In Use"
                  />
                  <FormControlLabel
                    checked={values.status == "Repair" ? true : false}
                    value="Repair"
                    control={<Radio />}
                    label="Repair"
                  />
                  <FormControlLabel
                    checked={values.status == "Idle" ? true : false}
                    value="Idle"
                    control={<Radio />}
                    label="Idle"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormControl controlId="formControlsSelectMultiple" fullWidth>
                <ControlLabel>Asset Type</ControlLabel>
                <Select
                  labelId="demo-simple-select-error-label"
                  id="demo-simple-select-error"
                  className="assetCreateInfoGroup"
                  fullWidth
                  input={
                    <OutlinedInput
                      sx={{ fontSize: "14px", borderColor: "red" }}
                    />
                  }
                  value={values.asset_type_id}
                  onChange={(e) => {
                    handleChange("asset_type_id", e);
                  }}
                >
                  {assetTypes.map((val) => (
                    <MenuItem
                      value={val.id}
                      style={{ fontSize: 14 }}
                      key={val.id}
                    >
                      {val.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-md-6">
              <label>
                Model # <text style={{ color: "red" }}>*</text>
              </label>
              <TextField
                required
                fullWidth
                className="text-field"
                id="outlined-required"
                value={values.model}
                error={!values.model && onSubmit === true ? true : false}
                helperText={
                  !values.model && onSubmit === true ? "Model is required" : ""
                }
                onChange={(e) => {
                  handleChange("model", e);
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label>
                Serial # <text style={{ color: "red" }}>*</text>
              </label>
              <TextField
                required
                fullWidth
                className="text-field"
                id="outlined-required"
                value={values.serial}
                error={!values.serial && onSubmit === true ? true : false}
                helperText={
                  !values.serial && onSubmit === true
                    ? "Serial is required"
                    : ""
                }
                onChange={(e) => {
                  handleChange("serial", e);
                }}
              />
            </div>
            <div className="col-md-6">
              <label>Manufacturer</label>
              <TextField
                fullWidth
                className="text-field"
                id="outlined-required"
                value={values.manufacturer}
                onChange={(e) => {
                  handleChange("manufacturer", e);
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <label>
                Current Mileage/Hours or Kilometers
                <text style={{ color: "red" }}> *</text>
              </label>
              <div className="mileage-hours-field">
                <div className="input-group">
                  <div className="input-group-btn">
                    <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                      { unitLabel }
                      <span className="caret"></span>
                    </button>
                    <ul className="dropdown-menu">
                      {
                        units.map((unitName) => {
                          return <li onClick={() => {
                                setUnitLabel(unitName);
                                setUpdateValue(true);
                              }
                            }>
                            <span>{unitName}</span>
                          </li>
                        })
                      }
                    </ul>
                  </div>
                  <input type="number" className="form-control mileage-hours-input" aria-label="..."
                         placeholder={unitLabel !== 'Select' ? unitLabel : 'Choose Unit'} value={values.unitValue}
                         onChange={(e)=> {
                           handleChange('unitValue', e)
                           unitLabel === 'Select' ? setUnitLabel('Mileage') : null
                         }}
                  />
                </div>
                {/*<div className="fields">*/}
                {/*  <label>Mileage</label>*/}
                {/*  <span>*/}
                {/*    <TextField*/}
                {/*      className="text-field"*/}
                {/*      id="outlined-required"*/}
                {/*      value={values.mileage}*/}
                {/*      name="mileage"*/}
                {/*      onChange={(e) => {*/}
                {/*        handleChange("mileage", e);*/}
                {/*      }}*/}
                {/*    />*/}
                {/*  </span>*/}
                {/*</div>*/}
                {/*<div className="or-lable">*/}
                {/*  <span>Or</span>*/}
                {/*</div>*/}
                {/*<div className="fields">*/}
                {/*  <label>Hours</label>*/}
                {/*  <span>*/}
                {/*    <TextField*/}
                {/*      className="text-field"*/}
                {/*      id="outlined-required"*/}
                {/*      value={values.hours}*/}
                {/*      name="hours"*/}
                {/*      onChange={(e) => {*/}
                {/*        handleChange("hours", e);*/}
                {/*      }}*/}
                {/*    />*/}
                {/*  </span>*/}
                {/*</div>*/}
                {/*<div className="or-lable">*/}
                {/*  <span>Or</span>*/}
                {/*</div>*/}
                {/*<div className="fields">*/}
                {/*  <label>Days</label>*/}
                {/*  <span>*/}
                {/*    <TextField*/}
                {/*      className="text-field"*/}
                {/*      id="outlined-required"*/}
                {/*      value={values.days}*/}
                {/*      name="days"*/}
                {/*      onChange={(e) => {*/}
                {/*        handleChange("days", e);*/}
                {/*      }}*/}
                {/*    />*/}
                {/*  </span>*/}
                {/*</div>*/}
                {/*<div className="or-lable">*/}
                {/*  <span>Or</span>*/}
                {/*</div>*/}
                {/*<div className="fields">*/}
                {/*  <label>Kilometers</label>*/}
                {/*  <span>*/}
                {/*    <TextField*/}
                {/*      className="text-field"*/}
                {/*      id="outlined-required"*/}
                {/*      value={values.kilometers}*/}
                {/*      name="kilometers"*/}
                {/*      onChange={(e) => {*/}
                {/*        handleChange("kilometers", e);*/}
                {/*      }}*/}
                {/*    />*/}
                {/*  </span>*/}
                {/*</div>*/}
              </div>
              <FormHelperText
                className="inputErrorMsg"
                error={
                  !values.unitValue && onSubmit === true
                    ? true
                    : false
                }
              >
                {
                  !values.unitValue && onSubmit === true
                      ? unitLabel === 'Select' ?  'Unit is required' : `${unitLabel} is required`
                      : ""
                }
              </FormHelperText>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <label>Company</label>
              <TextField
                fullWidth
                disabled={true}
                className="text-field"
                id="outlined-required"
                value={localStorage.getItem("token")}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <FormControl
                controlId="formControlsSelectMultiple"
                required
                fullWidth
              >
                <ControlLabel>
                  Group <text style={{ color: "red" }}>*</text>
                </ControlLabel>
                <Select
                  labelId="demo-simple-select-error-label"
                  id="demo-simple-select-error"
                  className="assetCreateInfoGroup"
                  fullWidth
                  input={
                    <OutlinedInput
                      sx={{ fontSize: "14px", borderColor: "red" }}
                    />
                  }
                  value={values.group}
                  onChange={(e) => {
                    handleChange("group", e);
                  }}
                >
                  {groupsList.map((val) => (
                    <MenuItem
                      value={val.id}
                      style={{ fontSize: 14 }}
                      key={val.id}
                    >
                      {val.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormHelperText
                className="inputErrorMsg"
                error={!values.group && onSubmit === true ? true : false}
              >
                {!values.group && onSubmit === true ? "Group is required" : ""}
              </FormHelperText>
            </div>
            <div className="col-md-12">
              <FormGroup controlId="formControlsSelectMultiple">
                <ControlLabel>Responsible Party <text style={{ color: "red" }}>*</text> </ControlLabel>
                <Autocomplete
                  multiple={true}
                  disablePortal={true}
                  limitTags={2}
                  id="multiple-limit-tags"
                  options={responsibleParties}
                  disableCloseOnSelect
                  getOptionLabel={(options) =>
                    `${options.last_name},${options.first_name} (${options.employee_id})`
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={option.id} style={{ fontSize: 14 }}>
                      {option.last_name},{option.first_name} ({option.employee_id})
                    </li>
                  )}
                  value={party}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, value) => {
                    setParty(value)
                    setUpdateValue(true);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="" placeholder=""
                    error={party.length == 0 && onSubmit}
                    helperText={
                      party.length == 0 && onSubmit
                        ? "Responsible Party is required"
                        : ""
                    }/>
                  )}
                  sx={{ width: "100%" }}
                />
              </FormGroup>
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <div className="row">
            <div className="col-md-12">
              <label>Description</label>
              <textarea
                className="create-asset-textarea"
                aria-label="empty textarea"
                value={values.description}
                onChange={(e) => {
                  handleChange("description", e);
                }}
              />
            </div>
            <div className="col-md-12">
              <label>Notes</label>
              <textarea
                className="create-asset-textarea"
                aria-label="empty textarea"
                value={values.notes}
                onChange={(e) => {
                  handleChange("notes", e);
                }}
              />
            </div>
            <div className="col-md-12">
              <label>Upload Image</label>
              <div className="create-asset-upload">
                <div>
                  <Button className="uploadButton" component="label">
                    <img src={Upload} alt="" /> Upload Image
                    <input
                      style={{ display: "none" }}
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={(e) => {
                        imageUpload(e.target.files[0]);
                        setUpdateValue(true);
                      }}
                    />
                  </Button>
                </div>
              </div>
              {values.image && (
                <div className="uploadedImage">
                  <img
                    src={imageFile ? imageFile : values.image}
                    alt=""
                    className="assetimage"
                  />
                  <i
                    className="fa fa-trash delete-icon"
                    onClick={(e) => {
                      imageUpload("");
                      setUpdateValue(true);
                    }}
                  ></i>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <hr />
      <div className="row">
        <div className="col-md-12">
          <h4>
            <b>Last Maintenance Information</b>
          </h4>
          <div className="col-md-4">
            <label>Last Date of Maintenance</label>
            <input
              type="date"
              className="date-field"
              placeholder="Search"
              aria-label="Username"
              aria-describedby="basic-addon1"
              name="maintenanceDate"
              value={values.maintenanceDate}
              onChange={(e) => {
                handleChange("maintenanceDate", e);
              }}
            />
          </div>
          <div className="col-md-4">
            <label>Last Maintenance Mileage or Hours</label>
            <div className="mileage-hours-field">
              <div className="fields">
                <label>Mileage</label>
                <span>
                  <TextField
                    required
                    className="text-field"
                    id="outlined-required"
                    name="maintenanceMilage"
                    value={values.maintenanceMilage}
                    onChange={(e) => {
                      handleChange("maintenanceMilage", e);
                    }}
                  />
                </span>
              </div>
              <div className="or-lable">
                <span>Or</span>
              </div>
              <div className="fields">
                <label>Hours</label>
                <span>
                  <TextField
                    required
                    className="text-field"
                    id="outlined-required"
                    name="hours"
                    value={values.maintenanceHours}
                    onChange={(e) => {
                      handleChange("maintenanceHours", e);
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="submit-button">
        <button
          className="btn btn-primary asset-creat-next-btn"
          disabled={disable}
          onClick={(event) => {
            saveInfo();
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
}
