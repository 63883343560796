import React from 'react';
import HeroSection from './CommonComponents/HeroSection';
import FeaturesSection from './CommonComponents/FeaturesSection';
import ParallaxSection from '../../pages/HomePage/Components/ParallaxSection';
import HomepageDemoSection from '../../pages/HomePage/Components/HomepageDemoSection';

export default function Reporting() {
    return (
        <>
            <HeroSection
                heroText={"Reporting"}
                subText={"Generate and view custom reports to gain insights and track performance."}
            />
            <FeaturesSection
                headerText={"Comprehensive Reporting"}
                subText={"Generate detailed reports for safety, compliance, and performance management."}
                featureHighlights={[
                    "Create custom reports for various use cases.",
                    "Monitor safety performance and compliance.",
                    "Generate real-time reports for stakeholders.",
                    "Easily share and export report data."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for Comprehensive Reporting section here
            />
            <FeaturesSection
                headerText={"Automated Reporting"}
                subText={"Set up automated reporting schedules to keep stakeholders informed."}
                featureHighlights={[
                    "Set up automated reports for regular updates.",
                    "Monitor report delivery and completion.",
                    "Ensure compliance with scheduled reports."
                ]}
                // Placeholder: Add image for Automated Reporting section here
            />
            <FeaturesSection
                headerText={"Reporting Analytics"}
                subText={"Analyze report data and gain insights for better decision-making."}
                featureHighlights={[
                    "Track trends and performance over time.",
                    "Generate data-driven insights for safety management.",
                    "Identify areas for improvement with analytics."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for Reporting Analytics section here
            />
            <div className="Home-Page sl-home-wrapper" style={{ paddingTop: 0 }}>
                <ParallaxSection />
            </div>
            <div className="features-homepage-wrapper">
                <HomepageDemoSection mergeTop={false} />
            </div>
        </>
    );
}
