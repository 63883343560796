import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import { AlertBoxContainer } from "./alretDialog";

export default function CompanyArchiveAlert({ data = {}, title = '', alertOpen, handleClose, handleOk, okText = 'Archive', loading=false, icon, okButtonStyle = {} }) {
    const [associateItem, setAssociateItem] = React.useState({ form: false, action: false });
    const onChangeItem = (e) => {
        const { name, checked } = e.target;
        setAssociateItem({...associateItem, [name]: checked})
    }

    const onSubmit = () => {
        handleOk(associateItem);
    }
  return (
    <React.Fragment>
      <Dialog
        open={alertOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AlertBoxContainer>
          <DialogContent
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {icon || <svg
              xmlns="http://www.w3.org/2000/svg"
              width="60"
              height="60"
              viewBox="0 0 60 60"
              fill="none"
            >
              <path
                d="M30 52.5C42.4264 52.5 52.5 42.4264 52.5 30C52.5 17.5736 42.4264 7.5 30 7.5C17.5736 7.5 7.5 17.5736 7.5 30C7.5 42.4264 17.5736 52.5 30 52.5Z"
                stroke="#D92929"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M37.5 22.5176L22.5 37.4827M22.5 22.5176L37.5 37.4827L22.5 22.5176Z"
                stroke="#D92929"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>}
            <Typography
              style={{
                fontSize: "24px",
                lineHeight: "32px",
                fontWeight: 700,
                color: "#080808",
              }}
              variant="h4"
            >
              {title}
            </Typography>
            </DialogContent>
            <DialogContentText
              style={{
                fontSize: "14px",
                lineHeight: "25px",
                fontWeight: 400,
                color: "#5c5c5c",
              }}
              id="alert-dialog-description"
              dangerouslySetInnerHTML={{
                __html: `Are you sure you want to archive this <b>“${data.name}”</b>?
                Groups associated with the following items. If you'd like to archive the associated
                items, please select the checkboxes.`,
              }}
            />
            <p>Associated item</p>
            <div className="row-item">
            <Checkbox style={{ color: '#407797' }} name={'form'} checked={associateItem.form} onChange={(e) => onChangeItem(e)} />
            <svg style={{ paddingRight: 5}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M14 3V7C14 7.26522 14.1054 7.51957 14.2929 7.70711C14.4804 7.89464 14.7348 8 15 8H19" stroke="#080808" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H14L19 8V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21Z" stroke="#080808" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9 9H10" stroke="#080808" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9 13H15" stroke="#080808" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9 17H15" stroke="#080808" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            <span style={{ paddingRight: 5}} >All Started Forms: {data.audits_count}</span>
            </div>
            <div className="row-item">
            <Checkbox style={{ color: '#407797' }} name={'action'} checked={associateItem.action} onChange={(e) => onChangeItem(e)} />
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_10444_307814)">
                <path d="M9 5H7C6.46957 5 5.96086 5.21071 5.58579 5.58579C5.21071 5.96086 5 6.46957 5 7V19C5 19.5304 5.21071 20.0391 5.58579 20.4142C5.96086 20.7893 6.46957 21 7 21H17C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19V7C19 6.46957 18.7893 5.96086 18.4142 5.58579C18.0391 5.21071 17.5304 5 17 5H15" stroke="#080808" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13 3H11C9.89543 3 9 3.89543 9 5C9 6.10457 9.89543 7 11 7H13C14.1046 7 15 6.10457 15 5C15 3.89543 14.1046 3 13 3Z" stroke="#080808" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7.87793 11.6143L9.30581 11.6143" stroke="#080808" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7.87793 16.4961L9.30581 16.4961" stroke="#080808" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 11.1693L13 12.1693L15.3954 9.77393" stroke="#080808" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 16.0511L13 17.0511L15.3954 14.6558" stroke="#080808" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_10444_307814">
                <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
            </svg>
            <span style={{ paddingRight: 5}} >Actions: {data.actions_count}</span>
            </div>

            <div className="information-box">
                <p className="text">Information: if this group is restored. associated item will return to their original place</p>
            </div>
          <DialogActions
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "end",
              justifyContent: "end",
            }}
          >
            <Button disabled={loading} className="cmn-btn cancel" onClick={handleClose}>
              Cancel
            </Button>
            <Button disabled={loading} endIcon={loading ? <CircularProgress style={{color: '#fff'}} size={16} color="inherit" /> : null} className="cmn-btn archive-btn" onClick={() => onSubmit()} autoFocus style={{...okButtonStyle}}>
              {okText}
            </Button>
          </DialogActions>
        </AlertBoxContainer>
      </Dialog>
    </React.Fragment>
  );
}
