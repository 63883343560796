import React, {useEffect, useState, createContext} from "react";
import ActiveRiskType from "../RiskTypes/ActiveRiskType";
import { Modal,Button } from "react-bootstrap";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Cookies from "js-cookie";
import Application from "../../../../javascript/config/ApplicationWrapper";
export const RiskTypeContext = createContext();


import ArchivedRiskType from "./ArchiveRiskType";
import {toast} from "react-toastify";

// /api/v2/risk_assesments/risk_types
export default function RiskType(){
    const [statusModal, setStatusModal] = useState(false);
    const [riskType, setRiskType] = useState('')
    const cookieDetails = Cookies.get("token");
    const [dataChange, setDataChange] = useState();
    const [errorMsg, setErrorMsg] = useState();
    const [refreshActiveType, setRefreshActiveType] = useState();
    const [refreshArchivedType, setRefreshArchivedType] = useState();
    const [activeCount, setActiveCount] = useState(0);
    const [archivedCount, setArchivedCount] = useState(0);

    const onhandleRiskTypeCreate = async () => {
        try {
            fetch(`${Application.api_url}/api/v2/risk_assesments/risk_types`, {
                method: "POST",
                headers: { Authorization: cookieDetails, 'Content-Type': 'application/json'},
                body : JSON.stringify({
                    risk_type: { name: riskType }
                })
            }).then(async response => {
                let resultObj = await response.json()
                if(resultObj.success){
                    setRiskType('')
                    setStatusModal(false);
                    setDataChange(resultObj);
                    toast.success('Created successfully');
                }
                else {
                    setErrorMsg(resultObj.error)
                }
            })
        } catch (err) {
            console.log("error===>", err);
        }
    };


    const changeRiskType = (event) => {
        setRiskType(event.target.value);
    }

    const onhandleStatusClose = () => setStatusModal(false);

    return (
        <div className="riskType">
            <div class="card riskTypeTab">
                <div class="card-body">
                    <div className="d-flex align-item-center justify-content-between header-badge">
                        <div className="d-flex align-item-center">
                            <h3>Risk Types</h3>
                        </div>
                        <div className="btn-style">
                            <button
                                type="button"
                                class="btn btn-primary"
                                onClick={()=>{
                                    setErrorMsg('');
                                    setStatusModal(true)
                                }}
                            >
                                <i class="fa fa-plus" aria-hidden="true"></i>
                                Risk Type
                            </button>
                        </div>
                    </div>

                    <ul className="nav nav-tabs">
                        <li className="active">
                            <a data-toggle="tab" href="#active-risk-type" onClick={(e) => setRefreshActiveType(Math.random())}>
                                Active Risk Type
                                <span className="badge badge-light">{activeCount}</span>
                            </a>
                        </li>
                        <li>
                            <a data-toggle="tab" href="#archived-risk-type" onClick={(e) => setRefreshArchivedType(Math.random())}>
                                Archived Risk Type
                                <span className="badge badge-light">{archivedCount}</span>
                            </a>
                        </li>
                    </ul>

                        <div className="tab-content">
                            <RiskTypeContext.Provider value={{activeCount, setActiveCount, archivedCount, setArchivedCount}} >
                                <div id="active-risk-type" className="tab-pane fade in active active-risk-assessment">
                                    <ActiveRiskType rtCreate={dataChange} refresh={refreshActiveType} />
                                </div>
                                <div id="archived-risk-type" className="tab-pane fade active-risk-assessment">
                                    <ArchivedRiskType refresh={refreshArchivedType}/>
                                </div>
                            </RiskTypeContext.Provider>
                        </div>
                </div>
            </div>
            <Modal
                show={statusModal}
                onHide={onhandleStatusClose}
                centered={true}
                className="status-modal risk-type"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="Rat-status-popup">
                        <b><i class="fa fa-plus" aria-hidden="true"></i> Risk Type</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '25ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    ><label>Risk Type Name<text style={{color: 'red'}}>*</text></label>
                        <TextField id="outlined-basic"  variant="outlined" style={{width:"100%"}}
                                   inputProps={{style: {fontSize: 15, padding: "9.5px 9px" }}}
                                   onChange={(event) => {changeRiskType(event)}}
                                   FormHelperTextProps={{style: {fontSize: 14}}}
                                   required
                                   error={errorMsg}
                                   helperText={errorMsg}
                        />
                    </Box>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-secondary" onClick={() => {onhandleStatusClose()}}>
                        Close
                    </Button>
                    <Button className="btn-primary" onClick={
                        () => {
                            if(riskType) {
                                onhandleRiskTypeCreate()
                            }
                            else {
                                setErrorMsg('Name is a required field');
                            }
                        }}>
                        Create
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}