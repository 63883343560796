import React from 'react';
import useUpdate from '../../../shared/components/useUpdate';
import NewInvite from './NewInvite';

const NewInviteRequest = ({open, setOpen, setFilterState}) => {
    const {mutate, isLoading: isMutating, error} = useUpdate(`/api/v2/entity_management/group_join_invites`);
    return (
        <NewInvite open={open} mutate={mutate} setOpen={setOpen} setFilterState={setFilterState} isMutating={isMutating} />
    )
}

export default NewInviteRequest;
