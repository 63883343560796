import React from 'react'
import { Button } from 'react-bootstrap'

export default function HomepageDemoSection({ mergeTop = true }) {
    return (
        <div className="Product-Page">
            <div className="personal-demo-section" >
                <div className="home-container" style={{
                    maxWidth: "1050px",
                    margin: `${mergeTop ? "-300px" : "230px"} auto 230px`
                }}>
                    <div className="d-flex personal-demo-content feature-page-demo-section" style={{
                        background: "linear-gradient(90deg, #11b2f9, #63c7f9)",
                        padding: "100px 50px",
                        borderRadius: "20px"
                    }}>
                        <div>
                            <h3 style={{
                                color: "white",
                                textTransform: "none",
                                marginBottom: "40px",
                                fontSize: "46px",
                                lineHeight: "1.2em",
                            }}>
                                <b>Experience SafetyLoop in Action</b>
                            </h3>
                            <a href="/contact" style={{
                                display: "inline-block",
                                marginBottom: "50px"
                            }}>
                                <Button className="home-demo-btn">Request Demo</Button>
                            </a>
                            <p style={{
                                color: "white"
                            }}>
                                Request a demo today and discover the power of streamlined safety.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
