import React,{ useEffect }  from "react";
import { Modal, Button } from "react-bootstrap";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Application from "../../../../config/ApplicationWrapper";

export default function Status(props) {
  const { status, token, closepopup, ratData } = props;
  const [sharedgroup, setsharedgroup] = React.useState("");
  const [groups, setGroups] = React.useState([]);

  useEffect(()=>{
    async function getGroupDetails() {
      try {
        const groupDetails = await fetch(
          `${Application.api_url}/api/groups`,
          {
            method: "GET",
            headers: { Authorization: token },
          }
        );
        const details = await groupDetails.json();
        setGroups(
          details && details ? details : []
        );
      } catch (err) {
        console.log("error===>", err);
      }
    }

    getGroupDetails();
  },[])

  const onhandleShareClose = () => {
    closepopup();
  };

  const saveGroups = async() => {
    try {
      var newArray = sharedgroup.map( function( el ){ 
        return el.id; 
       });


      let result = await fetch(
        `${Application.api_url}/api/v2/rats/${ratData.id}/share_with_group`,
        {
          method: "POST",
          headers: { 
            "content-type": "application/json",
            Authorization: token 
          },
          body: JSON.stringify({
            group_ids: newArray.toString()
          }),
        }
      );

      result = await result.json();
      if(result.success === true){
        closepopup(result.rat.updated_at);
      }

    } catch (err) {
      console.log("error===>", err);
    }

  }

  return (
    <Modal
        show={status}
        centered
        className="share-modal"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Share Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Autocomplete
            multiple={true}
            limitTags={2}
            id="multiple-limit-tags"
            options={groups}
            getOptionLabel={(options) => options.name}
            renderOption={(props, option) => <li {...props} key={option.id}>{option.name}</li>}
            defaultValue={ratData.groups}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, value) => setsharedgroup(value)}
            renderInput={(params) => (
                <TextField {...params} label="Groups" placeholder="Favorites" />
            )}
            sx={{ width: '500px' }}
        />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-secondary" onClick={(e)=>{ onhandleShareClose()}}>
            Close
          </Button>
          <Button className="btn-primary" onClick={(e)=>{ saveGroups()}}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
  );
}
