import React from 'react';
import Select from 'react-select';
import styles from './Rot.css'
import { BrowserRouter as Router, Route, Link, withRouter,Redirect, NavLink} from "react-router-dom";
import Login, {User} from "../../pages/HomePage/Login";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
var axios = require('axios');

const initialState = {
    summary: null,
    client: [],
    result:[],
    audit_types:[],
    owners:[],
    projects: [],
    emp_ids: [],
    fields: { employee:'', topic:'', training_status: '', search: true, bucket: '' }
};

class ROTOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState
        this.handleChange = this.handleChange.bind(this);
        this.resetfilter = this.resetfilter.bind(this);
    }

    handleChange(field, e){
        let fields = this.state.fields;
        fields[field] = e == null ? '' : e.value;
        this.setState({fields});
        if (e != null){
            this.props.onFilterSelect(field, e);
        }
    }

    resetfilter(){
        var that = this;
        let fields = this.state.fields;
        $.each(fields, function (i, value) {fields[i] = ""});
        this.setState({fields});
        this.props.onFilterSelect("", this);
   }

   renderFiltersData(results,value="") {
        if (results != undefined){
            let optionItems =  results.map((value, i) => {
            return (
                <option key={i} value={value}>{value}</option>
                );
        })

        if (!value){
            optionItems.unshift(<option key="" value="">Select</option>);
        }
        return optionItems;
    }
    }

    renderCompanyFilter(filters){
        if ( User.role == "SuperAdmin"){
            return(
                <div className="col-sm-12">
                    <label className="control-label">Company</label>
                    <Select
                        name="form-field-name"
                        value={this.state.fields["company"]}
                        onChange={this.handleChange.bind(this,"company")}
                        options={filters ? filters.company : filters}
                        id="company"
                        clearable={false}
                    />
                </div>
            )
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.filter_applied == true){
            return false;
        }else{
            return true;
        }
    }

    render() {
        
        const { filters, is_admin, summary } = this.props;
        let bucket_info = summary.buckets_info;

        return (
            <div className="rot_admin" style={{"backgroundColor":"#fff"}}>
                {/* <div className="col-sm-12">
                            <label className="control-label">Full Name</label>
                            <Select
                                name="form-field-name"
                                value={this.state.fields["employee"]}
                                onChange={this.handleChange.bind(this,"employee")}
                                options={filters.employees}
                                id="employee"
                                clearable={false}
                            />
                        </div> */}

                        <div className="row">
                            <div className="col-sm-5">
                                <label className="control-label">Training Topic</label>
                                <Select
                                name="form-field-name"
                                value={this.state.fields["topic"]}
                                onChange={this.handleChange.bind(this,"topic")}
                                options={filters.topics}
                                id="topic"
                                clearable={false}
                                />
                            </div>

                            <div className="col-sm-5">
                                <label className="control-label">Training Status</label>
                                <Select
                                name="form-field-name"
                                value={this.state.fields["training_status"]}
                                onChange={this.handleChange.bind(this,"training_status")}
                                options={filters.training_status}
                                id="training_status"
                                clearable={false}
                                />
                            </div>

                            <div className="col-sm-2 filter_button" style={{marginTop:"17px"}}>
                                <button type="button" className="btn btn-primary" onClick={this.resetfilter.bind()}>Reset Filter</button>
                            </div>
                        </div>

                <div className="row">
                    <div className="rot-widgets container-fluid">
                        <div className="col-xs-6 col-md-3 col-lg-3 no-padding">
                            <div className="panel-teal panel-widget border-right">
                                <div className="no-padding">
                                    <div className="total-employees">Total Employees</div>
                                    <div className="total-employees-count">{summary.total_employees}</div>
                                    {/* <div className="large">{summary.projects}</div>
                                    <div className="text-muted">Current Projects</div> */}
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-6 col-md-2 col-lg-2 no-padding expiring-hover">
                            <div className="panel-blue panel-widget border-right">
                                <div className="no-padding">
                                    <div className="rot-training-count">Expired Trainings</div>
                                    <div className="expiring-training rot-expiring-red" onClick={this.handleChange.bind(this,"bucket", "expired")}>{bucket_info.expired.total_count}</div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-6 col-md-2 col-lg-2 no-padding expiring-hover">
                            <div className="panel-orange panel-widget border-right">
                                <div className="no-padding">
                                    <div className="rot-training-count">Expiring in 30 days</div>
                                    <div className="expiring-training rot-expiring-red" onClick={this.handleChange.bind(this,"bucket", "30")}>{bucket_info['30'].total_count}</div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-6 col-md-2 col-lg-2 no-padding expiring-hover">
                            <div className="panel-red panel-widget ">
                                <div className="no-padding">
                                    <div className="rot-training-count">Expiring in 60 days</div>
                                    <div className="expiring-training expiring-warning" onClick={this.handleChange.bind(this,"bucket", "60")}>{bucket_info['60'].total_count}</div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-6 col-md-2 col-lg-2 no-padding expiring-hover">
                            <div className="panel-red panel-widget ">
                                <div className="no-padding">
                                    <div className="rot-training-count">Expiring in 90 days</div>
                                    <div className="expiring-training expiring-success" onClick={this.handleChange.bind(this,"bucket", "90")}>{bucket_info['90'].total_count}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            );
    }
}

export default ROTOverview;
