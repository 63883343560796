import React from 'react';
import NewUser from './newUser';
import useUpdate from '../../../shared/components/useUpdate';

const NewUserCreate = ({open, setOpen, setFilterState, refetch}) => {
    const {mutate, isLoading: isMutating, error} = useUpdate('/api/v2/entity_management/users');
    return (
        <NewUser open={open} mutate={mutate} setOpen={setOpen} setFilterState={setFilterState} refetch={refetch} isMutating={isMutating} />
    )
}

export default NewUserCreate;
