import { IconButton, InputAdornment, TextField } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { UserGroupIcons } from './userGroupIcons';
import SearchIcon from '../../../assets/fonts/Search.svg';

const InputSearchFilter = ({filterData, name, handleOnFilterChange, filterState = {}}) => {
    const {activeFilters = {}} = filterState;
    const inputRef = useRef();
    useEffect(() => {
      if (!activeFilters[name]) {
        inputRef.current.value = '';
      }
    }, [filterState])
    return <TextField
    size="small"
    inputRef={inputRef}
    className="search-input"
    placeholder="Search"
    value={filterData[name]}
    onKeyDown={(event) => {
      if (event.key === 'Enter') {
        handleOnFilterChange(event.target.value || "", name)
      }
    }}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          {!activeFilters[name] ? <IconButton onClick={() => {
            const value = inputRef.current.value || '';
            handleOnFilterChange(value, name)
          }} edge="end">
            <img src={SearchIcon} />
          </IconButton> : <IconButton onClick={() => {
            handleOnFilterChange('', name);
            inputRef.current.value = '';
          }} edge="end">
            {UserGroupIcons.clearInput}
          </IconButton>}
        </InputAdornment>
      ),
    }}
  />
}

export default InputSearchFilter;
