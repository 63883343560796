import React, { Fragment } from 'react'
import { PanelGroup, Panel, Table } from 'react-bootstrap';
import SharedForms from '../../components/SubContractor/SharedForms';
import SharedFiles from '../../components/SubContractor/SharedFiles';

const ProjectInfo = ({project, client, project_shared_info, index, onProjectSelect}) => {
    return (
        <Panel eventKey={index}>
            <Panel.Heading>
                <Panel.Title toggle onClick={() => onProjectSelect({group_id: project.id , company: client.id})}>
                    {project.name}
                </Panel.Title>
            </Panel.Heading>
            <Panel.Collapse>
                <Panel.Body>
                    <SharedForms forms={project_shared_info.shared_forms}/>
                    <SharedFiles files={project_shared_info.files}/>
                </Panel.Body>
            </Panel.Collapse>
        </Panel>
    )
}

export default ProjectInfo;
