import React, { useEffect, useState } from "react";
import { Modal, FormControl as Input } from "react-bootstrap";
import { toast } from "react-toastify";
import Application from "../../../../config/ApplicationWrapper";
import axios from "../../../../config/axiosConfig";
import File from "../../../../assets/fonts/File-cover.svg";

export default function BulkImport(props) {
  const { show, close } = props;
  const [disabled, setDisable] = useState(false);
  const [imageFile, setImageFile] = useState();
  const [details, setDetails] = useState();

  const Download = async () => {
    try {
      axios
        .get(`${Application.api_url}/inventory_sample.csv`, {
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "sample.csv"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log("err==>", error);
        });
    } catch (err) {
      console.log("error==>", err);
    }
  };

  const upload = async () => {
    try {
      setDisable(true);
      if (!imageFile) {
        toast.error("Please select the file");
        return false;
      }

      if (imageFile.type != "text/csv") {
        toast.error("Invalid file");
        return false;
      }
      const formData = new FormData();
      formData.append("file", imageFile);
      axios
        .post(`${Application.api_url}/api/v2/inventories/import`, formData)
        .then((result) => {
          setImageFile("");
          setDisable(false);
          close();
          if (result.data && result.data.success === true) {
            setDetails();
            toast.success("Uploaded Successfully");
            // axios
            //     .get(
            //         `${Application.api_url}/api/v2/inventories/imported_report?id=${result.data.data.inventory_log_id}`,
            //         {
            //           responseType: "blob",
            //         }
            //     )
            //     .then((response) => {
            //       const url = window.URL.createObjectURL(
            //           new Blob([response.data])
            //       );
            //       const link = document.createElement("a");
            //       link.href = url;
            //       link.setAttribute("download", "imported_report.csv"); //or any other extension
            //       document.body.appendChild(link)
            //       ;
            //       link.click();
            //     })
            //     .catch((error) => {
            //       console.log("err==>", error);
            //     });
          }
        })
        .catch((error) => {
          error.response.data.message.map((msg) => {
            toast.error(msg);
          });
        });
    } catch (err) {
      console.log("error==>", err);
    }
  };

  const imageUpload = (value) => {
    setImageFile(value);
    setDisable(false);
  };

  return (
    <Modal
      bsSize="large"
      show={show}
      onHide={(e) => {
        close();
      }}
      className="new-model-popup inventory-order-reject"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Bulk Import
          <button className="sample-file" onClick={(e) => Download()}>
            Sample File
          </button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="new-form">
          <div className="row">
            <div className="col-md-12">
              <div className="reason">
                <label className="label-name">Upload File</label>
                <div className="upload-file">
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      imageUpload(e.target.files[0]);
                    }}
                  />
                  <button
                    onClick={() => document.getElementById("fileInput").click()}
                  >
                    Upload File
                  </button>
                </div>

                {imageFile && (
                  <div className="attachment-grid mt-4">
                    <div className="file-item">
                      <img
                        src={File}
                        className="file-type"
                        alt="file"
                        style={{ width: "80px", height: "auto" }}
                      />
                      <button
                        className="delete-button"
                        onClick={() => setImageFile("")}
                        style={{
                          position: "absolute",
                          top: "-5px",
                          right: "60px",
                          transform: "translate(-145%, 5%)",
                          backgroundColor: "transparent",
                          border: "none",
                          cursor: "pointer",
                          padding: "0",
                        }}
                      >
                        <img
                          src="/packs/media/assets/fonts/Delete-62b3f74c182772359479dc3681fbd490.svg"
                          alt="Delete"
                          style={{
                            width: "24px",
                            height: "24px",
                          }}
                        />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn button cancel-btn"
          onClick={() => {
            close();
          }}
        >
          Cancel
        </button>
        <button
          className="btn btn-primary"
          disabled={disabled}
          onClick={(event) => {
            upload();
          }}
        >
          Upload
        </button>
      </Modal.Footer>
    </Modal>
  );
}
