import React, {useState } from "react";
import { Modal } from "react-bootstrap";
import { Button } from '@mui/material';
import Vector from "../../../assets/fonts/Vector.svg";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import SearchIcon from "../../../assets/fonts/Search.svg";
import _ from "lodash";

export default function NotifyUsersModel(props) {
    const { show, closepopup, details, rightSide } = props;
    const [checked, setChecked] = useState([]);
    const [right, setRight] = useState([]);
    const [data, setData] = useState([]);
    const [search, setSearch] = useState();

    useDidMountEffect(()=>{
        setRight(rightSide);
        let ids = _.map(rightSide, "id")
        setData(not(details, ids));
    }, [show])

    const onhandleStatusClose = () => {
        closepopup("", "users");
    };

    function not(a, b) {
        return a.filter((value) => b.indexOf(value.id) === -1);
    }

    function intersection(a, b) {
        return a.filter((value) => b.indexOf(value.id) !== -1);
    }

    const handleToggleAll = (value, isChecked) => {
        if (isChecked) {
            let newValues = _.map(value, "id");
            setChecked([...newValues]);
        } else {
            setChecked([]);
        }
    }

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    }

    const handleCheckedRight = () => {
        let newchecked = intersection(data, checked);
        setRight([...right, ...newchecked]);
        setData(not(data, checked));
        setChecked([]);
    }

    const handleRemoveRight = (value) => {
        setData([...data, ...intersection(right, [value])]);
        setRight(not(right, [value]));
    }

    const updateDetails = () => {
        closepopup(right, "users");
        setRight([]);
    }

    return (
        <Modal
            bsSize="lg"
            show={show}
            onHide={(e) => {
                onhandleStatusClose();
            }}
            className="qr-modal-wrap add-task-modal pf-model Activate"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h2>Add & Modify Users</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex">
                    <div className="transfer-list">
                        <div className="unselected-users">
                            <h4>Unselected Users</h4>
                            <span class="badge badge-light">{Number(data.length)}</span>
                            <input
                                type="text"
                                class="form-control assessment-name"
                                placeholder="Search"
                                aria-label="Username"
                                id="asset-name2"
                                aria-describedby="basic-addon1"
                                name="name"
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                }}
                                value={search}
                            />
                            <img className="search-icon" src={SearchIcon} alt="" />
                        </div>
                        <div className="users-list">
                            <ul>
                                {data.length != 0 &&
                                    <div className="select-all">
                                        <input type="checkbox" id="" value="1"
                                            checked={data.length === checked.length && checked.length !== 0}
                                            onClick={(e) => { handleToggleAll(data, e.target.checked) }}
                                        />
                                        Select All
                                    </div>
                                }
                                <div className="users-data">
                                    {
                                        data.filter((item) => new RegExp(search, 'i').test(item.full_name))
                                        .map((val, i) => (
                                            <div className="users d-flex">
                                                <input type="checkbox" id="" value={val.id} onClick={handleToggle(val.id)} checked={checked.indexOf(val.id) !== -1} />
                                                {val.full_name} <p className="user-employee-id"> - {val.employee_id}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                            </ul>
                        </div>
                    </div>
                    <div className="selected-btn">
                        <img src={Vector} alt="arrow" />
                        <Button onClick={(e) => { handleCheckedRight() }}>
                            Add to Selected
                        </Button>
                    </div>
                    <div className="transfer-list">
                        <div className="selected-users">
                            <h4>Selected Users</h4>
                            <span class="badge badge-light">{Number(right.length)}</span>
                        </div>
                        <div className="users-list">
                            <ul>
                                <div className="users-data">
                                    {
                                        right.map((val, i) => (
                                            <div className="users right-tab d-flex">
                                                {val.full_name} <p className="user-employee-id"> - {val.employee_id}</p>
                                                <label onClick={(e) => { handleRemoveRight(val.id) }}>X</label>
                                            </div>
                                        ))
                                    }
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="submit-btns">
                    <Button className="btn-cancel" onClick={(e) => { onhandleStatusClose() }}>Cancel</Button>
                    <Button variant="contained" onClick={(e) => { updateDetails() }}>
                        Update
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}
