import React from 'react';
import { BrowserRouter as Router, Route, Link, withRouter,Redirect, NavLink} from "react-router-dom";
import Login, {User} from "../../pages/HomePage/Login";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BarChartData from '../../config/bar_chart_data.json';
import TrainingBarChart from '../../config/training_bar_chart_data.json';

import PageSubNavBar from '../../pages/shared/PageSubNavBar';
class COROverview extends React.Component {

    constructor(props) {
    super(props);
}


    render() {
    const { summary } = this.props;
    return (

        <div className="rot_admin" style={{"backgroundColor":"#fff"}}>
            <div className="row">
                <div className="rot-widgets container-fluid text-center">

                    <div className="col-xs-6 col-md-6 col-lg-6 no-padding">
                        <div className="panel-blue panel-widget border-right">
                            <div className="no-padding">
                                <div className="total-score">Total Score</div>
                                <div className="expiring-training rot-expiring-red total-score-count">Pending</div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xs-6 col-md-6 col-lg-6 no-padding">
                        <div className="panel-orange panel-widget border-right">
                            <div className="no-padding">
                                <div className="total-score">Elements Not Started</div>
                                <div className="expiring-training rot-expiring-red total-score-count">{summary.total_element}</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        );
}
}

export default COROverview;
