import React, { useEffect, useMemo, useState } from "react";
import ActiveFilters from "../../../shared/components/activeFilters";
import CustomTable, {
  ActionMenu,
  CustomPagination,
  HeaderItem,
  TagRender,
} from "../../../shared/components/table";
import {
  Autocomplete,
  Checkbox,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import useFetch from "../../../shared/components/userFetch";
import SearchIcon from "../../../../assets/fonts/Search.svg";
import usePatch from "../../../shared/components/usePatch";
import AlertDialog from "../../../shared/components/alretDialog";
import { showToast } from "../../../shared/components/showNotification";
import { connect } from "react-redux";
import { setPageCount } from "../../../../reducers/common";
import Loader from "../../../shared/components/loader";

const ArchiveJobList = ({
  handleOnFilterChange,
  filterData,
  filterState,
  setFilterState,
  handleSort,
  pageCount,
  setPageCount
}) => {
  const [deleteItem, setDeleteItem] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);

  const {
    data: responseData,
    isLoading,
    error,
    refetch,
    isFetching
  } = useFetch("/api/v2/entity_management/job_profiles/archived", {
    filters: filterData,
    pagination: { page: filterState.page, limit: filterState.limit },
    sort: filterState.sort,
  });
  const { data: baseData, isLoading: baseDataLoading } = useFetch(
    "/api/v2/entity_management/job_profiles/base_data?activated_eq=false"
  );
  const { mutate, isLoading: isRestoring } = usePatch(
    "/api/v2/entity_management/job_profiles"
  );

  const { data = [], metadata = {} } = responseData || {};
  const {
    job_profiles = [],
    jp_form_templates = [],
    jp_trainings = [],
    jp_users = [],
    groups = [],
    permissions: {
      allow_restore = true
    } = {}
  } = baseData || {};


  useEffect(() => {
    metadata.count && setPageCount({jp: {
      job_profile: pageCount.jp.job_profile,
      arch: metadata.count || pageCount.jp.arch 
    }})
  }, [metadata.count]);
  const callRefetch = () => {
    refetch({
      filters: filterData,
      pagination: { page: filterState.page, limit: filterState.limit },
      sort: filterState.sort,
    });
  };
  useEffect(() => {
    if (!isLoading) {
      refetch({
        filters: filterData,
        pagination: { page: filterState.page, limit: filterState.limit },
        sort: filterState.sort,
      });
    }
  }, [filterState]);

  const trainingFilterData = [
    { label: "All", value: "All" },
    ...jp_trainings.map((e) => ({ ...e, label: e.key, value: e.value })),
  ];
  const profilesFilterData = [
    { label: "All", value: "All" },
    ...job_profiles.map((e) => ({ ...e, label: e.key, value: e.value })),
  ];

  const usersFilterData = [
    { label: "All", value: "All" },
    ...jp_users.map((e) => ({ ...e, label: e.key, value: e.value })),
  ];
  const templateFilterData = [
    { label: "All", value: "All" },
    ...jp_form_templates.map((e) => ({ ...e, label: e.key, value: e.value })),
  ];

  const handleRestore = (ele, setClose) => {
    setDeleteItem({ ...ele, setClose });
    setAlertOpen(true);
  };

  const handleOk = () => {
    mutate(
      { id: deleteItem.id },
      {
        onSuccess: () => {
          showToast({ message: "This profile successfully restored!" });
          callRefetch();
        },
        onError: (e) => {
          showToast({ type: 'error', message: typeof e === 'string' ? e : e.errors.toString() });        
        }
      },
       `/api/v2/entity_management/job_profiles/${deleteItem.id}/restore`
    );
    if (alertOpen) {
      setDeleteItem(null);
      setAlertOpen(false);
    }
  };


  const headers = [
    <TableCell className="left">
      <HeaderItem
        title="Job Profile Name"
        isSort
        handleSort={() => handleSort("name")}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            disableClearable
            isOptionEqualToValue={(option, value) => option.value === value}
            onChange={(_, v) => handleOnFilterChange(v || "All", "id_eq")}
            value={
              profilesFilterData.find((e) => e.value === filterData["id_eq"]) ||
              "All"
            }
            options={profilesFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: "100%", background: "#fff", border: "none" }}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell>
      <HeaderItem
        title="Trainings"
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            disableClearable
            isOptionEqualToValue={(option, value) => option.value === value}
            onChange={(_, v) =>
              handleOnFilterChange(
                v || "All",
                "jp_topics_topics_for_training_id_eq"
              )
            }
            value={
              trainingFilterData.find(
                (e) =>
                  e.value === filterData["jp_topics_topics_for_training_id_eq"]
              ) || "All"
            }
            options={trainingFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: "100%", background: "#fff", border: "none" }}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell>
      <HeaderItem
        title="Users"
        // isSort
        // handleSort={() => handleSort("jp_users_user_id_eq")}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            disableClearable
            isOptionEqualToValue={(option, value) => option.value === value}
            onChange={(_, v) =>
              handleOnFilterChange(v || "All", "jp_users_user_id_eq")
            }
            value={
              usersFilterData.find(
                (e) => e.value === filterData["jp_users_user_id_eq"]
              ) || "All"
            }
            options={usersFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: "100%", background: "#fff", border: "none" }}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell>
      <HeaderItem
        title="Form Templates"
        // isSort
        // handleSort={() => handleSort("jp_form_templates_id_eq")}
        filter={
          <Autocomplete
            size="small"
            defaultValue="All"
            disableClearable
            isOptionEqualToValue={(option, value) => option.value === value}
            onChange={(_, v) =>
              handleOnFilterChange(v || "All", "jp_form_templates_id_eq")
            }
            value={
              templateFilterData.find(
                (e) => e.value === filterData["jp_form_templates_id_eq"]
              ) || "All"
            }
            options={templateFilterData}
            popupIcon={<img src={SearchIcon} />}
            sx={{ width: "100%", background: "#fff", border: "none" }}
            renderInput={(params) => <TextField {...params} label="Search" />}
          />
        }
      />
    </TableCell>,
    <TableCell className="right" style={{ minWidth: "100px" }}>
      <HeaderItem title="Actions" />
    </TableCell>,
  ];

  const rows = useMemo(
    () =>
      data.map((ele, index) => {
        const {
          name,
          jp_trainings = [],
          jp_users = [],
          jp_shared_forms = [],
        } = ele || {};
        return (
          <TableRow key={index} className={index % 2 === 0 ? "even-row" : "odd-row"}>
            <TableCell className="left">
              <div className="flex">
                {/* <Checkbox /> */}
                <div className="flex" style={{ alignItems: "center" }}>
                  <div>{name}</div>
                </div>
              </div>
            </TableCell>
            <TableCell>
              <TagRender data={jp_trainings} />
            </TableCell>
            <TableCell>
              <TagRender data={jp_users} />
            </TableCell>
            <TableCell>
              <TagRender data={jp_shared_forms} />
            </TableCell>
            <TableCell className="right" style={{ minWidth: '100px' }}>
              <ActionMenu>
                {(setClose) => {
                  const menuItems = [];
                  if (allow_restore) {
                    menuItems.push(
                      <MenuItem onClick={() => handleRestore(ele, setClose)}>
                        Restore
                      </MenuItem>
                    )
                  }
                  return menuItems;
                }}
              </ActionMenu>
            </TableCell>
          </TableRow>
        );
      }),
    [data]
  );

  if(isLoading){
    return <Loader />
  }

  return (
    <>
      <AlertDialog
        alertOpen={alertOpen}
        handleClose={() => setAlertOpen(false)}
        handleOk={handleOk}
        title="Restore Job Profile"
        description = 'Are You sure you want to restore this job profile?'
        okText="Restore"
      />
      <CustomTable
        headers={headers}
        rows={rows}
        isSliderScroll={false}
        renderActiveFilter={
          <ActiveFilters filterData={filterState} update={setFilterState} keyName='jp' />
        }
      />
      {!!metadata.count && (
        <CustomPagination
          totalPage={metadata.last}
          lastPage={metadata.last}
          currentPage={filterState.page}
          handlePageChange={(_, nextPage) => {
            setFilterState((prev) => ({ ...prev, page: nextPage }));
          }}
          rowOptions={[20, 50, 100]}
          rowPerPage={filterState.limit}
          handleRowPerPageChange={(targetValue) => {
            setFilterState((prev) => ({
              ...prev,
              limit: parseInt(targetValue, 10),
              page: 1,
            }));
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
      pageCount: state.CommonReducer.pageCount
  };
};
const mapDispatchToProps = (dispatch) => ({
  setPageCount: (value) => dispatch(setPageCount(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveJobList);
