import React, { useEffect, useState, useRef, useContext } from "react";
import {MenuItem, Breadcrumbs, FormControl, Select, Pagination, Link} from "@mui/material"; 
import Application from "../../../../config/ApplicationWrapper";
import Documents from "../Popups/Docs";
import { toast } from "react-toastify";
import { AssetCreateContext } from "./index";
import Folder from "../../../../assets/fonts/Folder.svg";
import ListView from "../Edit/list";
import axios from "../../../../config/axiosConfig";

const AssetDocumnets = () => {
  const { setTabKey } = useContext(AssetCreateContext);
  const [page, setPage] = useState(1); 
  const [limit, setLimit] = useState(10); 
  const [lastUpdate, setLastUpdate] = useState(0); 
  const [pageCount, setPageCount] = useState(0);
  const [pageDetails, setPageDetails] = useState([]);
  const [paginationStatus, setPaginationStatus] = useState(false);
  const [name, setSearchByName] = useState();
  const [searchByType, setSearchByType] = useState();
  const [searchByCreatedBy, setSearchByCreatedBy] = useState();
  const [searchByUpdatedAt, setSearchByUpdatedAt] = useState(); 
  const [openFolder, setOpenFolder] = useState(false); 
  const [selectedData, setSelectedData] = useState();
  const [sorting, setSorting] = useState(false);
  const [sortedBy, setSortedBy] = useState("file_name");
  const [parentId, setParentId] = useState();
  const [docPopup, setDocPopup] = useState(false);
  const firstUpdate = useRef(false);
  const [editData, setEditData] = useState(false);
  const [breadcrumb, setBreadcrumb] = useState([{
    name: "Home",
    id: ""
  }])


  let getAssetId = localStorage.getItem("assetInfo");
  let asstId = getAssetId ? JSON.parse(getAssetId) : "";

  useEffect(() => {  
    getPageDetails(breadcrumb[breadcrumb.length - 1].id)
    if(breadcrumb.length < 2){
      localStorage.removeItem("asset_data")
    }
  }, [page, limit, sorting, sortedBy, lastUpdate])


  useEffect(() => {
    if (firstUpdate.current) {
      const delayDebounceFn = setTimeout(() => {
        setPage(1);
        setPaginationStatus(false)
        getPageDetails(breadcrumb[breadcrumb.length - 1].id, 1)
      }, 2000)

      return () => clearTimeout(delayDebounceFn)
    } else {
      firstUpdate.current = true;
    }
  }, [name, searchByType, searchByUpdatedAt, searchByCreatedBy])

  const getPageDetails = async (id, pageNo) => {
    try {
      window.scrollTo(0, 0);
      let url = `${Application.api_url}/api/v2/asset_trackings/${asstId.asstId}/documents?limit=${limit}&page=${page}&doc_type=file`;
      if (id) {
        url = `${Application.api_url}/api/v2/asset_trackings/${asstId.asstId}/documents/${id}?doc_type=file`;
      }
      let resultObj = await axios.get(url, {
        params: {
          page: pageNo ? pageNo : page,
          limit: limit,
          search_text: name,
          doc_type_cont: searchByType,
          user_full_name_cont: searchByCreatedBy,
          updated_at_eq: searchByUpdatedAt,
          sort: sortedBy + " " + `${sorting ? "asc" : "desc"}`
        },
      });

      if (resultObj.data && resultObj.data.success) { 
        let details = resultObj.data; 
        if (id) {
          setPageDetails(details ? details.data.children : [])
        } else {
          setPageDetails(details ? details.data : [])
        }
        setPageCount(
          details && details.metadata ? details.metadata.pages : 0
        );
        setPaginationStatus(true)
      }
    } catch (err) {
      console.log("error==>", err);
    }
  };
 
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const deleteFolder = async (id) => {
    try {
      let resultObj = await axios.delete(`${Application.api_url}/api/v2/asset_trackings/${asstId.asstId}/documents/${id}`);
      if (resultObj.data.success) {
        toast.success("Deleted Successfully");
        getPageDetails(breadcrumb[breadcrumb.length - 1].id);
      }
    } catch (err) {
      console.log("error==>", err);
    }
  }

  const changeRoot = async (data) => {
    setPaginationStatus(false)
    setParentId(data.id)
    setPage(1)
    setBreadcrumb([...breadcrumb, ...[{ name: data.name, id: data.id }]])
    getPageDetails(data.id)
    localStorage.setItem("asset_data", JSON.stringify(data))
  }

  const hanldeClick = (id) => {
    if (id) {
      const index = breadcrumb.findIndex((item) => item.id == id);
      const filteredValues = breadcrumb.slice(0, index + 1);
      localStorage.setItem("asset_data", JSON.stringify(breadcrumb[index]))
      setBreadcrumb(filteredValues)
      getPageDetails(id);
    } else {
      setParentId("")
      setBreadcrumb([{
        name: "Home",
        id: ""
      }])
      getPageDetails(); 
      localStorage.removeItem("asset_data")
    }
  }

  const editRecord = (data) => {
    setOpenFolder(true);
    setSelectedData(data);
  }

  const hanldeShorting = (sort, field) => {
    setSorting(sort);
    setSortedBy(field)
  }

  const hanldeStared = async (stared, id) => {
    try {
      let resultObj = await axios.patch(`${Application.api_url}/api/v2/company_documents/${id}/${stared ? "unstar" : "star"}`);
      if (resultObj.data.success) {
        toast.success(`${stared ? "Unstar Successfully" : "Star Successfully"}`);
        getPageDetails(breadcrumb[breadcrumb.length - 1].id);
      }
    } catch (err) {
      console.log("error==>", err);
    }
  }

  const hanldeSearch = (name, value) => {
    switch (name) {
      case "name":
        setSearchByName(value)
        break;
      case "type":
        setSearchByType(value)
        break;
      case "created_by":
        setSearchByCreatedBy(value)
        break;
      case "updated_at":
        setSearchByUpdatedAt(value)
        break;
      default:
        break;
    }
  }

  const openDocPopup = (data) => {
    setDocPopup(true);
    if (data) {
      setEditData(data);
    }
  };

  const closePopup = (data) => {
    setDocPopup(false);
    setEditData(false)
    if (data) {
      toast.success("Created Successfully");
      setLastUpdate(Date.now());
    }
  };

  return (
    <div className="relatedAssets asset-maintance">
      <div className="header d-flex">
        <h3 className="title">
          <b>Asset Documents</b>
        </h3>
        <button
          type="button"
          className="btn btn-primary add-maintance-btn"
          onClick={() => {
            openDocPopup();
          }}
        >
          <i className="fa fa-plus" aria-hidden="true"></i>
          Add Documents
        </button>
      </div>
      <div className="body">
        <div className="table-template">
          <div className="asset-active-filters mb-3">
            <Breadcrumbs
              separator={">"}
              aria-label="breadcrumb"
            >
              {breadcrumb.map((val, index) => (
                <Link underline="hover" role="button" key="index" color="inherit" onClick={(e) => {
                  hanldeClick(val.id)
                }} className="breadcrumbs-link">
                  <img src={Folder} alt="" className="folder-icon" />{val.name}
                </Link>
              ))}
            </Breadcrumbs>
          </div>
          <ListView
            pageDetails={pageDetails}
            deleteFolder={deleteFolder}
            changeiDirectory={changeRoot}
            edit={editRecord}
            changeSorting={hanldeShorting}
            stared={hanldeStared}
            searching={hanldeSearch}
            searchByName={name}
            searchByType={searchByType}
            searchByCreatedBy={searchByCreatedBy}
            searchByUpdatedAt={searchByUpdatedAt}
            breadcrumb={breadcrumb}
            pageData={pageDetails}
            update={setLastUpdate}
          />
          <div className="page-limits">
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={limit}
                onChange={(e) => {
                  setPage(1);
                  setPaginationStatus(false);
                  setLimit(e.target.value);
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={35}>35</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </FormControl>
            {paginationStatus && (
              <Pagination
                count={pageCount}
                variant="outlined"
                shape="rounded"
                onChange={handleChangePage}
              />
            )}
          </div>
        </div>
      </div>
      <div className="d-flex footer">
        {/* <button
          className="btn button"
          onClick={() => {
            setTabKey("4");
          }}
        >
          Skip
        </button> */}
        <div className="step-finish-footer">
          <button
            className="btn button"
            onClick={() => {
              setTabKey("4");
            }}
          >
            Previous
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              window.location = "/asset-tracking";
            }}
          >
            Create
          </button>
        </div>
      </div>
      <Documents
        show={docPopup}
        closepopup={closePopup}
        data={editData}
        parentId={asstId.asstId}
      />
    </div>
  );
};

export default AssetDocumnets;
