import React from "react";
import SideNav from "../../components/SideNav";

const UserManagement = () => {
  return (
    <SideNav/>
  )
}

export default UserManagement;
