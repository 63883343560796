import React from 'react'
import HeroSection from './CommonComponents/HeroSection'
import FeaturesSection from './CommonComponents/FeaturesSection'
import ParallaxSection from '../../pages/HomePage/Components/ParallaxSection'
import HomepageDemoSection from '../../pages/HomePage/Components/HomepageDemoSection'
import r1 from '../../../assets/images/emer1.png'
import r2 from '../../../assets/images/emer2.png'

export default function EmergencyPlanManagement() {
  return (
    <>
      <HeroSection heroText={"Effective Emergency Plan Management"} subText={"Ensure the safety of your team and assets with SafetyLoop's comprehensive emergency plan management tools. Prepare for any situation and respond swiftly for a more secure health and safety management system."} />
      <FeaturesSection
        headerText={"Effortless Creation of Emergency Plans"}
        subText={"SafetyLoop empowers you to create and customize emergency plans swiftly, ensuring your organization is prepared for any unexpected situation."}
        featureHighlights={["Generate new emergency plans tailored to your facility's specific needs.",
          "Easily incorporate evacuation routes, contact lists, and safety protocols.",
          "Collaborate with teams to develop comprehensive and effective plans."]}
        sectionBackground='dark'
        sectionAlign="image-right"
        featureImageSrc={r1}
      />
      <FeaturesSection
        headerText={"Real-time Visibility into Emergency Plans"}
        subText={"Stay informed and proactive with SafetyLoop's real-time view of active emergency plans. Monitor and update plans as conditions evolve."}
        featureHighlights={["Access and view all active emergency plans from a centralized dashboard.",
          "Receive notifications and alerts for plan updates or critical incidents.",
          "Ensure that your teams have the most current information to respond effectively."]}
        featureImageSrc={r2}
      />
      {/* <FeaturesSection
        headerText={"Instant Alerts for Timely Responses"}
        subText={"SafetyLoop keeps you ahead of emergencies with instant alerts. Receive notifications, allowing you to take swift and informed action."}
        featureHighlights={["Receive automatic alerts for critical incidents or changes in emergency plans.",
          "Customize alert preferences to match your organization's needs.",
          "Facilitate quick communication and response coordination during emergencies."]}
        sectionBackground='dark'
        sectionAlign="image-right"
      /> */}
      <div className="Home-Page sl-home-wrapper" style={{ paddingTop: 0 }}>
        <ParallaxSection />
      </div>
      <div className="features-homepage-wrapper">
        <HomepageDemoSection mergeTop={false} />
      </div>
    </>
  )
}
