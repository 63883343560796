import React, {useEffect, useState} from 'react'
import {Modal, Button} from "react-bootstrap";
import { updateDeliveryRequestItem, titleize } from "../utils";
import Select from "react-select";

const deliveryItemStatusOptions = [
  {
    label: 'New Request',
    value: 'in_progress'
  },
  {
    label: 'In Progress',
    value: 'in_progress'
  },
  {
    label: 'Declined By Picker',
    value: 'declined_by_picker'
  },
  {
    label: 'Picked',
    value: 'picked'
  },
  {
    label: 'Picker Accepted',
    value: 'picker_accepted'
  },
  {
    label: 'Out for Delivery',
    value: 'out_for_delivery'
  },
  {
    label: 'Delivered',
    value: 'delivered'
  }
]

const DeliveryStatusUpdateModal = ({show, close, item, fetchDetails}) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [options, setOptions] = useState(deliveryItemStatusOptions)

  useEffect(() => {
    handleSelectChange({ label: titleize(item.status), value: item.status });
  }, [])

  const handleUpdate = async () => {
    await updateDeliveryRequestItem(item.identifier, {
      status: selectedOption.value
    });
    await fetchDetails();
  }

  const handleSelectChange = (value) => {
    setSelectedOption(value);
  }

  return (
    <Modal
      show={show}
      onHide={(_) => close()}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Delivery Status Update
        </Modal.Title>
        <Modal.Body>
          <Select
            value={selectedOption}
            options={options}
            onChange={handleSelectChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="btn-secondary" onClick={close}>
            Close
          </Button>

          <Button variant="primary" className="btn-primary" onClick={handleUpdate}>
            Save
          </Button>
        </Modal.Footer>
      </Modal.Header>
    </Modal>
  )
}

export default DeliveryStatusUpdateModal;