import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Application from "../../../../config/ApplicationWrapper";
import { Modal, FormControl as Input } from "react-bootstrap";
import Cookies from "js-cookie";
import axios from "axios";

export default function ShareQRPopup(props) {
    const { show, closepopup, data } = props;
    const cookieDetails = Cookies.get("token");
    const [onSubmit, setSubmit] = useState(false);
    const [email, setEmail] = useState("");

    const shareQRCode = async () => {
        try {
            setSubmit(true);

            if (!email) {
                return false;
            }

            let apiUrl = `${Application.api_url}/api/v2/asset_trackings/${data.id}/send_qr_code`;

            axios.defaults.headers.common["Authorization"] = cookieDetails;
            const result = await axios({
                method: "POST",
                url: apiUrl,
                data: { email: email }
            });

            if (result && result.status === 200) {
                setEmail("");
                setSubmit(false);
                closepopup(Date.now());
            }
        } catch (err) {
            console.log("err===>", err);
        }
    };

    return (
        <Modal
            show={show}
            onHide={(e) => {
                onhandleStatusClose();
                setSubmit(false);
            }}
            className="create-maintanance-popup share-qr-code"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <i className="fa fa-plus" aria-hidden="true"></i>
                    <b> Share QR </b>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="create-form">
                    <div className="row">
                        <div className="col-md-12">
                            <label>
                                Email ID <text style={{ color: "red" }}>*</text>
                            </label>
                            <TextField
                                required
                                fullWidth
                                className="text-field"
                                id="outlined-required"
                                error={!email && onSubmit}
                                helperText={
                                    !email && onSubmit ? "Email ID is required" : ""
                                }
                                placeholder="Please enter the email"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn button cancel-btn"
                    onClick={() => {
                        setEmail("");
                        setSubmit(false);
                        closepopup();
                    }}
                >
                    Cancel
                </button>
                <button
                    className="btn btn-primary"
                    onClick={() => {
                        shareQRCode();
                    }}
                >
                    Share
                </button>
            </Modal.Footer>
        </Modal>
    );
}
