import React from 'react';
import HeroSection from './CommonComponents/HeroSection';
import FeaturesSection from './CommonComponents/FeaturesSection';
import ParallaxSection from '../../pages/HomePage/Components/ParallaxSection';
import HomepageDemoSection from '../../pages/HomePage/Components/HomepageDemoSection';
import r1 from '../../../assets/images/ug1.png'
import r2 from '../../../assets/images/ug2.png'
import r3 from '../../../assets/images/ug3.png'


export default function UserManagement() {
  return (
    <>
      <HeroSection
        heroText={"Effortless User Management"}
        subText={"Simplify user management for your users and companies within a unified platform. Take charge of your health and safety management system with SafetyLoop's powerful user management tools, streamlining access and control for your entire safety eco-system."}
      />
      <FeaturesSection
        headerText={"Efficient User & Group Management"}
        subText={"SafetyLoop simplifies the way you manage users and groups within your safety eco-system. Gain complete control and visibility over your workforce."}
        featureHighlights={["Create and organize users and groups effortlessly.", "Assign specific permissions and roles to individuals and teams.", "Ensure seamless communication and collaboration among users and groups."]}
        sectionBackground='dark'
        sectionAlign="image-right"
        featureImageSrc={r1}
      />
      <FeaturesSection
        headerText={"Custom Job Profiles"}
        subText={"Tailor job profiles to fit your organization's unique needs with SafetyLoop. Define roles and responsibilities with precision"}
        featureHighlights={["Create custom job profiles for various roles within your organization.", "Specify required qualifications, certifications, and training for each profile.", "Simplify onboarding and compliance management with job-specific profiles."]}
        sectionBackground='white'
        featureImageSrc={r2}
      />
      <FeaturesSection
        headerText={"Streamlined Company Groups & Global Projects"}
        subText={"SafetyLoop centralizes your company groups and global projects, ensuring a cohesive approach to health and safety management."}
        featureHighlights={["Organize your workforce into company groups for efficient management.", "Assign and oversee global projects with ease, regardless of location.", "Maintain consistency and compliance across all company groups and projects."]}
        sectionBackground='dark'
        sectionAlign="image-right"
        featureImageSrc={r3}
      />
      <div className="Home-Page sl-home-wrapper" style={{ paddingTop: 0 }}>
        <ParallaxSection />
      </div>
      <div className="features-homepage-wrapper">
        <HomepageDemoSection mergeTop={false} />
      </div>
    </>
  )
}
