import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import Cookies from "js-cookie";
import Application from "../../../../config/ApplicationWrapper";
import { toast } from "react-toastify";
const cookieDetails = Cookies.get("token");

export default function Priority(props) {
  const { show, closepopup, priorityDetails } = props;
  const [riskId, setRiskId] = React.useState(priorityDetails.priority_order);
  const onhandleStatusClose = () => {
    closepopup();
  };

  useEffect(()=>{
    setRiskId(priorityDetails.priority_order)
  },[priorityDetails])

  const update = async() => {
    try {
      await fetch(`${Application.api_url}/api/v2/rats/${priorityDetails.id}`, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          Authorization: cookieDetails,
        },
        body: JSON.stringify({
          priority_order: riskId,
        }),
      });
      toast.success("Updated successfully");
      closepopup(riskId)
    } catch (err) {
      console.log("error===>", err);
    }
  };

  return (
    <Modal
      show={show}
      centered={true}
      className="add-task-modal signature-popup periority-popup"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <b>Change Priority </b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="rat-details">
          <p className="asset-name">Assessments Name</p>
          <p className="asset-detail">{priorityDetails ? priorityDetails.name : ""}</p>
        </div>
        <div className="periority-list">
          <select
            className="form-control risk-type"
            value={riskId}
            onChange={(e) => {
              setRiskId(e.target.value);
            }}
          >
            {[...Array(15)].map((x, i) => (
              <option value={i + 1} key={i}>
                {i + 1}
              </option>
            ))}
          </select>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn-secondary"
          onClick={(e) => {
            onhandleStatusClose();
          }}
        >
          Cancel
        </Button>
        <Button
          className="btn-primary save-priority-btn"
          onClick={(e) => {
            update();
          }}
        >
          Change
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
