import React, { useEffect, useState, useLayoutEffect } from "react";
import { Modal } from "react-bootstrap";
import { Button, TextField, Checkbox, FormGroup, FormControlLabel, FormControl, Autocomplete } from '@mui/material';
import axios from "../../../../../config/axiosConfig";
import Application from "../../../../../config/ApplicationWrapper";
import { toast } from "react-toastify";
import _ from "lodash"

export default function CreateFolder(props) {
    const { show, closepopup, details, parentId } = props;
    let userDetails = localStorage.getItem("user");
    userDetails = JSON.parse(userDetails);
    const [group, setGroup] = useState([]);
    const [validation, setValidation] = useState(true);
    const [onSubmit, setSubmit] = useState(false);
    const [groupsList, setGroupsList] = useState([]);
    const [values, setValues] = useState({
        name: "",
        description: "",
        parent_id: "",
        expiry_date: "",
        is_public: false,
        is_global_project: false,
        is_hsms_doc: false,
        group: []
    })

    useLayoutEffect(() => {
        async function fetchGroup() {
            const groupDetails = await axios.get(`${Application.api_url}/api/v2/internal_subs/${userDetails.company_identifier}/internal_groups`);
            if (groupDetails.status == 200 && groupDetails.data.success === true) {
                var result = groupDetails.data;
                result = _.map(result.data, "group")
                setGroupsList(result);
                if (details.global_project_ids) {
                    let groupDetails = result.filter(person => details.global_project_ids.includes(person.id));
                    setGroup(groupDetails)
                }
            }

            if (details) {
                if (details.description) {
                    details.description = details.description != "null" && details.description != "undefined" ? details.description : "";
                }
                setValues({ ...values, ...details }); 
            }
        }
        fetchGroup()
    }, [])

    const onhandleStatusClose = () => {
        closepopup();
        setValues({
            name: "",
            description: "",
            parent_id: "",
            expiry_date: "",
            is_public: false,
            is_global_project: false,
            is_hsms_doc: false,
            group: []
        });
        setGroup([]);
        setSubmit(false)
    };

    const handleChange = (name, value) => {
        setValues({ ...values, [name]: value });
    }

    const create = async () => {
        setSubmit(true);
        if (!values.name.trim()) {
            setValidation(false)
            return false;
        }
        let doc_type = "folder";
        if (details && details.slug) {
            doc_type = details.is_directory ? "folder" : "file";
        }
        var users = localStorage.getItem('user');
        users = JSON.parse(users);
        const formData = new FormData();
        formData.append("doc_detail[doc_type]", doc_type);
        formData.append("doc_detail[user_id]", users.id);
        formData.append("doc_detail[company_id]", users.company_id);
        formData.append("doc_detail[description]", values.description);
        parentId && formData.append("doc_detail[parent_id]", parentId);
        formData.append("doc_detail[expiry_date]", values.expiry_date);
        formData.append("doc_detail[is_global_project]", values.is_global_project);
        formData.append("doc_detail[is_public]", values.is_public);
        formData.append("doc_detail[is_hsms_doc]", values.is_hsms_doc);

        if (doc_type == "file") {
            formData.append("doc_detail[file_name]", values.name.replace(/\.[^/.]+$/, ""));
        } else {
            formData.append("doc_detail[folder_name]", values.name);
        }

        if (values.is_global_project) {
            [...group].forEach(obj => {
                formData.append("global_project_ids[]", obj.id);
            });
        } else {
            formData.append("global_project_ids[]", "");
        }

        let url = `${Application.api_url}/api/v2/company_documents/${users.company_id}/upload`;

        if (details) {
            url = `${Application.api_url}/api/v2/company_documents/${details.slug}`;
        }

        const result = await axios({
            method: "PATCH",
            url: url,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if (result.data && result.data.success === true) {
            toast.success(`${details ? "Updated" : "Created"} Successfully`)
            setValues("");
            setGroup([]);
            setSubmit(false)
            closepopup(result.data);
        }
    }

    return (
        <Modal
            show={show}
            onHide={(e) => {
                onhandleStatusClose();
            }}
            className="qr-modal-wrap add-task-modal pf-model docs-model"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="pf-model-header">
                        <p className="pf-model-title">{details ? "Update Details" : "Create Folder"} </p>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="row">
                        <div className="col-md-12">
                            <label>
                                Folder Name  <text style={{ color: "red", fontSize: "16px" }}>*</text>
                            </label>
                            <TextField
                                required
                                fullWidth
                                className="text-field"
                                id="outlined-required"
                                error={!validation && onSubmit}
                                helperText={
                                    !validation && onSubmit ? "Folder Name is required" : ""
                                }
                                value={values.name}
                                onChange={(e) => {
                                    setSubmit(false);
                                    handleChange("name", e.target.value);
                                }}
                            />
                        </div>
                        <div className="col-md-12">
                            <label>Description</label>
                            <textarea
                                className="create-asset-textarea"
                                aria-label="empty textarea"
                                value={values.description}
                                onChange={(e) => {
                                    handleChange("description", e.target.value);
                                }}
                            />
                        </div>
                        <div className="col-md-12">
                            <label>
                                Expiry Date
                            </label>
                            <input
                                type="date"
                                min={new Date().toJSON().split("T")[0]}
                                class="date-field"
                                id="expiry_date"
                                placeholder="Search"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                name="expiry_date"
                                data-date="MM DD YYYY"
                                data-date-format="MM DD YYYY"
                                value={values.expiry_date}
                                onChange={(e) => {
                                    handleChange("expiry_date", e.target.value);
                                }}
                            />
                        </div> 
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="submit-btns">
                    <Button className="btn-cancel" onClick={(e) => { onhandleStatusClose() }}>Cancel</Button>
                    <Button variant="contained" onClick={(event) => {
                        event.currentTarget.disabled = true;
                        create()
                    }}>
                        {details ? "Update" : "Create"}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}
