import React from "react";
import { Redirect } from "react-router-dom";
import ActiveAsset from "./ActiveAsset";
import ArchiveAsset from "./ArchiveAsset";
import Cookies from "js-cookie";
const cookieDetails = Cookies.get("token");
import Application from "../../../config/ApplicationWrapper";
import Asset from "../../../assets/images/Asset.svg";
import ChildAsset from "../../../assets/images/ChildAsset.svg";
import AloneAsset from "../../../assets/images/aloneAsset.svg";
import axios from "axios";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import loader from "/public/main-images/newloader.gif";
import QrBulkGenerate from "../../../assets/images/QRCodeBlueScan.svg";

class AssetTrackingTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      create: false,
      ratId: "",
      metadata: "",
      searchMetaData:"",
      searchArchiveMetaData:"",
      archiveMetaData: "",
      exportQr: false,
      filterParams: {}
    };
    this.state = { isLoading: false };
    this.exportData = this.exportData.bind(this);
    this.updateCount = this.updateCount.bind(this);
    this.generateBulkQR = this.generateBulkQR.bind(this);
    this.updateArchiveCount = this.updateArchiveCount.bind(this);
    this.updateArchiveSearchCount = this.updateArchiveSearchCount.bind(this);
    this.updateSearchCount = this.updateSearchCount.bind(this);
  }

  setFilterParams = (params) => {
    this.setState({ filterParams: {...this.state.filterParams, ...params} });
  };

  clearFilterParams = () => {
    this.setState({ filterParams: {} })
  }

  exportData = async (filterParams) => {
    const headers = {
      "Content-Type": "blob",
      Authorization: cookieDetails,
    };
    const queryParams = new URLSearchParams(filterParams).toString();
    const config = {
      method: "GET",
      url: `${Application.api_url}/api/v2/asset_trackings/export?${queryParams}`,
      responseType: "arraybuffer",
      headers,
    };

    const currentDateTime = new Date();

    const year = currentDateTime.getFullYear();
    const month = currentDateTime.getMonth() + 1;
    const day = currentDateTime.getDate();
    const hours = currentDateTime.getHours();
    const minutes = currentDateTime.getMinutes();
    const seconds = currentDateTime.getSeconds();

    const formattedDateTime = `${year}-${(month)}-${(day)} ${hours}:${minutes}:${seconds}`;

    const response = await axios(config);
    const outputFilename = `Asset_export_${formattedDateTime}.zip`;

    // If you want to download file automatically using link attribute.
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", outputFilename);
    document.body.appendChild(link);
    link.click();
    this.setState({ isLoading: false });
  };

  handleClick = (filterParams) => {
    this.setState({ isLoading: true });
    toast.success("Exporting Data.  This might take a few minutes.");
    this.exportData(filterParams);
  };

  generateBulkQR = () => {
    try {
      this.setState({ exportQr: true });
      let url = new URL(`${Application.api_url}/api/v2/asset_trackings/bulk_qr_generate?archived_true=${false}`);
      fetch(
          url,
          {
            method: "GET",
            headers: { Authorization: cookieDetails },
          }
      ).then(async response => response.blob())
      .then((blob) => {
        let file = new Blob([blob], {type: 'application/pdf'});
        let fileURL = window.URL.createObjectURL(file);
        window.open(fileURL);
        this.setState({ exportQr: false });
      })
    } catch (err) {
      console.log("error===>", err);
    }
  };

  updateCount = async (val) => {
    this.setState({ metadata: val });
  };

  updateSearchCount = async (val) => {
    this.setState({ searchMetaData: val });
  };

  updateArchiveCount = async (val) => {
    this.setState({archiveMetaData: val})
  }

  updateArchiveSearchCount = async (val) => {
    this.setState({searchArchiveMetaData: val})
  }

  render() {
    const { isLoading, filterParams } = this.state;
    return (
      <>
        <div className="asset-tracking row">
          <div className="col-sm-12 col-md-12 col-lg-12 ">
            <div className="track-details d-flex">
              <div className="detail-box">
                <div className="asset-track">
                  <img src={Asset} alt="" className="tracking-img" />
                  <p>Total Asset</p>
                </div>
                <div className="asset-track-count">
                  <b>
                    {this.state.metadata && this.state.metadata.count}
                  </b>
                </div>
              </div>
              <div className="total-status-box">
                <h3>
                  <b>
                    {this.state.metadata && this.state.metadata.total_in_use ? this.state.metadata.total_in_use : 0}
                  </b>
                </h3>
                <span className={`badge badge-light UseTitle`}>
                  <p className="circle green"></p> In-use
                </span>
              </div>
              <div className="total-status-box">
                <h3>
                  <b>
                    {this.state.metadata  && this.state.metadata.total_idle ? this.state.metadata.total_idle : 0}
                  </b>
                </h3>
                <span className={`badge badge-light IdleTitle`}>
                  <p className="circle yellow"></p> Idle
                </span>
              </div>
              <div className="total-status-box">
                <h3>
                  <b>
                    {this.state.metadata ? this.state.metadata.total_repair : 0}
                  </b>
                </h3>
                <span className={`badge badge-light RepairTitle`}>
                  <p className="circle red"></p> Repair
                </span>
              </div>
            </div>
          </div> 
        </div>
        <div className="card asset-tab-landing">
          <div className="card-body">
            <div className="d-flex align-item-center justify-content-between header-badge">
              <div className="d-flex align-item-center">
                <h3>Asset Tracking </h3>
              </div>
              <div className="btn-style">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={(e) => {
                    this.handleClick(filterParams);
                  }}
                >

                  {isLoading && (
                        <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: "5px" }}
                      />
                    )
                  }
                    <>
                      <i className="fa fa-download" aria-hidden="true"></i>
                      Export
                    </>

                </button>
                <button type="button" className="btn btn-outline-primary"
                        onClick={() => {
                          this.generateBulkQR()
                        }}
                >

                  {
                    this.state.exportQr && (
                      <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: "5px" }}
                      />
                    )
                  }
                  <img src={QrBulkGenerate} style={{ width: "auto", padding: 0, marginRight: '5px' }} />
                    Bulk QR Code Generate
                </button>
                <NavLink
                  to={`/create-asset-tracking`}
                  id=""
                  role="button"
                  className="rat-links"
                  onClick={(e)=>{
                    localStorage.setItem(
                      "assetInfo",
                      ""
                    );
                  }}
                >
                  <button type="button" className="btn btn-primary">
                    <i className="fa fa-plus" aria-hidden="true"></i>
                    Create
                  </button>
                </NavLink>
              </div>
            </div>
            <ul className="nav nav-tabs">
              <li className="active">
                <a data-toggle="tab" href="#active-risk-assessment">
                  Active Asset Tracking
                  <span className="badge badge-light">
                    {this.state.searchMetaData ? this.state.searchMetaData.total_active : 0}
                  </span>
                </a>
              </li>
              <li>
                <a data-toggle="tab" href="#archived-risk-assessment">
                  Archived Asset Tracking
                  <span className="badge badge-light">
                    {this.state.searchArchiveMetaData
                      ? this.state.searchArchiveMetaData.total_archived
                      : 0}
                  </span>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div
                id="active-risk-assessment"
                className="tab-pane fade in active active-risk-assessment"
              >
                <ActiveAsset countUpdate={this.updateCount} countSearchCount={this.updateSearchCount} setFilterParams={this.setFilterParams} clearFilterParams={this.clearFilterParams} />
              </div>
              <div
                id="archived-risk-assessment"
                className="tab-pane fade in active-risk-assessment"
              >
                <ArchiveAsset countUpdate={this.updateArchiveCount} countSearchCount={this.updateArchiveSearchCount}/>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AssetTrackingTable;
