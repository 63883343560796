import React from "react";
//import { BrowserRouter as Router, Route, Link, withRouter,Redirect} from "react-router-dom";
import {
  BrowserRouter as Router,
  Route,
  NavLink,
  HashRouter,
  Redirect,
  Link,
} from "react-router-dom";
import cx from "classnames";
import UserDashboard from "../UserDashboard";
import Login from "./Login";
import SignUp from "./Signup";
import Forget from "./Forget";
import Main from "../Main";
import Layout from "./Layout";
import Aboutus from "./Aboutus";
import Contactus from "./Contactus";
import Home from "./Home";
import Profile from "../Main/Profile";
import Marketing from "./Marketing";
import Header from "./Header";
import Footer from "./Footer";
import Privacy from "../../components/PrivacyPolicy";
import Terms from "../../components/Terms";
import Products from "./Products";

import Teams from "./Teams";
import Careers from "./Careers";

import UpdatePassword from "./UpdatePassword";
import HelpUs from "./Helpus";
import EULA from "../../components/eula-policy";



var axios = require("axios");

// import UserDashboard from '../UserDashboard';

class HomePage extends React.Component {
  constructor(props) {
    super();
    this.state = {
      currentUser: null,
    };
    this.updateCurrentUser = this.updateCurrentUser.bind(this);
  }

  componentDidMount() {
    let that = this;
    axios
      .get("/users/check_for_user", {})
      .then(function (response) {
        if (
          response.data.user &&
          (response.data.user.email || response.data.user.username)
        ) {
          $("#app").data("user_data", response.data.user);
          $("#app").data("company_data", response.data.company);
          $("#app").data("company_url", response.data.company_url);
          $("#app").data("user_role", response.data.user_role);
          that.setState({
            currentUser: response.data.user.email,
          });
        } else {
          that.setState({
            currentUser: null,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  updateCurrentUser(email) {
    this.setState({
      currentUser: email,
    });
  }

  render() {
    const { currentUser } = this.state;
    if (currentUser) {
      return <Main />;
    }

    return (
      <Router>
        <div>
          <Route exact path="/" component={Home} />
          <Route path="/product" component={Products} />
          <Route path="/teams" component={Teams} />
          <Route path="/careers" component={Careers} />
          <Route path="/login" component={Login} />
          <Route path="/update-password" component={UpdatePassword} />
          <Route path="/forget-password" component={Forget} />
          <Route path="/register" component={SignUp} />
          <Route path="/about-us" component={Aboutus} />
          <Route path="/contact" component={Contactus} />
          <Route path="/contact" component={HelpUs} />
          <Route path="/marketing" component={Marketing} />
          <Route path="/profile" component={Profile} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/disclaimer" component={Disclaimer} />
          <Route path="/terms" component={Terms} />
          <Route path="/eula-policy" component={EULA} />
        </div>
      </Router>
    );
  }
}

export default HomePage;
