import React from 'react'
import HeroSection from './CommonComponents/HeroSection'
import BookDemoSection from './CommonComponents/BookDemoSection'

export default function Timetracking() {
  return (
    <>
      <HeroSection heroText={"Efficient Timetracking"} subText={"Track and manage time effortlessly with SafetyLoop's robust timetracking capabilities. Streamline workforce time monitoring for enhanced health and safety management."} />
      <BookDemoSection />
    </>
  )
}
