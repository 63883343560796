import React from "react";
import { BrowserRouter as Router, Route, Link, withRouter,Redirect} from "react-router-dom";

class Marketing extends React.Component {

    render() {

    return (
        <div className="main-wrap">
            <div className="content">
                <div className="container-fluid">
                    <div className="panel-heading">
                        <h2>Marketing Page</h2>
                    </div>
                </div>
            </div>
        </div>
        )
}
}

export default Marketing;
