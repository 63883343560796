import React, { useState } from "react";
import axios from "@config/axiosConfig";
import Application from "@config/ApplicationWrapper";

import Stared from "@assets/fonts/stared.svg";
import Unstar from "@assets/fonts/unstar.svg";
import EmptyTable from "@assets/images/empty-assets.svg";

import Folder from "@assets/fonts/Folder.svg";
import FolderExpired from "@assets/fonts/FolderExpired.svg";

import PNGIcon from "@assets/fonts/png.png";
import JPGIcon from "@assets/fonts/jpg.png";
import MP4Icon from "@assets/fonts/mp4.png";
import MP3Icon from "@assets/fonts/mp3.png";
import ZIPIcon from "@assets/fonts/zip.png";
import PPTIcon from "@assets/fonts/ppt.png";
import XLSIcon from "@assets/fonts/xls.png";
import PDFIcon from "@assets/fonts/pdf.png";
import DOCSIcon from "@assets/fonts/docs.png";
import XLSXIcon from "@assets/fonts/xlsx.png";

import ViewDocs from "../popups/view";

const CardView = ({ pageDetails, deleteFolder, changeiDirectory, edit, stared, findAsset }) => {
    const imageFormat = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png)$/i;
    const pdfFormat = /(\.pdf)$/i;
    const [openview, setOpenView] = useState(false);
    const [selectedData, setSelectedData] = useState();

    const NoAssetsFound = () => (
        <div className="no-assets" style={{ textAlign: "center", marginTop: "20px" }}>
            <img src={EmptyTable} />
            <p>
                <b>No Data</b>
            </p>
        </div>
    );

    const fileIcons = {
        Folder: Folder,
        FolderExpired: FolderExpired,
        png: PNGIcon,
        jpg: JPGIcon,
        jpeg: JPGIcon,
        pdf: PDFIcon,
        doc: DOCSIcon,
        docx: DOCSIcon,
        xlsx: XLSXIcon,
        xls: XLSIcon,
        csv: XLSIcon,
        mp4: MP4Icon,
        mp3: MP3Icon,
        zip: ZIPIcon,
        ppt: PPTIcon,
        pptx: PPTIcon,
    };

    const FileType = ({ item }) => {
        let fileExtension = item.is_directory ? 'Folder' : item.name.split('.').pop().toLowerCase();
        let fileIcon = fileIcons[fileExtension] || DOCSIcon;
        if(item.is_directory && item.expired) fileIcon = fileIcons['FolderExpired']

        return (
            <img src={fileIcon} alt="image" className="file-icon"/>
        );
    }

    const hanldeClick = async (val) => {
        findAsset(val.is_asset_doc || val.is_internal_doc)
        if (val.is_directory) {
            changeiDirectory(val)
        } else if (pdfFormat.exec(val.name) || imageFormat.exec(val.name)) {
            // setSelectedData(val.slug)
            // setOpenView(true)
            const data = await axios.get(`${Application.api_url}/api/v2/company_documents/${val.slug}`);
            if (data.status == 200 && data.data.success === true) {
                let values = data.data.data;
                if (values.file && values.file.url) {
                    window.open(values.file.url, "_blank")
                }
            }
        }
    }


    return (
        <div className="card-view">
            <div className="col-md-12">
                {pageDetails && pageDetails.map((val) => (
                    <div className="col-md-3">
                        <div className="card-details">
                            <div className="card-img">
                                <FileType item={val} />
                            </div>
                            <div className="card-body">
                                <p className={`cards-list-name ${val.expired ? 'folder-expired' : ''}`} onClick={(e) => { hanldeClick(val) }}>{val.name}</p>
                                <p>{val.created_by}</p>
                                <p>{val.created_at}</p>
                            </div>
                        </div>
                        {val.is_asset_doc != true && val.is_internal_doc != true &&
                            <p className="action-list">
                                <a
                                    aria-expanded="false"
                                    aria-haspopup="true"
                                    class="btn dropdown-toggle"
                                    data-toggle="dropdown"
                                    role="button"
                                >
                                    <i class="fa fa-ellipsis-v"></i>
                                </a>
                                <ul aria-labelledby="dropdownMenuLink" class="dropdown-menu dropdown-menu-right">
                                    <li>
                                        <a
                                            class="archive_group_invites"
                                            data-toggle="tooltip"
                                            title="Edit"
                                            onClick={(e) => {
                                                edit(val)
                                            }}
                                        >
                                            <div class="row">
                                                <div class="col-sm-3">
                                                    <i
                                                        class="fa fa-pencil-square-o"
                                                        style={{
                                                            fontSize: "16px",
                                                            cursor: "pointer"
                                                        }}
                                                    ></i>
                                                </div>
                                                <div
                                                    class="col-sm-9 cursor-pointer"
                                                >
                                                    Edit
                                                </div>
                                            </div>
                                        </a>
                                        <a
                                            class="archive_group_invites"
                                            data-toggle="tooltip"
                                            title="Archive"
                                            onClick={(e) => {
                                                deleteFolder(val.slug);
                                            }}
                                        >
                                            <div class="row">
                                                <div class="col-sm-3">
                                                    <i
                                                        class="fa fa-trash"
                                                        style={{
                                                            fontSize: "16px",
                                                            color: "#FF3030",
                                                            cursor: "pointer"
                                                        }}
                                                    ></i>
                                                </div>
                                                <div
                                                    class="col-sm-9 cursor-pointer"
                                                    style={{
                                                        color: "#FF3030",
                                                    }}
                                                >
                                                    Delete
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </p>
                        }
                        <p className="star-icon">
                            <img src={val.is_starred ? Stared : Unstar} alt="" className="cursor-pointer" onClick={(e) => { stared(val.is_starred, val.slug) }} />
                        </p>
                    </div>
                ))}
                {pageDetails && pageDetails.length == 0 &&
                    <NoAssetsFound />
                }
            </div>
            {selectedData &&
                <ViewDocs show={openview} details={selectedData} closepopup={(e) => { setOpenView(false) }} />
            }
        </div>
    )
}

export default CardView;