import React from "react";
import Highcharts from "highcharts";
import exporting from "highcharts/modules/exporting";
import HourlyChart from "./HourlyChart";
import PieChart from "./PieChart";
import FormsPerDayChart from "./FormsPerDayChart";
exporting(Highcharts);
import useFetchChartData from "../../hooks/useFetchChartData";
import ErrorFallback from "../../components/ErrorFallback";
import ActionStatusChart from "./ActionsStatusChart";

const ChartData = ({ query, onFilterSelect }) => {
  const { stats, loading, error } = useFetchChartData(query);

  return (
    <div className="chart-wrap">
      <div className="row">
        <div className="col-lg-7" id="hourlyChart">
          <div className={loading ? "card loader" : "card"}>
            {loading && (
              <div className="loading-block">
                <h4>Current Actions Status</h4>
                <img src="/main-images/newloader.gif" style={{ width: "175px" }} />
              </div>
            )}
            {!error && (
              <ActionStatusChart
                stats={stats.audit_actions_stats}
                query={query}
                onFilterSelect={onFilterSelect}
                error={error}
              />
            )}
            {error && (<ErrorFallback />)}
          </div>
        </div>
        <div className="col-lg-5" id="pieData">
          <div className={loading ? "card loader" : "card"}>
            {loading && (
              <div className="loading-block">
                <h4>Form Types</h4>
                <img src="/main-images/newloader.gif" style={{ width: "175px" }} />
              </div>
            )}
            {!error && (
              <PieChart
                stats={stats.form_types}
                query={query}
                onFilterSelect={onFilterSelect}
                error={error}
              />
            )}
            {error && (<ErrorFallback />)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartData;
