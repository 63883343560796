import React from 'react';
import HeroSection from './CommonComponents/HeroSection';
import FeaturesSection from './CommonComponents/FeaturesSection';
import ParallaxSection from '../../pages/HomePage/Components/ParallaxSection';
import HomepageDemoSection from '../../pages/HomePage/Components/HomepageDemoSection';

export default function DataIntegrations() {
    return (
        <>
            <HeroSection
                heroText={"Data & Integrations"}
                subText={"Integrate data across multiple platforms for comprehensive visibility and control."}
            />
            <FeaturesSection
                headerText={"Seamless Data Integration"}
                subText={"Integrate data from various sources for better visibility and control."}
                featureHighlights={[
                    "Integrate data across safety management platforms.",
                    "Centralize data from multiple sources for easy access.",
                    "Ensure data consistency and integrity across systems.",
                    "Monitor and analyze integrated data in real-time."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for Data Integration section here
            />
            <FeaturesSection
                headerText={"Custom Integrations"}
                subText={"Set up custom integrations to meet your organization's unique needs."}
                featureHighlights={[
                    "Create custom APIs for data integration.",
                    "Set up data pipelines for real-time monitoring.",
                    "Ensure seamless data flow across platforms."
                ]}
                // Placeholder: Add image for Custom Integrations section here
            />
            <FeaturesSection
                headerText={"Integration Analytics"}
                subText={"Analyze integrated data for actionable insights and decision-making."}
                featureHighlights={[
                    "Generate analytics reports from integrated data.",
                    "Monitor data flow and identify inconsistencies.",
                    "Optimize data integrations for better performance."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for Integration Analytics section here
            />
            <div className="Home-Page sl-home-wrapper" style={{ paddingTop: 0 }}>
                <ParallaxSection />
            </div>
            <div className="features-homepage-wrapper">
                <HomepageDemoSection mergeTop={false} />
            </div>
        </>
    );
}
