import React from 'react';
import Report from "../../../../javascript/assets/images/report.svg";
import QRCode from "../../../../javascript/assets/images/QRCode.svg";
import Complaint from "../../../../javascript/assets/images/complaint.svg";
import 'animate.css';
import ScrollAnimation from 'react-animate-on-scroll';
import { Button } from 'react-bootstrap';

export default function FeatureHighlightSection() {
    return (
        <div className="feature-highlight-section">
            <ScrollAnimation
                animateIn="animate__fadeIn"
                duration={1.5}
                animateOnce={true}
                delay={0}
            >
                <div className="feature-highlight-container">
                    <div style={{ maxWidth: "650px" }}>
                        <h2 className="feature-highlight-text">Modernizing Construction Site Safety with our Digital Health & Safety Solution.</h2>
                    </div>
                    <div className="feature-highlight-grid">
                        <div className="feature-card">
                            <img className='image' src={Report} />
                            <h2 className="title">Transparent Reporting, Clearer Safety</h2>
                            <p className='sub-text'>The Certificate of Recognition (COR®) is a national accreditation standard that verifies full implementation of an employer’s Occupational Health and Safety Management System. COR® is often used as a condition of contract by the public and private sectors across Canada. (IHSA Source)</p>
                        </div>
                        <div className="feature-card">
                            <img className='image' src={QRCode} />
                            <h2 className="title">Instant Access From Your Smartphone</h2>
                            <p className='sub-text'>Instant Access From Your Smart Device
                                    Access is at your fingertips. Free yourself from the constraints of a desk-bound or laptop computer; manage your entire OHSMS in real time and on the go.
                            </p>
                        </div>
                        <div className="feature-card">
                            <img className='image' src={Complaint} />
                            <h2 className="title">Stay Compliant</h2>
                            <p className='sub-text'>Our plug-and-play OHS software, crafted by safety professionals, is dedicated to streamlining your data management, freeing you up to concentrate on the critical elements of your OHSMS. This cost savings solution is equipped to handle any level of complexity within your OHSMS with ease.</p>
                        </div>
                    </div>
                    <a href="/features" style={{ marginTop: 30 }}>
                        <Button className="book-demo-btn">Explore all features</Button>
                    </a>
                </div>
            </ScrollAnimation>
        </div>
    )
}
