import React from 'react';

import HeroBannerImage from "../../assets/images/incidentmanagement.png";

export default function InspectionManagement() {
    return (
        <div className="inspection-management-section">
            <img src={HeroBannerImage}  />
        </div>
    );
}
