import React from 'react';
import useFetch from '../../../shared/components/userFetch';
import useModify from '../../../shared/components/userModify';
import CreateGroup from './newGroup';

const EditGroup = ({edit, setEdit, refetch}) => {
    const {data, isLoading} = useFetch(`/api/v2/entity_management/groups/${edit}?view_type=form`);
    const {mutate, isLoading: isMutating, error} = useModify('/api/v2/entity_management/groups');
    if (isLoading) {
        return null;
    }
    return (
        <CreateGroup mutate={mutate} open={!!edit} setOpen={setEdit} initialValues={data.data} refetch={refetch} isMutating={isMutating} />
    )
}

export default EditGroup;
