import React, { useEffect, useState } from "react";
import axios from "../../../../config/axiosConfig";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { TableContainer, Paper, Pagination, MenuItem, FormControl, Select, Switch, Button } from '@mui/material';
import Application from "../../../../config/ApplicationWrapper";
import Add from "./add";
import Loader from "../../../../components/NewLoader";
import ViewQrCode from "./ViewQrCode";
import SendSMS from "./SendSMS";

const EmployeesList = ({ id }) => {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(30);
    const [pageCount, setPageCount] = useState(0);
    const [pageDetails, setPageDetails] = useState([]);
    const [name, setSearchByName] = useState();
    const [show, setShow] = useState(false);
    const [showDetails, setShowDetails] = useState();
    const [loading, setLoading] = useState(true);
    const [searchable, setSearchable] = useState(false);
    const [showQr, setShowQr] = useState(false);
    const [showSMS, setShowSMS] = useState(false);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getPageDetails()
        }, 2000)

        return () => clearTimeout(delayDebounceFn)
    }, [name, limit])

    const getPageDetails = async () => {
        try {
            window.scrollTo(0, 0);
            setLoading(true)
            let resultObj = await axios.get(`${Application.api_url}/api/v2/internal_subs/${id}/internal_employees`, {
                params: {
                    page: page,
                    limit: limit,
                    full_name_cont: name,
                },
            });
            if (resultObj.status == 200) {
                let details = resultObj.data;
                setPageDetails(details ? details.data : [])
                setPageCount(
                    details && details.metadata ? details.metadata.pages : 0
                );
                setSearchable(details && details.data.length > 0 ? true : false)
                setLoading(false)
            }
        } catch (err) {
            console.log("error==>", err);
        }
    };

    const handleChangePage = (event, value) => {
        setPage(value);
        getPageDetails()
    };

    const hanldeLimitChange = (value) => {
        setPage(1);
        setLimit(value);
    }

    const clearFilter = () => {
        setSearchByName("");
    }

    const open = (value) => {
        setShow(true)
        setShowDetails(value)
    }

    const close = (data) => {
        setShow(false)
        setShowQr(false)
        setShowSMS(false)
        setShowDetails("")
        data && getPageDetails()
    }

    const archive = async (value) => {
        value.activated = value.archived
        await axios.patch(`${Application.api_url}/api/v2/internal_subs/${id}/internal_employees/${value.identifier}`, value).then((result) => {
            if (result.data && result.data.success === true) {
                toast.success(!value.archived ? `Deactivated Successfully` : "Activated Successfully")
                getPageDetails()
            }
        }).catch((error) => {
            console.log("error===>", error)
        })
    }

    const resendInvite = async (val) => {
        await axios.post(`${Application.api_url}/api/v2/internal_subs/${id}/internal_employees/${val}/resend_invite`).then((result) => {
            if (result.data && result.data.success === true) {
                toast.success(`Invite sent successfully`)
            }
        }).catch((err) => {
            console.log("err===>", err)
        })
    }

    return (
        <>
            <div class="card asset-tab-landing">
                <div class="card-body">
                    <div class="tab-content">
                        <div
                            id="active-risk-assessment"
                            class="tab-pane fade in active active-risk-assessment internal-sub-employee"
                        >
                            <div className="nestedTable pf-table">
                                <div className="fields-setting">
                                    <div className="asset-active-filters">
                                        Active Filters :
                                        <span className="active-filters">
                                            <span>Employee Name :</span>
                                            <span className="selected">{name ? name : "All"}</span>
                                            <span className={name ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setSearchByName("") }}>X</span>
                                        </span>
                                        <span className="active-filters-clear" onClick={(e) => clearFilter()}>
                                            Clear All
                                        </span>
                                    </div>
                                    <div className="bulk-action">
                                        <Button variant="contained" className="btn-create" onClick={(e) => { setShow(true) }}>+ Add Employee</Button>
                                    </div>
                                </div>
                                <TableContainer component={Paper}>
                                    <table class="table table-striped">
                                        <thead>
                                            <tr style={{ height: "100px" }}>
                                                <th scope="col" className="risk-assessment-divider">
                                                    <div className="d-flex justify-content-between align-item-center">
                                                        Employee  Name
                                                    </div>
                                                    {searchable &&
                                                        <div class="input-group">
                                                            <input
                                                                type="text"
                                                                class="form-control assessment-name"
                                                                placeholder="Search"
                                                                aria-label="Username"
                                                                id="name"
                                                                aria-describedby="basic-addon1"
                                                                name="name"
                                                                onChange={(e) => {
                                                                    setSearchByName(e.target.value)
                                                                }}
                                                                value={name}
                                                            />
                                                            <div class="input-group-addon">
                                                                <span class="input-group-text" id="basic-addon1">
                                                                    <i class="fa fa-search" aria-hidden="true"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    }
                                                </th>
                                                <th scope="col" className="risk-assessment-divider">
                                                    Email
                                                </th>
                                                <th scope="col" className="risk-assessment-divider">
                                                    Contact No.
                                                </th>
                                                <th scope="col" className="table-action">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pageDetails &&
                                                pageDetails.length > 0 &&
                                                pageDetails.map((val, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                {val.first_name} {val.last_name}
                                                            </td>
                                                            <td>
                                                                {val.email}
                                                            </td>
                                                            <td>
                                                                {val.phone_number}
                                                            </td>
                                                            <td
                                                                className="template-options"
                                                                style={{ textAlign: "center" }}
                                                            >
                                                                <a
                                                                    aria-expanded="false"
                                                                    aria-haspopup="true"
                                                                    class="btn dropdown-toggle"
                                                                    data-toggle="dropdown"
                                                                    href="#"
                                                                    id="dropdownMenuLink"
                                                                    role="button"
                                                                >
                                                                    <i class="fa fa-ellipsis-v"></i>
                                                                </a>
                                                                <ul aria-labelledby="dropdownMenuLink" class="dropdown-menu">
                                                                    <li>
                                                                        <a
                                                                            class="archive_group_invites"
                                                                            data-toggle="tooltip"
                                                                            title="Edit"
                                                                            onClick={(e) => { open(val) }}
                                                                        >
                                                                            <div class="row d-flex">
                                                                                <div class="col-sm-3">
                                                                                    <i
                                                                                        class="fa fa-pencil-square-o"
                                                                                        style={{
                                                                                            paddingTop: "5px",
                                                                                            fontSize: "16px",
                                                                                            cursor: "pointer"
                                                                                        }}
                                                                                    ></i>
                                                                                </div>
                                                                                <div
                                                                                    class="col-sm-9"
                                                                                    style={{
                                                                                        paddingTop: "5px",
                                                                                        cursor: "pointer"
                                                                                    }}
                                                                                >
                                                                                    Edit
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                        <a
                                                                                class="archive_group_invites"
                                                                                data-toggle="tooltip"
                                                                                title="View QR"
                                                                                onClick={(e) => { setShowDetails(val.identifier); setShowQr(true) }}
                                                                            >
                                                                                <div class="row d-flex">
                                                                                    <div class="col-sm-3">
                                                                                        <i
                                                                                            class="fa fa-qrcode"
                                                                                            style={{
                                                                                                paddingTop: "5px",
                                                                                                fontSize: "16px",
                                                                                                cursor: "pointer"
                                                                                            }}
                                                                                        ></i>
                                                                                    </div>
                                                                                    <div
                                                                                        class="col-sm-9"
                                                                                        style={{
                                                                                            paddingTop: "5px",
                                                                                            cursor: "pointer"
                                                                                        }}
                                                                                    >
                                                                                        View QR
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                        <a
                                                                            class="archive_group_invites"
                                                                            data-toggle="tooltip"
                                                                            title="Archive"
                                                                            onClick={(e) => {
                                                                                resendInvite(val.identifier);
                                                                            }}
                                                                        >
                                                                            <div class="row d-flex">
                                                                                <div class="col-sm-3">
                                                                                    <i
                                                                                        class="fa fa-envelope"
                                                                                        style={{
                                                                                            paddingTop: "5px",
                                                                                            fontSize: "16px",
                                                                                            cursor: "pointer"
                                                                                        }}
                                                                                    ></i>
                                                                                </div>
                                                                                <div
                                                                                    class="col-sm-9"
                                                                                    style={{
                                                                                        paddingTop: "5px",
                                                                                        cursor: "pointer"
                                                                                    }}
                                                                                >
                                                                                    Resend Invite
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                        <a
                                                                                class="archive_group_invites"
                                                                                data-toggle="tooltip"
                                                                                title="Send Message"
                                                                                onClick={(e) => {
                                                                                    setShowDetails(val)
                                                                                    setShowSMS(true);
                                                                                }}
                                                                            >
                                                                                <div class="row d-flex">
                                                                                    <div class="col-sm-3">
                                                                                        <i
                                                                                            class="fa fa-sms"
                                                                                            style={{
                                                                                                paddingTop: "5px",
                                                                                                fontSize: "16px",
                                                                                                cursor: "pointer"
                                                                                            }}
                                                                                        ></i>
                                                                                    </div>
                                                                                    <div
                                                                                        class="col-sm-9"
                                                                                        style={{
                                                                                            paddingTop: "5px",
                                                                                            cursor: "pointer"
                                                                                        }}
                                                                                    >
                                                                                        Send Message
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                    </li>
                                                                </ul>
                                                                <Switch
                                                                    checked={!val.archived}
                                                                    onClick={(event) => archive(val)}
                                                                />
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            <Loader pageDetails={pageDetails} loading={loading} btnName={"+ Add Employee"} open={open} />
                                        </tbody>
                                    </table>
                                </TableContainer>
                                <div className="page-limits">
                                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                        <Select
                                            labelId="demo-select-small"
                                            id="demo-select-small"
                                            value={limit}
                                            onChange={(e) => {
                                                hanldeLimitChange(e.target.value);
                                            }}
                                        >
                                            <MenuItem value={5}>5</MenuItem>
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={20}>20</MenuItem>
                                            <MenuItem value={30}>30</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {!loading && (
                                        <Pagination
                                            count={pageCount}
                                            page={page}
                                            variant="outlined"
                                            shape="rounded"
                                            onChange={handleChangePage}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {show &&
                <Add show={show} closepopup={close} details={showDetails} parentId={id} />
            }
            {showQr &&
                <ViewQrCode show={showQr} closepopup={close} details={showDetails} parentId={id}/>
            }
            {showSMS &&
                <SendSMS show={showSMS} closepopup={close} details={showDetails} parentId={id}/>
            }
        </>
    );
}

export default EmployeesList;
