import React from 'react';
import loader from '../../../assets/images/newloader.gif';

const Loader = () => {
    return (
        <div className="table-loader"><img width="125" src={loader} title="loader" /></div>
    )
}

export default Loader;
