import React from 'react';
const logo = require('../../assets/images/company_logo.png');

const dataAnalysisBox = {
    // border: 1px solid
    // height:400px
};

const GaugeBox = {
    // height: 00px;,
//    width: 200px;
  // boxShadow: 1px 1px 1px 1px

};

const widgetStyles ={
  // borderLeft: 1px solid #999
};

const firstRow = {
  // marginTop: 25px
};

class AnalyticsOverview extends React.Component
{
    constructor(props)

    {
        super(props);
        this.state = {
            company_url: ''
        }
    }

    componentDidMount() {

    console.log(this.props)
    }


    render()
    {

        return (
            <div>

            </div>



            )
    }
}

export default AnalyticsOverview;
