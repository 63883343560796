import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import {
  Modal,
  FormControl as Input,
} from "react-bootstrap";
import { toast } from "react-toastify";
import Application from "../../../config/ApplicationWrapper";
import axios from "../../../config/axiosConfig";


export default function SendSMS(props) {
  const { show, closepopup, details } = props;
  const [onSubmit, setSubmit] = useState(false);
  const [disabled, setDisable] = useState(false);
  const [phone, setPhone] = useState("+1");

  useEffect(() => {
    details && setPhone(`+1${details.admin.phone_number}`)
  }, [show])


  const sendsms = async () => {
    try {
      setSubmit(true);
      setDisable(true);
      if (!phone) {
        return false
      }

    await axios.post(`${Application.api_url}/api/v2/internal_subs/${details.identifier}/send_sms`,{
        phone_number:phone, 
    }).then((result) => {
        if (result.data.success === true) {
            toast.success(`SMS sent successfully`)
        } else {
            toast.error(`SMS not sent`)
        }
    }).catch((err) => {
        console.log("err==>", err.response)
        toast.error(err.response.data.message[0])
    })

    } catch (err) {
        toast.error("1")
    }
  };

  return (
    <Modal
      bsSize="large"
      show={show}
      onHide={(e) => {
        closepopup();
      }}
      className="create-maintanance-popup internal-sub-employee-popup"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <i className="fa fa-plus" aria-hidden="true"></i>
          <b>Send SMS</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="create-form">
          <div className="row">
            <div className="col-md-6">
              <label>
                Phone Number<text style={{ color: "red" }}>*</text>
              </label>
              <TextField
                required
                fullWidth
                className="text-field"
                id="outlined-required"
                error={!phone && onSubmit}
                helperText={
                  !phone && onSubmit ? "Phone Number is required" : ""
                }
                value={phone}
                onChange={(e) => {
                    setDisable(false)
                    setPhone(e.target.value);
                }}
              />
            </div> 
          </div> 
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn button cancel-btn"
          onClick={() => {
            closepopup()
          }}
        >
          Cancel
        </button>
        <button
          className="btn btn-primary"
          disabled={disabled}
          onClick={(event) => {
            sendsms();
          }}
        >
          Send SMS
        </button>
      </Modal.Footer>
    </Modal>
  );
}
