import React, { useState } from "react";
import ActiveAssetType from "./ActiveAssetType";
import ArchiveAssetType from "./ArchiveAssetType";
import { NavLink } from "react-router-dom";
import CreateAssetType from "./Popup/create";
import { toast } from "react-toastify";

export default function AssetType() {
  const [createPopup, setCreatePopup] = useState(false);
  const [editData, setEditData] = useState();
  const [lastUpdate, setLastUpdate] = useState(0);
  const [totalActive, setTotalActive] = useState(0);
  const [totalAchive, setTotalAchive] = useState(0);

  const openDocPopup = (data) => {
    setCreatePopup(true);
    if (data) {
      setEditData(data);
    }
  };

  const closePopup = (data) => {
    setCreatePopup(false);
    if (data) {
      toast.success("Created successfully");
      setLastUpdate(Date.now());
    }
  };

  return (
    <>
      <div class="card asset-tab-landing">
        <div class="card-body">
          <div className="d-flex align-item-center justify-content-between header-badge">
            <div className="d-flex align-item-center">
              <h3>Asset Types </h3>
            </div>
            <div className="btn-style">
              <button
                type="button"
                class="btn btn-primary"
                onClick={(e) => {
                  openDocPopup();
                }}
              >
                <i class="fa fa-plus" aria-hidden="true"></i>
                Asset Type
              </button>
            </div>
          </div>
          <ul class="nav nav-tabs">
            <li class="active">
              <a data-toggle="tab" href="#active-asset-type">
                Active Asset Types
                <span class="badge badge-light">{totalActive}</span>
              </a>
            </li>
            <li>
              <a data-toggle="tab" href="#archived-asset-type">
                Archived Asset Types
                <span class="badge badge-light">
                  {totalAchive}
                </span>
              </a>
            </li>
          </ul>
          <div class="tab-content">
            <div
              id="active-asset-type"
              class="tab-pane fade in active active-risk-assessment"
            >
              <ActiveAssetType updatedDate={lastUpdate} setUpdateDate={setLastUpdate} totalCount={setTotalActive}/>
            </div>
            <div
              id="archived-asset-type"
              class="tab-pane fade in active-risk-assessment"
            >
              <ArchiveAssetType  updatedDate={lastUpdate} setUpdateDate={setLastUpdate} totalCount={setTotalAchive}/>
            </div>
          </div>
          <CreateAssetType
            show={createPopup}
            closepopup={closePopup}
            data={editData}
          />
        </div>
      </div>
    </>
  );
}
