// Accordion.js
import React, { useState } from "react";
import Delete from "../../../assets/fonts/Delete.svg";
import axios from "../../../config/axiosConfig";
import Application from "../../../config/ApplicationWrapper";

const Accordion = ({ items, timeCardId, setRefresh, viewWorkEntry }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const handleBulkDelete = async (item) => {
    let workEntryItemIds = item.workEntries.reduce((ids, entry) => {
      if (item.userId == entry.user_id) {
        ids.push(entry.work_entry_id);
      }
      return ids;
    }, []);
    console.log("delete ids", workEntryItemIds)

    await axios.delete(
        `${Application.api_url}/api/v2/time_cards/${timeCardId}/work_entries/bulk_delete`,
        {
          params: {ids: workEntryItemIds, user_id: item.userId}
        }
    );
    setRefresh(Date.now())
  }

  return (
    <div className="accordion">
      {items.map((item, index) => (
        <div key={index} className="accordion-item">
          <div
            className={`accordion-header ${
              activeIndex === index ? "active" : ""
            }`}
            onClick={() => handleClick(index)}
          >
            <span
              className={`accordion-arrow ${
                activeIndex === index ? "open" : ""
              }`}
            >
              &#9660;
            </span>
            <span className="accordion-title">
              {item.title}
              {!viewWorkEntry &&
                  <img src={Delete} alt="" onClick={() => handleBulkDelete(item)}/>
              }
            </span>
          </div>
          <div
            className={`accordion-content ${
              activeIndex === index ? "show" : ""
            }`}
          >
            {item.content}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
