import React, { useEffect, useState, useContext } from "react";
import Pagination from "@mui/material/Pagination";
import Cookies from "js-cookie";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import EmptyData from "../../../../assets/images/empty-assets.svg";
import Application from "../../../../config/ApplicationWrapper";
import CreateGeoLocationPopup from "../Popups/GeoLocation";
import { toast } from "react-toastify";
import { AssetCreateContext } from "./index";
import locationIcon from "../../../../assets/images/Location.svg";

const CreateGeoLocation = () => {
  const [pagaData, setPageData] = useState([]);

  const cookieDetails = Cookies.get("token");
  const { setTabKey } = useContext(AssetCreateContext);

  // Pagination
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [pagination, setPagination] = useState(true);
  const [pageCounts, setPageCounts] = useState(0);
  const [lastUpdate, setLastUpdate] = useState(0);
  const [GeoLocationPopup, setGeoLocationPopup] = useState(false);
  const [editData, setEditData] = useState(false);

  let getAssetId = localStorage.getItem("assetInfo");
  let asstId = getAssetId ? JSON.parse(getAssetId) : "";

  useEffect(() => {
    try {
      async function getPageDetails() {
        if (asstId && asstId.asstId) {
          const groupDetails = await fetch(
            `${Application.api_url}/api/v2/asset_trackings/${asstId.asstId}/geo_location_trackings?limit=${limit}&page=${page}&sort=created_at+desc`,
            {
              method: "GET",
              headers: { Authorization: cookieDetails },
            }
          );
          const details = await groupDetails.json();
          setPageData(details && details.data ? details.data : []);
          setPageCounts(
            details && details.metadata ? details.metadata.pages : 1
          );
          setPagination(true);
        }
      }
      getPageDetails();
    } catch (err) {
      console.log("err===>", err);
    }
  }, [lastUpdate, page, limit]);

  const NoAssetsFound = () => (
    <div className="no-assets">
      <div className="d-flex">
        <div className="container">
          <img src={EmptyData} />
          <p>
            <b>No Data</b>
          </p>
        </div>
      </div>
    </div>
  );

  const removeGeoLocation = async (assetId) => {
    const groupDetails = await fetch(
      `${Application.api_url}/api/v2/asset_trackings/${asstId.asstId}/geo_location_trackings/${assetId}`,
      {
        method: "DELETE",
        headers: { Authorization: cookieDetails },
      }
    );
    setLastUpdate(Date.now());
    toast.success("Deleted Successfully");
  };

  const openGeoLocationPopup = (data) => {
    setGeoLocationPopup(true);
    if (data) {
      setEditData(data);
    }
  };

  const closeGeoLocationpopup = (data) => {
    setGeoLocationPopup(false);
    setEditData(false)
    if (data) {
      toast.success("Created Successfully");
      setLastUpdate(Date.now());
    }
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <div className="relatedAssets asset-maintance">
      <div className="header d-flex">
        <h3 className="title">
          <b>Geo Location Tracking</b>
        </h3>
        <button
          type="button"
          className="btn btn-primary add-maintance-btn"
          onClick={() => {
            openGeoLocationPopup(true);
          }}
        >
          <i className="fa fa-plus" aria-hidden="true"></i>
          Add Location
        </button>
      </div>
      <div className="body">
        <div className="table-template">
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">
                  <div className="d-flex justify-content-between">
                    Location Address
                  </div>
                </th>
                <th scope="col">
                  <div className="d-flex justify-content-between">
                    Logged by
                  </div>
                </th>
                <th scope="col">
                  <div className="d-flex justify-content-between">
                    Date & Time
                  </div>
                </th>
                <th scope="col" style={{ textAlign: "left" }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {pagaData &&
                pagaData.length > 0 &&
                pagaData.map((obj, index) => (
                  <tr key={index}>
                    <td>
                      <img
                        src={locationIcon}
                        alt=""
                        style={{ marginRight: "5px" }}
                      />
                      {obj.address}
                      <p style={{ color: "#65B5E3", marginLeft: "30px" }}>
                        ({obj.lat}, {obj.lon})
                      </p>
                    </td>
                    <td>{obj.logged_by ? obj.logged_by.full_name : ""}</td>
                    <td>{obj.created_at}</td>
                    <td>
                      <a
                        aria-expanded="false"
                        aria-haspopup="true"
                        className="btn dropdown-toggle"
                        data-toggle="dropdown"
                        id="dropdownMenuLink"
                        role="button"
                      >
                        <i className="fa fa-ellipsis-v"></i>
                      </a>
                      <ul
                        aria-labelledby="dropdownMenuLink"
                        className="dropdown-menu"
                      >
                        <li>
                          <a
                            className="archive_group_invites"
                            data-toggle="tooltip"
                            role="button"
                            title="Edit"
                            onClick={() => openGeoLocationPopup(obj)}
                          >
                            <div className="row">
                              <div className="col-sm-3">
                                <i
                                  className="fa fa-pencil-square-o"
                                  style={{
                                    paddingTop: "5px",
                                    fontSize: "16px",
                                  }}
                                ></i>
                              </div>
                              <div
                                className="col-sm-9"
                                style={{ paddingTop: "5px" }}
                              >
                                Edit
                              </div>
                            </div>
                          </a>
                          <a
                            className="archive_group_invites asset-links"
                            data-toggle="tooltip"
                            role="button"
                            title="Delete"
                            onClick={(e) => {
                              removeGeoLocation(obj.id);
                            }}
                          >
                            <div className="row">
                              <div className="col-sm-3">
                                <i className="fa fa-trash"></i>
                              </div>
                              <div className="col-sm-9 archive-menu">Delete</div>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>
                ))}
              {pagaData.length === 0 && (
                <tr>
                  <td colSpan={5}>
                    <NoAssetsFound />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {pagaData && pagaData.length > 0 && (
            <div
              className="d-flex justify-content-between"
              style={{ alignItems: "center" }}
            >
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={limit}
                  onChange={(e) => {
                    setLimit(e.target.value);
                    setPage(1);
                    setPagination(false);
                  }}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                </Select>
              </FormControl>
              {pagination ? (
                <Pagination
                  count={pageCounts}
                  variant="outlined"
                  shape="rounded"
                  onChange={handleChangePage}
                />
              ) : null}
            </div>
          )}
        </div>
      </div>
      <div className="d-flex footer">
        <button
          className="btn button"
          onClick={() => {
            setTabKey("5");
          }}
        >
          Skip
        </button>
        <div>
          <button
            className="btn button"
            onClick={() => {
              setTabKey("3");
            }}
          >
            Previous
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              setTabKey("5");
            }}
          >
            Next
          </button>
        </div>
      </div>
      <CreateGeoLocationPopup
        show={GeoLocationPopup}
        closepopup={closeGeoLocationpopup}
        data={editData}
        parentId={asstId.asstId}
      />
    </div>
  );
};

export default CreateGeoLocation;
