import axios from "../../../config/newAxiosConfig";
import {toast} from "react-toastify";

export const fetchUserDropdown = async () => {
  try {
    const res = await axios.get('/common/pickers');
    
    if (res.data.success) {
      return res.data.data;
    }

    return [];
  } catch (err) {
    console.error(err);
    return [];
  }
}
export const approveAsAdmin = async (itemId) => {
  try {
    const res = await axios.post(`/order_request_items/${itemId}/approve_as_admin`, {}, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    console.log(res)
    if (res.data.success) {
      toast.success('Approved')
      return res.data.data;
    }

    return null;
  } catch (err) {
    toast.error(err.response.data.message);
    return null;
  }
}

export const rejectAsAdmin = async (itemId, rejectReason) => {
  try {
    const res = await axios.post(`order_request_items/${itemId}/reject_as_admin`,
      {
        reject_reason: rejectReason
      }, {
      headers: {
        'Content-Type': 'application/json'
      }
      })

    if (res.data.success) {
      toast.success('Rejected.')
      return res.data.data;
    }

    return null;
  } catch(err) {
    toast.error(err.response.data.message);
    return null;
  }
}

export const orderItemSingleUpdate = async (itemId, data) => {
  try {
    const res = await axios.patch(`order_request_items/${itemId}/single_update`, data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )

    if (res.data.success) {
      toast.success('Updated successfully.');
      return res.data.data;
    }

    return null;
  } catch(err) {
    toast.error(err.response.data.message);
    return null;
  }
}

export const requestForBackorder = async (itemId, data) => {
  try {
    const res = await axios.post(`order_request_items/${itemId}/backorder`, data,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    })

    if (res.data.success) {
      toast.success('Updated successfully.');
      return res.data.data;
    }
  } catch(err) {
    toast.error(err.response.data.message);
    return null;
  }
}

export const approveAllAsAdmin = async (data) => {
  try {
    const res = await axios.post(`order_request_items/approve_all_as_admin`, data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })

    if (res.data.success) {
      toast.success('Approved successfully.');
      return res.data.data;
    }
  } catch(err) {
    toast.error(err.response.data.message);
    return null;
  }
}

export const updateDeliveryRequestItem = async (itemId, data) => {
  try {
    const res = await axios.patch(`delivery_request_items/${itemId}`, data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })

    if (res.data.success) {
      toast.success('Updated successfully.');
      return res.data.data;
    }
  } catch(err) {
    toast.error(err.response.data.message);
    return null;
  }
}

export const titleize = (str) => {
  return str.split('_')
    .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
    .join(' ');
}
