import React, { useEffect, useState } from "react";
import axios from "../../../config/axiosConfig";
import { TableContainer, Paper, Pagination, MenuItem, FormControl, Select, Button } from '@mui/material';
import EmptyTable from "../../../assets/images/empty-assets.svg";
import Application from "../../../config/ApplicationWrapper";
import { toast } from "react-toastify";
import Loader from "../../../components/NewLoader";
// import { User } from "../../../pages/HomePage/Login";
import AddCustomAttributesModal from "./AddCustomAttributesModal";

const Template = ({ templateId }) => {

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(30);
    const [pageCount, setPageCount] = useState(0);
    const [pageDetails, setPageDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [name, setSearchByName] = useState();
    const [user, setSearchByUser] = useState();
    const [group, setSearchByGroup] = useState();
    const [openModal, setOpenModal] = useState(false);
    const [customAttributes, setCustomAttributes] = useState([]);

    useEffect(() => {
        getPageDetails()
    }, [page, limit])


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getPageDetails()
        }, 2000)

        return () => clearTimeout(delayDebounceFn)
    }, [name, user, group])

    const getCustomAttributes = async (data) => {
        try {
          setLoading(true);
          
          let resultObj = await axios.get(`${Application.api_url}/api/custom_attributes`, {
            params: {
              company_id: data[0].company.id, 
            },
          });
          if (resultObj.status === 200) {
            debugger;
            setCustomAttributes(resultObj.data.data);
            setLoading(false);
          }
        } catch (err) {
          console.log("error==>", err);
          setLoading(false);
        }
      };
    //   useEffect(() => {
    //     getCustomAttributes();
    //   }, []);

    const getPageDetails = async () => {
        try {

            window.scrollTo(0, 0);
            setLoading(true);
            let resultObj = await axios.get(`${Application.api_url}/api/subcontractors/flagged_users`, {
                params: {
                    page: page,
                    limit: limit,
                    subcontractor_user_full_name_cont: name,
                    company_name_cont: group,
                    subcontractor_name_cont: user,
                },
            });
            if (resultObj.status == 200) {
                let details = resultObj.data;
                setPageDetails(details ? details.data : [])
                
                console.log(details.data)
                setPageCount(
                    details && details.metadata ? details.metadata.pages : 0
                );
                getCustomAttributes(details.data);
                setLoading(false)
            }
        } catch (err) {
            console.log("error==>", err);
        }
    };

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const clearFilter = () => {
        setSearchByGroup("");
        setSearchByName("");
        setSearchByUser("");
    }

    const remove = async (val) => {
        try {

            const formData = new FormData();
            formData.append("subcontractor_id", val.subcontractor.id);
            formData.append("user_id", val.subcontractor_user.id);
            formData.append("is_flagged", false);

            let resultObj = await axios.post(`${Application.api_url}/api/subcontractors/flag_employee`, formData);
            if (resultObj.data.success) {
                toast.success('Removed successfully')
                getPageDetails()
            }

        } catch (err) {
            console.log("error==>", err);
        }
    }

    return (
        <>
            <div class="card asset-tab-landing">
                <div class="card-body">
                    <div class="tab-content">
                        <div
                            id="active-risk-assessment"
                            class="tab-pane fade in active active-risk-assessment"
                        >
                            <div className="nestedTable pf-table">
                                <div className="fields-setting">
                                    <div className="asset-active-filters">
                                        Active Filters :
                                        <span className="active-filters">
                                            <span>Employee Name :</span>
                                            <span className="selected">{name ? name : "All"}</span>
                                            <span className={name ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setSearchByName("") }}>X</span>
                                        </span>
                                        <span className="active-filters">
                                            <span>Parent Company :</span>
                                            <span className="selected">{group ? group : "All"}</span>
                                            <span className={group ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setSearchByGroup("") }}>X</span>
                                        </span>
                                        <span className="active-filters">
                                            <span>Subcontractor	 :</span>
                                            <span className="selected">{user ? user : "All"}</span>
                                            <span className={user ? "clr-selected" : "clr-selected hidden"} onClick={(e) => { setSearchByUser("") }}>X</span>
                                        </span>
                                        <span className="active-filters-clear" onClick={(e) => clearFilter()}>
                                            Clear All
                                        </span>
                                    </div>
                                    
                                    <div className="bulk-action">
                                    </div>

                                </div>
                                
                                
                                <TableContainer component={Paper}>
                                
                                    <table class="table table-striped">
                                        <thead>
                                            <tr style={{ height: "100px" }}>
                                                <th scope="col" className="risk-assessment-divider">
                                                    <div className="d-flex justify-content-between align-item-center">
                                                        Employee Name
                                                    </div>
                                                    <div class="input-group">
                                                        <input
                                                            type="text"
                                                            class="form-control assessment-name"
                                                            placeholder="Search"
                                                            aria-label="Username"
                                                            id="name"
                                                            aria-describedby="basic-addon1"
                                                            name="name"
                                                            onChange={(e) => {
                                                                setSearchByName(e.target.value)
                                                            }}
                                                            value={name}
                                                        />
                                                        <div class="input-group-addon">
                                                            <span class="input-group-text" id="basic-addon1">
                                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th scope="col" className="risk-assessment-divider">
                                                    Parent Company
                                                    <div class="input-group">
                                                        <input
                                                            type="text"
                                                            class="form-control assessment-name"
                                                            placeholder="Search"
                                                            aria-label="Username"
                                                            id="group"
                                                            aria-describedby="basic-addon1"
                                                            name="group"
                                                            onChange={(e) => {
                                                                setSearchByGroup(e.target.value)
                                                            }}
                                                            value={group}
                                                        />
                                                        <div class="input-group-addon">
                                                            <span class="input-group-text" id="basic-addon1">
                                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th scope="col" className="risk-assessment-divider">
                                                    Subcontractor
                                                    <div class="input-group">
                                                        <input
                                                            type="text"
                                                            class="form-control assessment-name"
                                                            placeholder="Search"
                                                            aria-label="Username"
                                                            id="user"
                                                            aria-describedby="basic-addon1"
                                                            name="user"
                                                            onChange={(e) => {
                                                                setSearchByUser(e.target.value)
                                                            }}
                                                            value={user}
                                                        />
                                                        <div class="input-group-addon">
                                                            <span class="input-group-text" id="basic-addon1">
                                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th scope="col" className="table-action">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!loading &&
                                                pageDetails.length > 0 &&
                                                pageDetails.map((val, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="alerts-name">
                                                                {val.subcontractor_user ? val.subcontractor_user.full_name : "-"}
                                                            </td>
                                                            <td>
                                                                {val.company ? val.company.name : "-"}
                                                            </td>
                                                            <td>
                                                                {val.subcontractor ? val.subcontractor.name : "-"}
                                                            </td>
                                                            <td
                                                                className="template-options"
                                                                style={{ textAlign: "center", color: "red" }}
                                                            >   <i class="fa fa-minus-circle" aria-hidden="true" onClick={(e) => { remove(val) }} ></i>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            <Loader pageDetails={pageDetails} loading={loading} />
                                        </tbody>
                                    </table>
                                </TableContainer>
                                <div className="page-limits">
                                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                        <Select
                                            labelId="demo-select-small"
                                            id="demo-select-small"
                                            value={limit}
                                            onChange={(e) => {
                                                setPage(1);
                                                setLoading(true);
                                                setLimit(e.target.value);
                                            }}
                                        >
                                            <MenuItem value={5}>5</MenuItem>
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={20}>20</MenuItem>
                                            <MenuItem value={30}>30</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {!loading && (
                                        <Pagination
                                            count={pageCount}
                                            page={page}
                                            variant="outlined"
                                            shape="rounded"
                                            onChange={handleChangePage}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TableContainer component={Paper} style={{ marginTop: '20px' }}>
    <div className="row" style={{ marginTop: '20px' }}>
        <div className="col-md-10">
          <h3 style={{marginLeft:'10px', marginTop:'0px'}}>Flagged Custom Attributes</h3>
        </div>
        <div className="col-md-2">
        {/* <h5>
            Flag suspected users here, and SafetyLoop will help alert you if they try to complete new forms in the future. 
        </h5> */}
        <Button variant="contained" color="primary" onClick={() => setOpenModal(true)} style={{backgroundColor:'#407797'}}>
            + Add Custom Attributes
        </Button>
        </div>
    </div>
    
    
    
  <table class="table table-striped">
    <thead>
      <tr style={{ height: "100px" }}>
        <th scope="col" className="" style={{background:'#e0edf4', borderBottom:'0px'}}>First Name</th>
        <th scope="col" className="risk-assessment-divider" style={{background:'#e0edf4', borderBottom:'0px'}}>Last Name</th>
        <th scope="col" className="risk-assessment-divider" style={{background:'#e0edf4', borderBottom:'0px'}}>Phone Number</th>
        <th scope="col" className="risk-assessment-divider" style={{background:'#e0edf4', borderBottom:'0px'}}>Email</th>
        <th scope="col" className="table-action" style={{background:'#e0edf4', borderBottom:'0px'}}>Action</th>
      </tr>
    </thead>
    <tbody>
      {!loading &&
        customAttributes.length > 0 &&
        customAttributes.map((attr, index) => (
          <tr key={index}>
            <td>{attr.first_name}</td>
            <td>{attr.last_name}</td>
            <td>{attr.phone_number}</td>
            <td>{attr.email}</td>
            <td
              className="template-options"
              style={{ textAlign: "center", color: "red" }}
            >
              <i class="fa fa-minus-circle" aria-hidden="true" onClick={() => removeCustomAttribute(attr.id)}></i>
            </td>
          </tr>
        ))}
      {loading && (
        <tr>
          <td colSpan="5" style={{ textAlign: 'center' }}>
            Loading...
          </td>
        </tr>
      )}
    </tbody>
  </table>
</TableContainer>
            <AddCustomAttributesModal
             open={openModal}
             handleClose={() => setOpenModal(false)}
             getPageDetails={getPageDetails}
           />
        </>
    );
}

export default Template;
