import React from 'react';
import HeroSection from './CommonComponents/HeroSection';
import FeaturesSection from './CommonComponents/FeaturesSection';
import ParallaxSection from '../../pages/HomePage/Components/ParallaxSection';
import HomepageDemoSection from '../../pages/HomePage/Components/HomepageDemoSection';

export default function PrivateForms() {
    return (
        <>
            <HeroSection
                heroText={"Private Forms"}
                subText={"Collect data securely using SafetyLoop’s private forms for sensitive information."}
            />
            <FeaturesSection
                headerText={"Secure Private Forms"}
                subText={"Create and manage private forms to securely collect and store sensitive information."}
                featureHighlights={[
                    "Create custom private forms for various use cases.",
                    "Set permissions to restrict access to forms.",
                    "Track submissions and manage responses.",
                    "Maintain a secure record of form data."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for Secure Private Forms section here
            />
            <FeaturesSection
                headerText={"Automated Form Management"}
                subText={"Automate form submission tracking and receive real-time notifications."}
                featureHighlights={[
                    "Set up alerts for new form submissions.",
                    "Monitor form completion rates.",
                    "Track form access and ensure compliance."
                ]}
                // Placeholder: Add image for Automated Form Management section here
            />
            <FeaturesSection
                headerText={"Form Reporting and Analytics"}
                subText={"Analyze form data and generate reports for better decision-making."}
                featureHighlights={[
                    "Generate detailed reports on form submissions.",
                    "Analyze trends and responses over time.",
                    "Optimize forms based on data insights."
                ]}
                sectionBackground='dark'
                sectionAlign="image-right"
                // Placeholder: Add image for Form Reporting section here
            />
            <div className="Home-Page sl-home-wrapper" style={{ paddingTop: 0 }}>
                <ParallaxSection />
            </div>
            <div className="features-homepage-wrapper">
                <HomepageDemoSection mergeTop={false} />
            </div>
        </>
    );
}
